import { configureStore,getDefaultMiddleware } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import { composeWithDevTools } from 'redux-devtools-extension'
import authReducer from './pages/Auth/authSlice'
import qzReducer from './qzSlice'
import onboardingReducer from './pages/Dashboard/onboardingSlice'
import dashboardReducer from './pages/Dashboard/dashboardSlice'
import ordersReducer from './pages/Orders/ordersSlice'
import deliveriesReducer from './pages/Reports/Deliveries/deliveriesSlice'
import deliveriesmysqlReducer from './pages/Reports/Deliveries/deliveriesmysqlSlice'
import manifestsReducer from './pages/Reports/Manifest/manifestsSlice'
import pickupReducer from './pages/Reports/Pickup/pickupSlice'
import groupedmanifestsReducer from './pages/Manifest/groupedmanifestsSlice'
import IntegrationsReducer from './pages/Integration/Marketplace/integrationsSlice'
import IntegratedReducer from './pages/Integration/Marketplace/integratedSlice'
import quicklabelReducer from './pages/Shipments/QuickOrder/quickLabelSlice'
import bulkOrderReducer from './pages/BulkOrder/bulkOrderSlice'
import bulkPrintLabelReducer from './pages/BulkOrder/bulkPrintSlice'
import rateReducer from './pages/Shipments/NewShipment/rateSlice'
import createshipmentReducer from './pages/Shipments/NewShipment/createShipmentSlice'
import addressSenderReducer from './pages/Settings/AddressSender/addressSenderSlice'
import addressRecipientReducer from './pages/Settings/AddressRecipient/addressRecipientSlice'
import addressBillingReducer from './pages/Settings/AddressBilling/addressBillingSlice'
import carrierCredentialsReducer from './pages/Integration/Carrier/carrierCredentialsSlice'
import countrystateReducer from './pages/General/countryStateCitySlice'
import boxPageReducer from './pages/Settings/Box/boxSlice'
import tagPageReducer from './pages/Settings/TagSettings/tagSettingsSlice'
import timezonePageReducer from './pages/Settings/TimezoneSettings/timezoneSettingsSlice'
import PickupAddressPageReducer from './pages/Settings/PickupAddress/pickupAddressSlice'
import labelSettingsReducer from './pages/Settings/LabelSettings/labelSettingsSlice'
import manifestSettingsReducer from './pages/Settings/ManifestSettings/manifestSettingsSlice'
import pickupSettingsReducer from './pages/Settings/PickupSettings/pickupSettingsSlice'
import orderPicklistReducer from './pages/OrderPicklist/orderpicklistSlice'
import paymentReducer from './pages/Settings/Payment/paymentSlice'
import invoiceReducer from './pages/Settings/Invoice/invoiceSlice'
import configReducer from './pages/Settings/Config/configSlice'
import passwordReducer from './pages/Settings/Password/passwordSlice'
import profileReducer from './pages/Settings/Profile/profileSlice'
import trackingReducer from './pages/Reports/Deliveries/trackingEventsSlice';
import manifestdetailReducer from './pages/ManifestDetail/manifestdetailSlice';
import orderdetailsReducer from './pages/OrderDetail/orderdetailsSlice';
import hubsReducer from './pages/General/hubslice';
import addressbookReducer from './pages/General/NewOrder/addressbookSlice';
import notificationDashboardReducers from './pages/Reports/Notifications/notificationsSlice'
import customerFeedbackDashboardReducers from './pages/Reports/Feedback/customerFeedbackSlice'
import wigetAnalyticsReducers from './pages/Reports/Widgets/widgetAnalyticsSlice'
import shopifyappReducers from './pages/Integration/Marketplace/PlatformIntegrations/Shopify/shopifyappSlice';
import brightpearlReducer from './pages/Integration/Marketplace/PlatformIntegrations/Brightpearl/brightpearlSlice';
import woocommerceReducer from './pages/Integration/Marketplace/PlatformIntegrations/Woocommerce/woocommerceSlice';
import bigcommerceReducer from './pages/Integration/Marketplace/PlatformIntegrations/Bigcommerce/bigcommerceSlice';
import skulabsReducer from './pages/Integration/Marketplace/PlatformIntegrations/SkuLabs/skulabsSlice';
import shipstationReducer from './pages/Integration/Marketplace/PlatformIntegrations/ShipStation/shipstationSlice';
import etsyReducer from './pages/Integration/Marketplace/PlatformIntegrations/Etsy/etsySlice';
import emailNotificationReducer from './pages/Integration/Notification/PlatformIntegrations/Email/emailSlice';
import webhookNotificationReducer from './pages/Integration/Notification/PlatformIntegrations/Webhook/webhookSlice';
import klaviyoNotificationReducer from './pages/Integration/Notification/PlatformIntegrations/Klaviyo/klaviyoSlice';
import claimsReducer from './pages/Claims/claimsSlice'

const store = configureStore({
  reducer: {
    auth: authReducer,    
    onboarding: onboardingReducer,
    dashboard: dashboardReducer,
    orders: ordersReducer,
    deliveries: deliveriesReducer,
    deliveriesmysql: deliveriesmysqlReducer,
    integrations: IntegrationsReducer,
    integrated: IntegratedReducer,
    manifests: manifestsReducer,
    pickup: pickupReducer,
    quicklabel: quicklabelReducer,
    bulkorder: bulkOrderReducer,
    claims: claimsReducer,
    rates: rateReducer,
    addresssender: addressSenderReducer,
    addressrecipient: addressRecipientReducer,
    addressbilling: addressBillingReducer,
    carriercredentials: carrierCredentialsReducer,
    createshipment: createshipmentReducer,
    countrystate: countrystateReducer,
    box: boxPageReducer,
    config: configReducer,
    profile: profileReducer,
    password: passwordReducer,
    tracking: trackingReducer,
    groupedmanifest: groupedmanifestsReducer,
    manifestdetails : manifestdetailReducer,
    orderdetails : orderdetailsReducer,
    notificationdashboard:notificationDashboardReducers,
    customerfeedbackdashboard:customerFeedbackDashboardReducers,
    widgetanalytics:wigetAnalyticsReducers,
    shopifyapp: shopifyappReducers,
    brightpearl: brightpearlReducer,
    woocommerce: woocommerceReducer,
    bigcommerce: bigcommerceReducer,
    skulabs: skulabsReducer,
    shipstation: shipstationReducer,
    etsy: etsyReducer,
    email:emailNotificationReducer,
    webhook:webhookNotificationReducer,
    klaviyo:klaviyoNotificationReducer,
    labelsettings:labelSettingsReducer,
    manifestsettings:manifestSettingsReducer,
    pickupsettings:pickupSettingsReducer,
    orderpicklist:orderPicklistReducer,
    payment:paymentReducer,
    invoice:invoiceReducer,
    hubs: hubsReducer,
    addressbook: addressbookReducer,
    pickupaddress: PickupAddressPageReducer,
    tags: tagPageReducer,
    timezone: timezonePageReducer,
    bulkprintlabel:bulkPrintLabelReducer,
    qz: qzReducer

  },
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger)   
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
})

export default store
