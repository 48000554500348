import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useForm } from '../../../pages/General/useForm';
import FormHelperText from '@mui/material/FormHelperText';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import LinearProgress from '@mui/material/LinearProgress';
import Autocomplete from "@mui/material/Autocomplete";
import { fetchAddressBilling, updateAddressBilling, storeAddressBilling } from './addressBillingSlice';
import { fetchCountry, fetchState } from '../../../pages/General/countryStateCitySlice';

const AddressBillingPage = () => {
    const dispatch = useDispatch();
    const addressbilling = useSelector(state => state.addressbilling);
    const country = useSelector(state => state.countrystate.country);
    const state = useSelector(state => state.countrystate.state);
    const [alert, setAlert] = useState(false);
    const [open, setOpen] = useState(false);
    const [checkFormSubmit, setCheckFormSubmit] = useState(false);
    const handleClose = () => {
        setOpen(false)
    };

    const initialFValues = {
        billing_name: '',
        billing_address: '',
        billing_address2: '',
        billing_city: '',
        billing_company: '',
        billing_country_code: '',
        billing_email: '',
        billing_phone: '',
        billing_postal_code: '',
        billing_state_code: ''
    }

    useEffect(() => {
        dispatch(fetchAddressBilling());
        if (addressbilling && addressbilling.data && addressbilling.data.billing_name) {
            setValues({
                ...values,
                billing_name: addressbilling && addressbilling.data && addressbilling.data.billing_name,
                billing_address: addressbilling && addressbilling.data && addressbilling.data.billing_address,
                billing_address2: addressbilling && addressbilling.data && addressbilling.data.billing_address2,
                billing_city: addressbilling && addressbilling.data && addressbilling.data.billing_city,
                billing_company: addressbilling && addressbilling.data && addressbilling.data.billing_company,
                billing_email: addressbilling && addressbilling.data && addressbilling.data.billing_email,
                billing_phone: addressbilling && addressbilling.data && addressbilling.data.billing_phone,
                billing_country_code: addressbilling && addressbilling.data && addressbilling.data.billing_country_code,
                billing_postal_code: addressbilling && addressbilling.data && addressbilling.data.billing_postal_code,
                billing_state_code: addressbilling && addressbilling.data && addressbilling.data.billing_state_code,
            })
        }
    }, [addressbilling && addressbilling.data && addressbilling.data.billing_name]);

    useEffect(() => {
        if (checkFormSubmit && addressbilling && addressbilling.message) {
            setAlert(true)
            setOpen(true)
        } else if (checkFormSubmit && addressbilling && addressbilling.hasErrors == true) {
            setAlert(true)
            setOpen(true)
        }
    }, [dispatch, addressbilling && addressbilling.message])

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('billing_name' in fieldValues)
            temp.billing_name = fieldValues.billing_name ? "" : "This field is required."
        if (fieldValues && fieldValues.billing_name && fieldValues.billing_name.length < 3) {
            temp.billing_name = "Billing Name required minimum 3 characters"
        }
        if ('billing_address' in fieldValues)
            temp.billing_address = fieldValues.billing_address ? "" : "This field is required."
        if ('billing_city' in fieldValues)
            temp.billing_city = fieldValues.billing_city ? "" : "This field is required."
        if (fieldValues && fieldValues.billing_city && fieldValues.billing_city.length < 3)
            temp.billing_city = "Billing City required minimum 3 characters"
        if (fieldValues && fieldValues.billing_phone && fieldValues.billing_phone.length < 10)
            temp.billing_phone = "Billing Phone required minimum 10 digits"
        if ('billing_company' in fieldValues)
            temp.billing_company = fieldValues.billing_company ? "" : "This field is required."
        if ('billing_country_code' in fieldValues)
            temp.billing_country_code = fieldValues.billing_country_code ? "" : "This field is required."
        if ('billing_email' in fieldValues)
            temp.billing_email = fieldValues.billing_email ? "" : "This field is required."
        if ('billing_phone' in fieldValues)
            temp.billing_phone = fieldValues.billing_phone ? "" : "This field is required."
        if ('billing_phone' in fieldValues)
            temp.billing_phone = fieldValues.billing_phone ? "" : "This field is required."
        if ('billing_postal_code' in fieldValues)
            temp.billing_postal_code = fieldValues.billing_postal_code ? "" : "This field is required."
        if ((fieldValues && fieldValues.billing_postal_code && fieldValues.billing_postal_code.length < 3) ||
            (fieldValues && fieldValues.billing_postal_code && fieldValues.billing_postal_code === null))
            temp.billing_postal_code = "Billing postal code required minimum 3 characters"
        if (fieldValues && fieldValues.billing_postal_code && fieldValues.billing_postal_code.match(/[^0-9a-z-\s]/i)) {
            temp.billing_postal_code = "Postal code only accepts alpha numeric"
        }
        if ('billing_state_code' in fieldValues)
            temp.billing_state_code = fieldValues.billing_state_code ? "" : "This field is required."

        setErrors({
            ...temp
        })

        if (fieldValues === values)
            return Object.values(temp).every(x => x === "")
    }
    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
    } = useForm(initialFValues, validate, true);

    const handleSubmit = e => {
        e.preventDefault()
        if (validate()) {
            if (addressbilling && addressbilling.data && addressbilling.data._id) {
                setCheckFormSubmit(true)
                const idvalue = addressbilling && addressbilling.data && addressbilling.data._id
                dispatch(updateAddressBilling(idvalue, values))
            } else {
                setCheckFormSubmit(true)
                const dm = { "status": true }
                const mergeobjectdata = Object.assign(values, dm);
                dispatch(storeAddressBilling(mergeobjectdata))
                setAlert(true)
            }
        }
    }

    useEffect(() => {
        dispatch(fetchCountry());
    }, [dispatch]);

    useEffect(() => {
        if (addressbilling && addressbilling.data && addressbilling.data.billing_country_code) {
            dispatch(fetchState(addressbilling && addressbilling.data && addressbilling.data.billing_country_code));
        }
    }, [addressbilling && addressbilling.data && addressbilling.data.billing_country_code]);

    // const onChangeCountry = (countryCode) => {
    //     setValues({
    //         ...values,
    //         billing_country_code: countryCode
    //     })
    //     setErrors({ "billing_country_code": "" })
    //     dispatch(fetchState(countryCode));
    // }
    const filteredCountryData = country.data.filter(({ code }) => code );

    const onSelectCountryChange = (countryCode) => {
        setValues({
          ...values,
          billing_country_code: countryCode,
        });
        setErrors({ billing_country_code: "" });
        dispatch(fetchState(countryCode));
      };
    const getSelectedCountry = (country_code) => {
        let filteredValue = { code: "", name: "" };
        filteredCountryData.map((key) => {
          if (key.code == country_code) {
            filteredValue = key;
          }
        });
         return filteredValue;
      };
    
      const onSelectStateChange = (currentstate) => {
        setValues({
          ...values,
          billing_state_code: currentstate,
        });
        setErrors({ billing_state_code: "" });
      };
    
      const getSelectedState = (currentstate) => {
        let filteredStateValue = { code: "", name: "" };
        let tempState = "";
        state &&
          state.data &&
          state.data.map((key) => {
            if (key.code == currentstate && tempState == "") {
              filteredStateValue = key;
              tempState = "success";
            }
          });
        return filteredStateValue;
      };

    return (

        <div className="sc--settings--card">
            {alert &&
                <Snackbar
                    open={open}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    autoHideDuration={6000}
                    onClose={handleClose}
                >
                    <Alert severity={addressbilling && addressbilling.hasErrors === true ? "error" : "success"}
                        style={{ justifyContent: 'center', margin: "10px 0" }}
                    >
                        <span > {addressbilling && addressbilling.message}</span>

                    </Alert>
                </Snackbar>
            }

                <Card sx={{ minWidth: 1000, boxShadow: 'unset' }} className="sc--cardhead--details">
                    <CardHeader title='Billing Address' className="sc--cardhead--details" />
                    { (country && country.loading === true || addressbilling && addressbilling.loading) &&
                        <LinearProgress />
                    }
                    <Divider />
                    <CardContent>
                        <div className="" style={{ width: "700px", margin: 'auto' }}>
                            <div className="sc--form--center">
                                <TextField
                                    label="Name"
                                    name="billing_name"
                                    type="text"
                                    value={values.billing_name}
                                    onChange={handleInputChange}
                                    inputProps={{ maxLength: 40 }}
                                    error={!!errors.billing_name}
                                    helperText={errors.billing_name}
                                    style={{ width: "100%", margin: '8px' }}
                                    variant="standard"
                                />
                                <TextField
                                    label="Company"
                                    name="billing_company"
                                    type="text"
                                    value={values.billing_company}
                                    onChange={handleInputChange}
                                    inputProps={{ maxLength: 40 }}
                                    error={!!errors.billing_company}
                                    helperText={errors.billing_company}
                                    variant="standard" style={{ width: "100%", margin: '8px' }}
                                />          </div>
                            <div className="sc--form--center">
                                <TextField
                                    label="Phone"
                                    name="billing_phone"
                                    value={values.billing_phone}
                                    onChange={handleInputChange}
                                    inputProps={{ maxLength: 20 }}
                                    error={!!errors.billing_phone}
                                    helperText={errors.billing_phone}
                                    variant="standard" style={{ width: "100%", margin: '8px' }}
                                />
                                <TextField
                                    label="Email"
                                    name="billing_email"
                                    type="email"
                                    value={values.billing_email}
                                    inputProps={{ maxLength: 60 }}
                                    onChange={handleInputChange}
                                    error={!!errors.billing_email}
                                    helperText={errors.billing_email}
                                    variant="standard" style={{ width: "100%", margin: '8px' }}
                                />             </div>
                            <div className="sc--form--center">
                                <TextField
                                    label="Address1"
                                    name="billing_address"
                                    value={values.billing_address}
                                    inputProps={{ maxLength: 35 }}
                                    onChange={handleInputChange}
                                    error={!!errors.billing_address}
                                    helperText={errors.billing_address}
                                    variant="standard" style={{ width: "100%", margin: '8px' }}
                                />
                                <TextField
                                    label="Address2"
                                    name="billing_address2"
                                    value={values.billing_address2}
                                    inputProps={{ maxLength: 35 }}
                                    onChange={handleInputChange}
                                    error={!!errors.billing_address2}
                                    helperText={errors.billing_address2}
                                    variant="standard" style={{ width: "100%", margin: '8px' }}
                                />
                            </div>
                            <div className="sc--form--center">
                                <TextField
                                    label="Postal Code"
                                    name="billing_postal_code"
                                    value={values.billing_postal_code}
                                    onChange={handleInputChange}
                                    inputProps={{ maxLength: 6 }}
                                    error={!!errors.billing_postal_code}
                                    helperText={errors.billing_postal_code}
                                    variant="standard" style={{ width: "100%", margin: '8px' }}
                                />
                                <TextField
                                    label="City"
                                    name="billing_city"
                                    value={values.billing_city}
                                    inputProps={{ maxLength: 30 }}
                                    onChange={handleInputChange}
                                    error={!!errors.billing_city}
                                    helperText={errors.billing_city}
                                    variant="standard" style={{ width: "100%", margin: '8px' }}
                                />
                            </div>
                            <div className="sc--form--center">
                             <Autocomplete
                                className="help-text-error"
                                disablePortal
                                name="billing_country_code"
                                id="country-select-demo"
                                sx={{ width: "100%",margin: '8px' }}
                                options={filteredCountryData}
                                autoHighlight
                                loading={country && country.loading}
                                getOptionLabel={(option) => option.name}
                                value={getSelectedCountry(values && values.billing_country_code)}
                                renderOption={(props, option) => (
                                <MenuItem {...props} value={option.code} key={option.code}>
                                    {option.icon} {option.name}
                                </MenuItem>
                                )}
                                onChange={(event, value) => {
                                if (value !== null) {
                                    onSelectCountryChange(value.code);
                                    setErrors({ billing_country_code: "" });
                                } else {
                                    onSelectCountryChange("");
                                    setErrors({
                                        billing_country_code: "This field is required",
                                    });
                                }
                                }}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="country"
                                    variant="standard"
                                    helperText={errors.billing_country_code}
                                />
                                )}
                            />
                            <Autocomplete
                                className="help-text-error"
                                disablePortal
                                name="billing_state_code"
                                id="state-select-demo"
                                sx={{ width: "100%",margin: '8px' }}
                                options={state && state.data}
                                autoHighlight
                                loading={
                                (state && state.loading) || (country && country.loading)
                                }
                                getOptionLabel={(option) => option.name}
                                value={getSelectedState(values && values.billing_state_code)}
                                renderOption={(props, option) => (
                                <MenuItem {...props} value={option.code} key={option.code}>
                                    {option.name}
                                </MenuItem>
                                )}
                                onChange={(event, value) => {
                                if (value !== null) {
                                    onSelectStateChange(value.code);
                                } else {
                                    onSelectStateChange("");
                                    setErrors({ billing_state_code: "This field is required" });
                                }
                                }}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="state"
                                    variant="standard"
                                    helperText={errors.billing_state_code}
                                />
                                )}
                            />
                            </div>
                            <div className="sc--form--center" style={{ margin: "20px 0" }}>
                                <Button variant="contained" onClick={handleSubmit}>Save</Button>
                            </div>
                        </div>
                    </CardContent>
                </Card>
        </div>
    )
}


export default AddressBillingPage;
