import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { styled } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
// import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import Alert from '@mui/material/Alert';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Carrier from '../../../pages/General/Carrier'
import Loader from '../../../pages/General/Loader';
import { fetchCarrierDetail, destoryCarrier, resetCarrierNotification } from './carrierCredentialsSlice';
import IntelcomModal from './Intelcom/IntelcomModal';
import CanadapostModal from './Canadapost/CanadapostModal';
import SettingsStatus from '../../../pages/General/SettingsStatus'

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    border: "1px solid rgba(224, 224, 224, 1)",
    boxShadow: "unset"

}));
const deletemodalstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: '#fff',
    p: 4,
};
const CarrierCredentialsPage = () => {
    const dispatch = useDispatch();
    const carrier = useSelector(state => state.carriercredentials);
    const [intelcomOpen, setIntelcomOpen] = useState(false);
    const [canadapostOpen, setCanadapostOpen] = useState(false);
    const [deleteModalopen, setDeleteModalopen] = React.useState(false);
    const [deleteid, setDeleteId] = React.useState("");
    const [alert, setAlert] = useState(false);
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [selectedItem, setSelectedItem] = useState({});

    const snackbar = (value) => {
        setSnackOpen(value)
    }


    const handleClick = (id) => {
        setDeleteId(id)
        setDeleteModalopen(true);
    };
    const deleteModalClose = () => {
        setDeleteModalopen(false);
    };

    useEffect(() => {
        dispatch(fetchCarrierDetail())
    }, [dispatch]);

    useEffect(() => {
        if (carrier && carrier.message) {
            setAlert(true)
            setSnackOpen(true)
        }
    }, [carrier && carrier.message]);

    const deletecarrier = (id) => {
        dispatch(destoryCarrier(id))
        setAlert(true)
        setSnackOpen(true)
        setDeleteModalopen(false);
    }
    const snackhandleClose = () => {
        setSnackOpen(false)
        dispatch(resetCarrierNotification())
    };


    const columns = [
        { field: 'name', headerName: 'Name', width: 300 },
        {
            field: 'carrier_name',
            headerName: 'Carrier',
            width: 130,
            renderCell: (params) => {
                return (
                    <Carrier carrierName={params.row.carrier_name} width={"90px"} />
                )
            }
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 80,
            renderCell: (params) => {
                return (
                    <Chip
                        label={params.row.status} color="success"
                        style={{
                            fontSize: '10px', height: '20px', borderRadius: '5px',
                            backgroundColor: params.row.status == 'ACTIVE' ? "#088708" : "#e85347"
                        }}
                    />
                )
            }
        },
        {
            field: 'app_enabled',
            headerName: 'App',
            width: 80,
            renderCell: (params) => {
                return <SettingsStatus settingsStatus={params.row.app_enabled} />
            }
        },
        {
            field: 'api_enabled',
            headerName: 'API',
            width: 80,
            renderCell: (params) => {
                return <SettingsStatus settingsStatus={params.row.api_enabled} />
            }
        },
        {
            field: 'quick_ship',
            headerName: 'Quick Ship',
            width: 100,
            renderCell: (params) => {
                return <SettingsStatus settingsStatus={params.row.quick_ship} />
            }
        },

        {
            field: 'action',
            headerName: 'Action',
            width: 100,
            renderCell: (params) => {
                return <div>

                    <span style={{ marginRight: '10px' }}>

                        <Tooltip title="Edit" placement="left-start">
                            <IconButton>
                                {params.row.carrier_name == 'INTELCOM' &&
                                    <EditIcon style={{ color: 'rgba(0,0,0,.54)', cursor: 'pointer' }}
                                        onClick={() => {
                                            setSelectedItem(params.row);
                                            setIntelcomOpen(true)
                                        }}
                                    >

                                    </EditIcon>

                                }
                                {params.row.carrier_name == 'CANADAPOST' &&
                                    <EditIcon style={{ color: 'rgba(0,0,0,.54)', cursor: 'pointer' }}
                                        onClick={() => {
                                            setSelectedItem(params.row);
                                            setCanadapostOpen(true)
                                        }}
                                    >
                                    </EditIcon>
                                }
                                {params.row.carrier_name !== 'CANADAPOST' && params.row.carrier_name !== 'INTELCOM' &&
                                    <EditIcon style={{ color: 'rgba(0,0,0,.54)', cursor: 'pointer' }}
                                    // onClick={() => {
                                    //     setSelectedItem(params.row);
                                    //     setCanadapostOpen(true)
                                    // }}
                                    >

                                    </EditIcon>

                                }
                            </IconButton>
                        </Tooltip>
                    </span>
                    <span>
                        <Tooltip title="Delete" placement="left-start">
                            <IconButton>
                                {(params.row.carrier_name === 'CANADAPOST' || params.row.carrier_name === 'INTELCOM') &&
                                    <DeleteOutlineOutlinedIcon style={{ color: 'rgba(0,0,0,.54)', cursor: 'pointer' }}
                                        onClick={(e) => {
                                            handleClick(params.row._id)
                                        }}
                                    />
                                }
                                {params.row.carrier_name !== 'CANADAPOST' && params.row.carrier_name !== 'INTELCOM' &&
                                    <DeleteOutlineOutlinedIcon style={{ color: 'rgba(0,0,0,.54)', cursor: 'pointer' }}
                                    />
                                }

                            </IconButton>
                        </Tooltip>
                    </span>
                </div>
            }
        }
    ];

    return (
        <>
            <Modal
                open={deleteModalopen}
                onClose={deleteModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ backgroundColor: 'rgb(0 0 0 / 11%)' }}
            >
                <Box sx={deletemodalstyle}>
                    <Typography sx={{ p: 2 }}>Are you sure want to delete this carrier?<br />
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <span style={{ margin: '10px 10px' }}>
                                <Button
                                    variant="contained"
                                    style={{ margin: "10px 0" }}
                                    onClick={(event) => {
                                        deletecarrier(deleteid)
                                    }}
                                >
                                    Yes
                                </Button>
                            </span>
                            <span style={{ margin: '10px 10px' }}>
                                <Button
                                    variant="outlined"
                                    style={{ margin: "10px 0" }}
                                    onClick={deleteModalClose}
                                >
                                    No
                                 </Button>
                            </span>
                        </div>
                    </Typography>
                </Box>
            </Modal>

            <IntelcomModal
                intelcomOpen={intelcomOpen}
                setIntelcomOpen={setIntelcomOpen}
                formValues={selectedItem}
                setFormValues={setSelectedItem}
                snackbar={snackbar}
                alert={setAlert}
            />
            <CanadapostModal
                canadapostOpen={canadapostOpen}
                setCanadapostOpen={setCanadapostOpen}
                formValues={selectedItem}
                setFormValues={setSelectedItem}
                snackbar={snackbar}
            />
            <div className="scmaincard">
                {alert && carrier.loading === false &&
                    <Snackbar
                        open={snackOpen}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right"
                        }}
                        autoHideDuration={6000}
                        onClose={snackhandleClose}
                    >
                        <Alert
                            severity={carrier && carrier.hasErrors == true ? "error" : "success"}
                            style={{ justifyContent: 'center', margin: "10px 0" }}>
                            <span >{carrier && carrier.message}</span>
                        </Alert>
                    </Snackbar>
                }
                <div className="integration--stores">
                    <Box sx={{ flexGrow: 1, }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={5}>
                                <Item style={{ height: "100%" }}>
                                    <div className="integration--stores--main">
                                        <div className="integration--stores--head">
                                            <div className="integration--stores--title" style={{ width: '100%' }}>
                                                <h2 style={{ textAlign: 'left' }}>Add Carrier</h2>
                                                {carrier && carrier.loading === true &&
                                                    <LinearProgress />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} md={3}>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexWrap: 'wrap',
                                                    '& > :not(style)': {
                                                        m: 1,
                                                        width: 120,
                                                    },
                                                }}
                                            >
                                                <div onClick={() => { setCanadapostOpen(true); }} style={{ cursor: 'pointer' }}>
                                                    <Paper variant="outlined" sx={{ height: 103 }}>
                                                        <div className="nonintegration--images">
                                                            <img
                                                                className="integration--logo"
                                                                src="/assets/images/integration/CANADAPOST.png"
                                                                alt="CANADAPOST"
                                                                title="CANADAPOST"
                                                            />
                                                        </div>
                                                    </Paper>
                                                    <h4 title="CANADAPOST">CANADAPOST</h4>
                                                </div>

                                            </Box>
                                        </Grid>
                                        {/* <Grid item xs={6} md={3}>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexWrap: 'wrap',
                                                    '& > :not(style)': {
                                                        m: 1,
                                                        width: 120,
                                                    },
                                                }}
                                            >
                                                <div onClick={() => { setIntelcomOpen(true); }} style={{ cursor: 'pointer' }}>
                                                    <Paper variant="outlined" sx={{ height: 103 }}>
                                                        <div className="nonintegration--images">
                                                            <img className="integration--logo"
                                                                src="/assets/images/carriers/INTELCOM.png"
                                                                alt="INTELCOM"
                                                                title="INTELCOM"
                                                            />
                                                        </div>
                                                    </Paper>
                                                    <h4 title="INTELCOM">INTELCOM</h4>
                                                </div>
                                            </Box>
                                        </Grid> */}
                                    </Grid>
                                </Item>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={7}>
                                <Item style={{ height: "100%" }}>
                                    <div className="integrated--stores--title">
                                        <h2 style={{ textAlign: 'left' }}>Carriers</h2>
                                    </div>
                                    <div className="integrated--stores--list">
                                        <div style={{ width: '100%' }}>
                                            <DataGrid
                                                getRowId={(row) => row._id}
                                                rows={carrier && carrier.carrier}
                                                columns={columns}
                                                autoHeight={true}
                                                hideFooter={true}
                                                slots={{
                                                    loadingOverlay: LinearProgress,
                                                }}
                                                loading={
                                                    (carrier && carrier.loading)
                                                }
                                            />
                                        </div>
                                    </div>
                                </Item>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </div>
        </>

    )
}
export default CarrierCredentialsPage;

