import { createSlice } from '@reduxjs/toolkit';
import { LABELGENAPI } from '../../../API';

export const addressrecipientslice = createSlice({
    name: 'addressrecipient',
    initialState: {
        addressrecipient: {
            data: [],
            meta: [],
            loading: false,
            hasErrors: false,
            message: "",
            messageType: ""
        },
        importaddressrecipient: {
            loading: false,
            hasErrors: false,
            message: "",
            messageType: ""
        }
    },
    reducers: {
        getRecipientAddress: (state) => {
            return {
                ...state,
                addressrecipient: {
                    ...state.addressrecipient,
                    loading: true,
                    hasErrors: false
                }

            }
        },
        getRecipientAddressSuccess: (state, action) => {
            return {
                ...state,
                addressrecipient: {
                    ...state.addressrecipient,
                    data: action && action.payload && action.payload.data && action.payload.data.data,
                    hasErrors: false,
                    loading: false
                }

            }
        },
        getRecipientAddressFailure: (state, action) => {
            return {
                ...state,
                addressrecipient: {
                    ...state.addressrecipient,
                    loading: false,
                    hasErrors: true,
                    message: action && action.payload && action.payload.data && action.payload.data.message
                }

            }
        },
        putRecipientAddress: (state) => {
            return {
                ...state,
                addressrecipient: {
                    ...state.addressrecipient,
                    loading: true,
                    hasErrors: false,
                }

            }
        },
        putRecipientAddressSuccess: (state, action) => {
            return {
                ...state,
                addressrecipient: {
                    ...state.addressrecipient,
                    loading: false,
                    hasErrors: false,
                    messageType: "success",
                    message: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.message
                }

            }
        },
        putRecipientAddressFailure: (state, action) => {
            return {
                ...state,
                addressrecipient: {
                    ...state.addressrecipient,
                    loading: false,
                    hasErrors: true,
                    messageType: "failure",
                    message: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.message
                }

            }
        },
        deleteRecipientAddress: (state) => {
            return {
                ...state,
                addressrecipient: {
                    ...state.addressrecipient,
                    loading: true,
                    hasErrors: false
                }

            }
        },
        deleteRecipientAddressSuccess: (state, action) => {
            return {
                ...state,
                addressrecipient: {
                    ...state.addressrecipient,
                    loading: false,
                    hasErrors: false,
                    messageType: "success",
                    message: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.message
                }

            }
        },
        deleteRecipientAddressFailure: (state, action) => {
            return {
                ...state,
                addressrecipient: {
                    ...state.addressrecipient,
                    loading: false,
                    hasErrors: true,
                    messageType: "failure",
                    message: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.message
                }

            }
        },
        postRecipientAddress: (state) => {
            return {
                ...state,
                addressrecipient: {
                    ...state.addressrecipient,
                    loading: true,
                    hasErrors: false,
                }

            }
        },
        postRecipientAddressSuccess: (state, action) => {
            return {
                ...state,
                addressrecipient: {
                    ...state.addressrecipient,
                    loading: false,
                    hasErrors: false,
                    messageType: "success",
                    message: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.message
                }

            }

        },
        postRecipientAddressFailure: (state, action) => {
            return {
                ...state,
                addressrecipient: {
                    ...state.addressrecipient,
                    loading: false,
                    hasErrors: true,
                    messageType: "failure",
                    message: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.message
                }

            }

        },
        postImportRecipientAddress: (state) => {
            return {
                ...state,
                importaddressrecipient: {
                    ...state.importaddressrecipient,
                    loading: true,
                    hasErrors: false,
                }

            }
        },
        postImportRecipientAddressSuccess: (state, action) => {
            return {
                ...state,
                importaddressrecipient: {
                    ...state.importaddressrecipient,
                    loading: false,
                    hasErrors: false,
                    messageType: "success",
                    message: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.message
                }

            }

        },
        postImportRecipientAddressFailure: (state, action) => {
            return {
                ...state,
                importaddressrecipient: {
                    ...state.importaddressrecipient,
                    loading: false,
                    hasErrors: true,
                    messageType: "failure",
                    message: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.message
                }

            }

        },
        resetRecipientAddress: (state) => {
            return {
                ...state,
                addressrecipient: {
                    ...state.addressrecipient,
                    loading: false,
                    hasError: false,
                    message: "",
                }

            }
        },
        resetImportRecipientAddress: (state) => {
            return {
                ...state,
                importaddressrecipient: {
                    ...state.importaddressrecipient,
                    loading: false,
                    hasError: false,
                    message: "",
                }

            }
        }
    }
})

export const { getRecipientAddress, getRecipientAddressSuccess, getRecipientAddressFailure,
    putRecipientAddress, putRecipientAddressSuccess, putRecipientAddressFailure,
    deleteRecipientAddress, deleteRecipientAddressSuccess, deleteRecipientAddressFailure,
    postRecipientAddress, postRecipientAddressSuccess, postRecipientAddressFailure,
    postImportRecipientAddress, postImportRecipientAddressSuccess, postImportRecipientAddressFailure,
    resetRecipientAddress, resetImportRecipientAddress } = addressrecipientslice.actions;

export const fetchRecipientAddress = meta => dispatch => {
    dispatch(getRecipientAddress());
    try {
        const qs = Object.keys(meta)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(meta[key])}`)
            .join('&');
        LABELGENAPI
            .get('/settings/address-book-recipient?' + qs)
            .then(function (response) {
                dispatch(getRecipientAddressSuccess(response));
            })
            .catch(function (error) {
                dispatch(getRecipientAddressFailure(error.response));
            });
    } catch (error) {
        dispatch(getRecipientAddressFailure());
    }
};

export const storeRecipientAddress = data => dispatch => {
    dispatch(postRecipientAddress());
    try {
        LABELGENAPI.post('/settings/address-book-recipient', data)
            .then(function (response) {
                dispatch(postRecipientAddressSuccess(response));
                dispatch(fetchRecipientAddress({}));
            }).catch(function (error) {
                dispatch(postRecipientAddressFailure(error.response));
            })
    }
    catch (error) {
        dispatch(postRecipientAddressFailure(error.response));
    }
}

export const updateRecipientAddress = (id, data) => dispatch => {
    dispatch(putRecipientAddress());

    try {
        LABELGENAPI.patch('/settings/address-book-recipient/' + id, data)
            .then(function (response) {
                dispatch(putRecipientAddressSuccess(response));
                dispatch(fetchRecipientAddress({}));
            }).catch(function (error) {
                dispatch(putRecipientAddressFailure(error.response));
            })
    }
    catch (error) {
        dispatch(putRecipientAddressFailure(error.response));
    }

}

export const destoryRecipientAddress = id => dispatch => {
    dispatch(deleteRecipientAddress());

    try {
        LABELGENAPI
            .delete("/settings/address-book-recipient/" + id)
            .then(function (response) {
                dispatch(deleteRecipientAddressSuccess(response));
                dispatch(fetchRecipientAddress({}));
            })
            .catch(function (error) {
                dispatch(deleteRecipientAddressFailure(error.response));
            });
    } catch (error) {
        dispatch(deleteRecipientAddressFailure(error));
    }
};

export const importRecipientAddress = data => dispatch => {
    dispatch(postImportRecipientAddress());
    try {
        LABELGENAPI.post('/settings/import-recipient-address-book', data)
            .then(function (response) {
                dispatch(postImportRecipientAddressSuccess(response));
                dispatch(fetchRecipientAddress({}));
            }).catch(function (error) {
                dispatch(postImportRecipientAddressFailure(error.response));
            })
    }
    catch (error) {
        dispatch(postImportRecipientAddressFailure(error.response));
    }
}

export const resetRecipientAddressState = data => dispatch => {
    dispatch(resetRecipientAddress());
}

export const resetImportRecipientAddressState = data => dispatch => {
    dispatch(resetImportRecipientAddress());
}


export default addressrecipientslice.reducer;
