import { createSlice } from '@reduxjs/toolkit';
import { LABELGENAPI } from '../../API';
import axios from 'axios';

/* canceling previous requests if multiple requests is triggered */
const CancelToken = axios.CancelToken;
let source = CancelToken.source();

export const orderpicklistslice = createSlice({
    name: 'orderpicklist',
    initialState: {
        orderpickdetail: {
            loading: false,
            data: {},
            notification: { show: false, messageContent: "", messageType: "success" }
        },
        orderpickdetailitems: {
            loading: false,
            data: {},
            notification: { show: false, messageContent: "", messageType: "success" }
        },
        orderpickdetailitemsupdate: {
            loading: false,
            data: {},
            notification: { show: false, messageContent: "", messageType: "success" }
        },
    },
    reducers: {
        getPickOrderDetail: (state, action) => {
            return {
                ...state,
                orderpickdetail: {
                    ...state.orderpickdetail,
                    data:{},
                    loading: true
                }
            }
        },
        getPickOrderDetailSuccess: (state, action) => {
            return {
                ...state,
                orderpickdetail: {
                    ...state.orderpickdetail,
                    loading: false,
                    data: action && action.payload && action.payload.data && action.payload.data.data,
                    notification: { show: false, messageContent: "", messageType: "success" }
                }
            }
        },
        getPickOrderDetailFailure: (state, action) => {
            return {
                ...state,
                orderpickdetail: {
                    ...state.orderpickdetail,
                    loading: false,
                    data: {},
                    notification: { show: true, messageContent: action && action.payload && action.payload.data, messageType: "error" }
                }
            }
        },
        getPickOrderDetailitems: (state, action) => {
            return {
                ...state,
                orderpickdetailitems: {
                    ...state.orderpickdetailitems,
                    data:{},
                    loading: true
                }
            }
        },
        getPickOrderDetailitemsSuccess: (state, action) => {
            return {
                ...state,
                orderpickdetailitems: {
                    ...state.orderpickdetailitems,
                    loading: false,
                    data: action && action.payload && action.payload.data && action.payload.data.data,
                }
            }
        },
        getPickOrderDetailitemsFailure: (state, action) => {
            return {
                ...state,
                orderpickdetailitems: {
                    ...state.orderpickdetailitems,
                    loading: false,
                    data: {},
                    notification: { show: true, messageContent: action.data && action.data.message, messageType: "error" }
                }
            }
        },
        putOrderItems: (state) => {
            return {
                ...state,
                orderpickdetailitemsupdate: {
                    ...state.orderpickdetailitemsupdate,
                    message: "",
                    loading: true
                }
            }
        },
        putOrderItemsSuccess: (state, action) => {
            return {
                ...state,
                orderpickdetailitemsupdate: {
                    ...state.orderpickdetailitemsupdate,
                    message: "",
                    loading: false,
                    notification: { show: false, messageContent: action && action.payload && action.payload.data && action.payload.data.message, messageType: "success" }
                }
            }
        },
        putOrderItemsFailure: (state, action) => {
            return {
                ...state,
                orderpickdetailitemsupdate: {
                    ...state.orderpickdetailitemsupdate,
                    message: "",
                    loading: false,
                    notification: { show: false, messageContent: action && action.payload && action.payload.data && action.payload.data.message, messageType: "success" }
                }
            }
        },

        resetOrderNotification: (state, action) => {
            return {
                ...state,
                orderpickdetail: {
                    loading: false,
                    data: {},
                    notification: { show: false, messageContent: "", messageType: "success" }
                },
                orderpickdetailitems: {
                    loading: false,
                    data: {},
                    notification: { show: false, messageContent: "", messageType: "success" }
                },
                orderpickdetailitemsupdate: {
                    loading: false,
                    data: {},
                    notification: { show: false, messageContent: "", messageType: "success" }
                },
            }
        }
       
    }
})

export const { getPickOrderDetail, getPickOrderDetailSuccess, getPickOrderDetailFailure } = orderpicklistslice.actions;

export const fetchPickOrderDetail = (order_id) => {
    let url = 'packing-validation/fetch-order';
    if(order_id != undefined && order_id != '' ){
        url += '?order_id='+order_id;
    }
    return (dispatch) => {
        dispatch(getPickOrderDetail());
        try {
            LABELGENAPI
                .get(url)
                .then(function (response) {
                    dispatch(getPickOrderDetailSuccess(response));
                })
                .catch(function (error) {
                    dispatch(getPickOrderDetailFailure(error.response));
                });
        } catch (error) {
            dispatch(getPickOrderDetailFailure());
        }
    };
};

export const fetchPickOrderDetailitems = (order_id) => {
    let url = 'packing-validation/fetch-order-items';
    if(order_id != undefined && order_id != '' ){
        url += '?order_id='+order_id;
    }
    return (dispatch) => {
        dispatch(getPickOrderDetailitems());
        try {
            LABELGENAPI
                .get(url)
                .then(function (response) {
                    dispatch(getPickOrderDetailitemsSuccess(response));
                })
                .catch(function (error) {
                    dispatch(getPickOrderDetailitemsFailure(error.response));
                });
        } catch (error) {
            dispatch(getPickOrderDetailitemsFailure());
        }
    };
};


export const { getPickOrderDetailitems, getPickOrderDetailitemsSuccess, getPickOrderDetailitemsFailure } = orderpicklistslice.actions;

export const putOrderItemsDetails = (data,orderId) => {
    return (dispatch) => {
        dispatch(putOrderItems());
        try {
            LABELGENAPI
                .post('packing-validation/update-order-items', data)
                .then(function (response) {
                    dispatch(putOrderItemsSuccess(response));
                    dispatch(fetchPickOrderDetailitems(orderId));
                })
                .catch(function (error) {
                    dispatch(putOrderItemsFailure(error.response));
                });
        } catch (error) {
            dispatch(putOrderItemsFailure());
        }
    };
};

export const { putOrderItems, putOrderItemsSuccess, putOrderItemsFailure } = orderpicklistslice.actions;

export const resetOrderPickNotification = () => {
    return (dispatch) => {
        console.log("here")
        dispatch(resetOrderNotification());
    }
}
export const { resetOrderNotification } = orderpicklistslice.actions;

export default orderpicklistslice.reducer;