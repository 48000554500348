import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Mui Libraries
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';

// Slice
import { fetchNotificationPlatform } from '../../../pages/Integration/Marketplace/integrationsSlice';

// Components
import PlatformDrawer from '../../General/PlatformDrawer';

const NonintegratedPage = () => {

    const dispatch = useDispatch();
    const integrations = useSelector(state => state.integrations);
    const [orderDrawerVisible, setOrderDrawerVisible] = useState(false);
    const [platformName, setPlatformName] = useState('all');
    const platformCallback = (item) => {
        if (item) {          
            setPlatformName(item)
            setOrderDrawerVisible(true)
        } 
    }

    const orderOnClose = (values) => {
        setOrderDrawerVisible(false);
    };


    useEffect(() => {
        dispatch(fetchNotificationPlatform());
    }, [dispatch]);




    const PlatformComponent = ({ platform = [], currentCategory, searchField }) => {
        return (
            <>
                {
                    platform && platform.map((item) => {
                        if (item.name === 'Email' || item.name === 'Webhook' || item.name === 'Klaviyo') {
                            return (
                                <Grid
                                    key={item.name}
                                    item xs={6} md={4}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            justifyContent: 'center',
                                            '& > :not(style)': {
                                                m: 1,
                                                width: 120,
                                            },
                                        }}
                                    >
                                        <div
                                            onClick={e => { platformCallback(item.name) }}
                                        >

                                            <Paper
                                                sx={{ height: 100 }}>
                                                <div
                                                    className="nonintegration--images">
                                                    <img
                                                        className="integration--logo"
                                                        src={item.image}
                                                        data-src={item.image}
                                                        alt={item.name}
                                                        title={item.name}
                                                        style={{maxWidth:'70%'}}
                                                    />
                                                </div>
                                            </Paper>
                                            <h4 title={item.name}>
                                                {item.name}
                                            </h4>

                                        </div>
                                    </Box>
                                </Grid>
                            )
                        }
                    })
                }
            </>
        )
    }
    return (
        <>
            <Grid container spacing={2}>
                {integrations && integrations.platform.loading === false &&
                    <PlatformComponent
                        platform=
                        {integrations && integrations.platform.data}                        
                    />
                }
            </Grid>
            <PlatformDrawer
                visible={orderDrawerVisible}
                onClose={orderOnClose}
                platform={platformName}               
            />
           
        </>
    );
};
export default NonintegratedPage;
