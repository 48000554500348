import { createSlice } from '@reduxjs/toolkit';
import { OMSAPI } from '../../../API';
import { LABELINGCHIMP_SAM_API } from '../../../API';
import axios from 'axios';

/* canceling previous requests if multiple requests is triggered */
const CancelToken = axios.CancelToken;
let source = CancelToken.source();

export const quicklabelslice = createSlice({
    name: 'quicklabel',
    initialState: {
        order: {},
        message: {},
        loading: false,
        hasErrors: false,
    },
    reducers: {
        doGenerateQuickLabel: (state) => {
            return {
                ...state,
                loading: true,
                hasErrors: false,
                order: {},
                message: {},
            }
        },
        doGenerateQuickLabelSuccess: (state, action) => {
            return {
                ...state,
                loading: false,
                hasErrors: false,
                order: action && action.payload && action.payload.data,
                message: action && action.payload && action.payload.data && action.payload.data.message,
            }
        },
        doGenerateQuickLabelFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                hasErrors: true,
                order: {},
                message: action && action.payload && action.payload.data.message && action.payload.data.message[0],
            }
        },
        resetQuickLabelGeneratedOrder: (state) => {
            return {
                ...state,
                order: {},
                message: {},
                loading: false,
                hasErrors: false
            }
        },

    }
})
export const { doGenerateQuickLabel, doGenerateQuickLabelSuccess, doGenerateQuickLabelFailure, resetQuickLabelGeneratedOrder} = quicklabelslice.actions;


export const generateQuickLabel = (data,apikey)  => dispatch => {    
    dispatch(doGenerateQuickLabel());
    try {        
        LABELINGCHIMP_SAM_API.post('/quick-ship', data, {
            crossDomain: true,
            headers: {
                "x-api-key": apikey
            }
        }).then(function (response) {
            dispatch(doGenerateQuickLabelSuccess(response));
            dispatch(resetQuickLabelGeneratedOrder())
        }).catch(function (error) {            
            dispatch(doGenerateQuickLabelFailure(error.response));
        })
    }
    catch (error) {
        dispatch(doGenerateQuickLabelFailure(error.response));
    }

}

export default quicklabelslice.reducer;
