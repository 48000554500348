import { createSlice } from '@reduxjs/toolkit';
import { OMSPROFILE } from '../../API';
import { authenticate } from '../../pages/Auth/authSlice';

export const onboardingslice = createSlice({
    name: 'onboarding',
    initialState: {
        notificationdata: {
            data: {},
            message: "",
            loading: false,
            hasErrors: false
        },
        activation: {
            data: {},
            message: "",
            loading: false,
            hasErrors: false
        },

    },

    reducers: {
        fetchUserOnboardingData: (state) => {
            return {
                ...state,
                notificationdata: {
                    loading: true
                }
            }
        },
        fetchUserOnboardingDataSuccess: (state, action) => {
            return {
                ...state,
                notificationdata: {
                    data: action && action.payload && action.payload.data && action.payload.data.data,
                    loading: false,
                    hasErrors: false,
                }
            }
        },
        fetchUserOnboardingDataFailure: (state, action) => {
            return {
                ...state,
                notificationdata: {
                    data: {},
                    message: action && action.payload && action.payload.data && action.payload.data.data,
                    loading: false,
                    hasErrors: true,
                }
            }
        },
        postUserAccountActivate: (state) => {
            return {
                ...state,
                activation: {
                    loading: true
                }
            }
        },
        postUserAccountActivateSuccess: (state, action) => {
            return {
                ...state,
                activation: {
                    message: action && action.payload && action.payload.data && action.payload.data.message,
                    loading: false,
                    hasErrors: false,
                }
            }

        },
        postUserAccountActivateFailure: (state, action) => {
            return {
                ...state,
                activation: {
                    data: {},
                    message: action && action.payload && action.payload.data && action.payload.data.message,
                    loading: false,
                    hasErrors: true,
                }
            }
        },
        resetOnboardingNotification: (state, action) => {
            return {
                ...state,                
                activation: {
                    data: {},
                    message: "",
                    loading: false,
                    hasErrors: false
                }
            }
        }
    }
})
export const { fetchUserOnboardingData, fetchUserOnboardingDataSuccess, fetchUserOnboardingDataFailure
    , postUserAccountActivate, postUserAccountActivateSuccess, postUserAccountActivateFailure, resetOnboardingNotification
     } = onboardingslice.actions;

export const fetchOnboardingData = data => dispatch => {
    dispatch(fetchUserOnboardingData())
    try {
        OMSPROFILE.get('/onboarding-checklist/validate')
            .then(function (response) {
                dispatch(fetchUserOnboardingDataSuccess(response));
            }).catch(function (error) {
                dispatch(fetchUserOnboardingDataFailure(error.response));
            })
    }
    catch (error) {
        dispatch(fetchUserOnboardingDataFailure(error.response))
    }
}
export const useraccountactivate = data => dispatch => {
    dispatch(postUserAccountActivate())
    try {
        OMSPROFILE.post('onboarding-checklist/account-activation')
            .then(function (response) {
                dispatch(postUserAccountActivateSuccess(response));
                dispatch(authenticate());
            }).catch(function (error) {
                dispatch(postUserAccountActivateFailure(error.response));
            })
    }
    catch (error) {
        dispatch(postUserAccountActivateFailure(error.response))
    }
}
export const resetOnboarding = data => dispatch => {    
    dispatch(resetOnboardingNotification());
}
export default onboardingslice.reducer;
