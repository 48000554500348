import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// our components
import NorowsData from './NorowsData'
import Notification from './Notification'
import Carrier from './Carrier'
import OrderStatus from './OrderStatus'
import Customer from './Customer'
import CarrierTracking from './CarrierTracking'

// mui components
import {
    Typography,
    FormControl,
    Pagination,
    Card,
    CardContent,
    Stack,
    Tooltip,
    IconButton,
    Button,
    Backdrop,
    CircularProgress,
    Drawer,
    Box,
    Divider,
    CardHeader,
    Paper
} from "@mui/material";
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

import {
    DataGrid,
    GridToolbarContainer
} from '@mui/x-data-grid';

import CloseIcon from '@mui/icons-material/Close';
import Date from './Date'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import { fetchInvoiceDetail, resetInvoiceNotifications } from '../../pages/Settings/Invoice/invoiceSlice';
import TrackingDrawer from '../../pages/General/TrackingDrawer';
import OrderDrawer from '../General/OrderDrawer';
import { fetchOrderDetail } from '../../pages/OrderDetail/orderdetailsSlice';
import { fetchTrackingDetail } from '../Reports/Deliveries/trackingEventsSlice';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    boxShadow: 'unset',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    color: theme.palette.text.secondary,
    height: '100%'
}));

const InvoiceDetailDrawer = ({ visible, invoiceId,invoiceNumber, invoiceDetailOnClose }) => {
    const dispatch = useDispatch();
    const invoicedetail = useSelector(state => state.invoice.invoiceDetail);
    const profile = useSelector(state => state.auth.profile);
    const [meta, setMeta] = useState(invoicedetail.meta);
    const [drawer, setDrawer] = useState(true);
    const [trackingDrawerVisible, setTrackingDrawerVisible] = useState(false);
    const [orderDrawerVisible, setOrderDrawerVisible] = useState(false);
    const [isMultiPiece, setIsMultiPiece] = useState(false)

    const columns = [{
        field: 'order_number',
        headerName: 'Order ID #',
        minWidth: 80,
        flex: 1,
        resizable: false, 
        renderCell: (params) => {
            return <span style={{ color: 'rgb(24, 144, 255)', cursor: 'pointer' }} onClick={e => { orderCallback(params.row.order_number) }}>{params.row.order_number}</span>

        }
    },
    {
        field: 'tracking_number',
        headerName: 'Tracking Number #',
        minWidth: 250,
        flex: 1,
        resizable: false, 
        renderCell: (params) => {
            let tracking_number = (params.row.tracking_number
                && typeof params.row.tracking_number !== "undefined" && params.row.tracking_number !== null)
                ? params.row.tracking_number : "";
            let carrier_name = (params.row.carrier_name
                && typeof params.row.carrier_name !== "undefined" && params.row.carrier_name !== null)
                ? params.row.carrier_name : "";
            let tracking = { "tracking_number": tracking_number, "carrier_name": carrier_name }
            return (
                <CarrierTracking trackingItems={tracking} dispatchCallback={trackingCallback} carrierImageWidth="60px" />
            )
        }
    },
    {
        field: 'receiver_name',
        headerName: 'Receiver Name',
        flex: 1,
        resizable: false, 
        renderCell: (params) => {
            return <Customer name={params.row.receiver_name} />
        }
    },
    {
        field: 'receiver_address1',
        headerName: 'Receiver Address',
        flex: 1,
        resizable: false, 
        renderCell: (params) => {
            let addr = ""
            if (params.row.receiver_address1 && typeof params.row.receiver_address1 !== "undefined" && params.row.receiver_address1 !== null) {
                addr = params.row.receiver_address1;
            }
            if (params.row.receiver_address2 && typeof params.row.receiver_address2 !== "undefined" && params.row.receiver_address2 !== null) {
                addr = addr + " " + params.row.receiver_address2
            }
            return addr
        }
    },
    {
        field: 'receiver_city',
        headerName: 'Receiver City',
        flex: 1,
        resizable: false, 
    },
    {
        field: 'receiver_postal_code',
        headerName: 'Receiver Postal Code',
        flex: 1,
        resizable: false, 
    },
    {
        field: 'from',
        headerName: 'From',
        flex: 1,
        resizable: false, 
    },
    {
        field: 'to',
        headerName: 'To',
        flex: 1,
        resizable: false, 
    },
    {
        field: 'pieces',
        headerName: 'Pieces',
        flex: 1,
        resizable: false, 
    },
    {
        field: 'weight',
        headerName: 'Bill Weight',
        width: 80,
        resizable: false, 
        renderCell: (params) => {
            if (params.row && params.row.total_weight && params.row.total_weight != "") {
                return params.row.total_weight
            } else {
                return params.row.weight
            }
        }
    },
    {
        field: 'date_of_shipping',
        headerName: 'Ship Date',
        flex: 1,
        resizable: false, 
        renderCell: (params) => {
            return <Date date={params.row.date_of_shipping} />
        }
    },
    {
        field: 'base_cost',
        headerName: 'Base Cost',
        flex: 1,
        resizable: false, 
    },
    {
        field: 'adjustment',
        headerName: 'Adjustment Cost',
        flex: 1,
        resizable: false, 
    },
    {
        field: 'tax',
        headerName: 'Tax',
        flex: 1,
        resizable: false, 
    },
    {
        field: 'total',
        headerName: 'Total',
        flex: 1,
        resizable: false
    },
    ]


    useEffect(() => {
        if (invoiceId !== '') {
            dispatch(fetchInvoiceDetail(invoiceId, meta))
        }
    }, [meta])

    useEffect(() => {
        setMeta({
            ...meta,
            ...{
                page: 1,
                per_page: 10,
            }
        });
    }, [dispatch, invoiceId])

    const ondrawerclose = () => {
        setDrawer(false)
        invoiceDetailOnClose(false)
    }

    const orderCallback = (item) => {
        dispatch(fetchOrderDetail(item));
        setOrderDrawerVisible(true)
    }

    const orderOnClose = () => {
        setOrderDrawerVisible(false);
    }

    const onExportClick = (invoice_id) => {
        let url = process.env.REACT_APP_API_DOMAIN + '/labelgen/invoice-shipments/export?invoice_id=' + invoice_id+'&invoice_number='+invoiceNumber;
        window.open(url, '_blank')
    }

    const trackingCallback = (carriername, trackingNumber) => {
        let apikey = (profile && profile.api_token) ? profile.api_token : "";
        const data = { "carrier_name": carriername, 'tracking_number': trackingNumber }
        dispatch(fetchTrackingDetail(data, apikey))
        setTrackingDrawerVisible(true)
    }
    const onClose = () => {
        setTrackingDrawerVisible(false);
    };

    const onSortChange = (currentSorting) => {
        setMeta({
            ...meta,
            ...{
                sorting: JSON.stringify(currentSorting)
            }
        });
    }

    const onChangePerPage = (event) => {
        setMeta({
            ...meta,
            ...{
                page: 1,
                per_page: event.target.value
            }
        });
    }

    const onChangePagination = (event, page) => {
        setMeta({
            ...meta,
            ...{
                page: page
            }
        });
    }

    function CustomTotalRows() {

        return (
            <div className="custom-total-rows">
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    Showing {invoicedetail && invoicedetail.data && invoicedetail.data.length} of {(invoicedetail && invoicedetail.meta && invoicedetail.meta.total) ? invoicedetail.meta.total : 0}
                </FormControl>
            </div>
        )
    }


    function CustomPagesizeoptions() {

        return (
            <div className="custom-page-size-options">
                <span>
                    Show <select
                        value={meta && meta.per_page}
                        onChange={onChangePerPage}
                    >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </select> entries
                </span>
            </div>
        )
    }

    function CustomToolbar() {

        return (
            <GridToolbarContainer>
                <CustomPagesizeoptions />
                <CustomTotalRows />
            </GridToolbarContainer>
        );
    }

    function CustomPagination() {

        return (
            <Pagination
                color="primary"
                shape="circular"
                page={invoicedetail && invoicedetail.meta && invoicedetail.meta.current_page}
                count={invoicedetail && invoicedetail.meta && invoicedetail.meta.last_page}
                onChange={onChangePagination}
            />
        );
    }

    return (
        <>
            <Drawer
                anchor="right"
                open={visible}
                onClose={ondrawerclose}
                PaperProps={{
                    sx: { width: "85%" },
                }}
            >
                {(invoicedetail && invoicedetail.notification && invoicedetail.notification.show && invoicedetail.notification.messageContent
                    && invoicedetail.notification.messageContent !== "") &&
                    <Notification
                        is_open={invoicedetail.notification.show}
                        messageType={invoicedetail.notification.messageType}
                        messages={invoicedetail.notification.messageContent}
                        handleClose={() => dispatch(resetInvoiceNotifications())}
                    />
                }
                <div style={{ padding: "0 30px" }}>
                    <Card sx={{ marginTop: "20px" }} className="sc--cardhead--details">
                        <div style={{ width: '100%', background: '#fff' }}>
                            <CardHeader title="Invoice Details" action={
                                <div>
                                    <Button
                                        variant="outlined"
                                        style={{ marginRight: "20px", cursor: 'pointer' }}
                                        onClick={() => onExportClick(invoiceId)}
                                    >
                                        <FileDownloadOutlinedIcon style={{ color: "#1890ff" }} /> Export
                                    </Button>
                                    <IconButton aria-label="settings" onClick={ondrawerclose}>
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                            }
                            />
                            <DataGrid
                                style={{ paddingTop: "37px" }}
                                getRowId={(row) => row.tracking_number+Math.random()}
                                rows={invoicedetail && invoicedetail.data}
                                columns={columns}
                                autoHeight={true}
                                disableColumnFilter={true}
                                disableColumnMenu={true}
                                sortingMode="server"
                                onSortModelChange={(newSortModel) => onSortChange(newSortModel)}
                                sortModel={meta && meta.sorting && JSON.parse(meta.sorting)}
                                disableRowSelectionOnClick
                                slots={{
                                    loadingOverlay: LinearProgress,
                                    pagination: CustomPagination,
                                    toolbar: CustomToolbar,
                                    NorowsData
                                }}
                                loading={
                                    (invoicedetail && invoicedetail.loading)
                                }
                            />
                        </div>
                    </Card>
                </div>
                <TrackingDrawer
                    visible={trackingDrawerVisible} isMultiPiece={isMultiPiece} onClose={onClose}
                />
                <OrderDrawer
                    visible={orderDrawerVisible} onClose={orderOnClose}
                />
            </Drawer >
        </>
    )
}

export default InvoiceDetailDrawer;