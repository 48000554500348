import { createSlice } from '@reduxjs/toolkit';
import { OMSAPI } from '../../../API';
import { LABELGENAPI, LABELINGCHIMP_SAM_API, LABELINGCHIMP_LABEL_API } from '../../../API';
import axios from 'axios';

/* canceling previous requests if multiple requests is triggered */
const CancelToken = axios.CancelToken;
let source = CancelToken.source();

export const addressbookslice = createSlice({
    name: 'addressbook',
    initialState: {
        getsearchvalues:{
            data: [],
            loading: false,
            hasErrors: false,
            showNotification: false,
            message:[]
        }
    },
    reducers: {
        getAddressbook: (state) => {
            return {
                ...state,
                getsearchvalues: {
                    loading: true,
                    hasErrors: false,
                    data: [],
                }
            }
        },
        getAddressbookSuccess: (state, action) => {
            return {
                ...state,
                getsearchvalues: {
                    loading: false,
                    hasErrors: false,
                    data: action && action.payload && action.payload.data && action.payload.data.data,
                }
                
            }
        },
        getAddressbookFailure: (state, action) => {
            return {
                ...state,
                getsearchvalues: {
                    loading: false,
                    hasErrors: true,
                    message: "",
                    showNotification: true
                }
            }
        }
    }
})

export const { getAddressbook, getAddressbookSuccess, getAddressbookFailure } = addressbookslice.actions;

export const fetchAddressBookDetails = (search_by,search_value) => {
    let url = 'settings/address-book-recipient';
    if(search_by != '' &&  search_by != undefined){
        url += '?search_by='+search_by;
    }
    if(search_value != undefined && search_value != ''){
        url += '&search_value='+search_value;
    }
    return (dispatch) => {
        dispatch(getAddressbook());
        try {
            source && source.cancel('Operation canceled due to new request.');
            source = axios.CancelToken.source();
            LABELGENAPI
                .get(url, {
                    cancelToken: source.token
                })
                .then(function (response) {
                    dispatch(getAddressbookSuccess(response));
                })
                .catch(function (error) {
                    dispatch(getAddressbookFailure(error.response));
                });
        } catch (error) {
            dispatch(getAddressbookFailure());
        }
    };
};
export default addressbookslice.reducer;
