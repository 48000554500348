import React from "react";
import Chip from '@mui/material/Chip';

const OrderStatus = ({ orderStatus = null, badgeClass = "badge-success" }) => {

    let bcolor = "#007bff"
    if (orderStatus != null && orderStatus != "") {
        if (orderStatus.toLowerCase() === "manifest closed") {
            bcolor = "#1e7e34"
        } else if (orderStatus.toLowerCase() === "order received" || orderStatus.toLowerCase() === "order created") {
            bcolor = "#17a2b8"
        } else if (orderStatus.toLowerCase() === "voided") {
            bcolor = "#d39e00"
        }else if (orderStatus === "Label Generation Failed"){
            bcolor = "rgb(229 75 86)"
        }else if (orderStatus === "Label Generated" || orderStatus === "Delivered"){
            bcolor = "rgb(52 128 24)"
        }
    }

    return (
        <>
            {!orderStatus && <span className="tb-lead">-</span>}
            {orderStatus && <div><Chip className="sc--orders--chip" label={orderStatus[0].toUpperCase() + orderStatus.slice(1)} style={{ color: "white", backgroundColor: bcolor }} /></div>}
        </>
    );
};

export default OrderStatus;
