import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LinearProgress from '@mui/material/LinearProgress';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { DataGrid } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Snackbar from '@mui/material/Snackbar';
import Tooltip from '@mui/material/Tooltip';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';

import SettingsStatus from '../../../pages/General/SettingsStatus'
import { fetchBoxData, destoryBoxData, storeBoxData, updateBoxData, resetBoxState } from './boxSlice';
import NorowsData from '../../../pages/General/NorowsData'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper'
};
const deleteModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};
const Modalpopup = ({ modalVisible, setModalVisible, formValues, setFormValues, snackbar }) => {
    const dispatch = useDispatch();


    const initialBoxValues = {
        box_name: '',
        box_length: '',
        box_length_unit: '',
        box_width: '',
        box_width_unit: '',
        box_height: '',
        box_height_unit: '',
        box_weight: '',
        box_weight_unit: '',
        box_active: true,
        box_default: false
    }

    const [values, setValues] = useState(formValues && Object.keys(formValues).length === 0 ? initialBoxValues : formValues);
    const [errors, setErrors] = useState({});



    useEffect(() => {
        if (formValues && Object.keys(formValues).length !== 0) {
            let tempValues = {
                box_name: formValues['box_name'],
                box_length: formValues['box_length'],
                box_length_unit: formValues['box_length_unit'],
                box_width: formValues['box_width'],
                box_width_unit: formValues['box_width_unit'],
                box_height: formValues['box_height'],
                box_height_unit: formValues['box_height_unit'],
                box_weight: formValues['box_weight'],
                box_weight_unit: formValues['box_weight_unit'],
                box_default: formValues['box_default'],
                box_active: formValues['box_active'],
                dim_unit: formValues['box_length_unit']
            };
            setValues(tempValues)
        }
    }, [formValues])

    const onModalClose = () => {
        setModalVisible(false)
        setValues(initialBoxValues)
        setErrors({})
        setFormValues(initialBoxValues)
    }


    const validator = (fieldValues) => {
        let temp = { ...errors }
        if ('box_name' in fieldValues)
            temp.box_name = fieldValues.box_name ? "" : "This field is required."
        if (fieldValues && fieldValues.box_name && fieldValues.box_name.length < 3)
            temp.box_name = "Box Name required minimum 3 characters"
        if ('box_length' in fieldValues)
            temp.box_length = fieldValues.box_length ? "" : "This field is required."
        if (fieldValues && fieldValues.box_length && fieldValues.box_length <= 0)
            temp.box_length = "Length is not a negative number or 0"
        if ('box_length_unit' in fieldValues)
            temp.dim_unit = fieldValues.dim_unit ? "" : "This field is required."
        if ('box_width' in fieldValues)
            temp.box_width = fieldValues.box_width ? "" : "This field is required."
        if (fieldValues && fieldValues.box_width && fieldValues.box_width <= 0)
            temp.box_width = "Width is not a negative number  or 0"
        if ('box_height' in fieldValues)
            temp.box_height = fieldValues.box_height ? "" : "This field is required."
        if (fieldValues && fieldValues.box_height && fieldValues.box_height <= 0)
            temp.box_height = "Height is not a negative number  or 0"
        if ('box_weight' in fieldValues)
            temp.box_weight = fieldValues.box_weight ? "" : "This field is required."
        if (fieldValues && fieldValues.box_weight && fieldValues.box_weight <= 0)
            temp.box_weight = "Weight is not a negative number  or 0"
        if ('box_weight_unit' in fieldValues)
            temp.box_weight_unit = fieldValues.box_weight_unit ? "" : "This field is required."

        setErrors({
            ...temp
        })

        if (fieldValues === values)
            return Object.values(temp).every(x => x == "")
    }

    const onInputChange = e => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
        validator({ [name]: value })
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: e.target.checked,
        });
        validator({ [name]: value })
    };

    const handleSubmit = e => {
        e.preventDefault()
        const isValid = validator(values)
        if (isValid) {
            let tempValues = values
            let lengthUpdate=values.box_length
            let widthUpdate=values.box_width
            let heightUpdate=values.box_height
            let weightUpdate=values.box_weight
            lengthUpdate=parseFloat(lengthUpdate).toFixed(2)
            widthUpdate=parseFloat(widthUpdate).toFixed(2)
            heightUpdate=parseFloat(heightUpdate).toFixed(2)
            weightUpdate=parseFloat(weightUpdate).toFixed(2)
            values.box_length=lengthUpdate
            values.box_width=widthUpdate
            values.box_height=heightUpdate
            values.box_weight=weightUpdate
            tempValues["box_height_unit"] = tempValues["dim_unit"];
            tempValues["box_length_unit"] = tempValues["dim_unit"];
            tempValues["box_width_unit"] = tempValues["dim_unit"];
            if ("_id" in formValues) {
                dispatch(updateBoxData(formValues._id, tempValues))
                onModalClose()
                snackbar(true)
            } else {
                dispatch(storeBoxData(tempValues))
                onModalClose()
                snackbar(true)
            }
        }

    }

    return (
        <Modal
            open={modalVisible}
            onClose={onModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Card sx={{ minWidth: 600 }} className="sc--cardhead--details">
                    <CardHeader title='Box Dimensions' />
                    <Divider />
                    <CardContent>
                        <div className="sc--form--center" style={{ margin: '20px 0' }}>
                            <TextField
                                label="Box Name"
                                name="box_name"
                                defaultValue={formValues && formValues.box_name}
                                onChange={onInputChange}
                                error={errors.box_name}
                                helperText={errors.box_name}
                                style={{ width: "100%", margin: '8px' }}
                                variant="standard"
                            />

                        </div>
                        <div className="sc--form--center" style={{ margin: '20px 0' }}>
                            <TextField
                                label="Length"
                                type='number'
                                name="box_length"
                                defaultValue={formValues && formValues.box_length}
                                onChange={onInputChange}
                                error={errors.box_length}
                                helperText={errors.box_length}
                                variant="standard" style={{ width: "100%", margin: '8px' }}
                            />

                            <TextField
                                label="Width"
                                name="box_width"
                                type='number'
                                defaultValue={formValues && formValues.box_width}
                                onChange={onInputChange}
                                error={errors.box_width}
                                helperText={errors.box_width}
                                variant="standard" style={{ width: "100%", margin: '8px' }}
                            />
                        </div>
                        <div className="sc--form--center" style={{ margin: '20px 0' }}>
                               <TextField
                                label="Height"
                                name="box_height"
                                type='number'
                                defaultValue={formValues && formValues.box_height}
                                onChange={onInputChange}
                                error={errors.box_height}
                                helperText={errors.box_height}
                                variant="standard" style={{ width: "100%", margin: '8px' }}
                            />
                            <FormControl
                                variant="standard"
                                className="help-text-error"
                                style={{ width: "100%", margin: '8px' }}
                            >
                                <InputLabel id="demo-simple-select-standard-label">Dimension Unit</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-error"
                                    name="dim_unit"
                                    defaultValue={formValues && formValues.box_length_unit}
                                    error={errors.dim_unit}
                                    onChange={onInputChange}
                                    label="Length Unit"
                                >
                                    <MenuItem value={"in"}>in</MenuItem>
                                    <MenuItem value={"cm"}>cm</MenuItem>

                                </Select>
                                <FormHelperText>{errors.dim_unit}</FormHelperText>
                            </FormControl>
                        </div>
                        <div className="sc--form--center" style={{ margin: '20px 0' }}>
                            <TextField
                                label="Weight"
                                name="box_weight"
                                type='number'
                                defaultValue={formValues && formValues.box_weight}
                                onChange={onInputChange}
                                error={errors.box_weight}
                                helperText={errors.box_weight}
                                variant="standard" style={{ width: "100%", margin: '8px' }}
                            />
                            <FormControl variant="standard" className="help-text-error" style={{ width: "100%", margin: '8px' }} >
                                <InputLabel id="demo-simple-select-standard-label">Weight Unit</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-error"
                                    name="box_weight_unit"
                                    defaultValue={formValues && formValues.box_weight_unit}
                                    onChange={onInputChange}
                                    error={errors.box_weight_unit}
                                    label="Weight Unit"
                                >
                                    <MenuItem value={"kg"}>kg</MenuItem>
                                    <MenuItem value={"lb"}>lb</MenuItem>


                                </Select>
                                <FormHelperText>{errors.box_weight_unit}</FormHelperText>
                            </FormControl>
                        </div>

                        <div style={{ margin: '20px 0' }}>
                            <FormGroup>
                                <FormControlLabel control={<Switch defaultChecked={formValues && formValues.box_default} name="box_default" onChange={handleChange} />} label="Default" />
                            </FormGroup>
                        </div>

                        <div className="sc--form--center" style={{ margin: '30px 0' }}>
                            <Button variant="outlined" onClick={handleSubmit}>Submit</Button>
                        </div>
                    </CardContent>
                </Card>
            </Box>
        </Modal >
    )
}
const BoxPage = () => {
    const dispatch = useDispatch();
    const box = useSelector(state => state.box);
    const [alert, setAlert] = useState(false);
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [deleteid, setDeleteId] = React.useState("");
    const [deleteModalopen, setDeleteModalopen] = React.useState(false);
    const [boxDimensions, setboxDimensions] = useState([]);
    useEffect(() => {
        dispatch(fetchBoxData());
    }, [dispatch]);

    useEffect(() => {
        if (box && box.message) {
            setAlert(true)
            setSnackOpen(true)
        }
        let myArray = box && box.data.filter(function( obj ) {
             return  obj.hide !== true;
          });
          setboxDimensions(myArray)

    }, [box]);

    const handleClick = (id) => {
        setDeleteId(id)
        setDeleteModalopen(true);
    };
    const deleteModalClose = () => {
        setDeleteModalopen(false);
    };

    const deletebox = (id) => {
        dispatch(destoryBoxData(id))
        setAlert(true)
        setSnackOpen(true)
        setDeleteModalopen(false);
    }
    const snackhandleClose = () => {
        setSnackOpen(false)
        setAlert(false)
        dispatch(resetBoxState())
    };
    const snackbar = (value) => {
        setSnackOpen(value)
    }

    const columns = [
        { field: 'box_name', headerName: 'Box Name', flex:1 },
        { field: 'box_length', headerName: 'Length', flex:1 },
        { field: 'box_width', headerName: 'Width', flex:1},
        { field: 'box_height', headerName: 'Height', flex:1 },
        { field: 'box_length_unit', headerName: 'Dimension Unit', flex:1},
        { field: 'box_weight', headerName: 'Weight', flex:1},
        {
            field: 'box_active',
            headerName: 'Active',
            flex:1,
            renderCell: (params) => {
                return <SettingsStatus settingsStatus={params.row.box_active} />
            }
        },
        {
            field: 'box_default',
            headerName: 'Default',
            flex:1,
            renderCell: (params) => {
                return <SettingsStatus settingsStatus={params.row.box_default} />
            }
        },
        {
            field: 'action',
            headerName: 'Action',
            flex:1,
            renderCell: (params) => {
                return <div>
                    <span style={{ marginRight: '10px' }}>
                        <Tooltip title="Edit" placement="left-start">
                            <IconButton>
                                <EditIcon style={{ color: 'rgba(0,0,0,.54)', cursor: 'pointer' }} onClick={() => {
                                    setSelectedItem(params.row);
                                    setModalVisible(true)
                                }} />
                            </IconButton>
                        </Tooltip>
                    </span>
                    <span>
                        <Tooltip title="Delete" placement="left-start">
                            <IconButton>

                                <DeleteOutlineOutlinedIcon style={{ color: 'rgba(0,0,0,.54)', cursor: 'pointer' }}
                                    onClick={(e) => {
                                        handleClick(params.row._id)
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                    </span>
                </div>
            }
        }
    ];


    return (
        <div className="sc--settings--card">
            <Modalpopup
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}
                formValues={selectedItem}
                setFormValues={setSelectedItem}
                snackbar={snackbar}
            />

            <Modal
                open={deleteModalopen}
                onClose={deleteModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={deleteModalStyle}>
                    <Typography sx={{ p: 2 }}>Are you sure want to delete this box dimensions?<br />
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <span style={{ margin: '0 10px' }}>
                                <Button
                                    variant="contained"
                                    style={{ margin: "10px 0" }}
                                    onClick={(event) => {
                                        deletebox(deleteid)
                                    }}
                                >
                                    Yes
                                </Button>
                            </span>
                            <span>
                                <Button
                                    variant="outlined"
                                    style={{ margin: "10px 0" }}
                                    onClick={deleteModalClose}
                                >
                                    No
                                 </Button>
                            </span>
                        </div>
                    </Typography>
                </Box>
            </Modal>
            {alert && (box && box.loading === false) &&
                <Snackbar
                    open={snackOpen}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    autoHideDuration={6000}
                    onClose={snackhandleClose}
                >
                    <Alert
                        severity={box && box.hasErrors === true ? "error" : "success"}
                        style={{ justifyContent: 'center', margin: "10px 0" }}
                    >
                        <span >{box && box.message}</span>
                    </Alert>
                </Snackbar>
            }
            <Card sx={{ width: '100%', boxShadow: 'unset' }} className="sc--cardhead--details">
                <CardHeader title='Box Dimensions' className="sc--cardhead--details" action={
                    <div>
                        <Button variant="contained" onClick={() => { setModalVisible(true) }}>Add Box</Button>
                    </div>}
                />
                <Divider />
                <CardContent>
                    <div style={{ width: '100%'}}>
                        <DataGrid
                            getRowId={(row) => row._id}
                            disableRowSelectionOnClick={true}
                            rows={boxDimensions}
                            columns={columns}
                            autoHeight={true}
                            slots={{
                                loadingOverlay: LinearProgress,
                                NorowsData
                            }}
                            hideFooter={true}
                            loading={box && box.loading}
                        />
                    </div>
                </CardContent>
            </Card>
        </div>
    )
}


export default BoxPage;
