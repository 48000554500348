import React from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import CarrierTracking from '../../pages/General/CarrierTracking'
const MultipleTracking = ({ dispatchCallback, trackingItems = [], carrierImageWidth = null, moreCallBack , orderData = null,is_multi_piece}) => {
    delete trackingItems["raw_data"] // for deleting invalid raw_data if exists
    const initiateTracking = (carrier_name, tracking_number, message = null,is_multi_piece) => {
        if (typeof dispatchCallback === 'function') {
            dispatchCallback(carrier_name, tracking_number, message,is_multi_piece)
        } else {
            console.log("Dispatch Call Back Function is not valid");
        }
    }
    let row = 1;
    let prevTrackingNumbers = [];
    let distinctTrackingNumbers = [];
    let message = null;
    // iterate shipment object
    if(orderData && orderData.shipment && orderData.shipment.tracking && orderData.shipment.tracking.length != 0){
        message = null
        for (var key of Object.keys(orderData.shipment.tracking)) {
            if (!prevTrackingNumbers.includes(key) &&  orderData.shipment.tracking[key] && orderData.shipment.tracking[key].label && typeof orderData.shipment.tracking[key].label.is_void_in_progress === "undefined") {
                if(orderData && orderData.shipment && orderData.shipment.tracking && orderData.shipment.tracking[key] &&
                    orderData.shipment.tracking[key].manifest && orderData.shipment.tracking[key].manifest.is_closed === false){
                        message = "This shipment manifest is not yet closed"
                }
                let carrier = (orderData && orderData.shipment && orderData.shipment.tracking && orderData.shipment.tracking[key] &&
                    orderData.shipment.tracking[key].label && orderData.shipment.tracking[key].label.carrier_name &&
                    orderData.shipment.tracking[key].label.carrier_name != "" && orderData.shipment.tracking[key].label.carrier_name !== null)
                    ? orderData.shipment.tracking[key].label.carrier_name : ""
                prevTrackingNumbers.push(key)
                distinctTrackingNumbers.push({ "tracking_number": key, "carrier_name": carrier , "message": message})
            }
        }
    }else{
        trackingItems && Array.from(trackingItems).map((text, item) => {
            let tracking_numbers = []
            let carrier_name = (text.fulfilment_company && text.fulfilment_company != "" && text.fulfilment_company !== null && text.fulfilment_company.trim() != "") ? text.fulfilment_company : ""
            if (text && typeof text.tracking_numbers !== "undefined" && text.tracking_numbers.length != 0) {
                tracking_numbers = text.tracking_numbers
            } else if (text && typeof text.tracking_number !== "undefined") {
                tracking_numbers = [text.tracking_number]
            }
            tracking_numbers.forEach(function (item, index) {
                if (!prevTrackingNumbers.includes(item)) {
                    if(orderData && orderData.shipment && orderData.shipment.tracking && orderData.shipment.tracking[item] &&
                        orderData.shipment.tracking[item].manifest && orderData.shipment.tracking[item].manifest.is_closed === false){
                            message = "This shipment manifest is not yet closed"
                    }
                    prevTrackingNumbers.push(item)
                    distinctTrackingNumbers.push({ "tracking_number": item, "carrier_name": carrier_name , "message": message})
                }
            });
        })
    }
    return (
        <>
            <div>
                {distinctTrackingNumbers && Array.from(distinctTrackingNumbers).map((text, item) => (
                    <>{row <= 2 && <div key={row++}>
                        <span className="tb-lead">
                            <div className={`user-card`} style={{ display: 'flex', alignItems: 'center' }}>
                                <CarrierTracking trackingItems={text} carrierImageWidth={carrierImageWidth} dispatchCallback={initiateTracking} message = {text.message} is_multi_piece={is_multi_piece}></CarrierTracking>
                                <span>{(row == 2 || distinctTrackingNumbers.length > 1) && <Tooltip title="More"><IconButton onClick={moreCallBack} style={{ cursor: "pointer"}}><MoreVertIcon /></IconButton></Tooltip>}</span>
                            </div>
                        </span>

                    </div>
                    }
                    </>
                ))}
            </div>
        </>
    );
};

export default MultipleTracking;
