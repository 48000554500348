import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import 'moment-timezone';
import { fetchProfile } from '../Settings/Profile/profileSlice';

const Date = ({ date = null }) => {
    const dispatch = useDispatch();
    const profile = useSelector(state => state.profile);
    if (typeof date == "object" && date != null && date != ""){
        if(profile && profile.profile && profile.profile.time_zone && profile.profile.time_zone!==''){
            return moment.tz(parseInt(date.$date.$numberLong),profile.profile.time_zone).format('YYYY-MM-DD HH:mm:ss')
        }else{
            return moment(parseInt(date.$date.$numberLong)).format('YYYY-MM-DD HH:mm:ss')
        }
    }else if(typeof date == "string" && date != null && date != ""){
        if(profile && profile.profile && profile.profile.time_zone && profile.profile.time_zone!==''){
            return moment.tz(date,profile.profile.time_zone).format('YYYY-MM-DD HH:mm:ss')
        }else{
            return moment(date).format('YYYY-MM-DD HH:mm:ss')
        }
    }else{
        return date
    }
};

export default Date;
