import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import Modal from '@mui/material/Modal';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import IntegrationCarrier from '../../../../pages/General/IntegrationCarrier';

import { fetchCarrierDetail, storeCarrier, updateCarrier } from '../carrierCredentialsSlice';
import { fetchCountry, fetchState } from '../../../../pages/General/countryStateCitySlice';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    // bgcolor: 'background.paper'
};

const Canadapostmodal = ({ canadapostOpen, setCanadapostOpen, formValues, setFormValues, snackbar }) => {
    const dispatch = useDispatch();
    const [country, setCountry] = useState(['ALL']);
    const [state, setState] = useState(['ALL']);
    const [postal, setPostal] = useState(['ALL']);
    const initialCanadapostValues = {
        username: '',
        carrier_name: "CANADAPOST",
        contract_number: '',
        customer_number: '',
        environment: '',
        name: '',
        paid_by_customer: '',
        password: '',
        app_enabled: false,
        api_enabled: false,
        quick_ship: false,
        pickup: false

    }
    const [values, setValues] = useState(formValues && Object.keys(formValues).length === 0 ? initialCanadapostValues : formValues);
    const [errors, setErrors] = useState({});

    const onModalCanadapostClose = () => {
        setCanadapostOpen(false)
        setValues(initialCanadapostValues)
        setCountry(['ALL'])
        setPostal(['ALL'])
        setState(['ALL'])
        setErrors({})
        setFormValues(initialCanadapostValues)
    }

    useEffect(() => {
        if (formValues && Object.keys(formValues).length !== 0) {
            let tempValues = {
                username: formValues['username'],
                carrier_name: formValues['carrier_name'],
                contract_number: formValues['contract_number'],
                customer_number: formValues['customer_number'],
                environment: formValues['environment'],
                name: formValues['name'],
                paid_by_customer: formValues['paid_by_customer'],
                password: formValues['password'],
                app_enabled: formValues['app_enabled'],
                api_enabled: formValues['api_enabled'],
                quick_ship: formValues['quick_ship'],
                pickup: formValues['pickup']
            };
            setValues(tempValues)
        }
    }, [formValues])

    const validator = (fieldValues) => {
        let temp = { ...errors }
        if ('username' in fieldValues)
            temp.username = fieldValues.username ? "" : "This field is required."
        if ('carrier_name' in fieldValues)
            temp.carrier_name = fieldValues.carrier_name ? "" : "This field is required."
        if ('contract_number' in fieldValues)
            temp.contract_number = fieldValues.contract_number ? "" : "This field is required."
        if ('customer_number' in fieldValues)
            temp.customer_number = fieldValues.customer_number ? "" : "This field is required."
        if ('environment' in fieldValues)
            temp.environment = fieldValues.environment ? "" : "This field is required."
        if ('name' in fieldValues)
            temp.name = fieldValues.name ? "" : "This field is required."
        if ('paid_by_customer' in fieldValues)
            temp.paid_by_customer = fieldValues.paid_by_customer ? "" : "This field is required."
        if ('password' in fieldValues)
            temp.password = fieldValues.password ? "" : "This field is required."
        if (country)
            temp.country = country.length !== 0 ? "" : "This field is required."
        if (state)
            temp.state = state.length !== 0 ? "" : "This field is required."
        if (postal && errors.postal === '' || !errors.postal)
            temp.postal = postal.length !== 0 ? "" : "This field is required."


        setErrors({
            ...temp
        })

        if (fieldValues == values) {
            return Object.values(temp).every(x => x == "")
        }
    }

    const onInputChange = e => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
        validator({ [name]: value })
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: e.target.checked,
        });
        validator({ [name]: value })
    };

    const dispatchCountryCallback = (item) => {
        setCountry(item)
        setErrors({ "countryCode": "" })
    }
    const dispatchStateCallback = (item) => {
        setState(item)
        setErrors({ "province": "" })
    }
    const dispatchPostalCodeCallback = (item) => {
        let error = false
        item.forEach(function (item) {
            if (item.match(/[^0-9a-z]/i) && (errors.postal === undefined || errors.postal === "" || errors.postal)) {
                error = true
                setErrors({ "postal": "Postal code only accepts alpha numeric" })
            } else if (errors.postal !== '' && error === false) {
                setErrors({ "postal": "" })
            }
        })
        setPostal(item)
    }

    const handleSubmit = e => {
        e.preventDefault()
        const isValid = validator(values)
        if (isValid && !errors.postal && !errors.state && !errors.country) {
            let dm = ""
            dm = {
                "carrier_name": "CANADAPOST",
                "carrier_preferences": {
                    "country_code": country,
                    "province_code": state,
                    "postal_code": postal
                }
            }

            if ("_id" in formValues) {
                const mergeobjectdata = Object.assign(values, dm);
                dispatch(updateCarrier(formValues._id, mergeobjectdata))
                onModalCanadapostClose()
                snackbar(true)
            } else {
                const mergeobjectdata = Object.assign(values, dm);
                dispatch(storeCarrier(mergeobjectdata))
                onModalCanadapostClose()
                snackbar(true)
            }
        }

    }
    useEffect(() => {
        dispatch(fetchCountry());
    }, [dispatch])
    return (
        <Modal
            open={canadapostOpen}
            onClose={onModalCanadapostClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Card sx={{ minWidth: 600 }} className="sc--cardhead--details">
                    <CardHeader title='Carrier Details' />
                    <Divider />
                    <CardContent>
                        <div className="sc--form--center" style={{ margin: '20px 0' }}>
                            <TextField
                                id="standard-numbe"
                                label="Carrier"
                                value="CANADAPOST"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    readOnly: true,
                                }}
                                style={{ width: "100%", margin: '8px' }}
                                variant="filled"
                            />
                            <TextField
                                label="Name"
                                name="name"
                                defaultValue={formValues && formValues.name}
                                onChange={onInputChange}
                                error={errors.name}
                                helperText={errors.name}
                                inputProps={{ maxLength: 40 }}
                                style={{ width: "100%", margin: '8px' }}
                                variant="standard"

                            />
                        </div>
                        <div className="sc--form--center" style={{ margin: '20px 0' }}>
                            <FormControl variant="standard" className="help-text-error" style={{ width: "100%", margin: '8px' }} >
                                <InputLabel id="demo-simple-select-standard-label">Environment</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-error"
                                    name="environment"
                                    onChange={onInputChange}
                                    defaultValue={formValues && formValues.environment}
                                    error={errors.environment}
                                    label="Environment"
                                >
                                    <MenuItem value="production">Production</MenuItem>
                                    <MenuItem value="development">Development</MenuItem>
                                </Select>
                                <FormHelperText>{errors.environment}</FormHelperText>
                            </FormControl>
                            <TextField
                                label="Username"
                                name="username"
                                defaultValue={formValues && formValues.username}
                                onChange={onInputChange}
                                error={errors.username}
                                helperText={errors.username}
                                inputProps={{ maxLength: 40 }}
                                style={{ width: "100%", margin: '8px' }}
                                variant="standard"

                            />
                        </div>
                        <div className="sc--form--center" style={{ margin: '20px 0' }}>
                            <TextField
                                label="Password"
                                name="password"
                                defaultValue={formValues && formValues.password}
                                onChange={onInputChange}
                                error={errors.password}
                                helperText={errors.password}
                                inputProps={{ maxLength: 40 }}
                                style={{ width: "100%", margin: '8px' }}
                                variant="standard"

                            />
                            <TextField
                                label="Customer number"
                                name="customer_number"
                                defaultValue={formValues && formValues.customer_number}
                                onChange={onInputChange}
                                error={errors.customer_number}
                                helperText={errors.customer_number}
                                inputProps={{ maxLength: 40 }}
                                style={{ width: "100%", margin: '8px' }}
                                variant="standard"

                            />
                        </div>
                        <div className="sc--form--center" style={{ margin: '20px 0' }}>
                            <TextField
                                label="Contract number"
                                name="contract_number"
                                defaultValue={formValues && formValues.contract_number}
                                onChange={onInputChange}
                                error={errors.contract_number}
                                helperText={errors.contract_number}
                                inputProps={{ maxLength: 40 }}
                                style={{ width: "100%", margin: '8px' }}
                                variant="standard"

                            />
                            <TextField
                                label="Paid by customer"
                                name="paid_by_customer"
                                defaultValue={formValues && formValues.paid_by_customer}
                                onChange={onInputChange}
                                error={errors.paid_by_customer}
                                helperText={errors.paid_by_customer}
                                inputProps={{ maxLength: 40 }}
                                style={{ width: "100%", margin: '8px' }}
                                variant="standard"

                            />
                        </div>
                        <IntegrationCarrier
                            errors={errors}
                            values={values}
                            dispatchCountryCallback={dispatchCountryCallback}
                            dispatchStateCallback={dispatchStateCallback}
                            dispatchPostalCodeCallback={dispatchPostalCodeCallback}
                            newCarrierFormData={formValues}
                        />

                        <div className="sc--form--center" style={{ margin: '20px 0' }}>
                            <FormGroup style={{ width: "25%" }}>
                                <FormControlLabel control={<Switch defaultChecked={formValues && formValues.app_enabled} name="app_enabled" onChange={handleChange} />} label="App usage" />
                            </FormGroup>
                            <FormGroup style={{ width: "25%" }}>
                                <FormControlLabel control={<Switch defaultChecked={formValues && formValues.api_enabled} name="api_enabled" onChange={handleChange} />} label="API usage" />
                            </FormGroup>
                            <FormGroup style={{ width: "25%" }} >
                                <FormControlLabel control={<Switch defaultChecked={formValues && formValues.quick_ship} name="quick_ship" onChange={handleChange} />} label="Quick Ship" />
                            </FormGroup>
                            <FormGroup style={{ width: "25%" }} >
                                <FormControlLabel control={<Switch defaultChecked={formValues && formValues.pickup} name="pickup" onChange={handleChange} />} label="Pickup" />
                            </FormGroup>
                        </div>
                        <div className="sc--form--center" style={{ margin: '30px 0' }}>
                            <Button variant="outlined" onClick={handleSubmit}>Submit</Button>
                        </div>
                    </CardContent>
                </Card>
            </Box>

        </Modal >
    )
}
export default Canadapostmodal;
