import { createSlice } from '@reduxjs/toolkit';
import { OMSAPI } from '../../../../../API';
import { fetchIntegratedPlatform } from '../../integratedSlice';

export const etsyslice = createSlice({
    name: 'etsy',
    initialState: {
        progress: {
            data: {}, 
            message:"",
            loading: false,
            hasErrors: false
        },
        configure: {
            data: {},          
            message:"",
            loading: false,
            hasErrors: false,
            notification: { show: false, messageContent: "", messageType: "success" }
        },
        showdata: {
            data: {},          
            message:"",
            loading: false,
            hasErrors: false,
            notification: { show: false, messageContent: "", messageType: "success" }
        },
    },
    reducers: {
        fetchEtsyDataDetails: (state) => {
            return {
                ...state,
                showdata: {
                    loading: true
                }
            }
        },
        fetchEtsyDataDetailsSuccess: (state, action) => {
            return {
                ...state,
                showdata: {
                    data: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data,
                    loading: false,
                    hasErrors: false,
                }
            }
        },
        fetchEtsyDataDetailsFailure: (state, action) => {
            return {
                ...state,
                showdata: {
                    loading: false,
                    hasErrors: false,
                }
            }
        },
        fetchProgress: (state) => {
            return {
                ...state,
                progress: {
                    loading: true
                }
            }
        },
        fetchProgressSuccess: (state, action) => {
            return {
                ...state,
                progress: {
                    data: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data,
                    loading: false,
                    hasErrors: false,
                }
            }
        },
        fetchProgressFailure: (state, action) => {
            return {
                ...state,
                progress: {
                    loading: false,
                    hasErrors: false,
                }
            }
        },
        postEtsyConfigure: (state) => {
            return {
                ...state,
                configure: {
                    loading: true
                }
            }
        },
        postEtsyConfigureSuccess: (state, action) => {
            return {
                ...state,
                configure: {
                    data:{},
                    notification: { show: true, messageContent: [action && action.payload && action.payload.data && action.payload.data.messages], messageType: "success" },
                    loading: false,
                    hasErrors: false,
                }
            }
        },
        postEtsyConfigureFailure: (state, action) => {
            return {
                ...state,
                configure: {
                    notification: { show: true, messageContent: [action && action.payload && action.payload.data && action.payload.data.messages], messageType: "error" },
                    loading: false,
                    hasErrors: true,
                }
            }
        },
        resetConfigureStateDetails: (state, action) => {
            return {
                ...state,
                configure: {
                    data: {},
                    message:'',
                    loading: false,
                    hasErrors: false,
                }
            }
        },
        resetEtsyStateDetails: (state, action) => {
            return {
                ...state,
                configure: {
                    notification: { show: false, messageContent: "", messageType: "success" },
                    loading: false,
                    hasErrors: false,
                }
            }
        },
    }
})
export const { fetchEtsyDataDetails, fetchEtsyDataDetailsSuccess, fetchEtsyDataDetailsFailure,
    fetchProgress, fetchProgressSuccess, fetchProgressFailure,
    postEtsyConfigure, postEtsyConfigureSuccess, postEtsyConfigureFailure, resetConfigureStateDetails, resetEtsyStateDetails } = etsyslice.actions;

export const configureStore = data => dispatch => {
    dispatch(postEtsyConfigure());
    try {
        OMSAPI.post('/etsy/configure', data)
            .then(function (response) {
                dispatch(postEtsyConfigureSuccess(response));
                dispatch(fetchIntegratedPlatform());           
            }).catch(function (error) {
                dispatch(postEtsyConfigureFailure(error.response));
            })
    }
    catch (error) {
        dispatch(postEtsyConfigureFailure(error.response));
    }
}

export const fetchProgressData = data => dispatch => {   
    dispatch(fetchProgress());
    try {
        OMSAPI.get('/etsy/progress')
            .then(function (response) {
                dispatch(fetchProgressSuccess(response));
            }).catch(function (error) {
                dispatch(fetchProgressFailure());
            })
    }
    catch (error) {
        dispatch(fetchProgressFailure())
    }
}


export const fetchEtsyData = data => dispatch => {

    dispatch(fetchEtsyDataDetails())
    try {
        OMSAPI.post('/etsy/show', data)
            .then(function (response) {
                dispatch(fetchEtsyDataDetailsSuccess(response));
            }).catch(function (error) {
                dispatch(fetchEtsyDataDetailsFailure());
            })
    }
    catch (error) {
        dispatch(fetchEtsyDataDetailsFailure())
    }
}

export const resetConfigureState = data => dispatch => {    
    dispatch(resetConfigureStateDetails());
}

export const resetEtsyState = data => dispatch => {    
    dispatch(resetEtsyStateDetails());
}
export default etsyslice.reducer;