import { createSlice } from '@reduxjs/toolkit';
import { LABELGENAPI} from '../../../API';

export const timezoneslice = createSlice({
    name: 'timezone',
    initialState: {
        data: [],
        meta: [],
        loading: false,
        hasErrors: false,
        notification: { show: false, messageContent: "", messageType: "success" }
    },
    reducers: {
        getTimezoneData: (state) => {
            return {
                ...state,
                loading: true,
                hasErrors: false
            }
        },
        getTimezoneDataSuccess: (state, action) => {
            return {
                ...state,
                data: action && action.payload && action.payload.data && action.payload.data.data,
                notification: { show: true, messageContent: [action && action.payload && action.payload.data && action.payload.data.message], messageType: "success" },
                hasErrors: false,
                loading: false
            }
        },
        getTimezoneDataFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                hasErrors: true,
                notification: { show: true, messageContent: [action && action.payload && action.payload.data && action.payload.data.message], messageType: "error" }
            }
        },
        postTimezoneData: (state) => {
            return {
                ...state,
                loading: true,
                hasErrors: false
            }
        },
        postTimezoneDataSuccess: (state, action) => {
            return {
                ...state,
                hasErrors: false,
                loading: false,
                notification: { show: true, messageContent: [action && action.payload && action.payload.data && action.payload.data.message], messageType: "success" }
            }
        },
        postTimezoneDataFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                hasErrors: true,
                notification: { show: true, messageContent: [action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.message], messageType: "error" }
            }
        },
        putTimezoneData: (state) => {
            return {
                ...state,
                loading: true,
                hasErrors: false
            }
        },
        putTimezoneDataSuccess: (state, action) => {
            return {
                ...state,
                hasErrors: false,
                loading: false,
                notification: { show: true, messageContent: [action && action.payload && action.payload.data && action.payload.data.message], messageType: "success" }
            }
        },
        putTimezoneDataFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                hasErrors: true,
                notification: { show: true, messageContent: [action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.message], messageType: "error" }
            }
        },
        deleteTimezoneData: (state) => {
            return {
                ...state,
                loading: true,
                hasErrors: false
            }
        },
        deleteTimezoneDataSuccess: (state, action) => {
            return {
                ...state,
                hasErrors: false,
                loading: false,
                notification: { show: true, messageContent: [action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.message], messageType: "success" }
            }
        },
        deleteTimezoneDataFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                hasErrors: true,
                notification: { show: true, messageContent: [action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.message], messageType: "error" }
            }
        },

        resetTimezone: (state) => {
            return {
                ...state,
                loading: false,
                hasError: false,
                notification: { show: false, messageContent: "", messageType: "success" }
            }
        },


    }
})
export const { getTimezoneData, getTimezoneDataSuccess, getTimezoneDataFailure, postTimezoneData, postTimezoneDataSuccess, postTimezoneDataFailure,
    putTimezoneData, putTimezoneDataSuccess, putTimezoneDataFailure,
    deleteTimezoneData, deleteTimezoneDataSuccess, deleteTimezoneDataFailure,resetTimezone } = timezoneslice.actions;


export const fetchTimezoneData = () => dispatch => {
    dispatch(getTimezoneData());
    try {
        LABELGENAPI
            .get("/settings/timezone-settings")
            .then(function (response) {
                dispatch(getTimezoneDataSuccess(response));
            })
            .catch(function (error) {
                dispatch(getTimezoneDataFailure(error.response));
            });
    } catch (error) {
        dispatch(getTimezoneDataFailure());
    }
};

export const storeTimezoneData = data => dispatch => {
    dispatch(postTimezoneData());
    try {
        LABELGENAPI.post('/settings/timezone-settings', data)
            .then(function (response) {
                dispatch(postTimezoneDataSuccess(response));
                dispatch(fetchTimezoneData());
            }).catch(function (error) {
                dispatch(postTimezoneDataFailure(error.response));
            })
    }
    catch (error) {
        dispatch(postTimezoneDataFailure(error.response));
    }
}

export const resetTimezoneState = data => dispatch => {
    dispatch(resetTimezone());
}

export default timezoneslice.reducer;
