import { createSlice } from '@reduxjs/toolkit';
import { LABELGENAPI } from '../../API';

export const dashboardslice = createSlice({
    name: 'dashboard',
    initialState: {
        labelsearch:{
            label: "",
            message: "",
            loading: false,
            hasErrors: false
        }

    },
    reducers: {
        fetchLabel: (state) => {
            return {
                ...state,
                labelsearch: {
                    loading: true
                }
            }
        },
        fetchLabelSuccess: (state, action) => {
            return {
                ...state,
                labelsearch: {
                    label: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data,
                    message: "",
                    loading: false,
                    hasErrors: false
                }
            }
        },
        fetchLabelFailure: (state, action) => {
            return {
                ...state,
                labelsearch: {
                    label: "",
                    message: action && action.payload && action.payload.data && action.payload.data.message,
                    loading: false,
                    hasErrors: true
                }
            }
        },
        resetLabel: (state, action) => {
            return {
                ...state,
                labelsearch: {
                    label: "",
                    message: "",
                    loading: false,
                    hasErrors: false
                }
            }
        }
    }
})

export const { fetchLabel, fetchLabelSuccess, fetchLabelFailure, resetLabel } = dashboardslice.actions;

export const fetchLabelData = meta => dispatch => {
    dispatch(fetchLabel())
    try {
        const qs = Object.keys(meta)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(meta[key])}`)
        .join('&');
        LABELGENAPI.get('/dashboard/fetch-label?'+qs)
            .then(function (response) {
                dispatch(fetchLabelSuccess(response));
            }).catch(function (error) {
                dispatch(fetchLabelFailure(error.response));
            })
    }
    catch (error) {
        dispatch(fetchLabelFailure(error.response))
    }
}

export const resetLabelData = data => dispatch => {
    dispatch(resetLabel());
}
export default dashboardslice.reducer;