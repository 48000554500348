import { createSlice } from '@reduxjs/toolkit';
import { OMSPROFILE } from '../../API';
import { LABELGENAPI, LABELINGCHIMP_SAM_API, LABELINGCHIMP_LABEL_API } from '../../API';

export const bulkprintlabelslice = createSlice({
    name: 'bulkrintlabel',
    initialState: {
        bulklabeldata: [],
        loading: false,
        hasErrors: false,
    },
    reducers: {
        fetchBulkPrintLabelsData: (state) => {
            return {
                ...state,
                loading: true
            }
        },
        fetchBulkPrintLabelsSuccess: (state, action) => {
            return {
                ...state,
                bulklabeldata: action && action.payload && action.payload.data && action.payload.data.data,
                loading: false,
                hasErrors: false
            }
        },
        fetchBulkPrintLabelsFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                hasErrors: true
            }
        },
        resetBulkLabels: (state, action) => {
            return {
                ...state,
                bulklabeldata:[]
            }
        },
        
    }
})
export const { fetchBulkPrintLabelsData, fetchBulkPrintLabelsSuccess, fetchBulkPrintLabelsFailure,resetBulkLabels } = bulkprintlabelslice.actions;


export const getBulkLabels = (referenceid) => {
    return (dispatch) => {
        dispatch(fetchBulkPrintLabelsData());
        try {
            LABELGENAPI
                .get('/bulk-shipments/bulk-print?&referenceid='+referenceid)
                .then(function (response) {
                    dispatch(fetchBulkPrintLabelsSuccess(response));
                })
                .catch(function (error) {
                    dispatch(fetchBulkPrintLabelsFailure(error.response));
                });
        } catch (error) {
            dispatch(fetchBulkPrintLabelsFailure());
        }
    };
};
export const resetBulkLabel = () => {
    return (dispatch) => {
        dispatch(resetBulkLabels());
    }
}


export default bulkprintlabelslice.reducer;
