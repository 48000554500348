import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Snackbar from '@mui/material/Snackbar';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';

import LinearProgress from '@mui/material/LinearProgress';

import NorowsData from '../../../pages/General/NorowsData';
import Notification from '../../../pages/General/Notification';
import { fetchTagData, storeTagData,updateTagData,destoryTagData,resetTagState } from './tagSettingsSlice';


const TagSettingsPage = () => {
    const dispatch = useDispatch();
    const tags = useSelector(state => state.tags);
    const [selectedItem, setSelectedItem] = useState({});
    const [modalVisible, setModalVisible] = useState(false);
    const [deleteid, setDeleteId] = React.useState("");
    const [deleteModalopen, setDeleteModalopen] = React.useState(false);
    const [submitClick, setSubmitClick] = React.useState(false);
    const initialValues = {
        tag_name: '',
    }
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});

    const validator = (fieldValues) => {
        let temp = { ...errors }
        if ('tag_name' in fieldValues)
            temp.tag_name = fieldValues.tag_name ? "" : "This field is required."

        setErrors({
            ...temp
        })

        if (fieldValues === values)
            return Object.values(temp).every(x => x == "")
    }

    const onInputChange = e => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
        validator({ [name]: value })
    }
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper'
    };
    const deleteModalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };
    const handleClick = (id) => {
        setDeleteId(id)
        setDeleteModalopen(true);
    };
    const deleteModalClose = () => {
        setDeleteModalopen(false);
    };
    const deletetag = (id) => {
        dispatch(destoryTagData(id))
        setDeleteModalopen(false);
        onModalClose()
    }
    const onModalClose = () => {
        setModalVisible(false)
        setSelectedItem({})
        setErrors({})
    }
    const handleSubmit = e => {
        e.preventDefault()
        const isValid = validator(values)
        if (isValid) {
            if (selectedItem && Object.keys(selectedItem).length !== 0) {
                dispatch(updateTagData(selectedItem._id, values))
                setSubmitClick(true)
            } else {
                dispatch(storeTagData(values))
                setSubmitClick(true)
            }
        }

    }
    const columns = [
        {
          field: 'tag_name',
          headerName: 'Name',
          flex:1,
        },
        {
          field: 'action',
          headerName: 'Action',
          flex:1,
          renderCell: (params) => {
            return <div>
                <span style={{ marginRight: '10px' }}>
                    <Tooltip title="Edit" placement="left-start">
                        <IconButton
                            onClick={() => {
                                setSelectedItem(params.row);
                                setModalVisible(true)
                            }}
                        >
                            <EditIcon style={{ color: 'rgba(0,0,0,.54)', cursor: 'pointer' }} />
                        </IconButton>
                    </Tooltip>
                </span>
                <span>
                    <Tooltip title="Delete" placement="left-start">
                        <IconButton 
                            onClick={(e) => {
                            handleClick(params.row._id)
                            }}
                        >
                            <DeleteOutlineOutlinedIcon style={{ color: 'rgba(0,0,0,.54)', cursor: 'pointer' }}/>
                        </IconButton>
                    </Tooltip>
                </span>
            </div>
        }
        },
      ];

    useEffect(() => {
        if (selectedItem) {
            let tempValues = {
                tag_name: selectedItem.tag_name,
            };
            setValues(tempValues)
        }

    }, [selectedItem]);

      useEffect(() => {
        dispatch(fetchTagData());
    }, [dispatch]);

    useEffect(() => {
       if(tags && tags.loading==false && tags.notification && tags.notification.messageContent  && tags.notification.messageContent[0] !==''  && tags.notification.messageType!=='error' && submitClick==true){
            setSubmitClick(false)
            onModalClose()
       }
    }, [tags]);

    return (
        <div className="sc--settings--card">
             {(tags && tags.notification && tags.notification.messageContent!='') &&
            <Notification
                is_open={tags.notification.show}
                messageType={tags.notification.messageType}
                messages={tags.notification.messageContent}
                handleClose={() => dispatch(resetTagState())}
            />}
            <Modal
                open={modalVisible}
                onClose={onModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Card sx={{ minWidth: 400 }} className="sc--cardhead--details">
                        <CardHeader title='Tags' />
                        <Divider />
                        <CardContent>
                            <div className="sc--form--center" style={{ margin: '20px 0' }}>
                                <TextField
                                    label="Tag Name"
                                    name="tag_name"
                                    defaultValue={values && values.tag_name}
                                    onChange={onInputChange}
                                    error={errors.tag_name}
                                    helperText={errors.tag_name}
                                    style={{ width: "100%", margin: '8px' }}
                                    variant="standard"
                                />

                            </div>
                            <div className="sc--form--center" style={{ margin: '30px 0' }}>
                                <Button variant="outlined" onClick={handleSubmit}>Submit</Button>
                            </div>
                        </CardContent>
                    </Card>
                </Box>
            </Modal >
             <Modal
                open={deleteModalopen}
                onClose={deleteModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={deleteModalStyle}>
                    <Typography sx={{ p: 2 }}>Are you sure want to delete this tag?<br />
                        <div style={{ display: 'flex', justifyContent: 'center',marginTop:'10px' }}>
                            <span style={{ margin: '0 10px' }}>
                                <Button
                                    variant="contained"
                                    style={{ margin: "10px 0" }}
                                    onClick={(event) => {
                                        deletetag(deleteid)
                                    }}
                                >
                                    Yes
                                </Button>
                            </span>
                            <span>
                                <Button
                                    variant="outlined"
                                    style={{ margin: "10px 0" }}
                                    onClick={deleteModalClose}
                                >
                                    No
                                 </Button>
                            </span>
                        </div>
                    </Typography>
                </Box>
            </Modal>
            <Card sx={{ minWidth: '100%', boxShadow: 'unset' }} className="sc--cardhead--details">
                <CardHeader title='Tag Settings' className="sc--cardhead--details" 
                    action={
                        <div>
                            <Button variant="contained" onClick={() => { setModalVisible(true) }}>Add Tag</Button>
                        </div>
                    }
                />
                <Divider />
                    <CardContent>
                    <Box sx={{ height: 400}}>
                        <DataGrid
                            getRowId={(row) => row._id}
                            disableRowSelectionOnClick={true}
                            rows={tags && tags.data}
                            columns={columns}
                            autoHeight={true}
                            slots={{
                                loadingOverlay: LinearProgress,
                                NorowsData,
                            }}
                            loading={tags && tags.loading}
                        />
                    </Box>
                    </CardContent>
            </Card >
        </div>
    )
}

export default TagSettingsPage;
