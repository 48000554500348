import { createSlice } from '@reduxjs/toolkit';
import { OMSAPI } from '../../../../../API';
import { fetchIntegratedPlatform } from '../../integratedSlice';

export const brightpearlslice = createSlice({
    name: 'brightpearl',
    initialState: {
        progress: {
            data: {
                account: "",
                is_update_shipment: false,
                is_update_shipment_on_create: false,
                is_update_shipment_on_close: true
            },
            message: "",
            loading: false,
            hasErrors: false
        },
        configure: {
            data: {},
            message: "",
            loading: false,
            hasErrors: false
        }
    },
    reducers: {
        fetchBrightPearlDataDetails: (state) => {
            return {
                ...state,
                progress: {
                    loading: true
                }
            }
        },
        fetchBrightPearlDataDetailsSuccess: (state, action) => {
            return {
                ...state,
                progress: {
                    data: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data,
                    loading: false,
                    hasErrors: false,
                }
            }
        },
        fetchBrightPearlDataDetailsFailure: (state, action) => {
            return {
                ...state,
                progress: {
                    loading: false,
                    hasErrors: false,
                }
            }
        },
        postBrightPearlConfigure: (state) => {
            return {
                ...state,
                configure: {
                    loading: true
                }
            }
        },
        postBrightPearlConfigureSuccess: (state, action) => {
            return {
                ...state,
                configure: {
                    data: {},
                    message: action && action.payload && action.payload.data && action.payload.data.messages,
                    loading: false,
                    hasErrors: false,
                }
            }
        },
        postBrightPearlConfigureFailure: (state, action) => {
            return {
                ...state,
                configure: {
                    message: action && action.payload && action.payload.data && action.payload.data.messages,
                    loading: false,
                    hasErrors: true,
                }
            }
        },
        resetConfigureStateDetails: (state, action) => {
            return {
                ...state,
                configure: {
                    data: {},
                    message: '',
                    loading: false,
                    hasErrors: false,
                }
            }
        },
        resetBrightpearlStateDetails: (state, action) => {
            return {
                ...state,
                progress: {
                    data: {
                        account: "",
                        is_update_shipment: false,
                        is_update_shipment_on_create: false,
                        is_update_shipment_on_close: true
                    },
                    message: "",
                    loading: false,
                    hasErrors: false
                },
                configure: {
                    data: {},
                    message: "",
                    loading: false,
                    hasErrors: false
                }
            }
        },
    }
})
export const { fetchBrightPearlDataDetails, fetchBrightPearlDataDetailsSuccess, fetchBrightPearlDataDetailsFailure,
    postBrightPearlConfigure, postBrightPearlConfigureSuccess, postBrightPearlConfigureFailure, resetConfigureStateDetails, resetBrightpearlStateDetails } = brightpearlslice.actions;

export const configureStore = data => dispatch => {
    dispatch(postBrightPearlConfigure());
    try {
        OMSAPI.post('/brightpearl/configure', data)
            .then(function (response) {
                dispatch(postBrightPearlConfigureSuccess(response));
                dispatch(fetchIntegratedPlatform());
            }).catch(function (error) {
                dispatch(postBrightPearlConfigureFailure(error.response));
            })
    }
    catch (error) {
        dispatch(postBrightPearlConfigureFailure(error.response));
    }
}

export const fetchBrightPearlData = data => dispatch => {

    dispatch(fetchBrightPearlDataDetails())
    try {
        OMSAPI.post('/brightpearl/show', data)
            .then(function (response) {
                dispatch(fetchBrightPearlDataDetailsSuccess(response));
            }).catch(function (error) {
                dispatch(fetchBrightPearlDataDetailsFailure());
            })
    }
    catch (error) {
        dispatch(fetchBrightPearlDataDetailsFailure())
    }
}

export const resetConfigureState = data => dispatch => {
    dispatch(resetConfigureStateDetails());
}

export const resetBrightpearlState = data => dispatch => {
    dispatch(resetBrightpearlStateDetails());
}
export default brightpearlslice.reducer;
