import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
    DataGrid,
    GridToolbarContainer
} from '@mui/x-data-grid';
import AddLineItem from './AddLineItem';
import {
    Divider,
    Grid,
    Box,
    Button,
    Card,
    CardHeader,
    CardContent,
    IconButton,
    Tooltip
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

const getUniqueId = () => {
    return "SCIT" + Date.now().toString(36) + Math.random().toString(36).substr(2)
}

const LineItems = ({ lineItems, dispatchLineItemCallback, isDuplicateOrder, customLineItems, setCustomLineItems,isReturnOrder }) => {

    const [checkedIds, setCheckedIds] = useState([]);
    const [checkedItems, setCheckedItems] = useState([]);
    // const [pageSize, setPageSize] = useState(5);
    const [addLineItemVisible, setAddLineItemVisible] = useState(false)
    const [removeLineItemId, setRemoveLineItemId] = useState("")
    const [editLineItemId, setEditLineItemId] = useState("")
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 5,
        page: 0,
      });
    const [addLineItemData, setaddLineItemData] = useState({
        "line_item_id": getUniqueId(),
        "product_id": "",
        "product_desc": "",
        "product_url": "",
        "product_price": "",
        "hs_tariff_code": "",
        "hs_tariff_code_country": "",
        "edit_line_item_id": "",
        "quantity": 1,
        "tracking_number":"",
        "origin_country_code":""
    })
    const [lineItemErrors, setLineItemsErrors] = useState({
        "line_item_id": "",
        "product_id": "",
        "product_desc": "",
        "product_url": "",
        "product_price": "",
        "hs_tariff_code": "",
        "hs_tariff_code_country": "",
        "quantity": "",
        "origin_country_code":""
    });
    let initialColumns = [
        {
            field: 'product_id',
            headerName: 'Product Id',
            headerClassName: 'super-app-theme--header',
            width: 200,
            renderCell: (params) => {
                if (params.row && params.row.product_id && params.row.product_id != "") {
                    return params.row.product_id;
                }else if(params.row && params.row.sku && params.row.sku != ""){
                    return params.row.sku
                }else{
                    return 1;
                }
            }
        },
        {
            field: 'product_desc',
            headerName: 'Product Description',
            headerClassName: 'super-app-theme--header',
            width: 300,
            renderCell: (params) => {
                if (params.row && params.row.product_desc && params.row.product_desc != "") {
                    return params.row.product_desc;
                }else{
                    return "Product 1";
                }
            }
        }, {
            field: 'action',
            headerName: 'Action',
            headerClassName: 'super-app-theme--header',
            width: 100,
            hide: (customLineItems.length == 0),
            renderCell: (params) => {
                if (params.row && params.row.line_item_id && params.row.line_item_id != "") {
                    return <>
                        <span>
                            <Tooltip title="Edit" placement="left-start" disableFocusListener={false}>
                                <IconButton>
                                    <EditIcon style={{  color: 'rgba(0,0,0,.54)', cursor: updateButtonDisable(params.row) ? 'not-allowed' : 'pointer', pointerEvents: updateButtonDisable(params.row) ? 'none' : 'auto' }} onClick={() => setEditLineItemId(params.row.line_item_id)} />
                                </IconButton>
                            </Tooltip>
                        </span>
                        <span>
                            <Tooltip title="Delete" placement="left-start" disableFocusListener={false}>
                                <IconButton>
                                    <DeleteOutlineOutlinedIcon style={{  color: 'rgba(0,0,0,.54)', cursor: updateButtonDisable(params.row) ? 'not-allowed' : 'pointer', pointerEvents: updateButtonDisable(params.row) ? 'none' : 'auto' }}
                                        onClick={() => setRemoveLineItemId(params.row.line_item_id)}
                                    />
                                </IconButton>
                            </Tooltip>
                        </span>
                    </>;
                } else {
                    return <>-</>;
                }
            }

        }
    ]
    const [columns, setColumns] = useState(initialColumns)

    const updateButtonDisable = (item) => {
        let updateButtonDisable = true;
            if (!item.tracking_number || item.tracking_number === "") {
                updateButtonDisable = false;
            }
        return updateButtonDisable;
    }

    const onCheckedIdsChange = (items) => {
        let productLineItem = []
        if (items) {
            items && items.map((item) => {
                if (item && item.line_item_id && !item.tracking_number && !isDuplicateOrder && !isReturnOrder) {
                    productLineItem.push(String(item.line_item_id.toString()))
                }
            })
        }
        setCheckedIds(productLineItem)
    }


    const onAddLineItemModalClose = () => {
        setRemoveLineItemId("")
        setEditLineItemId("")
        setaddLineItemData({
            "line_item_id": getUniqueId(),
            "product_id": "",
            "product_desc": "",
            "product_url": "",
            "product_price": "",
            "hs_tariff_code": "",
            "hs_tariff_code_country": "",
            "edit_line_item_id": "",
            "quantity": 1,
            "tracking_number":"",
            "origin_country_code":""
        })
        setLineItemsErrors({
            "line_item_id": "",
            "product_id": "",
            "product_desc": "",
            "product_url": "",
            "product_price": "",
            "hs_tariff_code": "",
            "hs_tariff_code_country": "",
            "origin_country_code":"",
            "quantity": ""
        })
        setAddLineItemVisible(false)
    }

    const checkBoxCompute = (tempCheckedIds, tempCheckedItems, item, currentSelectedIds) => {
        const line_item_id = (item.line_item_id) ? item.line_item_id.toString() : ""
        const index = tempCheckedIds.indexOf(line_item_id);
        const method = (currentSelectedIds.indexOf(line_item_id) > -1)
        if (method) {
            if (index === -1) {
                tempCheckedIds.push(line_item_id);
                tempCheckedItems.push(item)
            }
        } else if (!method) {
            if (index > -1) {
                tempCheckedIds.splice(index, 1);
                tempCheckedItems.splice(index, 1);
            }
        }
        return [tempCheckedIds, tempCheckedItems];
    }

    const onSelectionChange = (currentSelectedIds, currentAllItems) => {
        let tempCheckedIds = [...checkedIds]
        let tempCheckedItems = [...checkedItems];
        currentAllItems && currentAllItems.length != 0 && currentAllItems.map((item) => (
            checkBoxCompute(tempCheckedIds, tempCheckedItems, item, currentSelectedIds)
        ))
        setCheckedIds(tempCheckedIds)
        setCheckedItems(tempCheckedItems)
    }

    const formDataHandler = e => {
        const { name, value } = e.target
        setaddLineItemData({
            ...addLineItemData,
            [name]: value
        })
        validateLineItemData();
    }

    const dispatchCountryCode = (id) => {
        setaddLineItemData({
            ...addLineItemData,
            ['origin_country_code']: id
        })
        validateLineItemData();
    }

    const isFloatValue = (value) => {
        value = Number(value);
        if (!isNaN(value) && value.toString().indexOf('.') != -1) {
            return true;
        }
        return false;
    }

    const isIntegerValue = (value) => {
        value = Number(value);
        if (Number.isInteger(value)) {
            return true;
        }
        return false;
    }

    const validateLineItemData = () => {
        let isValid = true
        let lineItemErrorsTemp = {
            "line_item_id": "",
            "product_id": "",
            "product_desc": "",
            "product_url": "",
            "product_price": "",
            "hs_tariff_code": "",
            "hs_tariff_code_country": "",
            "origin_country_code":"",
            "quantity": ""
        };

        if (addLineItemData.product_id == "" || addLineItemData.product_id.length >= 25) {
            isValid = false
            lineItemErrorsTemp.product_id = "Product id is required";
        }

        if (addLineItemData.product_desc == "" || addLineItemData.product_desc.length <= 1 || addLineItemData.product_desc.length >= 100) {
            isValid = false
            lineItemErrorsTemp.product_desc = "Product desc is required and should be more than 5 character long";
        }

        if (addLineItemData.product_url == "" || addLineItemData.product_url.length >= 250) {
            isValid = false
            lineItemErrorsTemp.product_url = "Product url is required and should be valid";
        }

        if (addLineItemData.product_price == "" || (!isFloatValue(addLineItemData.product_price) && !isIntegerValue(addLineItemData.product_price))) {
            isValid = false
            lineItemErrorsTemp.product_price = "Product price is required and should be valid";
        }

        if (addLineItemData.quantity == "" || !isIntegerValue(addLineItemData.quantity)) {
            isValid = false
            lineItemErrorsTemp.quantity = "Product quantity is required and should be a valid";
        }

        setLineItemsErrors(lineItemErrorsTemp)
        return isValid
    }


    const onAddLineItemClick = () => {

        if (validateLineItemData()) {
            let customLineItemData = [...customLineItems];
            if (_.isUndefined(_.find(customLineItems, ['product_id', addLineItemData.product_id])) && addLineItemData.edit_line_item_id == "") {
                customLineItemData.push(addLineItemData)
                setCustomLineItems(customLineItemData)
                setaddLineItemData({
                    "line_item_id": getUniqueId(),
                    "product_id": "",
                    "product_desc": "",
                    "product_url": "",
                    "product_price": "",
                    "hs_tariff_code": "",
                    "hs_tariff_code_country": "",
                    "edit_line_item_id": "",
                    "origin_country_code":"",
                    "quantity": 1
                })
            } else if (addLineItemData.edit_line_item_id != "") {
                let editCheckData = _.find(customLineItems, ['line_item_id', addLineItemData.edit_line_item_id])
                console.log(editCheckData["product_id"])
                if (editCheckData["product_id"] != addLineItemData.product_id && !_.isUndefined(_.find(customLineItems, ['product_id', addLineItemData.product_id]))) {
                    console.log("Product already exists")
                    setLineItemsErrors({
                        ...lineItemErrors,
                        [`product_id`]: "Product id already exists"
                    })
                    return false
                }
                let customLineItemData = [...customLineItems];
                let newLineItemData = [];
                customLineItemData.map((item) => {
                    if (item && item.line_item_id && item.line_item_id == addLineItemData.edit_line_item_id) {
                        newLineItemData.push(addLineItemData)
                    } else {
                        newLineItemData.push(item)
                    }
                })
                setCustomLineItems(newLineItemData)
            } else {
                console.log("Product already exists")
                setLineItemsErrors({
                    ...lineItemErrors,
                    [`product_id`]: "Product id already exists"
                })
                return false;
            }
            onAddLineItemModalClose()

        } else {
            console.log("Errors in line items")
        }
    }

    const onLineItemsChange = (items) => {
        if (items) {
            let productLineItem = []
            items && items.map((item) => {
                if (item) {
                    let product_id = 1;
                    if(item && item.product_id && item.product_id != ""){
                        product_id = item.product_id;
                    }else if(item && item.sku && item.sku != ""){
                        product_id = item.sku;
                    }
                    productLineItem.push({
                        "line_item_id": (item && item.line_item_id && item.line_item_id != "") ? item.line_item_id : "1",
                        "product_id": product_id,
                        "product_desc": (item && item.product_desc && item.product_desc != "") ? item.product_desc : "Product 1",
                        "product_url": (item && item.product_image && item.product_image != "") ? item.product_image : "https://www.shippingchimp.com/",
                        "product_price": (item && item.product_price && item.product_price != "") ? item.product_price : "100",
                        "hs_tariff_code": (item && item.hs_tariff_code && item.hs_tariff_code != "") ? item.hs_tariff_code : "",
                        "hs_tariff_code_country": (item && item.hs_tariff_code_country && item.hs_tariff_code_country != "") ? item.hs_tariff_code_country : "",
                        "quantity": (item && item.quantity && item.quantity != "") ? item.quantity : 1,
                        "tracking_number": (item && item.tracking_number && item.tracking_number != "" && !isDuplicateOrder && !isReturnOrder) ? item.tracking_number : "",
                        "origin_country_code":(item && item.origin_country_code && item.origin_country_code != "") ? item.origin_country_code : ""
                    })
                }
            })
            setCustomLineItems(productLineItem)
        }
    }

    useEffect(() => {
        dispatchLineItemCallback(checkedIds)
    }, [checkedIds])

    useEffect(() => {
        if (lineItems && (typeof lineItems !== 'undefined') && lineItems.length != 0) {
            onCheckedIdsChange(lineItems)
            onLineItemsChange(lineItems)
        }
    }, [lineItems])

    useEffect(() => {
        setColumns(initialColumns)
        onCheckedIdsChange(customLineItems)
    }, [customLineItems])

    useEffect(() => {
        if (removeLineItemId != "" && removeLineItemId != "undefined") {
            let customLineItemData = [...customLineItems];
            let newLineItemData = [];
            customLineItemData.map((item) => {
                if (item && item.line_item_id && item.line_item_id != removeLineItemId) {
                    newLineItemData.push(item)
                }
            })
            setCustomLineItems(newLineItemData)
        }
    }, [removeLineItemId])

    useEffect(() => {
        if (editLineItemId != "" && editLineItemId != "undefined" && !_.isUndefined(_.find(customLineItems, ['line_item_id', editLineItemId]))) {
            console.log("Found edit")
            let editData = _.find(customLineItems, ['line_item_id', editLineItemId])
            editData[`edit_line_item_id`] = editLineItemId
            setaddLineItemData(editData)
            setAddLineItemVisible(true)
        }
    }, [editLineItemId])

    return (
        <>
            <Grid xs={6} md={6} style={{ height: '100%', width: '50%', padding: '10px' }}>
                <Card
                    sx={{ minWidth: 275, boxShadow: 'unset', border: '1px solid rgba(0, 0, 0, 0.12)' }}
                    className="sc--cardhead--details">
                    <CardHeader
                        title='Line Item'
                        action={
                            <>
                                {((typeof lineItems == 'undefined') ||lineItems.length == 0 || isDuplicateOrder) &&
                                    <Button variant="outlined" aria-label="settings" onClick={() => setAddLineItemVisible(true)}>
                                        Add Line Item
                                    </Button>
                                }
                            </>
                        }
                    />
                    <Divider />
                    <CardContent>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '25ch' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <AddLineItem
                                addLineItemVisible={addLineItemVisible}
                                onAddLineItemModalClose={onAddLineItemModalClose}
                                formDataHandler={formDataHandler}
                                dispatchCountryCode={dispatchCountryCode}
                                onAddLineItemClick={onAddLineItemClick}
                                addLineItemData={addLineItemData}
                                editLineItemId={editLineItemId}
                                errors={lineItemErrors}
                            />
                            <div className="sc--form--center" style={{ margin: '20px 0' }}>
                                <DataGrid
                                    disableRowSelectionOnClick
                                    getRowId={(row) => row.line_item_id ? row.line_item_id.toString() : Math.random()}
                                    rows={customLineItems}
                                    columns={columns}
                                    autoHeight={true}
                                    checkboxSelection
                                    disableColumnFilter={true}
                                    disableColumnMenu={true}
                                    sortingMode="server"
                                    isRowSelectable={(params) => !params.row.tracking_number}
                                    onRowSelectionModelChange={(newSelectionModel) => { onSelectionChange(newSelectionModel, (!isDuplicateOrder && !isReturnOrder && lineItems.length != 0) ? lineItems : customLineItems) }}
                                    rowSelectionModel={checkedIds}
                                    // pageSize={pageSize}
                                    // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                    pageSizeOptions={[5, 10, 25]}
                                    paginationModel={paginationModel}
                                    onPaginationModelChange={setPaginationModel}
                                />
                            </div>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
        </>
    )
};

export default LineItems;