import React from "react";
import Chip from '@mui/material/Chip';

const EmailStatus = ({ emailStatus = null, badgeClass = "badge-success" }) => {
 
    let bcolor = ""
    if (emailStatus != null && emailStatus != "") {
        if (emailStatus === "SUCCESS") {
            bcolor = "rgb(52, 128, 24)"
        } else{
            bcolor = "rgb(217 44 48)"
        }
    }

    return (
        <>
            {!emailStatus && <span className="tb-lead">-</span>}
            {emailStatus && <div><Chip className="sc--orders--chip" label={emailStatus} style={{ color: "white", backgroundColor: bcolor }} /></div>}
        </>
    );
};

export default EmailStatus;
