import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import LinearProgress from '@mui/material/LinearProgress';

import { useForm } from '../../../pages/General/useForm';
import Loader from '../../../pages/General/Loader';

import { fetchProfile, updateProfile } from './profileSlice';

const ProfilePage = () => {
    const dispatch = useDispatch();

    const profile = useSelector(state => state.profile);
    const [alert, setAlert] = useState(false);
    const [open, setOpen] = useState(false);
    const [checkFormSubmit, setCheckFormSubmit] = useState(false);

    const initialFValues = {
        name: '',
        phone: '',
    }

    useEffect(() => {
        dispatch(fetchProfile())
    }, [dispatch])

    useEffect(() => {
        if (profile) {
            setValues({
                ...values,
                name: profile.profile.name,
                phone: profile.profile.phone,
                email: profile.profile.email,
            })
        }
        // 
        if (checkFormSubmit && profile && profile.message) {
            setAlert(true)
            setOpen(true)
        }
    }, [profile]);

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if (fieldValues.name && fieldValues.name.length < 3)
            temp.name = "Name required minimum 3 characters"
        else {
            temp.name = ""
        }

        if (fieldValues.phone && fieldValues.phone.length < 10) {
            temp.phone = "The phone number minimum 10 digits"
        } else {
            temp.phone = ""
        }

        setErrors({
            ...temp
        })

        if (fieldValues == values)
            return Object.values(temp).every(x => x == "")
    }

    const handleClose = () => {
        setOpen(false)
    };

    const handleSubmit = e => {
        e.preventDefault()
        if (validate()) {
            setCheckFormSubmit(true)
            if (profile.profile.id) {
                dispatch(updateProfile(values))
            }
        }
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange
    } = useForm(initialFValues, validate, true);

    return (
        <div className="sc--settings--card">
            {/* {profile && profile.loading === true &&
                <Loader />
            } */}
            {profile && profile.loading === false && alert &&
                <Snackbar
                    open={open}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    autoHideDuration={6000}
                    onClose={handleClose}
                >
                    <Alert
                        severity={profile.hasErrors == true ? "error" : "success"}
                        style={{ justifyContent: 'center', margin: "10px 0" }}>
                        <span>{profile.profile.message}</span>
                    </Alert>
                </Snackbar>
            }
            {profile && profile.loading === false &&
                <Card sx={{ minWidth: 1000, boxShadow: 'unset' }} className="sc--cardhead--details">
                    <CardHeader title='Profile' className="sc--cardhead--details" />
                    {profile && profile.loading === true &&
                        <LinearProgress />
                    }
                    <Divider />
                    <CardContent>
                        <div className="sc--form--center">
                            <TextField
                                label='Name'
                                size='small'
                                name="name"
                                value={values.name ? values.name : ""}
                                variant="standard"
                                inputProps={{ maxLength: 25 }}
                                style={{ width: 350, margin: '8px' }}
                                onChange={handleInputChange}
                                error={!!errors.name}
                                helperText={errors.name}
                            />
                        </div>
                        <div className="sc--form--center">
                            <TextField
                                label='Email'
                                size='small'
                                name="email"
                                value={profile.profile.email ? profile.profile.email : ""}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{ readOnly: true }}
                                variant="filled"
                                style={{ width: 350, margin: '8px' }}
                            />
                        </div>
                        <div className="sc--form--center">
                            <TextField
                                label='Phone'
                                size='small'
                                name="phone"
                                value={values.phone ? values.phone : ""}
                                onChange={handleInputChange}
                                error={!!errors.phone}
                                inputProps={{ maxLength: 15 }}
                                helperText={errors.phone}
                                variant="standard"
                                style={{ width: 350, margin: '8px' }}
                            />
                        </div>
                        <div className="sc--form--center" style={{ margin: '15px 0' }}>
                            <Button variant="contained" onClick={handleSubmit} >Save</Button> </div>
                    </CardContent>
                </Card>
            }
        </div>
    )
}


export default ProfilePage;
