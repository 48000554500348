import { createSlice } from '@reduxjs/toolkit';
import {LABELGENAPI } from '../../../API';

export const addresssenderslice = createSlice({
    name: 'addresssender',
    initialState: {
        data: [],
        meta: [],
        loading: false,
        hasErrors: false,
        message: "",
        messageType:""
    },
    reducers: {
        getSenderAddress: (state) => {
            return {
                ...state,
                loading: true,
                hasErrors: false
            }
        },
        getSenderAddressSuccess: (state, action) => {
            return {
                ...state,
                data: action && action.payload && action.payload.data && action.payload.data.data,
                hasErrors: false,
                loading: false
            }
        },
        getSenderAddressFailure: (state, action) => {
            return {
                ...state,              
                loading: false,
                hasErrors: true,
                message: action && action.payload && action.payload.data && action.payload.data.message
            }
        },
        putSenderAddress: (state) => {
            return {
                ...state,
                loading: true,
                hasErrors: false,
                message: ""
            }
        },
        putSenderAddressSuccess: (state, action) => {
            return {
                ...state,
                loading: false,
                hasErrors: false,
                messageType:"success",
                message: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.message
            }
        },
        putSenderAddressFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                hasErrors: true,
                message: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.message
            }
        },
        deleteSenderAddress: (state) => {
            return {
                ...state,
                loading: true,
                hasErrors: false,
                message: ""
            }
        },
        deleteSenderAddressSuccess: (state, action) => {
            return {
                ...state,
                loading: false,
                hasErrors: false,
                message: action && action.payload && action.payload.data && action.payload.data.message
            }
        },
        deleteSenderAddressFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                hasErrors: true,
                message: action && action.payload && action.payload.data && action.payload.data.message
            }
        },
        postSenderAddress: (state) => {
            return {
                ...state,
                loading: true,
                hasErrors: false,
                message: ""
            }
        },
        postSenderAddressSuccess: (state, action) => {
            return {
                ...state,
                loading: false,
                hasErrors: false,
                messageType:"success",
                message: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.message
            }                
            
        },
        postSenderAddressFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                hasErrors: true,
                messageType:"failure",
                message: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.message
            }
            
        },
        resetSenderAddress: (state) => {
            return {
                ...state,
                loading: false,
                hasError: false,
                message: "",
            }
        },

        


    }
})
export const { getSenderAddress, getSenderAddressSuccess, getSenderAddressFailure, 
    putSenderAddress, putSenderAddressSuccess, putSenderAddressFailure, deleteSenderAddress, deleteSenderAddressSuccess, deleteSenderAddressFailure,
    postSenderAddress, postSenderAddressSuccess, postSenderAddressFailure, resetSenderAddress } = addresssenderslice.actions;


export const fetchSenderAddress = data =>dispatch => {    
    dispatch(getSenderAddress());
    try {
        LABELGENAPI
            .get('/settings/address-sender')
            .then(function (response) {
                dispatch(getSenderAddressSuccess(response));
            })
            .catch(function (error) {
                dispatch(getSenderAddressFailure(error.response));
            });
    } catch (error) {
        dispatch(getSenderAddressFailure());
    }
};

export const storeSenderAddress = data => dispatch => {
    dispatch(postSenderAddress());
    try {
        LABELGENAPI.post('/settings/address-sender', data)
            .then(function (response) {
                dispatch(postSenderAddressSuccess(response));
                dispatch(fetchSenderAddress());
            }).catch(function (error) {
                dispatch(postSenderAddressFailure(error.response));
            })
    }
    catch (error) {
        dispatch(postSenderAddressFailure(error.response));
    }
}



export const updateSenderAddress = (id, data) => dispatch => {   
    dispatch(putSenderAddress());

    try {
        LABELGENAPI.patch('/settings/address-sender/' + id, data)
            .then(function (response) {
                dispatch(putSenderAddressSuccess(response));
                dispatch(fetchSenderAddress());
            }).catch(function (error) {
                dispatch(putSenderAddressFailure(error.response));
            })
    }
    catch (error) {
        dispatch(putSenderAddressFailure(error.response));
    }

}

export const destorySenderAddress = id => dispatch => {
    dispatch(deleteSenderAddress());

    try {
        LABELGENAPI
            .delete("/settings/address-sender/" + id)
            .then(function (response) {
                dispatch(deleteSenderAddressSuccess(response));
                dispatch(fetchSenderAddress());
            })
            .catch(function (error) {
                dispatch(deleteSenderAddressFailure(error.response));
            });
    } catch (error) {
        dispatch(deleteSenderAddressFailure(error));
    }
};
export const resetSenderAddressState = data => dispatch => {    
    dispatch(resetSenderAddress());
}


export default addresssenderslice.reducer;
