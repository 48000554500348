import React from "react";

const IntegrationPlatform = ({ platform = "" }) => {
    return (
        <>
            {!platform && <div> - </div>}
            {platform && <div><img srcSet={"/assets/images/integration/" + platform.toUpperCase() + ".png"} width="40px" alt={platform} /></div>}
        </>
    );
};

export default IntegrationPlatform;