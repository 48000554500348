import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete from "@mui/material/Autocomplete";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { fetchCountry, fetchState, fetchMultiStates } from '../../pages/General/countryStateCitySlice';


const ProvinceDropdown = ({ values=null,dispatchProvinceCallback }) => {
    const dispatch = useDispatch();
    const country_code = useSelector(state => state.countrystate.country);
    const state = useSelector(state => state.countrystate.state);
    const onSelectStateChange = id => {
        dispatchProvinceCallback(id)
    }

    const getSelectedState = (currentstate) => {
        let filteredStateValue = { code: "", name: "" };
        let tempState = "";
        state &&
          state.data &&
          state.data.map((key) => {
            if (key.code == currentstate && tempState == "") {
              filteredStateValue = key;
              tempState = "success";
            }
          });
        return filteredStateValue;
      };
      useEffect(() => {
        if (values && values.country) {
            dispatch(fetchState(values.country));
        }
    }, [values]);
    return (
       <>
            <Autocomplete
                className="help-text-error"
                disablePortal
                name="province"
                id="state-select-demo"
                sx={{ width: "50%",marginRight: '8px'}}
                options={state && state.data}
                autoHighlight
                loading={
                (state && state.loading) || (country_code && country_code.loading)
                }
                getOptionLabel={(option) => option.name}
                value={getSelectedState(values && values.province)}
                renderOption={(props, option) => (
                <MenuItem {...props} value={option.code} key={option.code}>
                    {option.name}
                </MenuItem>
                )}
                onChange={(event, value) => {
                    if (value !== null) {
                        onSelectStateChange(value.code);
                    } else {
                        onSelectStateChange("");
                    }
                    }}
                renderInput={(params) => (
                <TextField
                    {...params}
                    label="Province"
                    variant="standard"
                    // helperText={errors.province}
                />
                )}
            />
       </>
    )
}


export default ProvinceDropdown;