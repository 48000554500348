import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Modal from '@mui/material/Modal';
import { DataGrid } from '@mui/x-data-grid';
import LinearProgress from '@mui/material/LinearProgress';
import EditIcon from '@mui/icons-material/Edit';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';

import Notification from '../../../../../pages/General/Notification'
import { fetchWebhookDetail, storeWebhook, destoryWebhook, resetConfigureStateDetails } from './webhookSlice';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper'
};
const deleteModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};
const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    height: '100%',
    padding: '20px 0'
}));
const Modalpopup = ({ modalVisible, setModalVisible, formValues }) => {
    const dispatch = useDispatch();
    const webhook = useSelector(state => state.webhook);
    const [webhookdomain, setWebhookdomain] = useState('');
    const [webhookstatus, setWebhookstatus] = useState('');
    const [eventname, setEventname] = useState('');
    const [updateButtonDisabled, setUpdateButtonDisabled] = useState(true);
    useEffect(() => {
        if (formValues && Object.keys(formValues).length !== 0) {
            setEventname(formValues && formValues.event)
            setWebhookstatus(formValues && formValues.enable)
            setWebhookdomain(formValues && formValues.webhook_url)
        }
    }, [formValues])

    const onModalClose = () => {
        setModalVisible(false)
    }
    const checkUpdateFormHasValues = () => {
        let updatedisableButton = true
        if (webhookdomain !== '') {
            updatedisableButton = false
        }
        return updatedisableButton
    }
    useEffect(() => {
        setUpdateButtonDisabled(checkUpdateFormHasValues())
    }, [webhookdomain])

    const modalhandleSubmit = e => {
        const values = {
            'event': eventname,
            'enable': webhookstatus.toString(),
            'webhook_url': webhookdomain,
            "_method": 'put'
        }
        dispatch(storeWebhook(values))
    }

    return (
        <Modal
            open={modalVisible}
            onClose={onModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Card sx={{ minWidth: 600 }} className="sc--cardhead--details">
                    <CardHeader title={`Update Hook - ${formValues && formValues.event}`} />
                    <Divider />
                    <CardContent>
                        <div className="sc--form--center" style={{ margin: '20px 0' }}>
                            <TextField
                                label="Webhook Url"
                                name="webhook_url"
                                defaultValue={formValues && formValues.webhook_url}
                                onInput={e => setWebhookdomain(e.target.value)}
                                style={{ width: "100%", margin: '8px' }}
                                variant="standard"
                            />

                        </div>
                        <div className="" style={{ margin: '20px 0' }}>
                            <FormGroup style={{ width: "50%" }}>
                                <FormControlLabel
                                    control={<Switch
                                        defaultChecked={formValues && formValues.enable}
                                        onChange={e => setWebhookstatus(e.target.checked)} name="enable"
                                    />}
                                    label="Status"
                                />
                            </FormGroup>
                        </div>
                        <div className="sc--form--center" style={{ margin: '30px 0' }}>
                            <LoadingButton
                                variant="outlined"
                                onClick={modalhandleSubmit}
                                loading={
                                    webhook && webhook.loading}
                                disabled={updateButtonDisabled}>Update
                                </LoadingButton>
                            <Button
                                variant="outlined"
                                style={{ marginLeft: '10px' }}
                                onClick={onModalClose}
                            >Cancel
                            </Button>
                        </div>
                    </CardContent>
                </Card>
            </Box>
        </Modal >
    )
}
const WebhookIntegration = ({ storeName, platformName, storeParam, storeId }) => {

    const dispatch = useDispatch();
    const webhook = useSelector(state => state.webhook);
    const webhookdata = useSelector(state => state.webhook.webhook);

    const [event, setEvent] = useState('');
    const [webhookurl, setWebhookurl] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(true)
    const [deleteModalopen, setDeleteModalopen] = React.useState(false);
    const [deleteid, setDeleteId] = React.useState("");

    let events = {
        "Order Received": false,
        "Fulfilled": false,
        "Picked Up": false,
        "In Transit": false,
        "Shipment On Hold": false,
        "Out For Delivery": false,
        "Delivered": false,
        "Exception": false,
        "Delivery Attempt Failure": false,
        "Returns": false,
        "Lost": false,
        "Damage": false,
    }
    let options = [];
    for (const [key, value] of Object.entries(webhookdata)) {
        console.log(value)
        if (events[value.event] !== undefined && key !== "") {
            events[value.event] = true;
        }
    }
    for (const [eventKey, eventValue] of Object.entries(events)) {
        options.push(
            <MenuItem key={eventKey} value={eventKey} disabled={eventValue}>
                {eventKey}
            </MenuItem>
        );
    }
    console.log(events)
    const onDelete = (item) => {
        setDeleteId(item)
        setDeleteModalopen(true);
    }

    const deleteEvent = (item) => {
        dispatch(destoryWebhook(item))
        setDeleteModalopen(false)
    }

    const handleSubmit = () => {
        if (event != '' && webhookurl != '') {
            const values = { 'event': event, 'webhook_url': webhookurl, "_method": 'put' }
            dispatch(storeWebhook(values))
        }
    }
    const deleteModalClose = () => {
        setDeleteModalopen(false);
    };
    const checkFormHasValues = () => {
        let disableButton = true
        if (event !== '' && webhookurl !== '') {
            disableButton = false
        }
        return disableButton
    }
    useEffect(() => {
        setSaveButtonDisabled(checkFormHasValues())
    }, [event])
    useEffect(() => {
        setSaveButtonDisabled(checkFormHasValues())
    }, [webhookurl])
    const columns = [
        { field: 'event', headerName: 'Event Type', flex: 1 },
        {
            field: 'notification_type',
            headerName: 'Webhook',
            flex: 1,
            renderCell: (params) => {
                return (
                    <Switch
                        defaultChecked={params.row.enable}
                        name="is_update_shipment"
                    />
                )
            }
        },
        {
            field: 'webhook_url',
            headerName: 'Webhook URL',
            flex: 1,
            renderCell: (params) => {
                return <p>{params.row.webhook_url}</p>
            }
        },
        {
            field: 'ACTIONS',
            renderCell: (params) => {
                return (
                    <>
                        <span
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                textDecoration: 'unset',
                                color: '#1976d2'
                            }}>
                            <EditIcon onClick={() => {
                                setSelectedItem(params.row);
                                setModalVisible(true)
                            }} />
                        </span>
                        <span
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                textDecoration: 'unset',
                                color: '#1976d2'
                            }}><DeleteOutlineOutlinedIcon
                                onClick={() => {
                                    onDelete(params.row.event)
                                }}
                                style={{ marginLeft: '10px' }}
                            />
                        </span>
                    </>
                )
            },
            flex: 1
        }
    ]
    useEffect(() => {
        dispatch(fetchWebhookDetail());
    }, [dispatch])
    console.log(options)
    return (
        <>
            {(webhook && webhook.notification && webhook.notification.showMessage) &&
                <Notification
                    is_open={webhook.notification.showMessage}
                    messageType={webhook.notification.messageType}
                    messages={webhook.notification.messageContent}
                    handleClose={() => dispatch(resetConfigureStateDetails())}
                />}
            <Modalpopup
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}
                formValues={selectedItem}
            />
            <Modal
                open={deleteModalopen}
                onClose={deleteModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={deleteModalStyle}>
                    <Typography sx={{ p: 2 }}>Are you sure want to delete {deleteid} event?<br />
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <span style={{ margin: '0 10px' }}>
                                <Button
                                    variant="contained"
                                    style={{ margin: "10px 0" }}
                                    onClick={(event) => {
                                        deleteEvent(deleteid)
                                    }}
                                >
                                    Yes
                                </Button>
                            </span>
                            <span>
                                <Button
                                    variant="outlined"
                                    style={{ margin: "10px 0" }}
                                    onClick={deleteModalClose}
                                >
                                    No
                                 </Button>
                            </span>
                        </div>
                    </Typography>
                </Box>
            </Modal>
            <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                    <Card sx={{ marginBottom: '30px' }} className="sc--cardhead--details">
                        <div style={{ width: '100%', background: '#fff' }}>
                            <CardHeader title="Add Webhook" />
                            <Divider />
                            <div style={{ margin: 'auto', width: '35%' }}>
                                <div className="sc--form--center" style={{ margin: '20px 0' }}>
                                    <FormControl
                                        variant="standard"
                                        className="help-text-error"
                                        style={{ width: '100%', margin: '8px' }}
                                    >
                                        <InputLabel
                                            id="demo-simple-select-standard-label"
                                        >Event
                                             </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            name="events"
                                            value={event}
                                            onChange={(e) => {
                                                setEvent(e.target.value)
                                            }
                                            }
                                            label="Event"
                                        >
                                            {options}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="sc--form--center" style={{ margin: '20px 0' }}>
                                    <TextField
                                        label="Webhook Url"
                                        name="webhook_url"
                                        onInput={e => setWebhookurl(e.target.value)}
                                        style={{ width: "100%", margin: '8px' }}
                                        variant="standard"
                                        type="url"
                                    />
                                </div>
                                <div className="sc--form--center" style={{ margin: '30px 0' }}>
                                    <LoadingButton
                                        variant="outlined"
                                        onClick={handleSubmit}
                                        loading={
                                            webhook && webhook.loading}
                                        disabled={saveButtonDisabled}>Save
                                            </LoadingButton>
                                </div>
                            </div>
                        </div>
                    </Card>
                </Grid>
            </Grid>
            <Card sx={{ marginBottom: '30px' }} className="sc--cardhead--details">
                <div style={{ width: '100%', background: '#fff' }}>

                    <div style={{ maxWidth: '100%' }}>
                        <DataGrid
                            getRowId={(row) => row.event}
                            disableSelectionOnClick={true}
                            rows={webhook && webhook.webhook}
                            columns={columns}
                            autoHeight={true}
                            slots={{
                                loadingOverlay: LinearProgress,
                            }}
                            hideFooter={true}
                            loading={webhook && webhook.loading}
                        />
                    </div>
                </div>
            </Card>
        </>

    )
}

export default WebhookIntegration;