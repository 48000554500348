import { createSlice } from '@reduxjs/toolkit';
import { LABELINGCHIMP_SAM_API, LABELGENAPI, LABELINGCHIMP_LABEL_API, INTEGRATIONS_API } from '../../API';
import axios from 'axios';

/* canceling previous requests if multiple requests is triggered */
const CancelToken = axios.CancelToken;
let source = CancelToken.source();

export const ordersslice = createSlice({
    name: 'orders',
    initialState: {
        ordersData: {
            data: [],
            loading: false,
            meta: {
                page: 1,
                per_page: 10,
                start_date: "",
                end_date: "",
                order_id: "",
                sku: "",
                order_status: [],
                receiver_province: "",
                receiver_country_code: "",
                tracking_number: "",
                carrier_name: [],
                platform_tags: [],
                order_tags: [],
                customer_name: "",
                reference_no: "",
                goods_out_note:"",
                shipment_type:0,
                sorting: JSON.stringify([{
                    field: 'order.date',
                    sort: 'desc',
                }])
            },
            notification: { show: false, messageContent: "", messageType: "success" }
        },
        ordersColumns: {
            loading: false,
            notification: { show: false, messageContent: "", messageType: "success" }
        },
        createShipment: {
            loading: false,
            message:"",
            notification: { show: false, messageContent: "", messageType: "success" }
        },
        createShipmentBulk: {
            loading: false,
            notification: { show: false, messageContent: "", messageType: "success" }
        },
        voidShipment: {
            loading: false,
            notification: { show: false, messageContent: "", messageType: "success" }
        },
        manifestClose: {
            loading: false,
            notification: { show: false, messageContent: "", messageType: "success" }
        },
        bulkLabel: {
            loading: false,
            filename: "",
            is_shown: true,
            notification: { show: false, messageContent: "", messageType: "success" }
        },
        carriers: {
            loading: false,
            data: [],
            notification: { show: false, messageContent: "", messageType: "success" }
        },
        platformTags: {
            loading: false,
            data: [],
            notification: { show: false, messageContent: "", messageType: "success" }
        },
        assignTags: {
            loading: false,
            data: [],
            notification: { show: false, messageContent: "", messageType: "success" }
        },
        lineItemsWeight: {
            loading: false,
            data: []
        },
        bulkSelectedOrdersData: {
            data: [],
            loading: false,
            meta: {},
            notification: { show: false, messageContent: "", messageType: "success" }
        },
        bulkOrderValidation: {
            loading: false,
            data: [],
        },
        orderUpdate: {
            data: [],
            loading: false,
            meta: {},
            notification: { show: false, messageContent: "", messageType: "success" }
        },
        syncOrderData: {
            loading: false,
            meta: {},
            notification: { show: false, messageContent: "", messageType: "success" }
        },
        orderUIUpdate: {
            data: [],
            loading: false,
            meta: {},
            notification: { show: false, messageContent: "", messageType: "success" }
        },
        addBulkDimension: {
            loading: false,
            data: [],
            notification: { show: false, messageContent: "", messageType: "success" }
        },
        deleteOrders: {
            loading: false,
            data: [],
            notification: { show: false, messageContent: "", messageType: "success" }
        },
    },
    reducers: {
        getOrders: (state) => {
            return {
                ...state,
                ordersData: {
                    ...state.ordersData,
                    data:[],
                    loading: true
                }
            }
        },
        getOrdersSuccess: (state, action) => {
            return {
                ...state,
                ordersData: {
                    ...state.ordersData,
                    data: action && action.payload && action.payload.data && action.payload.data.data,
                    meta: action && action.payload && action.payload.data && action.payload.data.meta,
                    loading: false
                }
            }
        },
        getOrdersFailure: (state, action) => {
            return {
                ...state,
                ordersData: {
                    ...state.ordersData,
                    loading: false,
                    notification: { show: true, messageContent: action.data && action.data.message, messageType: "error" }
                }
            }
        },
        getBulkSelectedOrders: (state) => {
            return {
                ...state,
                bulkSelectedOrdersData: {
                    ...state.bulkSelectedOrdersData,
                    data:[],
                    loading: true
                }
            }
        },
        getBulkSelectedOrdersSuccess: (state, action) => {
            return {
                ...state,
                bulkSelectedOrdersData: {
                    ...state.bulkSelectedOrdersData,
                    data: action && action.payload && action.payload.data && action.payload.data.data,
                    meta: action && action.payload && action.payload.data && action.payload.data.meta,
                    loading: false
                }
            }
        },
        getBulkSelectedOrdersFailure: (state, action) => {
            return {
                ...state,
                bulkSelectedOrdersData: {
                    ...state.bulkSelectedOrdersData,
                    loading: false,
                    notification: { show: true, messageContent: action.data && action.data.message, messageType: "error" }
                }
            }
        },
        getOrdersColumn: (state, action) => {
            return {
                ...state,
                ordersColumns: {
                    ...state.ordersColumns,
                    loading: true
                }
            }
        },
        getOrdersColumnSuccess: (state, action) => {
            return {
                ...state,
                ordersColumns: {
                    ...state.ordersColumns,
                    loading: false,
                    columns: action && action.payload && action.payload.data && action.payload.data.data
                }
            }
        },
        getOrdersColumnFailure: (state, action) => {
            return {
                ...state,
                ordersColumns: {
                    ...state.ordersColumns,
                    loading: false,
                    notification: { show: true, messageContent: [action.payload && action.payload.data && action.payload.data.message], messageType: "error" }
                }
            }
        },
        postOrdersColumn: (state, action) => {
            return {
                ...state,
                ordersColumns: {
                    ...state.ordersColumns,
                    loading: true
                }
            }
        },
        postOrdersColumnSuccess: (state, action) => {
            return {
                ...state,
                ordersColumns: {
                    ...state.ordersColumns,
                    loading: false,
                    notification: { show: true, messageContent: [action.payload && action.payload.data && action.payload.data.message], messageType: "success" }
                }
            }
        },
        postOrdersColumnFailure: (state, action) => {
            return {
                ...state,
                ordersColumns: {
                    ...state.ordersColumns,
                    loading: false,
                    notification: { show: true, messageContent: [action.payload && action.payload.data && action.payload.data.message], messageType: "error" }
                }
            }
        },
        postDataCreateShipment: (state, action) => {
            return {
                ...state,
                createShipment: {
                    ...state.createShipment,
                    loading: true
                }
            }
        },
        postDataCreateShipmentSuccess: (state, action) => {
            return {
                ...state,
                createShipment: {
                    ...state.createShipment,
                    message: action && action.payload && action.payload.data && action.payload.data.message,
                    loading: false,
                    notification: { show: true, messageContent: (action.payload && action.payload.data && action.payload.data.desc && action.payload.data.desc === "success") ? ["Shipment Created Successfully"] : [""], messageType: "success" }
                }
            }
        },
        postDataCreateShipmentFailure: (state, action) => {
            return {
                ...state,
                createShipment: {
                    ...state.createShipment,
                    loading: false,
                    notification: { show: true, messageContent: action.payload && action.payload.data && action.payload.data.message, messageType: "error" }
                }
            }
        },
        postDataBulkShipment: (state, action) => {
            return {
                ...state,
                createShipmentBulk: {
                    ...state.createShipmentBulk,
                    loading: true
                }
            }
        },
        postDataBulkShipmentSuccess: (state, action) => {
            return {
                ...state,
                createShipmentBulk: {
                    ...state.createShipmentBulk,
                    loading: false,
                    notification: { show: true, messageContent: action.payload && action.payload.data && action.payload.data.message, messageType: "success" }
                }
            }
        },
        postDataBulkShipmentFailure: (state, action) => {
            return {
                ...state,
                createShipmentBulk: {
                    ...state.createShipmentBulk,
                    loading: false,
                    notification: { show: true, messageContent: action.payload && action.payload.data && action.payload.data.message, messageType: "error" }
                }
            }
        },
        postTrackingNumberVoid: (state, action) => {
            return {
                ...state,
                voidShipment: {
                    ...state.voidShipment,
                    loading: true
                }
            }
        },
        postTrackingNumberVoidSuccess: (state, action) => {
            return {
                ...state,
                voidShipment: {
                    ...state.voidShipment,
                    loading: false,
                    notification: { show: true, messageContent: action.payload && action.payload.data && action.payload.data.message, messageType: "success" }
                }
            }
        },
        postTrackingNumberVoidFailure: (state, action) => {
            return {
                ...state,
                voidShipment: {
                    ...state.voidShipment,
                    loading: false,
                    notification: { show: true, messageContent: action.payload && action.payload.data && action.payload.data.message, messageType: "error" }
                }
            }
        },
        postTrackingNumberCloseManifest: (state, action) => {
            return {
                ...state,
                manifestClose: {
                    ...state.manifestClose,
                    loading: true
                }
            }
        },
        postTrackingNumberCloseManifestSuccess: (state, action) => {
            return {
                ...state,
                manifestClose: {
                    ...state.manifestClose,
                    loading: false,
                    notification: { show: true, messageContent: (action.payload && action.payload.data && action.payload.data.desc && action.payload.data.desc === "success") ? ["Manifest close triggered successfully"] : [""], messageType: "success" }
                }
            }
        },
        postTrackingNumberCloseManifestFailure: (state, action) => {
            return {
                ...state,
                manifestClose: {
                    ...state.manifestClose,
                    loading: false,
                    notification: { show: true, messageContent: action.payload && action.payload.data && action.payload.data.message, messageType: "error" }
                }
            }
        },
        printBulkLabel: (state, action) => {
            return {
                ...state,
                bulkLabel: {
                    ...state.bulkLabel,
                    loading: true
                }
            }
        },
        printBulkLabelSuccess: (state, action) => {
            return {
                ...state,
                bulkLabel: {
                    ...state.bulkLabel,
                    loading: false,
                    is_shown: false,
                    filename: action.payload && action.payload.data && action.payload.data.filename
                }
            }
        },
        printBulkLabelFailure: (state, action) => {
            return {
                ...state,
                bulkLabel: {
                    ...state.bulkLabel,
                    loading: false,
                    notification: { show: true, messageContent: action.payload && action.payload.data && action.payload.data.message, messageType: "error" }
                }
            }
        },
        getDistinctCarriers: (state, action) => {
            return {
                ...state,
                carriers: {
                    ...state.carriers,
                    loading: true
                }
            }
        },
        getDistinctCarriersSuccess: (state, action) => {
            return {
                ...state,
                carriers: {
                    ...state.carriers,
                    loading: false,
                    data: action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.carriers
                }
            }
        },
        getDistinctCarriersFailure: (state, action) => {
            return {
                ...state,
                carriers: {
                    ...state.carriers,
                    loading: false,
                    notification: { show: true, messageContent: action.payload && action.payload.data && action.payload.data.message, messageType: "error" }
                }
            }
        },
        getPlatformTags: (state, action) => {
            return {
                ...state,
                platformTags: {
                    ...state.platformTags,
                    loading: true
                }
            }
        },
        getPlatformTagsSuccess: (state, action) => {
            return {
                ...state,
                platformTags: {
                    ...state.platformTags,
                    loading: false,
                    data: action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.platform_tags
                }
            }
        },
        getPlatformTagsFailure: (state, action) => {
            return {
                ...state,
                platformTags: {
                    ...state.platformTags,
                    loading: false,
                    notification: { show: true, messageContent: action.payload && action.payload.data && action.payload.data.message, messageType: "error" }
                }
            }
        },
        getLineItemsWeight: (state, action) => {
            return {
                ...state,
                lineItemsWeight: {
                    ...state.lineItemsWeight,
                    loading: true
                }
            }
        },
        getLineItemsWeightSuccess: (state, action) => {
            return {
                ...state,
                lineItemsWeight: {
                    ...state.lineItemsWeight,
                    loading: false,
                    data: action.payload && action.payload.data && action.payload.data.data
                }
            }
        },
        getLineItemsWeightFailure: (state, action) => {
            return {
                ...state,
                lineItemsWeight: {
                    ...state.lineItemsWeight,
                    loading: false,
                }
            }
        },
        updateAssignTags: (state, action) => {
            return {
                ...state,
                assignTags: {
                    ...state.assignTags,
                    loading: true
                }
            }
        },
        updateAssignTagsSuccess: (state, action) => {
            return {
                ...state,
                assignTags: {
                    ...state.assignTags,
                    loading: false,
                    notification: { show: true, messageContent: [action.payload && action.payload.data && action.payload.data.message], messageType: "success" }
                }
            }
        },
        updateAssignTagsFailure: (state, action) => {
            return {
                ...state,
                assignTags: {
                    ...state.assignTags,
                    loading: false,
                    notification: { show: true, messageContent: [action.payload && action.payload.data && action.payload.data.message], messageType: "error" }

                }
            }
        },
        orderUpdate: (state, action) => {
            return {
                ...state,
                orderUpdate: {
                    ...state.orderUpdate,
                    loading: true
                }
            }
        },
        orderUpdateSuccess: (state, action) => {
            return {
                ...state,
                orderUpdate: {
                    ...state.orderUpdate,
                    loading: false,
                    notification: { show: true, messageContent: [action.payload && action.payload.data && action.payload.data.message], messageType: "success" }
                }
            }
        },
        orderUpdateFailure: (state, action) => {
            return {
                ...state,
                orderUpdate: {
                    ...state.orderUpdate,
                    loading: false,
                    notification: { show: true, messageContent: [action.payload && action.payload.data && action.payload.data.message], messageType: "error" }

                }
            }
        },

        syncOrderData: (state, action) => {
            return {
                ...state,
                syncOrderData: {
                    ...state.syncOrderData,
                    loading: true
                }
            }
        },
        syncOrderDataSuccess: (state, action) => {
            return {
                ...state,
                syncOrderData: {
                    ...state.syncOrderData,
                    loading: false,
                    notification: { show: true, messageContent: [action.payload && action.payload.data && action.payload.data.message], messageType: "success" }
                }
            }
        },
        syncOrderDataFailure: (state, action) => {
            return {
                ...state,
                syncOrderData: {
                    ...state.syncOrderData,
                    loading: false,
                    notification: { show: true, messageContent: [action.payload && action.payload.data && action.payload.data.message], messageType: "error" }

                }
            }
        },
        postDataBulkOrderValidation: (state, action) => {
            return {
                ...state,
                bulkOrderValidation: {
                    ...state.bulkOrderValidation,
                    loading: true,
                    data:[]
                }
            }
        },
        postDataBulkOrderValidationSuccess: (state, action) => {
            return {
                ...state,
                bulkOrderValidation: {
                    ...state.bulkOrderValidation,
                    loading: false,
                    data:action.payload && action.payload.data && action.payload.data.data
                }
            }
        },
        postDataBulkOrderValidationFailure: (state, action) => {
            return {
                ...state,
                bulkOrderValidation: {
                    ...state.bulkOrderValidation,
                    loading: false,
                    data:[]
                }
            }
        },

        getOrdersUIUpdate: (state) => {
            return {
                ...state,
                orderUIUpdate: {
                    ...state.orderUIUpdate,
                    data:[],
                    loading: true
                }
            }
        },
        getOrdersUIUpdateSuccess: (state, action) => {
            return {
                ...state,
                orderUIUpdate: {
                    ...state.orderUIUpdate,
                    data: action && action.payload && action.payload.data && action.payload.data.data,
                    meta: action && action.payload && action.payload.data && action.payload.data.meta,
                    loading: false
                }
            }
        },
        getOrdersUIUpdateFailure: (state, action) => {
            return {
                ...state,
                orderUIUpdate: {
                    ...state.orderUIUpdate,
                    loading: false,
                    notification: { show: true, messageContent: action.data && action.data.message, messageType: "error" }
                }
            }
        },
        postDatagridData: (state) => {
            return {
                ...state,
                orderUIUpdate: {
                    ...state.orderUIUpdate,
                    data:[],
                    loading: true
                }
            }
        },
        postDatagridDataSuccess: (state, action) => {
            return {
                ...state,
                orderUIUpdate: {
                    ...state.orderUIUpdate,
                    notification: { show: true, messageContent: [action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.message], messageType: "success" },
                    loading: false
                }
            }
        },
        postDatagridDataFailure: (state, action) => {
            return {
                ...state,
                orderUIUpdate: {
                    ...state.orderUIUpdate,
                    loading: false,
                    notification: { show: true, messageContent: action.data && action.data.message, messageType: "error" }
                }
            }
        },
        postBulkDimensions: (state, action) => {
            return {
                ...state,
                addBulkDimension: {
                    ...state.addBulkDimension,
                    loading: true
                }
            }
        },
        postBulkDimensionsSuccess: (state, action) => {
            return {
                ...state,
                addBulkDimension: {
                    ...state.addBulkDimension,
                    loading: false,
                    notification: { show: true, messageContent: [action.payload && action.payload.data && action.payload.data.message], messageType: "success" }
                }
            }
        },
        postBulkDimensionsFailure: (state, action) => {
            return {
                ...state,
                addBulkDimension: {
                    ...state.addBulkDimension,
                    loading: false,
                    notification: { show: true, messageContent: [action.payload && action.payload.data && action.payload.data.message], messageType: "error" }

                }
            }
        },
        
        deleteOrdersData: (state, action) => {
            return {
                ...state,
                deleteOrders: {
                    ...state.deleteOrders,
                    loading: true
                }
            }
        },
        deleteOrdersDataSuccess: (state, action) => {
            return {
                ...state,
                deleteOrders: {
                    ...state.deleteOrders,
                    loading: false,
                    data:action.payload && action.payload.data,
                    notification: { show: true, messageContent: [action.payload && action.payload.data && action.payload.data.message], messageType: "success" }
                }
            }
        },
        deleteOrdersDataFailure: (state, action) => {
            return {
                ...state,
                deleteOrders: {
                    ...state.deleteOrders,
                    loading: false,
                    data:action.payload && action.payload.data,
                    notification: { show: true, messageContent: [action.payload && action.payload.data && action.payload.data.message], messageType: "error" }

                }
            }
        },
        
        resetOrderNotification: (state, action) => {
            return {
                ...state,
                ordersData: {
                    ...state.ordersData,
                    notification: { show: false, messageContent: "", messageType: "success" }
                },
                ordersColumns: {
                    ...state.ordersColumns,
                    notification: { show: false, messageContent: "", messageType: "success" }
                },
                createShipment: {
                    ...state.createShipment,
                    notification: { show: false, messageContent: "", messageType: "success" }
                },
                createShipmentBulk: {
                    ...state.createShipmentBulk,
                    notification: { show: false, messageContent: "", messageType: "success" }
                },
                voidShipment: {
                    ...state.voidShipment,
                    notification: { show: false, messageContent: "", messageType: "success" }
                },
                manifestClose: {
                    ...state.manifestClose,
                    notification: { show: false, messageContent: "", messageType: "success" }
                },
                bulkLabel: {
                    ...state.bulkLabel,
                    filename: "",
                    is_shown: true,
                    notification: { show: false, messageContent: "", messageType: "success" }
                },
                carriers: {
                    ...state.carriers,
                    notification: { show: false, messageContent: "", messageType: "success" }
                },
                platformTags: {
                    ...state.platformTags,
                    notification: { show: false, messageContent: "", messageType: "success" }
                },
                assignTags: {
                    ...state.assignTags,
                    notification: { show: false, messageContent: "", messageType: "success" }
                },
                orderUpdate: {
                    ...state.orderUpdate,
                    notification: { show: false, messageContent: "", messageType: "success" }
                },
                syncOrderData: {
                    ...state.syncOrderData,
                    notification: { show: false, messageContent: "", messageType: "success" }
                },
                orderUIUpdate: {
                    ...state.orderUIUpdate,
                    notification: { show: false, messageContent: "", messageType: "success" }
                },
                bulkOrderValidation:{
                    ...state.bulkOrderValidation,
                    data: []
                },
                addBulkDimension: {
                    ...state.addBulkDimension,
                    notification: { show: false, messageContent: "", messageType: "success" }
                },
                deleteOrders: {
                    ...state.deleteOrders,
                    data:[],
                    notification: { show: false, messageContent: "", messageType: "success" }
                },
            }
        },
        resetOrderUpdateNotificationsData: (state, action) => {
            return {
                ...state,
                orderUpdate: {
                    ...state.orderUpdate,
                    notification: { show: false, messageContent: "", messageType: "success" }
                },
            }
        }
    }
})
export const { getOrders, getOrdersSuccess, getOrdersFailure } = ordersslice.actions;

export const fetchOrders = meta => dispatch => {
    const qs = Object.keys(meta)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(meta[key])}`)
        .join('&');
    dispatch(getOrders());
    try {
        source && source.cancel('Operation canceled due to new request.');
        source = axios.CancelToken.source();
        LABELGENAPI.get('/orders/info?' + qs, {
            cancelToken: source.token
        }).then(function (response) {
            dispatch(getOrdersSuccess(response));
        }).catch(function (error) {
            if (!axios.isCancel(error)) {
                dispatch(getOrdersFailure(error.response));
            }
        });
    } catch (error) {
        if (!axios.isCancel(error)) {
            dispatch(getOrdersFailure(error.response));
        }
    }
};

export const { getOrdersColumn, getOrdersColumnSuccess, getOrdersColumnFailure } = ordersslice.actions;

export const fetchOrderColumns = () => {
    return (dispatch) => {
        dispatch(getOrdersColumn());
        try {
            LABELGENAPI
                .get('/orders/columns')
                .then(function (response) {
                    dispatch(getOrdersColumnSuccess(response));
                })
                .catch(function (error) {
                    dispatch(getOrdersColumnFailure(error.response));
                });
        } catch (error) {
            dispatch(getOrdersColumnFailure());
        }
    };
};

export const { postOrdersColumn, postOrdersColumnSuccess, postOrdersColumnFailure } = ordersslice.actions;

export const saveOrdersColumns = (data) => {
    return (dispatch) => {
        dispatch(postOrdersColumn());
        try {
            LABELGENAPI
                .post('/orders/columns', data)
                .then(function (response) {
                    dispatch(postOrdersColumnSuccess(response));
                    dispatch(fetchOrderColumns());
                })
                .catch(function (error) {
                    dispatch(postOrdersColumnFailure(error.response));
                });
        } catch (error) {
            dispatch(postOrdersColumnFailure());
        }
    };
};

export const { postDataCreateShipment, postDataCreateShipmentSuccess, postDataCreateShipmentFailure } = ordersslice.actions;

export const dopostDataCreateShipment = (data, api_token, meta) => {
    return (dispatch) => {
        dispatch(postDataCreateShipment());
        try {
            LABELINGCHIMP_SAM_API
                .post('/create-shipment', data, { headers: { 'x-api-key': api_token, 'Content-Type': 'application/json' } })
                .then(function (response) {
                    dispatch(postDataCreateShipmentSuccess(response));
                    try {
                        if (response.data.desc === "success") {
                            setTimeout(() => { dispatch(fetchOrders(meta)) }, 1000);
                        }
                    } catch (error) {
                        dispatch(postDataCreateShipmentFailure(error.response));
                    }
                })
                .catch(function (error) {
                    dispatch(postDataCreateShipmentFailure(error.response));
                });
        } catch (error) {
            dispatch(postDataCreateShipmentFailure());
        }
    };
};

export const { postDataBulkShipment, postDataBulkShipmentSuccess, postDataBulkShipmentFailure } = ordersslice.actions;

export const dopostDataBulkShipment = (data, api_token, meta) => {
    return (dispatch) => {
        dispatch(postDataBulkShipment());
        try {
            LABELINGCHIMP_SAM_API
                .post('/bulk-create-order-shipment', data, { headers: { 'x-api-key': api_token, 'Content-Type': 'application/json' } })
                .then(function (response) {
                    dispatch(postDataBulkShipmentSuccess(response));
                    try {
                        if (response.data.desc === "success") {
                            setTimeout(() => { dispatch(fetchOrders(meta)) }, 3000);
                        }
                    } catch (error) {
                        dispatch(postDataBulkShipmentFailure(error.response));
                    }
                })
                .catch(function (error) {
                    dispatch(postDataBulkShipmentFailure(error.response));
                });
        } catch (error) {
            dispatch(postDataBulkShipmentFailure());
        }
    };
};


export const { postTrackingNumberVoid, postTrackingNumberVoidSuccess, postTrackingNumberVoidFailure } = ordersslice.actions;

export const doPostTrackingNumberVoid = (data, api_token, updateOrderData, meta) => {
    return (dispatch) => {
        dispatch(postTrackingNumberVoid());
        try {
            LABELINGCHIMP_SAM_API
                .post('/void-label', data, { headers: { 'x-api-key': api_token, 'Content-Type': 'application/json' } })
                .then(function (response) {
                    dispatch(postTrackingNumberVoidSuccess(response));
                    try {
                        if (response.data.desc === "success") {
                            setTimeout(() => { dispatch(fetchOrders(meta)) }, 3000);
                        }
                    } catch (error) {
                        dispatch(postTrackingNumberVoidFailure(error.response));
                    }
                })
                .catch(function (error) {
                    dispatch(postTrackingNumberVoidFailure(error.response));
                });
        } catch (error) {
            dispatch(postTrackingNumberVoidFailure());
        }
    };
};

export const { printBulkLabel, printBulkLabelSuccess, printBulkLabelFailure } = ordersslice.actions;

export const printBulkLabels = (data, userid) => {
    return (dispatch) => {
        dispatch(printBulkLabel());
        try {
            LABELINGCHIMP_LABEL_API
                .post('/merge-pdf?&file_prefix=label&user_id=' + userid, data)
                .then(function (response) {
                    dispatch(printBulkLabelSuccess(response));
                    dispatch(resetOrderNotification());
                })
                .catch(function (error) {
                    dispatch(printBulkLabelFailure(error.response));
                    dispatch(resetOrderNotification());
                });
        } catch (error) {
            dispatch(printBulkLabelFailure());
        }
    };
};

export const { getDistinctCarriers, getDistinctCarriersSuccess, getDistinctCarriersFailure } = ordersslice.actions;

export const fetchDistinctCarriers = () => {
    return (dispatch) => {
        dispatch(getDistinctCarriers());
        try {
            LABELGENAPI
                .get('/orders/distinct-carriers')
                .then(function (response) {
                    dispatch(getDistinctCarriersSuccess(response));
                })
                .catch(function (error) {
                    dispatch(getDistinctCarriersFailure(error.response));
                });
        } catch (error) {
            dispatch(getDistinctCarriersFailure());
        }
    };
};

export const { getPlatformTags, getPlatformTagsSuccess, getPlatformTagsFailure } = ordersslice.actions;

export const fetchPlatformTags = () => {
    return (dispatch) => {
        dispatch(getPlatformTags());
        try {
            LABELGENAPI
                .get('/orders/platform-tags')
                .then(function (response) {
                    dispatch(getPlatformTagsSuccess(response));
                })
                .catch(function (error) {
                    dispatch(getPlatformTagsFailure(error.response));
                });
        } catch (error) {
            dispatch(getPlatformTagsFailure());
        }
    };
};


export const { postTrackingNumberCloseManifest, postTrackingNumberCloseManifestSuccess, postTrackingNumberCloseManifestFailure } = ordersslice.actions;

export const dopostTrackingNumberCloseManifest = (data, api_token, meta) => {
    return (dispatch) => {
        dispatch(postTrackingNumberCloseManifest());
        try {
            LABELINGCHIMP_SAM_API
                .post('/close-manifest', data, { headers: { 'x-api-key': api_token, 'Content-Type': 'application/json' } })
                .then(function (response) {
                    dispatch(postTrackingNumberCloseManifestSuccess(response));
                    try {
                        if (response.data.desc === "success") {
                            setTimeout(() => { dispatch(fetchOrders(meta)) }, 3000);
                        }
                    } catch (error) {
                        dispatch(postTrackingNumberCloseManifestFailure(error.response));
                    }
                })
                .catch(function (error) {
                    dispatch(postTrackingNumberCloseManifestFailure(error.response));
                });
        } catch (error) {
            dispatch(postTrackingNumberCloseManifestFailure());
        }
    };
};

export const { getLineItemsWeight, getLineItemsWeightSuccess, getLineItemsWeightFailure } = ordersslice.actions;

export const fetchLineItemsWeight = () => {
    return (dispatch) => {
        dispatch(getLineItemsWeight());
        try {
            LABELGENAPI
                .get('/settings/getlineitemsweight')
                .then(function (response) {
                    dispatch(getLineItemsWeightSuccess(response));
                })
                .catch(function (error) {
                    dispatch(getLineItemsWeightFailure(error.response));
                });
        } catch (error) {
            dispatch(getLineItemsWeightFailure());
        }
    };
};


export const { updateAssignTags, updateAssignTagsSuccess, updateAssignTagsFailure } = ordersslice.actions;

export const updateAssignTag = (data,meta) => {
    return (dispatch) => {
        dispatch(updateAssignTags());
        try {
            LABELGENAPI
                .post('/orders/assign-tags',data)
                .then(function (response) {
                    dispatch(updateAssignTagsSuccess(response));
                    dispatch(fetchOrders(meta))
                })
                .catch(function (error) {
                    dispatch(updateAssignTagsFailure(error.response));
                });
        } catch (error) {
            dispatch(updateAssignTagsFailure());
        }
    };
};

export const { getBulkSelectedOrders, getBulkSelectedOrdersSuccess, getBulkSelectedOrdersFailure } = ordersslice.actions;

export const fetchBulkSelectedOrders = meta => dispatch => {
    const qs = Object.keys(meta)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(meta[key])}`)
        .join('&');
    dispatch(getBulkSelectedOrders());
    try {
        source && source.cancel('Operation canceled due to new request.');
        source = axios.CancelToken.source();
        LABELGENAPI.get('/orders/docinfo?' + qs, {
            cancelToken: source.token
        }).then(function (response) {
            dispatch(getBulkSelectedOrdersSuccess(response));
        }).catch(function (error) {
            if (!axios.isCancel(error)) {
                dispatch(getBulkSelectedOrdersFailure(error.response));
            }
        });
    } catch (error) {
        if (!axios.isCancel(error)) {
            dispatch(getBulkSelectedOrdersFailure(error.response));
        }
    }
};

export const { orderUpdate, orderUpdateSuccess, orderUpdateFailure } = ordersslice.actions;

export const orderUpdateData = (data) => {
    return (dispatch) => {
        dispatch(orderUpdate());
        try {
            LABELGENAPI
                .post('/orders/order-update',data)
                .then(function (response) {
                    dispatch(orderUpdateSuccess(response));
                })
                .catch(function (error) {
                    dispatch(orderUpdateFailure(error.response));
                });
        } catch (error) {
            dispatch(orderUpdateFailure());
        }
    };
};

export const { syncOrderData, syncOrderDataSuccess, syncOrderDataFailure } = ordersslice.actions;

export const syncOrderDatas = (data) => {
    return (dispatch) => {
        dispatch(syncOrderData());
        try {
            INTEGRATIONS_API
                .post('ss/today-orders?apikey='+data)
                .then(function (response) {
                    dispatch(syncOrderDataSuccess(response));
                })
                .catch(function (error) {
                    dispatch(syncOrderDataFailure(error.response));
                });
        } catch (error) {
            dispatch(syncOrderDataFailure());
        }
    };
};

export const { postDataBulkOrderValidation, postDataBulkOrderValidationSuccess, postDataBulkOrderValidationFailure } = ordersslice.actions;

export const postBulkOrderValidation = (data) => {
    return (dispatch) => {
        dispatch(postDataBulkOrderValidation());
        try {
            LABELGENAPI
                .post('/bulk-shipments/bulk-orders-validate',data)
                .then(function (response) {
                    dispatch(postDataBulkOrderValidationSuccess(response));
                })
                .catch(function (error) {
                    dispatch(postDataBulkOrderValidationFailure(error.response));
                });
        } catch (error) {
            dispatch(postDataBulkOrderValidationFailure());
        }
    };
};

export const { getOrdersUIUpdate, getOrdersUIUpdateSuccess, getOrdersUIUpdateFailure } = ordersslice.actions;


export const fetchOrdersUI = (data) => {
    return (dispatch) => {
        dispatch(getOrdersUIUpdate());
        try {
            LABELGENAPI.get('/config/data-grid?report_name=orders')
                .then(function (response) {
                    dispatch(getOrdersUIUpdateSuccess(response));
                }).catch(function (error) {
                    dispatch(getOrdersUIUpdateFailure(error.response));
                })
        }catch (error) {
            dispatch(getOrdersUIUpdateFailure(error.response));
        }
    };
};
export const { postDatagridData, postDatagridDataSuccess, postDatagridDataFailure } = ordersslice.actions;

export const storeDatagridData = (data) => {
    return (dispatch) => {
        dispatch(postDatagridData());
        try {
            LABELGENAPI.post('/config/data-grid', data)
                .then(function (response) {
                    dispatch(postDatagridDataSuccess(response));
                    dispatch(fetchOrdersUI());
                }).catch(function (error) {
                    dispatch(postDatagridDataFailure(error.response));
                })
        }
        catch (error) {
            dispatch(postDatagridDataFailure(error.response));
        }
    }
}

export const { postBulkDimensions, postBulkDimensionsSuccess, postBulkDimensionsFailure } = ordersslice.actions;

export const updateBulkDimension = (data,meta) => {
    return (dispatch) => {
        dispatch(postBulkDimensions());
        try {
            LABELGENAPI
                .post('/orders/addbulkdimension',data)
                .then(function (response) {
                    dispatch(postBulkDimensionsSuccess(response));
                    dispatch(fetchOrders(meta))
                })
                .catch(function (error) {
                    dispatch(postBulkDimensionsFailure(error.response));
                });
        } catch (error) {
            dispatch(postBulkDimensionsFailure());
        }
    };
};

export const { deleteOrdersData, deleteOrdersDataSuccess, deleteOrdersDataFailure } = ordersslice.actions;

export const postdeleteOrdersData = (data) => {
    return (dispatch) => {
        dispatch(deleteOrdersData());
        try {
            LABELGENAPI
                .post('/orders/order-delete',data)
                .then(function (response) {
                    dispatch(deleteOrdersDataSuccess(response));
                })
                .catch(function (error) {
                    dispatch(deleteOrdersDataFailure(error.response));
                });
        } catch (error) {
            dispatch(deleteOrdersDataFailure());
        }
    };
};

export const { resetOrderNotification } = ordersslice.actions;

export const resetOrderNotifications = () => {
    return (dispatch) => {
        dispatch(resetOrderNotification());
    }
}
export const { resetOrderUpdateNotificationsData } = ordersslice.actions;

export const resetOrderUpdateNotifications = () => {
    return (dispatch) => {
        dispatch(resetOrderUpdateNotificationsData());
    }
}

export default ordersslice.reducer;
