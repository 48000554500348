import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Typography,
    FormControl,
    Pagination,
    IconButton,
    Tooltip,
    Modal,
    Box,
    Button
} from "@mui/material";
import Chip from '@mui/material/Chip';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SearchIcon from '@mui/icons-material/Search';
import LinearProgress from '@mui/material/LinearProgress';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import BulkOrderCreateDrawer from '../../pages/General/BulkOrderCreateDrawer'
import { getBulkLabels,resetBulkLabel } from '../../pages/BulkOrder/bulkPrintSlice';

import {
    DataGridPro,
    GridToolbarContainer,
    GridFooterContainer,
    useGridApiRef,
    GridAutosizeOptions
} from '@mui/x-data-grid-pro';
import { fetchBulkOrderReference, fetchBulkOrderDetail, resetBulkOrderNotifications } from './bulkOrderSlice';

//Custom Components Import Starts
import NorowsData from '../General/NorowsData'
import BulkOrderDrawer from '../General/BulkOrderDrawer'
import Notification from '../General/Notification'
import Filters from './Filters'
import Search from './Search'
//Custom Components Import Ends

const BulkOrderPage = () => {

    const dispatch = useDispatch();
    const bulkorder = useSelector(state => state.bulkorder && state.bulkorder.bulkorderdata);
    const profile = useSelector(state => state.auth);
    const [meta, setMeta] = useState(bulkorder.meta);
    const [bulkOrderDrawerVisible, setBulkOrderDrawerVisible] = useState(false);
    const [bulkOrderCreateDrawerVisible, setBulkOrderCreateDrawerVisible] = useState(false);
    const [referenceId, setReferenceId] = useState("");
    const [showFilter, setShowFilter] = useState(false);
    const [showSearch, setShowSearch] = useState(false);

    const [bulkdrawershow, setbulkdrawershow] = useState(false);

    const bulkOrderCreateCallback = (item) => {
        setBulkOrderCreateDrawerVisible(true)
    }
    const bulkOrderCreateOnClose = (values) => {
        setBulkOrderCreateDrawerVisible(false);
    };
    const bulkOrderCallback = (item) => {
        setReferenceId(item)
        dispatch(fetchBulkOrderDetail(item));
        setBulkOrderDrawerVisible(true)
        setbulkdrawershow(true)
        let userid=profile && profile.profile && profile.profile.id
        let referenceid=item
        dispatch(getBulkLabels(referenceid))
    }
    const bulkOrderOnClose = (values) => {
        setBulkOrderDrawerVisible(false);
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };
    const columns = [

        {
            field: '_id',
            headerName: 'Reference',
            sortable: false,
            minWidth:'250',
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return <span style={{ color: 'rgb(24, 144, 255)', cursor: 'pointer' }} onClick={e => { bulkOrderCallback(params.row._id) }}>{params.row._id}</span>

            }
        },
        {
            field: 'upload_type',
            headerName: 'Type',
            sortable: false,
            minWidth:'200',
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                if(params.row.upload_type && params.row.upload_type==='ORDER'){
                    return <Chip label={"ONLY ORDER"} color="primary"  style={{ backgroundColor:'#884EA0',width:'165px',height:'28px',fontSize:'12px' }}/>
                }else{
                    return <Chip label={"ORDER + SHIPMENT"} color="success"  style={{backgroundColor:'#154360',width:'165px',height:'28px',fontSize:'12px' }}/>
                }
            }
        },
        {
            field: 'total_shipments',
            headerName: <Typography style={{fontWeight:'600',fontSize:'14px'}}>Total<br /> shipments</Typography>,
            sortable: false,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'success_count',
            headerName: <Typography style={{fontWeight:'600',fontSize:'14px'}}>Success<br /> Count</Typography>,
            sortable: false,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'failure_count',
            headerName: <Typography style={{fontWeight:'600',fontSize:'14px'}}>Failure<br /> Count</Typography>,
            sortable: false,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'status',
            headerName: 'Status',
            sortable: false,
            minWidth:'200',
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                if(params.row.status=='PENDING'){
                    return <Chip label={params.row.status} color="primary"  style={{ width:'120px',height:'28px',fontSize:'12px' }}/>
                }else if(params.row.status=='COMPLETED'){
                    return <Chip label={params.row.status} color="success"  style={{ width:'120px',height:'28px',fontSize:'12px' }}/>
                }else{
                    return "-"
                }
            }
        },
        {
            field: 'notes',
            headerName: 'Notes',
            sortable: false,
            minWidth:'200',
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'created_at',
            headerName: 'Uploaded Date',
            headerClassName: 'super-app-theme--header',
            minWidth:'200'        
        }

    ];

    const onSortChange = (currentSorting) => {
        setMeta({
            ...meta,
            ...{
                sorting: JSON.stringify(currentSorting)
            }
        });
    }

    const onChangePerPage = (event) => {
        setMeta({
            ...meta,
            ...{
                page: 1,
                per_page: event.target.value
            }
        });
    }

    const onChangePagination = (event, page) => {
        setMeta({
            ...meta,
            ...{
                page: page
            }
        });
    }

    function CustomTotalRows() {

        return (
            <div className="custom-total-rows">
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    Showing {bulkorder && bulkorder.data && bulkorder.data.length} of {(bulkorder && bulkorder.meta && bulkorder.meta.total) ? bulkorder.meta.total : 0}
                </FormControl>
            </div>
        )
    }


    function CustomPagesizeoptions() {

        return (
            <div className="custom-page-size-options">
                <span>
                    Show <select
                        value={meta && meta.per_page}
                        onChange={onChangePerPage}
                    >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </select> entries
                </span>
            </div>
        )
    }

    function CustomToolbar() {

        return (
            <GridToolbarContainer>
                <CustomPagesizeoptions />
                <CustomTotalRows />
            </GridToolbarContainer>
        );
    }

    function CustomPagination() {

        return (
            <GridFooterContainer style={{justifyContent:'end'}}>
                <Pagination
                    color="primary"
                    shape="circular"
                    page={bulkorder && bulkorder.meta && bulkorder.meta.current_page}
                    count={bulkorder && bulkorder.meta && bulkorder.meta.last_page}
                    onChange={onChangePagination}
                />
            </GridFooterContainer>
        );
    }


    useEffect(() => {
        dispatch(fetchBulkOrderReference(meta))
    }, [meta]);

    const onMenuClick = (type, show) => {
        setShowSearch((type === "search" && show === true) ? true : false)
        setShowFilter((type === "filter" && show === true) ? true : false)
    }

    return (
        <>
            {(bulkorder  && bulkorder.notification && bulkorder.notification.show) &&
                    <Notification
                        is_open={bulkorder.notification.show}
                        messageType={bulkorder.notification.messageType}
                        messages={bulkorder.notification.messageContent}
                        handleClose={() => dispatch(resetBulkOrderNotifications())}
                    />}
            <div className="scmaincard">
                <div className="sccardhead">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="h5" gutterBottom component="div">
                            Bulk Order
                    </Typography>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ul className="features-list">
                            <li>
                                <Tooltip title="Bulk orders upload" placement="top-start">
                                    <IconButton onClick={bulkOrderCreateCallback}>
                                        <UploadFileOutlinedIcon style={{ color: 'rgb(25, 118, 210)' }} />
                                    </IconButton>
                                </Tooltip>
                            </li>
                            <li>
                                <Tooltip title="Seacrh" placement="top-start">
                                    <IconButton style={(showSearch) ? { backgroundColor: 'rgb(230, 230, 230)' } : {}} onClick={() => onMenuClick("search", !showSearch)}>
                                        <SearchIcon style={{ color: 'rgb(25, 118, 210)' }} />
                                    </IconButton>
                                </Tooltip>
                            </li>
                            {/* <li>
                                <Tooltip title="Filter" placement="top-start">
                                    <IconButton onClick={() => onMenuClick("filter", !showFilter)}>
                                        <FilterAltOutlinedIcon style={{ color: 'rgb(25, 118, 210)' }} />
                                    </IconButton>
                                </Tooltip>
                            </li> */}
                        </ul>
                    </div>
                </div>
                <Search visible={showSearch} meta={meta} setMeta={setMeta} />
                <Filters visible={showFilter} meta={meta} setMeta={setMeta} />
                <div style={{ width: '100%', background: '#fff', marginTop: "20px" }}>
                    <DataGridPro
                        style={{ paddingTop: "37px"}}
                        getRowId={(row) => row._id}
                        rows={bulkorder && bulkorder.data}
                        columns={columns}
                        autoHeight={true}
                        disableColumnFilter={true}
                        disableColumnMenu={true}
                        sortingMode="server"
                        onSortModelChange={(newSortModel) => onSortChange(newSortModel)}
                        sortModel={meta && meta.sorting && JSON.parse(meta.sorting)}
                        disableRowSelectionOnClick
                        slots={{
                            loadingOverlay: LinearProgress,
                            footer: CustomPagination,
                            toolbar: CustomToolbar,
                            NorowsData
                        }}
                        loading={
                            (bulkorder && bulkorder.loading)
                        }
                    />

                </div>

            </div>
            {bulkdrawershow &&
            <BulkOrderDrawer visible={bulkOrderDrawerVisible} referenceId={referenceId} bulkDraweronClose={bulkOrderOnClose} checkReload={bulkOrderCallback}
            />
            }
            <BulkOrderCreateDrawer
                visible={bulkOrderCreateDrawerVisible} onClose={bulkOrderCreateOnClose}
            />
        </>
    );
}

export default BulkOrderPage;
