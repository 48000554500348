import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CloseIcon from '@mui/icons-material/Close';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import KeyboardReturnOutlinedIcon from '@mui/icons-material/KeyboardReturnOutlined';
import { DataGrid } from '@mui/x-data-grid';

//custom components
import OrderStatus from '../../pages/General/OrderStatus'
import TrackingDrawer from '../../pages/General/TrackingDrawer'
import NewOrderCreateDrawer from '../General/NewOrder/NewOrderCreateDrawer'
import Printer from '../../pages/General/Printer'
import Date from '../../pages/General/Date'
import CarrierTracking from '../../pages/General/CarrierTracking'
import ShipmentRate from '../../pages/General/ShipmentRate'
import { fetchTrackingDetail } from '../Reports/Deliveries/trackingEventsSlice';


// mui components
import {
    Drawer,
    Card,
    Divider,
    IconButton,
    Tooltip,
    Box,
    Button,
    CardHeader,
    Modal,
    Typography
} from "@mui/material";

//actions
import {
    doPostTrackingNumberVoid,
    dopostTrackingNumberCloseManifest
} from '../Orders/ordersSlice';


const LineItemsDrawer = ({ visible, order, onClose }) => {
    const dispatch = useDispatch();
    const profile = useSelector(state => state.auth.profile);
    const orders = useSelector(state => state.orders);
    const [viewActionAnchorEl, setViewActionAnchorEl] = useState(null);
    const [openVoidConfirmModal, setOpenVoidConfirmModal] = useState(false)
    const [openManifestConfirmModal, setOpenManifestConfirmModal] = useState(false)
    const [trackingDrawerVisible, setTrackingDrawerVisible] = useState(false);
    const [trackingMessage, setTrackingMessage] = useState(null);
    const [printUrl, setPrintUrl] = useState("")
    const [isPrinted, setIsPrinted] = useState(true)

    const [isShipmentCreationFlow, setIsShipmentCreationFlow] = useState(false)
    const [newOrderFormData, setNewOrderFormData] = useState("");
    const [newOrderCreateDrawerVisible, setNewOrderCreateDrawerVisible] = useState(false);
    const [isDuplicateOrder, setIsDuplicateOrder] = useState(false);
    const [isReturnOrder, setIsReturnOrder] = useState(false);
    const [duplicateActionAnchorEl, setDuplicateActionAnchorEl] = useState(null);
    const [choosenTrackingNumber, setChoosenTrackingNumber] = useState(0);
    const [meta, setMeta] = useState(orders.ordersData.meta);
    const [isMultiPiece, setIsMultiPiece] = useState(false)

    const [voidShipmentData, setVoidShipmentData] = useState({
        "tracking_numbers": "",
        "doc_ids": ""
    })
    const [manifestShipmentData, setManifestShipmentData] = useState({
        "tracking_numbers": "",
        "doc_ids": ""
    })
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    const handleViewActionClick = (event) => {
        setViewActionAnchorEl(event.currentTarget);
    }

    const label_url = (row) => {
        let url = ""
        if (row &&
            row.label &&
            row.label.url_detachable &&
            row.manifest &&
            typeof row.label.is_void_in_progress === "undefined"
        ) {
            url = row.label.url_detachable;
        } else if (row &&
            row.label &&
            row.label.url_direct_detachable &&
            row.manifest &&
            typeof row.label.is_void_in_progress === "undefined"
        ) {
            url = row.label.url_direct_detachable;
        }else if (row &&
            row.label &&
            row.label.url &&
            row.manifest &&
            typeof row.label.is_void_in_progress === "undefined"
        ) {
            url = row.label.url;
        } else if (row &&
            row.label &&
            row.label.url_direct &&
            row.manifest &&
            typeof row.label.is_void_in_progress === "undefined"
        ) {
            url = row.label.url_direct;
        }
        return url
    }

    const handleViewActionClose = () => {
        setViewActionAnchorEl(null);
    }

    const trackingCallback = (carriername, trackingNumber, message = null) => {
        setTrackingMessage(message)
        let apikey = (profile && profile.api_token) ? profile.api_token : "";
        const data = { "carrier_name": carriername, 'tracking_number': trackingNumber }
        dispatch(fetchTrackingDetail(data, apikey))
        setTrackingDrawerVisible(true)
        let firstChar=trackingNumber.charAt(0);
        if(firstChar=='M'){
            setIsMultiPiece(true)
          }
    }
    const onCloseTracking = () => {
        setTrackingDrawerVisible(false);
    };

    const triggerVoidShipment = (trackingnumber, doc_id) => {
        setVoidShipmentData({
            "tracking_numbers": [trackingnumber],
            "doc_ids": [doc_id]
        })
        setOpenVoidConfirmModal(true)
    }

    const triggerManifestShipment = (trackingnumbers, doc_id) => {
        setManifestShipmentData({
            "tracking_numbers": [trackingnumbers],
            "doc_ids": [doc_id]
        })
        setOpenManifestConfirmModal(true)
    }

    const voidShipment = () => {
        var postData = {
            "tracking_numbers": voidShipmentData["tracking_numbers"],
            "ignore_errors": true
        }
        var updateOrderData = { "doc_ids": voidShipmentData["doc_ids"], "update_type": "void" }
        dispatch(doPostTrackingNumberVoid(postData, profile.api_token, updateOrderData, orders.ordersData.meta))
        setOpenVoidConfirmModal(false)
        onClose()
    }

    const manifestClose = () => {
        var postData = {
            "tracking_numbers": manifestShipmentData["tracking_numbers"],
            "ignore_errors": true
        }
        dispatch(dopostTrackingNumberCloseManifest(postData, profile.api_token, orders.ordersData.meta))
        setOpenManifestConfirmModal(false)
        onClose()
    }

    const newOrderCreateCallback = (item, is_return,tracking_number) => {
        if (item != "" && is_return == true) {
            setIsShipmentCreationFlow(true)
        } 
        setNewOrderFormData(item);
        setChoosenTrackingNumber(tracking_number)
        setNewOrderCreateDrawerVisible(true)
        setIsReturnOrder(is_return)
        setDuplicateActionAnchorEl(null);
    }

    const newOrderCreateOnClose = () => {
        setNewOrderCreateDrawerVisible(false);
    }

    const actionButton = (row) => {
        let actionButtonDisable = true;
        if (order.shipment &&
            order.shipment.tracking &&
            row.tracking_number &&
            row.tracking_number !== "" &&
            order.shipment.tracking[row.tracking_number] &&
            order.shipment.tracking[row.tracking_number].label &&
            order.shipment.tracking[row.tracking_number].manifest &&
            typeof order.shipment.tracking[row.tracking_number].label.is_void_in_progress === "undefined" &&
            order.shipment.tracking[row.tracking_number].manifest.is_closed === false
        ) {
            actionButtonDisable = false;
        }
        return actionButtonDisable;
    }

    const labelButton = (row) => {
        let labelButtonDisable = true;
        if (row &&
            row.label &&
            row.manifest &&
            typeof row.label.is_void_in_progress === "undefined" &&
            (row.label.url_detachable || row.label.url_direct_detachable || row.label.url || row.label.url_direct)
        ) {
            labelButtonDisable = false;
        }
        return labelButtonDisable;
    }

    const returnlabelButton = (row) => {
        let isreturnlabelButtonDisable = false;
        if ((order.shipment &&
            order.shipment.tracking &&
            row.tracking_number &&
            row.tracking_number !== "" &&
            order.shipment.tracking[row.tracking_number] &&
            order.shipment.tracking[row.tracking_number].label &&
            order.shipment.tracking[row.tracking_number].label.is_return &&
            order.shipment.tracking[row.tracking_number].label.is_return === true) ||
            (order.shipment.tracking[row.tracking_number].label.is_voided &&
            order.shipment.tracking[row.tracking_number].label.is_voided === true)

        ) {
            isreturnlabelButtonDisable = true;
        }
        return isreturnlabelButtonDisable;
    }

    const columns = [
        {
            field: 'tracking_number',
            headerName: 'Tracking Number',
            headerClassName: 'super-app-theme--header',
            width: 250,

            renderCell: (params) => {
                let tracking_number = (params.row.tracking_number)
                    ? params.row.tracking_number : "-";
                let carrier_name = (params.row && params.row.label && params.row.label.carrier_name)
                    ? params.row.label.carrier_name : "-";
                let message = (params.row && params.row.manifest && params.row.manifest.is_closed === false)
                    ? "This shipment manifest is not yet closed" : null;
                let tracking = { "tracking_number": tracking_number, "carrier_name": carrier_name }
                return (
                    <CarrierTracking trackingItems={tracking} dispatchCallback={trackingCallback} carrierImageWidth="60px" message={message} />
                )
            }
        },
        {
            field: 'shipment.tracking.status',
            headerName: 'Status',
            minWidth: 180,
            flex: 1,
            renderCell: (params) => {
                let row = params.row
                if (
                    row &&
                    row.status
                ) {
                    return (
                        <OrderStatus orderStatus={row.status} />
                    )
                } else {
                    return "-"
                }
            }
        },
        {
            field: 'shipment.tracking.created_at',
            headerName: 'Shipment Date',
            minWidth: 180,
            flex: 1,
            renderCell: (params) => {
                let row = params.row
                if (
                    row &&
                    row.created_at
                ) {
                    return (
                        <Date date={row.created_at} />
                    )
                } else {
                    return "-"
                }
            }
        },
        {
            field: 'shipment.tracking',
            headerName: 'Rate',
            flex: 1,
            minWidth: 120,
            renderCell: (params) => {
                return (
                    <ShipmentRate
                        billed_rate={params.row && params.row.rate && params.row.rate.billed && params.row.rate.billed.total}
                        api_rate={params.row && params.row.rate && params.row.rate.total_charges}
                        is_multi_piece={params.row && params.row.label && params.row.label.multi_piece}
                        is_voided={labelButton(params.row)}
                    />
                )
            }
        },
        {
            field: 'action',
            headerName: 'Shipment Action',
            minWidth: 200,
            flex: 1,
            renderCell: (params) => {
                let row = params.row
                return <div>
                    <span style={{ marginRight: '10px' }}><Tooltip title="View Label" placement="left-start">
                        <IconButton
                            disabled={labelButton(row)}
                            style={{
                                color: labelButton(row) ? 'rgba(0,0,0,.54)' : '#1890ff',
                                pointerEvents: labelButton(row) ? 'none' : 'auto',
                                cursor: labelButton(row) ? 'not-allowed' : 'pointer'
                            }}
                        >
                            <RemoveRedEyeIcon onClick={() => window.open(label_url(row))} />
                        </IconButton>
                    </Tooltip>
                    </span>
                    <span style={{ marginRight: '10px' }}><Tooltip title="Print Label" placement="left-start">
                        <IconButton
                            onClick={handleViewActionClick}
                            disabled={labelButton(row)}
                            style={{
                                color: labelButton(row) ? 'rgba(0,0,0,.54)' : '#1890ff',
                                pointerEvents: labelButton(row) ? 'none' : 'auto',
                                cursor: labelButton(row) ? 'not-allowed' : 'pointer'
                            }}
                        >
                            <LocalPrintshopIcon onClick={() => { setPrintUrl(label_url(row)); setIsPrinted(false); }} />
                        </IconButton>
                    </Tooltip>
                    </span>
                    <span>
                        <Tooltip title="Void Shipment" placement="left-start">
                            <IconButton
                                disabled={actionButton(row)}
                                style={{
                                    color: actionButton(row) ? 'rgba(0,0,0,.54)' : '#1890ff',
                                    pointerEvents: actionButton(row) ? 'none' : 'auto',
                                    cursor: actionButton(row) ? 'not-allowed' : 'pointer'
                                }}
                                onClick={() => triggerVoidShipment(row && row.tracking_number, order && order._id)}
                            >
                                <DeleteOutlineOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                    </span>
                    <span>
                        <Tooltip title="Return Shipment" placement="left-start">
                            <IconButton
                                disabled={returnlabelButton(row)}
                                style={{
                                    color: returnlabelButton(row) ? 'rgba(0,0,0,.54)' : '#1890ff',
                                    pointerEvents: returnlabelButton(row) ? 'none' : 'auto',
                                    cursor: returnlabelButton(row) ? 'not-allowed' : 'pointer'
                                }}
                                onClick={(event) => newOrderCreateCallback(order, true,row && row.tracking_number)}
                            >
                                <KeyboardReturnOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                    </span>
                </div >

            }
        }
    ];

    const getFinalData = (datas) => {
        let finalData = []
        for (var key of Object.keys(datas)) {
            let dataTemp = typeof datas[key] == "object" ? JSON.parse(JSON.stringify(datas[key])) : {}
            dataTemp["tracking_number"] = key
            finalData.push(dataTemp)
        }
        return finalData
    }

    return (
        <>
            <Printer isPrinted={isPrinted} setIsPrinted={setIsPrinted} print_url={printUrl} />
            <Modal
                open={openVoidConfirmModal}
                aria-labelledby="void-confirm-modal-title"
                aria-describedby="void-confirm-modal-description"
            >
                <Box sx={style}>
                    <Typography style={{ textAlign: "center" }} id="void-confirm-modal-description" sx={{ mt: 2 }}>
                        Do you want to void this shipment?
                    </Typography>
                    <Typography style={{ marginTop: "10px", textAlign: "center" }}>
                        <Button variant="outlined" onClick={() => { setOpenVoidConfirmModal(false) }}>No</Button>
                        <Button variant="contained" style={{ marginLeft: "15px" }} onClick={() => { voidShipment() }}>Yes</Button>
                    </Typography>
                </Box>
            </Modal>
            <Modal
                open={openManifestConfirmModal}
                aria-labelledby="manifest-confirm-modal-title"
                aria-describedby="manifest-confirm-modal-description"
            >
                <Box sx={style}>
                    <Typography style={{ textAlign: "center" }} id="manifest-confirm-modal-description" sx={{ mt: 2 }}>
                        Do you want to close manifest for this shipment?
                    </Typography>
                    <Typography style={{ marginTop: "10px", textAlign: "center" }}>
                        <Button variant="outlined" onClick={() => { setOpenManifestConfirmModal(false) }}>No</Button>
                        <Button variant="contained" style={{ marginLeft: "15px" }} onClick={() => { manifestClose() }}>Yes</Button>
                    </Typography>
                </Box>
            </Modal>
            <Drawer
                anchor="right"
                open={visible}
                onClose={onClose}
                PaperProps={{
                    sx: { width: "55%" },
                }}
            >
                <div style={{ padding: "0 20px" }}>
                    <Card sx={{ margin: '20px 0' }} className="sc--cardhead--details">
                        <CardHeader title={(order && order.order && order.order.id) ? "Shipment Details for #" + order.order.id : "Shipment Details"} action={
                            <div>
                                <IconButton aria-label="settings" onClick={onClose}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        } />
                        <Divider />
                        <div className="sc--form--center" style={{ margin: '20px 0' }}>
                            <DataGrid
                                getRowId={(row) => Math.random() + Math.random()}
                                rows={(order && order.shipment && order.shipment.tracking && order.shipment.tracking.length != 0) ? getFinalData(order.shipment.tracking) : []}
                                columns={columns}
                                autoHeight={true}
                                disableColumnFilter={true}
                                disableColumnMenu={true}
                                disableSelectionOnClick={true}
                                rowsPerPageOptions={[]}
                            />
                        </div>
                    </Card>
                </div>
            </Drawer>
            <TrackingDrawer
                visible={trackingDrawerVisible} message={trackingMessage} isMultiPiece={isMultiPiece} onClose={onCloseTracking}
            />
             <NewOrderCreateDrawer
                visible={newOrderCreateDrawerVisible}
                reportName={"orders"}
                newOrderFormData={newOrderFormData}
                setNewOrderFormData={setNewOrderFormData}
                meta={meta}
                onClose={newOrderCreateOnClose}
                isDuplicateOrder={isDuplicateOrder}
                isReturnOrder={isReturnOrder}
                setIsReturnOrder={setIsReturnOrder}
                setNewOrderCreateDrawerVisible={setNewOrderCreateDrawerVisible}
                choosenTrackingNumber={choosenTrackingNumber}
                isShipmentCreationFlow={isShipmentCreationFlow}
            />
        </>
    )
}

export default LineItemsDrawer;