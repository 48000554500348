import { createSlice } from '@reduxjs/toolkit';
import { OMSAPI } from '../../../API';
import { LABELGENAPI, LABELINGCHIMP_SAM_API, LABELINGCHIMP_LABEL_API } from '../../../API';
import axios from 'axios';

/* canceling previous requests if multiple requests is triggered */
const CancelToken = axios.CancelToken;
let source = CancelToken.source();

export const carriercredentialsslice = createSlice({
    name: 'carriercredentials',
    initialState: {
        carrier: [],
        default:[{"_id":"623b4d306a6392374900","carrier_name":"ShippingChimp","name":"ShippingChimp Standard","api_key":"shippinchimp","userid":0,"enabled":true,"status":"ACTIVE","environment":"development","updated_at":"2022-03-04T19:11:03.340000Z","quick_ship":true,"default":true,"app_enabled":true,"api_enabled":true,"response_message":"Valid Credentials"}],
        quickship: [],
        loading: false,
        meta: [],
        hasErrors: false,
        message: "",
        notification: ""
    },
    reducers: {
        getCarrierDetail: (state) => {
            return {
                ...state,
                loading: true
            }
        },
        getCarrierDetailSuccess: (state, action) => {
            return {
                ...state,
                carrier: state.default.concat(action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.all),
                quickship: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.quick_ship,
                hasErrors: false,
                loading: false
            }
        },
        getCarrierDetailFailure: (state, action) => {
            return {
                ...state,
                carrier: [],
                loading: false,
                hasErrors: true
            }
        },

        postCarrier: (state) => {
            return {
                ...state,
                loading: true,
                notification: { showMessage: false, messageContent: "", messageType: "success" }
            }
        },
        postCarrierSuccess: (state, action) => {
            return {
                ...state,
                loading: false,
                message: action && action.payload && action.payload.data && action.payload.data.data.message,
                notification: "success"
            }
        },
        postCarrierFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                message: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.message,
                hasErrors: true,
                notification: "success"
            }
        },

        putCarrier: (state) => {
            return {
                ...state,
                loading: true,
                notification: { showMessage: false, messageContent: "", messageType: "success" }
            }
        },
        putCarrierSuccess: (state, action) => {
            return {
                ...state,
                loading: false,
                message: action && action.payload && action.payload.data && action.payload.data.data.message,
                notification: "success"
            }
        },
        putCarrierFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                hasErrors: true,
                message: action && action.payloads && action.payload.data && action.payload.data.data.message,
                notification: "success"
            }
        },

        deleteCarrier: (state) => {
            return {
                ...state,
                loading: true,
                // notification: { showMessage: false, messageContent: "", messageType: "success" }
            }
        },
        deleteCarrierSuccess: (state, action) => {
            return {
                ...state,
                loading: false,
                message: action && action.payload && action.payload.data && action.payload.data.data.message,
                notification: "success"
            }
        },
        deleteCarrierFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                hasErrors: true,
                message: action && action.payload && action.payload.data && action.payload.data.data.message,
                notification: "success"
            }
        },
        resetCarrier: (state) => {
            return {
                ...state,
                message:"",
                loading: false,
                hasError: false,
            }
        },


    }
})
export const { getCarrierDetail, getCarrierDetailSuccess, getCarrierDetailFailure,
    postCarrier, postCarrierSuccess, postCarrierFailure, putCarrier, putCarrierSuccess, putCarrierFailure, deleteCarrier, deleteCarrierSuccess, deleteCarrierFailure,resetCarrier } = carriercredentialsslice.actions;

export const fetchCarrierDetail = data => dispatch => {

    dispatch(getCarrierDetail());
    try {
        LABELGENAPI
            .get('/settings/carrier-credentials')
            .then(function (response) {
                dispatch(getCarrierDetailSuccess(response));
            })
            .catch(function (error) {
                dispatch(getCarrierDetailFailure(error.response));
            });
    } catch (error) {
        dispatch(getCarrierDetailFailure());
    }
};
export const storeCarrier = data => dispatch => {
    dispatch(postCarrier());
    try {
        LABELGENAPI.post('/settings/carrier-credentials', data)
            .then(function (response) {
                dispatch(postCarrierSuccess(response));
                dispatch(fetchCarrierDetail());
            }).catch(function (error) {
                dispatch(postCarrierFailure(error.response));
            })
    }
    catch (error) {
        dispatch(postCarrierFailure(error.response));
    }
}

export const updateCarrier = (id, data) => dispatch => {  
    dispatch(putCarrier());
    try {
        LABELGENAPI.patch('/settings/carrier-credentials/' + id, data)
            .then(function (response) {
                dispatch(putCarrierSuccess(response));
                dispatch(fetchCarrierDetail());
            }).catch(function (error) {
                dispatch(putCarrierFailure(error.response));
            })
    }
    catch (error) {
        dispatch(putCarrierFailure(error.response));
    }
}

export const destoryCarrier = id => dispatch => {
    dispatch(deleteCarrier());
    try {
        LABELGENAPI
            .delete("/settings/carrier-credentials/" + id)
            .then(function (response) {
                dispatch(deleteCarrierSuccess(response));
                dispatch(fetchCarrierDetail());
            })
            .catch(function (error) {
                dispatch(deleteCarrierFailure(error.response));
            });
    } catch (error) {
        dispatch(deleteCarrierFailure(error));
    }
};
export const resetCarrierNotification= data => dispatch => {
    dispatch(resetCarrier());
}
export default carriercredentialsslice.reducer;
