import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers'
import Stack from '@mui/material/Stack';
import moment from 'moment';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';

const Filters = ({ visible, meta, setMeta }) => {
    const deliveries = useSelector(state => state.deliveries);
    const profile = useSelector(state => state.profile.profile);
    const [formValues, setFormValues] = useState(meta)
    const [filterButtonDisabled, setFilterButtonDisabled] = useState(true)
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [checkFormUse, setCheckFormUse] = useState("")
    const carriers = [
        "CANADAPOST",
        "INTELCOM",
        "RAVENFORCE",
        "TFORCE",
        "FLASHBOX",
        "UNIEXPRESS",
        "ICS"
    ]
    const onInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value
        });
    };

    const submitFilterValues = () => {
        setMeta(prevState => ({
            ...prevState,
            [`carrier_name`]: formValues["carrier_name"],
            [`shipment_status_category`]: formValues["shipment_status_category"],
            [`start_date`]: formValues["start_date"],
            [`end_date`]: formValues["end_date"],
            [`page`]: 1
        }));
        setCheckFormUse(true)
    }

    const onDateChange = (date_type, date) => {
        if(date != "" && typeof date !== "undefined" && date !=  null){
           let date_formatted = (date != null) ? moment(date).format("YYYY-MM-DD") : date
           if(date_type == "start_date"){
                let start_date = date_formatted + " 00:00:00"
                let start_date_format="";
                if(profile && profile.time_zone && profile.time_zone!==''){
                    start_date_format=moment.tz(start_date,profile.time_zone).utc().format("YYYY-MM-DD HH:mm:ss")
                }else{
                    start_date_format=moment(start_date).utc().format("YYYY-MM-DD HH:mm:ss")  
                }
                setStartDate(start_date)
                setFormValues({
                    ...formValues,
                    [`start_date`]: start_date_format
                });
           }else{
                let end_date = date_formatted + " 23:59:59"
                let end_date_format="";
                if(profile && profile.time_zone && profile.time_zone!==''){
                    end_date_format = moment.tz(end_date,profile.time_zone).utc().format("YYYY-MM-DD HH:mm:ss")
                }else{
                    end_date_format=moment(end_date).utc().format("YYYY-MM-DD HH:mm:ss")
                }
                setEndDate(end_date)
                setFormValues({
                    ...formValues,
                    [`end_date`]: end_date_format
                });
           }
        }
    }

    const resetFields = () => {
        setStartDate(null)
        setEndDate(null)
        setFormValues({
            ...formValues,
            [`carrier_name`]: [],
            [`shipment_status_category`]: [],
            [`start_date`]: "",
            [`end_date`]: "",
        });
        setMeta(prevState => ({
            ...prevState,
            [`carrier_name`]: [],
            [`shipment_status_category`]: [],
            [`start_date`]: "",
            [`end_date`]: "",
            [`page`]: 1
        }));
    }

    const checkFormHasValues = () => {
        let disableButton = true
        if (formValues["carrier_name"].length !== 0 || formValues["shipment_status_category"].length !== 0 || formValues["start_date"] !== ""  || formValues["end_date"] !== "") {
            disableButton = false
        }

        return disableButton
    }

    useEffect(() => {
        setCheckFormUse("")
        setFilterButtonDisabled(checkFormHasValues())
    }, [formValues])

    useEffect(() => {
        if ((formValues["carrier_name"].length !== 0 || formValues["shipment_status_category"].length !== 0 || formValues["start_date"] !== "" || formValues["end_date"] !== "") && checkFormUse === '') {
            setFormValues({
                ...formValues,
                [`carrier_name`]: [],
                [`shipment_status_category`]: [],
                [`start_date`]: "",
                [`end_date`]: ""
            });
            setMeta(prevState => ({
                ...prevState,
                [`carrier_name`]: [],
                [`shipment_status_category`]: [],
                [`start_date`]: "",
                [`end_date`]: "",
                [`page`]: 1
            }));
            setStartDate(null)
            setEndDate(null)
        }
    }, [deliveries])
    return (
        <>
            {visible &&
                <div className="filtercontainer">
                    <div className="filteraccordion" id="sc-filteraccordion">
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { m: 1 },
                            }}

                            noValidate
                            autoComplete="off"
                        >
                            {(profile.hide_carrier === false) && 
                                <FormControl className="sc__minwidth--align" variant="standard" sx={{ m: 1, minWidth: 270 }}>
                                    <InputLabel id="carrier-name-select-label" size={'small'}>Carrier Name</InputLabel>
                                    <Select
                                        multiple
                                        value={formValues && formValues.carrier_name}
                                        name={"carrier_name"}
                                        labelId="carrier-name-select-label"
                                        id="demo-simple-select"
                                        onChange={onInputChange}>
                                        {carriers && carriers.map((item) => {
                                            return <MenuItem value={item}>{item}</MenuItem>
                                        })
                                        }
                                    </Select>
                                </FormControl>
                            }
                            <FormControl  className="sc__minwidth--align" variant="standard" sx={{ m: 1, minWidth: 270 }}>
                                <InputLabel id="order-status-select-label" size={'small'}>Shipment Status</InputLabel>
                                <Select
                                    multiple
                                    value={formValues && formValues.shipment_status_category}
                                    name={"shipment_status_category"} labelId="order-status-select-label"
                                    id="demo-simple-select"
                                    onChange={onInputChange}>
                                    <MenuItem value={"Label Generated"}>Label Generated</MenuItem>
                                    <MenuItem value={"In Transit"}>In Transit</MenuItem>
                                    <MenuItem value={"Issues"}>Issues</MenuItem>
                                    <MenuItem value={"Delivered"}>Delivered</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 370 }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Stack spacing={3} style={{flexDirection:'row'}}>
                                        <DatePicker
                                            label="Start Date"
                                            value={startDate}
                                            disableFuture={true}
                                            inputFormat={"yyyy-MM-dd"}
                                            onChange={(newValue) => {
                                                onDateChange("start_date",newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                variant="standard"
                                                label="Star Date"
                                                size={'small'}
                                                {...params}
                                                />
                                            )}
                                        />
                                        <DatePicker
                                            label="End Date"
                                            value={endDate}
                                            disableFuture={true}
                                            inputFormat={"yyyy-MM-dd"}
                                            onChange={(newValue) => {
                                                onDateChange("end_date",newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                variant="standard"
                                                label="End Date"
                                                size={'small'}
                                                {...params}
                                                />
                                            )}
                                        />
                                    </Stack>
                                </LocalizationProvider>
                            </FormControl>
                        </Box>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={12}>
                                <div className="filterfooter">
                                    <Button variant="contained" onClick={submitFilterValues} disabled={filterButtonDisabled}>Filter</Button>
                                    <Button variant="outlined" onClick={resetFields} disabled={filterButtonDisabled}>Clear</Button>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            }
        </>
    )

}

export default Filters;
