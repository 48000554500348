import React from 'react';
import { useSelector } from 'react-redux';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';

const WebTokenPage = () => {

    const profile = useSelector(state => state.auth);
    const [open, setOpen] = React.useState(false);
   
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };


    const copyToken = () => {
        navigator.clipboard.writeText(profile.profile.api_token);
        setOpen(true);

    }
    const action = (
        <>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </>
    );
    return (
        <Card sx={{ minWidth: '100%',boxShadow:'unset' }} className="sc--cardhead--details">
            <CardHeader title='API Token' className="sc--cardhead--details" />
            <Divider />
            <CardContent>
                <div className="sc--form--center" style={{margin:'50px 0'}}>
                    <TextField id="standard-basic" size='small' defaultValue={profile.profile.api_token} InputProps={{
                        readOnly: true,
                    }} variant="outlined" style={{ width: 350, margin: '8px' }} />
                    <Button variant="contained" onClick={copyToken}>Copy</Button>
                </div>
                <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    message="API Token has copied to the clipboard"
                    action={action}
                />
            </CardContent>
        </Card>
    )
}


export default WebTokenPage;
