import React from "react";
import Chip from '@mui/material/Chip';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

const SettingsStatus = ({ settingsStatus }) => {
    return (
        <>

            {settingsStatus === true &&
                <div style={{ margin: "auto" }}>
                    <CheckCircleOutlineOutlinedIcon />
                </div>
            }
            {settingsStatus === false &&
                <div style={{ margin: "auto" }}>
                    <CancelOutlinedIcon />
                </div>
            }


        </>
    );
};

export default SettingsStatus;
