import axios from 'axios';
import { Modal } from '@mui/material';

export const OMSAUTH = axios.create({
  baseURL: process.env.REACT_APP_API_DOMAIN + "/auth",
  withCredentials: true,
});

export const OMSPROFILE = axios.create({
  baseURL: process.env.REACT_APP_API_DOMAIN + "/user",
  withCredentials: true,
});

export const OMSAPI = axios.create({
  baseURL: process.env.REACT_APP_API_DOMAIN + "/oms",
  withCredentials: true,
});

export const NOTIFICATIONAPI = axios.create({
  baseURL: process.env.REACT_APP_NOTIFICATION_DOMAIN,
  withCredentials: true,
});

export const LABELGEN = axios.create({
  baseURL: process.env.REACT_APP_LABELGEN_DOMAIN,
  withCredentials: true,
});

export const LABELGENAPI = axios.create({
  baseURL: process.env.REACT_APP_API_DOMAIN + "/labelgen",
  withCredentials: true,
});

export const LABELINGCHIMP_SAM_API = axios.create({
  baseURL: process.env.REACT_APP_LABELINGCHIMP_SAM_API_DOMAIN
});

export const LABELINGCHIMP_LABEL_API = axios.create({
  baseURL: process.env.REACT_APP_LABELINGCHIMP_LABEL_API_DOMAIN
});
export const INTEGRATIONS_API = axios.create({
  baseURL: process.env.REACT_APP_INTEGRATIONS_API_DOMAIN
});


export const OMS = axios.create({
  baseURL: process.env.REACT_APP_OMS_DOMAIN,
  withCredentials: true,
});

function handleErrorResponse(error) {

  let roles = window.localStorage.getItem("roles")
  if (error && error.response && error.response.status) {
    if (window.location.pathname != "/login" &&
      window.location.pathname != "/register" &&
      window.location.pathname != "/" &&
      window.location.pathname != "/passwordreset") {
      if (401 === error.response.status || 419 === error.response.status) {
          window.location = '/login';
      } else if (403 === error.response.status) {
        if (roles.includes("labelgen") && !roles.includes("oms")) {
          Modal.warning({
            title: 'Forbidden',
            content: "You don't have access to this page!",
            okText: "Go To Labelgen",
            onOk: () => { window.location = process.env.REACT_APP_LABELGEN_DOMAIN + '/dashboard'; }
          });
        }
        else if (roles.includes("oms") && roles.includes("agent") || roles.includes("brand-admin")) {
          Modal.warning({
            title: 'Forbidden',
            content: "You don't have access to this page!",
            okText: "Go Home",
            onOk: () => { window.location = '/dashboard'; }
          });
        }
      } else if (500 === error.response.status) {
        Modal.warning({
          title: 'Internal Server Error',
          content: "Something went wrong please try again later",
          okText: "Go Home",
          onOk: () => { window.location = '/dashboard'; }
        });
      }
    }
  }
}

OMSAUTH.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  handleErrorResponse(error);
  return Promise.reject(error);
});

OMSPROFILE.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  handleErrorResponse(error);
  return Promise.reject(error);
});

OMSAPI.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  handleErrorResponse(error);
  return Promise.reject(error);
});

LABELGENAPI.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  handleErrorResponse(error);
  return Promise.reject(error);
});

LABELINGCHIMP_SAM_API.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  handleErrorResponse(error);
  return Promise.reject(error);
});

OMS.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  handleErrorResponse(error);
  return Promise.reject(error);
});