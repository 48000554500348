import { createSlice } from '@reduxjs/toolkit';
import { OMSPROFILE } from '../../../API';

export const profileslice = createSlice({
    name: 'profile',
    initialState: {
        profile: {},
        meta: [],
        loading: false,
        hasErrors: false,
        message: "",
        notification: { show: false, messageContent: "", messageType: "success" }
    },
    reducers: {
        getProfile: (state) => {
            return {
                ...state,
                loading: true
            }
        },
        getProfileSuccess: (state, action) => {
            return {
                ...state,
                profile: action && action.payload && action.payload.data && action.payload.data.data,
                meta: action && action.payload && action.payload.data && action.payload.data.meta,
                loading: false,
                hasErrors: false
            }
        },
        getProfileFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                hasErrors: true
            }
        },
        putProfile: (state) => {
            return {
                ...state,
                loading: true
            }
        },
        putProfileSuccess: (state, action) => {
            return {
                ...state,
                profile: action && action.payload && action.payload.data && action.payload.data.data,
                message: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.message,
                loading: false,
                hasErrors: false                
            }
        },
        putProfileFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                hasErrors: true,
                message: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.message
            }
        },
        putProductSettings: (state) => {
            return {
                ...state,
                loading: true
            }
        },
        putProductSettingsSuccess: (state, action) => {
            return {
                ...state,
                profile: action && action.payload && action.payload.data && action.payload.data.data,
                message: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.message,
                loading: false,
                hasErrors: false,
                notification: { show: true, messageContent: (action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.message === "Product settings Saved Successfully") ? ["Product settings Saved Successfully"] : [""], messageType: "success" }
            }
        },
        putProductSettingsFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                hasErrors: true,
                message: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.message,
                notification: { show: true, messageContent: action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.message ? [action.payload.data.data.message] : [""], messageType: "error" }

            }
        },
        resetProfileSettings: (state, action) => {
            return {
                ...state,
                    profile: action && action.payload && action.payload.data && action.payload.data.data,
                    notification: { show: false, messageContent: "", messageType: "success" }
            }
        }

    }
})
export const { getProfile, getProfileSuccess, getProfileFailure, putProfile,
    putProfileSuccess, putProfileFailure, putProductSettings,
    putProductSettingsSuccess, putProductSettingsFailure,resetProfileSettings } = profileslice.actions;


export const fetchProfile = data => dispatch => {
    dispatch(getProfile());
    try {
        OMSPROFILE.get('/profile')
            .then(function (response) {
                dispatch(getProfileSuccess(response));
            }).catch(function (error) {
                dispatch(getProfileFailure());
            })
    }
    catch (error) {
        dispatch(getProfileFailure());
    }
}
export const updateProfile = data => dispatch => {
    dispatch(putProfile());
    try {
        OMSPROFILE.patch('/profile/1', data)
            .then(function (response) {
                dispatch(putProfileSuccess(response));
                dispatch(getProfileSuccess(response));
            }).catch(function (error) {
                dispatch(putProfileFailure(error.response));
            })
    }
    catch (error) {
        dispatch(putProfileFailure());
    }
}
export const updateProductSettings = data => dispatch => {
    dispatch(putProductSettings());
    try {
        OMSPROFILE.post('/product-settings', data)
            .then(function (response) {
                dispatch(putProductSettingsSuccess(response));
                dispatch(fetchProfile());
            }).catch(function (error) {
                dispatch(putProductSettingsFailure(error.response));
            })
    }
    catch (error) {
        dispatch(putProductSettings());
    }
}
export const resetProfile = () => {
    return (dispatch) => {
        dispatch(resetProfileSettings());
        dispatch(fetchProfile());
    }
}
export default profileslice.reducer;
