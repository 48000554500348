import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';

const Search = ({ visible, meta, setMeta }) => {
    const deliveries = useSelector(state => state.deliveries);
    const [formValues, setFormValues] = useState(meta)
    const [searchButtonDisabled, setSearchButtonDisabled] = useState(true)
    const [checkFormUse, setCheckFormUse] = useState("")
    const [errors, setErrors] = useState({});
    const validator = (fieldValues) => {
		let temp = { ...errors }
        if(/\`|\~|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\.|\>|\?|\/|\""|\;|\:|\-|\s/g.test(fieldValues && fieldValues.order_id)){
			temp.order_id = "Please enter valid order number"
        }
        else{
            temp.order_id=""
        }
        if(/\`|\~|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\.|\>|\?|\/|\""|\;|\:|\-|\s/g.test(fieldValues && fieldValues.tracking_number)){
            temp.tracking_number = "Please enter valid tracking number"
        }else{
            temp.tracking_number=""
        }
        if(/\`|\~|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\.|\>|\?|\/|\""|\;|\:|\-|\s/g.test(fieldValues && fieldValues.receiver_province)){
                temp.receiver_province =  "Please enter valid receiver province"
        }else{
            temp.receiver_province = ""
        }
        if(/\`|\~|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\.|\>|\?|\/|\""|\;|\:|\-/g.test(fieldValues && fieldValues.customer_name)){
                    temp.customer_name ="Please enter valid customer name"
        }else{
            temp.customer_name =""
        }
		setErrors({
			...temp
		})

		if (fieldValues == formValues)
			return Object.values(temp).every(x => x == "")
	}
    const onInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value
        });
        validator({ [name]: value })
    };

    const checkFormHasValues = () => {
        let disableButton = true
        if (formValues["tracking_number"] != "" || formValues["customer_name"] != "" || formValues["order_id"] != "" || formValues["receiver_province"] != ""){
            disableButton = false
        }

        return disableButton
    }

    const handleKeyUp = (event) => {
        if (event.keyCode === 13) {
            const isValid = validator(formValues)
            if (isValid) {
                setMeta(prevState => ({
                    ...prevState,
                    ["order_id"]: formValues["order_id"],
                    ["tracking_number"]: formValues["tracking_number"],
                    ["customer_name"]: formValues["customer_name"],
                    [`receiver_province`]: formValues["receiver_province"],
                    [`page`]: 1,
                }));
                setCheckFormUse(true)
            }
        }
    }

    const submitSearchValues =e => {
        e.preventDefault()
		const isValid = validator(formValues)
		if (isValid) {
            setMeta(prevState => ({
                ...prevState,
                ["order_id"]: formValues["order_id"],
                ["tracking_number"]: formValues["tracking_number"],
                ["customer_name"]: formValues["customer_name"],
                [`receiver_province`]: formValues["receiver_province"],
                [`page`]: 1,
            }));
            setCheckFormUse(true)
        }
    }

    const resetFields = () => {
        setFormValues({
            ...formValues,
            ["order_id"]: "",
            ["tracking_number"]: "",
            ["customer_name"]: "",
            [`receiver_province`]: "",
        });
        setMeta(prevState => ({
            ...prevState,
            ["order_id"]: "",
            ["tracking_number"]: "",
            ["customer_name"]: "",
            [`receiver_province`]: "",
            [`page`]: 1,
        }));
    }

    const onInputClear = (name) => {
        setErrors({ name: "" })
        setFormValues({
            ...formValues,
            [name]: "",
        });
        setMeta(prevState => ({
            ...prevState,
            [name]: ""
        }));
    };

    useEffect(() => {
        setCheckFormUse("") 
        setSearchButtonDisabled(checkFormHasValues())
    }, [formValues])

    // useEffect(() => {
    //     if ((formValues["tracking_number"] != "" || formValues["customer_name"] != "" || formValues["order_id"] != "" || formValues["receiver_province"] != "") && checkFormUse==='') {
    //         console.log("success")
    //         setFormValues({
    //             ...formValues,
    //             ["order_id"]: "",
    //             ["tracking_number"]: "",
    //             ["customer_name"]: "",
    //             [`receiver_province`]: "",
    //         });
    //         setMeta(prevState => ({
    //             ...prevState,
    //             ["order_id"]: "",
    //             ["tracking_number"]: "",
    //             ["customer_name"]: "",
    //             [`receiver_province`]: "",
    //             [`page`]: 1,
    //         }));     
    //     }        
    // }, [deliveries])

    return (
        <>
            {visible &&
                <div className="filtercontainer">
                    <div className="filteraccordion" onKeyUp={handleKeyUp}>
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { m: 1, width: '25ch' },
                            }}

                            noValidate
                            autoComplete="off"
                            onSubmit={e => { e.preventDefault(); }}
                        >
                            <TextField
                                value={formValues && formValues.order_id}
                                name={"order_id"}
                                label="Order Number"
                                size={'small'}
                                onChange={onInputChange}
                                InputProps={{
                                    endAdornment: (formValues && formValues.order_id) ? (
                                        <IconButton size="small" onClick={() => onInputClear("order_id")}>
                                            <ClearIcon />
                                        </IconButton>
                                    ) : undefined
                                }}
                                variant="standard"
                                error={errors.order_id}
                                helperText={errors.order_id}
                            />
                            <TextField
                                value={formValues && formValues.tracking_number}
                                name={"tracking_number"}
                                label="Tracking Number"
                                size={'small'}
                                onChange={onInputChange}
                                InputProps={{
                                    endAdornment: (formValues && formValues.tracking_number) ? (
                                        <IconButton size="small" onClick={() => onInputClear("tracking_number")}>
                                            <ClearIcon />
                                        </IconButton>
                                    ) : undefined
                                }}
                                variant="standard"
                                error={errors.tracking_number}
                                helperText={errors.tracking_number}
                            />
                            <TextField
                                value={formValues && formValues.receiver_province}
                                name={"receiver_province"}
                                label="Receiver Province Code"
                                size={'small'}
                                onChange={onInputChange}
                                InputProps={{
                                    endAdornment: (formValues && formValues.receiver_province) ? (
                                        <IconButton size="small" onClick={() => onInputClear("receiver_province")}>
                                            <ClearIcon />
                                        </IconButton>
                                    ) : undefined
                                }}
                                variant="standard"
                                error={errors.receiver_province}
                                helperText={errors.receiver_province}
                            />
                            <TextField
                                value={formValues && formValues.customer_name}
                                name={"customer_name"}
                                label="Customer Name"
                                size={'small'}
                                onChange={onInputChange}
                                InputProps={{
                                    endAdornment: (formValues && formValues.customer_name) ? (
                                        <IconButton size="small" onClick={() => onInputClear("customer_name")}>
                                            <ClearIcon />
                                        </IconButton>
                                    ) : undefined
                                }}
                                variant="standard"
                                error={errors.customer_name}
                                helperText={errors.customer_name}
                            />
                        </Box>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={12}>
                                <div className="filterfooter">
                                    <Button variant="contained" onClick={submitSearchValues} disabled={searchButtonDisabled}>Search</Button>
                                    <Button variant="outlined" onClick={resetFields} disabled={searchButtonDisabled}>Clear</Button>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            }
        </>
    )

}

export default Search;
