import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';

const Search = ({ visible, meta, setMeta }) => {
    const report = useSelector((state) => state.notificationdashboard.report);
    const [formValues, setFormValues] = useState(meta)
    const [searchButtonDisabled, setSearchButtonDisabled] = useState(true)
    const [checkFormUse, setCheckFormUse] = useState("")
    const onInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
            [`page`]: 1
        });
    };

    const checkFormHasValues = () => {
        let disableButton = true
        if (formValues["orderid"] != "") {
            disableButton = false
        }

        return disableButton
    }

    const handleKeyUp = (event) => {
        if (event.keyCode === 13) {
            submitSearchValues();
        }
    }

    const submitSearchValues = () => {
        setMeta(prevState => ({
            ...prevState,
            ["orderid"]: formValues["orderid"],
            [`page`]: 1,
        }));
        setCheckFormUse(true)
    }

    const resetFields = () => {
        setFormValues({
            ...formValues,
            ["orderid"]: "",
        });
        setMeta(prevState => ({
            ...prevState,
            ["orderid"]: "",
            [`page`]: 1,
        }));
    }

    const onInputClear = (name) => {
        setFormValues({
            ...formValues,
            [name]: "",
        });
        setMeta(prevState => ({
            ...prevState,
            [name]: ""
        }));
    };

    useEffect(() => {
        setCheckFormUse("")
        console.log(checkFormHasValues())
        setSearchButtonDisabled(checkFormHasValues())        
    }, [formValues])

    useEffect(() => {
        if ((formValues["orderid"] != "") && checkFormUse === '') {
            setFormValues({
                ...formValues,
                ["orderid"]: "",
            });
            setMeta(prevState => ({
                ...prevState,
                ["orderid"]: "",
                [`page`]: 1,
            }));
        }
    }, [report])
    console.log(searchButtonDisabled)

    return (
        <>
            {visible &&
                <div className="filtercontainer">
                    <div className="filteraccordion" onKeyUp={handleKeyUp}>
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { m: 1, width: '25ch' },
                            }}

                            noValidate
                            autoComplete="off"
                            onSubmit={e => { e.preventDefault(); }}
                        >
                            <TextField
                                value={formValues && formValues.orderid}
                                name={"orderid"}
                                label="Order Number"
                                size={'small'}
                                onChange={onInputChange}
                                InputProps={{
                                    endAdornment: (formValues && formValues.orderid) ? (
                                        <IconButton size="small" onClick={() => onInputClear("orderid")}>
                                            <ClearIcon />
                                        </IconButton>
                                    ) : undefined
                                }}
                                variant="standard"
                            />

                        </Box>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={12}>
                                <div className="filterfooter">
                                    <Button variant="contained" onClick={submitSearchValues} disabled={searchButtonDisabled}>Search</Button>
                                    <Button variant="outlined" onClick={resetFields} disabled={searchButtonDisabled}>Clear</Button>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            }
        </>
    )

}

export default Search;
