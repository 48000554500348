import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { fetchIntegratedPlatform } from '../../integratedSlice';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    height: '100%',
    padding: '20px 0'
}));
const CentricIntegration = ({ storeName, platformName, storeParam, storeId }) => {
    const dispatch = useDispatch();

    const profile = useSelector(state => state.auth);
    const [open, setOpen] = React.useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };


    const copyToken = () => {
        navigator.clipboard.writeText(profile.profile.api_token);
        setOpen(true);

    }
    const action = (
        <>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </>
    );
    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} md={12} style={{ textAlign: 'center' }}>
                    <Item style={{ marginBottom: '30px' }}>
                        <div>
                            <img
                                src="/assets/images/integration/SHIPPINGCHIMP.png"
                                alt="SHIPPINGCHIMP"
                                style={{ margin: 'auto', display: 'flex', maxWidth: '60px' }} />
                        </div>
                        <div>
                            <div className="sc--form--center" style={{ margin: '20px 0' }}>
                                <TextField id="standard-basic" size='small' defaultValue={profile.profile.api_token} InputProps={{
                                    readOnly: true,
                                }} variant="outlined" style={{ width: 350, margin: '8px' }} />
                                <Button variant="contained" onClick={copyToken}>Copy</Button>

                            </div>
                            <div className='text-center'>
                                <p><b>Marketplace API Documentation </b><a href="http://labelingchimp-docs.shippingchimp.com/#/" target="_blank">Refer Here</a></p>
                            </div>
                            <Snackbar
                                open={open}
                                autoHideDuration={6000}
                                onClose={handleClose}
                                message="API Token has copied to the clipboard"
                                action={action}
                            />
                        </div>
                    </Item>
                </Grid>
            </Grid>
        </>
    )
}


export default CentricIntegration;