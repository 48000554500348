import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const NotFoundPage = ({ }) => {
   

    return (
        <>
        <div className='verticaly-center'>
            <img src="/assets/images/404.png" alt='404' />
            <Typography variant="h6" component="h6" style={{padding:"30px 0 0 0"}}>
                Not found
            </Typography>
            <Typography variant="p" component="p">
            Sorry, the page you visited does not exist.
            </Typography>
            <Button href="/" variant="contained" style={{margin:'20px',color:"#fff"}}>Back to Home</Button>
        </div>
        </>
    )

}

export default NotFoundPage;