
import React from "react";
import _ from 'lodash';

const Platform = ({ platform = "" }) => {
    if (platform.toUpperCase() == "OMS") {
        platform = "SHIPPING CHIMP";
    }
    return (
        <>
            {!platform && <div> - </div>}
            {platform && <div>{_.startCase(_.lowerCase(platform))}</div>}
        </>
    );
};

export default Platform;