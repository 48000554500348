import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import ThreePOutlinedIcon from '@mui/icons-material/ThreePOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import ProductionQuantityLimitsOutlinedIcon from '@mui/icons-material/ProductionQuantityLimitsOutlined';
import PrintIcon from '@mui/icons-material/Print';
import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from "@mui/material";
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import {
    DataGrid,
    GridToolbarContainer
} from '@mui/x-data-grid';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Carrier from '../../pages/General/Carrier'
import ShipmentRate from '../../pages/General/ShipmentRate'
import NorowsData from '../../pages/General/NorowsData'
import Customer from '../../pages/General/Customer'
import PlatformImage from './PlatformImage'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import OrderStatus from '../General/OrderStatus'
import Date from '../General/Date'


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    boxShadow: 'unset',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    color: theme.palette.text.secondary,
}));


const ShipmentAccordion = ({ shipment = null, setPrintUrl, setIsPrinted }) => {

    const profile = useSelector(state => state.auth.profile);
    const Accordion = styled((props) => (
        <MuiAccordion disableGutters elevation={0} square {...props} />
    ))(({ theme }) => ({
        border: `1px solid ${theme.palette.divider}`,
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
    }));
    const [expanded, setExpanded] = React.useState("");
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    const AccordionSummary = styled((props) => (
        <MuiAccordionSummary
            expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
            {...props}
        />
    ))(({ theme }) => ({
        backgroundColor:
            theme.palette.mode === 'dark'
                ? 'rgba(255, 255, 255, .05)'
                : 'rgba(0, 0, 0, .03)',
        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
        },
        '& .MuiAccordionSummary-content': {
            marginLeft: theme.spacing(1),
        },
    }));
    const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
        padding: theme.spacing(2),
        borderTop: '1px solid rgba(0, 0, 0, .125)',
    }));
    const labelButton = (row) => {
        let labelButtonDisable = true;
            if (row &&
                row.label &&
                row.manifest &&
                typeof row.label.is_void_in_progress === "undefined" &&
                (row.label.url_detachable || row.label.url_direct_detachable || row.label.url || row.label.url_direct)
            ) {
                labelButtonDisable = false;
            }
        return labelButtonDisable;
    }

    return (
        Object.keys(shipment.shipment_details).map(function (key) {
            let url = ""
            if(shipment.shipment_details[key].label && shipment.shipment_details[key].label.url_detachable){
                url = shipment.shipment_details[key].label.url_detachable
            }else if(shipment.shipment_details[key].label && shipment.shipment_details[key].label.url_direct_detachable){
                url = shipment.shipment_details[key].label.url_direct_detachable
            }else if(shipment.shipment_details[key].label && shipment.shipment_details[key].label.url){
                url = shipment.shipment_details[key].label.url
            }else if(shipment.shipment_details[key].label && shipment.shipment_details[key].label.url_direct){
                url = shipment.shipment_details[key].label.url_direct
            }
            return (
                <div>
                    <Accordion className='sc-order-typography' expanded={expanded === `panel${key}`} onChange={handleChange(`panel${key}`)}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" >
                            <Typography >
                                <div style={{ display: 'flex', alignItems: "center" }}>
                                    <span style={{ marginRight: '10px' }}>
                                        {((profile.hide_carrier === false) && shipment.shipment_details[key].label && shipment.shipment_details[key].label.carrier_name) ?
                                            <Carrier carrierName={shipment.shipment_details[key].label.carrier_name} width={"100px"}></Carrier> : ""}
                                    </span>
                                    <span style={{ marginRight: '10px' }}><b>{(shipment.shipment_details[key].tracking_number) ? shipment.shipment_details[key].tracking_number : "-"}</b> </span>
                                    {(shipment.shipment_details[key].shipment_status) && <span> <b><Chip label={shipment.shipment_details[key].shipment_status} style={{ backgroundColor: 'rgb(0 153 0)', color: '#fff', height: '25px' }} />
                                    </b></span>}
                                    <span style={{ marginLeft: '15px' }}>
                                        { !labelButton(shipment.shipment_details[key]) && ((shipment.shipment_details[key].rate && shipment.shipment_details[key].rate.billed && shipment.shipment_details[key].rate.billed.total) ?
                                            <Chip label={shipment.shipment_details[key].rate.billed.total} style={{ backgroundColor: 'rgb(0 153 0)', color: '#fff', height: '25px' }} /> :
                                            (shipment.shipment_details[key].rate && shipment.shipment_details[key].rate.total_charges) ?
                                                <Chip label={shipment.shipment_details[key].rate.total_charges} style={{ backgroundColor: 'rgb(0 153 0)', color: '#fff', height: '25px' }} /> : "")
                                        }
                                    </span>
                                </div>
                            </Typography>
                            <Typography sx={{ color: 'text.secondary' }}>
                                {url &&
                                    <>
                                        <Button style={{ marginLeft: '15px' }} disabled={labelButton(shipment.shipment_details[key])} variant="outlined" onClick={() => window.open(url, '_blank')}>
                                            <RemoveRedEyeOutlinedIcon style={{ paddingRight: '10px' }} /> View
                                        </Button>
                                        <Button style={{ marginLeft: '15px' }} disabled={labelButton(shipment.shipment_details[key])} variant="outlined" onClick={() => {setPrintUrl(url); setIsPrinted(false);} }>
                                            <PrintIcon style={{ paddingRight: '10px' }}/> Print
                                        </Button>
                                    </>
                                }
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container direction="row" spacing={1}>
                                <Grid item xs={12} md={4} style={{ padding: '10px' }}>
                                    <Card sx={{ maxWidth: 1200 }}>
                                        <CardHeader avatar={
                                            <LocalShippingOutlinedIcon />
                                        } title='Shipment Info' className="sc--cardhead--details" />
                                        <Divider />
                                        <CardContent style={{ padding: '10px 16px' }}>
                                            <p><span style={{ fontWeight: '500' }}>Service Type: </span>
                                                <span>{(shipment.shipment_details[key].label && shipment.shipment_details[key].label.service_type) ? shipment.shipment_details[key].label.service_type : "-"}</span></p>
                                            <Divider />
                                            <p><span style={{ fontWeight: '500' }}>Shipped Date: </span>
                                                <span>{(shipment.shipment_details[key].shipped_date) ? shipment.shipment_details[key].shipped_date : "-"}</span></p>
                                            <Divider />
                                            <p><span style={{ fontWeight: '500' }}>status: </span>
                                                <span>{(shipment.shipment_details[key].shipment_status) ? shipment.shipment_details[key].shipment_status : "-"}</span></p>
                                            <Divider />
                                            <p><span style={{ fontWeight: '500' }}>Delivered Date: </span>
                                                <span>{(shipment.shipment_details[key].delivered_date) ? shipment.shipment_details[key].delivered_date : "-"}</span></p>
                                            
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={4} style={{ padding: '10px' }}>
                                    <Card sx={{ maxWidth: 1200 }}>
                                        <CardHeader avatar={
                                            <ProductionQuantityLimitsOutlinedIcon />
                                        } title='Package Info' className="sc--cardhead--details" />
                                        <Divider />
                                        <CardContent style={{ padding: '10px 16px' }} >
                                            <p><span style={{ fontWeight: '500' }}>Quantity: </span>
                                                <span>{shipment.shipment_details[key].product_info && shipment.shipment_details[key].product_info.quantity ? shipment.shipment_details[key].product_info.quantity : "-"}</span></p>
                                            <Divider />
                                            <p><span style={{ fontWeight: '500' }}>Weight: </span>
                                                <span>
                                                    {shipment.shipment_details[key].product_info && shipment.shipment_details[key].product_info.actual_weight ?
                                                        (shipment.shipment_details[key].product_info && shipment.shipment_details[key].product_info.weight_unit ?
                                                            (shipment.shipment_details[key].product_info.actual_weight + " " + shipment.shipment_details[key].product_info.weight_unit) : shipment.shipment_details[key].product_info.actual_weight) : "-"}
                                                </span></p>
                                            <Divider />
                                            <p><span style={{ fontWeight: '500' }}>Dimension: </span>
                                                <span>
                                                    {shipment.shipment_details[key].product_info && shipment.shipment_details[key].product_info.dimensions && <>
                                                        <>{shipment.shipment_details[key].product_info.dimensions.length && shipment.shipment_details[key].product_info.dimensions.length}</>
                                                        <>{shipment.shipment_details[key].product_info.dimensions.length_unit && shipment.shipment_details[key].product_info.dimensions.length_unit} </>
                                                        <>{" * "}</>
                                                        <>{shipment.shipment_details[key].product_info.dimensions.width && shipment.shipment_details[key].product_info.dimensions.width}</>
                                                        <>{shipment.shipment_details[key].product_info.dimensions.width_unit && shipment.shipment_details[key].product_info.dimensions.width_unit}</>
                                                        <>{" * "}</>
                                                        <>{shipment.shipment_details[key].product_info.dimensions.height && shipment.shipment_details[key].product_info.dimensions.height}</>
                                                        <>{shipment.shipment_details[key].product_info.dimensions.height_unit && shipment.shipment_details[key].product_info.dimensions.height_unit}</>
                                                    </>}
                                                    {!shipment.shipment_details[key].product_info.dimensions && <>-</>}
                                                </span></p>
                                            <Divider />
                                            <p><span style={{ fontWeight: '500' }}>Insurance: </span>
                                                <span>{shipment.shipment_details[key].product_info && shipment.shipment_details[key].product_info.insurance_amount ?
                                                    <>{shipment.shipment_details[key].product_info.insurance_amount}
                                                        <Tooltip title="Commerical invoice or insured value whichever is lower" placement="right-start">
                                                            <InfoIcon style={{ position: "relative", left: "5px", top: "5px" }} fontSize="small" />
                                                        </Tooltip></> : "-"}
                                                </span></p>
                                            
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={4} style={{ padding: '10px' }}>
                                    <Card sx={{ maxWidth: 1200 }}>
                                        <CardHeader avatar={
                                            <AccountBalanceWalletOutlinedIcon />
                                        } title='Billing Info' className="sc--cardhead--details" />
                                        <Divider />
                                        <CardContent style={{ padding: '10px 16px' }}>
                                            <p><span style={{ fontWeight: '500' }}>Base Cost: </span>
                                                <span>
                                                    <ShipmentRate
                                                        billed_rate = {shipment.shipment_details[key].rate && shipment.shipment_details[key].rate.billed && shipment.shipment_details[key].rate.billed.base_cost}
                                                        api_rate = {shipment.shipment_details[key].rate && shipment.shipment_details[key].rate.total_charges}
                                                        is_multi_piece = {shipment.shipment_details[key].label && shipment.shipment_details[key].label.multi_piece}
                                                        is_voided = {labelButton(shipment.shipment_details[key])}
                                                    />
                                                </span></p>
                                            <Divider />
                                            <p><span style={{ fontWeight: '500' }}>Adjustment: </span>
                                                <span>{(shipment.shipment_details[key].rate && shipment.shipment_details[key].rate.billed && shipment.shipment_details[key].rate.billed.adjustment_cost) ?
                                                    <>
                                                        { shipment.shipment_details[key].rate.billed.adjustment_cost }
                                                        {(shipment.shipment_details[key].rate && shipment.shipment_details[key].rate.adjustment_description) && 
                                                        <Tooltip title={shipment.shipment_details[key].rate.adjustment_description} placement="right-start">
                                                            <InfoIcon style={{ position: "relative", left: "5px", top: "5px" }} fontSize="small" />
                                                        </Tooltip>}
                                                    </> : "-"}</span></p>
                                            <Divider />
                                            <p><span style={{ fontWeight: '500' }}>Tax: </span>
                                                <span>{(shipment.shipment_details[key].rate && shipment.shipment_details[key].rate.billed && shipment.shipment_details[key].rate.billed.tax) ? shipment.shipment_details[key].rate.billed.tax : "-"}</span></p>
                                            <Divider />
                                            <p><span style={{ fontWeight: '500' }}>Total: </span>
                                                <span>
                                                    <ShipmentRate
                                                        billed_rate = {shipment.shipment_details[key].rate && shipment.shipment_details[key].rate.billed && shipment.shipment_details[key].rate.billed.total}
                                                        api_rate = {shipment.shipment_details[key].rate && shipment.shipment_details[key].rate.total_charges}
                                                        is_multi_piece = {shipment.shipment_details[key].label && shipment.shipment_details[key].label.multi_piece}
                                                        is_voided = {labelButton(shipment.shipment_details[key])}
                                                    />
                                                </span></p>
                                            
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </div>
            )
        })
    )
};

export default ShipmentAccordion;
