import { createSlice } from '@reduxjs/toolkit';
import { OMSAPI } from '../../../API';
import axios from 'axios';

/* canceling previous requests if multiple requests is triggered */
const CancelToken = axios.CancelToken;
let source = CancelToken.source();

export const integrationsslice = createSlice({
    name: 'integrations',
    initialState: {
        category: {
            data: [],
            loading: false,
            hasErrors: false,
        },
        platform: {
            data: [],
            loading: false,
            hasErrors: false,
        },
    },
    reducers: {
        getPlatform: (state) => {
            return {
                ...state,
                platform: {
                    loading: true,
                },
            }
        },
        getPlatformSuccess: (state, action) => {
            return {
                ...state,
                platform: {
                    data: action && action.payload && action.payload.data,
                    loading: false,
                    hasErrors: false,
                }
            }
        },
        getPlatformFailure: (state) => {
            return {
                ...state,
                platform: {
                    loading: false,
                    hasErrors: true
                }
            }
        },
        getNotificationPlatform: (state) => {
            return {
                ...state,
                platform: {
                    loading: true,
                },
            }
        },
        getNotificationPlatformSuccess: (state, action) => {
            return {
                ...state,
                platform: {
                    data: action && action.payload && action.payload.data,
                    loading: false,
                    hasErrors: false,
                }
            }
        },
        getNotificationPlatformFailure: (state) => {
            return {
                ...state,
                platform: {
                    loading: false,
                    hasErrors: true
                }
            }
        },
        getCategory: (state) => {
            return {
                ...state,
                category: {
                    loading: true,
                }
            }
        },
        getCategorySuccess: (state, action) => {
            return {
                ...state,
                category: {
                    data: action && action.payload && action.payload.data,
                    loading: false,
                    hasErrors: false,
                }
            }
        },
        getCategoryFailure: (state) => {
            return {
                ...state,
                category: {
                    loading: false,
                    hasErrors: true
                }
            }
        },


    }
})
export const { getPlatform, getPlatformSuccess, getPlatformFailure, getNotificationPlatform, getNotificationPlatformSuccess, getNotificationPlatformFailure, getCategory, getCategorySuccess, getCategoryFailure } = integrationsslice.actions;

export const fetchPlatform = data => dispatch => {

    dispatch(getPlatform());

    try {
        OMSAPI.get('/integrations/all-platform/shopping')
            .then(function (response) {
                dispatch(getPlatformSuccess(response));
            }).catch(function (error) {
                dispatch(getPlatformFailure());
            })
    }
    catch (error) {
        dispatch(getPlatformFailure())
    }
}

export const fetchNotificationPlatform = data => dispatch => {

    dispatch(getNotificationPlatform());

    try {
        OMSAPI.get('/integrations/all-platform/notification')
            .then(function (response) {
                dispatch(getNotificationPlatformSuccess(response));
            }).catch(function (error) {
                dispatch(getNotificationPlatformFailure());
            })
    }
    catch (error) {
        dispatch(getNotificationPlatformFailure())
    }
}


export const fetchCategory = data => dispatch => {

    dispatch(getCategory())

    try {
        OMSAPI.get('/integrations/category')
            .then(function (response) {
                dispatch(getCategorySuccess(response));
            }).catch(function (error) {
                dispatch(getCategoryFailure());
            })
    }
    catch (error) {
        dispatch(getCategoryFailure())
    }
}



export default integrationsslice.reducer;