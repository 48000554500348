import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { LicenseInfo } from '@mui/x-data-grid-pro';

import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import store from './store';
import App from './App';
import './index.css';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
LicenseInfo.setLicenseKey('2a562ba4f436319e913aaca747a38674Tz05MjkzNCxFPTE3NTA1OTM2NzAwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
Sentry.init({
  dsn: "https://3877993d74ad45aeadfe3dc87a88356b@o917867.ingest.sentry.io/5863912",
  integrations: [new BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
    <BrowserRouter>
      <App />
      </BrowserRouter>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
