import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import RemoveIcon from '@mui/icons-material/Remove';
import {
    Paper,
    Divider,
    styled,
    Grid,
    Box,
    Button,
    Modal,
    Typography,
    Card,
    CardHeader,
    CardContent
} from "@mui/material";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import { LABELGENAPI} from '../../../API';
import { fetchConfig } from '../../Settings/Config/configSlice';

const qz = require("qz-tray");

const MultiPackageDimensions = ({ errors, values, multiPackageFields, onMultiPackageAdd, onMultiPackageRemove, handleMultiPackageDimensionInputChange,dispatchShipmentTypeInputChangeCallback }) => {

    const dispatch = useDispatch();
    const box = useSelector(state => state.box);
    const config = useSelector(state => state.config);
    const profile = useSelector(state => state.auth);
    const [qzLinkVisible, setQzLinkVisible] = useState(false)
    const [qzUsbNotConnected, setQzUsbNotConnected] = useState(false)
    const [insuranceCharge, setInsuranceCharge] = useState(0)
    const [boxData, setBoxData] = useState([])


    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: (option) => option.box_name,
      });

    const handleInputChange = e => {
        const { name, value } = e.target
        dispatchShipmentTypeInputChangeCallback(name, value)
    }

    const getWeightScaleStatus = (status) => {
        switch(status) {
			case 1:	// fault
			case 5:	// underweight
			case 6:	// overweight
			case 7:	// calibrate
			case 8:	// re-zero
				status = 'error'; break;
			case 3:
				status = 'busy'; break;
			case 2:	// stable at zero
			case 4: // stable non-zero
			default:
				status = 'stable';
		}

        return status
    }

    const releaseWeightScale = (vendor, product) => {
        qz.hid.isClaimed(vendor, product).then(() => {
            qz.hid.releaseDevice(vendor, product).catch((e) => {
                console.log(e.message)
            }).then(() => {
                console.log("Release and disconnection web socket")
                // qz.websocket.disconnect();
            })
        }).catch((e) => {
            console.log(e.message)
            console.log("Disconnection web socket")
            // qz.websocket.disconnect();
        })
    }

    const getWeightInfo = (data) => {
        console.log("Inside Weight Info =>",data)
        let precision = parseInt(data[3], 16);
        precision = precision ^ -256;
        data.splice(0,4);
        data.reverse();
		let weight = parseInt(data.join(''), 16);
        weight *= Math.pow(10, precision);
        return weight.toFixed(Math.abs(precision));
    }

    const getWeightUnit = (unit) => {
        console.log("Unit code received from scale => "+unit)
        switch(unit) {
			case 3:
				unit = 'kg'; break;
			case 11: // ounces
				unit = 'oz'; break;
			case 12:
			default:
				unit = 'lb';
		}
        return unit
    }

    const convertWeightToKg = (weight, weight_unit) => {
        if(weight_unit == "oz"){
            weight = parseFloat(weight / 35.274).toFixed(2);
        }
        return weight
    }

    const verifySecuritySignature = () => {
        qz.security.setCertificatePromise((resolve, reject) => {
            LABELGENAPI.
            get("/security/digital-certificate").
            then(function (response) {
                resolve(response.data)
            })
            .catch(function (error) {
                console.log(error.response)
                reject(error.response)
            });
        });
        qz.security.setSignatureAlgorithm("SHA512");
        qz.security.setSignaturePromise((toSign) => {
            return (resolve, reject) => {
                LABELGENAPI.
                get("/security/private-key?data="+toSign).
                then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    console.log(error.response)
                    reject(error.response)
                });
            };
        });
    }

    const getWeight = (index) => {
        console.log("===================")
        console.log(index)
        console.log("===================")
        verifySecuritySignature();
        console.log("Inside Get Weight")
        if (config &&
            config.data &&
            config.data.weighing_scale &&
            config.data.weighing_scale.is_enabled &&
            config.data.weighing_scale.config &&
            config.data.weighing_scale.config.vendor &&
            config.data.weighing_scale.config.product &&
            config.data.weighing_scale.config.device &&
            config.data.weighing_scale.config.endpoint){
                let vendor = config.data.weighing_scale.config.vendor
                let product = config.data.weighing_scale.config.product
                let device = config.data.weighing_scale.config.device
                let endpoint = config.data.weighing_scale.config.endpoint

                console.log("Config details found !!")

                qz.websocket.connect()
                .then(() => {
                    console.log("Connected. claiming device !!")
                    return qz.hid.claimDevice(vendor, product);
                }).catch((e) => {
                    console.log("catch ==> "+ e.message)
                    if(e.message === "Unable to establish connection with QZ"){
                        setQzLinkVisible(true)
                        setQzUsbNotConnected(false)
                    }
                    if(e.message === "USB device could not be found"){
                        setQzUsbNotConnected(true)
                        setQzLinkVisible(false)
                    }

                    if(e.message == "An open connection with QZ Tray already exists"){
                        console.log("Connection already exists. claiming device !!")
                        return qz.hid.claimDevice(vendor, product);
                    }
                }).then(() => {
                    return qz.hid.readData(vendor, product, '8');
                }).then((data) => {
                    console.log("Data raw",data)
                    setQzLinkVisible(false)
                    setQzUsbNotConnected(false)
                    let status = getWeightScaleStatus(parseInt(data[1], 16));
                    console.log("Scale Status => "+ status)
                    let weight_unit = getWeightUnit(parseInt(data[2], 16))
                    console.log("Scale Weight Unit => "+ weight_unit)
                    let weight = getWeightInfo(data)
                    console.log("Scale Weight => "+ weight)
                    if(weight != ""){
                        if(weight_unit !== "kg" && weight_unit !== "lb"){
                            weight = convertWeightToKg(weight, weight_unit)
                            weight_unit = "kg"
                        }
                        const actualWeightEvent = {
                            "target": {
                                "name": "actual_weight",
                                "value": weight
                            }
                        }
                        const actualWeightUnitEvent = {
                            "target": {
                                "name": "weight_unit",
                                "value": weight_unit
                            }
                        }
                        console.log(actualWeightEvent)
                        console.log(actualWeightUnitEvent)
                        handleMultiPackageDimensionInputChange(index, actualWeightEvent)
                        handleMultiPackageDimensionInputChange(index, actualWeightUnitEvent)
                    }
                }).then(() => {
                    releaseWeightScale(vendor, product)
                }).catch(() => {
                    releaseWeightScale(vendor, product)
                })
            }
    }

    const getChooseMeasurement = (currentvalue) => {
        let filteredCurrentValue = {};
        let tempValue = "";
        boxData &&
        boxData.map((key) => {
            if (key._id == currentvalue && tempValue == "") {
                filteredCurrentValue = key;
                tempValue = "success";
            }
          });
        return filteredCurrentValue;
      };

    useEffect(() => {
        dispatch(fetchConfig())
        // setTimeout(function () {
        //     if (values && values.actual_weight == "") { document.getElementById("actual-weight-id").focus(); }
        // }, 1000);
    }, [dispatch]);

    useEffect(() => {
        let tempBoxData=[]
        let customValue={"_id":0,"box_name":"custom"}
        tempBoxData.push(customValue)
        let boxOriginalData = box && box.data.filter(function( obj ) {
            return  obj.hide !== true;
            });
        if (boxOriginalData.length !== 0) {
            boxOriginalData.map((item) => {
                tempBoxData.push(item)
            })
            setBoxData(tempBoxData)
        }else{
            setBoxData(tempBoxData)
        }
    }, [box]);

    return (
        <>
                <Grid newOrderFormData xs={6} md={6} style={{ width: '50%', padding: '10px' }}>
                    <Card
                        sx={{
                            minWidth: 275, marginTop: '20px', boxShadow: 'unset',
                            border: '1px solid rgba(0, 0, 0, 0.12)'
                        }}
                        className="sc--cardhead--details">
                        <CardHeader
                            title='Measurement'
                            action={
                                <Button variant="outlined" disabled={multiPackageFields.length >= 20} aria-label="settings" onClick={() => onMultiPackageAdd()}>
                                    Add Package
                                                        </Button>
                            }
                        />
                        <Divider />
                        <CardContent style={{ padding:"16px" }}>
                            <FormControl
                                variant="standard"
                                className="help-text-error"
                                style={{ width: '100%' }}
                            >
                                <InputLabel
                                > Package Type
                                </InputLabel>
                                <Select
                                    name="shipment_type"
                                    onChange={handleInputChange}
                                    value={values.shipment_type}
                                    label="Package Type"
                                    id="filled-error-helper-text"
                                >
                                    <MenuItem value="PACKAGE">Package</MenuItem>
                                    <MenuItem value="LETTER">Letter</MenuItem>
                                </Select>
                            </FormControl>
                        </CardContent>
                        <CardContent style={{ maxHeight: '500px', overflowY: 'auto' }}>
                            {multiPackageFields.map((input, index) => {
                                return (
                                    <Card style={{ marginTop: "15px" }}>
                                        <CardHeader title={"Package " + (index + 1)} action={
                                            <div>
                                                <Button aria-label="settings" variant="outlined" onClick={() => onMultiPackageRemove(index)} disabled={(multiPackageFields.length == 1) ? true : false}>
                                                    Delete
                                                </Button>
                                            </div>
                                        } />
                                        <div>
                                            <Divider />
                                            <div className="sc--form--center" style={{ margin: '20px 0' }}>
                                                <Autocomplete
                                                    className="help-text-error"
                                                    disablePortal
                                                    name="choosenmeasurement"
                                                    id="state-select-demo"
                                                    sx={{ width: "100%",margin:'8px'}}
                                                    options={boxData}
                                                    autoHighlight
                                                    loading={
                                                    (box && box.loading)
                                                    }
                                                    getOptionLabel={(option) => option.box_name=='custom' ? ""+option.box_name+"": ( ""+option.box_name+" ("+option.box_length+option.box_length_unit+"x "+ option.box_width+option.box_width_unit+"x "+ option.box_height+option.box_height_unit+", "+option.box_weight+option.box_weight_unit+")")}
                                                    filterOptions={filterOptions}
                                                    value={getChooseMeasurement(multiPackageFields[index].choosenmeasurement)}
                                                    renderOption={(props, option) => (
                                                    <MenuItem {...props} value={option._id} key={option._id}>
                                                     {option.box_name =='custom' ?
                                                        <p style={{ margin: 'unset', color: 'rgba(0, 0, 0, 0.87)' }}>
                                                           {option.box_name}
                                                        </p>:
                                                        <p style={{ margin: 'unset', color: 'rgba(0, 0, 0, 0.87)' }}>
                                                        {option.box_name}(
                                             {option.box_length}{option.box_length_unit} <span style={{ color: '#0082ff' }}> x </span>
                                                                     {option.box_width}{option.box_width_unit} <span style={{ color: '#0082ff' }}> x </span>
                                                                     {option.box_height}{option.box_height_unit}<span>, </span>
                                                                     {option.box_weight}{option.box_weight_unit})
                                                     </p>}
                                                    </MenuItem>
                                                    )}
                                                    onChange={(event, value) => {
                                                        if (value !== null) {
                                                            let event={
                                                                        "target":{
                                                                            "name":"choosenmeasurement",
                                                                            "value":value._id
                                                                        }}
                                                            handleMultiPackageDimensionInputChange(index,event)
                                                        } 
                                                    }}
                                                    renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Choose Measurement"
                                                        variant="standard"
                                                        helperText={errors.province}
                                                    />
                                                    )}
                                                />
                                            </div>
                                            <div className="sc--form--center" style={{ margin: '10px 0' }}>
                                                <TextField id="standard-basic"
                                                    label="Length"
                                                    variant="standard"
                                                    name="length"
                                                    type="number"
                                                    inputProps={{ min: 1 }}
                                                    value={multiPackageFields[index].length}
                                                    onChange={event => handleMultiPackageDimensionInputChange(index, event)}
                                                    error={errors && errors[index] && errors[index].length}
                                                    helperText={errors && errors[index] && errors[index].length}
                                                    style={{ width: '16%' }}
                                                />
                                                <TextField id="standard-basic"
                                                    label="Width"
                                                    variant="standard"
                                                    name="width"
                                                    type="number"
                                                    inputProps={{ min: 1 }}
                                                    value={multiPackageFields[index].width}
                                                    onChange={event => handleMultiPackageDimensionInputChange(index, event)}
                                                    error={errors && errors[index] && errors[index].width}
                                                    helperText={errors && errors[index] && errors[index].width}
                                                    style={{ width: '16%' }}
                                                />
                                                <TextField id="standard-basic"
                                                    label="Height"
                                                    variant="standard"
                                                    name="height"
                                                    type="number"
                                                    inputProps={{ min: 1 }}
                                                    value={multiPackageFields[index].height}
                                                    onChange={event => handleMultiPackageDimensionInputChange(index, event)}
                                                    error={errors && errors[index] && errors[index].height}
                                                    helperText={errors && errors[index] && errors[index].height}
                                                    style={{ width: '16%' }}
                                                />
                                                <FormControl
                                                    variant="standard"
                                                    className="help-text-error"
                                                    style={{ width: '16%', margin: '8px' }}
                                                >
                                                    <InputLabel
                                                        style={{ color: errors.dimension_unit ? "#D32F2F" : '' }}
                                                    > Dim Unit
                                                </InputLabel>
                                                    <Select
                                                        name="dimension_unit"
                                                        onChange={event => handleMultiPackageDimensionInputChange(index, event)}
                                                        value={multiPackageFields[index].dimension_unit}
                                                        error={errors && errors[index] && errors[index].dimension_unit}
                                                        label="Dimension Unit"
                                                        id="filled-error-helper-text"
                                                    >
                                                        <MenuItem value="in">in</MenuItem>
                                                        <MenuItem value="cm">cm</MenuItem>
                                                    </Select>
                                                    <FormHelperText>{errors && errors[index] && errors[index].dimension_unit}</FormHelperText>
                                                </FormControl>

                                            </div>
                                            <div className="sc--form--center" style={{ margin: '10px 0' }}>
                                                <TextField id="actual-weight-id"
                                                    label="Actual weight"
                                                    variant="standard"
                                                    name="actual_weight"
                                                    type="number"
                                                    inputProps={{ min: 1 }}
                                                    value={multiPackageFields[index].actual_weight}
                                                    autoFocus={true}
                                                    onChange={event => handleMultiPackageDimensionInputChange(index, event)}
                                                    error={errors && errors[index] && errors[index].actual_weight}
                                                    helperText={errors && errors[index] && errors[index].actual_weight}
                                                    style={{ width: '50%' }} />

                                                <FormControl
                                                    variant="standard"
                                                    className="help-text-error"
                                                    style={{ width: '16%', margin: '8px' }}
                                                >
                                                    <InputLabel
                                                        id="demo-simple-select-standard-label"
                                                        style={{ color: errors.weight_unit ? "#D32F2F" : '' }}
                                                    > Weight Unit
                                </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-standard-label"
                                                        name="weight_unit"
                                                        onChange={event => handleMultiPackageDimensionInputChange(index, event)}
                                                        value={multiPackageFields[index].weight_unit}
                                                        label="Weight Unit"
                                                    >
                                                        <MenuItem value="lb">lb</MenuItem>
                                                        <MenuItem value="kg">kg</MenuItem>

                                                    </Select>
                                                    <FormHelperText>{errors && errors[index] && errors[index].weight_unit}</FormHelperText>
                                                </FormControl>
                                            </div>
                                            {profile && profile.profile.is_custom_insurance_field_required && profile.profile.is_custom_insurance_field_required == true ?
                                            <div className="sc--form--center" style={{ margin: '10px 0' }}>
                                                <TextField id="insurance-amount-id"
                                                    label="Insurance Amount (Optional)"
                                                    variant="standard"
                                                    name="insurance_amount"
                                                    type="number"
                                                    inputProps={{ min: 100,step:100 }}
                                                    value={multiPackageFields[index].insurance_amount}
                                                    autoFocus={true}
                                                    onChange={event => {
                                                        handleMultiPackageDimensionInputChange(index, event)
                                                        }
                                                    }
                                                    error={errors && errors[index] && errors[index].insurance_amount}
                                                    helperText={errors && errors[index] && errors[index].insurance_amount}
                                                    style={{ width: '50%' }} />

                                                <TextField id="insurance-amountcharge-id"
                                                    label="Charge"
                                                    variant="standard"
                                                    name="insurance_charge"
                                                    type="number"
                                                    value={((Math.ceil(multiPackageFields[index].insurance_amount/100)-1)*2.25) >= 2.25 ? (Math.ceil(multiPackageFields[index].insurance_amount/100)-1)*2.25 : 0}
                                                    style={{ width: '16%',margin:'8px' }} />
                                            </div>
                                            :
                                            <div className="sc--form--center" style={{ margin: '10px 0' }}>
                                                <TextField id="insurance-amount-id"
                                                    label="Insurance Amount (Optional)"
                                                    variant="standard"
                                                    name="insurance_amount"
                                                    type="number"
                                                    inputProps={{ min: 1 }}
                                                    value={multiPackageFields[index].insurance_amount}
                                                    autoFocus={true}
                                                    onChange={event => handleMultiPackageDimensionInputChange(index, event)}
                                                    error={errors && errors[index] && errors[index].insurance_amount}
                                                    helperText={errors && errors[index] && errors[index].insurance_amount}
                                                    style={{ width: '50%' }} />
                                            </div>
                                            }
                                            <div className="sc--form--center">
                                                <span style={{ fontSize: "12px" }}>Note: Free insurance upto $100.</span>
                                            </div>
                                            <div className="sc--form--center" style={{ margin: '0 90px', justifyContent: 'flex-start' }}>
                                                {(config &&
                                                    config.data &&
                                                    config.data.weighing_scale &&
                                                    config.data.weighing_scale.is_enabled) && <Button variant="outlined" style={{ border: '1px solid rgba(0, 0, 0, 0.12)', margin: '0px 15px 15px 0px' }} onClick={() => getWeight(index)}>Get Weight</Button>}
                                            </div>
                                        </div>
                                    </Card>)
                            })}
                            {qzLinkVisible &&
                                <div className="sc--form--center">
                                    <span style={{ "paddingLeft": "35px", "marginTop": "15px", "color": "red" }}>Unable to establish connection with QZ Tray. Please open or <a href="https://qz.io/download/" target="_blank">setup</a></span>
                                </div>
                            }
                            {qzUsbNotConnected &&
                                <div className="sc--form--center">
                                    <span style={{ "paddingLeft": "30px", "marginTop": "15px", "color": "red" }}>Please ensure the Weighing Scale is connected to your system.</span>
                                </div>
                            }
                        </CardContent>
                    </Card>
                </Grid>
        </>)
}

export default MultiPackageDimensions;