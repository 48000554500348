import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// Our Components
import TrackingDrawer from '../../../pages/General/TrackingDrawer'
import Carrier from '../../General/Carrier'
import OrderStatus from '../../General/OrderStatus'
import Customer from '../../../pages/General/Customer'
import Filters from './Filters'
import Search from './Search'
import DynamicColumns from '../../../pages/General/DynamicColumns'
import Notification from '../../../pages/General/Notification'
import NorowsData from '../../../pages/General/NorowsData'
import IntegrationPlatform from '../../../pages/General/IntegrationPlatform'
import DateTime from '../../../pages/General/DateTime'
import NotificationDrawer from '../../../pages/General/NotificationDrawer'
import OrderDrawer from '../../../pages/General/OrderDrawer'
import EmailStatus from '../../General/EmailStatus'
import LinearProgress from '@mui/material/LinearProgress';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import _ from "lodash";
// mui components
import {
    Typography,
    FormControl,
    Pagination,
    IconButton,
    Tooltip,
    Avatar
} from "@mui/material";
import { deepOrange, deepPurple } from '@mui/material/colors';
import Chip from '@mui/material/Chip';
import {
    DataGrid,
    GridToolbarContainer
} from '@mui/x-data-grid';
import {
    fetchNotificationReport, fetchNotificationpreview, fetchNotificationTotalIntegratedPlatform,
    fetchNotificationCategoriesCount, resendNotification, resetResendNotification
} from './notificationsSlice';

import {
    fetchOrderDetail
} from '../../../pages/OrderDetail/orderdetailsSlice';

import SearchIcon from '@mui/icons-material/Search';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ViewColumnSharpIcon from '@mui/icons-material/ViewColumnSharp';
import KeyboardReturnOutlinedIcon from '@mui/icons-material/KeyboardReturnOutlined';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
const NotificationsDashboardPage = () => {
    const dispatch = useDispatch();
    const report = useSelector((state) => state.notificationdashboard.report);
    const resend = useSelector((state) => state.notificationdashboard.resend);
    const [meta, setMeta] = useState(report.meta);
    const [showFilter, setShowFilter] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [trackingDrawerVisible, setTrackingDrawerVisible] = useState(false);
    const [orderDrawerVisible, setOrderDrawerVisible] = useState(false);

    const onMenuClick = (type, show) => {
        setShowSearch((type === "search" && show === true) ? true : false)
        setShowFilter((type === "filter" && show === true) ? true : false)
    }


    const onChangePerPage = (event) => {
        setMeta({
            ...meta,
            ...{
                page: 1,
                per_page: event.target.value
            }
        });
    }

    const onChangePagination = (event, page) => {
        setMeta({
            ...meta,
            ...{
                page: page
            }
        });
    }

    const trackingCallback = (item) => {
        console.log(item)
        dispatch(fetchNotificationpreview(item));
        setTrackingDrawerVisible(true)
    }

    const orderCallback = (item) => {
        dispatch(fetchOrderDetail(item));
        setOrderDrawerVisible(true)
    }
    const onClose = (values) => {
        setTrackingDrawerVisible(false);
    };

    const orderOnClose = (values) => {
        setOrderDrawerVisible(false);
    };
    const Emailresendnotification = (item) => {
        let data = { 'id': item }
        dispatch(resendNotification(data))
    };
    const columns = [
        {
            field: 'orderid',
            headerName: 'Order Id',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            renderCell: (params) => {
                return <span style={{ color: 'rgb(24, 144, 255)', cursor: 'pointer' }} onClick={e => { orderCallback(params.row.orderid) }}>{params.row.orderid}</span>
            }
        },
        {
            field: 'tracking_number',
            headerName: 'Tracking Number',
            headerClassName: 'super-app-theme--header',
            flex: 1
        },
        {
            field: 'created_at',
            headerName: 'Customer',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            renderCell: (params) => {
                if (!_.isUndefined(params.row.customer)) {
                    return (
                        <Customer name={params.row.customer.name} />
                    );
                } else {
                    return "-";
                }
            },
        },
        {
            field: 'platform',
            headerName: 'Platform',
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                if (!_.isUndefined(params.row.platform)) {
                    return <IntegrationPlatform platform={params.row.platform} />;
                } else {
                    return "-";
                }
            }
        },
        {
            field: 'event',
            headerName: 'Event',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            renderCell: (params) => {
                if (params.row.event && params.row.event && params.row.event !== "") {
                    return (
                        <OrderStatus orderStatus={params.row.event} />
                    )
                } else {
                    return <>-</>;
                }
            }
        },
        {
            field: 'status',
            headerName: 'Status',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            renderCell: (params) => {
                if (params.row.status && params.row.status !== "") {
                    return (
                        <EmailStatus emailStatus={params.row.status} />
                    )
                } else {
                    return <>-</>;
                }
            }
        },
        {
            field: 'updated_at',
            headerName: 'Date',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            renderCell: (params) => {
                if (!_.isUndefined(params.row.updated_at)) {
                    return <DateTime datetime={params.row.updated_at} />;
                } else {
                    return "-";
                }
            }
        },
        {
            field: 'preview',
            headerName: 'Action',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            renderCell: (params) => {
                if (params && params.row && params.row.platform !== 'EMAIL') {
                    return (
                        <div>
                            <span style={{ marginRight: '10px' }}>
                                <Tooltip title="Preview" placement="left-start">
                                    <IconButton onClick={e => { trackingCallback(params.row._id) }}>
                                        <RemoveRedEyeIcon style={{ color: "#1890ff", cursor: 'pointer' }} />
                                    </IconButton>
                                </Tooltip>
                            </span>
                        </div>
                    )
                } else {
                    return (
                        <>
                            <div>
                                <span style={{ marginRight: '10px' }}>
                                    <Tooltip title="Preview" placement="left-start">
                                        <IconButton>
                                            <RemoveRedEyeIcon style={{ color: "#1890ff", cursor: 'pointer' }} onClick={e => { trackingCallback(params.row._id) }} />
                                        </IconButton>
                                    </Tooltip>
                                </span>
                                <span style={{ marginRight: '10px' }}><Tooltip title="Email Resend" placement="left-start">
                                    <IconButton onClick={e => { Emailresendnotification(params.row._id) }}>
                                        <KeyboardReturnOutlinedIcon style={{ color: "#1890ff", cursor: 'pointer' }} />
                                    </IconButton>
                                </Tooltip>
                                </span>
                            </div>
                        </>
                    )
                }
            }
        },
    ];



    function CustomTotalRows() {

        return (
            <div className="custom-total-rows">
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    Showing {report && report.data && report.data.length} of {(report && report.meta && report.meta.total) ? report.meta.total : 0}
                </FormControl>
            </div>
        )
    }


    function CustomPagesizeoptions() {

        return (
            <div className="custom-page-size-options">
                <span>
                    Show <select
                        value={meta && meta.per_page}
                        onChange={onChangePerPage}
                    >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </select> entries
                </span>
            </div>
        )
    }

    function CustomToolbar() {

        return (
            <GridToolbarContainer>
                <CustomPagesizeoptions />
                <CustomTotalRows />
            </GridToolbarContainer>
        );
    }

    function CustomPagination() {

        return (
            <Pagination
                color="primary"
                shape="circular"
                page={report && report.meta && report.meta.current_page}
                count={report && report.meta && report.meta.last_page}
                onChange={onChangePagination}
            />
        );
    }

    useEffect(() => {
        dispatch(fetchNotificationReport({ ...meta }));
    }, [meta]);

    return (
        <>
            {(resend && resend.notification && resend.notification.showMessage) &&
                <Notification
                    is_open={resend.notification.showMessage}
                    messageType={resend.notification.messageType}
                    messages={resend.notification.messageContent}
                    handleClose={() => dispatch(resetResendNotification())}
                />}
            <div className="scmaincard">
                <div className="sccardhead">
                    <Typography variant="h5" gutterBottom component="div">
                        Notifications
                    </Typography>
                    <ul className="features-list" style={{ position: "absolute", float: "right", right: "16px" }}>
                        <li>
                            <Tooltip title="Back" placement="top-start">
                                <Link to="/integration/notification" style={{ color: 'blue', textDecoration: 'none' }}>
                                    <IconButton>
                                        <ArrowBackIosNewIcon style={{ color: "#1890ff", cursor: 'pointer' }} />
                                    </IconButton>
                                </Link>
                            </Tooltip>
                        </li>
                        <li>
                            <Tooltip title="Seacrh" placement="top-start">
                                <IconButton style={(showSearch) ? { backgroundColor: 'rgb(230, 230, 230)' } : {}} onClick={() => onMenuClick("search", !showSearch)}>
                                    <SearchIcon style={{ color: 'rgb(25, 118, 210)' }} />
                                </IconButton>
                            </Tooltip>
                        </li>
                        <li>
                            <Tooltip title="Filter" placement="top-start">
                                <IconButton style={(showFilter) ? { backgroundColor: 'rgb(230, 230, 230)' } : {}} onClick={() => onMenuClick("filter", !showFilter)}>
                                    <FilterAltOutlinedIcon style={{ color: 'rgb(25, 118, 210)' }} />
                                </IconButton>
                            </Tooltip>
                        </li>
                    </ul>
                </div>
            </div>
            <div>
                <Search visible={showSearch} meta={meta} setMeta={setMeta} />
                {showFilter && <Filters visible={showFilter} meta={meta} setMeta={setMeta} />}
                <div style={{ width: '100%', background: '#fff', marginTop: "20px" }}>
                    <DataGrid
                        style={{ paddingTop: "37px" }}
                        getRowId={(row) => row._id}
                        rows={report && report.data}
                        columns={columns}
                        autoHeight={true}
                        disableColumnFilter={true}
                        disableColumnMenu={true}
                        sortingMode="server"
                        disableRowSelectionOnClick
                        slots={{
                            pagination: CustomPagination,
                            toolbar: CustomToolbar,
                            NorowsData,
                            loadingOverlay: LinearProgress,
                        }}
                        loading={
                            (report && report.loading)
                        }
                    />
                </div>

            </div>
            <NotificationDrawer
                visible={trackingDrawerVisible} onClose={onClose}
            />
            <OrderDrawer
                visible={orderDrawerVisible} onClose={orderOnClose}
            />


        </>
    )
}

export default NotificationsDashboardPage;
