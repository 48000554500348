import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { fetchBrightPearlData, configureStore, resetConfigureState } from './brightpearlSlice';
import ShipmentUpdateFlag from '../../../../General/ShipmentUpdateFlag';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    height: '100%',
    padding: '20px 0'
}));
const BrightpearlIntegration = ({ storeName, storeId }) => {
    const dispatch = useDispatch();
    const integratedStore = useSelector(state => state.integrated);
    const profile = useSelector(state => state.auth);
    const progress = useSelector(state => state.brightpearl && state.brightpearl.progress);
    const configure = useSelector(state => state.brightpearl && state.brightpearl.configure);

    const [accountCode, setAccountCode] = useState("");
    const [isUpdateShipment, setIsUpdateShipment] = useState(progress && progress.data && progress.data.is_update_shipment)
    const [isCreateShipmentStatus, setIsCreateShipmentStatus] = useState(progress && progress.data && progress.data.is_update_shipment_on_create)
    const [isManifestCloseStatus, setIsManifestCloseStatus] = useState(progress && progress.data && progress.data.is_update_shipment_on_close)
    const [btnDisabled, setBtnDisabled] = useState(true);
    const [btnConfigDisabled, setBtnConfigDisabled] = useState(true);
    const [authorizeUrl, setAuthorizeUrl] = useState('');
    const [alert, setAlert] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const params = new URLSearchParams(window.location.search)
    let storeParam = params.get('account')

    useEffect(() => {
        if (progress && progress.data) {
            setIsUpdateShipment(progress && progress.data && progress.data.is_update_shipment);
            setIsCreateShipmentStatus(progress && progress.data && progress.data.is_update_shipment_on_create);
            setIsManifestCloseStatus(progress && progress.data && progress.data.is_update_shipment_on_close);
        }
    }, [progress && progress.data]);

    useEffect(() => {
        if (configure && configure.message) {
            setAlert(true)
            setSnackOpen(true)
        } else if (configure && configure.hasErrors == true) {
            setAlert(true)
            setSnackOpen(true)
        }
    }, [dispatch, configure && configure.message])

    useEffect(() => {
        let apikey = profile.profile.api_token
        const data = { apikey: apikey, store_name: storeName, store_id: storeId }
        dispatch(fetchBrightPearlData(data));
    }, [dispatch]);

    const handleClose = () => {
        dispatch(resetConfigureState())
        setSnackOpen(false);
        if (storeParam && configure && configure.hasErrors == false) {
            window.location.href = window.location.origin + "/integration/marketplace"
        }
    };

    const inputChange = (e) => {
        let userId = profile.profile.id;
        let account = e.target.value;
        setAccountCode(account)
        let url = "https://oauth.brightpearl.com/authorize/"
            + account + "?response_type=code&client_id=shippingchimp&redirect_uri="
            + process.env.REACT_APP_INTEGRATIONS_API_DOMAIN + "/bp/callback&state=" + userId;
        setAuthorizeUrl(url);

        let success = false;
        if (integratedStore && integratedStore.data && integratedStore.data.length !== 0) {
            integratedStore.data.map((item) => {
                if (account === item.store_name && item.platform === "BRIGHTPEARL") {
                    setBtnDisabled(true)
                    success = true;
                } else if (success === false && account !== item.store_name) {
                    setBtnDisabled(false)
                }
            })
        } else {
            setBtnDisabled(false)
        }
    }

    const updateCallBackValue = (updateValue) => {
        setIsUpdateShipment(updateValue.is_update_shipment);
        setIsCreateShipmentStatus(updateValue.is_update_shipment_on_create);
        setIsManifestCloseStatus(updateValue.is_update_shipment_on_close);
        setBtnConfigDisabled(false)
    };

    const configurestore = () => {
        let storeDetails = ""
        if (storeName) {
            storeDetails = storeName;
        } else {
            storeDetails = storeParam;
        }
        const data = {
            "store_name": storeDetails,
            'is_update_shipment': isUpdateShipment,
            'is_update_shipment_on_create': isCreateShipmentStatus,
            'is_update_shipment_on_close': isManifestCloseStatus
        }
        dispatch(configureStore(data))
    }

    return (
        <>

            {alert &&
                <Snackbar
                    open={snackOpen}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    autoHideDuration={6000}
                    onClose={handleClose}
                >
                    <Alert
                        variant="filled"
                        severity=
                        {configure && configure.hasErrors === true ? "error" : "success"}
                        style={{ justifyContent: 'center', margin: "10px 0" }}
                    >
                        <span > {configure && configure.message}</span>
                    </Alert>
                </Snackbar>
            }
            {(!storeParam && !storeName) &&
                <Grid container spacing={1}>
                    <Grid item xs={12} md={12} style={{ textAlign: 'center' }}>
                        <Item>
                            <div>
                                <img
                                    src="/assets/images/integration/BRIGHTPEARL.png"
                                    alt="BRIGHTPEARL"
                                    style={{ margin: 'auto', display: 'flex', maxWidth: '60px' }} />
                            </div>
                            <div
                                className="sc--form--center"
                                style={{ margin: 'auto', textAlign: 'center', width: '50%', marginBottom: '10px' }}>
                                <TextField
                                    id="standard-numbe"
                                    label="Account code"
                                    size="small"
                                    onInput={inputChange}
                                    style={{ width: "40%", margin: '8px' }}
                                    variant="outlined"
                                    error={btnDisabled && accountCode !== ''}
                                    helperText={btnDisabled && accountCode !== '' ? "this brightpearl account already integrated" : ""}
                                />
                            </div>
                            <div
                                className="sc--form--center"
                                style={{ margin: 'auto', textAlign: 'center', width: '50%' }}>
                                <Button
                                    variant="contained"
                                    disabled={btnDisabled}
                                    href={authorizeUrl}
                                    target="_blank"
                                >Authorize <SendOutlinedIcon style={{ paddingLeft: '10px' }} />
                                </Button>
                            </div>
                        </Item>
                    </Grid>
                </Grid>
            }
            {(progress && progress.loading === false) && <>
                {(storeParam || storeName === 'all' || storeName) &&
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={12}>
                            <Item>
                                <div
                                    className="sc--form--center"
                                    style={{ margin: 'auto', textAlign: 'center', width: '50%' }}>
                                    <TextField
                                        id="standard-numbe"
                                        label="Brightpearl Account"
                                        value={storeName ? storeName : storeParam}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        size="small"
                                        style={{ width: "60%", margin: '8px' }}
                                        variant="filled"
                                    />
                                </div>
                                <ShipmentUpdateFlag
                                    updateFlag={isUpdateShipment}
                                    onCreateFlag={isCreateShipmentStatus}
                                    onCloseFlag={isManifestCloseStatus}
                                    updateCallBack={updateCallBackValue}
                                    message={"Do you want to update shipment information back to woocommerce account?"}
                                />
                                <div
                                    className="sc--form--center"
                                    style={{ margin: '10px 0', textAlign: 'center' }}
                                >
                                    <LoadingButton
                                        variant="contained"
                                        onClick={configurestore}
                                        disabled={btnConfigDisabled}
                                        loading={configure && configure.loading}
                                    >Configure
                                    </LoadingButton>
                                </div>
                            </Item>
                        </Grid>
                    </Grid>
                }
            </>
            }
        </>
    )
}


export default BrightpearlIntegration;