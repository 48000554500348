import { createSlice } from '@reduxjs/toolkit';
import { OMSAPI } from '../../../../../API';

export const emailslice = createSlice({
    name: 'email',
    initialState: {
        data: [],
        meta: [],
        message: [],
        loading: false,
        hasErrors: false,
        eventDetail: { data: {}, loading: false },
        event: { data: {}, loading: false },
        preview: { hasErrors: false, loading: false, success: false, notification: { showMessage: false, messageContent: "", messageType: "success" } },
        notification: { showMessage: false, messageContent: "", messageType: "success" },
        emailtemplate: { data: [], loading: false },
        unlayertemplate: { data: {}, message: "", loading: false, },
        channelconfig: { data: [], meta: [], message: [], loading: false, hasErrors: false, notification: { showMessage: false, messageContent: "", messageType: "success" } },
        notificationconfig: { data: [], meta: [], message: [], loading: false, hasErrors: false, notification: { showMessage: false, messageContent: "", messageType: "success" } }
    },
    reducers: {
        getNotificationEvents: (state) => {
            return {
                ...state,
                event: {
                    loading: true
                }
            }
        },
        getNotificationEventsSuccess: (state, action) => {
            return {
                ...state,
                event: {
                    data: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data,
                    hasErrors: false,
                    loading: false
                }
            }
        },
        getNotificationEventsFailure: (state, action) => {
            return {
                ...state,
                event: {
                    loading: false,
                    hasErrors: true
                }
            }
        },
        getEmailTemplate: (state) => {
            return {
                ...state,
                emailtemplate: {
                    loading: true
                }
            }
        },
        getEmailTemplateSuccess: (state, action) => {
            return {
                ...state,
                emailtemplate: {
                    data: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data,
                    hasErrors: false,
                    loading: false
                }

            }
        },
        getEmailTemplateFailure: (state, action) => {
            return {
                ...state,
                emailtemplate: {
                    loading: false,
                    hasErrors: true
                }

            }
        },
        getEmailTemplateId: (state) => {
            return {
                ...state,
                unlayertemplate: {
                    loading: true,
                    hasErrors: false,
                },
            }
        },
        getEmailTemplateIdSuccess: (state, action) => {
            return {
                ...state,
                unlayertemplate: {
                    data: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data,
                    message: action && action.payload && action.payload.data && action.payload.data.message && action.payload.data.message,
                    loading: false,
                    hasErrors: false
                },
            }
        },
        getEmailTemplateIdFailure: (state, action) => {
            return {
                ...state,
                unlayertemplate: {
                    message: action && action.payload && action.payload.data && action.payload.data.message && action.payload.data.message,
                    loading: false,
                    hasErrors: true,
                },
            }
        },
        getNotificationEventDetail: (state) => {
            return {
                ...state,
                eventDetail: {
                    data: {},
                    loading: true,
                    changedState: false
                }
            }
        },
        getNotificationEventDetailSuccess: (state, action) => {
            return {
                ...state,
                eventDetail: {
                    data: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data,
                    hasErrors: false,
                    loading: false,
                    changedState: true
                }

            }
        },
        getNotificationEventDetailFailure: (state, action) => {
            return {
                ...state,
                eventDetail: {
                    loading: false,
                    hasErrors: true,
                    changedState: false
                }
            }
        },
        getchannelconfig: (state) => {
            return {
                ...state,
                channelconfig: {
                    loading: true
                }
            }
        },
        getchannelconfigSuccess: (state, action) => {
            return {
                ...state,
                channelconfig: {
                    data: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data,
                    hasErrors: false,
                    loading: false,
                }
            }
        },
        getchannelconfigFailure: (state, action) => {
            return {
                ...state,
                channelconfig: {
                    data:[],
                    notification: { showMessage: true, messageContent: [action && action.payload && action.payload.data && action.payload.data.message], messageType: "error" },
                    loading: false,
                    hasErrors: true
                }
            }
        },
        putchannelconfig: (state) => {
            return {
                ...state,
                notificationconfig: {
                    loading: true
                }
            }
        },
        putchannelconfigSuccess: (state, action) => {
            return {
                ...state,
                notificationconfig: {
                    data: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data,
                    hasErrors: false,
                    loading: false,
                    notification: { showMessage: true, messageContent: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.message, messageType: "success" }
                }
            }
        },
        putchannelconfigFailure: (state, action) => {
            return {
                ...state,
                notificationconfig: {
                    loading: false,
                    hasErrors: true
                }
            }
        },
        putNotificationEvents: (state) => {
            return {
                ...state,
                notificationconfig: {
                    loading: true
                }
            }
        },
        putNotificationEventsSuccess: (state, action) => {
            return {
                ...state,
                notificationconfig: {
                    data: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data,
                    hasErrors: false,
                    loading: false,
                    notification: { showMessage: true, messageContent: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.message, messageType: "success" }
                }
            }
        },
        putNotificationEventsFailure: (state, action) => {
            return {
                ...state,
                notificationconfig: {
                    loading: false,
                    hasErrors: true
                }
            }
        },
        postPreviewEmail: (state) => {
            return {
                ...state,
                preview: {
                    loading: true,
                    hasErrors: false,
                    success: false
                }
            }
        },
        postPreviewEmailSuccess: (state, action) => {
            return {
                ...state,
                preview: {
                    hasErrors: false,
                    loading: false,
                    success: true,
                    notification: { showMessage: true, messageContent: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.message, messageType: "success" }
                },
            }
        },
        postPreviewEmailFailure: (state, action) => {
            return {
                ...state,
                preview: {
                    loading: false,
                    hasErrors: true,
                    success: false
                },
                notification: { showMessage: true, messageContent: action && action.payload && action.payload.data && action.payload.data.message, messageType: "error" }
            }
        },
        resetConfigureStateDetails: (state, action) => {
            return {
                ...state,
                // eventDetail: { data: {}, loading: false },
                notificationconfig: {
                    notification: { showMessage: false, messageContent: "", messageType: "success" },
                },
                preview: {
                    notification: { showMessage: false, messageContent: "", messageType: "success" },
                }
            }
        },

    }
})
export const { getNotificationEvents, getNotificationEventsSuccess, getNotificationEventsFailure, getEmailTemplate, getEmailTemplateSuccess,
    getEmailTemplateFailure, getEmailTemplateId, getEmailTemplateIdSuccess, getEmailTemplateIdFailure, getNotificationEventDetail, getNotificationEventDetailSuccess,
    getNotificationEventDetailFailure, getchannelconfig, getchannelconfigSuccess, getchannelconfigFailure, putchannelconfig, putchannelconfigSuccess, putchannelconfigFailure
    , putNotificationEvents, putNotificationEventsSuccess, putNotificationEventsFailure, postPreviewEmail, postPreviewEmailSuccess, postPreviewEmailFailure,
    resetConfigureStateDetails } = emailslice.actions;

export const fetchNotificationEvents = data => dispatch => {
    dispatch(getNotificationEvents());
    try {
        OMSAPI.get('/notificationevents')
            .then(function (response) {
                dispatch(getNotificationEventsSuccess(response));
            }).catch(function (error) {
                dispatch(getNotificationEventsFailure(error.response));
            })
    }
    catch (error) {
        dispatch(getNotificationEventsFailure(error.response));
    }
}

export const fetchEmailTemplate = data => dispatch => {
    dispatch(getEmailTemplate());
    try {
        OMSAPI.get('/email-template')
            .then(function (response) {
                dispatch(getEmailTemplateSuccess(response));
            }).catch(function (error) {
                dispatch(getEmailTemplateFailure(error.response));
            })
    }
    catch (error) {
        dispatch(getEmailTemplateFailure(error.response));
    }
}

export const fetchEmailTemplateId = data => dispatch => {
    dispatch(getEmailTemplateId());
    try {
        OMSAPI.get('/email-template/' + data)
            .then(function (response) {
                dispatch(getEmailTemplateIdSuccess(response));
            }).catch(function (error) {
                dispatch(getEmailTemplateIdFailure(error.response));
            })
    }
    catch (error) {
        dispatch(getEmailTemplateIdFailure(error.response));
    }
}
export const fetchNotificationEventDetail = (event = "") => dispatch => {
    dispatch(getNotificationEventDetail());
    try {
        OMSAPI.get('/notificationevents/' + event)
            .then(function (response) {
                dispatch(getNotificationEventDetailSuccess(response));
            }).catch(function (error) {
                dispatch(getNotificationEventDetailFailure(error.response));
            })
    }
    catch (error) {
        dispatch(getNotificationEventDetailFailure(error.response));
    }
}
export const fetchchannelconfig = (id = "") => dispatch => {
    dispatch(getchannelconfig());

    try {
        OMSAPI.get('/notifications' + (id==='' ? "":"/"+id))
            .then(function (response) {
                dispatch(getchannelconfigSuccess(response));
            }).catch(function (error) {
                dispatch(getchannelconfigFailure(error.response));
            })
    }
    catch (error) {
        dispatch(getchannelconfigFailure(error.response));
    }
}


export const updatechannelconfig = (updatedata) => dispatch => {
    dispatch(putchannelconfig());
    const id = updatedata.id;
    try {
        OMSAPI.patch('/notifications/' + id, updatedata)
            .then(function (response) {
                dispatch(putchannelconfigSuccess(response));
                // dispatch(resetNotification());
                dispatch(fetchchannelconfig());
            }).catch(function (error) {
                dispatch(putchannelconfigFailure(error.response));
                // dispatch(resetNotification());
            })
    }
    catch (error) {
        dispatch(putchannelconfigFailure());
    }
}

export const updateNotificationEvents = (updatedata) => dispatch => {
    dispatch(putNotificationEvents());
    var id = updatedata.id;
    try {
        OMSAPI.patch('/notificationevents/' + id, updatedata)
            .then(function (response) {
                dispatch(putNotificationEventsSuccess(response));
                // dispatch(fetchNotificationEvents());
                // dispatch(fetchchannelconfig());
            }).catch(function (error) {
                dispatch(putNotificationEventsFailure());
            })
    }
    catch (error) {
        dispatch(putNotificationEventsFailure());
    }
}
export const sendPreviewEmail = (event, data) => dispatch => {
    dispatch(postPreviewEmail());
    try {
        OMSAPI.post('/notifications/preview/' + event, data)
            .then(function (response) {
                dispatch(postPreviewEmailSuccess(response));
            }).catch(function (error) {
                dispatch(postPreviewEmailFailure(error.response));
            })
    }
    catch (error) {
        dispatch(postPreviewEmailFailure(error.response));
    }
}
export const resetConfigureState = data => dispatch => {
    dispatch(resetConfigureStateDetails());
}
export default emailslice.reducer;
