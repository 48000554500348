import { createSlice } from '@reduxjs/toolkit';
import { OMSAPI } from '../../API';
import { LABELGENAPI, LABELINGCHIMP_SAM_API,NOTIFICATIONAPI, LABELINGCHIMP_LABEL_API } from '../../API';
import axios from 'axios';

/* canceling previous requests if multiple requests is triggered */
const CancelToken = axios.CancelToken;
let source = CancelToken.source();

export const countrystatecityslice = createSlice({
    name: 'rate',
    initialState: {
        country: {
            loading: false,
            data: []
        },
        state: {
            loading: false,
            data: []
        },
        multistates: {
            loading: false,
            data: []
        },
        city: {
            loading: false,
            data: []
        }
    },
    reducers: {
        getCountry: (state) => {
            return {
                ...state,
                country: {
                    loading: true,
                    data: []
                }
            }
        },
        getCountrySuccess: (state, action) => {
            return {
                ...state,
                country: {
                    loading: false,
                    data: action && action.payload && action.payload.data && action.payload.data.data
                }
            }
        },
        getCountryFailure: (state, action) => {
            return {
                ...state,
                country: {
                    loading: false,
                    data: []
                }
            }
        },
        getState: (state) => {
            return {
                ...state,
                state: {
                    loading: true,
                    data: []
                }
            }
        },
        getStateSuccess: (state, action) => {
            return {
                ...state,
                state: {
                    loading: false,
                    data: action && action.payload && action.payload.data && action.payload.data.data
                }
            }
        },
        getStateFailure: (state, action) => {
            return {
                ...state,
                state: {
                    loading: false,
                    data: []
                }
            }
        },
        getMultiStates: (state) => {
            return {
                ...state,
                multistates: {
                    loading: true,
                    data: []
                }
            }
        },
        getMultiStatesSuccess: (state, action) => {
            return {
                ...state,
                multistates: {
                    loading: false,
                    data: action && action.payload && action.payload.data && action.payload.data.data
                }
            }
        },
        getMultiStatesFailure: (state, action) => {
            return {
                ...state,
                multistates: {
                    loading: false,
                    data: []
                }
            }
        },
        getCity: (state) => {
            return {
                ...state,
                city: {
                    loading: true,
                    data: []
                }
            }
        },
        getCitySuccess: (state, action) => {
            return {
                ...state,
                city: {
                    loading: false,
                    data: action && action.payload && action.payload.data && action.payload.data.data
                }
            }
        },
        getCityFailure: (state, action) => {
            return {
                ...state,
                city: {
                    loading: false,
                    data: []
                }
            }
        },




    }
})
export const { getCountry, getCountrySuccess, getCountryFailure, getState, getStateSuccess, getStateFailure,getMultiStates, getMultiStatesSuccess, getMultiStatesFailure, getCity, getCitySuccess, getCityFailure } = countrystatecityslice.actions;

export const fetchCountry = data => dispatch => {

    dispatch(getCountry());
    try {
        NOTIFICATIONAPI.get('/utility/country')
            .then(function (response) {
                dispatch(getCountrySuccess(response));
            })
            .catch(function (error) {
                dispatch(getCountryFailure(error.response));
            });
    } catch (error) {
        dispatch(getCountryFailure());
    }
};


export const fetchState = country => dispatch => {    
    dispatch(getState());
    try {
        NOTIFICATIONAPI.get('/utility/state/' + country)
            .then(function (response) {
                dispatch(getStateSuccess(response));
            })
            .catch(function (error) {
                dispatch(getStateFailure(error.response));
            });
    } catch (error) {
        dispatch(getStateFailure());
    }
};

export const fetchMultiStates = country => dispatch => {   
    dispatch(getMultiStates());
    try {
        NOTIFICATIONAPI.get('/utility/states/' + country)
            .then(function (response) {
                dispatch(getMultiStatesSuccess(response));
            })
            .catch(function (error) {
                dispatch(getMultiStatesFailure(error.response));
            });
    } catch (error) {
        dispatch(getMultiStatesFailure());
    }
};

export const fetchCity = (country, state) => dispatch => {
    dispatch(getCity());
    try {
        NOTIFICATIONAPI.get('/utility/city/' + country + '/' + state)
            .then(function (response) {
                dispatch(getCitySuccess(response));
            })
            .catch(function (error) {
                dispatch(getCityFailure(error.response));
            });
    } catch (error) {
        dispatch(getCityFailure());
    }
};



export default countrystatecityslice.reducer;
