import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// our components
import NorowsData from './NorowsData'
import Notification from './Notification'
import Carrier from './Carrier'
import OrderStatus from './OrderStatus'
import Customer from './Customer'
import CarrierTracking from './CarrierTracking'
import ShipmentRate from './ShipmentRate'

// mui components
import {
    Typography,
    FormControl,
    Pagination,
    Card,
    CardContent,
    Stack,
    Tooltip,
    IconButton,
    Button,
    Backdrop,
    CircularProgress,
    Drawer,
    Box,
    Divider,
    CardHeader,
    Paper
} from "@mui/material";
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

import {
    DataGrid,
    GridToolbarContainer
} from '@mui/x-data-grid';

import CloseIcon from '@mui/icons-material/Close';
import Date from './Date'
import TrackingDrawer from '../../pages/General/TrackingDrawer';
import OrderDrawer from '../General/OrderDrawer';
import { fetchOrderDetail } from '../../pages/OrderDetail/orderdetailsSlice';
import { fetchTrackingDetail } from '../Reports/Deliveries/trackingEventsSlice';
import {
    doGetMysqlDeliveries,
    resetDeliveriessNotifications
} from '../Reports/Deliveries/deliveriesmysqlSlice';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    boxShadow: 'unset',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    color: theme.palette.text.secondary,
    height: '100%'
}));

const UnclosedShipmentDrawer = ({ visible, unclosedShipmentsOnClose }) => {
    const dispatch = useDispatch();
    const deliveries = useSelector(state => state.deliveriesmysql);
    const profile = useSelector(state => state.auth);
    const [meta, setMeta] = useState(deliveries.deliveriesData.meta);
    const [drawer, setDrawer] = useState(true);
    const [trackingDrawerVisible, setTrackingDrawerVisible] = useState(false);
    const [orderDrawerVisible, setOrderDrawerVisible] = useState(false);
    const [isMultiPiece, setIsMultiPiece] = useState(false)
    const ondrawerclose = () => {
        dispatch(resetDeliveriessNotifications());
        setDrawer(false)
        unclosedShipmentsOnClose(false)
    }

    let columns = [{
        field: 'order_id',
        headerName: 'Order',
        headerClassName: 'super-app-theme--header',
        minWidth: 180,
        sortable: false,
        flex: 1,
        renderCell: (params) => {
            return <span style={{ color: 'rgb(24, 144, 255)', cursor: 'pointer' }} onClick={e => { orderCallback(params.row.order_id) }}>{params.row.order_id}</span>

        }
    },
    {
        field: 'tracking_number',
        headerName: 'Tracking Number',
        minWidth: 180,
        sortable: false,
        flex: 1,
        renderCell: (params) => {
            let tracking_number = (params.row.tracking_number
                && typeof params.row.tracking_number !== "undefined" && params.row.tracking_number !== null)
                ? params.row.tracking_number : "";
            let carrier_name = (params.row.label_carrier_name
                && typeof params.row.label_carrier_name !== "undefined" && params.row.label_carrier_name !== null)
                ? params.row.label_carrier_name : "";
            let message = (params.row && params.row.is_manifest_closed === false)
                ? "This shipment manifest is not yet closed" : null;
            let tracking = { "tracking_number": tracking_number, "carrier_name": carrier_name }
            return (
                <CarrierTracking trackingItems={tracking} dispatchCallback={trackingCallback} carrierImageWidth="60px" message={message} />
            )
        }
    },
    {
        field: 'shipment_status_category',
        headerName: 'Shipment Status',
        minWidth: 180,
        sortable: false,
        flex: 1,
        renderCell: (params) => {
            return (
                <OrderStatus orderStatus={params.row.shipment_status_category}
                />
            )
        }
    },
    {
        field: 'customer_first_name',
        headerName: 'Customer',
        minWidth: 170,
        sortable: false,
        flex: 1,
        renderCell: (params) => {
            let name = ""
            if (params.row.customer_first_name !== "undefined" && params.row.customer_first_name !== null) {
                name = params.row.customer_first_name;
            }
            if (params.row.customer_last_name !== "undefined" && params.row.customer_last_name !== null) {
                name = name + " " + params.row.customer_last_name
            }
            return (
                <Customer name={name} />
            )
        }
    },
    {
        field: 'shipment_date',
        headerName: 'Shipment Date',
        minWidth: 180,
        flex: 1,
        renderCell: (params) => {
            if (params.row && params.row.shipment_date) {
                return <Date date={params.row.shipment_date} />
            } else {
                return "-"
            }
        }
    },
    {
        field: 'rate_total_charges',
        headerName: 'Rate',
        sortable: false,
        flex: 1,
        minWidth: 100,
        renderCell: (params) => {
            let voided = ((params.row.is_void_in_progress && params.row.is_void_in_progress !== null) || (params.row.is_voided && params.row.is_voided !== null)) ? true : false
            return <ShipmentRate
                billed_rate={params.row.rate_billed_total}
                api_rate={params.row.rate_total_charges}
                is_multi_piece={params.row.is_multi_piece_label}
                is_voided={voided}
            />
        }
    },
    ]

    useEffect(() => {
        dispatch(doGetMysqlDeliveries({
            ...meta,
            ...{
                "unclosed_shipment": true
            }
        }))
    }, [meta]) 

    const orderCallback = (item) => {
        dispatch(fetchOrderDetail(item));
        setOrderDrawerVisible(true)
    }

    const orderOnClose = () => {
        setOrderDrawerVisible(false);
    }

    const trackingCallback = (carriername, trackingNumber) => {
        let apikey = (profile && profile.profile && profile.profile.api_token) ? profile.profile.api_token : "";
        const data = { "carrier_name": carriername, 'tracking_number': trackingNumber }
        let firstChar=trackingNumber.charAt(0);
        if(firstChar=='M'){
          setIsMultiPiece(true)
        }
        dispatch(fetchTrackingDetail(data, apikey))
        setTrackingDrawerVisible(true)
    }

    const onClose = () => {
        setTrackingDrawerVisible(false);
    };

    const onSortChange = (currentSorting) => {
        setMeta({
            ...meta,
            ...{
                sorting: JSON.stringify(currentSorting)
            }
        });
    }

    const onChangePerPage = (event) => {
        setMeta({
            ...meta,
            ...{
                page: 1,
                per_page: event.target.value
            }
        });
    }

    const onChangePagination = (event, page) => {
        setMeta({
            ...meta,
            ...{
                page: page
            }
        });
    }

    function CustomTotalRows() {

        return (
            <div className="custom-total-rows">
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    Showing {deliveries && deliveries.deliveriesData && deliveries.deliveriesData.data && deliveries.deliveriesData.data.length} of {(deliveries && deliveries.deliveriesData && deliveries.deliveriesData.meta && deliveries.deliveriesData.meta.total) ? deliveries.deliveriesData.meta.total : 0}
                </FormControl>
            </div>
        )
    }

    function CustomPagesizeoptions() {
        return (
            <div className="custom-page-size-options">
                <span>
                    Show <select
                        value={meta && meta.per_page}
                        onChange={onChangePerPage}
                    >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </select> entries
                </span>
            </div>
        )
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <CustomPagesizeoptions />
                <CustomTotalRows />
            </GridToolbarContainer>
        );
    }

    function CustomPagination() {
        return (
            <Pagination
                color="primary"
                shape="circular"
                page={deliveries && deliveries.deliveriesData && deliveries.deliveriesData.meta && deliveries.deliveriesData.meta.current_page}
                count={deliveries && deliveries.deliveriesData && deliveries.deliveriesData.meta && deliveries.deliveriesData.meta.last_page}
                onChange={onChangePagination}
            />
        );
    }

    return (
        <>
            <Drawer
                anchor="right"
                open={visible}
                onClose={ondrawerclose}
                PaperProps={{
                    sx: { width: "85%" },
                }}
            >
                {(deliveries && deliveries.deliveriesData && deliveries.deliveriesData.notification && deliveries.deliveriesData.notification.show) &&
                    <Notification
                        is_open={deliveries.deliveriesData.notification.show}
                        messageType={deliveries.deliveriesData.notification.messageType}
                        messages={deliveries.deliveriesData.notification.messageContent}
                        handleClose={() => dispatch(resetDeliveriessNotifications())}
                    />}
                <div style={{ padding: "0 30px" }}>
                    <Card sx={{ marginTop: "20px" }} className="sc--cardhead--details">
                        <div style={{ width: '100%', background: '#fff' }}>
                            <CardHeader title="Unclosed Shipment Details" action={
                                <div>
                                    <IconButton aria-label="settings" onClick={ondrawerclose}>
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                            }
                            />
                            <DataGrid
                                style={{ paddingTop: "37px" }}
                                getRowId={(row) => row.id}
                                rows={deliveries && deliveries.deliveriesData && deliveries.deliveriesData.data}
                                columns={columns}
                                autoHeight={true}
                                disableColumnFilter={true}
                                disableColumnMenu={true}
                                sortingMode="server"
                                onSortModelChange={(newSortModel) => onSortChange(newSortModel)}
                                sortModel={meta && meta.sorting && JSON.parse(meta.sorting)}
                                disableRowSelectionOnClick
                                slots={{
                                    loadingOverlay: LinearProgress,
                                    pagination: CustomPagination,
                                    toolbar: CustomToolbar,
                                    NorowsData
                                }}
                                loading={
                                    (deliveries && deliveries.deliveriesData && deliveries.deliveriesData.loading)
                                }
                            />
                        </div>
                    </Card>
                </div>
                <TrackingDrawer
                    visible={trackingDrawerVisible}  isMultiPiece={isMultiPiece} onClose={onClose}
                />
                <OrderDrawer
                    visible={orderDrawerVisible} onClose={orderOnClose}
                />
            </Drawer >
        </>
    )
}

export default UnclosedShipmentDrawer;