import { createSlice } from '@reduxjs/toolkit';

export const qzslice = createSlice({
    name: 'qz',
    initialState: {
        is_active: false,
        printer: "",
        qz: "",
        queue_count: 0,
        progress: false,
        notification: { show: false, messageContent: "", messageType: "success" }
    },
    reducers: {
        setQzState: (state, action) => {
            return {
                ...state,
                is_active: action.payload.is_active,
                printer: action.payload.printer,
                qz: action.payload.qz,
                queue_count: 0,
                progress: false
            }
        },
        setQzQueueCount: (state, action) => {
            return {
                ...state,
                queue_count: (action.payload.added && action.payload.added == true) ? state.queue_count + 1 : state.queue_count - 1
            }
        },
        setQzProgress: (state, action) => {
            return {
                ...state,
                progress: true
            }
        },
        setQzNotification: (state, action) => {
            return {
                ...state,
                notification: { show: true, messageContent: action.payload.message, messageType: (action.payload.error) ? "error": "success" }
            }
        },
        resetQzNotification: (state, action) => {
            return {
                ...state,
                notification: { show: false, messageContent: "", messageType: "success" }
            }
        }
    }
})

export const { setQzState, setQzNotification, resetQzNotification, setQzQueueCount, setQzProgress } = qzslice.actions;

export const setQzStateInfo = (data) => {
    return (dispatch) => {
        dispatch(setQzState(data))
    }
}

export const setQzInProgress = () => {
    return (dispatch) => {
        dispatch(setQzProgress())
    }
}

export const setQzQueueCounts = (data) => {
    return (dispatch) => {
        dispatch(setQzQueueCount(data))
    }
}

export const setQzNotifications = (data) => {
    return (dispatch) => {
        dispatch(setQzNotification(data))
    }
}

export const resetQzNotifications = () => {
    return (dispatch) => {
        dispatch(resetQzNotification());
    }
}

export default qzslice.reducer;