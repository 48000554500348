import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import { fetchWooCommerceData, configureStore, fetchWoocommerceAppCheckUrl, resetConfigureState } from './woocommerceSlice';
import ShipmentUpdateFlag from '../../../../General/ShipmentUpdateFlag';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    height: '100%',
    padding: '20px 0'
}));
const WoocommerceIntegration = ({ storeName, platformName, storeParam, storeId }) => {
    const dispatch = useDispatch();
    const integratedStore = useSelector(state => state.integrated);
    const profile = useSelector(state => state.auth);
    const checkUrl = useSelector(state => state.woocommerce && state.woocommerce.validate);
    const progress = useSelector(state => state.woocommerce && state.woocommerce.progress);
    const configure = useSelector(state => state.woocommerce && state.woocommerce.configure);

    const [storeUrl, setStoreUrl] = useState("");
    const [btnDisabled, setBtnDisabled] = useState(true);
    const [btnConfigDisabled, setBtnConfigDisabled] = useState(true);
    const [authorizeUrl, setAuthorizeUrl] = useState('');
    const [isUpdateShipment, setIsUpdateShipment] = useState(progress && progress.data && progress.data.is_update_shipment)
    const [isCreateShipmentStatus, setIsCreateShipmentStatus] = useState(progress && progress.data && progress.data.is_update_shipment_on_create)
    const [isManifestCloseStatus, setIsManifestCloseStatus] = useState(progress && progress.data && progress.data.is_update_shipment_on_close)
    const [authorizeCheck, setAuthorizeCheck] = useState(false);
    const [alert, setAlert] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const [storeExistError, setStoreExistError] = useState("");

    useEffect(() => {
        if (configure && configure.message) {
            setAlert(true)
            setSnackOpen(true)
        } else if (configure && configure.hasErrors == true) {
            setAlert(true)
            setSnackOpen(true)
        }
    }, [dispatch, configure && configure.message])

    useEffect(() => {
        if (storeName) {
            let apikey = profile.profile.api_token
            const data = { apikey: apikey, store_name: storeName, store_id: storeId }
            dispatch(fetchWooCommerceData(data));
        }
    }, [dispatch]);

    useEffect(() => {
        if (progress && progress.data) {
            setIsUpdateShipment(progress && progress.data && progress.data.is_update_shipment);
            setIsCreateShipmentStatus(progress && progress.data && progress.data.is_update_shipment_on_create);
            setIsManifestCloseStatus(progress && progress.data && progress.data.is_update_shipment_on_close);
        }
    }, [progress && progress.data]);

    useEffect(() => {
        setStoreExistError("")
    }, [dispatch]);

    useEffect(() => {
        if (!authorizeCheck) {
            if (checkUrl && checkUrl.data && checkUrl.data.message) {
                setBtnDisabled(false);
                setStoreExistError("woocommerce store url verified successfully");
            }
            if (checkUrl && checkUrl.hasErrors) {
                setAuthorizeCheck(true)
                setBtnDisabled(true);
                setStoreExistError("Please provide valid woocommerce store url");
            }
        }
    }, [checkUrl]);

    const handleClose = () => {
        dispatch(resetConfigureState())
        setSnackOpen(false);
        if (storeParam && configure && configure.hasErrors == false) {
            window.location.href = window.location.origin + "/integration/marketplace"
        }
    };

    const inputChange = (e) => {
        let userId = profile.profile.id;
        let source = profile.profile.source;
        let storeUrlValue = e.target.value;
        if (storeUrlValue) {
            setAuthorizeCheck(false)
        }
        if (!(storeUrlValue.indexOf("http://") == 0 || storeUrlValue.indexOf("https://") == 0)) {
            storeUrlValue = "https://" + storeUrlValue;
        }
        if (!storeUrlValue.endsWith("/")) {
            storeUrlValue = storeUrlValue + "/";
        }
        setStoreUrl(storeUrlValue)
        let url = "";
        let encodeURI = process.env.REACT_APP_LABELGEN_URL + "/integration/marketplace?platform=woocommerce&store_url=" + storeUrlValue
        if (source === 'LABELGEN') {
            url = storeUrlValue + "/wc-auth/v1/authorize?app_name=shippingchimp&scope=read_write&user_id="
                + userId + "&return_url=" + encodeURIComponent(encodeURI) +
                "&callback_url=" + process.env.REACT_APP_INTEGRATIONS_URL + "/wc/callback?store_url=" + storeUrlValue;
        } else {
            url = storeUrlValue + "/wc-auth/v1/authorize?app_name=shippingchimp&scope=read_write&user_id="
                + userId + "&return_url=" + process.env.REACT_APP_OMSUI_URL + "/integrate/WOOCOMMERCE?store_url="
                + storeUrlValue + "&callback_url=" + process.env.REACT_APP_INTEGRATIONS_URL + "/wc/callback?store_url=" + storeUrlValue;
        }
        if (url) {
            setAuthorizeUrl(url)
            dispatch(fetchWoocommerceAppCheckUrl(url));
        }

        let success = false;
        if (integratedStore && integratedStore.data && integratedStore.data.length !== 0) {
            integratedStore.data.map((item) => {
                if (storeUrlValue === item.store_name && item.platform === "WOOCOMMERCE") {
                    setBtnDisabled(true)
                    setStoreExistError("This woocommerce store already integrated");
                    setAuthorizeCheck(true)
                    success = true;
                }
            })
        } else {
            setBtnDisabled(false)
        }
    }

    const updateCallBackValue = (updateValue) => {
        setIsUpdateShipment(updateValue.is_update_shipment);
        setIsCreateShipmentStatus(updateValue.is_update_shipment_on_create);
        setIsManifestCloseStatus(updateValue.is_update_shipment_on_close);
        setBtnConfigDisabled(false)
    };

    const configurestore = () => {
        let storeDetails = ""
        if (storeName) {
            storeDetails = storeName;
        } else {
            storeDetails = storeParam;
        }
        const data = {
            "store_name": storeDetails,
            'is_update_shipment': isUpdateShipment,
            'is_update_shipment_on_create': isCreateShipmentStatus,
            'is_update_shipment_on_close': isManifestCloseStatus
        }
        dispatch(configureStore(data))
    }

    return (
        <>
            {alert &&
                <Snackbar
                    open={snackOpen}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    autoHideDuration={6000}
                    onClose={handleClose}
                >
                    <Alert
                        variant="filled"
                        severity={configure && configure.hasErrors === true ? "error" : "success"}
                        style={{ justifyContent: 'center', margin: "10px 0" }}
                    >
                        <span > {configure && configure.message}</span>
                    </Alert>
                </Snackbar>
            }
            {!storeParam && !storeName &&
                <Grid container spacing={1}>
                    <Grid item xs={12} md={12} style={{ textAlign: 'center' }}>
                        <Item>
                            <div>
                                <img
                                    src="/assets/images/integration/woo.png"
                                    alt="BRIGHTPEARL"
                                    style={{ margin: 'auto', display: 'flex', maxWidth: '60px' }} />
                            </div>
                            <div
                                className="sc--form--center"
                                style={{ margin: 'auto', textAlign: 'center', width: '50%', marginBottom: '10px' }}>
                                <TextField
                                    id="standard-numbe"
                                    label="Store url"
                                    size="small"
                                    onInput={inputChange}
                                    style={{ width: "40%", margin: '8px' }}
                                    variant="outlined"
                                    error={btnDisabled && storeUrl !== '' && authorizeCheck}
                                    helperText={btnDisabled && storeUrl !== '' ? storeExistError : !btnDisabled && storeUrl !== '' ? storeExistError : ""}
                                />
                            </div>
                            <div
                                className="sc--form--center"
                                style={{ margin: 'auto', textAlign: 'center', width: '50%' }}>
                                <LoadingButton
                                    variant="contained"
                                    disabled={btnDisabled}
                                    href={authorizeUrl}
                                    loading={
                                        checkUrl && checkUrl.loading}
                                    target="_blank"
                                >Authorize <SendOutlinedIcon style={{ paddingLeft: '10px' }} />
                                </LoadingButton>
                            </div>
                        </Item>
                    </Grid>
                </Grid>

            }
            {(progress && progress.loading === false) && <>
                {(storeParam || platformName === 'woocommerce' || storeName) &&
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={12}>
                            <Item>
                                <div
                                    className="sc--form--center"
                                    style={{ margin: 'auto', textAlign: 'center', width: '50%' }}>
                                    <TextField
                                        id="standard-numbe"
                                        label="Store url"
                                        value={storeName ? storeName : storeParam}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        size="small"
                                        style={{ width: "60%", margin: '8px' }}
                                        variant="filled"
                                    />
                                </div>
                                <ShipmentUpdateFlag
                                    updateFlag={isUpdateShipment}
                                    onCreateFlag={isCreateShipmentStatus}
                                    onCloseFlag={isManifestCloseStatus}
                                    updateCallBack={updateCallBackValue}
                                    message={"Do you want to update shipment information back to woocommerce account?"}
                                />
                                <div
                                    className="sc--form--center"
                                    style={{ margin: '10px 0', textAlign: 'center' }}
                                >
                                    <LoadingButton
                                        variant="contained"
                                        onClick={configurestore}
                                        disabled={btnConfigDisabled}
                                        loading={configure && configure.loading}
                                    >Configure
                                    </LoadingButton>
                                </div>
                            </Item>
                        </Grid>
                    </Grid>
                }
            </>
            }
        </>
    )
}


export default WoocommerceIntegration;