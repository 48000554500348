import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import LinearProgress from "@mui/material/LinearProgress";

import {
  getPickupSettings,
  postPickupSettings,
  resetPickupSettingsState,
} from "./pickupSettingsSlice";

const PickupSettingsPage = () => {
  const dispatch = useDispatch();
  const [alert, setAlert] = useState(false);
  const [open, setOpen] = useState(false);
  const pickupsettings = useSelector((state) => state.pickupsettings);

  const initialValues = {
    pickup: false,
    dropoff: false,
  };

  const [values, setValues] = useState(initialValues);

  const onLabelChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: e.target.checked,
    });
  };

  const onSubmit = () => {
    let pickup_preferences = {};
    pickup_preferences = {
      pickup: values.pickup,
      dropoff: values.dropoff,
    };
    dispatch(postPickupSettings(pickup_preferences));
  };

  useEffect(() => {
    if (pickupsettings && pickupsettings.loading === false) {
     
      if (
        pickupsettings &&
        pickupsettings.data &&
        pickupsettings.data.pickup_settings
      ) {
       
        setValues({
          ...values,
          pickup:
            pickupsettings &&
            pickupsettings.data &&
            pickupsettings.data.pickup_settings &&
            pickupsettings.data.pickup_settings.pickup_preference &&
            pickupsettings.data.pickup_settings.pickup_preference.pickup,
          dropoff:
            pickupsettings &&
            pickupsettings.data &&
            pickupsettings.data.pickup_settings &&
            pickupsettings.data.pickup_settings.pickup_preference &&
            pickupsettings.data.pickup_settings.pickup_preference.dropoff,
        });
      }
    }
    if (pickupsettings && pickupsettings.message) {
      setAlert(true);
      setOpen(true);
    }
  }, [pickupsettings]);

  const handleClose = () => {
    setOpen(false);
    dispatch(resetPickupSettingsState());
  };

  useEffect(() => {
    dispatch(getPickupSettings());
  }, []);

  return (
    <div className="sc--settings--card">
      {pickupsettings && pickupsettings.loading === false && alert && (
        <Snackbar
          open={open}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            severity={pickupsettings.hasErrors == true ? "error" : "success"}
            style={{ justifyContent: "center", margin: "10px 0" }}
          >
            <span>{pickupsettings && pickupsettings.message}</span>
          </Alert>
        </Snackbar>
      )}
      <Card
        sx={{ minWidth: '100%', boxShadow: "unset" }}
        className="sc--cardhead--details"
      >
        <CardHeader title="Pickup Settings" className="sc--cardhead--details" />
        {pickupsettings && pickupsettings.loading === true && (
          <LinearProgress />
        )}
        <Divider />
        {pickupsettings && pickupsettings.loading === false && (
          <CardContent className="sc--label--settings">
            <div className="sc--form--center">
              <FormGroup className="sc--label--settings--label">
                <FormControlLabel
                  control={
                    <Switch
                      checked={values.pickup}
                      name="pickup"
                      onChange={onLabelChange}
                    />
                  }
                  label="Pick up"
                  labelPlacement="start"
                />
              </FormGroup>
            </div>
            <div className="sc--form--center">
              <FormGroup className="sc--label--settings--label">
                <FormControlLabel
                  control={
                    <Switch
                      checked={values.dropoff}
                      name="dropoff"
                      onChange={onLabelChange}
                    />
                  }
                  label="Drop off"
                  labelPlacement="start"
                />
              </FormGroup>
            </div>

            <div className="sc--form--center">
              <Button
                variant="outlined"
                onClick={onSubmit}
                style={{ margin: "15px 15px" }}
              >
                Save
              </Button>
            </div>
          </CardContent>
        )}
      </Card>
    </div>
  );
};

export default PickupSettingsPage;
