import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// mui
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Drawer from '@mui/material/Drawer';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import LinearProgress from '@mui/material/LinearProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';

// Our components
import Notification from './Notification'
import BulkOrderDrawer from './BulkOrderDrawer'

// slice
import { uploadBulkOrder, fetchBulkOrderDetail, resetBulkOrders } from '../../pages/BulkOrder/bulkOrderSlice';
const Input = styled('input')({
    display: 'none',
});
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    boxShadow: 'unset',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    color: theme.palette.text.secondary,
}));

const BulkOrderCreateDrawer = ({ visible, onClose }) => {
    const dispatch = useDispatch();

    // state
    const bulkorder = useSelector(state => state.bulkorder && state.bulkorder.uploadorder);
    const profile = useSelector(state => state.auth);
    const [drawer, setDrawer] = useState(true);
    const [fileUpload, setFileUpload] = useState("")
    const [fileName, setFileName] = useState("")
    const [bulkOrderDrawerVisible, setBulkOrderDrawerVisible] = useState(false);
    const [referenceId, setReferenceId] = useState("");
    const [buttonDisabled, setbuttonDisabled] = useState(true)
    const [uploadType, setUploadType] = useState("SHIPMENT")
    const [notes, setNotes] = useState("")

    const bulkOrderCallback = (item) => {
        // setReferenceId(item)
        dispatch(fetchBulkOrderDetail(item));
        setBulkOrderDrawerVisible(true)
    }
    const bulkOrderOnClose = (values) => {
        setBulkOrderDrawerVisible(false);
    };
    const onFileUpload = (e) => {
        e.preventDefault()
        if (e.target.files && e.target.files.length === 0) {
            setFileName(fileUpload.name)
            setFileUpload(fileUpload);
        } else {
            setFileUpload(e.target.files[0]);
            setFileName(e.target.files[0].name)
        }
        setbuttonDisabled(false)
    }

    const onSubmit = () => {
        let apikey = (profile.profile.api_token) ? profile.profile.api_token : "";
        var formData = new FormData();
        formData.append('file', fileUpload);
        dispatch(uploadBulkOrder(formData, apikey, uploadType,notes))
        setFileName("")
        setFileUpload("")
        setbuttonDisabled(true)
    }

    const ondrawerclose = () => {
        dispatch(resetBulkOrders())
        setFileName("")
        setReferenceId("")
        setbuttonDisabled(true)
        setDrawer(false)
        onClose(false)
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        if(e.target.value === "ORDER"){
            setUploadType("ORDER")
        }else{
            setUploadType("SHIPMENT")
        }
    };

    useEffect(() => {
        if ((bulkorder && bulkorder.notification && bulkorder.notification.show && bulkorder.notification.messageType === 'success')) {
            setReferenceId(bulkorder && bulkorder.order && bulkorder.order.reference_id)
            setFileName("")
            setbuttonDisabled(true)
        }
    }, [bulkorder])

    return (
        <>
            <Drawer
                anchor="right"
                open={visible}
                onClose={ondrawerclose}
                PaperProps={{
                    sx: { width: "60%" },
                }}
            >
                {(bulkorder && bulkorder.notification && bulkorder.notification.show && bulkorder.notification.messageType === 'success') &&
                    <Notification
                        is_open={bulkorder.notification.show}
                        messageType={bulkorder.notification.messageType}
                        messages={bulkorder.notification.messageContent}
                        handleClose={() => dispatch(resetBulkOrders())}
                    />}
                {(bulkorder && bulkorder.notification && bulkorder.notification.show && bulkorder.notification.messageType === 'error') &&
                    <Notification
                        is_open={bulkorder.notification.show}
                        messageType={bulkorder.notification.messageType}
                        messages={bulkorder.notification.messageContent}
                        handleClose={() => dispatch(resetBulkOrders())}
                    />}
                <div style={{ padding: "0 30px" }}>
                    <Card sx={{ margin: '20px 0' }} className="sc--cardhead--details">
                        <CardHeader title="Import bulk order" action={
                            <div>
                                <IconButton aria-label="settings" onClick={ondrawerclose}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        } />
                        {bulkorder && bulkorder.loading === true &&
                            <LinearProgress />
                        }
                        <Divider />
                            <div className="sc--form--center" style={{ margin: '15px 0', paddingTop: '25px', textAlign: 'center', }}>
                                {referenceId &&
                                    <div>
                                        <p>Check your Reference id: <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={e => { bulkOrderCallback(referenceId) }}>{referenceId}</span></p>
                                    </div>
                                }

                                <label htmlFor="contained-button-file">
                                    {fileName=='' && <Input accept=".csv" id="contained-button-file" onChange={onFileUpload} key={fileName} multiple type="file" />}
                                    <Button variant="contained" component="span" disabled={fileName=='' ? false :true} style={{ margin: '0 10px' }}>
                                        Upload
                                        </Button>
                                    <a href="https://shippingchimp.s3.us-east-2.amazonaws.com/labelgen/bulk_create_shipment/Bulk_Create_Shipment_Sample.csv">Sample orders csv</a>
                                    {fileName && <><br /><span style={{ fontSize: '10px' }}>Filename:{fileName}</span></>}
                                </label>
                            </div>
                            <div className="sc--form--center"  style={{ margin: '15px 0',  textAlign: 'center',position:'relative',left:'-3%' }}>
                                <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={uploadType}
                                    onChange={handleChange}
                                >
                                    <FormControlLabel value="ORDER"  control={<Radio size="small" />} label={<Typography style={{ fontSize: '12px' }}>ONLY ORDER</Typography>} />
                                    <FormControlLabel value="SHIPMENT" control={<Radio size="small" />} label={<Typography style={{ fontSize: '12px' }}>ORDER + SHIPMENT</Typography>} />
                                </RadioGroup>
                            </div>
                            <div className="sc--form--center"  style={{ margin: '10px 0',  textAlign: 'center',position:'relative',left:'-3%' }}>
                                <TextField
                                        label="Notes"
                                        name="notes"
                                        inputProps={{ maxLength: 200 }}
                                        onInput={e => setNotes(e.target.value)}
                                        variant="standard" 
                                />
                            </div>
                            <div className="sc--form--center" style={{ margin: '15px 0', textAlign: 'center',position:'relative',left:'-7%' }}>
                                <Button variant="outlined" onClick={onSubmit} style={{ margin: '15px 15px' }} disabled={buttonDisabled}>
                                    Submit
                                    </Button>
                            </div>
                    </Card>
                </div>

            </Drawer >
            <BulkOrderDrawer
                visible={bulkOrderDrawerVisible} referenceId={referenceId} bulkDraweronClose={bulkOrderOnClose}
            />
        </>
    )
}


export default BulkOrderCreateDrawer;