import { createSlice } from '@reduxjs/toolkit';
import { OMSAPI } from '../../../../../API';

export const webhookslice = createSlice({
    name: 'webhook',
    initialState: {
        webhook: {},
        loading: false,
        meta: [],
        hasErrors: false,
        notification: { showMessage: false, messageContent: "", messageType: "success" }
    },
    reducers: {
        getWebhookDetail: (state) => {
            return {
                ...state,
                loading: true
            }
        },
        getWebhookDetailSuccess: (state, action) => {
            return {
                ...state,
                webhook: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data,
                hasErrors: false,
                loading: false
            }
        },
        getWebhookDetailFailure: (state, action) => {
            return {
                ...state,
                webhook: action.webhookData,
                loading: false,
                hasErrors: true
            }
        },
        postWebhook: (state) => {
            return {
                ...state,
                loading: true,
                notification: { showMessage: false, messageContent: "", messageType: "success" }
            }
        },
        postWebhookSuccess: (state, action) => {
            return {
                ...state,
                webhook: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data,
                loading: false,
                notification: { showMessage: true, messageContent: [action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.message], messageType: "success" }
            }
        },
        postWebhookFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                hasErrors: true,
                notification: { showMessage: true, messageContent: [action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.message], messageType: "error" }
            }
        },
        deleteWebhook: (state) => {
            return {
                ...state,
                loading: true,
                notification: { showMessage: false, messageContent: "", messageType: "success" }
            }
        },
        deleteWebhookSuccess: (state, action) => {
            return {
                ...state,
                loading: false,
                notification: { showMessage: true, messageContent: [action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.message], messageType: "success" }
            }
        },
        deleteWebhookFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                notification: { showMessage: true, messageContent: [action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.message], messageType: "error" }
            };
        },

        resetConfigureStateDetails: (state, action) => {
            return {
                ...state,
                loading: false,
                notification: { showMessage: false, messageContent: "", messageType: "success" }
            }
        },

    }
})
export const { getWebhookDetail, getWebhookDetailSuccess, getWebhookDetailFailure, postWebhook, postWebhookSuccess, postWebhookFailure
    , deleteWebhook, deleteWebhookSuccess, deleteWebhookFailure, resetConfigureStateDetails } = webhookslice.actions;

export const fetchWebhookDetail = () => {
    return dispatch => {
        dispatch(getWebhookDetail());
        try {
            OMSAPI
                .get('/webhook')
                .then(function (response) {
                    dispatch(getWebhookDetailSuccess(response));
                })
                .catch(function (error) {
                    dispatch(getWebhookDetailFailure());
                });
        } catch (error) {
            dispatch(getWebhookDetailFailure());
        }
    };
};

export const storeWebhook = (data) => {

    return (dispatch) => {

        dispatch(postWebhook());

        try {
            OMSAPI.post('/webhook/1', data)
                .then(function (response) {
                    dispatch(postWebhookSuccess(response));
                    dispatch(fetchWebhookDetail());
                }).catch(function (error) {
                    dispatch(postWebhookFailure(error.response));
                })
        }
        catch (error) {
            dispatch(postWebhookFailure(error.response));
        }

    }

}
export const destoryWebhook = (id) => {
    return (dispatch) => {
        dispatch(deleteWebhook());

        try {
            OMSAPI
                .delete("/webhook/" + id)
                .then(function (response) {
                    dispatch(deleteWebhookSuccess(response));
                    dispatch(fetchWebhookDetail());
                })
                .catch(function (error) {
                    dispatch(deleteWebhookFailure(error.response));
                });
        } catch (error) {
            dispatch(deleteWebhookFailure(error));
        }
    };
};
export const resetNotification = data => dispatch => {
    dispatch(resetConfigureStateDetails());
}
export default webhookslice.reducer;
