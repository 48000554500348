import { v4 as uuidv4 } from 'uuid';
export const data = [
  {
    id: '1',
    Task: 'Football',
    // Assigned_To: 'Beltran',
    // Assignee: 'Romona',
    // Status: 'To-do',
    // Priority: 'Low',
    // Due_Date: '25-May-2020',
  },
  {
    id: '2',
    Task: 'Shirt',
    // Assigned_To: 'Dave',
    // Assignee: 'Romona',
    // Status: 'To-do',
    // Priority: 'Low',
    // Due_Date: '26-May-2020',
  },
  {
    id: '3',
    Task: 'Jewellery',
    // Assigned_To: 'Roman',
    // Assignee: 'Romona',
    // Status: 'To-do',
    // Priority: 'Low',
    // Due_Date: '27-May-2020',
  },
];

export const columnsFromBackend = {
  [uuidv4()]: {
    title: 'YET to be packed',
    items: data,
  },
  [uuidv4()]: {
    title: 'Packed',
    items: [],
  },
 
};
