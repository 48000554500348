import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { useForm } from '../../../pages/General/useForm';
import FormHelperText from '@mui/material/FormHelperText';
import Autocomplete from '@mui/material/Autocomplete';
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { fetchCountry, fetchState, fetchMultiStates } from '../../../pages/General/countryStateCitySlice';
import { fetchAddressBookDetails } from './addressbookSlice';

const RecipientAddressSelect = ({ errors, values, dispatchInputChangeCallback,dispatchCheckboxChangeCallback, addresssender, isReturnOrder, dispatchCountryCallback, newOrderFormData, setValues }) => {
    const dispatch = useDispatch();
    const country_code = useSelector(state => state.countrystate.country);
    const state = useSelector(state => state.countrystate.state);
    const addressbooksearchvalues = useSelector((state) => state.addressbook && state.addressbook.getsearchvalues);
    const filteredCountryData = country_code.data.filter(({ code }) => code );
    const [isRecipientSearchVisible, setIsRecipientSearchVisible] = useState(false)
    const [addressSearchBy, setAddressSearchBy] = useState("name");
    const [addressSearchByValue, setAddressSearchByValue] = useState("");

    const handleInputChange = e => {
        const { name, value } = e.target
        dispatchInputChangeCallback(name, value)
    }
    const onSignatureChange = e => {
        const { name, value } = e.target
        dispatchCheckboxChangeCallback(name, e.target.checked)
    }
    const onChangeCountry = id => {
        dispatchCountryCallback(id)
    }

    const getSelectedCountry = (country_code) => {
        let filteredValue = { code: "", name: "" };
        filteredCountryData.map((key) => {
          if (key.code == country_code) {
            filteredValue = key;
          }
        });
         return filteredValue;
      };

      const onSelectStateChange = (currentstate) => {
        dispatchInputChangeCallback("province", currentstate)
      };
    
      const getSelectedState = (currentstate) => {
        let filteredStateValue = { code: "", name: "" };
        let tempState = "";
        state &&
          state.data &&
          state.data.map((key) => {
            if (key.code == currentstate && tempState == "") {
              filteredStateValue = key;
              tempState = "success";
            }
          });
        return filteredStateValue;
      };

    const recipientclick = (value) => {
        if (value == 'no') {
            setIsRecipientSearchVisible(false)
        } else {
            setIsRecipientSearchVisible(true)
        }
    }


    useEffect(() => {
        dispatch(fetchCountry());
    }, [dispatch]);


    useEffect(() => {
        if ((newOrderFormData && newOrderFormData.shipping_address && newOrderFormData.shipping_address.country_code)) {
            dispatch(fetchState(newOrderFormData && newOrderFormData.shipping_address
                && newOrderFormData.shipping_address.country_code));
        }
    }, [newOrderFormData]);

    
    useEffect(() => {
        if(isReturnOrder==undefined || isReturnOrder==false){
            dispatch(fetchAddressBookDetails(addressSearchBy, ""))
        }
    }, [addressSearchBy])

    useEffect(() => {
        if(isReturnOrder==undefined || isReturnOrder==false){
            dispatch(fetchAddressBookDetails(addressSearchBy, addressSearchByValue))
        }
    }, [addressSearchByValue])

    const [labelgetvalues, setlabelgetvalues] = useState([]);


    const onSearchLabelChange = (event, value) => {
        if (value != null && value != "") {
            setValues({
                ...values,
                name: value.name ? value.name : "",
                company: value.company ? value.company : "",
                email: value.email ? value.email : "",
                address: value.address ? value.address : "",
                address2: value.address2 ? value.address2 : "",
                city: value.city ? value.city : "",
                postal_code: value.postal_code ? value.postal_code : "",
                country_code: value.country_code ? value.country_code.toUpperCase() : "",
                province: value.province_code ? value.province_code.toUpperCase() : ""
            });
            dispatch(fetchState(value.country_code));
            
        }
    }

    useEffect(() => {
        if (addressbooksearchvalues && addressbooksearchvalues.data) {
            let searchvaluearray = []
            Object.entries(addressbooksearchvalues.data).map((entry) => {
                entry.map((item) => {
                    if (typeof item == "object") {
                        searchvaluearray.push(item)
                    }
                });
            });
            setlabelgetvalues(searchvaluearray)
        }
    }, [addressbooksearchvalues.data]);


    useEffect(() => {
        if (addresssender && addresssender.data && isReturnOrder) {
            addresssender.data.map((value) => {
                if (value && value.default) {
                    setValues({
                        ...values,
                        name: value.sender_name ? value.sender_name.trim() : "",
                        company: value.sender_company ? value.sender_company.trim() : "",
                        email: value.sender_email ? value.sender_email : "",
                        phone:value.sender_phone ? value.sender_phone : "",
                        address: value.sender_address ? value.sender_address : "",
                        address2: value.sender_address2 ? value.sender_address2 : "",
                        city: value.sender_city ? value.sender_city : "",
                        postal_code: value.sender_postal_code ? value.sender_postal_code : "",
                        country_code: value.sender_country_code ? value.sender_country_code.toUpperCase() : "",
                        province: value.sender_state_code ? value.sender_state_code.toUpperCase() : ""
                    })
                    dispatch(fetchState(value.sender_country_code));
                }
            })
        }
    }, [addresssender && isReturnOrder]);
    return (
        <>
            <Grid newOrderFormData xs={6} md={6} style={{ width: '50%', padding: '10px' }}>
                <Card sx={{
                    minWidth: 275,
                    boxShadow: 'unset', border: '1px solid rgba(0, 0, 0, 0.12)',
                    height: '100%',
                    marginTop: '20px',
                }}
                    className="sc--cardhead--details">
                    <CardHeader title='Recipient' />
                    <Divider />
                    <CardContent>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            {!isReturnOrder && <>
                            <div className="sc--form--center" style={{ margin: '10px 0' }}>
                                <FormControl
                                    variant="standard"
                                    className="help-text-error"
                                    style={{ width: '20%', margin: '8px' }}
                                >
                                    <InputLabel
                                        style={{ color: errors.dimension_unit ? "#D32F2F" : '' }}
                                    > Search by
                                </InputLabel>
                                    <Select
                                        name="search_by"
                                        onChange={(event) => { setAddressSearchBy(event.target.value) }}
                                        value={addressSearchBy}
                                        label="Search by"
                                        id="filled-error-helper-text"
                                    >
                                        <MenuItem value="name">Name</MenuItem>
                                        <MenuItem value="company">Company</MenuItem>
                                        <MenuItem value="address">Address</MenuItem>
                                        <MenuItem value="address2">Addres2</MenuItem>
                                        <MenuItem value="city">City</MenuItem>
                                        <MenuItem value="province_code">Province code</MenuItem>
                                        <MenuItem value="country_code">Country code</MenuItem>
                                        <MenuItem value="postal_code">Postal code</MenuItem>
                                        <MenuItem value="email">Email</MenuItem>
                                        <MenuItem value="phone">Phone</MenuItem>
                                        <MenuItem value="customer_id">Customer id</MenuItem>
                                        <MenuItem value="reference">Reference</MenuItem>
                                        <MenuItem value="reference2">Reference2</MenuItem>
                                        <MenuItem value="reference3">Reference3</MenuItem>
                                    </Select>
                                </FormControl>
                                <Autocomplete
                                    disablePortal
                                    name="search_value"
                                    id="combo-box-demo"
                                    noOptionsText={"No matches found"}
                                    loading={addressbooksearchvalues && addressbooksearchvalues.loading}
                                    options={labelgetvalues}
                                    filterOptions={(options) => options}
                                    renderOption={(props, option) => (
                                        <MenuItem {...props} value={option._id} key={option._id}>
                                        <p style={{ margin: 'unset' }} key={option._id}>{option.name} ({option.company})<br />
                                            {option.address}, {option.city}, {option.province_code}, {option.country_code} - {option.postal_code}
                                        </p>
                                    </MenuItem>
                                    )}
                                    onInputChange={(event, value, reason) => {
                                            if ( ! value.includes(",") && reason != "reset") {
                                                setAddressSearchByValue(value)
                                            }
                                        }
                                    }
                                    onChange={(event, value) => onSearchLabelChange(event, value)}
                                    style={{ width: 480, margin: '8px' }}
                                    getOptionLabel={(option) => ( ""+option.name+" ("+option.company+")"+", "+ option.address ) }
                                    renderInput={(params) => <TextField 
                                        {...params} 
                                        label="Search" 
                                        style={{ width: '95%' }} 
                                        variant="standard"
                                         />}
                                />
                            </div>
                            </>
                            }
                            <div className="sc--form--center">
                                <TextField
                                    variant="standard"
                                    label="Name"
                                    name="name"
                                    value={values.name}
                                    multiline
                                    onChange={handleInputChange}
                                    error={errors.name}
                                    helperText={errors.name}
                                    style={{ width: "100%" }}
                                />
                                <TextField id="standard-basic"
                                    label="Company"
                                    variant="standard"
                                    name="company"
                                    value={values.company}
                                    onChange={handleInputChange}
                                    error={errors.company}
                                    helperText={errors.company}
                                    style={{ width: "100%" }} />

                            </div>
                            <div className="sc--form--center">
                                <TextField id="standard-basic"
                                    label="Phone Number"
                                    name="phone"
                                    value={values.phone}
                                    variant="standard"
                                    onChange={handleInputChange}
                                    style={{ width: "100%" }}
                                />
                                <TextField id="standard-basic"
                                    label="Email"
                                    variant="standard"
                                    name="email"
                                    value={values.email}
                                    onChange={handleInputChange}
                                    error={errors.email}
                                    helperText={errors.email}
                                    style={{ width: "100%" }} />
                            </div>
                            <div className="sc--form--center">
                                <TextField id="standard-basic"
                                    label="Address1"
                                    variant="standard"
                                    name="address"
                                    value={values.address}
                                    inputProps={{ maxLength: 35 }}
                                    onChange={handleInputChange}
                                    error={errors.address}
                                    helperText={errors.address}
                                    style={{ width: "100%" }} />

                                <TextField id="standard-basic"
                                    label="Address2"
                                    variant="standard"
                                    name="address2"
                                    value={values.address2}
                                    inputProps={{ maxLength: 35 }}
                                    onChange={handleInputChange}
                                    error={errors.address2}
                                    helperText={errors.address2}
                                    style={{ width: "100%" }} />
                            </div>
                            <div className="sc--form--center">
                                <TextField id="standard-basic"
                                    label="City"
                                    variant="standard"
                                    name="city"
                                    value={values.city}
                                    onChange={handleInputChange}
                                    error={errors.city}
                                    helperText={errors.city}
                                    style={{ width: "100%" }} />
                                <TextField id="standard-basic"
                                    label="Postal Code"
                                    variant="standard"
                                    name="postal_code"
                                    inputProps={{ maxLength: 10 }}
                                    value={values.postal_code.toUpperCase()}
                                    onChange={handleInputChange}
                                    error={errors.postal_code}
                                    helperText={errors.postal_code}
                                    style={{ width: "100%" }} />
                            </div>
                            <div className="sc--form--center">
                            <Autocomplete
                                className="help-text-error"
                                disablePortal
                                name="country_code"
                                id="country-select-demo"
                                sx={{ width: "100%"}}
                                options={filteredCountryData}
                                autoHighlight
                                loading={country_code && country_code.loading}
                                getOptionLabel={(option) => option.name}
                                value={getSelectedCountry(values && values.country_code)}
                                renderOption={(props, option) => (
                                <MenuItem {...props} value={option.code} key={option.code}>
                                    {option.icon} {option.name}
                                </MenuItem>
                                )}
                                onChange={(event, value) => {
                                if (value !== null) {
                                    onChangeCountry(value.code);
                                } else {
                                    onChangeCountry("");
                                }
                                }}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="country"
                                    variant="standard"
                                    helperText={errors.country_code}
                                />
                                )}
                            />
                            <Autocomplete
                                className="help-text-error"
                                disablePortal
                                name="province"
                                id="state-select-demo"
                                sx={{ width: "100%",margin:'8px'}}
                                options={state && state.data}
                                autoHighlight
                                loading={
                                (state && state.loading) || (country_code && country_code.loading)
                                }
                                getOptionLabel={(option) => option.name}
                                value={getSelectedState(values && values.province)}
                                renderOption={(props, option) => (
                                <MenuItem {...props} value={option.code} key={option.code}>
                                    {option.name}
                                </MenuItem>
                                )}
                                onChange={(event, value) => {
                                    if (value !== null) {
                                      onSelectStateChange(value.code);
                                    } else {
                                      onSelectStateChange("");
                                    }
                                  }}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="state"
                                    variant="standard"
                                    helperText={errors.province}
                                />
                                )}
                            />
                            </div>
                            <div className="sc--form--center" style={{justifyContent:'unset'}}>
                                <FormGroup className="trial__page__checkbox" style={{paddingLeft:'10px'}}>
                                    <FormControlLabel
                                        control={<Checkbox checked={values.is_signature_required} name="is_signature_required" onChange={onSignatureChange}/>}
                                        label="Signature Required"
                                    />
                                </FormGroup>
                            </div>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
        </>
    )
};

export default RecipientAddressSelect;