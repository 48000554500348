import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';

// Our components
import Carrier from '../General/Carrier'
import OrderStatus from '../General/OrderStatus'
import Customer from '../../pages/General/Customer'
import NorowsData from '../../pages/General/NorowsData'
import Date from '../../pages/General/Date'

// mui components
import {
    Typography,
    Card,
    CardContent,
    Stack
} from "@mui/material";
import {
    DataGrid,
    GridToolbarContainer
} from '@mui/x-data-grid';


import { fetchOrderDetail } from './orderdetailsSlice';
const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    border: "1px solid rgba(224, 224, 224, 1)",
    boxShadow: "unset"

}));

const OrderDetailsPage = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const { orderId, storeId } = useParams();
    const orderDetailData = useSelector(state => state.orderdetails.data);
    const orderDetails = useSelector(state => state.orderdetails);
    const columns = [{
        field: 'order.id',
        headerName: 'Order',
        headerClassName: 'super-app-theme--header',
        width: 150,
        resizable: false, 
        renderCell: (params) => {
            return <Link className="sc--link--style" to={"/orders/" + params.row.order.id}>{params.row.order.id}</Link>
        }
    },
    {
        field: 'order.status',
        headerName: 'Status',
        width: 200,
        resizable: false, 
        renderCell: (params) => {
            return (
                <OrderStatus orderStatus={params.row.order.status}
                />
            )
        }
    },
    {
        field: 'order.date',
        headerName: 'Date',
        width: 200,
        resizable: false, 
        renderCell: (params) => {
            return <Date date={params.row.order.date }/>
        }
    }
    ]

    useEffect(() => {
        dispatch(fetchOrderDetail(orderId, storeId))
    }, [orderId, dispatch])

    return (
        <>

            <div className="scmaincard">
                <div className="sccardhead">
                    <Typography variant="h5" gutterBottom component="div">
                        Order Details
                    </Typography>
                    <Button style={{ marginBottom: '15px' }} variant="outlined" onClick={() => { history.goBack() }}><ArrowBackIosIcon /> Back</Button>
                </div>
                {orderDetails && orderDetails.loading === true &&
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={true}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                }
                {orderDetails && orderDetails.loading === false &&
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={6} md={9}>
                                <Card style={{ boxShadow: 'unset', border: "1px solid rgba(224, 224, 224, 1)" }}>
                                    <>
                                        <p style={{ padding: '0 30px' }}>
                                            <span>{orderDetailData.order_details && "Ordered On " + moment(orderDetailData.order_details.order_date, "YYYY-MM-DD").format('DD MMMM YYYY') + " | "}</span>
                                            <span>Order <b>#{orderId}</b></span>
                                            <span>{orderDetailData && orderDetailData.store && orderDetailData.store.name && <> - Purchased from {orderDetailData.store.name}</>}</span>
                                        </p>
                                        <CardContent style={{ padding: '0 30px' }}>
                                            <Stack direction="row" spacing={30} style={{ marginTop: "10px", marginBottom: "10px" }}>
                                                <div className="sc--order--details">
                                                    <Typography variant="h6" gutterBottom component="div" style={{ fontSize: '16px' }}>
                                                        Customer
                                        </Typography>
                                                    {(orderDetailData.customer_details && orderDetailData.customer_details.id) && <p>#{orderDetailData.customer_details.id}</p>}
                                                    {(orderDetailData.customer_details && orderDetailData.customer_details.first_name) && <p>{orderDetailData.customer_details.first_name} {orderDetailData.customer_details.last_name}</p>}
                                                    {(orderDetailData.customer_details && orderDetailData.customer_details.email) && <p>{orderDetailData.customer_details.email}</p>}
                                                    {(orderDetailData.customer_details && orderDetailData.customer_details.phone) && <p>{orderDetailData.customer_details.phone}</p>}
                                                </div>
                                                <div className="sc--order--details">
                                                    <Typography variant="h6" gutterBottom component="div" style={{ fontSize: '16px' }}>
                                                        Delivery Address
                                        </Typography>
                                                    {orderDetailData.shipping_address && <>
                                                        {
                                                            Object.keys(orderDetailData.shipping_address).map(function (key) {
                                                                if (orderDetailData.shipping_address[key] !== "" && orderDetailData.shipping_address[key] !== null) {
                                                                    return <p key={key}>{orderDetailData.shipping_address[key]}</p>
                                                                } else {
                                                                    return "";
                                                                }
                                                            })
                                                        }
                                                    </>
                                                    }
                                                </div>
                                                <div className="sc--order--details">
                                                    <Typography variant="h6" gutterBottom component="div" style={{ fontSize: '16px' }}>
                                                        Order Summary
                                        </Typography>
                                                    <p>{orderDetailData && orderDetailData.store && orderDetailData.store.name && <><b>Store</b>: {orderDetailData.store.name}</>}</p>
                                                    <p><b>No of Items:</b> {orderDetailData.product_details && Object.keys(orderDetailData.product_details).length}</p>
                                                    <p><b>Total :</b> {(orderDetailData.order_details && orderDetailData.order_details.total !== "") && parseFloat(orderDetailData.order_details.total).toFixed(2)}</p>
                                                </div>
                                            </Stack>
                                        </CardContent>
                                    </>
                                </Card>

                                {orderDetailData.custom_order_fields && orderDetailData.custom_order_fields.length != 0 &&
                                    <>
                                        <Card sx={{ marginTop: '25px' }}>
                                            <CardContent>
                                                <div className="sp-plan-name">
                                                    <h6 className="title">Custom Order Fields</h6>
                                                </div>

                                                <div className="sc--order-details--cp sc--order--details--list">
                                                    <Grid container spacing={1}>
                                                        {orderDetailData.custom_order_fields && orderDetailData.custom_order_fields.map((item, index) => {
                                                            return (
                                                                Object.keys(item).map((i) => {
                                                                    return (
                                                                        <Grid item xs={4} md={3}>
                                                                            <List style={{ display: 'inline-flex', flexDirection: 'row', padding: 0 }}>
                                                                                <ListItem>
                                                                                    <p className="text-soft"><span>{i}</span><br />
                                                                                        <span style={{ color: "black" }}> {item[i]}</span></p>
                                                                                </ListItem>
                                                                            </List>
                                                                        </Grid>
                                                                    )
                                                                })
                                                            )
                                                        })
                                                        }
                                                    </Grid>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    </>
                                }
                                {orderDetailData && orderDetailData.product_details && Object.keys(orderDetailData.product_details).map(function (key) {
                                    return (
                                        <Card style={{ boxShadow: 'unset', border: "1px solid rgba(224, 224, 224, 1)", marginTop: '15px', padding: '15px 30px' }}>
                                            <h6 className="title" style={{ margin: '10px 0', fontSize: '14px' }}><span className="sc_anchor_button">{orderDetailData.product_details[key].product_desc}
                                            </span>
                                                <Chip className={"sc--orders--details--chip " + orderDetailData.product_details[key].tracking_data.status_color} label={orderDetailData.product_details[key].tracking_data.status && orderDetailData.product_details[key].tracking_data.status} />
                                            </h6>
                                            <p style={{ fontSize: '14px' }}>Product ID: <span className="text-base">{orderDetailData.product_details[key].product_id}</span></p>
                                            <CardContent className="sc--order--details--list">

                                                <List style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                                                    <ListItem><p><span className="text-soft">Shipped On </span> {orderDetailData.product_details[key].tracking_data.ship_date && moment(orderDetailData.product_details[key].tracking_data.ship_date, "YYYY-MM-DD").format('MMM DD, YYYY')}</p>
                                                    </ListItem>
                                                    <ListItem>    <p><span className="text-soft">Tracking Number </span> <br /> {orderDetailData.product_details[key].tracking_number && orderDetailData.product_details[key].tracking_number}</p>
                                                    </ListItem>
                                                    <ListItem>    <p><span className="text-soft">Carrier </span> <br /> <Carrier carrierName={orderDetailData.product_details[key].fulfilment_company && orderDetailData.product_details[key].fulfilment_company} width={"90px"} /> </p>
                                                    </ListItem>
                                                    <ListItem>    <p><span className="text-soft">Price </span> <br /> {(orderDetailData.product_details[key].product_price && orderDetailData.product_details[key].product_price !== "") && parseFloat(orderDetailData.product_details[key].product_price).toFixed(2) + " " + orderDetailData.product_details[key].currency}</p>
                                                    </ListItem>
                                                    <ListItem>    <p><span className="text-soft">Returnable </span> <br />{(typeof orderDetailData.product_details[key].return_eligibility !== "undefined") && <>{(orderDetailData.product_details[key].return_eligibility.eligible === true) ? "Yes" : "No"} </>}</p>
                                                    </ListItem>

                                                </List>
                                                <List style={{ display: 'inline-flex', flexDirection: 'row', padding: 0 }}>
                                                    <ListItem>    <p><span className="text-soft">Weight </span> <br /> {orderDetailData.product_details[key].actual_weight && orderDetailData.product_details[key].actual_weight}
                                                        {orderDetailData.product_details[key].weight_unit && orderDetailData.product_details[key].weight_unit}</p>
                                                    </ListItem>
                                                    <ListItem style={{ width: '372px' }}>    <p><span className="text-soft">Dimension </span> <br /> {orderDetailData.product_details[key].dimensions && <>
                                                        <>{orderDetailData.product_details[key].dimensions.length && orderDetailData.product_details[key].dimensions.length}</>
                                                        <>{orderDetailData.product_details[key].dimensions.length_unit && orderDetailData.product_details[key].dimensions.length_unit} </>
                                                        <>{" * "}</>
                                                        <>{orderDetailData.product_details[key].dimensions.width && orderDetailData.product_details[key].dimensions.width}</>
                                                        <>{orderDetailData.product_details[key].dimensions.width_unit && orderDetailData.product_details[key].dimensions.width_unit}</>
                                                        <>{" * "}</>
                                                        <>{orderDetailData.product_details[key].dimensions.height && orderDetailData.product_details[key].dimensions.height}</>
                                                        <>{orderDetailData.product_details[key].dimensions.height_unit && orderDetailData.product_details[key].dimensions.height_unit}</>
                                                    </>}</p>
                                                    </ListItem>
                                                </List>
                                            </CardContent>
                                        </Card>
                                    )
                                })

                                }
                                {orderDetailData &&
                                    orderDetailData.product_details &&
                                    orderDetailData.product_details.length != 0 &&
                                    orderDetailData.product_details[0] &&
                                    orderDetailData.product_details[0].custom_fields &&
                                    orderDetailData.product_details[0].custom_fields.length != 0 &&
                                    <>
                                        <Card style={{ boxShadow: 'unset', marginTop: '15px', border: "1px solid rgba(224, 224, 224, 1)" }}>
                                            <CardContent style={{ padding: "15px 30px" }}>

                                                <div className="sp-plan-name">
                                                    <p style={{ margin: 'unset' }}>Custom Product Fields</p>
                                                </div>
                                                <div className="sc--order-details--cp sc--order--details--list">
                                                    <Grid container spacing={1}>
                                                        {orderDetailData.product_details && orderDetailData.product_details.map((item) => {
                                                            return (
                                                                item.custom_product_fields && item.custom_product_fields.map((obj) => {
                                                                    return (
                                                                        Object.keys(obj).map((d) => {
                                                                            return (
                                                                                <Grid item xs={4} md={3}>
                                                                                    <List style={{ display: 'inline-flex', flexDirection: 'row', padding: 0 }}>
                                                                                        <ListItem>
                                                                                            <p className="text-soft"><span>{d}</span><br />
                                                                                                <span style={{ color: "black" }}> {obj[d]}</span></p>
                                                                                        </ListItem>
                                                                                    </List>
                                                                                </Grid>
                                                                            )
                                                                        })
                                                                    )
                                                                })
                                                            )
                                                        })
                                                        }
                                                    </Grid>
                                                </div>

                                            </CardContent>
                                        </Card>
                                    </>
                                }
                            </Grid>

                            <Grid item xs={6} md={3}>
                                <Card style={{ boxShadow: 'unset', border: "1px solid rgba(224, 224, 224, 1)", height: '100%' }}>
                                    <CardContent style={{ padding: 'unset' }}>
                                        <Card style={{ boxShadow: 'unset' }}>
                                            <CardContent>
                                                <div className="card card-bordered sp-plan">
                                                    {orderDetailData.customer_details && <>
                                                        <div className="sc--order--details--list">
                                                            <div className="user-card user-card-s2 mb-2">
                                                                <Customer name={orderDetailData.customer_details && orderDetailData.customer_details.first_name + " " + orderDetailData.customer_details.last_name} />
                                                                <div className="user-info">
                                                                    <p style={{ textAlign: 'center' }}>Customer</p>
                                                                </div>
                                                            </div>
                                                            <List style={{ display: 'flex', flexDirection: 'row', padding: 0, position: 'relative', left: '20px' }}>
                                                                <ListItem><p style={{ textAlign: 'center' }}>{orderDetailData.order_details && orderDetailData.order_details.ordersCount} <br /><span className="text-soft">Orders </span></p>
                                                                </ListItem>
                                                                <ListItem><p style={{ textAlign: 'center' }}>{orderDetailData.order_details && orderDetailData.order_details.disputesCount}<br /> <span className="text-soft">Disputes </span> </p>
                                                                </ListItem>
                                                                <ListItem><p style={{ textAlign: 'center' }}>{orderDetailData.order_details && orderDetailData.order_details.deliveredCount}<br /> <span className="text-soft">Delivered </span> <br /></p>
                                                                </ListItem>
                                                            </List>
                                                        </div>
                                                        <Divider />
                                                        <p> User Information</p>
                                                        <div className="sc--order--details--list">
                                                            <List>
                                                                <ListItem disablePadding>
                                                                    <ListItemButton style={{ padding: '5px 0' }}>
                                                                        <ListItemIcon>
                                                                            <EmailIcon />
                                                                        </ListItemIcon>
                                                                        <ListItemText primary={(orderDetailData.customer_details && orderDetailData.customer_details.email) && <><span>{orderDetailData.customer_details.email}</span></>}
                                                                        />
                                                                    </ListItemButton>
                                                                </ListItem>
                                                                {orderDetailData.customer_details && orderDetailData.customer_details.phone &&
                                                                    <ListItem disablePadding>
                                                                        <ListItemButton style={{ padding: '5px 0' }}>
                                                                            <ListItemIcon>
                                                                                <LocalPhoneIcon />
                                                                            </ListItemIcon>
                                                                            <ListItemText primary={orderDetailData.customer_details.phone}
                                                                            />
                                                                        </ListItemButton>
                                                                    </ListItem>
                                                                }
                                                            </List>
                                                        </div>
                                                        <p>Recent Orders</p>
                                                        <div style={{ width: '100%', background: '#fff', marginTop: "20px" }}>
                                                            <DataGrid
                                                                getRowId={(row) => row._id}
                                                                rows={orderDetailData && orderDetailData.recentorders}
                                                                columns={columns}
                                                                autoHeight={true}
                                                                disableColumnFilter={true}
                                                                disableColumnMenu={true}
                                                                hideFooter={true}
                                                                disableRowSelectionOnClick
                                                                slots={{
                                                                    NorowsData
                                                                }}
                                                                loading={
                                                                    (orderDetailData && orderDetailData.loading)
                                                                }
                                                            />
                                                        </div>
                                                    </>
                                                    }
                                                </div>
                                            </CardContent>
                                        </Card>

                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </>
                }
            </div >
        </>
    );
}

export default OrderDetailsPage;