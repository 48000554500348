import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers'
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import 'moment-timezone';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const Filters = ({ visible, meta, setMeta, carriers, tags, orderTags, report }) => {
    const profile = useSelector(state => state.profile.profile);
    const [filterButtonDisabled, setFilterButtonDisabled] = useState(true)
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [switchStatus, setSwitchStatus] = React.useState(false);
    let orderValues = [{"display_name":"All","name": "all"},{"display_name":"Regular ($15 and Under)","name": "under15"},{"name":"above15", "display_name":"Parcel (Above $15)"}]
    let orderValuesNew = [{"display_name":"All","name": "all"},{"display_name":"Regular (Under $20)","name": "under20"},{"name":"between20_35", "display_name":"Parcel ($20 to $34.99)"},{"name":"above35", "display_name":"Large Parcel ($35+)"}]

    const onInputChange = (e) => {
        const { name, value } = e.target;
        setMeta({
            ...meta,
            [name]: value,
            [`page`]: 1
        });
    };

    const submitFilterValues = () => {
        setMeta(prevState => ({
            ...prevState,
            [`carrier_name`]: meta["carrier_name"],
            [`order_status`]: meta["order_status"],
            [`order_value`]: meta["order_value"],
            [`shipment_type`]: meta["shipment_type"],
            [`start_date`]: meta["start_date"],
            [`end_date`]: meta["end_date"],
            [`platform_tags`]: meta["platform_tags"],
            [`order_tags`]: meta["order_tags"],
            [`page`]: 1
        }));
    }

    const onDateChange = (date_type, date) => {
        if (date != "" && typeof date !== "undefined" && date != null) {
            let date_formatted = (date != null) ? moment(date).format("YYYY-MM-DD") : date
            if (date_type == "start_date") {
                let start_date = date_formatted + " 00:00:00"
                let start_date_format="";
                if(profile  && profile.time_zone && profile.time_zone!==''){
                    start_date_format=moment.tz(start_date,profile.time_zone).utc().format("YYYY-MM-DD HH:mm:ss")
                }else{
                    start_date_format=moment(start_date).utc().format("YYYY-MM-DD HH:mm:ss")  
                }
                setStartDate(start_date)
                setMeta({
                    ...meta,
                    [`start_date`]: start_date_format
                });
            } else {
                let end_date = date_formatted + " 23:59:59"
                let end_date_format="";
                if(profile && profile.time_zone && profile.time_zone!==''){
                    end_date_format = moment.tz(end_date,profile.time_zone).utc().format("YYYY-MM-DD HH:mm:ss")
                }else{
                    end_date_format=moment(end_date).utc().format("YYYY-MM-DD HH:mm:ss")
                }
                setEndDate(end_date)
                setMeta({
                    ...meta,
                    [`end_date`]: end_date_format
                });
            }
        }
    }

    const onChangeSwitch = (switchValue) => {
        setSwitchStatus(switchValue)
        setMeta({
            ...meta,
            [`sc_generated`]: switchValue
        });
    }

    const handleKeyUp = (event) => {
        if (event.keyCode === 13) {
            submitFilterValues();
        }
    }

    const onInputClear = (name) => {
        setMeta({
            ...meta,
            [name]: "",
        });
    };

    const resetFields = () => {
        setStartDate(null)
        setEndDate(null)
        setSwitchStatus(false)
        setMeta(prevState => ({
            ...prevState,
            [`carrier_name`]: [],
            [`order_status`]: [],
            [`order_value`]: "",
            [`shipment_type`]:0,
            [`start_date`]: "",
            [`end_date`]: "",
            [`sc_generated`]: "false",
            [`page`]: 1,
            [`platform_tags`]: [],
            [`order_tags`]: []
        }));
    }

    useEffect(() => {
        setFilterButtonDisabled(checkFormHasValues())
    }, [meta])

    useEffect(() => {
        if(report=='openorders'){
            setMeta(prevState => ({
                ...prevState,
                [`carrier_name`]: meta["carrier_name"],
                [`order_value`]: meta["order_value"],
                [`order_status`]:["unfulfilled"],
                [`start_date`]: meta["start_date"],
                [`shipment_type`]:meta["shipment_type"],
                [`end_date`]: meta["end_date"],
                [`platform_tags`]: meta["platform_tags"],
                [`order_tags`]: meta["order_tags"],
                [`page`]: 1
            }));
        }else{
            setMeta(prevState => ({
                ...prevState,
                [`carrier_name`]: meta["carrier_name"],
                [`order_value`]: meta["order_value"],
                [`order_status`]:[],
                [`start_date`]: meta["start_date"],
                [`end_date`]: meta["end_date"],
                [`shipment_type`]:meta["shipment_type"],
                [`platform_tags`]: meta["platform_tags"],
                [`order_tags`]: meta["order_tags"],
                [`page`]: 1
            }));
        }
    }, [report])

    const checkFormHasValues = () => {
        let disableButton = true
        if (meta["carrier_name"].length !== 0 || meta["order_status"].length !== 0 || meta["start_date"] !== "" || meta["end_date"] !== "" || switchStatus === true || meta["platform_tags"].length !== 0 || meta["order_tags"].length !== 0) {
            disableButton = false
        }

        return disableButton
    }

    return (
        <>
            {visible &&
                <div className="filtercontainer">
                    <div className="filteraccordion" onKeyUp={handleKeyUp}>
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { m: 1, width: '20ch' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            {(profile.hide_carrier === false) &&
                                <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
                                    <InputLabel id="carrier-name-select-label" size={'small'}>Carrier Name</InputLabel>
                                    <Select
                                        multiple
                                        value={meta && meta.carrier_name}
                                        name={"carrier_name"}
                                        labelId="carrier-name-select-label"
                                        id="demo-simple-select"
                                        onChange={onInputChange}>
                                        {carriers && carriers.data && carriers.data.length !== 0 && carriers.data.map((item) => {
                                            return <MenuItem value={item[0]}>{item[0]}</MenuItem>
                                        })
                                        }
                                    </Select>
                                </FormControl>
                            }
                            {report=="" && <>
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
                                <InputLabel id="order-status-select-label" size={'small'}>Order Status</InputLabel>
                                <Select
                                    multiple
                                    value={meta && meta.order_status}
                                    name={"order_status"} labelId="order-status-select-label"
                                    id="demo-simple-select"
                                    onChange={onInputChange}>
                                    <MenuItem value={"unfulfilled"}>Not Yet Fulfilled</MenuItem>
                                    <MenuItem value={"fulfilled"}>Fulfilled</MenuItem>
                                </Select>
                            </FormControl>
                            </>
                            }
                            {(profile && profile.id && profile.id == 1775) &&
                                <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
                                    <InputLabel id="carrier-name-select-label" size={'small'}>Order Value</InputLabel>
                                    <Select
                                        value={meta && meta.order_value}
                                        name={"order_value"}
                                        labelId="carrier-name-select-label"
                                        id="demo-simple-select"
                                        onChange={onInputChange}>
                                        {orderValues && orderValues.length !== 0 && orderValues.map((item) => {
                                            return <MenuItem value={item.name}>{item.display_name}</MenuItem>
                                        })
                                        }
                                    </Select>
                                </FormControl>
                            }
                            {(profile && profile.id && profile.id == 1754) &&
                                <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
                                    <InputLabel id="carrier-name-select-label" size={'small'}>Order Value</InputLabel>
                                    <Select
                                        value={meta && meta.order_value}
                                        name={"order_value"}
                                        labelId="carrier-name-select-label"
                                        id="demo-simple-select"
                                        onChange={onInputChange}>
                                        {orderValuesNew && orderValuesNew.length !== 0 && orderValuesNew.map((item) => {
                                            return <MenuItem value={item.name}>{item.display_name}</MenuItem>
                                        })
                                        }
                                    </Select>
                                </FormControl>
                            }
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 200, maxWidth: "200px" }}>
                                <InputLabel id="platform-tags-select-label" size={'small'}>Platform Tags</InputLabel>
                                <Select
                                    multiple
                                    value={meta && meta.platform_tags}
                                    name={"platform_tags"} labelId="platform-tags-select-label"
                                    id="demo-simple-select"
                                    MenuProps={{ style: { maxHeight: 500 } }}
                                    onChange={onInputChange}>
                                    {tags && tags.data && tags.data.length !== 0 && tags.data.map((item) => {
                                        return <MenuItem value={item[0]}>{item[0]}</MenuItem>
                                    })
                                    }
                                </Select>
                            </FormControl>
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 200, maxWidth: "200px" }}>
                                <InputLabel id="platform-tags-select-label" size={'small'}>Order Tags</InputLabel>
                                <Select
                                    multiple
                                    value={meta && meta.order_tags}
                                    name={"order_tags"} labelId="platform-tags-select-label"
                                    id="demo-simple-select"
                                    MenuProps={{ style: { maxHeight: 500 } }}
                                    onChange={onInputChange}>
                                    {orderTags && orderTags.data && orderTags.data.length !== 0 && orderTags.data.map((item) => {
                                        return <MenuItem value={item.tag_name}>{item.tag_name}</MenuItem>
                                    })
                                    }
                                    <MenuItem value="NOTASSIGNED">Tag not assigned</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 315 }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Stack spacing={3} style={{ flexDirection: 'row' }}>
                                        <DatePicker
                                            label="Start Date"
                                            value={startDate}
                                            disableFuture={true}
                                            inputFormat={"yyyy-MM-dd"}
                                            onChange={(newValue) => {
                                                onDateChange("start_date", newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    variant="standard"
                                                    label="Star Date"
                                                    size={'small'}
                                                    {...params}
                                                />
                                            )}
                                        />
                                        <DatePicker
                                            label="End Date"
                                            value={endDate}
                                            disableFuture={true}
                                            inputFormat={"yyyy-MM-dd"}
                                            onChange={(newValue) => {
                                                onDateChange("end_date", newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    variant="standard"
                                                    label="End Date"
                                                    size={'small'}
                                                    {...params}
                                                />
                                            )}
                                        />
                                    </Stack>
                                </LocalizationProvider>
                            </FormControl>
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
                                <InputLabel id="order-status-select-label" size={'small'}>Types of Shipments</InputLabel>
                                <Select
                                    value={meta && meta.shipment_type}
                                    name={"shipment_type"} labelId="order-status-select-label"
                                    id="demo-simple-select"
                                    onChange={onInputChange}>
                                    <MenuItem value={0}>All Shipments</MenuItem>
                                    <MenuItem value={1}>Single Piece Shipments</MenuItem>
                                    <MenuItem value={2}>Multi Piece Shipments</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl style={{paddingTop:'15px'}} variant="standard" sx={{ m: 1, minWidth: 200 }}>
                                <FormControlLabel
                                    style={{fontSize: '14px !important'}}
                                    control={
                                        <Switch
                                            color="primary"
                                            defaultChecked={switchStatus}
                                            checked={switchStatus}
                                            onChange={(e) => {
                                                onChangeSwitch(e.target.checked)
                                            }}
                                            size="small"
                                            name="sc_generated" />
                                    }
                                    label={
                                        <Typography style={{ fontSize: '14px' }}>
                                            Show only SC shipment orders
                                        </Typography>}
                                    labelPlacement="end"
                                />
                            </FormControl>
                        </Box>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={12}>
                                <div className="filterfooter">
                                    <Button variant="contained" onClick={submitFilterValues} disabled={filterButtonDisabled}>Filter</Button>
                                    <Button variant="outlined" onClick={resetFields} disabled={filterButtonDisabled}>Clear</Button>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            }
        </>
    )

}

export default Filters;
