import { createSlice } from '@reduxjs/toolkit';
import { OMSAPI } from '../../../../../API';
import { fetchIntegratedPlatform } from '../../integratedSlice';

export const shopifyappslice = createSlice({
    name: 'shopifyapp',
    initialState: {
        progress: {
            data: {
                selected_location_id: "",
                is_update_shipment: false,
                is_update_shipment_on_create: false,
                is_update_shipment_on_close: true
            },
            location:[],
            message:"",
            loading: false,
            hasErrors: false
        },
        configure: {
            data: {},          
            message:"",
            loading: false,
            hasErrors: false
        }
    },
    reducers: {
        fetchShopifyDataDetails: (state) => {
            return {
                ...state,
                progress: {
                    loading: true
                }
            }
        },
        fetchShopifyDataDetailsSuccess: (state, action) => {
            return {
                ...state,
                progress: {
                    data: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data,
                    location: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.location,
                    loading: false,
                    hasErrors: false,
                }
            }
        },
        fetchShopifyDataDetailsFailure: (state, action) => {
            return {
                ...state,
                progress: {
                    loading: false,
                    hasErrors: false,
                }
            }
        },
        fetchLocationAddress: (state) => {
            return {
                ...state,
                progress: {
                    loading: true
                }
            }
        },
        fetchLocationAddressSuccess: (state, action) => {
            return {
                ...state,
                progress: {
                    data:{},
                    location: action && action.payload && action.payload.data && action.payload.data.data,
                    loading: false,
                    hasErrors: false,
                }
            }
        },
        fetchLocationAddressFailure: (state, action) => {
            return {
                ...state,
                progress: {
                    loading: false,
                    hasErrors: false,
                }
            }
        },
        postShopifyConfigure: (state) => {
            return {
                ...state,
                configure: {
                    loading: true
                }
            }
        },
        postShopifyConfigureSuccess: (state, action) => {
            return {
                ...state,
                configure: {
                    data:{},
                    message: action && action.payload && action.payload.data && action.payload.data.messages,
                    loading: false,
                    hasErrors: false,
                }
            }
        },
        postShopifyConfigureFailure: (state, action) => {
            return {
                ...state,
                configure: {
                    message:action && action.payload && action.payload.data && action.payload.data.messages,
                    loading: false,
                    hasErrors: true,
                }
            }
        },
        resetConfigureStateDetails: (state, action) => {
            return {
                ...state,
                configure: {
                    data: {},
                    message:'',
                    loading: false,
                    hasErrors: false,
                }
            }
        },
        resetShopifyStateDetails: (state, action) => {
            return {
                ...state,
                progress: {
                    data: {
                        selected_location_id: "",
                        is_update_shipment: false,
                        is_update_shipment_on_create: false,
                        is_update_shipment_on_close: true
                    },
                    location:[],
                    message:"",
                    loading: false,
                    hasErrors: false
                },
                configure: {
                    data: {},
                    message:'',
                    loading: false,
                    hasErrors: false,
                }
            }
        },
    }
})
export const { fetchShopifyDataDetails, fetchShopifyDataDetailsSuccess, fetchShopifyDataDetailsFailure,
    fetchLocationAddress, fetchLocationAddressSuccess, fetchLocationAddressFailure,
    postShopifyConfigure, postShopifyConfigureSuccess, postShopifyConfigureFailure, resetConfigureStateDetails, resetShopifyStateDetails } = shopifyappslice.actions;

export const configureStore = data => dispatch => {
    dispatch(postShopifyConfigure());
    try {
        OMSAPI.post('/shopify/configure', data)
            .then(function (response) {
                dispatch(postShopifyConfigureSuccess(response));
                dispatch(fetchIntegratedPlatform());           
            }).catch(function (error) {
                dispatch(postShopifyConfigureFailure(error.response));
            })
    }
    catch (error) {
        dispatch(postShopifyConfigureFailure(error.response));
    }
}

export const fetchLocation = data => dispatch => {   
    dispatch(fetchLocationAddress())
    try {
        OMSAPI.post('/shopify/location', data)
            .then(function (response) {
                dispatch(fetchLocationAddressSuccess(response));
            }).catch(function (error) {
                dispatch(fetchLocationAddressFailure());
            })
    }
    catch (error) {
        dispatch(fetchLocationAddressFailure())
    }
}


export const fetchShopifyData = data => dispatch => {

    dispatch(fetchShopifyDataDetails())
    try {
        OMSAPI.post('/shopify/show', data)
            .then(function (response) {
                dispatch(fetchShopifyDataDetailsSuccess(response));
            }).catch(function (error) {
                dispatch(fetchShopifyDataDetailsFailure());
            })
    }
    catch (error) {
        dispatch(fetchShopifyDataDetailsFailure())
    }
}

export const resetConfigureState = data => dispatch => {    
    dispatch(resetConfigureStateDetails());
}

export const resetShopifyState = data => dispatch => {    
    dispatch(resetShopifyStateDetails());
}
export default shopifyappslice.reducer;