import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Snackbar from '@mui/material/Snackbar';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { styled } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import DeleteIcon from '@mui/icons-material/Delete';
import LinearProgress from '@mui/material/LinearProgress';
import dayjs from 'dayjs';


import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { getManifestSettings, postManifestSettings, resetManifestSettingsState } from './manifestSettingsSlice';

const ManifestSettingsPage = () => {
    const dispatch = useDispatch();
    const manifestsettings = useSelector(state => state.manifestsettings);
    const [alert, setAlert] = useState(false);
    const [open, setOpen] = useState(false);
    const [manifestClose, setManifestClose] = useState("manually");
    const [manifestCloseAt, setManifestCloseAt] = useState(dayjs("2023-01-01 18:00"));

    const onInputChange = e => {
        setManifestClose(e.target.value)
    }

    const z = (n) => {
        return ('0' + n).slice(-2);
      }

    const onSubmit = () => {
        const d = new Date(manifestCloseAt);
        let localTime = z(d.getHours()) + ':' + z(d.getMinutes());
        let utcTime = z(d.getUTCHours()) + ':' + z(d.getUTCMinutes());
        let data = {
            "manifest_close": manifestClose,
            "manifest_close_at": localTime,
            "manifest_close_at_utc": utcTime
        }
        dispatch(postManifestSettings(data))
    }

    useEffect(() => {
        if (manifestsettings && manifestsettings.loading === false) {
            if (manifestsettings && manifestsettings.data && manifestsettings.data.manifest_settings && manifestsettings.data.manifest_settings && manifestsettings.data.manifest_settings.automatic_close) {
                setManifestClose("automatically")
                if (manifestsettings && manifestsettings.data && manifestsettings.data.manifest_settings && manifestsettings.data.manifest_settings && manifestsettings.data.manifest_settings.close_at) {
                    setManifestCloseAt(dayjs("2023-01-01 " + manifestsettings.data.manifest_settings.close_at))
                }else{
                    setManifestCloseAt(dayjs("2023-01-01 18:00"))
                }
            } else {
                setManifestClose("manually")
                if (manifestsettings && manifestsettings.data && manifestsettings.data.manifest_settings && manifestsettings.data.manifest_settings && manifestsettings.data.manifest_settings.close_on_create_shipment) {
                    setManifestClose("oncreate")
                }
                setManifestCloseAt(dayjs("2023-01-01 18:00"))
            }
            if (manifestsettings && manifestsettings.message) {
                setAlert(true)
                setOpen(true)
            }
        }
    }, [manifestsettings]);

    const handleClose = () => {
        setOpen(false)
        dispatch(resetManifestSettingsState())
    };

    useEffect(() => {
        dispatch(getManifestSettings())
    }, [])

    return (
        <div className="sc--settings--card">

            {manifestsettings && manifestsettings.loading === false && alert &&
                <Snackbar
                    open={open}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    autoHideDuration={6000}
                    onClose={handleClose}
                >
                    <Alert
                        severity={manifestsettings.hasErrors == true ? "error" : "success"}
                        style={{ justifyContent: 'center', margin: "10px 0" }}>
                        <span>{manifestsettings && manifestsettings.message}</span>
                    </Alert>
                </Snackbar>
            }

            <Card sx={{ minWidth: '100%', boxShadow: 'unset' }} className="sc--cardhead--details">
                <CardHeader title='Manifest Settings' className="sc--cardhead--details" />
                {manifestsettings && manifestsettings.loading === true &&
                    <LinearProgress />
                }
                <Divider />
                {manifestsettings && manifestsettings.loading === false &&
                    <CardContent>
                        <div className="sc--form--center" style={{ width: "50%", margin: 'auto' }}>
                            <FormControl variant="standard" className="help-text-error" style={{ width: '60%', margin: '8px' }} >
                                <InputLabel id="demo-simple-select-standard-label">Close Manifest</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-error"
                                    name="label"
                                    value={manifestClose}
                                    onChange={onInputChange}
                                    label="label"
                                >
                                    <MenuItem value={"manually"}>Manual</MenuItem>
                                    <MenuItem value={"automatically"}>Automatic</MenuItem>
                                    <MenuItem value={"oncreate"}>On Shipment Create</MenuItem>
                                </Select>

                            </FormControl>
                        </div>
                        <div className="sc--form--center" style={{ width: "50%", margin: 'auto' }}>
                            {manifestClose && manifestClose === 'automatically' &&
                                <FormControl variant="standard" className="help-text-error" style={{ width: '60%', margin: '8px' }} >
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <TimePicker
                                            label="Close at"
                                            value={manifestCloseAt}
                                            onChange={(newValue) => {
                                                setManifestCloseAt(newValue);
                                            }}
                                            renderInput={(params) => <TextField variant="standard" {...params} />}
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            }
                        </div>
                        <div className="sc--form--center" style={{ width: '77%', justifyContent: 'flex-end', position: 'relative', left: '-10%' }}>
                            <Button variant="outlined" onClick={onSubmit} style={{ margin: '15px 15px' }}>
                                Save
                            </Button>
                        </div>
                    </CardContent>
                }
            </Card >
        </div>
    )
}

export default ManifestSettingsPage;
