import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const PageSearch = ({ pagenumber, getpagenumber }) => {
    const orders = useSelector(state => state.orders);
    const [pageNo, setPageNo] = useState("")
    const onInputChange = (e) => {
        let pagenumber = '' 
        pagenumber = e.target.value
        setPageNo(pagenumber)
    }
    const onPageChange = () => {
        if (pageNo >= 1 && pageNo <=  orders.ordersData.meta.last_page) {
            getpagenumber(pageNo)
        }
    }
    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginRight: '10px' }}>
                <p style={{ padding: '0 10px' }}>Go to Page</p>
                <TextField
                    autoComplete='off'
                    defaultValue={pagenumber ? pagenumber : pageNo}
                    id="outlined-basic"
                    type="number"
                    size={'small'}
                    inputProps={{ min: 1}}
                    onInput={onInputChange}
                    style={{ width: '30%', padding: '0 10px',fontSize:'10px' }} variant="outlined" />
                <Button variant="contained" style={{ height: '35px', padding: '0 10px' }} onClick={onPageChange}>Jump</Button>

            </div>
        </>
    )
};

export default PageSearch;
