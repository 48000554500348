import React from "react";
import { useSelector } from 'react-redux';

const CarrierTracking = ({ trackingItems = null, carrierImageWidth = null , dispatchCallback = null, message = null,is_multi_piece}) => {
    const profile = useSelector(state => state.auth.profile);
    const initiateTracking = (carrier_name, tracking_number, message,is_multi_piece) => {
        if (typeof dispatchCallback === 'function') {
            dispatchCallback(carrier_name, tracking_number, message,is_multi_piece)
        } else {
            console.log("Dispatch Call Back Function is not valid");
        }
    }

    return (
        <>
            <span className="tb-lead">
                <div className={`user-card`} style={{ display: 'flex', alignItems: 'center' }}>
                    {(profile.hide_carrier === false) && trackingItems && trackingItems.carrier_name && trackingItems.carrier_name !== "" && carrierImageWidth != null && <div><img src={"/assets/images/carriers/" + trackingItems.carrier_name.toUpperCase().replace(/ /g, "_") + ".jpg"} onError={(e) => { e.target.onerror = null; e.target.src = "/assets/images/carriers/OTHERS.jpg" }} alt={trackingItems.carrier_name.toUpperCase()} style={{ width: carrierImageWidth }} /></div>}
                    {(profile.hide_carrier === false) && trackingItems && trackingItems.carrier_name && trackingItems.carrier_name !== "" && carrierImageWidth == null && <div><img src={"/assets/images/carriers/" + trackingItems.carrier_name.toUpperCase().replace(/ /g, "_") + ".jpg"} onError={(e) => { e.target.onerror = null; e.target.src = "/assets/images/carriers/OTHERS.jpg" }} alt={trackingItems.carrier_name.toUpperCase()} /></div>}
                    {dispatchCallback != null && <span style={{ color: "#1890ff", cursor: "pointer", marginLeft: "15px" }} onClick={() => initiateTracking(trackingItems.carrier_name.toUpperCase(), trackingItems.tracking_number, message,is_multi_piece)}> {trackingItems.tracking_number}</span>}
                    {dispatchCallback == null && <span style={{ marginLeft: "15px" }}> {trackingItems.tracking_number}</span>}
                </div>
            </span>
        </>
    )
}

export default CarrierTracking;
