import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";

// Mui Libraries
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import EmailEditor from "react-email-editor";
import _ from "lodash";
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';

// Components
import { fetchEmailTemplate, fetchNotificationEvents, fetchNotificationEventDetail, sendPreviewEmail, updateNotificationEvents, resetConfigureState } from '../Notification/PlatformIntegrations/Email/emailSlice';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper'
};
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  border: "1px solid rgba(224, 224, 224, 1)",
  boxShadow: "unset"
}));
const Modalpopup = ({ modalVisible, setModalVisible, extraInfo, emailpreview }) => {
  const dispatch = useDispatch();
  const onModalClose = () => {
    setModalVisible(false)
    setErrors({})
  }
  const initialValues = {
    to_email: ''
  }
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});

  const validator = (fieldValues) => {
    let temp = { ...errors }
    if ('to_email' in fieldValues)
      temp.to_email = fieldValues.to_email ? "" : "This field is required."

    setErrors({
      ...temp
    })

    if (fieldValues === values)
      return Object.values(temp).every(x => x == "")
  }

  const onInputChange = e => {
    const { name, value } = e.target
    setValues({
      ...values,
      [name]: value
    })
    validator({ [name]: value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const isValid = validator(values)
    if (isValid) {
      dispatch(sendPreviewEmail(extraInfo.event_type, values));
    }
  }
  return (
    <Modal
      open={modalVisible}
      onClose={onModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Card sx={{ minWidth: 600 }} className="sc--cardhead--details">
          <CardHeader title='Preview Email' />
          <Divider />
          <CardContent>
            <div className="sc--form--center" style={{ margin: '20px 0' }}>
              <TextField
                label="To Email"
                name="to_email"
                size={"small"}
                value={values.to_email}
                onChange={onInputChange}
                error={errors.to_email}
                helperText={errors.to_email}
                style={{ width: "100%", margin: '8px' }}
                variant="outlined"
              />
            </div>
            <div className="sc--form--center" style={{ margin: '30px 0' }}>
              <LoadingButton
                variant="outlined"
                onClick={handleSubmit}
                loading={emailpreview && emailpreview.loading}
              >Send
              </LoadingButton>

            </div>
          </CardContent>
        </Card>
      </Box>
    </Modal >
  )
}
const UEditor = ({ template, onChange, loadTemplate, setLoadTemplate }) => {
  const options = {
    mergeTags: {
      order: {
        name: "Order",
        mergeTags: {
          order_number: {
            name: "Number",
            value: "[Order Number]",
          },
          order_status: {
            name: "Status",
            value: "[Order Status]",
          },
          order_status_url: {
            name: "Status Url",
            value: "[Order Status Url]",
          },
          order_date: {
            name: "Date",
            value: "[Order Date]",
          },
          order_day: {
            name: "Day",
            value: "[Order Day]",
          },
          order_month: {
            name: "Month",
            value: "[Order Month]",
          },
          order_formatted_date: {
            name: "Formatted Date",
            value: "[Order Date Formatted]",
          },
          order_subtotal_price_without_tax: {
            name: "Subtotal Price without tax",
            value: "[Subtotal Exclude Tax Price]",
          },
          order_subtotal_price_with_tax: {
            name: "Subtotal Price with tax",
            value: "[Subtotal Include Tax Price]",
          },
          order_shipping_price_without_tax: {
            name: "Shipping Price without tax",
            value: "[Shipping Exclude Tax Price]",
          },
          order_shipping_price_with_tax: {
            name: "Shipping Price with tax",
            value: "[Shipping Include Tax Price]",
          },
          order_discount_price: {
            name: "Discount Price",
            value: "[Discount Price]",
          },
          order_coupon_price: {
            name: "Coupon Price",
            value: "[Coupon Price]",
          },
          order_total_tax: {
            name: "Total Tax",
            value: "[Tax Price]",
          },
          order_price_without_tax: {
            name: "Price without tax",
            value: "[Order Exclude Tax Price]",
          },
          order_price: {
            name: "Price",
            value: "[Order Price]",
          },
          currency: {
            name: "Currency",
            value: "[Currency]",
          },
        },
      },
      customer: {
        name: "Customer",
        mergeTags: {
          name: {
            name: "Name",
            value: "[Customer Name]",
          },
          id: {
            name: "Id",
            value: "[Customer Id]",
          },
          email: {
            name: "Email",
            value: "[Customer Email]",
          },
          phone: {
            name: "Phone",
            value: "[Customer Phone]",
          },
        },
      },
      shipping_address: {
        name: "Shipping Address",
        mergeTags: {
          name: {
            name: "Name",
            value: "[Shipping Address Name]",
          },
          first_name: {
            name: "First Name",
            value: "[Shipping Address First Name]",
          },
          last_name: {
            name: "Last Name",
            value: "[Shipping Address Last Name]",
          },
          company: {
            name: "Company",
            value: "[Shipping Address Company]",
          },
          street_1: {
            name: "Street 1",
            value: "[Shipping Address Street1]",
          },
          street_2: {
            name: "Street 2",
            value: "[Shipping Address Street2]",
          },
          city: {
            name: "City",
            value: "[Shipping Address City]",
          },
          province: {
            name: "Province",
            value: "[Shipping Address Province]",
          },
          country: {
            name: "Country",
            value: "[Shipping Address Country]",
          },
          province_code: {
            name: "Province Code",
            value: "[Shipping Address Province Code]",
          },
          country_code: {
            name: "Country Code",
            value: "[Shipping Address Country Code]",
          },
          zip: {
            name: "Zip",
            value: "[Shipping Address Zip]",
          },
          phone: {
            name: "Phone",
            value: "[Shipping Address Phone]",
          },
        },
      },
      billing_address: {
        name: "Billing Address",
        mergeTags: {
          name: {
            name: "Name",
            value: "[Billing Address Name]",
          },
          first_name: {
            name: "First Name",
            value: "[Billing Address First Name]",
          },
          last_name: {
            name: "Last Name",
            value: "[Billing Address Last Name]",
          },
          company: {
            name: "Company",
            value: "[Billing Address Company]",
          },
          street_1: {
            name: "Street 1",
            value: "[Billing Address Street1]",
          },
          street_2: {
            name: "Street 2",
            value: "[Billing Address Street2]",
          },
          city: {
            name: "City",
            value: "[Billing Address City]",
          },
          province: {
            name: "Province",
            value: "[Billing Address Province]",
          },
          country: {
            name: "Country",
            value: "[Billing Address Country]",
          },
          province_code: {
            name: "Province Code",
            value: "[Billing Address Province Code]",
          },
          country_code: {
            name: "Country Code",
            value: "[Billing Address Country Code]",
          },
          zip: {
            name: "Zip",
            value: "[Billing Address Zip]",
          },
          phone: {
            name: "Phone",
            value: "[Billing Address Phone]",
          },
        },
      },
      shipment: {
        name: "Shipment",
        mergeTags: {
          tracking_number: {
            name: "Tracking Number",
            value: "[Tracking Number]",
          },
          shipment_status: {
            name: "Status",
            value: "[Shipment Status]",
          },
          shipment_status_formatted: {
            name: "Status Formatted",
            value: "[Shipment Status Formatted]",
          },
          tracking_link: {
            name: "Tracking Link",
            value: "[Tracking Link]",
          },
          carrier_tracking_link: {
            name: "Carrier Tracking Link",
            value: "[Carrier Tracking Link]",
          },
          shipping_carrier_name: {
            name: "Carrier Name",
            value: "[Shipping Carrier Name]",
          },
          shipping_carrier_name_formatted: {
            name: "Carrier Name Formatted",
            value: "[Shipping Carrier Name Formatted]",
          },
          shipping_method: {
            name: "Bigcommerce Shipping Method",
            value: "[Bigcommerce Shipping Method]",
          },
          last_update_on: {
            name: "Event Last Update On",
            value: "[Last Update On]",
          },
          location: {
            name: "Event Location",
            value: "[Location]",
          },
          estimated_date: {
            name: "Estimated Date",
            value: "[Estimated Date]",
          },
          estimated_date_formatted: {
            name: "Estimated Date Formatted",
            value: "[Estimated Date Formatted]",
          },
          disputed_date: {
            name: "Disputed Date",
            value: "[Disputed Date]",
          },
          disputed_date: {
            name: "Disputed Date Formatted",
            value: "[Disputed Date Formatted]",
          },
          service_type: {
            name: "Service Type",
            value: "[Service Type]",
          },
        },
      },
      products: {
        name: "Products",
        rules: {
          repeat: {
            name: "Repeat for Each Product",
            before: "{{start_products}}",
            after: "{{end_products}}",
          },
        },
        mergeTags: {
          product_name: {
            name: "Product Name",
            value: "[Product Name]",
          },
          product_quantity: {
            name: "Product Quantity",
            value: "[Product Qty]",
          },
          product_price: {
            name: "Product Price",
            value: "[Product Price]",
          },
          product_image: {
            name: "Product Image",
            value: "[Product Image]",
          },
          product_carrier_name: {
            name: "Product Carrier Name",
            value: "[Product Carrier Name]",
          },
          product_tracking_number: {
            name: "Product Tracking Number",
            value: "[Product Tracking Number]",
          },
          product_tracking_link: {
            name: "Product Tracking Link",
            value: "[Product Tracking Link]",
          },
          product_shipping_method: {
            name: "Product Bigcommerce Shipping Method",
            value: "[Product Bigcommerce Shipping Method]",
          },
        },
      },
    },
  };
  const editorRef = useRef();
  const onLoadEditor = useCallback(() => {
    const timer = setInterval(function () {
      if (editorRef && editorRef.current && editorRef.current.editor) {
        editorRef.current.editor.loadDesign(template.design);
        editorRef.current.addEventListener("design:updated", () =>
          editorRef.current.exportHtml(onChange)
        );
        clearInterval(timer);
      }
    }, 500);
  }, [editorRef]);

  useEffect(() => {
    if (loadTemplate == true) {
      if (editorRef && editorRef.current && editorRef.current.editor) {
        editorRef.current.editor.loadDesign(template.design);
        editorRef.current.addEventListener("design:updated", () =>
          editorRef.current.exportHtml(onChange)
        );
        setLoadTemplate(false)
      }
    }
  }, [template]);

  if (typeof template !== "undefined") {
    return (
      <EmailEditor options={options} ref={editorRef} minHeight="800px" onLoad={onLoadEditor} projectId={18990} />
    );
  } else {
    return (
      <EmailEditor options={options} ref={editorRef} minHeight="800px" projectId={18990} />
    );
  }


};
const NotificationTemplatePage = ({ match }) => {

  const dispatch = useDispatch();
  const history = useHistory();
  const emailtemplate = useSelector((state) => state.email.emailtemplate);
  const unlayertemplate = useSelector((state) => state.email.unlayertemplate);
  const notificationconfig = useSelector(state => state.email && state.email.notificationconfig);
  const emailpreview = useSelector(state => state.email && state.email.preview);

  const [modalVisible, setModalVisible] = useState(false);
  const [loadTemplate, setLoadTemplate] = useState(false);
  const [alert, setAlert] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const handleClose = () => {
    dispatch(resetConfigureState())
    setSnackOpen(false);
    setAlert(false)
  };

  const { eventDetail, event, notification, preview } = useSelector(
    (state) => state.email
  );
  const editorOnChange = (template) => {
    setTemplate(template);
  };
  const [template, setTemplate] = useState();

  const extraInfo = {
    id: match.params.id,
    event_type: match.params.event_type,
    event_key: match.params.event_type,
    notification_type: match.params.notification_type,
    is_enabled: match.params.is_enabled,
    // event_name: _.result(
    //     _.find(eventData, { key: match.params.event_type }),
    //     "name"
    // ),
  };
  const initiaValues = {
    subject: ''
  }
  const [values, setValues] = useState(initiaValues);
  const [errors, setErrors] = useState({});

  const validator = (fieldValues) => {
    let temp = { ...errors }
    if ('subject' in fieldValues)
      temp.subject = fieldValues.subject ? "" : "This field is required."

    setErrors({
      ...temp
    })

    if (fieldValues === values)
      return Object.values(temp).every(x => x == "")
  }

  const onInputChange = e => {
    const { name, value } = e.target
    setValues({
      ...values,
      [name]: value
    })
    validator({ [name]: value })
  }

  const handleEmailSubmit = e => {
    e.preventDefault()
    const isValid = validator(values)
    if (isValid) {

      let data = { ...values, ...extraInfo, ...{ template: template } }
      dispatch(updateNotificationEvents(data));
    }
  }
  useEffect(() => {
    dispatch(fetchEmailTemplate());
    dispatch(fetchNotificationEvents());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchNotificationEventDetail(match.params.event_type));
  }, [dispatch, match.params.event_type]);

  useEffect(() => {
    if (unlayertemplate && unlayertemplate.data && unlayertemplate.data.template != "" && !_.isUndefined(unlayertemplate.data.template)) {
      setTemplate(unlayertemplate.data.template);
      setLoadTemplate(true)
    }
  }, [unlayertemplate && unlayertemplate.data && unlayertemplate.data.template])

  useEffect(() => {
    if (eventDetail) {
      setValues({
        ...values,
        subject: eventDetail.data &&
          eventDetail.data.event_info &&
          eventDetail.data.event_info["EMAIL"] &&
          eventDetail.data.event_info["EMAIL"].subject
      })
    }
    if (eventDetail &&
      eventDetail.data &&
      eventDetail.data.event_info &&
      eventDetail.data.event_info["EMAIL"] &&
      eventDetail.data.event_info["EMAIL"].message
    ) {
      setTemplate(eventDetail.data.event_info["EMAIL"].message);
      setLoadTemplate(true)
    }

  }, [eventDetail])
  useEffect(() => {
    if (notificationconfig && notificationconfig.notification && notificationconfig.notification.showMessage) {
      setAlert(true)
      setSnackOpen(true)
    }

  }, [notificationconfig])
  useEffect(() => {
    if (emailpreview &&
      emailpreview.notification && emailpreview.notification.showMessage) {
      setAlert(true)
      setSnackOpen(true)
    }

  }, [emailpreview])
  return (
    <>
      {alert && (notificationconfig && notificationconfig.loading === false || emailpreview && emailpreview.loading === false) &&
        <Snackbar
          open={snackOpen}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            variant="filled"
            severity=
            {(notificationconfig && notificationconfig.notification && notificationconfig.notification.messageType === "success" ||
              emailpreview && emailpreview.notification && emailpreview.notification.messageType === "success") ? "success" : "error"}
            style={{ justifyContent: 'center', margin: "10px 0" }}
          >
            {notificationconfig && notificationconfig.notification && notificationconfig.notification.messageContent &&
              <span > {notificationconfig && notificationconfig.notification && notificationconfig.notification.messageContent}</span>
            }
            {emailpreview && emailpreview.notification && emailpreview.notification.messageContent &&
              <span > {emailpreview && emailpreview.notification && emailpreview.notification.messageContent}</span>
            }
          </Alert>
        </Snackbar>
      }
    {eventDetail && eventDetail.loading === true &&
        <div className="vertical-center">
            <CircularProgress />
        </div>
     }
      {eventDetail && eventDetail.loading === false &&
        <div className="scmaincard">
          <div className="integration--stores">
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Item style={{ height: '100%' }}>
                    <div>
                      <div className="integration--stores--head">
                        <div className="integration--stores--title" style={{ width: '100%' }}>
                          <h2 style={{ textAlign: 'left' }}>{`${extraInfo["event_type"]} Template`}</h2>
                        </div>
                        <div style={{ margin: '0px 20px', display: 'flex' }}>
                          <Button variant="outlined" style={{ width: '150px', margin: '0 15px' }} onClick={() => {
                            dispatch(resetConfigureState())
                            history.goBack();
                          }}>Back
                        </Button>
                          <LoadingButton variant="outlined" style={{ width: '215px', margin: '0 15px' }} onClick={handleEmailSubmit} loading={
                            notificationconfig && notificationconfig.loading}>Save email template</LoadingButton>
                          <Button variant="outlined" style={{ width: '150px' }} onClick={() => { setModalVisible(true) }}>Preview email</Button>
                        </div>
                      </div>
                      <div
                        className="sc--form--center"
                        style={{ margin: '20px 0' }}>
                        <TextField
                          label="Email Subject"
                          name="subject"
                          size={'small'}
                          value={values.subject}
                          onChange={onInputChange}
                          error={errors.subject}
                          helperText={errors.subject}
                          style={{ width: "100%", margin: '8px' }}
                          variant="outlined"
                        />
                      </div>
                      <div
                        className="sc--form--center"
                        style={{ margin: '20px 0' }}>
                        
                        {template && template.design && <UEditor template={template} onChange={editorOnChange} loadTemplate={loadTemplate} setLoadTemplate={setLoadTemplate} />}
                      </div>

                    </div>
                  </Item>
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
      }
      <Modalpopup
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        extraInfo={extraInfo}
        emailpreview={emailpreview}
      />
    </>
  )
}
export default NotificationTemplatePage;

