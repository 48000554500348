import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Typography,
    FormControl,
    Pagination,
    IconButton,
    Tooltip,
    Button,
} from "@mui/material";
import {
    DataGrid,
    GridToolbarContainer
} from '@mui/x-data-grid';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import LinearProgress from '@mui/material/LinearProgress';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Date from '../../../pages/General/Date'
import NorowsData from '../../General/NorowsData'
import PickupDrawer from '../../General/PickupDrawer'
import PickupItemsDrawer from '../../General/PickupItemsDrawer'
import { fetchPickupDetails, resetPickupNotifications } from './pickupSlice';
import Notification from '../../../pages/General/Notification'
import Printer from '../../../pages/General/Printer'


const PickupPage = () => {
    const dispatch = useDispatch();
    const pickup = useSelector(state => state.pickup && state.pickup.pickupData);
    const pickupCreate = useSelector(state => state.pickup && state.pickup.createPickup);
    const [meta, setMeta] = useState(pickup && pickup.meta);
    const [pickupDrawerVisible, setPickupDrawerVisible] = useState(false);
    const [pickupItemDrawerVisible, setPickupItemDrawerVisible] = useState(false);
    const [pickupItems, setPickupItems] = useState({});
    const [pickupReferenceId, setPickupReferenceId] = useState("");
    const [printUrl, setPrintUrl] = useState("")
    const [isPrinted, setIsPrinted] = useState(true)

    const openPickupDrawer = () => {
        setPickupDrawerVisible(true)
    }

    const pickupOnClose = (values) => {
        setPickupDrawerVisible(false);
    };

    const oepnPickupItemsDrawer = (pickupid) => {
        let output = pickup && pickup.data.filter(({ _id }) => [pickupid].includes(_id));
        let finaloutput = Object.assign({}, output[0]);
        setPickupItems(finaloutput)
        setPickupItemDrawerVisible(true)
        setPickupReferenceId(pickupid)

    }

    const pickupItemsOnClose = () => {
        setPickupItemDrawerVisible(false);
    };

    const columns = [
        {
            field: '_id',
            headerName: 'Pickup Reference',
            headerClassName: 'super-app-theme--header',
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return <span style={{ color: 'rgb(24, 144, 255)', cursor: 'pointer' }} onClick={e => { oepnPickupItemsDrawer(params.row._id) }}>{params.row._id}</span>

            }
        },
       
        {
            field: 'total_quantity',
            headerName: 'Total Quantity',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            renderCell: (params) => {
                if (params.row.total_quantity) {
                    return params.row.total_quantity
                } else {
                    return "-"
                }
            }
        },
        {
            field: 'created_at',
            headerName: 'Created Date',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            renderCell: (params) => {
                return <Date date={params.row.created_at} />
            }
        },
        {
            field: 'action',
            headerName: 'Pickup Label',
            minWidth: 200,
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return <div>
                    <span style={{ marginRight: '10px' }}><Tooltip title="View" placement="left-start">
                        <IconButton 
                            onClick={() => window.open(params.row.carrier_response && params.row.carrier_response.label_url_direct, '_blank')}
                            style={{
                                color: params.row.carrier_response && params.row.carrier_response.label_url_direct ? '#1890ff' : 'rgba(0,0,0,.54)',
                                pointerEvents: params.row.carrier_response && params.row.carrier_response.label_url_direct ? 'auto' : 'none',
                                cursor: params.row.carrier_response && params.row.carrier_response.label_url_direct ? 'pointer' : 'not-allowed'
                            }}
                            >
                            <RemoveRedEyeIcon />
                        </IconButton>
                    </Tooltip>
                    </span>
                    <span style={{ marginRight: '10px' }}><Tooltip title="Print" placement="left-start">
                        <IconButton 
                             onClick={() => { setPrintUrl(params.row.carrier_response && params.row.carrier_response.label_url_direct); setIsPrinted(false) }}
                             style={{
                                color: params.row.carrier_response && params.row.carrier_response.label_url_direct ? '#1890ff' : 'rgba(0,0,0,.54)',
                                pointerEvents: params.row.carrier_response && params.row.carrier_response.label_url_direct ? 'auto' : 'none',
                                cursor: params.row.carrier_response && params.row.carrier_response.label_url_direct ? 'pointer' : 'not-allowed'
                            }}
                        >
                            <LocalPrintshopIcon />
                        </IconButton>
                    </Tooltip>
                    </span>
                </div>
            }
        }
    ];

    const onSortChange = (currentSorting) => {
        setMeta({
            ...meta,
            ...{
                sorting: JSON.stringify(currentSorting)
            }
        });
    }

    const onChangePerPage = (event) => {
        setMeta({
            ...meta,
            ...{
                page: 1,
                per_page: event.target.value
            }
        });
    }

    const onChangePagination = (event, page) => {
        setMeta({
            ...meta,
            ...{
                page: page
            }
        });
    }

    function CustomTotalRows() {

        return (
            <div className="custom-total-rows">
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    Showing {pickup && pickup.data && pickup.data.length} of {(pickup && pickup.meta && pickup.meta.total) ? pickup.meta.total : 0}
                </FormControl>
            </div>
        )
    }


    function CustomPagesizeoptions() {

        return (
            <div className="custom-page-size-options">
                <span>
                    Show <select
                        value={meta && meta.per_page}
                        onChange={onChangePerPage}
                    >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </select> entries
                </span>
            </div>
        )
    }

    function CustomToolbar() {

        return (
            <GridToolbarContainer>
                <CustomPagesizeoptions />
                <CustomTotalRows />
            </GridToolbarContainer>
        );
    }

    function CustomPagination() {

        return (
            <Pagination
                color="primary"
                shape="circular"
                page={pickup && pickup.meta && pickup.meta.current_page}
                count={pickup && pickup.meta && pickup.meta.last_page}
                onChange={onChangePagination}
            />
        );
    }
    useEffect(() => {
        dispatch(fetchPickupDetails(meta))
    }, [meta]);
   
    return (
        <>
            {(pickupCreate && pickupCreate.notification && pickupCreate.notification.show) &&
                <Notification
                    is_open={pickupCreate.notification.show}
                    messageType={pickupCreate.notification.messageType}
                    messages={pickupCreate.notification.messageContent}
                    handleClose={() => dispatch(resetPickupNotifications())}
                />}
            <Printer isPrinted={isPrinted} setIsPrinted={setIsPrinted} print_url={printUrl} />
            <div className="scmaincard">
                <div className="sccardhead">
                    <Typography variant="h5" gutterBottom component="div">
                        Pickup
                    </Typography>
                    <ul className="features-list">
                        <li>
                            <Button variant="outlined" onClick={openPickupDrawer}>
                                <LocalMallOutlinedIcon style={{ paddingRight: '10px' }} /> Create Pickup Request
                            </Button>
                        </li>
                    </ul>
                </div>

            </div>

            <div style={{ width: '100%', background: '#fff', marginTop: "20px" }}>
                <DataGrid
                    style={{ paddingTop: "37px" }}
                    getRowId={(row) => row._id}
                    rows={pickup && pickup.data}
                    columns={columns}
                    autoHeight={true}
                    disableColumnFilter={true}
                    disableColumnMenu={true}
                    sortingMode="server"
                    onSortModelChange={(newSortModel) => onSortChange(newSortModel)}
                    sortModel={meta && meta.sorting && JSON.parse(meta.sorting)}
                    disableSelectionOnClick
                    slots={{
                        pagination: CustomPagination,
                        toolbar: CustomToolbar,
                        NorowsData,
                        loadingOverlay: LinearProgress,
                    }}
                    loading={
                        (pickup && pickup.loading)
                    }
                />
            </div>
            <PickupDrawer
                visible={pickupDrawerVisible} pickupOnClose={pickupOnClose}
            />
            <PickupItemsDrawer
                pickupItems={pickupItems} visible={pickupItemDrawerVisible} pickupReferenceId={pickupReferenceId} pickupItemsOnClose={pickupItemsOnClose}
            />
        </>
    );
}

export default PickupPage;
