import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Mui Libraries
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import LinearProgress from '@mui/material/LinearProgress';
import Notification from '../../../pages/General/Notification'

// Components
import Nonintegrated from './NonintegratedPage'
import Integrated from './IntegratedPage'


const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    border: "1px solid rgba(224, 224, 224, 1)",
    boxShadow: "unset"
}));

const IntegrationPage = ({ }) => {
    const integrations = useSelector(state => state.integrations);
    const channelconfig = useSelector(state => state.email && state.email.channelconfig);
   
    return (
        <>
            {(channelconfig && channelconfig.notification && channelconfig.notification.showMessage) &&
                <Notification
                    is_open={channelconfig.notification.showMessage}
                    messageType={channelconfig.notification.messageType}
                    messages={channelconfig.notification.messageContent}
                    handleClose={""}
                />}
            <div className="scmaincard">
                <div className="integration--stores">
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <Item style={{ height: '100%' }}>
                                    <div className="integration--stores--main">
                                        <div className="integration--stores--head">
                                            <div className="integration--stores--title" style={{ width: '100%' }}>
                                                <h2 style={{ textAlign: 'left' }}>Notifications</h2>
                                                {integrations && integrations.platform.loading === true &&
                                                    <Box sx={{ width: '100%' }}>
                                                        <LinearProgress />
                                                    </Box>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <Nonintegrated />
                                </Item>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <Item style={{ height: '100%' }}>
                                    <Integrated />
                                </Item>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </div>
        </>
    )
}
export default IntegrationPage;

