import React, { useState, useEffect } from "react";
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';

const ShipmentUpdateFlag = ({ updateFlag = false, onCreateFlag = false, onCloseFlag = true, updateCallBack = null, message = null }) => {

    const handleChange = (event, type) => {
        let updateValue = {
            "is_update_shipment": updateFlag,
            "is_update_shipment_on_create": onCreateFlag,
            "is_update_shipment_on_close": onCloseFlag
        }
        if (type === "switch") {
            updateValue.is_update_shipment = event.target.checked
        }else if (type === "radio") {
            updateValue.is_update_shipment_on_create = (event.target.value === "create_shipment") ? true : false;
            updateValue.is_update_shipment_on_close = (event.target.value === "manifest_close") ? true : false;
        }
        updateCallBack(updateValue)
    };

    return (
        <>
            <div
                className="sc--form--center"
                style={{ margin: 'auto', textAlign: 'center', width: '70%' }}
            >
                <FormGroup style={{ width: '40%', margin: '15px', textAlign: 'left' }}>
                    <FormControlLabel
                        control={<Switch checked={updateFlag}
                            name="is_update_shipment"
                            onChange={event => handleChange(event, "switch")}
                        />
                        }
                        style={{ marginLeft: 'unset' }}
                        labelPlacement="start"
                        label={<Typography style={{ fontSize: '12px' }}>{message}</Typography>}
                    />
                    <FormLabel id="demo-controlled-radio-buttons-group">{<Typography style={{ fontSize: '12px', marginTop: '20px' }}>Event on</Typography>}</FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={(onCreateFlag === true) ? 'create_shipment' : 'manifest_close'}
                        onChange={event => handleChange(event, "radio")}
                    >
                        <FormControlLabel value="create_shipment" disabled={!updateFlag} control={<Radio size="small" />} label={<Typography style={{ fontSize: '12px' }}>Create shipment</Typography>} />
                        <FormControlLabel value="manifest_close" disabled={!updateFlag} control={<Radio size="small" />} label={<Typography style={{ fontSize: '12px' }}>Manifest close</Typography>} />
                    </RadioGroup>
                </FormGroup>
            </div>
        </>
    )
}

export default ShipmentUpdateFlag;