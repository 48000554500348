import { createSlice } from '@reduxjs/toolkit';
import { OMSAPI } from '../../../../../API';

export const klaviyoslice = createSlice({
    name: 'klaviyo',
    initialState: {
        progress: {
            data: [],
            loading: false,
            hasErrors: false,
            notification: { show: false, messageContent: "", messageType: "success" }
        },
        authorization: {
            message: null,
            loading: false,
            hasErrors: false,
            notification: { show: false, messageContent: "", messageType: "success" }
        },
        configuration: {
            message: null,
            loading: false,
            hasErrors: false,
            notification: { show: false, messageContent: "", messageType: "success" }
        },
        events:{
            data: [],
            loading: false,
            hasErrors: false,
        }
    },
    reducers: {
        getKlaviyoProgress: (state) => {
            return {
                ...state,
                progress: {
                    ...state.progress,
                    data:[],
                    loading: true
                }
            }
        },
        getKlaviyoProgressSuccess: (state, action) => {
           return {
                ...state,
                progress: {
                    ...state.progress,
                    data:action && action.payload && action.payload.data,
                    loading: false
                }
            }
        },
        getKlaviyoProgressFailure: (state, action) => {
            return {
                ...state,
                progress: {
                    ...state.progress,
                    loading: false,
                    hasErrors: false,
                }
            }
        },
        postKlaviyoAuthorize: (state) => {
            return {
                ...state,
                authorization: {
                    ...state.authorization,
                    data:[],
                    loading: true
                }
            }
        },
        postKlaviyoAuthorizeSuccess: (state, action) => {
           return {
                ...state,
                authorization: {
                    ...state.authorization,
                    notification: { show: true, messageContent: action.payload && action.payload.data && action.payload.data.message  && action.payload.data.message ? ["Authorized Successfully"] : [""], messageType: "success" },
                    loading: false
                }
            }
        },
        postKlaviyoAuthorizeFailure: (state, action) => {
            return {
                ...state,
                authorization: {
                    ...state.authorization,
                    loading: false,
                    hasErrors: false,
                }
            }
        },
        getKlaviyoConfigure: (state) => {
            return {
                ...state,
                configuration: {
                    ...state.configuration,
                    data:[],
                    loading: true
                }
            }
        },
        getKlaviyoConfigureSuccess: (state, action) => {
           return {
                ...state,
                configuration: {
                    ...state.configuration,
                    data:action && action.payload && action.payload.configuration,
                    notification: { show: true, messageContent: action.payload && action.payload.data && action.payload.data.message  && action.payload.data.message ? ["Configured Successfully"] : [""], messageType: "success" },
                    loading: false
                }
            }
        },
        getKlaviyoConfigureFailure: (state, action) => {
            return {
                ...state,
                configuration: {
                    ...state.configuration,
                    loading: false,
                    hasErrors: false,
                }
            }
        },
        getEvents: (state) => {
            return {
                ...state,
                events: {
                    ...state.events,
                    data:[],
                    loading: true
                }
            }
        },
        getEventsSuccess: (state, action) => {
           return {
                ...state,
                events: {
                    ...state.events,
                    data:action && action.payload && action.payload.data,
                    loading: false
                }
            }
        },
        getEventsFailure: (state, action) => {
            return {
                ...state,
                events: {
                    ...state.events,
                    loading: false,
                    hasErrors: false,
                }
            }
        },
        resetConfigureStateDetails: (state, action) => {
            return {
                ...state,
                authorization: {
                    ...state.authorization,
                    notification: { show: false, messageContent: "", messageType: "success" }
                },
                configuration: {
                    ...state.configuration,
                    notification: { show: false, messageContent: "", messageType: "success" }
                }
            }
        }
    }
})
export const { getKlaviyoProgress, getKlaviyoProgressSuccess, getKlaviyoProgressFailure, postKlaviyoAuthorize,postKlaviyoAuthorizeSuccess,
    postKlaviyoAuthorizeFailure,getKlaviyoConfigure,getKlaviyoConfigureSuccess,getKlaviyoConfigureFailure,getEvents,
    getEventsSuccess,getEventsFailure,resetConfigureStateDetails } = klaviyoslice.actions;


export const fetchKlaviyoProgress = () => {
    return (dispatch) => {
        dispatch(getKlaviyoProgress());
        try {
            OMSAPI
                .get('/klaviyo/progress')
                .then(function (response) {
                    dispatch(getKlaviyoProgressSuccess(response));
                })
                .catch(function (error) {
                    dispatch(getKlaviyoProgressFailure(error.response));
                });
        } catch (error) {
            dispatch(getKlaviyoProgressFailure());
        }
    };
};

export const storeKlaviyoAuthorize = (data) => {
    return (dispatch) => {
        dispatch(postKlaviyoAuthorize());
        try {
            OMSAPI
                .post('/klaviyo/authorize',data)
                .then(function (response) {
                    dispatch(postKlaviyoAuthorizeSuccess(response));
                    dispatch(fetchKlaviyoProgress());
                })
                .catch(function (error) {
                    dispatch(postKlaviyoAuthorizeFailure(error.response));
                });
        } catch (error) {
            dispatch(postKlaviyoAuthorizeFailure());
        }
    };
};

export const fetchKlaviyoConfigure = (data) => {
    return (dispatch) => {
        dispatch(getKlaviyoConfigure());
        try {
            OMSAPI
                .post('/klaviyo/configure',data)
                .then(function (response) {
                    dispatch(getKlaviyoConfigureSuccess(response));
                    dispatch(fetchKlaviyoProgress());
                })
                .catch(function (error) {
                    dispatch(postKlaviyoAuthorizeFailure(error.response));
                });
        } catch (error) {
            dispatch(getKlaviyoConfigureFailure());
        }
    };
};

export const fetchEvents = () => {
    return (dispatch) => {
        dispatch(getEvents());
        try {
            OMSAPI
                .get('/events')
                .then(function (response) {
                    dispatch(getEventsSuccess(response));
                })
                .catch(function (error) {
                    dispatch(getEventsFailure(error.response));
                });
        } catch (error) {
            dispatch(getEventsFailure());
        }
    };
};
export const resetConfigureState = data => dispatch => {
    dispatch(resetConfigureStateDetails());
}
export default klaviyoslice.reducer;
