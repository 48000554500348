import React from "react";
import _ from 'lodash';

const Device = ({ source = "", size = "30px" }) => {
    let device = "laptop";
    if (!_.isUndefined(source.isTablet) && source.isTablet) {
        device = "tablet";
    } else if (!_.isUndefined(source.isMobile) && source.isMobile) {
        device = "mobile";
    } else if (!_.isUndefined(source.isDesktop) && source.isDesktop) {
        device = "monitor";
    } else if (!_.isUndefined(source.isBot) && source.isBot) {
        device = "bot";
    }
    return (
        <>
            {!device && <div> - </div>}
            {device && <div><em className={"icon ni ni-" + device} style={{fontSize: size}}></em></div>}
        </>
    );
};

export default Device;