import { createSlice } from '@reduxjs/toolkit';
import { OMSAPI } from '../../../API';
import { LABELGENAPI, LABELINGCHIMP_SAM_API, LABELINGCHIMP_LABEL_API } from '../../../API';
import axios from 'axios';

/* canceling previous requests if multiple requests is triggered */
const CancelToken = axios.CancelToken;
let source = CancelToken.source();

export const rateslice = createSlice({
    name: 'rate',
    initialState: {
        data: [],
        meta: [],        
        loading: false,
        notification: { show: false, messageContent: "", messageType: "success" }
    },
    reducers: {
        getRate: (state) => {
            return {
                ...state,
                loading: true,
                // hasErrors: false,
                data: [],
            }
        },
        getRateSuccess: (state, action) => {
            return {
                ...state,
                loading: false,
                // hasErrors: false,
                data: action && action.payload && action.payload.data && action.payload.data.message && action.payload.data.message.rates
                
            }
        },
        getRateFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                notification: { show: true, messageContent: (action && action.payload && action.payload.data && action.payload.data.message && typeof action.payload.data.message == "object") ? [action.payload.data.message] : (action.payload.data && action.payload.data.errors && action.payload.data.errors.length != 0 && action.payload.data.errors[0] ) ? action.payload.data.errors : [action.payload.data.message], messageType: "error" },
           
            }
        },
        resetRate: (state) => {
            return {
                ...state,
                loading: false,
                notification: { show: false, messageContent: "", messageType: "success" },
                data:[]
            }
        },



    }
})
export const { getRate, getRateSuccess, getRateFailure,resetRate } = rateslice.actions;

export const doGetRate = (data,apikey) => dispatch => {
    const qs = Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
    dispatch(getRate());
    try {
        LABELINGCHIMP_SAM_API.get('/rates?' + qs,{
        crossDomain: true,
            headers: {
                "x-api-key": apikey
            }
        }).then(function (response) {
                dispatch(getRateSuccess(response));
            })
            .catch(function (error) {
                dispatch(getRateFailure(error.response));
                // dispatch(resetNotification());
            });
    } catch (error) {
        dispatch(getRateFailure(error.response));
        // dispatch(resetNotification());
    }
};
export const resetRateState = data => dispatch => {    
    dispatch(resetRate());
}



export default rateslice.reducer;
