import { createSlice } from '@reduxjs/toolkit';
import { LABELGENAPI } from '../../API';
import axios from 'axios';

/* canceling previous requests if multiple requests is triggered */
const CancelToken = axios.CancelToken;
let source = CancelToken.source();

export const manifestdetailslice = createSlice({
    name: 'manifestdetail',
    initialState: {
        manifestDetail:{
            loading: false,
            data: {
                groupedData: []
            },
            notification: { show: false, messageContent: "", messageType: "success" }
        },
        shipmentDetail:{
            loading: false,
            notification: { show: false, messageContent: "", messageType: "success" }
        }
    },
    reducers: {
        getManifestDetail: (state) => {
            return {
                ...state,
                manifestDetail: {
                    ...state.manifestDetail,
                    loading: true
                }
            }
        },
        getManifestDetailSuccess: (state, action) => {
            return {
                ...state,            
                manifestDetail: {
                    ...state.manifestDetail,
                    loading: false,
                    data: action && action.payload && action.payload.data && action.payload.data.data
                }
            }
        },
        getManifestDetailFailure: (state, action) => {
            return {
                ...state,            
                manifestDetail: {
                    ...state.manifestDetail,
                    loading: false,
                    notification: { show: true, messageContent: action.payload && action.payload.data && action.payload.data.data && [action.payload.data.data.message], messageType: "error" }
                }
            }
        },
        resetNotification:(state, action) => {
            return {
                ...state,
                manifestDetail:{
                    ...state.manifestDetail,
                    notification: { show: false, messageContent: "", messageType: "success" }
                },
                shipmentDetail:{
                    ...state.shipmentDetail,
                    notification: { show: false, messageContent: "", messageType: "success" }
                }
            }
        }
    }
})

export const { getManifestDetail, getManifestDetailSuccess, getManifestDetailFailure } = manifestdetailslice.actions;

export const fetchManifestDetail = (id) => {
    return dispatch => {
        dispatch(getManifestDetail());
        try {
            LABELGENAPI
                .get('/manifest-detail/' + id)
                .then(function (response) {
                    dispatch(getManifestDetailSuccess(response));
                })
                .catch(function (error) {
                    dispatch(getManifestDetailFailure(error.response));
                });
        } catch (error) {
            dispatch(getManifestDetailFailure());
        }
    };
}

export const { resetNotification } = manifestdetailslice.actions;

export const resetManifestDetailNotifications = () => {
    return (dispatch) => {
        dispatch(resetNotification());
    }
}

export default manifestdetailslice.reducer;
