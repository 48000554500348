import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import { DataGrid } from '@mui/x-data-grid';
import LinearProgress from '@mui/material/LinearProgress';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';
import { fetchIntegratedPlatform } from '../../../../../pages/Integration/Marketplace/integratedSlice';
import Notification from "../../../../../pages/General/Notification";

import { fetchKlaviyoConfigure, storeKlaviyoAuthorize, fetchKlaviyoProgress, fetchEvents, resetConfigureState } from './klaviyoSlice';
const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    height: '100%',
    padding: '20px 0'
}));
const KlaviyoIntegration = ({ storeName, platformName, storeParam, storeId }) => {

    const dispatch = useDispatch();
    const integrate = useSelector(state => state.integrated && state.integrated);
    const events = useSelector(state => state.klaviyo && state.klaviyo.events);
    const progress = useSelector(state => state.klaviyo && state.klaviyo.progress);
    const authorization = useSelector(state => state.klaviyo && state.klaviyo.authorization);
    const configuration = useSelector(state => state.klaviyo && state.klaviyo.configuration);

    const initialConfigureValues = {
        "attempted.enable": false,
        "damage.enable": false,
        "delivered.enable": false,
        "dispatched.enable": false,
        "exception.enable": false,
        "intransit.enable": false,
        "lost.enable": false,
        "orderreceived.enable": false,
        "outfordelivery.enable": false,
        "pickedup.enable": false,
        "returns.enable": false,
        "shipmentonhold.enable": false
    }
    const [configureValues, setConfigureValues] = useState(initialConfigureValues);

    const initialValues = {
        "public_api_key": ""
    }
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [platform, setPlatform] = useState("all");
    const [checkAdd, setCheckAdd] = useState(false);
    const [configureStatus, setConfigureStatus] = useState(false);
    const [selectedApiKey, setSelectedApiKey] = useState({});
    const [eventSet, seteventSet] = useState(false);
    const [pageSize, setPageSize] = useState(5);

    const validator = (fieldValues) => {
        let temp = { ...errors }
        if ('public_api_key' in fieldValues)
            temp.public_api_key = fieldValues.public_api_key ? "" : "This field is required."

        setErrors({
            ...temp
        })

        if (fieldValues === values)
            return Object.values(temp).every(x => x == "")
    }

    const onPlatformInputChange = e => {
        setPlatform(e.target.value)
    }

    const onInputChange = e => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
        validator({ [name]: value })
    }
    const handleSubmit = e => {
        e.preventDefault()
        const isValid = validator(values)
        if (isValid) {
            let data = ''
            data = values
            dispatch(storeKlaviyoAuthorize(data));
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setConfigureValues({
            ...configureValues,
            [name]: e.target.checked,
        });
    };

    const onEventConfigure = () => {
        let data = { 'public_api_key': values.public_api_key, "stores_assigned": platform }
        Object.assign(data, configureValues)
        dispatch(fetchKlaviyoConfigure(data));
    }

    const keyColumns = [
        {
            field: 'public_api_key',
            headerName: 'API Key',
            flex: 1
        },
        {
            field: 'stores',
            headerName: 'Stores',
            flex: 1,
            renderCell: (params) => {
                return (
                    <p>{params && params.row && params.row.stores && params.row.stores.length != 0 ? params.row.stores.store_name : '-'}</p>
                )
            }
        },
        {
            field: 'Action',
            headerName: 'Action',
            flex: 1,
            renderCell: (params) => {
                return (
                    <Chip label="Configure" variant="outlined" onClick={() => {
                        setPlatform(params.row.stores_assigned ? params.row.stores_assigned : "all")
                        setConfigureStatus(true)
                        seteventSet(false)
                        dispatch(fetchEvents());
                        setValues({
                            ...values,
                            ["public_api_key"]: params.row.public_api_key,
                        })
                        let output = progress && progress.data && progress.data.data && progress.data.data.filter(({ _id }) => [params.row._id].includes(_id));
                        let finaloutput = Object.assign({}, output[0]);
                        setSelectedApiKey(finaloutput)
                    }} />
                )
            }
        }
    ]

    const columns = [
        { field: 'name', headerName: 'Create Track Event Activity for Disputes / Shipment Events', flex: 1 },
        // { field: 'notification_type', headerName: 'Email', flex: 1 },
        {
            field: 'Enable',
            headerName: 'Enable',
            flex: 1,
            renderCell: (params) => {
                let keyname = params.row.key;
                if (eventSet) {
                    return (
                        <Switch
                            defaultChecked={configureValues && configureValues[keyname + ".enable"] ? configureValues[keyname + ".enable"] : false}
                            name={params.row.key + ".enable"}
                            onChange={handleChange}
                        />
                    )
                } else {
                    return (
                        <Switch
                            defaultChecked={false}
                            name={params.row.key + ".enable"}
                            onChange={handleChange}
                        />
                    )
                }
            }
        }
    ]
    useEffect(() => {
        dispatch(fetchEvents());
        dispatch(fetchKlaviyoProgress());
    }, [dispatch]);
    useEffect(() => {
        dispatch(fetchIntegratedPlatform())
    }, [dispatch])

    useEffect(() => {
        setConfigureValues(initialConfigureValues)
        if (selectedApiKey && selectedApiKey._id) {
            seteventSet(true)
            let tempValues = {
                "attempted.enable": selectedApiKey && selectedApiKey.attempted && selectedApiKey.attempted.enable ? selectedApiKey.attempted.enable : false,
                "damage.enable": selectedApiKey && selectedApiKey.damage && selectedApiKey.damage.enable ? selectedApiKey.damage.enable : false,
                "delivered.enable": selectedApiKey && selectedApiKey.delivered && selectedApiKey.delivered.enable ? selectedApiKey.delivered.enable : false,
                "dispatched.enable": selectedApiKey && selectedApiKey.dispatched && selectedApiKey.dispatched.enable ? selectedApiKey.dispatched.enable : false,
                "exception.enable": selectedApiKey && selectedApiKey.exception && selectedApiKey.exception.enable ? selectedApiKey.exception.enable : false,
                "intransit.enable": selectedApiKey && selectedApiKey.intransit && selectedApiKey.intransit.enable ? selectedApiKey.intransit.enable : false,
                "lost.enable": selectedApiKey && selectedApiKey.lost && selectedApiKey.lost.enable ? selectedApiKey.lost.enable : false,
                "orderreceived.enable": selectedApiKey && selectedApiKey.orderreceived && selectedApiKey.orderreceived.enable ? selectedApiKey.orderreceived.enable : false,
                "outfordelivery.enable": selectedApiKey && selectedApiKey.outfordelivery && selectedApiKey.outfordelivery.enable ? selectedApiKey.outfordelivery.enable : false,
                "pickedup.enable": selectedApiKey && selectedApiKey.pickedup && selectedApiKey.pickedup.enable ? selectedApiKey.pickedup.enable : false,
                "returns.enable": selectedApiKey && selectedApiKey.returns && selectedApiKey.returns.enable ? selectedApiKey.returns.enable : false,
                "shipmentonhold.enable": selectedApiKey && selectedApiKey.shipmentonhold && selectedApiKey.shipmentonhold.enable ? selectedApiKey.shipmentonhold.enable : false
            };
            setConfigureValues(tempValues)
        }
    }, [selectedApiKey])


    useEffect(() => {
        if (authorization && authorization.notification && authorization.notification.messageType && authorization.notification.messageType == "success") {
            setCheckAdd(false)
        }
    }, [authorization])
    return (
        <>
            {authorization &&
                authorization.notification &&
                authorization.notification.show && (
                    <Notification
                        is_open={authorization.notification.show}
                        messageType={authorization.notification.messageType}
                        messages={authorization.notification.messageContent}
                        handleClose={() => dispatch(resetConfigureState())}
                    />
                )}
            {configuration &&
                configuration.notification &&
                configuration.notification.show && (
                    <Notification
                        is_open={configuration.notification.show}
                        messageType={configuration.notification.messageType}
                        messages={configuration.notification.messageContent}
                        handleClose={() => dispatch(resetConfigureState())}
                    />
                )}
            <Grid container spacing={1} style={{ alignItems: 'center' }}>
                <Grid item xs={12} md={6}>
                    <Card className="sc--cardhead--details">
                        <div style={{ width: '100%', background: '#fff' }}>
                            <CardHeader title="Authorize" action={
                                <div>
                                    {checkAdd == false &&
                                        <Button variant="contained" onClick={() => {
                                            setCheckAdd(true)
                                            setConfigureStatus(false)
                                            setValues({
                                                ...values,
                                                ["public_api_key"]: "",
                                            })
                                        }}>Add Key</Button>
                                    }
                                    {checkAdd == true &&
                                        <Button variant="contained" onClick={() => {
                                            setCheckAdd(false)
                                        }}>View Keys</Button>
                                    }
                                </div>} />
                            {(authorization && authorization.loading === true) &&
                                <LinearProgress />
                            }
                            <Divider />
                            {(checkAdd == true || progress && progress.data && progress.data.data && progress.data.data.length == 0) &&
                                <div style={{ margin: 'auto', width: '60%' }}>
                                    <div
                                        className="sc--form--center"
                                        style={{ margin: '40px 0' }}>
                                        <TextField
                                            label="Public API Key"
                                            name="public_api_key"
                                            value={values.public_api_key}
                                            onChange={onInputChange}
                                            error={errors.public_api_key}
                                            helperText={errors.public_api_key}
                                            style={{ width: "100%", margin: '8px' }}
                                            variant="standard"
                                        />
                                    </div>
                                    <div className="sc--form--center" style={{ margin: '40px 0' }}>
                                        <Button variant="outlined" onClick={handleSubmit}>Save</Button>
                                    </div>
                                </div>
                            }
                        </div>
                        {(checkAdd == false && progress && progress.data && progress.data.data && progress.data.data.length !== 0) &&
                            <div style={{ maxWidth: '100%' }}>
                                <DataGrid
                                    getRowId={(row) => row._id}
                                    rows={progress && progress.data && progress.data.data}
                                    columns={keyColumns}
                                    autoHeight={true}
                                    slots={{
                                        loadingOverlay: LinearProgress,
                                    }}
                                    loading={progress && progress.loading}
                                    pageSize={pageSize}
                                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                    rowsPerPageOptions={[5, 10, 20]}
                                    pagination
                                />
                            </div>
                        }
                    </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                    <img className="" src="/assets/images/integration/klaviyo/klaviyo_step1a.jpg" style={{ width: '100%', height: 'auto' }} alt="klaviyo configure" />
                </Grid>
            </Grid>
            {configureStatus &&
                <Card sx={{ marginBottom: '30px' }} className="sc--cardhead--details">
                    <div style={{ width: '100%', background: '#fff' }}>
                        <CardHeader title="Configure Notification" />
                        <Divider />
                        <p style={{ textAlign: "center" }}>Current API Key:{values && values.public_api_key}</p>
                        <div style={{ margin: "15px 0", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <FormControl variant="standard" className="help-text-error" style={{ width: "50%", margin: '8px' }} >
                                <InputLabel id="demo-simple-select-standard-label">Select Platform</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-error"
                                    name="platform"
                                    value={platform}
                                    onChange={onPlatformInputChange}
                                    label="platform"
                                >
                                    <MenuItem value={'all'}>All</MenuItem>
                                    {integrate && integrate.data && integrate.data.map((key) => {
                                        return <MenuItem value={key._id}>{key.platform} {key.store_name}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                        <div style={{ maxWidth: '100%' }}>
                            <DataGrid
                                getRowId={(row) => row._id}
                                rows={events && events.data}
                                columns={columns}
                                autoHeight={true}
                                components={{
                                    LoadingOverlay: LinearProgress,
                                }}
                                loading={(events && events.loading || progress && progress.loading)}
                            />
                        </div>
                        <div style={{ textAlign: "center", margin: "15px 0" }}>
                            <Button variant="contained" onClick={onEventConfigure}>Configure</Button>
                        </div>
                    </div>
                </Card>
            }
        </>

    )
}


export default KlaviyoIntegration;
