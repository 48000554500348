import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';

const Search = ({ visible, meta, setMeta }) => {
    const [formValues, setFormValues] = useState(meta)
    const [searchButtonDisabled, setSearchButtonDisabled] = useState(true)
    const [referenceidError, setReferenceidError] = useState("")
    const [errors, setErrors] = useState({});

    const validator = (fieldValues) => {
		let temp = { ...errors }
        if(/\`|\~|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\.|\>|\?|\/|\""|\;|\:|\-|\s/g.test(fieldValues && fieldValues.reference_id)){
			temp.reference_id = "Please enter valid reference id"
        }
        else{
            temp.reference_id=""
        }
		setErrors({
			...temp
		})

		if (fieldValues == formValues)
			return Object.values(temp).every(x => x == "")
	}
    const onInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
            [`page`]: 1
        });
        validator({ [name]: value })
    };
    
    const checkFormHasValues = () => {
        let disableButton = true
        if (formValues.reference_id !== "" && Object.keys(formValues).length!==0){
            disableButton = false
        }
        return disableButton
    }

    const handleKeyUp = (event) => {
        if (event.keyCode === 13) {
            const isValid = validator(formValues)
		if (isValid) {
            setMeta(prevState => ({
                ...prevState,
                [`reference_id`]: formValues["reference_id"],
            }));
        }
        }
    }

    const submitSearchValues = (e) => {
        e.preventDefault()
		const isValid = validator(formValues)
		if (isValid) {
            setMeta(prevState => ({
                ...prevState,
                [`reference_id`]: formValues["reference_id"],
            }));
        }
    }

    const resetFields = () => {
        setFormValues({
            ...formValues,
            [`reference_id`]: "",
        });
        setMeta(prevState => ({
            ...prevState,
            [`reference_id`]: "",
            [`page`]: 1,
        }));
    }

    const onInputClear = (name) => {
        setErrors({ name: "" })
        setFormValues({
            ...formValues,
            [name]: "",
        });
        setMeta(prevState => ({
            ...prevState,
            [name]: ""
        }));
    };

    useEffect(() => {
        setSearchButtonDisabled(checkFormHasValues())
    }, [formValues])

    return (
        <>
            {visible &&
                <div className="filtercontainer">
                    <div className="filteraccordion" onKeyUp={handleKeyUp}>
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { m: 1, width: '25ch' },
                            }}

                            noValidate
                            autoComplete="off"
                            onSubmit={e => { e.preventDefault(); }}
                        >
                            <TextField
                                value={formValues && formValues.reference_id}
                                name={"reference_id"}
                                label="Reference Id"
                                size={'small'}
                                onChange={onInputChange}
                                InputProps={{
                                    endAdornment: (formValues && formValues.reference_id) ? (
                                        <IconButton size="small" onClick={() => onInputClear("reference_id")}>
                                            <ClearIcon />
                                        </IconButton>
                                    ) : undefined
                                }}
                                variant="standard"
                                error={errors.reference_id}
                                helperText={errors.reference_id}
                            />
                        </Box>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={12}>
                                <div className="filterfooter">
                                    <Button variant="contained" onClick={submitSearchValues} disabled={searchButtonDisabled}>Search</Button>
                                    <Button variant="outlined" onClick={resetFields} disabled={searchButtonDisabled}>Clear</Button>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            }
        </>
    )

}

export default Search;
