import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';

const Search = ({ visible, meta, setMeta }) => {
    const [searchButtonDisabled, setSearchButtonDisabled] = useState(true)
    const onInputChange = (e) => {
        const { name, value } = e.target;
        setMeta({
            ...meta,
            [name]: value,
            [`page`]: 1
        });
    };

    const checkFormHasValues = () => {
        let disableButton = true
        if (meta["sku"] != "" || meta["tracking_number"] != "" || meta["customer_name"] != "" || meta["order_id"] != "" 
            || meta["goods_out_note"] != "" || meta["reference_no"] != "" || meta["receiver_province"] != "" || meta["receiver_country_code"] != "") {
            disableButton = false
        }

        return disableButton
    }

    const handleKeyUp = (event) => {
        if (event.keyCode === 13) {
            submitSearchValues();
        }
    }

    const submitSearchValues = () => {
        setMeta(prevState => ({
            ...prevState,
            [`sku`]: meta["sku"],
            [`order_id`]: meta["order_id"],
            [`tracking_number`]: meta["tracking_number"],
            [`customer_name`]: meta["customer_name"],
            [`goods_out_note`]: meta["goods_out_note"],
            [`reference_no`]: meta["reference_no"],
            [`receiver_province`]: meta["receiver_province"],
            [`receiver_country_code`]: meta["receiver_country_code"],
            [`page`]: 1
        }));
    }

    const resetFields = () => {
        setMeta(prevState => ({
            ...prevState,
            [`sku`]: "",
            [`order_id`]: "",
            [`tracking_number`]: "",
            [`customer_name`]: "",
            [`page`]: 1,
            [`reference_no`]: "",
            [`goods_out_note`]: "",
            [`receiver_province`]: "",
            [`receiver_country_code`]: ""
        }));
    }

    const onInputClear = (name) => {
        setMeta(prevState => ({
            ...prevState,
            [name]: ""
        }));
    };

    useEffect(() => {
        setSearchButtonDisabled(checkFormHasValues())
    }, [meta])


    return (
        <>
            {visible &&
                <div className="filtercontainer">
                    <div className="filteraccordion" onKeyUp={handleKeyUp}>
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { m: 1, width: '20ch' },
                            }}

                            noValidate
                            autoComplete="off"
                            onSubmit={e => { e.preventDefault(); }}
                        >
                            <TextField
                                value={meta && meta.goods_out_note}
                                name={"goods_out_note"}
                                label="Scan Goods Out Note"
                                size={'small'}
                                onChange={onInputChange}
                                InputProps={{
                                    endAdornment: (meta && meta.goods_out_note) ? (
                                        <IconButton size="small" onClick={() => onInputClear("goods_out_note")}>
                                            <ClearIcon />
                                        </IconButton>
                                    ) : undefined
                                }}
                                variant="standard"
                            />
                            <TextField
                                value={meta && meta.order_id}
                                name={"order_id"}
                                label="Order Number"
                                size={'small'}
                                onChange={onInputChange}
                                InputProps={{
                                    endAdornment: (meta && meta.order_id) ? (
                                        <IconButton size="small" onClick={() => onInputClear("order_id")}>
                                            <ClearIcon />
                                        </IconButton>
                                    ) : undefined
                                }}
                                variant="standard"
                            />
                            <TextField
                                value={meta && meta.tracking_number}
                                name={"tracking_number"}
                                label="Tracking Number"
                                size={'small'}
                                onChange={onInputChange}
                                InputProps={{
                                    endAdornment: (meta && meta.tracking_number) ? (
                                        <IconButton size="small" onClick={() => onInputClear("tracking_number")}>
                                            <ClearIcon />
                                        </IconButton>
                                    ) : undefined
                                }}
                                variant="standard"
                            />
                            <TextField
                                value={meta && meta.customer_name}
                                name={"customer_name"}
                                label="Customer Name"
                                size={'small'}
                                onChange={onInputChange}
                                InputProps={{
                                    endAdornment: (meta && meta.customer_name) ? (
                                        <IconButton size="small" onClick={() => onInputClear("customer_name")}>
                                            <ClearIcon />
                                        </IconButton>
                                    ) : undefined
                                }}
                                variant="standard"
                            />
                            <TextField
                                value={meta && meta.reference_no}
                                name={"reference_no"}
                                label="Reference Number"
                                size={'small'}
                                onChange={onInputChange}
                                InputProps={{
                                    endAdornment: (meta && meta.reference_no) ? (
                                        <IconButton size="small" onClick={() => onInputClear("reference_no")}>
                                            <ClearIcon />
                                        </IconButton>
                                    ) : undefined
                                }}
                                variant="standard"
                            />
                            <TextField
                                value={meta && meta.receiver_province}
                                name={"receiver_province"}
                                label="Receiver Province Code"
                                size={'small'}
                                onChange={onInputChange}
                                InputProps={{
                                    endAdornment: (meta && meta.receiver_province) ? (
                                        <IconButton size="small" onClick={() => onInputClear("receiver_province")}>
                                            <ClearIcon />
                                        </IconButton>
                                    ) : undefined
                                }}
                                variant="standard"
                            />
                            <TextField
                                value={meta && meta.receiver_country_code}
                                name={"receiver_country_code"}
                                label="Receiver Country Code"
                                size={'small'}
                                onChange={onInputChange}
                                InputProps={{
                                    endAdornment: (meta && meta.receiver_country_code) ? (
                                        <IconButton size="small" onClick={() => onInputClear("receiver_country_code")}>
                                            <ClearIcon />
                                        </IconButton>
                                    ) : undefined
                                }}
                                variant="standard"
                            />
                            <TextField
                                value={meta && meta.sku}
                                name={"sku"}
                                label="SKU"
                                size={'small'}
                                onChange={onInputChange}
                                InputProps={{
                                    endAdornment: (meta && meta.sku) ? (
                                        <IconButton size="small" onClick={() => onInputClear("sku")}>
                                            <ClearIcon />
                                        </IconButton>
                                    ) : undefined
                                }}
                                variant="standard"
                            />
                        </Box>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={12}>
                                <div className="filterfooter">
                                    <Button variant="contained" onClick={submitSearchValues} disabled={searchButtonDisabled}>Search</Button>
                                    <Button variant="outlined" onClick={resetFields} disabled={searchButtonDisabled}>Clear</Button>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            }
        </>
    )

}

export default Search;
