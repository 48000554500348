import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// mui components
import {
    Paper,
    Divider,
    styled,
    Grid,
    Box,
    Button,
    Modal,
    Typography,
    TextField,
    Card,
    CardHeader,
    CardContent
} from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper'
};

const AddLineItem = ({ addLineItemVisible, onAddLineItemModalClose, formDataHandler,dispatchCountryCode, onAddLineItemClick, addLineItemData, editLineItemId, errors }) => {
    const country_code = useSelector(state => state.countrystate.country);
    const filteredCountryData = country_code.data.filter(({ code }) => code );
    const onChangeCountry = id => {
        dispatchCountryCode(id)
    }

    const getSelectedCountry = (country_code) => {
        let filteredValue = { code: "", name: "" };
        filteredCountryData.map((key) => {
          if (key.code == country_code) {
            filteredValue = key;
          }
        });
         return filteredValue;
      };
    return (
        <>
            <Modal
                open={addLineItemVisible}
                onClose={onAddLineItemModalClose}
            >
                <Box sx={modalStyle}>
                    <Card sx={{ minWidth: 600 }} className="sc--cardhead--details">
                        <CardHeader title={(editLineItemId != "") ? "Update Line Item" : "New Line Item"} />
                        <Divider />
                        <CardContent>
                            <div className="sc--form--center" style={{ margin: '20px 0' }}>
                                <TextField id="standard-basic"
                                    label="Product Id"
                                    variant="standard"
                                    name="product_id"
                                    value={addLineItemData.product_id}
                                    onChange={formDataHandler}
                                    error={errors.product_id}
                                    helperText={errors.product_id}
                                    style={{ width: "100%", margin: '8px' }}
                                />
                                <TextField id="standard-basic"
                                    label="Product Description"
                                    variant="standard"
                                    name="product_desc"
                                    value={addLineItemData.product_desc}
                                    onChange={formDataHandler}
                                    error={errors.product_desc}
                                    helperText={errors.product_desc}
                                    style={{ width: "100%", margin: '8px' }}
                                />
                            </div>
                            <div className="sc--form--center" style={{ margin: '20px 0' }}>
                                <TextField id="standard-basic"
                                    label="Product Url"
                                    variant="standard"
                                    name="product_url"
                                    value={addLineItemData.product_url}
                                    onChange={formDataHandler}
                                    error={errors.product_url}
                                    helperText={errors.product_url}
                                    style={{ width: "100%", margin: '8px' }}
                                />
                                <TextField id="standard-basic"
                                    label="Unit Price"
                                    variant="standard"
                                    name="product_price"
                                    value={addLineItemData.product_price}
                                    onChange={formDataHandler}
                                    error={errors.product_price}
                                    helperText={errors.product_price}
                                    style={{ width: "100%", margin: '8px' }}
                                />
                            </div>
                            <div className="sc--form--center" style={{ margin: '20px 0' }}>
                                <TextField id="standard-basic"
                                    label="Quantity"
                                    variant="standard"
                                    name="quantity"
                                    value={addLineItemData.quantity}
                                    onChange={formDataHandler}
                                    error={errors.quantity}
                                    helperText={errors.quantity}
                                    style={{ width: "100%", margin: '8px' }}
                                />
                                <Autocomplete
                                    className="help-text-error"
                                    disablePortal
                                    name="origin_country_code"
                                    id="country-select-demo"
                                    sx={{ width: "100%", margin: '8px'}}
                                    options={filteredCountryData}
                                    autoHighlight
                                    loading={country_code && country_code.loading}
                                    getOptionLabel={(option) => option.name}
                                    value={getSelectedCountry(addLineItemData && addLineItemData.origin_country_code)}
                                    renderOption={(props, option) => (
                                    <MenuItem {...props} value={option.code} key={option.code}>
                                        {option.icon} {option.name}
                                    </MenuItem>
                                    )}
                                    onChange={(event, value) => {
                                    if (value !== null) {
                                        onChangeCountry(value.code);
                                    } else {
                                        onChangeCountry("");
                                    }
                                    }}
                                    renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Country of Origin"
                                        variant="standard"
                                        helperText={errors.origin_country_code}
                                    />
                                    )}
                                />
                            </div>
                            <div className="sc--form--center" style={{ margin: '20px 0' }}>
                                <TextField id="standard-basic"
                                    label="Hs Code"
                                    variant="standard"
                                    name="hs_tariff_code"
                                    value={addLineItemData.hs_tariff_code}
                                    onChange={formDataHandler}
                                    error={errors.hs_tariff_code}
                                    helperText={errors.hs_tariff_code}
                                    style={{ width: "100%", margin: '8px' }}
                                />
                                <TextField id="standard-basic"
                                    label="Hs Country Code"
                                    variant="standard"
                                    name="hs_tariff_code_country"
                                    value={addLineItemData.hs_tariff_code_country}
                                    onChange={formDataHandler}
                                    error={errors.hs_tariff_code_country}
                                    helperText={errors.hs_tariff_code_country}
                                    style={{ width: "100%", margin: '8px' }}
                                />
                            </div>
                            <div className="sc--form--center" style={{ marginTop: "15px" }}>
                                <Button
                                    variant="outlined"
                                    onClick={onAddLineItemClick}
                                    style={{ border: '1px solid rgba(0, 0, 0, 0.12)', margin: '0 15px' }}
                                >
                                    {(editLineItemId != "") ? "Update Item" : "Add Item"}
                                </Button>
                            </div>
                        </CardContent>
                    </Card>
                </Box>
            </Modal>
        </>
    )
}

export default AddLineItem;