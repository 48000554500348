import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import {
    DataGrid,
    GridToolbarContainer
} from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Drawer from '@mui/material/Drawer';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import { CardContent } from "@mui/material";
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import { Typography } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import { fetchSenderAddress } from '../../pages/Settings/AddressSender/addressSenderSlice';
import NorowsData from '../General/NorowsData'

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    boxShadow: 'unset',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    color: theme.palette.text.secondary,
}));

const PickupItemsDrawer = ({ pickupItems,visible,pickupReferenceId, pickupItemsOnClose }) => {
   
    const pickup = useSelector(state => state.pickup && state.pickup.pickupData);
	const addresssender = useSelector(state => state.addresssender);
	const [senderAddressObjectId, setSenderAddressObjectId] = useState('');
	const [drawer, setDrawer] = useState(true);
    const dispatch = useDispatch();
    const ondrawerclose = () => {
		setDrawer(false)
        pickupItemsOnClose(false)
    }
		useEffect(() => {
			dispatch(fetchSenderAddress());
	}, [dispatch]);

    const columns = [
        {
            field: 'package_dimension',
            headerName: 'Package Dimension',
            headerClassName: 'super-app-theme--header',
            sortable: false,
            flex: 1,
            resizable: false,
            renderCell: (params) => {
                if (params.row.package_dimension) {
                    return params.row.package_dimension
                } else {
                    return "-"
                }
            }
        },
        {
            field: 'dimension_unit',
            headerName: 'Dimension Unit',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            resizable: false,
            renderCell: (params) => {
                if (params.row.dimension_unit) {
                    return params.row.dimension_unit
                } else {
                    return "-"
                }
            }
        },
        {
            field: 'weight',
            headerName: 'Weight',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            resizable: false,
            renderCell: (params) => {
                if (params.row.weight) {
                    return params.row.weight
                } else {
                    return "-"
                }
            }
        },
        {
            field: 'weight_unit',
            headerName: 'Weight Unit',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            resizable: false,
            renderCell: (params) => {
                if (params.row.weight_unit) {
                    return params.row.weight_unit
                } else {
                    return "-"
                }
            }
        },
        {
            field: 'quantity',
            headerName: 'Quantity',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            resizable: false,
            renderCell: (params) => {
                if (params.row.quantity) {
                    return params.row.quantity
                } else {
                    return "-"
                }
            }
        }
    ];
    return (
        <>

            <Drawer
                anchor="right"
                open={visible}
                onClose={ondrawerclose}
                PaperProps={{
                    sx: { width: "60%" },
                }}
            >

                <div style={{ padding: "0 30px" }}>
                    <Card sx={{ margin: '20px 0' }} className="sc--cardhead--details">
                        <CardHeader title={`Pickup Details for ${pickupReferenceId}`} action={
                            <div>
                                <IconButton aria-label="settings" onClick={ondrawerclose}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        } />
                        {/* <LinearProgress /> */}
                        <Divider />
                    </Card>
                    <CardContent>
                    {pickup && pickup.loading==false && Object.keys(pickupItems).length != 0 &&
                         <Grid container spacing={1}>
                                <Grid item xs={6} md={6} style={{margin:'auto',padding:'10px'}}>
                                    <Item>
                                        <div>
                                            <p style={{marginTop:'0px'}}><strong style={{ marginRight: '15px' }}>Address:</strong><Typography style={{ fontSize: '14px' }}>{pickupItems.address.sender_name} ({pickupItems.address.sender_company})<br />
                                                        {pickupItems.address.sender_address}, {pickupItems.address.sender_city}, {pickupItems.address.sender_state_code},<br /> {pickupItems.address.sender_country_code} - {pickupItems.address.sender_postal_code}</Typography></p>
                                        </div>
                                    </Item>
                                </Grid>
                                <Grid item xs={6} md={6} style={{padding:'10px'}}>
                                    <Item>
                                        <div>
                                            <p style={{marginTop:'0px'}}><strong style={{ marginRight: '15px' }}>Email :</strong><Typography style={{ fontSize: '14px' }}>{pickupItems.address.sender_email} </Typography></p>
                                            <p style={{marginTop:'0px',marginBottom:'5px'}}><strong style={{ marginRight: '15px' }}>Phone :</strong><Typography style={{ fontSize: '14px' }}>{pickupItems.address.sender_phone} </Typography></p>
                                        </div>
                                    </Item>
                                </Grid>
                         </Grid>
                        }
                    <div style={{ width: '100%', background: '#fff', marginTop: "20px" }}>
                    <DataGrid
                        getRowId={(row) => Math.random() + Math.random()}
                        rows={pickupItems && pickupItems.items}
                        columns={columns}
                        autoHeight={true}
                        disableColumnFilter={true}
                        disableColumnMenu={true}
                        sortingMode="server"
                        disableRowSelectionOnClick
                        slots={{
                            NorowsData,
                        }}
                        loading={
                            (pickup && pickup.loading)
                        }
                    />
                </div>
                    </CardContent>
                </div>

            </Drawer >
        </>
    )
}


export default PickupItemsDrawer;