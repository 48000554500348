import { createSlice } from '@reduxjs/toolkit';
import { LABELGENAPI, LABELINGCHIMP_SAM_API, LABELINGCHIMP_LABEL_API } from '../../../API';
import axios from 'axios';

/* canceling previous requests if multiple requests is triggered */
const CancelToken = axios.CancelToken;
let source = CancelToken.source();

export const deliveriesmysqlslice = createSlice({
    name: 'deliveriesmysql',
    initialState: {
        deliveriesData: {
            data: [],
            loading: false,
            meta: {
                page: 1,
                per_page: 10,
                start_date: "",
                end_date: "",
                order_id: "",
                shipment_status_category: [],
                receiver_province: "",
                tracking_number: "",
                shippingchimp_manifest_number: "",
                carrier_name: [],
                customer_name: "",
                unclosed_shipment:'false',
                sorting: JSON.stringify([{
                    field: 'shipment_date',
                    sort: 'desc',
                  }])
            },
            notification: { show: false, messageContent: "", messageType: "success" }
        },
        deliveriesColumns: {
            loading: false,
            notification: { show: false, messageContent: "", messageType: "success" }
        },
        shipmentStatusCount: {
            loading: false,
            data: [],
        }
    },
    reducers: {
        getMysqlDeliveries: (state) => {
            return {
                ...state,
                deliveriesData: {
                    ...state.deliveriesData,
                    data: [],
                    loading: true
                }
            }
        },
        getMysqlDeliveriesSuccess: (state, action) => {
            return {
                ...state,
                deliveriesData: {
                    ...state.deliveriesData,
                    data: action && action.payload && action.payload.data && action.payload.data.data,
                    meta: action && action.payload && action.payload.data && action.payload.data.meta,
                    loading: false
                }
            }
        },
        getMysqlDeliveriesFailure: (state, action) => {
            return {
                ...state,
                deliveriesData: {
                    ...state.deliveriesData,
                    loading: false,
                    notification: { show: true, messageContent: action && action.payload && action.payload.data && action.payload.data.message && [action.payload.data.message], messageType: "error" }
                }
            }
        },
        getDeliveriesColumn: (state) => {
            return {
                ...state,
                deliveriesColumns: {
                    ...state.deliveriesColumns,
                    loading: true
                }
            }
        },
        getDeliveriesColumnSuccess: (state, action) => {
            return {
                ...state,
                deliveriesColumns: {
                    ...state.deliveriesColumns,
                    loading: false,
                    columns: action && action.payload && action.payload.data && action.payload.data.data
                }
            }
        },
        getDeliveriesColumnFailure: (state, action) => {
            return {
                ...state,
                deliveriesColumns: {
                    ...state.deliveriesColumns,
                    loading: false,
                    notification: { show: true, messageContent: action && action.payload && action.payload.data && action.payload.data.message && [action.payload.data.message], messageType: "error" }
                }
            }
        },
        postDeliveriesColumn: (state, action) => {
            return {
                ...state,
                deliveriesColumns: {
                    ...state.deliveriesColumns,
                    loading: true
                }
            }
        },
        postDeliveriesColumnSuccess: (state, action) => {
            return {
                ...state,
                deliveriesColumns: {
                    ...state.deliveriesColumns,
                    loading: false,
                    notification: { show: true, messageContent: [action.payload && action.payload.data && action.payload.data.message], messageType: "success" }
                }
            }
        },
        postDeliveriesColumnFailure: (state, action) => {
            return {
                ...state,
                deliveriesColumns: {
                    ...state.deliveriesColumns,
                    loading: false,
                    notification: { show: true, messageContent: [action.payload && action.payload.data && action.payload.data.message], messageType: "error" }
                }
            }
        },
        getShipmentStatusCount: (state, action) => {
            return {
                ...state,
                shipmentStatusCount: {
                    ...state.shipmentStatusCount,
                    loading: true
                }
            }
        },
        getShipmentStatusCountSuccess: (state, action) => {
            return {
                ...state,
                shipmentStatusCount: {
                    ...state.shipmentStatusCount,
                    loading: false,
                    data: action && action.payload && action.payload.data && action.payload.data.data,                }
            }
        },
        getShipmentStatusCountFailure: (state, action) => {
            return {
                ...state,
                shipmentStatusCount: {
                    ...state.shipmentStatusCount,
                    loading: false,
                    data: action && action.payload && action.payload.data && action.payload.data.data,                }
            }
        },
        resetDeliveriessNotification: (state, action) => {
            return {
                ...state,
                deliveriesData: {
                    ...state.deliveriesData,
                    notification: { show: false, messageContent: "", messageType: "success" }
                },
                deliveriesColumns: {
                    ...state.deliveriesColumns,
                    notification: { show: false, messageContent: "", messageType: "success" }
                }
            }
        }


    }
})

export const { getMysqlDeliveries, getMysqlDeliveriesSuccess, getMysqlDeliveriesFailure } = deliveriesmysqlslice.actions;

export const doGetMysqlDeliveries = (meta) => dispatch => {

    const qs = Object.keys(meta)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(meta[key])}`)
        .join('&');

    dispatch(getMysqlDeliveries());
    try {
        source && source.cancel('Operation canceled due to new request.');
        source = axios.CancelToken.source();
        LABELGENAPI.get('/shipment/infos?' + qs, {
            cancelToken: source.token
        }).then(function (response) {
            dispatch(getMysqlDeliveriesSuccess(response));
        })
            .catch(function (error) {
                if (!axios.isCancel(error)) {
                    dispatch(getMysqlDeliveriesFailure(error.response));
                }
            });
    } catch (error) {
        if (!axios.isCancel(error)) {
            dispatch(getMysqlDeliveriesFailure(error.response));
        }
    }
};


export const { getDeliveriesColumn, getDeliveriesColumnSuccess, getDeliveriesColumnFailure } = deliveriesmysqlslice.actions;

export const fetchDeliveriesColumns = () => {
    return (dispatch) => {
        dispatch(getDeliveriesColumn());
        try {
            LABELGENAPI
                .get('/shipment/columns')
                .then(function (response) {
                    dispatch(getDeliveriesColumnSuccess(response));
                })
                .catch(function (error) {
                    dispatch(getDeliveriesColumnFailure(error.response));
                });
        } catch (error) {
            dispatch(getDeliveriesColumnFailure());
        }
    };
};

export const { postDeliveriesColumn, postDeliveriesColumnSuccess, postDeliveriesColumnFailure } = deliveriesmysqlslice.actions;

export const saveDeliveriesColumns = (data) => {
    return (dispatch) => {
        dispatch(postDeliveriesColumn());
        try {
            LABELGENAPI
                .post('/shipment/columns', data)
                .then(function (response) {
                    dispatch(postDeliveriesColumnSuccess(response));
                    dispatch(fetchDeliveriesColumns());
                })
                .catch(function (error) {
                    dispatch(postDeliveriesColumnFailure(error.response));
                });
        } catch (error) {
            dispatch(postDeliveriesColumnFailure());
        }
    };
};

export const { getShipmentStatusCount, getShipmentStatusCountSuccess, getShipmentStatusCountFailure } = deliveriesmysqlslice.actions;

export const fetchShipmentCategoryCountData = (meta) => {
    const qs = Object.keys(meta)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(meta[key])}`)
        .join('&');

    return (dispatch) => {
        dispatch(getShipmentStatusCount());
        try {
            LABELGENAPI
                .get('/shipment/getcategorycount??' + qs)
                .then(function (response) {
                    dispatch(getShipmentStatusCountSuccess(response));
                })
                .catch(function (error) {
                    dispatch(getShipmentStatusCountFailure(error.response));
                });
        } catch (error) {
            dispatch(getShipmentStatusCountFailure());
        }
    };
};
export const { resetDeliveriessNotification } = deliveriesmysqlslice.actions;

export const resetDeliveriessNotifications = () => {
    return (dispatch) => {
        dispatch(resetDeliveriessNotification());
    }
}

export default deliveriesmysqlslice.reducer;
