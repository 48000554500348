import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import DateRangePicker from '@mui/lab/DateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Stack from '@mui/material/Stack';
import Rating from '@mui/material/Rating';
import moment from 'moment';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';

const Filters = ({ visible, meta, setMeta }) => {
    const report = useSelector((state) => state.customerfeedbackdashboard.report);
    const [formValues, setFormValues] = useState(meta)
    const [filterButtonDisabled, setFilterButtonDisabled] = useState(true)
    const [value, setValue] = React.useState([null, null]);
    const [checkFormUse, setCheckFormUse] = useState("")

    const onInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
            [`page`]: 1
        });
    };

    const submitFilterValues = () => {
        setMeta(prevState => ({
            ...prevState,
            [`rating`]: formValues["rating"],
            [`date`]: formValues["date"],
            [`page`]: 1
        }));
        setCheckFormUse(true)        
    }
   

    const onDateChange = (date) => {
        setValue(date)
        let start_date = ""
        let end_date = ""
        if (date[0] != null) {
            start_date = moment(date[0]).format('YYYY-MM-DD')
        }
        if (date[1] != null) {
            end_date = moment(date[1]).format('YYYY-MM-DD')
        }
        setFormValues({
            ...formValues,
            [`date`]: (start_date !== "" && end_date !== "") ? start_date + "," + end_date : ""
        });
    }

    const resetFields = () => {
        setFormValues({
            ...formValues,
            [`rating`]: "",
            [`date`]: "",
        });
        setMeta(prevState => ({
            ...prevState,
            [`rating`]: "",
            [`date`]: "",
            [`page`]: 1
        }));
        setValue([null, null])
    }

    const checkFormHasValues = () => {
        let disableButton = true
        if (formValues["date"] != "" || formValues["rating"] != "") {
            disableButton = false
        }

        return disableButton
    }

    useEffect(() => {
        setCheckFormUse("")
        setFilterButtonDisabled(checkFormHasValues())
    }, [formValues])

    console.log(formValues && formValues.rating)


    useEffect(() => {
        if ((formValues["date"] !== "" || formValues["rating"] !== "") && checkFormUse === '') {
            setFormValues({
                ...formValues,
                [`rating`]: "",
                [`date`]: "",
            });
            setMeta(prevState => ({
                ...prevState,
                [`rating`]: "",
                [`date`]: "",
                [`page`]: 1
            }));
            setValue([null, null])
        }
    }, [report])



    return (
        <>
            {visible &&
                <div className="filtercontainer">
                    <div className="filteraccordion" id="sc-filteraccordion">
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { m: 1, width: '25ch' },
                            }}

                            noValidate
                            autoComplete="off"
                        >
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 270 }}>
                                <InputLabel id="carrier-name-select-label" size={'small'}>Rating</InputLabel>
                                <Select
                                   
                                    name="rating"
                                    labelId="carrier-name-select-label"
                                    id="demo-simple-select"
                                    onChange={onInputChange}
                                    Value={formValues && formValues.rating}
                                >
                                    <MenuItem value={1}><Rating name="size-small" defaultValue={1} size="small" readOnly /></MenuItem>
                                    <MenuItem value={2}><Rating name="size-small" defaultValue={2} size="small" readOnly /></MenuItem>
                                    <MenuItem value={3}><Rating name="size-small" defaultValue={3} size="small" readOnly /></MenuItem>
                                    <MenuItem value={4}><Rating name="size-small" defaultValue={4} size="small" readOnly /></MenuItem>
                                    <MenuItem value={5}><Rating name="size-small" defaultValue={5} size="small" readOnly /></MenuItem>

                                </Select>
                            </FormControl>                           
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 370 }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Stack spacing={3}>
                                        <DateRangePicker
                                            startText="Start Date"
                                            endText="End Date"
                                            disableFuture
                                            value={value}
                                            views={["year", "month", "day"]}
                                            clearable={true}
                                            format={"yyyy-MM-dd"}
                                            onChange={(newValue) => {
                                                onDateChange(newValue);
                                            }}
                                            renderInput={(startProps, endProps) => (
                                                <React.Fragment>
                                                    <TextField
                                                        variant="standard"
                                                        fullWidth={true}
                                                        label="From"
                                                        size={'small'}
                                                        {...startProps}
                                                        InputProps={{
                                                            endAdornment: (value[0] != null) ? (
                                                                <IconButton size="small" onClick={() => onDateChange([null, null])}>
                                                                    <ClearIcon />
                                                                </IconButton>
                                                            ) : undefined
                                                        }}
                                                    />
                                                    <TextField
                                                        variant="standard"
                                                        fullWidth={true}
                                                        label="End"
                                                        size={'small'}
                                                        {...endProps}
                                                        InputProps={{
                                                            endAdornment: (value[1] != null) ? (
                                                                <IconButton size="small" onClick={() => onDateChange([null, null])}>
                                                                    <ClearIcon />
                                                                </IconButton>
                                                            ) : undefined
                                                        }}
                                                    />
                                                </React.Fragment>
                                            )}
                                        />
                                    </Stack>
                                </LocalizationProvider>
                            </FormControl>
                        </Box>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={12}>
                                <div className="filterfooter">
                                    <Button variant="contained" onClick={submitFilterValues} disabled={filterButtonDisabled}>Filter</Button>
                                    <Button variant="outlined" onClick={resetFields} disabled={filterButtonDisabled}>Clear</Button>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            }
        </>
    )

}

export default Filters;
