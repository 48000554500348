import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LinearProgress from '@mui/material/LinearProgress';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { DataGrid } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Snackbar from '@mui/material/Snackbar';
import Tooltip from '@mui/material/Tooltip';
import Alert from '@mui/material/Alert';
import Modal from '@mui/material/Modal';
import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import CloseIcon from '@mui/icons-material/Close';
import Autocomplete from "@mui/material/Autocomplete";
import { fetchRecipientAddress, destoryRecipientAddress, storeRecipientAddress, updateRecipientAddress, importRecipientAddress, resetRecipientAddressState, resetImportRecipientAddressState } from './addressRecipientSlice';
import { fetchCountry, fetchState } from '../../../pages/General/countryStateCitySlice';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import UploadIcon from '@mui/icons-material/Upload';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from "@mui/icons-material/Clear";

import NorowsData from '../../../pages/General/NorowsData'

const Input = styled('input')({
    display: 'none',
});

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper'
};

const deleteModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const Modalpopup = ({ modalVisible, setModalVisible, formValues, setFormValues }) => {
    const dispatch = useDispatch();
    const addressrecipient = useSelector(state => state.addressrecipient && state.addressrecipient.addressrecipient);
    const country = useSelector(state => state.countrystate.country);
    const state = useSelector(state => state.countrystate.state);
    const initialRecipientAddressValues = {
        name: '',
        company: '',
        address: '',
        address2: '',
        city: '',
        province_code: '',
        country_code: '',
        postal_code: '',
        email: '',
        phone: '',
        customer_id: '',
        reference: '',
        reference2: '',
        reference3: '',
    }
    const [values, setValues] = useState(formValues && Object.keys(formValues).length === 0 ? initialRecipientAddressValues : formValues);
    const [errors, setErrors] = useState({});
    const filteredCountryData = country.data.filter(({ code }) => code );

    useEffect(() => {
        if (formValues && formValues.country_code) {
            dispatch(fetchState(formValues && formValues.country_code));
        }
    }, [formValues]);

    useEffect(() => {
        if (formValues && Object.keys(formValues).length !== 0) {
            let tempValues = {
                name: formValues['name'],
                company: formValues['company'],
                address: formValues['address'],
                address2: formValues['address2'],
                city: formValues['city'],
                province_code: formValues['province_code'],
                country_code: formValues['country_code'],
                postal_code: formValues['postal_code'],
                email: formValues['email'],
                phone: formValues['phone'],
                customer_id: formValues['customer_id'],
                reference: formValues['reference'],
                reference2: formValues['reference2'],
                reference3: formValues['reference3'],
            };
            setValues(tempValues)
        }
    }, [formValues])

    useEffect(() => {
        if (addressrecipient && addressrecipient.message && addressrecipient.message != "") {
            if (addressrecipient && addressrecipient.messageType == 'success') {
                setValues(initialRecipientAddressValues)
                setErrors({})
            }
        }
    }, [addressrecipient.message]);

    const onModalClose = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;
        onModalEditClose()
    }

    const onModalEditClose = () => {
        setFormValues(initialRecipientAddressValues)
        setModalVisible(false)
        dispatch(resetRecipientAddressState())
        dispatch(resetImportRecipientAddressState())
    }

    const onInputChange = e => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
        validator({ [name]: value })
    }

   
    const onSelectCountryChange = (countryCode) => {
        setValues({
        ...values,
        country_code: countryCode,
        });
        setErrors({ country_code: "" });
        dispatch(fetchState(countryCode));
    };

    const getSelectedCountry = (country_code) => {
        let filteredValue = { code: "", name: "" };
        filteredCountryData.map((key) => {
        if (key.code == country_code) {
            filteredValue = key;
        }
        });
        return filteredValue;
    };

    const onSelectStateChange = (currentstate) => {
        setValues({
        ...values,
        province_code: currentstate,
        });
        setErrors({ province_code: "" });
    };

    const getSelectedState = (currentstate) => {
        let filteredStateValue = { code: "", name: "" };
        let tempState = "";
        state &&
        state.data &&
        state.data.map((key) => {
            if (key.code == currentstate && tempState == "") {
            filteredStateValue = key;
            tempState = "success";
            }
        });
        return filteredStateValue;
    };

    const handleSubmit = e => {
        e.preventDefault()
        const isValid = validator(values)
        if (isValid) {
            if ("_id" in formValues) {
                dispatch(updateRecipientAddress(formValues._id, values))
            } else {
                dispatch(storeRecipientAddress(values))
            }
        }
    }

    const validator = (fieldValues) => {
        let temp = { ...errors }
        if ('name' in fieldValues)
            temp.name = fieldValues.name ? "" : "This field is required."
        if (fieldValues && fieldValues.name && fieldValues.name.length < 3)
            temp.name = "Recipient Name required minimum 3 characters"
        if ('address' in fieldValues)
            temp.address = fieldValues.address ? "" : "This field is required."
        if ('city' in fieldValues)
            temp.city = fieldValues.city ? "" : "This field is required."
        if ('email' in fieldValues)
            temp.email = fieldValues.email ? "" : "This field is required."
        if ('postal_code' in fieldValues)
            temp.postal_code = fieldValues.postal_code ? "" : "This field is required."
        if ((fieldValues && fieldValues.postal_code && fieldValues.postal_code.length < 3) ||
            (fieldValues && fieldValues.postal_code && fieldValues.postal_code === null))
            temp.postal_code = "Recipient postal code required minimum 3 characters"
        if (fieldValues && fieldValues.postal_code && fieldValues.postal_code.match(/[^0-9a-z]/i))
            temp.postal_code = "Postal code only accepts alpha numeric"
        if ('province_code' in fieldValues)
            temp.province_code = fieldValues.province_code ? "" : "This field is required."
        if ('country_code' in fieldValues)
            temp.country_code = fieldValues.country_code ? "" : "This field is required."
        setErrors({
            ...temp
        })

        if (fieldValues === values) {
            return Object.values(temp).every(x => x == "")
        }
    }

    return (

        <Modal
            open={modalVisible}
            onClose={onModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Card sx={{ minWidth: 600 }} className="sc--cardhead--details">
                    <CardHeader title='Recipient Address'  action={
                        <div>
                            <IconButton aria-label="settings" onClick={onModalClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    }/>
                    <Divider />
                    <CardContent>
                        <div className="sc--form--center" style={{ margin: '20px 0' }}>
                            <TextField
                                label="Name"
                                name="name"
                                defaultValue={formValues && formValues.name}
                                onChange={onInputChange}
                                error={errors.name}
                                helperText={errors.name}
                                inputProps={{ maxLength: 40 }}
                                style={{ width: "100%", margin: '8px' }}
                                variant="standard"
                                required
                            />
                            <TextField
                                label="Company"
                                name="company"
                                defaultValue={formValues && formValues.company}
                                onChange={onInputChange}
                                inputProps={{ maxLength: 40 }}
                                variant="standard" style={{ width: "100%", margin: '8px' }}
                            />
                        </div>
                        <div className="sc--form--center" style={{ margin: '20px 0' }}>
                            <TextField
                                label="Phone"
                                name="phone"
                                defaultValue={formValues && formValues.phone}
                                onChange={onInputChange}
                                inputProps={{ maxLength: 13 }}
                                variant="standard" style={{ width: "100%", margin: '8px' }}
                            />
                            <TextField
                                label="Email"
                                name="email"
                                defaultValue={formValues && formValues.email}
                                onChange={onInputChange}
                                error={errors.email}
                                inputProps={{ maxLength: 60 }}
                                helperText={errors.email}
                                variant="standard" style={{ width: "100%", margin: '8px' }}
                                required
                            />
                        </div>
                        <div className="sc--form--center" style={{ margin: '20px 0' }}>
                            <TextField
                                label="Address1"
                                name="address"
                                defaultValue={formValues && formValues.address}
                                onChange={onInputChange}
                                error={errors.address}
                                inputProps={{ maxLength: 35 }}
                                helperText={errors.address}
                                variant="standard" style={{ width: "100%", margin: '8px' }}
                                required
                            />
                            <TextField
                                label="Address2"
                                name="address2"
                                defaultValue={formValues && formValues.address2}
                                onChange={onInputChange}
                                inputProps={{ maxLength: 35 }}
                                variant="standard" style={{ width: "100%", margin: '8px' }}
                            />
                        </div>
                        <div className="sc--form--center" style={{ margin: '20px 0' }}>
                            <TextField
                                label="Postal Code"
                                name="postal_code"
                                defaultValue={formValues && formValues.postal_code}
                                onChange={onInputChange}
                                inputProps={{ maxLength: 6 }}
                                error={errors.postal_code}
                                helperText={errors.postal_code}
                                variant="standard" style={{ width: "100%", margin: '8px' }}
                                required
                            />
                            <TextField
                                label="City"
                                name="city"
                                defaultValue={formValues && formValues.city}
                                onChange={onInputChange}
                                error={errors.city}
                                inputProps={{ maxLength: 30 }}
                                helperText={errors.city}
                                variant="standard" style={{ width: "100%", margin: '8px' }}
                                required
                            />
                        </div>
                        <div className="sc--form--center" style={{ margin: '20px 0' }}>
                            <Autocomplete
                                className="help-text-error"
                                disablePortal
                                name="country_code"
                                id="country-select-demo"
                                sx={{ width: "100%",margin: '8px' }}
                                options={filteredCountryData}
                                autoHighlight
                                loading={country && country.loading}
                                getOptionLabel={(option) => option.name}
                                value={getSelectedCountry(values && values.country_code)}
                                renderOption={(props, option) => (
                                <MenuItem {...props} value={option.code} key={option.code}>
                                    {option.icon} {option.name}
                                </MenuItem>
                                )}
                                onChange={(event, value) => {
                                    if (value !== null) {
                                      onSelectCountryChange(value.code);
                                      setErrors({ country_code: "" });
                                    } else {
                                      onSelectCountryChange("");
                                      setErrors({
                                        country_code: "This field is required",
                                      });
                                    }
                                  }}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="country"
                                    variant="standard"
                                    helperText={errors.country_code}
                                />
                                )}
                            />
                            <Autocomplete
                                className="help-text-error"
                                disablePortal
                                name="province_code"
                                id="state-select-demo"
                                sx={{ width: "100%",margin: '8px' }}
                                options={state && state.data}
                                autoHighlight
                                loading={
                                (state && state.loading) || (country && country.loading)
                                }
                                getOptionLabel={(option) => option.name}
                                value={getSelectedState(values && values.province_code)}
                                renderOption={(props, option) => (
                                <MenuItem {...props} value={option.code} key={option.code}>
                                    {option.name}
                                </MenuItem>
                                )}
                                onChange={(event, value) => {
                                    if (value !== null) {
                                      onSelectStateChange(value.code);
                                    } else {
                                      onSelectStateChange("");
                                    }
                                  }}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="state"
                                    variant="standard"
                                    helperText={errors.province_code}
                                />
                                )}
                            />
                        </div>
                        <div className="sc--form--center" style={{ margin: '20px 0' }}>
                            <TextField
                                label="Customer ID"
                                name="customer_id"
                                defaultValue={formValues && formValues.customer_id}
                                onChange={onInputChange}
                                inputProps={{ maxLength: 30 }}
                                error={errors.customer_id}
                                helperText={errors.customer_id}
                                variant="standard" style={{ width: "100%", margin: '8px' }}
                            />
                            <TextField
                                label="Reference"
                                name="reference"
                                defaultValue={formValues && formValues.reference}
                                onChange={onInputChange}
                                inputProps={{ maxLength: 30 }}
                                variant="standard" style={{ width: "100%", margin: '8px' }}
                            />
                        </div>
                        <div className="sc--form--center" style={{ margin: '20px 0' }}>
                            <TextField
                                label="Reference 2"
                                name="reference2"
                                defaultValue={formValues && formValues.reference2}
                                onChange={onInputChange}
                                inputProps={{ maxLength: 30 }}
                                variant="standard" style={{ width: "100%", margin: '8px' }}
                            />
                            <TextField
                                label="Reference 3"
                                name="reference3"
                                defaultValue={formValues && formValues.reference3}
                                onChange={onInputChange}
                                inputProps={{ maxLength: 30 }}
                                variant="standard" style={{ width: "100%", margin: '8px' }}
                            />
                        </div>
                        <div className="sc--form--center" style={{ margin: '30px 0' }}>
                            <Button variant="outlined" onClick={handleSubmit}>Submit</Button>
                        </div>
                    </CardContent>
                </Card>
            </Box>
        </Modal >
    )
}

const ModalImportPopup = ({ modalImportVisible, setModalImportVisible, setSnackOpen, setAlertImport }) => {

    const dispatch = useDispatch();
    const importaddressrecipient = useSelector(state => state.addressrecipient && state.addressrecipient.importaddressrecipient);

    const [fileUpload, setFileUpload] = useState("")
    const [fileName, setFileName] = useState("")
    const [buttonSubmitDisabled, setButtonSubmitDisabled] = useState(true)
    const [buttonUploadDisabled, setButtonUploadDisabled] = useState(false)

    useEffect(() => {
        console.log(importaddressrecipient)
        if (importaddressrecipient && importaddressrecipient.message && importaddressrecipient.message !== "") {
            setFileName("")
            setFileUpload("")
            setButtonSubmitDisabled(true)
            setButtonUploadDisabled(false)
            setSnackOpen(true)
            setAlertImport(true)
            if (importaddressrecipient && importaddressrecipient.messageType && importaddressrecipient.messageType === 'success') {
                setModalImportVisible(false)
            }
        }
    }, [importaddressrecipient.message])

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;
        onModalImportClose();
    }

    const onModalImportClose = () => {
        setFileName("")
        setFileUpload("")
        setButtonSubmitDisabled(true)
        setButtonUploadDisabled(false)
        setModalImportVisible(false)
        dispatch(resetRecipientAddressState())
        dispatch(resetImportRecipientAddressState())
    }

    const onFileUpload = (e) => {
        e.preventDefault()
        if (e.target.files && e.target.files.length === 0) {
            setFileName(fileUpload.name)
            setFileUpload(fileUpload);
        } else {
            setFileUpload(e.target.files[0]);
            setFileName(e.target.files[0].name)
        }
        setButtonSubmitDisabled(false)
    }

    const onSubmit = () => {
        var formData = new FormData();
        formData.append('file', fileUpload);
        dispatch(importRecipientAddress(formData))
        setFileName("")
        setFileUpload("")
        setButtonSubmitDisabled(true)
        setButtonUploadDisabled(true)
    }

    return (

        <Modal
            open={modalImportVisible}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Card sx={{ minWidth: 600 }} className="sc--cardhead--details">
                    <CardHeader title='Import Recipient Address' action={
                        <div>
                            <IconButton aria-label="settings" onClick={onModalImportClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    } />
                    {importaddressrecipient && importaddressrecipient.loading === true &&
                        <LinearProgress />
                    }
                    <Divider />
                    <CardContent>
                        <div style={{ margin: '15px 0', paddingTop: '25px', textAlign: 'center', }}>
                            <label htmlFor="contained-button-file">
                                <Input accept=".csv" id="contained-button-file" onChange={onFileUpload} key={fileName} name='import_recipient_address_book' multiple type="file" />
                                <Button variant="contained" component="span" style={{ margin: '0 10px' }} disabled={buttonUploadDisabled}>
                                    Upload
                                </Button>
                                <a href="https://shippingchimp.s3.us-east-2.amazonaws.com/labelgen/sample/Sample+Recipient+Address+Book.csv">Sample Recipient Address CSV</a>
                            </label>
                                {fileName && <><br /><span style={{ fontSize: '10px' }}>Filename : {fileName}</span></>}
                            <div>
                                <Button variant="outlined" onClick={onSubmit} style={{ margin: '15px 15px' }} disabled={buttonSubmitDisabled}>
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </Box>
        </Modal >
    )
}
const AddressRecipientPage = () => {

    const dispatch = useDispatch();
    const addressrecipient = useSelector(state => state.addressrecipient && state.addressrecipient.addressrecipient);
    const importaddressrecipient = useSelector(state => state.addressrecipient && state.addressrecipient.importaddressrecipient);

    const [addressAlert, setAddressAlert] = useState(false);
    const [alertImport, setAlertImport] = useState(false);
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [deleteModalopen, setDeleteModalopen] = React.useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalImportVisible, setModalImportVisible] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [deleteid, setDeleteId] = React.useState("");
    const [pageSize, setPageSize] = useState(10);
    const [showSearch, setShowSearch] = useState(false);
    const [addressSearchValues, setAddressSearchValues] = useState({
        "search_by": "name",
        "search_value": ""
    })

    useEffect(() => {
        dispatch(fetchRecipientAddress(addressSearchValues));
        dispatch(fetchCountry());
    }, [dispatch]);

    useEffect(() => {
        if (addressrecipient && addressrecipient.message && addressrecipient.message !== "") {
            setAddressAlert(true)
            setSnackOpen(true)
            if (addressrecipient && addressrecipient.messageType == 'success') {
                setModalVisible(false)
                setSelectedItem({})
            }
        }
    }, [addressrecipient.message]);

    const handleOnClickDelete = (id) => {
        setDeleteId(id)
        setDeleteModalopen(true);
    };

    const deleteModalClose = () => {
        setDeleteModalopen(false);
    };

    const deleteaddress = (id) => {
        dispatch(destoryRecipientAddress(id))
        setAddressAlert(true)
        setSnackOpen(true)
        setDeleteModalopen(false);
    }
    const snackhandleClose = () => {
        setAddressAlert(false)
        setAlertImport(false)
        setSnackOpen(false)
        dispatch(resetRecipientAddressState())
        dispatch(resetImportRecipientAddressState())
    };

    const snackbar = (value) => {
        setSnackOpen(value)
    }

    const onInputClear = () => {
        let tempAddressSearchValues = addressSearchValues
        tempAddressSearchValues["search_value"] = ""
        setAddressSearchValues(tempAddressSearchValues);
        dispatch(fetchRecipientAddress(tempAddressSearchValues));
    };

    const onMenuClick = () => {
        setShowSearch(!showSearch)
        if (showSearch) {
            setAddressSearchValues({
                "search_by": "name",
                "search_value": ""
            });
            dispatch(fetchRecipientAddress({
                "search_by": "name",
                "search_value": ""
            }));
        }
    }

    const onSearchAddressFieldsChange = (event) => {
        let addressSearchValuesTemp = { ...addressSearchValues };
        addressSearchValuesTemp[event.target.name] = event.target.value
        setAddressSearchValues(addressSearchValuesTemp)
        if(event.target.value === ""){
            dispatch(fetchRecipientAddress(addressSearchValuesTemp));

        }
    }

    const onSearchAddressFieldsEnter = (event) => {
        let addressSearchValuesTemp = { ...addressSearchValues };
        if (event.charCode === 13 && addressSearchValuesTemp.search_value != "") {
            dispatch(fetchRecipientAddress(addressSearchValuesTemp));
        }
    }

    const columns = [
        { field: 'name', headerName: 'Name', flex:1  },
        { field: 'company', headerName: 'Company Name', flex:1  },
        { field: 'phone', headerName: 'Phone Number', flex:1  },
        { field: 'email', headerName: 'Email', flex:1  },
        { field: 'address', headerName: 'Address', flex:1  },
        { field: 'city', headerName: 'City', flex:1  },
        { field: 'province_code', headerName: 'Province Code', flex:1  },
        { field: 'country_code', headerName: 'Country Code', flex:1  },
        { field: 'postal_code', headerName: 'Postal Code',flex:1  },
        { field: 'reference', headerName: 'Reference', flex:1  },
        {
            field: 'action',
            headerName: 'Action',
            flex:1 ,
            renderCell: (params) => {
                return <div>
                    <span>
                        <Tooltip title="Edit" placement="left-start" disableFocusListener={false}>
                            <IconButton>
                                <EditIcon style={{ color: 'rgba(0,0,0,.54)', cursor: 'pointer' }} onClick={() => {
                                    setSelectedItem(params.row);
                                    setModalVisible(true)
                                }} />
                            </IconButton>
                        </Tooltip>
                    </span>
                    <span>
                        <Tooltip title="Delete" placement="left-start" disableFocusListener={false}>
                            <IconButton>
                                <DeleteOutlineOutlinedIcon style={{ color: 'rgba(0,0,0,.54)', cursor: 'pointer' }}
                                    onClick={(e) => {
                                        handleOnClickDelete(params.row._id)
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                    </span>
                </div>
            }
        }
    ];
    return (
        <div className="sc--settings--card">
            <Modal
                open={deleteModalopen}
                onClose={deleteModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={deleteModalStyle}>
                    <Typography sx={{ p: 2 }}>Are you sure want to delete this recipient address?<br />
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <span style={{ margin: '0 10px' }}>
                                <Button
                                    variant="contained"
                                    style={{ margin: "10px 0" }}
                                    onClick={(event) => {
                                        deleteaddress(deleteid)
                                    }}
                                >
                                    Yes
                                </Button>
                            </span>
                            <span>
                                <Button
                                    variant="outlined"
                                    style={{ margin: "10px 0" }}
                                    onClick={deleteModalClose}
                                >
                                    No
                                </Button>
                            </span>
                        </div>
                    </Typography>
                </Box>
            </Modal>
            <Modalpopup
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}
                formValues={selectedItem}
                setFormValues={setSelectedItem}
            />
            <ModalImportPopup
                modalImportVisible={modalImportVisible}
                setModalImportVisible={setModalImportVisible}
                setSnackOpen={setSnackOpen}
                setAlertImport={setAlertImport}
            />
            {addressAlert && (addressrecipient && addressrecipient.loading === false) &&
                <Snackbar
                    open={snackOpen}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    autoHideDuration={6000}
                    onClose={snackhandleClose}
                >
                    <Alert
                        severity={addressrecipient && addressrecipient.hasErrors === true ? "error" : "success"}
                        style={{ justifyContent: 'center', margin: "10px 0" }}>
                        <span >{addressrecipient && addressrecipient.message}</span>
                    </Alert>
                </Snackbar>
            }
            {alertImport && (importaddressrecipient && importaddressrecipient.loading === false) &&
                <Snackbar
                    open={snackOpen}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    autoHideDuration={6000}
                    onClose={snackhandleClose}
                >
                    <Alert
                        severity={importaddressrecipient && importaddressrecipient.hasErrors === true ? "error" : "success"}
                        style={{ justifyContent: 'center', margin: "10px 0" }}>
                        <span >{importaddressrecipient && importaddressrecipient.message}</span>
                    </Alert>
                </Snackbar>
            }
            <Card sx={{ width: '100%', boxShadow: 'unset' }} className="sc--cardhead--details">
                <CardHeader title='Recipient Address' className="sc--cardhead--details" action={
                    <div className="recipient-address-header-elements" style={{ display: 'flex', alignItems: 'center' }}>
                        {showSearch && (<div style={{ display: 'flex', alignItems: 'center' }}>
                            <div className="recipient-address-header-elements">
                                <FormControl variant="standard" style={{ width: '100%' }}>
                                    <Select
                                        name="search_by"
                                        onChange={onSearchAddressFieldsChange}
                                        value={addressSearchValues.search_by}
                                        label="Search By"
                                        style={{ width: '150px', marginTop: "13px" }}
                                    >
                                        <MenuItem value="name">Name</MenuItem>
                                        <MenuItem value="company">Company</MenuItem>
                                        <MenuItem value="address">Address</MenuItem>
                                        <MenuItem value="address2">Addres2</MenuItem>
                                        <MenuItem value="city">City</MenuItem>
                                        <MenuItem value="province_code">Province code</MenuItem>
                                        <MenuItem value="country_code">Country code</MenuItem>
                                        <MenuItem value="postal_code">Postal code</MenuItem>
                                        <MenuItem value="email">Email</MenuItem>
                                        <MenuItem value="phone">Phone</MenuItem>
                                        <MenuItem value="customer_id">Customer id</MenuItem>
                                        <MenuItem value="reference">Reference</MenuItem>
                                        <MenuItem value="reference2">Reference 2</MenuItem>
                                        <MenuItem value="reference3">Reference 3</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="recipient-address-header-elements">
                                <TextField
                                    placeholder={"Enter " + addressSearchValues.search_by.replace("_", " ")}
                                    label={addressSearchValues.search_by.replace("_", " ")}
                                    variant="standard"
                                    name="search_value"
                                    value={addressSearchValues.search_value}
                                    inputProps={{ min: 2, maxLength: 25 }}
                                    onChange={onSearchAddressFieldsChange}
                                    onKeyPress={onSearchAddressFieldsEnter}
                                    style={{ width: '100%' }}
                                    InputProps={{
                                        endAdornment: addressSearchValues.search_value ? (
                                            <IconButton size="small" onClick={() => onInputClear()}>
                                                <ClearIcon />
                                            </IconButton>
                                        ) : undefined,
                                    }}
                                />
                            </div>
                        </div>)
                        }

                        <div className="recipient-address-header-elements">
                            <Tooltip title="Seacrh" placement="top-start">
                                <IconButton style={(showSearch) ? { backgroundColor: 'rgb(230, 230, 230)' } : {}} onClick={() => onMenuClick()}>
                                    <SearchIcon style={{ color: 'rgb(25, 118, 210)' }} />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div className="recipient-address-header-elements">
                            <Tooltip title="Add Address" placement="top-start">
                                <IconButton onClick={() => { setModalVisible(true) }}>
                                    <AddCircleOutlineRoundedIcon style={{ color: 'rgb(25, 118, 210)' }} />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div className="recipient-address-header-elements">
                            <Tooltip title="Import Address" placement="top-start">
                                <IconButton onClick={() => { setModalImportVisible(true) }}>
                                    <UploadIcon style={{ color: 'rgb(25, 118, 210)' }} />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                } />
                <Divider />
                <CardContent>
                    <div>
                        <DataGrid
                            getRowId={(row) => row._id}
                            rows={addressrecipient && addressrecipient.data ? addressrecipient.data : []}
                            columns={columns}
                            autoHeight={true}
                            disableRowSelectionOnClick={true}
                            sortingMode="server"
                            slots={{
                                loadingOverlay: LinearProgress,
                                NorowsData
                            }}
                            loading={addressrecipient && addressrecipient.loading}
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            rowsPerPageOptions={[5, 10, 20]}
                            pagination
                        />
                    </div>
                </CardContent>
            </Card>
        </div>
    )
}
export default AddressRecipientPage;
