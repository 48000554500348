import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import Autocomplete from '@mui/material/Autocomplete';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListSubheader from '@mui/material/ListSubheader';
import Chip from '@mui/material/Chip';
import { fetchCountry, fetchState } from './countryStateCitySlice';
import { ContactlessOutlined } from '@mui/icons-material';

const IntegrationCarrier = ({ errors, dispatchCountryCallback, dispatchStateCallback, dispatchPostalCodeCallback, newCarrierFormData }) => {
    const dispatch = useDispatch();
    const country = useSelector(state => state.countrystate.country);
    const state = useSelector(state => state.countrystate.state);
    const filteredCountryData = country.data.filter(({ code }) => code );
    const [countryCode, setCountryCode] = useState(["ALL"]);
    const [province, setProvince] = useState(["ALL"]);
    const [postalcode, setPostalcode] = useState(["ALL"]);
    const [formpostalcode, setFormPostalcode] = useState([]);
    const postalcodes = [
        "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"
    ]
    let countryCheck = ''
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    const onMultiSelectChange = (e) => {
        if (e.target.value.includes("ALL")) {
            e.target.value.splice(0, 1);
            setCountryCode(e.target.value)
            setProvince(["ALL"])
        }

        setCountryCode(e.target.value)
        dispatch(fetchState(e.target.value));
        dispatchCountryCallback(e.target.value)
    }
    const onMultiSelectStateChange = (e) => {

        setProvince(e.target.value)
        dispatchStateCallback(e.target.value)
    }
    const onMultiSelectPostalcodeChange = (e, value) => {
        dispatchPostalCodeCallback(value)
    }
    useEffect(() => {
        dispatch(fetchCountry());
    }, [dispatch]);

    useEffect(() => {
        if (newCarrierFormData && Object.keys(newCarrierFormData).length !== 0) {
            if (newCarrierFormData.carrier_preferences) {
                let countrycodeitems = []
                newCarrierFormData.carrier_preferences && newCarrierFormData.carrier_preferences.country_code && newCarrierFormData.carrier_preferences.country_code.map((item) => {
                    countrycodeitems.push(String(item))
                })
                let provinceitems = []
                newCarrierFormData.carrier_preferences && newCarrierFormData.carrier_preferences.province_code && newCarrierFormData.carrier_preferences.province_code.map((item) => {
                    provinceitems.push(String(item))
                })
                let postalcodeitems = []
                newCarrierFormData.carrier_preferences && newCarrierFormData.carrier_preferences.postal_code && newCarrierFormData.carrier_preferences.postal_code.map((item) => {
                    postalcodeitems.push(String(item))
                })
                setCountryCode(countrycodeitems)
                setProvince(provinceitems)
                setFormPostalcode(postalcodeitems)
                dispatchCountryCallback(countrycodeitems)
                dispatch(fetchState(countrycodeitems));
                dispatchStateCallback(provinceitems)
                dispatchPostalCodeCallback(postalcodeitems)
            }
        }
    }, [newCarrierFormData])
    return (
        <>
            <div className="sc--form--center" style={{ margin: '20px 0' }}>
                <FormControl variant="standard" className="help-text-error" style={{ width: "100%", margin: '8px' }} >
                    <InputLabel id="country-multiple-chip-label">Country</InputLabel>

                    <Select
                        multiple
                        name="countryCode"
                        value={countryCode}
                        labelId="country-multiple-chip-label"
                        id="country-multiple-chip"
                        onChange={onMultiSelectChange}
                        error={errors.country}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                    <Chip key={value} label={value} />
                                ))}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                    >
                        <MenuItem value={"ALL"}>ALL</MenuItem>
                        {filteredCountryData.map((key) => {
                            return <MenuItem value={key.code}>{key.icon} {key.name}</MenuItem>
                        })}
                    </Select>
                    <FormHelperText>{errors.country}</FormHelperText>
                </FormControl>

                <FormControl variant="standard" className="help-text-error" style={{ width: "100%", margin: '8px' }} >
                    <InputLabel id="state-multiple-name-label">Province / State</InputLabel>
                    <Select
                        multiple
                        labelId="state-multiple-chip-label"
                        id="state-multiple-chip"
                        onChange={onMultiSelectStateChange}
                        name="province"
                        value={province}
                        error={errors.state}
                        label="Province / State"
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                    <Chip key={value} label={value} />
                                ))}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                    >
                        <MenuItem value={"ALL"}>ALL</MenuItem>
                        {state && state.data.map((key) => {
                            if ((countryCheck !== key.country)) {
                                countryCheck = key.country
                                return (
                                    <>
                                        <ListSubheader>{key.country}</ListSubheader>
                                    </>
                                )
                            } else {
                                return <MenuItem key={key.code} value={key.code}>{key.name}</MenuItem>
                            }
                        })}
                    </Select>
                    <FormHelperText>{errors.state}</FormHelperText>
                </FormControl>
            </div>
            <div style={{ margin: '20px 0' }}>
                <Autocomplete
                    multiple
                    freeSolo
                    id="tags-standard"
                    options={postalcodes}
                    getOptionLabel={(option) => option.value || option}
                    defaultValue={formpostalcode.length !== 0 ? formpostalcode : postalcode}
                    onChange={onMultiSelectPostalcodeChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                            label="Postal Code"
                            error={errors.postal}
                            helperText={errors.postal}
                            placeholder="Postalcode"
                            style={{ width: '48%', margin: '8px' }}
                        />
                    )}
                />
            </div>
        </>
    )
};

export default IntegrationCarrier;
