import { createSlice } from '@reduxjs/toolkit';
import { OMSAPI } from '../../../API';
import axios from 'axios';

/* canceling previous requests if multiple requests is triggered */
const CancelToken = axios.CancelToken;
let source = CancelToken.source();

export const integratedslice = createSlice({
    name: 'integrated',
    initialState: {
        data: [],
        loading: false,
        notification: { show: false, messageContent: "", messageType: "success" },
        hasErrors: false,
        isdataLoaded: false
    },
    reducers: {
        getIntegratedPlatform: (state) => {
            return {
                ...state,
                loading: true,
                isdataLoaded: false
            }
        },
        getIntegratedPlatformSuccess: (state, action) => {
            return {
                ...state,
                data: action && action.payload && action.payload.data,
                loading: false,
                hasErrors: false,
                isdataLoaded: true
            }
        },
        getIntegratedPlatformFailure: (state,action) => {
            return {
                ...state,
                notification: { show: true, messageContent: [action && action.payload && action.payload.data && action.payload.data.message], messageType: "error" },
                loading: false,
                hasErrors: true
            }
        },


    }
})
export const { getIntegratedPlatform, getIntegratedPlatformSuccess, getIntegratedPlatformFailure } = integratedslice.actions;

export const fetchIntegratedPlatform = data => dispatch => {

    dispatch(getIntegratedPlatform())
    try {
        OMSAPI.get('/marketplace')
            .then(function (response) {
                dispatch(getIntegratedPlatformSuccess(response));
            }).catch(function (error) {
                dispatch(getIntegratedPlatformFailure(error.response));
            })
    }
    catch (error) {
        dispatch(getIntegratedPlatformFailure())
    }
}


export default integratedslice.reducer;