import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Snackbar from "@mui/material/Snackbar";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import { styled } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import DeleteIcon from "@mui/icons-material/Delete";
import LinearProgress from "@mui/material/LinearProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import Typography from "@mui/material/Typography";
import {
  getLabel,
  postlabel,
  deletelabel,
  resetLabelState,
} from "./labelSettingsSlice";

const LabelSettingsPage = () => {
  const dispatch = useDispatch();
  const labelsettings = useSelector((state) => state.labelsettings);

  const Input = styled("input")({
    display: "none",
  });
  const [alert, setAlert] = useState(false);
  const [open, setOpen] = useState(false);
  const [label, setLabel] = useState("pdf");
  const [fileUpload, setFileUpload] = useState("");
  const [fileName, setFileName] = useState("");
  const [checkRemove, setCheckRemove] = useState(true);
  const [logoDelete, setLogoDelete] = useState(false);

  const initialValues = {
    is_signature_required:false,
    is_detachable_label: false,
    order_number: false,
    tracking_number: false,
    reference_number: false,
    carrier_name: false,
    weight: false,
  };
  const [values, setValues] = useState(initialValues);

  const onInputChange = (e) => {
    setLabel(e.target.value);
  };

  const onLabelChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: e.target.checked,
    });
  };

  const onFileUpload = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files.length === 0) {
      setFileName(fileUpload.name);
      setFileUpload(fileUpload);
    } else {
      setFileUpload(e.target.files[0]);
      const objectUrl = URL.createObjectURL(e.target.files[0]);
      setFileName(objectUrl);
      setCheckRemove(false);
    }
  };

  const removeCustom = () => {
    setFileName("");
    setCheckRemove(true);
    setLogoDelete(true);
  };

  const onSubmit = () => {
    let detachable_label_field_array = [];

    for (const [key, value] of Object.entries(values)) {
      if (key != "is_detachable_label" && key != "is_signature_required") {
        if (value == true) {
          detachable_label_field_array.push(key);
        }
      }
    }

    if (checkRemove === false && fileName !== "") {
      let formData = new FormData();
      formData.append("image", fileUpload);
      formData.append("is_detachable_label_enabled", values.is_detachable_label);
      formData.append("is_signature_required", values.is_signature_required);
      formData.append(
        "detachable_label_field_array",
        detachable_label_field_array
      );
      dispatch(postlabel(label, logoDelete, formData));
    } else {
      let formData = {
        detachable_label_field_array: detachable_label_field_array,
        is_detachable_label_enabled: values.is_detachable_label,
        is_signature_required:values.is_signature_required
      };

      dispatch(postlabel(label, logoDelete, formData));
    }
  };
  useEffect(() => {
    if (labelsettings && labelsettings.loading === false) {
      if (
        labelsettings &&
        labelsettings.data &&
        labelsettings.data.label_settings
      ) {
        setFileName(
          labelsettings &&
            labelsettings.data &&
            labelsettings.data.label_settings &&
            labelsettings.data.label_settings.custom_logo_s3_url
        );
        setFileUpload(
          labelsettings &&
            labelsettings.data &&
            labelsettings.data.label_settings &&
            labelsettings.data.label_settings.custom_logo_s3_url
        );
        setLabel(
          labelsettings &&
            labelsettings.data &&
            labelsettings.data.label_settings &&
            labelsettings.data.label_settings.format
        );

        setValues({
          ...values,
          ["is_signature_required"]:
          labelsettings &&
          labelsettings.data &&
          labelsettings.data.is_signature_required,
          ["is_detachable_label"]:
            labelsettings &&
            labelsettings.data &&
            labelsettings.data.label_settings &&
            labelsettings.data.label_settings.is_detachable_label,
          ["order_number"]:
            labelsettings &&
            labelsettings.data &&
            labelsettings.data.label_settings &&
            labelsettings.data.label_settings.detachable_label_field_array &&
            labelsettings.data.label_settings.detachable_label_field_array.includes(
              "order_number"
            )
              ? true
              : false,
          ["tracking_number"]:
            labelsettings &&
            labelsettings.data &&
            labelsettings.data.label_settings &&
            labelsettings.data.label_settings.detachable_label_field_array &&
            labelsettings.data.label_settings.detachable_label_field_array.includes(
              "tracking_number"
            )
              ? true
              : false,
          ["reference_number"]:
            labelsettings &&
            labelsettings.data &&
            labelsettings.data.label_settings &&
            labelsettings.data.label_settings.detachable_label_field_array &&
            labelsettings.data.label_settings.detachable_label_field_array.includes(
              "reference_number"
            )
              ? true
              : false,
          ["carrier_name"]:
            labelsettings &&
            labelsettings.data &&
            labelsettings.data.label_settings &&
            labelsettings.data.label_settings.detachable_label_field_array &&
            labelsettings.data.label_settings.detachable_label_field_array.includes(
              "carrier_name"
            )
              ? true
              : false,
          ["weight"]:
            labelsettings &&
            labelsettings.data &&
            labelsettings.data.label_settings &&
            labelsettings.data.label_settings.detachable_label_field_array &&
            labelsettings.data.label_settings.detachable_label_field_array.includes(
              "weight"
            )
              ? true
              : false,
        });
      }
      if (labelsettings && labelsettings.message) {
        setAlert(true);
        setOpen(true);
      }
    }
  }, [labelsettings]);
  const handleClose = () => {
    setOpen(false);
    setCheckRemove(true);
    setLogoDelete(false);
    dispatch(resetLabelState());
  };
  useEffect(() => {
    dispatch(getLabel());
  }, []);


  let options = [];
  for (const [eventKey, eventValue] of Object.entries(values)) {
    if (eventKey != "is_detachable_label" && eventKey!="is_signature_required") {
      options.push(
        <div className={`sc--form--center1 sc--label--${eventKey}`}>
          <FormGroup className={`sc--label--settings--${eventKey}`}>
            <FormControlLabel
              control={
                <Switch
                  checked={
                    eventValue
                  }
                  name={eventKey}
                  onChange={onLabelChange}
                />
              }
              labelPlacement="end"
              label={<Typography>{eventKey.replace(/_/, " ")}</Typography>}
            />
          </FormGroup>
        </div>
      );
    }
  }


  return (
    <div className="sc--settings--card">
      {labelsettings && labelsettings.loading === false && alert && (
        <Snackbar
          open={open}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            severity={labelsettings.hasErrors == true ? "error" : "success"}
            style={{ justifyContent: "center", margin: "10px 0" }}
          >
            <span>{labelsettings && labelsettings.message}</span>
          </Alert>
        </Snackbar>
      )}

      <Card
        sx={{ minWidth: '100%', boxShadow: "unset" }}
        className="sc--cardhead--details"
      >
        <CardHeader title="Label Settings" className="sc--cardhead--details" />
        {labelsettings && labelsettings.loading === true && <LinearProgress />}
        <Divider />
        {labelsettings && labelsettings.loading === false && (
          <CardContent className="sc--label--settings">
            <div style={{width:'500px',margin:'50px auto'}}>
            <div className="sc--form--center1">
              <FormGroup className="sc--label--settings--signature">
                <FormControlLabel
                  control={
                    <Switch
                      checked={values.is_signature_required}
                      name="is_signature_required"
                      onChange={onLabelChange}
                    />
                  }
                  label="Signature Required"
                  labelPlacement="end"
                />
              </FormGroup>
            </div>
            <div className="sc--form--center1" >
              <FormGroup className="sc--label--settings--label">
                <FormControlLabel
                  control={
                    <Switch
                      checked={values.is_detachable_label}
                      name="is_detachable_label"
                      onChange={onLabelChange}
                    />
                  }
                  label="Detachable Label"
                  labelPlacement="end"
                />
              </FormGroup>
            </div>
            {values.is_detachable_label && <>{options}</>}
            <div
              className="sc--form--center1"
            >
              <FormControl
                variant="standard"
                className="help-text-error"
                style={{ width: "60%", margin: "8px" }}
              >
                <InputLabel id="demo-simple-select-standard-label">
                  Label Format
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-error"
                  name="label"
                  defaultValue={
                    labelsettings &&
                    labelsettings.data &&
                    labelsettings.data.label_settings &&
                    labelsettings.data.label_settings.format
                      ? labelsettings &&
                        labelsettings.data &&
                        labelsettings.data.label_settings &&
                        labelsettings.data.label_settings.format
                      : label
                  }
                  onChange={onInputChange}
                  label="label"
                >
                  <MenuItem value={"pdf"}>PDF</MenuItem>
                  <MenuItem value={"zpl"}>ZPL</MenuItem>
                </Select>
              </FormControl>
            </div>
            {!fileName && (
              <>
                <div
                  className="sc--form--center1"
                >
                  <label htmlFor="contained-button-file">
                    <Input
                      accept="image/*"
                      id="contained-button-file"
                      onChange={onFileUpload}
                      multiple
                      type="file"
                    />
                    <Button
                      variant="contained"
                      component="span"
                      style={{ margin: "0 10px" }}
                    >
                      Upload label custom logo
                    </Button>
                  </label>
                </div>
              </>
            )}
            {fileName && (
              <>
                <div
                  className="sc--form--center1"
                >
                  <div style={{ fontSize: "10px", display: "flex" }}>
                    <img
                      className="labellogo"
                      src={fileName}
                      alt="logo-dark"
                      style={{
                        border: "1px solid #efefef",
                        maxWidth: "300px",
                        height: "100px",
                      }}
                    />
                    <p
                      style={{
                        position: "relative",
                        top: "-28px",
                        fontSize: "22px",
                        left: "-15px",
                        cursor: "pointer",
                      }}
                      onClick={removeCustom}
                    >
                      <DeleteIcon />
                    </p>
                  </div>
                </div>
              </>
            )}
            <div className="sc--form--center1">
              <p style={{ marginTop: "5px", marginBottom: "unset" }}>
                <b>Note:</b>
                <span style={{ fontSize: "14px" }}>
                  {" "}
                  Certain labels won't supports custom logo
                </span>
              </p>
            </div>
            <div className="sc--form--center1">
              <Button
                variant="outlined"
                onClick={onSubmit}
                style={{ margin: "15px 15px" }}
              >
                Save
              </Button>
            </div>
            </div>
          </CardContent>
        )}
      </Card>
    </div>
  );
};

export default LabelSettingsPage;
