import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import { fetchIntegratedPlatform } from '../../integratedSlice';
import { fetchBigCommerceData, configureStore, resetConfigureState } from './bigcommerceSlice';
import ShipmentUpdateFlag from '../../../../General/ShipmentUpdateFlag';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    height: '100%',
    padding: '20px 0'
}));
const BigcommerceIntegration = ({ storeName, platformName, storeParam, storeId }) => {
    const dispatch = useDispatch();

    const profile = useSelector(state => state.auth);
    const bigcommerce = useSelector(state => state.bigcommerce && state.bigcommerce.progress);
    const bigcommerceconfigdetails = useSelector(state => state.bigcommerce && state.bigcommerce.configure);
    const [alert, setAlert] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);

    const initialValues = {
        hash: '',
        clientid: '',
        clienttoken: '',
    }
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});

    const validator = (fieldValues) => {
        let temp = { ...errors }
        if ('hash' in fieldValues)
            temp.hash = fieldValues.hash ? "" : "This field is required."
        if ('clientid' in fieldValues)
            temp.clientid = fieldValues.clientid ? "" : "This field is required."
        if ('clienttoken' in fieldValues)
            temp.clienttoken = fieldValues.clienttoken ? "" : "This field is required."

        setErrors({
            ...temp
        })

        if (fieldValues === values)
            return Object.values(temp).every(x => x == "")
    }

    const onInputChange = e => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
        validator({ [name]: value })
    }

    const updateCallBackValue = (updateValue) => {
        setValues({
            ...values,
            ['is_update_shipment']: updateValue.is_update_shipment,
            ['is_update_shipment_on_create']: updateValue.is_update_shipment_on_create,
            ['is_update_shipment_on_close']: updateValue.is_update_shipment_on_close,
        });
    };
    const handleSubmit = e => {
        e.preventDefault()
        const isValid = validator(values)
        if (isValid) {
            dispatch(configureStore(values));
        }

    }
    useEffect(() => {
        if (bigcommerce && bigcommerce.data && bigcommerce.data.bigcommerce
             && bigcommerce.data.bigcommerce.store_hash) {
            setValues({
                ...values,
                hash: bigcommerce && bigcommerce.data && bigcommerce.data.bigcommerce &&
                 bigcommerce.data.bigcommerce.store_hash,
                clientid: bigcommerce && bigcommerce.data && bigcommerce.data.client_id,
                clienttoken: bigcommerce && bigcommerce.data && bigcommerce.data.bigcommerce &&
                 bigcommerce.data.bigcommerce.access_token,
                is_update_shipment: bigcommerce && bigcommerce.data && bigcommerce.data.is_update_shipment,
                ['is_update_shipment_on_create']: bigcommerce && bigcommerce.data && bigcommerce.data.is_update_shipment_on_create,
                ['is_update_shipment_on_close']: bigcommerce && bigcommerce.data && bigcommerce.data.is_update_shipment_on_close,
            })
        }
    }, [bigcommerce && bigcommerce.data && bigcommerce.data.bigcommerce && bigcommerce.data.bigcommerce.store_hash])

    const handleClose = () => {
        dispatch(resetConfigureState())
        setSnackOpen(false);
    };

    useEffect(() => {
        if (storeName && bigcommerceconfigdetails && bigcommerceconfigdetails.message) {
            setAlert(true)
            setSnackOpen(true)
        } else if (bigcommerceconfigdetails && bigcommerceconfigdetails.hasErrors == true) {
            setAlert(true)
            setSnackOpen(true)
        }
    }, [dispatch, bigcommerceconfigdetails && bigcommerceconfigdetails.message])

    useEffect(() => {
        if (storeName) {
            let apikey = profile.profile.api_token
            const data = { apikey: apikey, store_name: storeName, store_id: storeId }
            dispatch(fetchBigCommerceData(data));
        }
    }, [dispatch]);

    useEffect(() => {
        if (platformName) {
            setValues({
                ...values,
                hash: '',
                clientid: '',
                clienttoken: '',
                is_update_shipment: false
            });
        }
    }, [platformName]);
    useEffect(() => {       
        fetchIntegratedPlatform();
    }, [dispatch]);

    return (
        <>
            {alert &&
                <Snackbar
                    open={snackOpen}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    autoHideDuration={6000}
                    onClose={handleClose}
                >
                    <Alert
                        variant="filled"
                        severity=
                        {bigcommerceconfigdetails && bigcommerceconfigdetails.hasErrors === true ? "error" : "success"}
                        style={{ justifyContent: 'center', margin: "10px 0" }}
                    >
                        <span > {bigcommerceconfigdetails && bigcommerceconfigdetails.message}</span>
                    </Alert>
                </Snackbar>
            }
            <Grid container spacing={1}>
                <Grid item xs={12} md={12} style={{ textAlign: 'center' }}>
                    <Item style={{ marginBottom: '30px' }}>
                        <div>
                            <img
                                src="/assets/images/integration/bigcommerce.png"
                                alt="BRIGHTPEARL"
                                style={{ margin: 'auto', display: 'flex', maxWidth: '60px' }} />
                        </div>
                        {storeName &&
                            <div
                                className="sc--form--center"
                                style={{ margin: 'auto', textAlign: 'center', width: '30%', marginBottom: '10px' }}>
                                <TextField
                                    label="Store Hash"
                                    name="hash"
                                    value={values.hash}
                                    onChange={onInputChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    style={{ width: "100%", margin: '8px' }}
                                    variant="filled"
                                />
                            </div>
                        }
                        {!storeName &&
                            <div
                                className="sc--form--center"
                                style={{ margin: 'auto', textAlign: 'center', width: '30%', marginBottom: '10px' }}>
                                <TextField
                                    label="Store Hash"
                                    name="hash"
                                    value={values.hash}
                                    onChange={onInputChange}
                                    error={errors.hash}
                                    helperText={errors.hash}
                                    style={{ width: "100%", margin: '8px' }}
                                    variant="standard"
                                />
                            </div>
                        }
                        <div
                            className="sc--form--center"
                            style={{ margin: 'auto', textAlign: 'center', width: '30%', marginBottom: '10px' }}>
                            <TextField
                                label="Client Id"
                                name="clientid"
                                value={values.clientid}
                                onChange={onInputChange}
                                error={errors.clientid}
                                helperText={errors.clientid}
                                style={{ width: "100%", margin: '8px' }}
                                variant="standard"
                            />
                        </div>
                        <div
                            className="sc--form--center"
                            style={{ margin: 'auto', textAlign: 'center', width: '30%', marginBottom: '10px' }}>
                            <TextField
                                label="Access Token"
                                name="clienttoken"
                                value={values.clienttoken}
                                onChange={onInputChange}
                                error={errors.clienttoken}
                                helperText={errors.clienttoken}
                                style={{ width: "100%", margin: '8px' }}
                                variant="standard"
                            />
                        </div>
                        <ShipmentUpdateFlag
                                    updateFlag={values.is_update_shipment}
                                    onCreateFlag={values.is_update_shipment_on_create}
                                    onCloseFlag={values.is_update_shipment_on_close}
                                    updateCallBack={updateCallBackValue}
                                    message={" Do you want to update shipment information back to bigcommerce account?"}
                        />
                        <div
                            className="sc--form--center"
                            style={{ margin: 'auto', textAlign: 'center', width: '50%' }}>
                            {!storeName &&
                                <LoadingButton
                                    variant="contained"
                                    onClick={handleSubmit}
                                    loading={
                                        bigcommerceconfigdetails && bigcommerceconfigdetails.loading}
                                >Authorize <SendOutlinedIcon style={{ paddingLeft: '10px' }} />
                                </LoadingButton>
                            }
                            {storeName &&
                                <LoadingButton
                                    variant="contained"
                                    onClick={handleSubmit}
                                    loading={
                                        bigcommerceconfigdetails && bigcommerceconfigdetails.loading}
                                >Configure <SendOutlinedIcon style={{ paddingLeft: '10px' }} />
                                </LoadingButton>
                            }
                        </div>

                    </Item>
                </Grid>
            </Grid>

        </>
    )
}


export default BigcommerceIntegration;