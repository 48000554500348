import { createSlice } from '@reduxjs/toolkit';
import { LABELGENAPI} from '../../../API';

export const boxslice = createSlice({
    name: 'rate',
    initialState: {
        data: [],
        default: [],
        meta: [],
        loading: false,
        hasErrors: false,
        message: ""
    },
    reducers: {
        getBoxData: (state) => {
            return {
                ...state,
                loading: true,
                hasErrors: false
            }
        },
        getBoxDataSuccess: (state, action) => {
            return {
                ...state,
                data: action && action.payload && action.payload.data && action.payload.data.data,
                hasErrors: false,
                loading: false
            }
        },
        getBoxDataFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                hasErrors: true,
                message: action && action.payload && action.payload.data && action.payload.data.message
            }
        },
        postBoxData: (state) => {
            return {
                ...state,
                loading: true,
                hasErrors: false
            }
        },
        postBoxDataSuccess: (state, action) => {
            return {
                ...state,
                hasErrors: false,
                loading: false,
                message: action && action.payload && action.payload.data && action.payload.data.message
            }
        },
        postBoxDataFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                hasErrors: true,
                message: action && action.payload && action.payload.data && action.payload.data.message
            }
        },
        putBoxData: (state) => {
            return {
                ...state,
                loading: true,
                hasErrors: false
            }
        },
        putBoxDataSuccess: (state, action) => {
            return {
                ...state,
                hasErrors: false,
                loading: false,
                message: action && action.payload && action.payload.data && action.payload.data.message
            }
        },
        putBoxDataFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                hasErrors: true,
                message: action && action.payload && action.payload.data && action.payload.data.message
            }
        },
        deleteBoxData: (state) => {
            return {
                ...state,
                loading: true,
                hasErrors: false
            }
        },
        deleteBoxDataSuccess: (state, action) => {
            return {
                ...state,
                hasErrors: false,
                loading: false,
                message: action && action.payload && action.payload.data && action.payload.data.message
            }
        },
        deleteBoxDataFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                hasErrors: true,
                message: action && action.payload && action.payload.data && action.payload.data.message
            }
        },

        resetbox: (state) => {
            return {
                ...state,
                loading: false,
                hasError: false,
                message: "",
            }
        },


    }
})
export const { getBoxData, getBoxDataSuccess, getBoxDataFailure, postBoxData, postBoxDataSuccess, postBoxDataFailure,
    putBoxData, putBoxDataSuccess, putBoxDataFailure,
    deleteBoxData, deleteBoxDataSuccess, deleteBoxDataFailure,resetbox } = boxslice.actions;


export const fetchBoxData = (defaults = false) => dispatch => {
    dispatch(getBoxData());
    try {
        LABELGENAPI
            .get("/settings/box?default="+defaults)
            .then(function (response) {
                dispatch(getBoxDataSuccess(response));
            })
            .catch(function (error) {
                dispatch(getBoxDataFailure(error.response));
            });
    } catch (error) {
        dispatch(getBoxDataFailure());
    }
};

export const storeBoxData = data => dispatch => {
    dispatch(postBoxData());
    try {
        LABELGENAPI.post('/settings/box', data)
            .then(function (response) {
                dispatch(postBoxDataSuccess(response));
                dispatch(fetchBoxData());
            }).catch(function (error) {
                dispatch(postBoxDataFailure(error.response));
            })
    }
    catch (error) {
        dispatch(postBoxDataFailure(error.response));
    }
}
export const updateBoxData = (id, data) => dispatch => {
    dispatch(putBoxData());
    try {
        LABELGENAPI.patch('/settings/box/' + id, data)
            .then(function (response) {
                dispatch(putBoxDataSuccess(response));
                dispatch(fetchBoxData());
            }).catch(function (error) {
                dispatch(putBoxDataFailure(error.response));
            })
    }
    catch (error) {
        dispatch(putBoxDataFailure(error.response));
    }
}
export const destoryBoxData = (id) => dispatch => {
    dispatch(deleteBoxData());
    try {
        LABELGENAPI
            .delete("/settings/box/" + id)
            .then(function (response) {
                dispatch(deleteBoxDataSuccess(response));
                dispatch(fetchBoxData());
            })
            .catch(function (error) {
                dispatch(deleteBoxDataFailure(error.response));
            });
    } catch (error) {
        dispatch(deleteBoxDataFailure(error));
    }
};
export const resetBoxState = data => dispatch => {
    dispatch(resetbox());
}




export default boxslice.reducer;
