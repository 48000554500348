import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';
import Card from '@mui/material/Card';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import PlatformImage from '../../General/PlatformImage'
import PlatformDrawer from '../../General/PlatformDrawer';
import SettingsStatus from '../../../pages/General/SettingsStatus'
import { fetchchannelconfig } from '../../../pages/Integration/Notification/PlatformIntegrations/Email/emailSlice';


const IntegratedPage = ({ loading, data }) => {
    const channelconfig = useSelector(state => state.email && state.email.channelconfig);
    const [currentCategory, setCurrentCategory] = useState('all');
    const [orderDrawerVisible, setOrderDrawerVisible] = useState(false);
    const [platformName, setPlatformName] = useState('all');
    const [storeName, setStoreName] = useState('all');
    const [storeId, setStoreId] = useState('all');

    const platformCallback = (platform) => {
        setPlatformName(platform)
        setOrderDrawerVisible(true)
    }

    const orderOnClose = (values) => {
        setOrderDrawerVisible(false);
    };
    const dispatch = useDispatch();


    const columns = [
        {
            field: "notification_type",
            headerName: 'Platform',
            width: 100,
            resizable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <PlatformImage platform={params.row.notification_type} width={"35px"} />
                )
            }
        },
        {
            field: 'is_enabled',
            headerName: 'Status',
            flex: 1,
            resizable: false,
            renderCell: (params) => {
                return <SettingsStatus settingsStatus={params.row.is_enabled} />
            }
        },
        {
            field: 'Actions',
            resizable: false,
            renderCell: (params) => {
                return (
                    <div>
                        <span
                            style={{ marginRight: '20px' }}
                        >
                            <EditIcon
                                style={{ color: '#1890ff', cursor: 'pointer' }}
                                onClick={(e) => {
                                    platformCallback(params.row.notification_type)
                                }}
                            />
                        </span>
                    </div>
                )

            },
            flex: 1
        }
    ];
    useEffect(() => {

        dispatch(fetchchannelconfig());
    }, [dispatch])
    return (
        <>
            {loading && <Card loading={loading} />}
            <div className="integrated--stores--title" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h2 style={{ textAlign: 'left' }}>Connected Notification Platforms</h2>
                <span style={{ margin: '0 10px' }}>
                    <Link to="/reports/notifications" style={{ color: 'blue', textDecoration: 'none' }}>
                        <Button
                            variant="outlined"
                            style={{ margin: '15px auto' }}>
                            Notification Logs
                        </Button>
                    </Link>
                </span>
            </div>
            {channelconfig && channelconfig.loading === false &&
                <div className="integrated--stores--list">
                    <div style={{ width: '100%' }}>
                        <DataGrid
                            getRowId={(row) => row._id}
                            rows={channelconfig && channelconfig.data}
                            columns={columns}
                            autoHeight={true}
                            disableRowSelectionOnClick
                            disableColumnFilter={true}
                            disableColumnMenu={true}
                            hideFooter={true}
                            slots={{
                                loadingOverlay: LinearProgress,
                            }}
                            loading={
                                (channelconfig && channelconfig.loading)
                            }
                        />
                    </div>
                </div>
            }
            <PlatformDrawer
                visible={orderDrawerVisible}
                onClose={orderOnClose}
                platform={platformName}
            />

        </>
    )
}

export default IntegratedPage
