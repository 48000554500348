import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';


import {
    Typography,
    FormControl,
    Pagination,
    IconButton,
    Tooltip,
    Modal,
    Box,
    Button,
    Card,
    Alert,
    CardContent
} from "@mui/material";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';
import LinearProgress from '@mui/material/LinearProgress';
import SearchIcon from '@mui/icons-material/Search';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';


import {
    DataGrid,
    GridToolbarContainer
} from '@mui/x-data-grid';
import { fetchManifest, resetGenerateManifestNotification, dopostTrackingNumberCloseManifest } from './manifestsSlice';
import {
    getPickupSettings,
    postPickupSettings,
    resetPickupSettingsState,
  } from "../../../pages/Settings/PickupSettings/pickupSettingsSlice";
//Custom Components Import Starts
import Carrier from '../../General/Carrier'
import NorowsData from '../../General/NorowsData'
import Search from './Search'
import Notification from '../../../pages/General/Notification'
import ManifestDrawer from '../../../pages/General/ManifestDrawer'
import Printer from '../../../pages/General/Printer'
import UnclosedShipmentDrawer from '../../../pages/General/UnclosedShipmentDrawer'
import PendingShipmentsDrawer from '../../../pages/General/PendingShipmentsDrawer'

import Date from '../../../pages/General/Date'

//Custom Components Import Ends

const ManifestsPage = () => {

    const dispatch = useDispatch();
    const manifest = useSelector(state => state.manifests);
    const profile = useSelector(state => state.auth.profile);
    const pickupsettings = useSelector((state) => state.pickupsettings);
    const [meta, setMeta] = useState(manifest.meta);
    const [showFilter, setShowFilter] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [manifestId, setManifestId] = useState("");
    const [manifestUrl, setManifestUrl] = useState("");
    const [refreshIntervalId, setRefreshIntervalId] = useState("")
    const [manifestDrawerVisible, setManifestDrawerVisible] = useState(false);
    const [openAllManifestCloseConfirmModal, setOpenAllManifestCloseConfirmModal] = useState(false)
    const [unclosedShipmentsDrawerVisible, setUnclosedShipmentsDrawerVisible] = useState(false)
    const [pendingShipmentsDrawerVisible, setPendingShipmentsDrawerVisible] = useState(false)
    const [shipmentType, setShipmentType] = useState("");
    const [printUrl, setPrintUrl] = useState("")
    const [isPrinted, setIsPrinted] = useState(true)
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };
    const manifestCallback = (manifest_number, url = "") => {
        setManifestId(manifest_number);
        setManifestUrl(url)
        setManifestDrawerVisible(true)
    }

    const manifestOnClose = (values) => {
        setManifestDrawerVisible(false);
    };

    const closeAllUnmanifestedShipments = (value) => {
        let pickup_preference=false;
        if(value=="nopickup"){
            var postData = {
                "close_all_unclosed_shipments": true,
                "ignore_errors": true,
                "request_medium":"UI"
            }
        }else{
            if(value==true){
                pickup_preference=true
            }else if(value==false){
                pickup_preference=false
            }
            var postData = {
                "close_all_unclosed_shipments": true,
                "ignore_errors": true,
                "pickup_preference":pickup_preference,
                "request_medium":"UI"
            }
        }
        dispatch(dopostTrackingNumberCloseManifest(postData, profile.api_token, meta))
        setOpenAllManifestCloseConfirmModal(false);
    }

    const callUnclosedShipments = () => {
        setUnclosedShipmentsDrawerVisible(true);
    }

    const unclosedShipmentsOnClose = () => {
        setUnclosedShipmentsDrawerVisible(false);
    }

    const callPendingShipments = (manifest_number, shipment_type) => {
        setManifestId(manifest_number);
        setShipmentType(shipment_type);
        setPendingShipmentsDrawerVisible(true);
    }

    const pendingShipmentsOnClose = () => {
        setPendingShipmentsDrawerVisible(false);
    }

    const columns = [
        {
            field: 'manifest_number',
            headerName: 'Manifest Number',
            headerClassName: 'super-app-theme--header',
            sortable: false,
            minWidth: 200,
            renderCell: (params) => {
                return <span style={{ color: 'rgb(24, 144, 255)', cursor: 'pointer' }} onClick={e => { manifestCallback(params.row.manifest_number, params.row && params.row.carrier_manifest_url && params.row.carrier_manifest_url) }}>{params.row.manifest_number}</span>

            }
        },
        {
            field: 'status',
            headerName: 'Status',
            headerClassName: 'super-app-theme--header',
            minWidth: 200,
            renderCell: (params) => {
                if (params.row.status) {
                    return params.row.status
                } else {
                    return "-"
                }
            }
        },
       
        // {
        //     field: 'total_shipments',
        //     headerName: 'Manifested Shipments',
        //     headerClassName: 'super-app-theme--header',
        //     flex: 1,
        //     renderCell: (params) =>
        //     {
        //         if(params.row.total_shipments !== 0){
        //             return <div> <a href='#' onClick={() => { callPendingShipments(params.row.manifest_number, "total_shipments") }} >{params.row.total_shipments} </a></div>
        //         }else{
        //             return <div><span>{params.row.total_shipments}</span></div>
        //         }
        //     }
        // },
        // {
        //     field: 'received_shipments',
        //     headerName: 'Received Shipments',
        //     headerClassName: 'super-app-theme--header',
        //     flex: 1,
        //     renderCell: (params) =>
        //     {
        //         if(params.row.received_shipments !== 0){
        //             return <div> <a href='#' onClick={() => { callPendingShipments(params.row.manifest_number, "received_shipments") }} >{params.row.received_shipments} </a></div>
        //         }else{
        //             return <div><span>{params.row.received_shipments}</span></div>
        //         }
        //     }
        // },
        // {
        //     field: 'not_yet_received_shipments',
        //     headerName: 'Not Yet Received Shipments',
        //     headerClassName: 'super-app-theme--header',
        //     flex: 1,
        //     renderCell: (params) =>
        //     {
        //         if(params.row.not_yet_received_shipments !== 0){
        //             return <div> <a href='#' onClick={() => { callPendingShipments(params.row.manifest_number, "not_yet_received_shipments") }} >{params.row.not_yet_received_shipments} </a></div>
        //         }else{
        //             return <div><span>{params.row.not_yet_received_shipments}</span></div>
        //         }
        //     }
        // },
        {
            field: 'created_at',
            headerName: 'Closed Date',
            headerClassName: 'super-app-theme--header',
            minWidth: 200,
            renderCell: (params) => {
                return <Date date={params.row.created_at} />
            }
        },
        {
            field: 'action',
            headerName: 'Manifest Action',
            sortable: false,
            minWidth: 200,
            renderCell: (params) => {
                return <div>
                    <span style={{ marginRight: '10px' }}><Tooltip title="View" placement="left-start">
                        <IconButton>
                            <RemoveRedEyeIcon style={{ color: "#1890ff", cursor: 'pointer' }} onClick={() => window.open("https://shippingchimp.s3.us-east-2.amazonaws.com/labelgen/internal/manifest/" + profile.id + "/" + params.row.manifest_number + ".pdf", '_blank')} />
                        </IconButton>
                    </Tooltip>
                    </span>
                    <span style={{ marginRight: '10px' }}><Tooltip title="Print" placement="left-start">
                        <IconButton>
                            <LocalPrintshopIcon style={{ color: "#1890ff", cursor: 'pointer' }} onClick={() => { setPrintUrl("https://shippingchimp.s3.us-east-2.amazonaws.com/labelgen/internal/manifest/" + profile.id + "/" + params.row.manifest_number + ".pdf"); setIsPrinted(false) }} />
                        </IconButton>
                    </Tooltip>
                    </span>
                </div>
            }
        }
    ];

    const onSortChange = (currentSorting) => {
        setMeta({
            ...meta,
            ...{
                sorting: JSON.stringify(currentSorting)
            }
        });
    }

    const onChangePerPage = (event) => {
        setMeta({
            ...meta,
            ...{
                page: 1,
                per_page: event.target.value
            }
        });
    }

    const onChangePagination = (event, page) => {
        setMeta({
            ...meta,
            ...{
                page: page
            }
        });
    }

    const onMenuClick = (type, show) => {
        setShowSearch((type === "search" && show === true) ? true : false)
        setShowFilter((type === "filter" && show === true) ? true : false)
    }

    function CustomTotalRows() {

        return (
            <div className="custom-total-rows">
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    Showing {manifest && manifest.data && manifest.data.length} of {(manifest && manifest.meta && manifest.meta.total) ? manifest.meta.total : 0}
                </FormControl>
            </div>
        )
    }


    function CustomPagesizeoptions() {

        return (
            <div className="custom-page-size-options">
                <span>
                    Show <select
                        value={meta && meta.per_page}
                        onChange={onChangePerPage}
                    >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </select> entries
                </span>
            </div>
        )
    }

    function CustomToolbar() {

        return (
            <GridToolbarContainer>
                <CustomPagesizeoptions />
                <CustomTotalRows />
            </GridToolbarContainer>
        );
    }

    function CustomPagination() {

        return (
            <Pagination
                color="primary"
                shape="circular"
                page={manifest && manifest.meta && manifest.meta.current_page}
                count={manifest && manifest.meta && manifest.meta.last_page}
                onChange={onChangePagination}
            />
        );
    }

    useEffect(() => {
        setMeta({
            ...meta,
            ...{
                shippingchimp_manifest_number: "",
            }
        });
    }, [dispatch])

    useEffect(() => {
        dispatch(fetchManifest(meta))
    }, [meta]);

    useEffect(() => {
        if ((manifest && manifest.un_closed_shipments_count != 0 && manifest.manifest_all_in_progress == true) || (manifest && manifest.manifest_all_in_progress == true)) {
            if (refreshIntervalId == "") {
                let intervalId = setInterval(() => {
                    dispatch(fetchManifest(meta))
                }, 15000);
                setRefreshIntervalId(intervalId)
            }
        } else {
            if (manifest && manifest.manifest_all_in_progress == false && refreshIntervalId != "") {
                clearInterval(refreshIntervalId)
            }
        }
    }, [manifest.un_closed_shipments_count, manifest.manifest_all_in_progress]);

    useEffect(() => {
        dispatch(getPickupSettings());
      }, []);

    return (
        <>
            { (manifest && manifest.notification && manifest.notification.show) &&
                <Notification
                    is_open={manifest.notification.show}
                    messageType={manifest.notification.messageType}
                    messages={manifest.notification.messageContent}
                    handleClose={() => dispatch(resetGenerateManifestNotification())}
                />}
            {(manifest && manifest.manifestClose.notification && manifest.manifestClose.notification.show) &&
                <Notification
                    is_open={manifest.manifestClose.notification.show}
                    messageType={manifest.manifestClose.notification.messageType}
                    messages={manifest.manifestClose.notification.messageContent}
                    handleClose={() => dispatch(resetGenerateManifestNotification())}
                />}
            <Printer isPrinted={isPrinted} setIsPrinted={setIsPrinted} print_url={printUrl} />
            <Modal
                open={openAllManifestCloseConfirmModal}
                aria-labelledby="manifest-confirm-modal-title"
                aria-describedby="manifest-confirm-modal-description"
            >
                <Box sx={style}>
                    <Typography style={{ textAlign: "center" }} id="manifest-confirm-modal-description" sx={{ mt: 2 }}>
                        This action will close manifest for ALL shipments. <br />Do you want to proceed?
                    </Typography>
                    { (pickupsettings &&
                    pickupsettings.data &&
                    pickupsettings.data.pickup_settings &&
                    pickupsettings.data.pickup_settings.pickup_preference &&
                    pickupsettings.data.pickup_settings.pickup_preference.pickup==true) && (pickupsettings &&
                    pickupsettings.data &&
                    pickupsettings.data.pickup_settings &&
                    pickupsettings.data.pickup_settings.pickup_preference &&
                    pickupsettings.data.pickup_settings.pickup_preference.dropoff==true) ?
                    <Typography style={{ marginTop: "30px", textAlign: "center" }}>
                        <Button variant="contained"  onClick={() => { closeAllUnmanifestedShipments(true) }}>Yes, Arrange pickup</Button>
                        <Button variant="contained" style={{ marginLeft: "15px" }} onClick={() => { closeAllUnmanifestedShipments(false) }}>Yes, i will drop off</Button>
                        <Button variant="outlined" style={{ marginLeft: "15px" }} onClick={() => { setOpenAllManifestCloseConfirmModal(false) }}>No</Button>
                    </Typography> :  <Typography style={{ marginTop: "30px", textAlign: "center" }}>
                        <Button variant="contained"  onClick={() => { closeAllUnmanifestedShipments("nopickup") }}>Yes</Button>
                        <Button variant="outlined" style={{ marginLeft: "15px" }} onClick={() => { setOpenAllManifestCloseConfirmModal(false) }}>No</Button>
                    </Typography>
                    }
                </Box>
            </Modal>
            <div>
                <div className="sccardhead">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="h5" gutterBottom component="div">
                            Open Manifests
                    </Typography>
                    </div>
                </div>
                <Card sx={{ minWidth: 1000, boxShadow: 'unset', border: "1px solid rgba(224, 224, 224, 1)" }}>
                    <CardContent >
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2} style={{ display: "flex", alignItems: "center" }}>
                                <Grid item xs={2} alignItems="center">
                                    <div>Total packages : {(manifest && manifest.un_closed_shipments_count && manifest.un_closed_shipments_count !== 0)
                                        ? <a href='#' onClick={() => { callUnclosedShipments() }} >{manifest.un_closed_shipments_count} </a> : <span>{manifest.un_closed_shipments_count}</span>}</div>
                                </Grid>
                                <Grid item xs={2}>
                                    <LoadingButton
                                        variant="contained"
                                        style={{ marginLeft: "20px" }}
                                        loading={manifest && manifest.manifestClose && manifest.manifestClose.loading}
                                        disabled={
                                            (manifest && manifest.manifestClose && manifest.manifestClose.loading) ||
                                            manifest.manifest_all_in_progress ||
                                            manifest.un_closed_shipments_count == 0
                                        }
                                        onClick={() => setOpenAllManifestCloseConfirmModal(true)}
                                    >
                                        {(manifest.manifest_all_in_progress == true) ? "Closing in progress" : "Close All Manifest"}
                                    </LoadingButton>
                                </Grid>
                                <Grid item xs={6}>
                                    <div>
                                        {(manifest.manifest_all_in_progress == true) && <Alert severity="warning">Please wait, closing manifest for all the shipments. Page will auto refresh until the process is completed</Alert>}
                                    </div>
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </div>
            <div className="scmaincard">
                <div className="sccardhead">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="h5" gutterBottom component="div">
                            Closed Manifests
                    </Typography>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ul className="features-list">
                            <li>
                                <Tooltip title="Seacrh" placement="top-start">
                                    <IconButton style={(showSearch) ? { backgroundColor: 'rgb(230, 230, 230)' } : {}} onClick={() => onMenuClick("search", !showSearch)}>
                                        <SearchIcon style={{ color: 'rgb(25, 118, 210)' }} />
                                    </IconButton>
                                </Tooltip>
                            </li>
                            {/* <li>
                                <Tooltip title="Filter" placement="top-start">
                                    <IconButton onClick={() => onMenuClick("filter", !showFilter)}>
                                        <FilterAltOutlinedIcon style={{ color: 'rgb(25, 118, 210)' }} />
                                    </IconButton>
                                </Tooltip>
                            </li> */}
                        </ul>
                    </div>
                </div>
                <Search visible={showSearch} meta={meta} setMeta={setMeta} />
                <div style={{ width: '100%', background: '#fff', marginTop: "20px" }}>
                    <DataGrid
                        style={{ paddingTop: "37px" }}
                        getRowId={(row) => row._id}
                        rows={manifest && manifest.data}
                        columns={columns}
                        autoHeight={true}
                        disableColumnFilter={true}
                        disableColumnMenu={true}
                        sortingMode="server"
                        onSortModelChange={(newSortModel) => onSortChange(newSortModel)}
                        sortModel={meta && meta.sorting && JSON.parse(meta.sorting)}
                        disableRowSelectionOnClick
                        slots={{
                            loadingOverlay: LinearProgress,
                            pagination: CustomPagination,
                            toolbar: CustomToolbar,
                            NorowsData
                        }}
                        loading={
                            (manifest && manifest.loading)
                        }
                    />
                </div>

            </div>
            {manifestId &&
                <ManifestDrawer
                    visible={manifestDrawerVisible} manifestId={manifestId} manifestOnClose={manifestOnClose} manifestUrl={manifestUrl}
                />
            }
            {manifestId && pendingShipmentsDrawerVisible && shipmentType!="" &&
                <PendingShipmentsDrawer
                    visible={pendingShipmentsDrawerVisible} manifestId={manifestId} pendingShipmentsOnClose={pendingShipmentsOnClose} shipmentType={shipmentType}
                />
            }
            {manifest && manifest.un_closed_shipments_count != 0 && unclosedShipmentsDrawerVisible &&
                <UnclosedShipmentDrawer
                    visible={unclosedShipmentsDrawerVisible} unclosedShipmentsOnClose={unclosedShipmentsOnClose}
                />
            }
        </>
    );
}

export default ManifestsPage;
