import React, { useEffect, useState, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { doPasswordReset, resetNotification} from './authSlice';
const PasswordresetPage = () => {
    const auth = useSelector(state => state.auth);    
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [error, setErrors] = useState(null);
    const [emailerror, setemailErrors] = useState(null);
    const validateerror = document.getElementsByClassName("validateErrors")
    const [visible, SetVisible] = useState(false)
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [alert, setAlert] = useState(false);
    // console.log(validateerror)
    if (validateerror) {
        setTimeout(function () {
            SetVisible(true);
        }, 5000);
    }

    const handleSubmit = () => {
        if (email != '') {
            const values = { 'email': email }
            console.log(values)
            dispatch(doPasswordReset(values))
        } else {
            setErrors("Email must be required");
        }
    }

    const emailValidate = () => {
        const emailValidation = !/\S+@\S+\.\S+/.test(email);
        if (emailValidation) {
            setemailErrors("Email address is invalid");
        } else {
            setemailErrors(null);
        }
    };

    const snackhandleClose = () => {
        setAlert(false)
        setSnackOpen(false)
        dispatch(resetNotification())
    };

    useEffect(() => {
        if (auth && auth.notification && auth.notification.messageContent) {
            setAlert(true)
            setSnackOpen(true)
        }
    }, [auth])


    return (
        <>
            {alert &&
                <Snackbar
                    open={snackOpen}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center"
                    }}
                    autoHideDuration={6000}
                    onClose={snackhandleClose}
                >
                    <Alert
                        severity={auth && auth.notification && auth.notification.messageType === "error" ? "error" : "success"}
                        style={{ justifyContent: 'center', margin: "10px 0" }}>
                        <span >{auth && auth.notification && auth.notification.messageContent}</span>
                    </Alert>
                </Snackbar>
            }
            <div className="vertical-center">
                <Paper elevation={3} sx={{ m: "10px", padding: "48px 32px" }} square={false}>
                    <div className="loginlayout--image">
                        <a href="/" className="logo-link">
                            <img className="loginlogo" src="/assets/images/shippingchimp_logo.png" alt="logo-dark" />
                        </a>
                    </div>
                    <Stack
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1 }, width: "400px"
                        }}
                        Validate
                        autoComplete="off"
                    >
                        {!visible && <> {error && <p className="validateErrors" style={{ color: 'red' }}>{error}</p>}</>}
                        <TextField id="email" label="Email" size="small" required variant="outlined"
                            onInput={e => setEmail(e.target.value)} onBlur={emailValidate} />
                        {emailerror && <p style={{ color: 'red' }}>{emailerror}</p>}
                        <Button variant="contained" onClick={handleSubmit}>Get Password from your email</Button>
                    </Stack>
                    <div className="logindefaultview--signup">
                        <p style={{ float: 'right', fontSize: '14px', color: '#75757f' }}>Return to   <a href="/login">Login</a></p>
                    </div>
                    {(auth.notification.messageContent && auth.notification.messageContent.data) && <><div style={{ color: 'red' }}>{auth.notification.messageContent.data.msg}</div></>}

                </Paper>
            </div>
        </>
    )

}

export default PasswordresetPage