import { createSlice } from '@reduxjs/toolkit';
import { OMSAPI } from '../../../../../API';

export const bigcommerceslice = createSlice({
    name: 'bigcommerce',
    initialState: {
        progress: {
            data: {},
            location: [],
            message: "",
            loading: false,
            hasErrors: false
        },
        configure: {
            data: {},
            message: "",
            loading: false,
            hasErrors: false
        }
    },
    reducers: {
        fetchBigCommerceDataDetails: (state) => {
            return {
                ...state,
                progress: {
                    loading: true
                }
            }
        },
        fetchBigCommerceDataDetailsSuccess: (state, action) => {
            return {
                ...state,
                progress: {
                    message: action && action.payload && action.payload.data && action.payload.data.messages,
                    data: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data,
                    location: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.location,
                    loading: false,
                    hasErrors: false,
                }
            }
        },
        fetchBigCommerceDataDetailsFailure: (state, action) => {
            return {
                ...state,
                progress: {
                    loading: false,
                    hasErrors: false,
                }
            }
        },
        postBigCommerceConfigure: (state) => {
            return {
                ...state,
                configure: {
                    loading: true
                }
            }
        },
        postBigCommerceConfigureSuccess: (state, action) => {
            return {
                ...state,
                configure: {
                    data: {},
                    message: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.message,
                    loading: false,
                    hasErrors: false,
                }
            }
        },
        postBigCommerceConfigureFailure: (state, action) => {
            return {
                ...state,
                configure: {
                    message: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.message,
                    loading: false,
                    hasErrors: true,
                }
            }
        },
        resetConfigureStateDetails: (state, action) => {
            return {
                ...state,
                configure: {
                    data: {},
                    message: '',
                    loading: false,
                    hasErrors: false,
                }
            }
        },
    }
})
export const { fetchBigCommerceDataDetails, fetchBigCommerceDataDetailsSuccess, fetchBigCommerceDataDetailsFailure,
    postBigCommerceConfigure, postBigCommerceConfigureSuccess, postBigCommerceConfigureFailure, resetConfigureStateDetails } = bigcommerceslice.actions;

export const configureStore = data => dispatch => {
    dispatch(postBigCommerceConfigure());
    try {
        OMSAPI.post('/bigcommerce/configure', data)
            .then(function (response) {
                dispatch(postBigCommerceConfigureSuccess(response));
            }).catch(function (error) {
                dispatch(postBigCommerceConfigureFailure(error.response));
            })
    }
    catch (error) {
        dispatch(postBigCommerceConfigureFailure(error.response));
    }
}

export const fetchBigCommerceData = data => dispatch => {

    dispatch(fetchBigCommerceDataDetails())
    try {
        OMSAPI.post('/bigcommerce/show', data)
            .then(function (response) {
                dispatch(fetchBigCommerceDataDetailsSuccess(response));
            }).catch(function (error) {
                dispatch(fetchBigCommerceDataDetailsFailure());
            })
    }
    catch (error) {
        dispatch(fetchBigCommerceDataDetailsFailure())
    }
}

export const resetConfigureState = data => dispatch => {
    dispatch(resetConfigureStateDetails());
}
export default bigcommerceslice.reducer;
