import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import { fetchIntegratedPlatform } from '../../integratedSlice';
import { fetchShipStationData, configureStore, resetConfigureState } from './shipstationSlice';
import ShipmentUpdateFlag from '../../../../General/ShipmentUpdateFlag';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    height: '100%',
    padding: '20px 0'
}));
const ShipStationIntegration = ({ storeName, platformName, storeParam, storeId }) => {
    const dispatch = useDispatch();

    const profile = useSelector(state => state.auth);
    const shipstation = useSelector(state => state.shipstation && state.shipstation.progress);
    const shipstationconfigdetails = useSelector(state => state.shipstation && state.shipstation.configure);
    const [alert, setAlert] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const initialValues = {
        api_key: '',
        api_secret: '',
    }
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});

    const validator = (fieldValues) => {
        let temp = { ...errors }
        if ('api_key' in fieldValues)
            temp.api_key = fieldValues.api_key ? "" : "This field is required."
        if ('api_secret' in fieldValues)
            temp.api_secret = fieldValues.api_secret ? "" : "This field is required."

        setErrors({
            ...temp
        })

        if (fieldValues === values)
            return Object.values(temp).every(x => x == "")
    }

    const onInputChange = e => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
        validator({ [name]: value })
    }

    const updateCallBackValue = (updateValue) => {
        setValues({
            ...values,
            ['is_update_shipment']: updateValue.is_update_shipment,
            ['is_update_shipment_on_create']: updateValue.is_update_shipment_on_create,
            ['is_update_shipment_on_close']: updateValue.is_update_shipment_on_close,
        });
    };

    const handleSubmit = e => {
        e.preventDefault()
        const isValid = validator(values)
        if (isValid) {
            dispatch(configureStore(values));
        }
    }

    useEffect(() => {
        if (shipstation && shipstation.data
             && shipstation.data.api_key) {
            setValues({
                ...values,
                api_key: shipstation && shipstation.data
                && shipstation.data.api_key,
                api_secret: shipstation && shipstation.data &&
                shipstation.data.api_secret,
                is_update_shipment: shipstation && shipstation.data && shipstation.data.is_update_shipment,
                ['is_update_shipment_on_create']: shipstation && shipstation.data  && shipstation.data.is_update_shipment_on_create,
                ['is_update_shipment_on_close']: shipstation  && shipstation.data  && shipstation.data.is_update_shipment_on_close,
            })
        }
    }, [shipstation && shipstation.data && shipstation.data.api_key])

    const handleClose = () => {
        dispatch(resetConfigureState())
        setSnackOpen(false);
    };

    useEffect(() => {
        if (shipstationconfigdetails && shipstationconfigdetails.message) {
            setAlert(true)
            setSnackOpen(true)
        } else if (shipstationconfigdetails && shipstationconfigdetails.hasErrors == true) {
            setAlert(true)
            setSnackOpen(true)
        }
    }, [dispatch, shipstationconfigdetails && shipstationconfigdetails.message])

    useEffect(() => {
        if (storeName) {
            let apikey = profile.profile.api_token
            const data = { apikey: apikey, store_name: storeName, store_id: storeId }
            dispatch(fetchShipStationData(data));
        }
    }, [dispatch]);

    useEffect(() => {
        if (platformName) {
            setValues({
                ...values,
                api_key: '',
                api_secret: '',
                is_update_shipment: false
            });
        }
    }, [platformName]);

    useEffect(() => {       
        fetchIntegratedPlatform();
    }, [dispatch]);

    return (
        <>
         {alert &&
                <Snackbar
                    open={snackOpen}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    autoHideDuration={6000}
                    onClose={handleClose}
                >
                    <Alert
                        variant="filled"
                        severity=
                        {shipstationconfigdetails && shipstationconfigdetails.hasErrors === true ? "error" : "success"}
                        style={{ justifyContent: 'center', margin: "10px 0" }}
                    >
                        <span > {shipstationconfigdetails && shipstationconfigdetails.message}</span>
                    </Alert>
                </Snackbar>
            }
              <Grid container spacing={1}>
                <Grid item xs={12} md={12} style={{ textAlign: 'center' }}>
                    <Item style={{ marginBottom: '30px' }}>
                        <div>
                            <img
                                src="/assets/images/integration/shipstation.png"
                                alt="SHIPSTATION"
                                style={{ margin: 'auto', display: 'flex', maxWidth: '60px' }} />
                        </div>
                       
                        <div
                            className="sc--form--center"
                            style={{ margin: 'auto', textAlign: 'center', width: '30%', marginBottom: '10px' }}>
                            <TextField
                                label="API KEY"
                                name="api_key"
                                value={values.api_key}
                                onChange={onInputChange}
                                error={errors.api_key}
                                helperText={errors.api_key}
                                style={{ width: "100%", margin: '8px' }}
                                variant="standard"
                            />
                        </div>
                        <div
                            className="sc--form--center"
                            style={{ margin: 'auto', textAlign: 'center', width: '30%', marginBottom: '10px' }}>
                            <TextField
                                label="API Secret"
                                name="api_secret"
                                value={values.api_secret}
                                onChange={onInputChange}
                                error={errors.api_secret}
                                helperText={errors.api_secret}
                                style={{ width: "100%", margin: '8px' }}
                                variant="standard"
                            />
                        </div>
                        <ShipmentUpdateFlag
                                    updateFlag={values.is_update_shipment}
                                    onCreateFlag={values.is_update_shipment_on_create}
                                    onCloseFlag={values.is_update_shipment_on_close}
                                    updateCallBack={updateCallBackValue}
                                    message={" Do you want to update shipment information back to shipstation account?"}
                        />
                        <div
                            className="sc--form--center"
                            style={{ margin: 'auto', textAlign: 'center', width: '50%' }}>
                                <LoadingButton
                                    variant="contained"
                                    onClick={handleSubmit}
                                    loading={
                                        shipstationconfigdetails && shipstationconfigdetails.loading}
                                >Configure <SendOutlinedIcon style={{ paddingLeft: '10px' }} />
                                </LoadingButton>
                        </div>

                    </Item>
                </Grid>
            </Grid>

        </>
    )
}


export default ShipStationIntegration;