import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

// mui
import Drawer from '@mui/material/Drawer';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import SendIcon from '@mui/icons-material/Send';
import LinearProgress from '@mui/material/LinearProgress';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
// Our components
import RateDrawer from '../../../pages/General/RateDrawer'
import SenderAddressSelect from '../../../pages/General/NewOrder/SenderAddressSelect'
import RecipientAddressSelect from '../../../pages/General/NewOrder/RecipientAddressSelect'
import LineItems from '../../General/NewOrder/LineItems';
import MultiPackageDimensions from '../../../pages/General/NewOrder/MultiPackageDimensions';
import Notification from '../../../pages/General/Notification'
import Printer from '../../../pages/General/Printer'
// slice
import { doGetRate, resetRateState } from '../../../pages/Shipments/NewShipment/rateSlice';
import { fetchAddressBilling } from '../../../pages/Settings/AddressBilling/addressBillingSlice';
import { fetchSenderAddress } from '../../../pages/Settings/AddressSender/addressSenderSlice';
import { fetchCountry, fetchState,fetchMultiStates } from '../../../pages/General/countryStateCitySlice';
import { fetchBoxData } from '../../../pages/Settings/Box/boxSlice';
import { dopostDataCreateShipment } from '../../../pages/Orders/ordersSlice';
import { fetchProfile } from '../../Settings/Profile/profileSlice';

const NewOrderCreateDrawer = ({ visible, reportName, newOrderFormData, isRateAutoEnabled, meta, onClose, isDuplicateOrder, isReturnOrder, setNewOrderFormData, setNewOrderCreateDrawerVisible = () => { }, choosenTrackingNumber = 0, isShipmentCreationFlow = false,isLabelPrint= true,isFailedShipment=false,setIsReturnOrder,currentOrderId="" }) => {
    const dispatch = useDispatch();
    // state
    const country_code = useSelector(state => state.countrystate.country);
    const orders = useSelector(state => state.orders.createShipment);
    const lineItemsWeight = useSelector(state => state.orders && state.orders.lineItemsWeight);
    const state = useSelector(state => state.countrystate.state);
    const box = useSelector(state => state.box);
    const addresssender = useSelector(state => state.addresssender);
    const addressbilling = useSelector(state => state.addressbilling);
    const profile = useSelector(state => state.profile);
    const rates = useSelector(state => state.rates);
    // const
    const [formDataValues, setFormDataValues] = useState("")
    const [senderAddressObjectId, setSenderAddressObjectId] = useState('');
    const [rateDrawervisible, setRateDrawervisible] = useState(false);
    const [lineItemId, setLineItemId] = useState([]);
    const [drawer, setDrawer] = useState(true);
    const [isLabelOpened, setIsLabelOpened] = useState(true)
    const [isRateShown, setIsRateShown] = useState(false)
    const [showFieldErrors, setShowFieldErrors] = useState(false)
    const [snackOpen, setsnackOpen] = useState(false)
    const [orderId, setOrderId] = useState("")
    const [printUrl, setPrintUrl] = useState("")
    const [isPrinted, setIsPrinted] = useState(true)
    const [customLineItems, setCustomLineItems] = useState([])
    const [customboxDimensions, setcustomboxDimensions] = useState({})
    const [isFailedLabelPrinted, setIsFailedLabelPrinted] = useState(true)
    const [createButtonDisable,setCreateButtonDisable] = useState(false)
    const [skus, setSkus] = useState([])
    const [boxValues, setBoxValues] = useState([])
    const [boxDimensions, setboxDimensions] = useState([]);
    const initialDimValues = {
        "length": "",
        "width": "",
        "height": "",
        "dimension_unit": "",
        "actual_weight": "",
        "weight_unit": "",
        "choosenmeasurement": 0,
        "insurance_amount": "100"
    }
    const [defaultBoxDim, setDefaultBoxDim] = useState(initialDimValues)
    const [multiPackageFields, setMultiPackageFields] = useState([])
    const [errors, setErrors] = useState({});
    const [dimensionsErrors, setDimensionsErrors] = useState({});
    useEffect(() => {
        let data = [...multiPackageFields];
        data.splice(0, 1)
        data = [{ ...defaultBoxDim }]
        setMultiPackageFields(data)
    }, [defaultBoxDim])

    useEffect(() => {
        if (rates && rates.message && rates.message.length !== 0) {
            setsnackOpen(true)
        }
    }, [rates]);

    // const handleClose = () => {
    //     dispatch(resetRateState())
    //     setsnackOpen(false)
    // };

    const onMultiPackageAdd = () => {
        let defaultBoxDimCopy = { ...defaultBoxDim }
        setMultiPackageFields([...multiPackageFields, defaultBoxDimCopy])
    }

    const onMultiPackageRemove = (index) => {
        let data = [...multiPackageFields];
        data.splice(index, 1)
        setMultiPackageFields(data)
    }
 
    const handleMultiPackageDimensionInputChange = (dimDataIndex, event) => {
        let packageFieldData = [...multiPackageFields];
        if (event.target.name == "choosenmeasurement") {
            if (event.target.value != 0) {
                box && box.data && box.data.map((boxData) => {
                    if (boxData._id === event.target.value) {
                        packageFieldData[dimDataIndex] = {
                            "length": parseFloat(boxData.box_length).toFixed(2),
                            "width": parseFloat(boxData.box_width).toFixed(2),
                            "height": parseFloat(boxData.box_height).toFixed(2),
                            "dimension_unit": boxData.box_length_unit,
                            "actual_weight": (lineItemsWeight && lineItemsWeight.data && lineItemsWeight.data.length>0 && customLineItems.length >0) ? getLineItemWeight(customLineItems) :parseFloat(boxData.box_weight).toFixed(2),
                            "weight_unit": (lineItemsWeight && lineItemsWeight.data && lineItemsWeight.data.length>0 && customLineItems.length >0) ? getLineItemWeightUnit(customLineItems) : boxData.box_weight_unit,
                            "choosenmeasurement": event.target.value,
                            "insurance_amount": "100"
                        }
                    }
                })
            } else {
                packageFieldData[dimDataIndex] = {
                    "length": "",
                    "width": "",
                    "height": "",
                    "dimension_unit": "",
                    "actual_weight": "",
                    "weight_unit": "",
                    "choosenmeasurement": event.target.value,
                    "insurance_amount": "100"
                }
            }
        } else {
            packageFieldData[dimDataIndex][event.target.name] = event.target.value;
        }
        setMultiPackageFields(packageFieldData);
    }

    const rateDraweronClose = (values) => {
        setRateDrawervisible(false);
    };
    
    const initialFValues = {
        name: '',
        company: '',
        phone: '',
        email: '',
        address: '',
        address2: '',
        city: '',
        postal_code: '',
        country_code: '',
        weight: '',
        box_weight: '',
        actual_weight: '',
        province: '',
        preferred_sender_id: '',
        length: '',
        height: '',
        width: '',
        dimension_unit: 'in',
        weight_unit: 'lb',
        insurance_amount: "",
        choosemeasurement: '',
        preferred_carrier: '',
        preferred_carrier_reference_id: '',
        preferred_service: '',
        sender_reference_no:'',
        return_shipment_sender_id:"",
        shipment_type:"PACKAGE",
        is_signature_required:(profile && profile.profile && typeof profile.profile.is_signature_required !== "undefined") ? profile.profile.is_signature_required :false
    }
    const [values, setValues] = useState(newOrderFormData !== '' ? newOrderFormData : initialFValues);

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        let dimensionsErrorsTemp = {}
        if ('name' in fieldValues)
            temp.name = (fieldValues.name.trim() == "") ? "This field is required." : ""
        if ('address' in fieldValues)
            temp.address = (fieldValues.address.trim() == "") ? "This field is required." : ""
        if ('city' in fieldValues)
            temp.city = (fieldValues.city.trim() == "") ? "This field is required." : ""
        if ('postal_code' in fieldValues)
            temp.postal_code = (fieldValues.postal_code.trim() == "") ? "This field is required." : ""
        if (fieldValues && fieldValues.postal_code && fieldValues.postal_code.match(/[^0-9a-z-\s]/i)) {
            temp.postal_code = "Postal code only accepts alpha numeric"
        }
        if ('country_code' in fieldValues){
            if(fieldValues.country_code.trim() == ""){
                temp.country_code = "This field is required."
            }else{
                temp.country_code = ""
                if(fieldValues.country_code.trim().toUpperCase() == "US" || fieldValues.country_code.trim().toUpperCase() == "CA"){
                    if ('province' in fieldValues)
                        temp.province = (fieldValues.province.trim() == "") ? "This field is required." : ""
                }
            }
        }else{
            if ('province' in fieldValues)
                temp.province = (fieldValues.province.trim() == "") ? "This field is required." : ""
        }
        if(!isReturnOrder){
            if ('preferred_sender_id' in fieldValues)
                temp.preferred_sender_id = (fieldValues.preferred_sender_id.trim() == "") ? "This field is required." : ""
        }
        if(isReturnOrder){
            if ('sender_name' in fieldValues)
            temp.sender_name = (fieldValues.sender_name.trim() == "") ? "This field is required." : ""
            if ('sender_address' in fieldValues)
                temp.sender_address = (fieldValues.sender_address.trim() == "") ? "This field is required." : ""
            if ('sender_city' in fieldValues)
                temp.sender_city = (fieldValues.sender_city.trim() == "") ? "This field is required." : ""
            if ('sender_postal_code' in fieldValues)
                temp.sender_postal_code = (fieldValues.sender_postal_code.trim() == "") ? "This field is required." : ""
            if (fieldValues && fieldValues.sender_postal_code && fieldValues.sender_postal_code.match(/[^0-9a-z-\s]/i)) {
                temp.sender_postal_code = "Postal code only accepts alpha numeric"
            }
            if ('sender_country_code' in fieldValues)
                temp.sender_country_code = (fieldValues.sender_country_code.trim() == "") ? "This field is required." : ""
            if ('sender_state_code' in fieldValues)
                temp.sender_state_code = (fieldValues.sender_state_code.trim() == "") ? "This field is required." : ""
        }

        if (multiPackageFields && multiPackageFields.length != 0) {
            let dimError = false
            multiPackageFields.forEach((dim_data, dim_index) => {
                dimensionsErrorsTemp[dim_index] = {
                    "length": "",
                    "width": "",
                    "height": "",
                    "dimension_unit": "",
                    "actual_weight": "",
                    "weight_unit": "",
                    "insurance_amount": ""
                }

                if (dim_data["length"] == "" || dim_data["length"] <= 0) {
                    dimensionsErrorsTemp[dim_index]["length"] = "length is required and should be valid"
                    dimError = true
                }

                if (dim_data["width"] == "" || dim_data["width"] <= 0) {
                    dimensionsErrorsTemp[dim_index]["width"] = "width is required and should be valid"
                    dimError = true
                }

                if (dim_data["height"] == "" || dim_data["height"] <= 0) {
                    dimensionsErrorsTemp[dim_index]["height"] = "height is required and should be valid"
                    dimError = true
                }

                if (dim_data["dimension_unit"] == "" || (dim_data["dimension_unit"] != "in" && dim_data["dimension_unit"] != "cm")) {
                    dimensionsErrorsTemp[dim_index]["dimension_unit"] = "dim unit is required and should be in or cm"
                    dimError = true
                }

                if (dim_data["actual_weight"] == "" || dim_data["actual_weight"] <= 0) {
                    dimensionsErrorsTemp[dim_index]["actual_weight"] = "actual weight is required and should be valid"
                    dimError = true
                }

                if (dim_data["weight_unit"] == "" || (dim_data["weight_unit"] != "lb" && dim_data["weight_unit"] != "kg")) {
                    dimensionsErrorsTemp[dim_index]["weight_unit"] = "weight unit is required and should be kg or lb"
                    dimError = true
                }

                if(dim_data["insurance_amount"] != "" && typeof parseFloat(dim_data["insurance_amount"]) != "number"){
                    dimensionsErrorsTemp[dim_index]["insurance_amount"] = "enter valid insurance amount"
                    dimError = true
                }
            })

            setDimensionsErrors(dimensionsErrorsTemp)
            if (dimError == true) {
                return false
            }
        }

        setErrors({
            ...temp
        })
        if (fieldValues === values)
            return Object.values(temp).every(x => x === "")
    }

    const dispatchInputChangeCallback = (name, value) => {
        var newvalue={};
        var intialValue=value.trim();
        var addressValue=intialValue.split(/\r?\n/)
        if(addressValue.length>1){
        newvalue["name"]= addressValue[0];
     
        if(addressValue[1].length>35){
            var index = 0,
            res = [];
            while ((index = addressValue[1].indexOf(' ', index + 1)) > 0) {
            res.push(index);
            }
            var a=""
            for (var i = res.length; i > 0; i--) {
                if(res[i]<35){
                    a= res[i]
                    break
                }
            }
        const result = [addressValue[1].slice(0, a), addressValue[1].slice(a)];
        newvalue["address"]= result[0];
        newvalue["address2"]= result[1];
        }else{
            newvalue["address"]= addressValue[1];
        }
        if(addressValue.length>2){
            let multiLinePostal=addressValue[2];
            if(multiLinePostal.includes(',')){
                let multiLinePostalSplit=multiLinePostal.split(',');
                newvalue["city"]= multiLinePostalSplit[0];
                if(multiLinePostal.includes(' ')){
                    let multiLineStateCountrySplit=multiLinePostalSplit[1].split(' ');
                    let multiLineStateCountrySplitFilter=multiLineStateCountrySplit.filter(n => n)
                    newvalue["province"]= multiLineStateCountrySplitFilter[0];
                    if(multiLineStateCountrySplitFilter.length>=2){
                        var newvalue_postal_code=multiLineStateCountrySplitFilter[1];
                        if(multiLineStateCountrySplitFilter.length>2){
                            newvalue_postal_code= newvalue_postal_code + multiLineStateCountrySplitFilter[2];
                        }
                        newvalue["postal_code"]= newvalue_postal_code;
                        if(multiLineStateCountrySplitFilter.length>3){
                            newvalue["country_code"]= multiLineStateCountrySplitFilter[3];
                            dispatch(fetchState(multiLineStateCountrySplitFilter[3]));
                        }
                    }
                }
            }else{
                newvalue["city"]= multiLinePostal;
            }
        }
        setValues({
            ...values,
            ...newvalue
        })
        }else{
            setValues({
                ...values,
                [name]: value
            })
            validate({ [name]: value })
        }
    }
    const dispatchShipmentTypeInputChangeCallback = (name, value) => {
        setValues({
            ...values,
            [name]: value
        })
        validate({ [name]: value })
    }
    const dispatchCheckboxChangeCallback = (name, value) => {
        setValues({
            ...values,
            [name]: value
        })
        validate({ [name]: value })
    }
    
    const dispatchSenderAddressCallback = (id) => {
        setSenderAddressObjectId(id)
        setValues({
            ...values,
            ['preferred_sender_id']: id,
        })
        setErrors({ "preferred_sender_id": "" })
    }

    const dispatchLineItemCallback = (items) => {
        setLineItemId(items)
    }
    const getLineItemWeightUnit = (items) => {
        let totalLineItems=items.length;
        let lineItemWeight="";
        let lineItemWeightUnit="";
        totalLineItems>0 && lineItemsWeight && lineItemsWeight.data && lineItemsWeight.data.map((lineItemsWeightData) => {
            if(totalLineItems in lineItemsWeightData.line_items_weight){
                lineItemWeightUnit=lineItemsWeightData.line_item_weight_unit
            }
        })
        return lineItemWeightUnit
    }

    const getLineItemWeight = (items) => {
        let totalLineItems=items.length;
        let lineItemWeight="";
        totalLineItems>0 && lineItemsWeight && lineItemsWeight.data && lineItemsWeight.data.map((lineItemsWeightData) => {
            if(totalLineItems in lineItemsWeightData.line_items_weight){
                lineItemWeight=parseFloat(lineItemsWeightData.line_items_weight[totalLineItems-1]).toFixed(2)
            }
        })
        return lineItemWeight
    }

    const chooseMeasurement = (lineItemAcutualWeight) => {
        if (box && box.data && box.data.length != 0) {
            box.data.map((item) => {
                if (item && item.box_default && item.box_default === true) {
                    setDefaultBoxDim({
                        "length": parseFloat(item.box_length).toFixed(2),
                        "width": parseFloat(item.box_width).toFixed(2),
                        "height": parseFloat(item.box_height).toFixed(2),
                        "dimension_unit": item.box_length_unit,
                        "actual_weight": (lineItemsWeight && lineItemsWeight.data && lineItemsWeight.data.length>0 && customLineItems.length >0) ? getLineItemWeight(customLineItems) : (lineItemAcutualWeight!==0 && item.use_actual_weight && item.use_actual_weight===true) ? parseFloat(lineItemAcutualWeight).toFixed(2) :parseFloat(item.box_weight).toFixed(2),
                        "weight_unit": (lineItemsWeight && lineItemsWeight.data && lineItemsWeight.data.length>0 && customLineItems.length >0) ? getLineItemWeightUnit(customLineItems) : item.box_weight_unit,
                        "choosenmeasurement": item._id,
                        "insurance_amount": "100"
                    })
                }
            })
        }
    }
    const productlineitemsetfunc = (items) => {
        if (items) {
            let productLineItem = []
            items && items.map((item) => {
                if (item) {
                    let product_id = 1;
                    if(item && item.product_id && item.product_id != ""){
                        product_id = item.product_id;
                    }else if(item && item.sku && item.sku != ""){
                        product_id = item.sku;
                    }
                    productLineItem.push({
                        "line_item_id": (item && item.line_item_id && item.line_item_id != "") ? item.line_item_id : "1",
                        "product_id": product_id,
                        "product_desc": (item && item.product_desc && item.product_desc != "") ? item.product_desc : "Product 1",
                        "product_url": (item && item.product_image && item.product_image != "") ? item.product_image : "https://www.shippingchimp.com/",
                        "product_price": (item && item.product_price && item.product_price != "") ? item.product_price : "100",
                        "hs_tariff_code": (item && item.hs_tariff_code && item.hs_tariff_code != "") ? item.hs_tariff_code : "",
                        "hs_tariff_code_country": (item && item.hs_tariff_code_country && item.hs_tariff_code_country != "") ? item.hs_tariff_code_country : "",
                        "origin_country_code": (item && item.origin_country_code && item.origin_country_code != "") ? item.origin_country_code : "",
                        "quantity": (item && item.quantity && item.quantity != "") ? item.quantity : 1
                    })
                }
            })
            return productLineItem
        }
    }
    const formAutoRateFetchValuesfunc = (newOrderFormData,length,width,height,box_weight,actual_weight,weight_unit,dimension_unit,dimensions,insurance_amount,preferred_sender,address_formatted,address_formatted2,shipment_type) => {
            let isRateAutovalues={}
                isRateAutovalues={
                     "name": (newOrderFormData && newOrderFormData.shipping_address && newOrderFormData.shipping_address.name)
                         ? newOrderFormData.shipping_address.name : "",
                     "company": (newOrderFormData && newOrderFormData.shipping_address && newOrderFormData.shipping_address.company)
                         ? newOrderFormData.shipping_address.company : "",
                     "email": (newOrderFormData && newOrderFormData.customer && newOrderFormData.customer.email)
                         ? newOrderFormData.customer.email : (profile && profile.profile && profile.profile.default_customer_email) ? profile.profile.default_customer_email : profile.profile.email,
                     "address": address_formatted,
                     "address2": address_formatted2,
                     "city": (newOrderFormData && newOrderFormData.shipping_address && newOrderFormData.shipping_address.city)
                         ? newOrderFormData.shipping_address.city : "",
                     "postal_code": (newOrderFormData && newOrderFormData.shipping_address && newOrderFormData.shipping_address.zip)
                         ? newOrderFormData.shipping_address.zip.replace(/[`~!@#$%^&*()_|+\=?;:'",.<>\{\}\[\]\\\/]/gi, '') : "",
                     "province": (newOrderFormData && newOrderFormData.shipping_address && newOrderFormData.shipping_address.province_code)
                         ? newOrderFormData.shipping_address.province_code
                         : (newOrderFormData && newOrderFormData.shipping_address && newOrderFormData.shipping_address.province)
                             ? newOrderFormData.shipping_address.province : "",
                     "country_code": (newOrderFormData && newOrderFormData.shipping_address
                         && newOrderFormData.shipping_address.country_code) ? newOrderFormData.shipping_address.country_code : "",
                     "phone": (newOrderFormData && newOrderFormData.shipping_address && newOrderFormData.shipping_address.phone)
                         ? newOrderFormData.shipping_address.phone : "",
                     "sender_reference_no":(newOrderFormData && newOrderFormData.reference && newOrderFormData.reference.no)
                     ? newOrderFormData.reference.no : "",
                     "length": length,
                     "width": width,
                     "height": height,
                     "box_weight": box_weight,
                     "actual_weight": actual_weight,
                     "weight_unit": weight_unit,
                     "dimension_unit": dimension_unit,
                     "dimensions": dimensions,
                     "choosemeasurement": "custom",
                     "insurance_amount": insurance_amount,
                     "preferred_sender_id": preferred_sender,
                     "shipment_type":shipment_type
                }
                 setErrors({})
                 if(length!=0){
                    let dimensionObjectSet={
                        "weight":actual_weight,
                        "package_dimension":length+"x"+width+"x"+height
                    }
                    Object.assign(isRateAutovalues,dimensionObjectSet)
                 }
                if(newOrderFormData && newOrderFormData.line_items && newOrderFormData.line_items.length!=0){
                    let productLineItem= productlineitemsetfunc(newOrderFormData && newOrderFormData.line_items)
                    productLineItem=JSON.stringify(productLineItem)
                    let productlineitemset={}
                    productlineitemset={
                        "line_items":productLineItem
                    }
                    Object.assign(isRateAutovalues,productlineitemset)
                }else{
                    dimension_data["line_items"] = [];
                }
                let email = isRateAutovalues.email;
                if (isRateAutovalues.email === '') {
                     email = profile.profile.email
                }
                let dimension_data = {}
                if(isRateAutoEnabled!=true){
                    let dimension_data = {}
                        dimension_data = getConsolidateDimensions()
                }
                 if(isReturnOrder){
                     dimension_data["is_return"] = true
                }
                dimension_data["email"] = email
                dimension_data["request_medium"] = "UI"
                if(profile && profile.profile && profile.profile.hide_rate_drawer === true){
                    dimension_data["recommended_rate_only"] = true
                    setRateDrawervisible(false)
                    setIsRateShown(true)
                }else{
                    dimension_data["recommended_rate_only"] = false
                    setRateDrawervisible(true)
                    setIsRateShown(false)
                }
                dimension_data["request_cheapest_carrier"] = (profile && profile.profile && profile.profile.request_cheapest_service && profile.profile.request_cheapest_service === true) ? true : false
                dimension_data["standard_service_only"] = (profile && profile.profile && profile.profile.standard_service_only && profile.profile.standard_service_only === true) ? true : false
                Object.assign(isRateAutovalues, dimension_data)
            return isRateAutovalues
    }

    useEffect(() => {
        setCreateButtonDisable(false)
        if (newOrderFormData !== '') {
            let length = "";
            let width = "";
            let height = "";
            let dimensions = "";
            let box_weight = "";
            let actual_weight = "";
            let weight_unit = "";
            let dimension_unit = "";
            let line_item_index = 0;
            let address_formatted='';
            let address_formatted2='';
            let insurance_amount = "100"
            let preferred_sender = senderAddressObjectId
            let shipment_type="PACKAGE"
            let is_signature_required=false
            let lineItemAcutualWeight=0

            if(newOrderFormData && newOrderFormData.line_items && newOrderFormData.line_items.length!==0){
                newOrderFormData.line_items.map((item) => {
                        lineItemAcutualWeight = (item && item.actual_weight && item.actual_weight!="") ? parseFloat(lineItemAcutualWeight)+parseFloat(item.actual_weight) : parseFloat(lineItemAcutualWeight);
                })
            }
            if(profile && profile.profile && typeof profile.profile.is_signature_required !== "undefined" && profile.profile.is_signature_required===true){
                is_signature_required=true
            }
            if(newOrderFormData && newOrderFormData.product_info &&  (newOrderFormData.product_info.actual_weight ||  newOrderFormData.product_info.actual_weight=='') && newOrderFormData.product_info.dimensions && (newOrderFormData.shipment && newOrderFormData.shipment.tracking  && Object.keys(newOrderFormData.shipment.tracking).length == 0 || typeof (newOrderFormData.shipment) =='undefined' || typeof (newOrderFormData.shipment.tracking) =='undefined')){
                setDefaultBoxDim({
                    "length": newOrderFormData.product_info.dimensions && newOrderFormData.product_info.dimensions.length && newOrderFormData.product_info.dimensions.length >0 ? parseFloat(newOrderFormData.product_info.dimensions.length).toFixed(2) : 0,
                    "width": newOrderFormData.product_info.dimensions && newOrderFormData.product_info.dimensions.width && newOrderFormData.product_info.dimensions.width>0 ? parseFloat(newOrderFormData.product_info.dimensions.width).toFixed(2) : 0,
                    "height": newOrderFormData.product_info.dimensions && newOrderFormData.product_info.dimensions.height && newOrderFormData.product_info.dimensions.height >0 ? parseFloat(newOrderFormData.product_info.dimensions.height).toFixed(2) : 0,
                    "actual_weight": newOrderFormData.product_info.actual_weight >0 ?parseFloat(newOrderFormData.product_info.actual_weight).toFixed(2) : 0,
                    "dimension_unit": newOrderFormData.product_info.dimensions && newOrderFormData.product_info.dimensions.height_unit!=='' ? newOrderFormData.product_info.dimensions.height_unit :"",
                    "weight_unit": newOrderFormData.product_info && newOrderFormData.product_info.weight_unit!=='' ? newOrderFormData.product_info.weight_unit :"",
                    "choosenmeasurement": 0,
                    "insurance_amount": newOrderFormData && newOrderFormData.product_info && newOrderFormData.product_info.insurance_amount ? newOrderFormData.product_info.insurance_amount : "100"
                })
            }else if(newOrderFormData && newOrderFormData.shipping_address && newOrderFormData.shipping_address.country_code && newOrderFormData.shipping_address.country_code!=='' && newOrderFormData && newOrderFormData.order && newOrderFormData.order.total_price && newOrderFormData.order.total_price!=='' && boxValues.length>0 && (newOrderFormData.shipping_address.country_code==='US' || newOrderFormData.shipping_address.country_code==='GB')){
                let orderTotalPrice=newOrderFormData && newOrderFormData.order && newOrderFormData.order.total_price;
                let boxTag='';
                if(newOrderFormData.shipping_address.country_code =='US' && orderTotalPrice<20){
                    boxTag=1;
                }else if(newOrderFormData.shipping_address.country_code =='US' && orderTotalPrice >=20 && orderTotalPrice<35){
                    boxTag=2;
                }
                else if(newOrderFormData.shipping_address.country_code =='US' && orderTotalPrice >35){
                    boxTag=3;
                }
                else if(newOrderFormData.shipping_address.country_code =='GB' && orderTotalPrice <15){
                    boxTag=4;
                }
                else if(newOrderFormData.shipping_address.country_code =='GB' && orderTotalPrice >=15){
                    boxTag=5;
                }
                boxValues.map((item) => {
                    if (boxTag==item.box_tag) {
                        setDefaultBoxDim({
                            "length": parseFloat(item.box_length).toFixed(2),
                            "width": parseFloat(item.box_width).toFixed(2),
                            "height": parseFloat(item.box_height).toFixed(2),
                            "actual_weight": parseFloat(item.box_weight).toFixed(2),
                            "dimension_unit": item.box_length_unit,
                            "weight_unit": item.box_weight_unit,
                            "choosenmeasurement": 0,
                            "insurance_amount": "100"
                        })
                    }
                })
            }else {
                if(newOrderFormData && newOrderFormData.line_items && newOrderFormData.line_items.length!==0){
                    let skucheck=[]
                    if(newOrderFormData && newOrderFormData.line_items && newOrderFormData.line_items.length!==0){
                        newOrderFormData.line_items.map((item) => {
                            let newSku = (item && item.sku && item.sku!="") ? item.sku.toUpperCase() : "";
                            let quantity = (item && item.quantity && item.quantity!="") ? item.quantity : 1;
                            newSku = newSku +"-"+ quantity
                            if(item && item.sku && item.sku!="" && skucheck.includes(newSku)==false){
                                skucheck.push(newSku)
                            }
                        })
                        setSkus(skucheck)
                        if(skucheck.length==1){
                            if(skucheck[0] in customboxDimensions){
                                    setDefaultBoxDim({
                                        ['length']: customboxDimensions[skucheck[0]].box_length,
                                        ['width']: customboxDimensions[skucheck[0]].box_width,
                                        ['height']: customboxDimensions[skucheck[0]].box_height,
                                        ['box_weight']: customboxDimensions[skucheck[0]].box_width,
                                        ['actual_weight']: customboxDimensions[skucheck[0]].box_weight,
                                        ['weight_unit']: customboxDimensions[skucheck[0]].box_weight_unit,
                                        ['dimension_unit']: customboxDimensions[skucheck[0]].box_width_unit,
                                        "choosenmeasurement": customboxDimensions[skucheck[0]]._id,
                                        "insurance_amount": "100"
                                    })
                            }else{
                                if (newOrderFormData && newOrderFormData.shipment && newOrderFormData.shipment.tracking && Object.keys(newOrderFormData.shipment.tracking).length == 0 || newOrderFormData && typeof (newOrderFormData.shipment) =='undefined' || typeof (newOrderFormData.shipment.tracking) =='undefined') {
                                    chooseMeasurement(lineItemAcutualWeight);
                                }
                            }
                        }else{
                            if (newOrderFormData && newOrderFormData.shipment && newOrderFormData.shipment.tracking && Object.keys(newOrderFormData.shipment.tracking).length == 0 || newOrderFormData && typeof (newOrderFormData.shipment) =='undefined' || typeof (newOrderFormData.shipment.tracking) =='undefined') {
                                chooseMeasurement(lineItemAcutualWeight);
                            }
                        }
                    }
                }
            }

            if (newOrderFormData && newOrderFormData.line_items && newOrderFormData.line_items[line_item_index] && newOrderFormData.line_items[line_item_index].insurance_amount
                && newOrderFormData.line_items[line_item_index].insurance_amount !== "") {
                insurance_amount = newOrderFormData.line_items[line_item_index].insurance_amount;
            }

            if(newOrderFormData && newOrderFormData.shipping_address && newOrderFormData.shipping_address.address1){
                if(newOrderFormData.shipping_address.address1.length>35){
                var index = 0,
                res = [];
                while ((index = newOrderFormData.shipping_address.address1.indexOf(' ', index + 1)) > 0) {
                res.push(index);
                }
                var a=""
                for (var i = res.length; i > 0; i--) {
                    if(res[i]<35){
                        a= res[i]
                        break
                    }
                }
            const result = [newOrderFormData.shipping_address.address1.slice(0, a), newOrderFormData.shipping_address.address1.slice(a)];
            address_formatted=result[0];
            address_formatted2=result[1];
            }else{
                address_formatted=newOrderFormData.shipping_address.address1
            }
            }
            if(newOrderFormData && newOrderFormData.shipping_address && newOrderFormData.shipping_address.address2 ){
                if(address_formatted2!==""){
                    address_formatted2+= " " +newOrderFormData.shipping_address.address2
                }else{
                    address_formatted2= newOrderFormData.shipping_address.address2
                }
            }
            if (newOrderFormData && newOrderFormData.shipment && newOrderFormData.shipment.tracking && Object.keys(newOrderFormData.shipment.tracking).length != 0) {
                for (var key in newOrderFormData.shipment.tracking) {
                    if (newOrderFormData.shipment.tracking[key] && newOrderFormData.shipment.tracking[key].label && newOrderFormData.shipment.tracking[key].label.shipment_type) {
                        shipment_type=newOrderFormData.shipment.tracking[key].label.shipment_type
                        }
                    if (newOrderFormData.shipment.tracking[key] && newOrderFormData.shipment.tracking[key].address && newOrderFormData.shipment.tracking[key].address.sender) {
                        preferred_sender=newOrderFormData.shipment.tracking[key].address.sender.id
                        setSenderAddressObjectId(preferred_sender)
                        break
                        }
                    }
                let shipmentLength = Object.keys(newOrderFormData.shipment.tracking).length
                for (var key in newOrderFormData.shipment.tracking) {
                        if (newOrderFormData.shipment.tracking[key] &&  (shipmentLength == 1 || (shipmentLength > 1 && choosenTrackingNumber == key))) {
                            if (newOrderFormData.shipment.tracking[key] && newOrderFormData.shipment.tracking[key].label && typeof newOrderFormData.shipment.tracking[key].label.is_signature_required !== "undefined") {
                                is_signature_required=newOrderFormData.shipment.tracking[key].label.is_signature_required
                            }
                    }
                }    
            }
            setValues({
                ...values,
                ['length']: length,
                ['width']: width,
                ['height']: height,
                ['box_weight']: box_weight,
                ['actual_weight']: actual_weight,
                ['weight_unit']: weight_unit,
                ['dimension_unit']: dimension_unit,
                ['dimensions']: dimensions,
                ['choosemeasurement']: "custom",
                [`insurance_amount`]: insurance_amount,
                ['preferred_sender_id']: preferred_sender,
                ['is_signature_required']: is_signature_required,
                name: (newOrderFormData && newOrderFormData.shipping_address && newOrderFormData.shipping_address.name)
                    ? newOrderFormData.shipping_address.name.trim() : "",
                company: (newOrderFormData && newOrderFormData.shipping_address && newOrderFormData.shipping_address.company)
                    ? newOrderFormData.shipping_address.company.trim() : "",
                email: (newOrderFormData && newOrderFormData.customer && newOrderFormData.customer.email)
                    ? newOrderFormData.customer.email : (profile && profile.profile && profile.profile.default_customer_email) ? profile.profile.default_customer_email : profile.profile.email,
                address: address_formatted.trim(),
                address2: address_formatted2.trim(),
                city: (newOrderFormData && newOrderFormData.shipping_address && newOrderFormData.shipping_address.city)
                    ? newOrderFormData.shipping_address.city : "",
                postal_code: (newOrderFormData && newOrderFormData.shipping_address && newOrderFormData.shipping_address.zip)
                    ? newOrderFormData.shipping_address.zip.replace(/[`~!@#$%^&*()_|+\=?;:'",.<>\{\}\[\]\\\/]/gi, '') : "",
                province: (newOrderFormData && newOrderFormData.shipping_address && newOrderFormData.shipping_address.province_code)
                    ? newOrderFormData.shipping_address.province_code
                    : (newOrderFormData && newOrderFormData.shipping_address && newOrderFormData.shipping_address.province)
                        ? newOrderFormData.shipping_address.province : "",
                country_code: (newOrderFormData && newOrderFormData.shipping_address
                    && newOrderFormData.shipping_address.country_code) ? newOrderFormData.shipping_address.country_code : "",
                phone: (newOrderFormData && newOrderFormData.shipping_address && newOrderFormData.shipping_address.phone)
                    ? newOrderFormData.shipping_address.phone : "",
                sender_reference_no:(newOrderFormData && newOrderFormData.reference && newOrderFormData.reference.no)
                ? newOrderFormData.reference.no : "",
                sender_reference_no:(newOrderFormData && newOrderFormData.reference && newOrderFormData.reference.no)
                ? newOrderFormData.reference.no : "",
                shipment_type:shipment_type
            });

            if(isReturnOrder){
                setValues({
                    ...values,
                    sender_name: (newOrderFormData && newOrderFormData.shipping_address && newOrderFormData.shipping_address.name)
                        ? newOrderFormData.shipping_address.name.trim() : "",
                    sender_company: (newOrderFormData && newOrderFormData.shipping_address && newOrderFormData.shipping_address.company && newOrderFormData.shipping_address.company!=="")
                        ? newOrderFormData.shipping_address.company :newOrderFormData.shipping_address.name.trim(),
                    sender_email: (newOrderFormData && newOrderFormData.customer && newOrderFormData.customer.email)
                        ? newOrderFormData.customer.email : (profile && profile.profile && profile.profile.default_customer_email) ? profile.profile.default_customer_email : profile.profile.email,
                    sender_address: address_formatted.trim(),
                        sender_address2: address_formatted2.trim(),
                    sender_city: (newOrderFormData && newOrderFormData.shipping_address && newOrderFormData.shipping_address.city)
                        ? newOrderFormData.shipping_address.city : "",
                    sender_postal_code: (newOrderFormData && newOrderFormData.shipping_address && newOrderFormData.shipping_address.zip)
                        ? newOrderFormData.shipping_address.zip.replace(/ /g, '') : "",
                    sender_state_code: (newOrderFormData && newOrderFormData.shipping_address && newOrderFormData.shipping_address.province_code)
                        ? newOrderFormData.shipping_address.province_code
                        : (newOrderFormData && newOrderFormData.shipping_address && newOrderFormData.shipping_address.province)
                            ? newOrderFormData.shipping_address.province : "",
                    sender_country_code: (newOrderFormData && newOrderFormData.shipping_address
                        && newOrderFormData.shipping_address.country_code) ? newOrderFormData.shipping_address.country_code : "",
                        sender_phone: (newOrderFormData && newOrderFormData.shipping_address && newOrderFormData.shipping_address.phone)
                        ? newOrderFormData.shipping_address.phone : ""
                    });
            }
            if(newOrderFormData && newOrderFormData.order && newOrderFormData.order.custom_fields && newOrderFormData.order.custom_fields.preferred_sender_id ){
                if(typeof(newOrderFormData.order.custom_fields.preferred_sender_id) === "string" && newOrderFormData.order.custom_fields.preferred_sender_id != ""){
                    console.log("string");
                    if (addresssender && addresssender.data) {
                        addresssender.data.map((item) => {
                            if (item && item._id && item._id === newOrderFormData.order.custom_fields.preferred_sender_id) {
                                setSenderAddressObjectId(item._id)
                            }
                        })
                    }
                }else if((newOrderFormData.order.custom_fields.preferred_sender_id instanceof Array) && newOrderFormData.order.custom_fields.preferred_sender_id != []){
                    let preferred_sender_array = newOrderFormData.order.custom_fields.preferred_sender_id
                    console.log("array");
                    if (addresssender && addresssender.data) {
                        addresssender.data.map((item) => {
                            if (item && item._id && preferred_sender_array.includes(item._id)) {
                                setSenderAddressObjectId(item._id)
                            }
                        })
                    }
                }
            }

            if (newOrderFormData && newOrderFormData.shipment && newOrderFormData.shipment.tracking && Object.keys(newOrderFormData.shipment.tracking).length != 0) {
                let multiPackageFieldsCustom = []
                let shipmentLength = Object.keys(newOrderFormData.shipment.tracking).length
                for (var key in newOrderFormData.shipment.tracking) {
                        if (newOrderFormData.shipment.tracking[key] && newOrderFormData.shipment.tracking[key].product_info && newOrderFormData.shipment.tracking[key].product_info.dimensions && (shipmentLength == 1 || (shipmentLength > 1 && choosenTrackingNumber == key))) {
                        let dim_values = {
                            "length": (newOrderFormData.shipment.tracking[key].product_info.dimensions.length) ? newOrderFormData.shipment.tracking[key].product_info.dimensions.length : "",
                            "width": (newOrderFormData.shipment.tracking[key].product_info.dimensions.width) ? newOrderFormData.shipment.tracking[key].product_info.dimensions.width : "",
                            "height": (newOrderFormData.shipment.tracking[key].product_info.dimensions.height) ? newOrderFormData.shipment.tracking[key].product_info.dimensions.height : "",
                            "dimension_unit": (newOrderFormData.shipment.tracking[key].product_info.dimensions.length_unit) ? newOrderFormData.shipment.tracking[key].product_info.dimensions.length_unit : "",
                            "actual_weight": (newOrderFormData.shipment.tracking[key].product_info.actual_weight) ? newOrderFormData.shipment.tracking[key].product_info.actual_weight : "",
                            "weight_unit": (newOrderFormData.shipment.tracking[key].product_info.weight_unit) ? newOrderFormData.shipment.tracking[key].product_info.weight_unit : "",
                            "choosenmeasurement": 0,
                            "insurance_amount":(newOrderFormData.shipment.tracking[key].product_info.insurance_amount) ? newOrderFormData.shipment.tracking[key].product_info.insurance_amount : "100"
                        }
                        dim_values["length"] = (dim_values["length"] != "" && (typeof dim_values["length"] == "string" || typeof dim_values["length"] == "number")) ? parseFloat(dim_values["length"]).toFixed(2) : "";
                        dim_values["width"] = (dim_values["width"] != "" && (typeof dim_values["width"] == "string" || typeof dim_values["width"] == "number")) ? parseFloat(dim_values["width"]).toFixed(2) : "";
                        dim_values["height"] = (dim_values["height"] != "" && (typeof dim_values["height"] == "string" || typeof dim_values["height"] == "number")) ? parseFloat(dim_values["height"]).toFixed(2) : "";
                        dim_values["actual_weight"] = (dim_values["actual_weight"] != "" && (typeof dim_values["actual_weight"] == "string" || typeof dim_values["actual_weight"] == "number")) ? parseFloat(dim_values["actual_weight"]).toFixed(2) : "";
                        multiPackageFieldsCustom.push(dim_values)
                            if(isReturnOrder){
                                setValues({
                                    ...values,
                                    sender_name:  newOrderFormData.shipment.tracking[key].address.receiver.name.trim(),
                                    sender_company:  newOrderFormData.shipment.tracking[key].address.receiver.company && newOrderFormData.shipment.tracking[key].address.receiver.company!==""?
                                                        newOrderFormData.shipment.tracking[key].address.receiver.company:newOrderFormData.shipment.tracking[key].address.receiver.name.trim(),
                                    sender_email:  newOrderFormData.shipment.tracking[key].address.receiver.email,
                                    sender_address: newOrderFormData.shipment.tracking[key].address.receiver.address1.trim(),
                                    sender_address2:newOrderFormData.shipment.tracking[key].address.receiver.address2.trim(),
                                    sender_city: newOrderFormData.shipment.tracking[key].address.receiver.city,
                                    sender_postal_code: newOrderFormData.shipment.tracking[key].address.receiver.zip.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, ''),
                                    sender_state_code: newOrderFormData.shipment.tracking[key].address.receiver.province_code,
                                    sender_country_code: newOrderFormData.shipment.tracking[key].address.receiver.country_code,
                                    sender_phone: newOrderFormData.shipment.tracking[key].address.receiver.phone
                                })
                            }
                        }
                        else if (newOrderFormData.shipment.master && newOrderFormData.shipment.tracking[key].product_info && newOrderFormData.shipment.tracking[key].product_info.dimensions && (shipmentLength == 1 || shipmentLength > 1 && choosenTrackingNumber==0)) {
                            let dim_values = {
                                "length": (newOrderFormData.shipment.tracking[key].product_info.dimensions.length) ? newOrderFormData.shipment.tracking[key].product_info.dimensions.length : "",
                                "width": (newOrderFormData.shipment.tracking[key].product_info.dimensions.width) ? newOrderFormData.shipment.tracking[key].product_info.dimensions.width : "",
                                "height": (newOrderFormData.shipment.tracking[key].product_info.dimensions.height) ? newOrderFormData.shipment.tracking[key].product_info.dimensions.height : "",
                                "dimension_unit": (newOrderFormData.shipment.tracking[key].product_info.dimensions.length_unit) ? newOrderFormData.shipment.tracking[key].product_info.dimensions.length_unit : "",
                                "actual_weight": (newOrderFormData.shipment.tracking[key].product_info.actual_weight) ? newOrderFormData.shipment.tracking[key].product_info.actual_weight : "",
                                "weight_unit": (newOrderFormData.shipment.tracking[key].product_info.weight_unit) ? newOrderFormData.shipment.tracking[key].product_info.weight_unit : "",
                                "choosenmeasurement": 0,
                                "insurance_amount":(newOrderFormData.shipment.tracking[key].product_info.insurance_amount) ? newOrderFormData.shipment.tracking[key].product_info.insurance_amount : "100"
                            }
                            dim_values["length"] = (dim_values["length"] != "" && (typeof dim_values["length"] == "string" || typeof dim_values["length"] == "number")) ? parseFloat(dim_values["length"]).toFixed(2) : "";
                            dim_values["width"] = (dim_values["width"] != "" && (typeof dim_values["width"] == "string" || typeof dim_values["width"] == "number")) ? parseFloat(dim_values["width"]).toFixed(2) : "";
                            dim_values["height"] = (dim_values["height"] != "" && (typeof dim_values["height"] == "string" || typeof dim_values["height"] == "number")) ? parseFloat(dim_values["height"]).toFixed(2) : "";
                            dim_values["actual_weight"] = (dim_values["actual_weight"] != "" && (typeof dim_values["actual_weight"] == "string" || typeof dim_values["actual_weight"] == "number")) ? parseFloat(dim_values["actual_weight"]).toFixed(2) : "";
                            multiPackageFieldsCustom.push(dim_values)
                        }
                    }
                if (multiPackageFieldsCustom.length != 0) {
                    setMultiPackageFields(multiPackageFieldsCustom)
                }
            } 
           
            if(isRateAutoEnabled){
                let isRateAutovalues=formAutoRateFetchValuesfunc(newOrderFormData,length,width,height,box_weight,actual_weight,weight_unit,dimension_unit,dimensions,insurance_amount,preferred_sender,address_formatted,address_formatted2,shipment_type)
                let apikey = (profile.profile.api_token) ? profile.profile.api_token : "";
                setFormDataValues(isRateAutovalues)
                dispatch(doGetRate(isRateAutovalues, apikey));
            }
        } else {
            setCustomLineItems([]);
            chooseMeasurement(0);
        }
        if(newOrderFormData && newOrderFormData.order && newOrderFormData.order.id ){
            setOrderId("# " + newOrderFormData.order.id)
        }
    }, [newOrderFormData, visible])


    const dispatchCountryCallback = (id) => {
        setValues({
            ...values,
            ['country_code']: id
        })
        setErrors({ "country_code": "" })
        dispatch(fetchState(id));
    }


    const dispatchSenderCountryCallback = (id) => {
        setValues({
            ...values,
            ['sender_country_code']: id
        })
        setErrors({ "country_code": "" })
        dispatch(fetchMultiStates(id));
    }
    const getConsolidateDimensions = () => {
        let response = {
            "package_dimension": "",
            "dimension_unit": "",
            "weight": "",
            "weight_unit": "",
            "insurance_amount": ""
        }
        multiPackageFields.forEach((data) => {
            let dimension = parseFloat(data["length"]).toFixed(2) + "x" + parseFloat(data["width"]).toFixed(2) + "x" + parseFloat(data["height"]).toFixed(2)
            response["package_dimension"] = (response["package_dimension"] == "") ? dimension : response["package_dimension"] + "," + dimension
            response["dimension_unit"] = (response["dimension_unit"] == "") ? data["dimension_unit"] : response["dimension_unit"] + "," + data["dimension_unit"]
            response["weight"] = (response["weight_unit"] == "") ? parseFloat(data["actual_weight"]).toFixed(2): response["weight"] + "," + parseFloat(data["actual_weight"]).toFixed(2)
            response["weight_unit"] = (response["weight_unit"] == "") ? data["weight_unit"] : response["weight_unit"] + "," + data["weight_unit"]
            response["insurance_amount"] = (response["insurance_amount"] == "") ? data["insurance_amount"] : response["insurance_amount"] + "," + data["insurance_amount"]
        })
        return response
    }

    const handleSubmit = (e, value) => {
        setShowFieldErrors(true)
        if(isRateAutoEnabled!=true){
            e.preventDefault()
        }
        if (validate()) {
            let apikey = (profile.profile.api_token) ? profile.profile.api_token : "";
            let email = values.email;
            let name=values.name;
            let city=values.city;
            let address = values.address;
            let address2 = values.address2;
            let is_signature_required=values.is_signature_required;
            if (values.email === '') {
                email = profile.profile.email
            }
            if (values.name !== '') {
                name = values.name.replaceAll(/-/g, ' ').replaceAll(/[’`']/g, '');
            }
            if (values.city !== '') {
                city = values.city.replaceAll(/-/g, ' ').replaceAll(/[’`']/g, '');
            }
            if (values.address !== '') {
                address = values.address.replaceAll(/[’`]/g, '');
            }
            if (values.address2 !== '') {
                address2 = values.address2.replaceAll(/[’`]/g, '');
            }
            let dimension_data = {}
            dimension_data = getConsolidateDimensions()
            dimension_data["total_quantity"] = newOrderFormData && newOrderFormData.total_quantity ? newOrderFormData && newOrderFormData.total_quantity :""
            dimension_data["no_of_line_items"] = newOrderFormData && newOrderFormData.no_of_line_items ? newOrderFormData && newOrderFormData.no_of_line_items :""
            dimension_data["skus"] = skus && skus.length!==0 ? skus:[]
            if(isReturnOrder){
                delete values['is_signature_required'];
                dimension_data["is_return"] = true
            }else{
                dimension_data["is_signature_required"] = is_signature_required
            }
            let templineItemIds=[];  
            dimension_data["line_items"] = [];
            if (customLineItems.length != 0) {
               let tempItem=[];   
               templineItemIds=[...lineItemId];    
                customLineItems.map((item) => {
                    if(lineItemId.length!=0){
                        if(templineItemIds.includes(String(item.line_item_id.toString())) && (tempItem.length<20)){
                            tempItem.push(item)
                        }
                    }else{
                        if(item && (!item.tracking_number || item.tracking_number=='')){
                            templineItemIds.push(String(item.line_item_id.toString()))
                            if(tempItem.length<20){
                                tempItem.push(item)
                            }
                        }
                    }
                })
                setLineItemId(templineItemIds)
                dimension_data["line_items"] = JSON.stringify(tempItem)
            }
            if (value === 'FETCH') {
                dimension_data["email"] = email
                dimension_data["name"] = name
                dimension_data["city"] = city
                dimension_data["address"] = address
                dimension_data["address2"] = address2
                dimension_data["request_medium"] = "UI"
                dimension_data["order_value"] = newOrderFormData && newOrderFormData.order && newOrderFormData.order.total_price && newOrderFormData.order.total_price!=='' ?  newOrderFormData.order.total_price : 0
                if(newOrderFormData && newOrderFormData.shipping_address && newOrderFormData.shipping_address.is_commercial && newOrderFormData.shipping_address.is_commercial===true){
                     dimension_data["is_commercial"] = "yes"
                }
                if(profile && profile.profile && profile.profile.hide_rate_drawer === true){
                    dimension_data["recommended_rate_only"] = true
                    setRateDrawervisible(false)
                    setIsRateShown(true)
                }else{
                    dimension_data["recommended_rate_only"] = false
                    setRateDrawervisible(true)
                    setIsRateShown(false)
                }
                setIsFailedLabelPrinted(true)
                dimension_data["request_cheapest_carrier"] = (profile && profile.profile && profile.profile.request_cheapest_service && profile.profile.request_cheapest_service === true) ? true : false
                dimension_data["standard_service_only"] = (profile && profile.profile && profile.profile.standard_service_only && profile.profile.standard_service_only === true) ? true : false
                Object.assign(values, dimension_data);
                setFormDataValues(values)
                dispatch(doGetRate(values, apikey));
            } else {
                if(value === 'FAILEDGENERATE'){
                    setIsFailedLabelPrinted(false)
                }else{
                    setIsFailedLabelPrinted(true)
                }
                let order_id_check=""
                let document_reference={}
                if(isReturnOrder){
                    order_id_check=(newOrderFormData && newOrderFormData.order && newOrderFormData.order.id)
                        ? "R"+newOrderFormData.order.id : ""
                    document_reference={
                        "order_id": order_id_check
                    }
                }else if(isDuplicateOrder){
                    document_reference={}
                }else{
                    order_id_check=(newOrderFormData && newOrderFormData.order && newOrderFormData.order.id)
                    ? newOrderFormData.order.id : ""
                    document_reference={
                    "doc_id": (newOrderFormData && newOrderFormData._id) ? newOrderFormData._id : "",
                    "order_id": order_id_check,
                    "item_ids": templineItemIds,
                    "is_failed_bulk_order": (newOrderFormData && newOrderFormData.order && newOrderFormData.order.status && newOrderFormData.order.status == "Label Generation Failed") ? true : false
                    }
                }
                let referenceno = ""
                if(!isDuplicateOrder && newOrderFormData && newOrderFormData.reference && newOrderFormData.reference.no){
                    referenceno = newOrderFormData.reference.no
                }
                dimension_data["referenceno"] = values.sender_reference_no;
                dimension_data["preferred_carrier"] = ""
                dimension_data["preferred_carrier_reference_id"] = ""
                dimension_data["preferred_service"] = ""
                dimension_data["request_cheapest_carrier"] = (profile && profile.profile && profile.profile.request_cheapest_service && profile.profile.request_cheapest_service === true) ? true : false
                dimension_data["standard_service_only"] = (profile && profile.profile && profile.profile.standard_service_only && profile.profile.standard_service_only === true) ? true : false
                dimension_data["email"] = email
                dimension_data["name"] = name
                dimension_data["city"] = city
                dimension_data["address"] = address
                dimension_data["address2"] = address2
                dimension_data["request_medium"] = "UI"
                dimension_data["order_value"] = newOrderFormData && newOrderFormData.order && newOrderFormData.order.total_price && newOrderFormData.order.total_price!=='' ?  newOrderFormData.order.total_price : 0
                dimension_data["quantity"] = (newOrderFormData && newOrderFormData.line_items && newOrderFormData.line_items.length !== 0 && newOrderFormData.line_items[0].quantity) ? newOrderFormData.line_items[0].quantity : 1
                dimension_data["reference"] = document_reference
                if(newOrderFormData && newOrderFormData.shipping_address && newOrderFormData.shipping_address.is_commercial && newOrderFormData.shipping_address.is_commercial===true){
                    dimension_data["is_commercial"] = "yes"
               }
                Object.assign(values, dimension_data);
                setIsLabelOpened(false);
                dispatch(dopostDataCreateShipment(values, apikey, meta))
            }
        }
    }
    // useEffect
    useEffect(() => {
        if(_.size(country_code.data) === 0 )
            dispatch(fetchCountry());
        if(_.size(addresssender.data) === 0 )
            dispatch(fetchSenderAddress());
        if(_.size(addressbilling.data) === 0 )
            dispatch(fetchAddressBilling());
        if(_.size(box.data) === 0 )
            dispatch(fetchBoxData(false));
        dispatch(fetchProfile())
    }, [dispatch]);

    useEffect(() => {
        setValues({
            ...values,
            ['is_signature_required']: (profile && profile.profile && typeof profile.profile.is_signature_required !== "undefined") ? profile.profile.is_signature_required :false
        })
    }, [profile]);

    useEffect(() => {
        let boxdimensionTemp={}
        if (box && box.data && box.data.length !== 0) {
            box.data.map((item) => {
                if (item.box_default === true) {
                    setDefaultBoxDim({
                        "length": parseFloat(item.box_length).toFixed(2),
                        "width": parseFloat(item.box_width).toFixed(2),
                        "height": parseFloat(item.box_height).toFixed(2),
                        "dimension_unit": item.box_length_unit,
                        "actual_weight": (lineItemsWeight && lineItemsWeight.data && lineItemsWeight.data.length>0 && customLineItems.length >0) ? getLineItemWeight(customLineItems) :parseFloat(item.box_weight).toFixed(2),
                        "weight_unit": (lineItemsWeight && lineItemsWeight.data && lineItemsWeight.data.length>0 && customLineItems.length >0) ? getLineItemWeightUnit(customLineItems) : item.box_weight_unit,
                        "choosenmeasurement": item._id,
                        "insurance_amount": "100"
                    })
                }
                if(!(item.box_name in boxdimensionTemp)){
                    boxdimensionTemp[item.box_name.toUpperCase()]=item
                }
            })
            setcustomboxDimensions(boxdimensionTemp)
        }
        let boxData = box && box.data.filter(function( obj ) {
            return  obj.country;
         });
         setBoxValues(boxData)
    }, [box && box.data]);

    useEffect(() => {
        if(showFieldErrors){
            validate({})
        }
    }, [multiPackageFields])

    useEffect(() => {
      if(lineItemsWeight && lineItemsWeight.data && lineItemsWeight.data.length>0 && customLineItems.length >0){
            let lineitemweight=getLineItemWeight(customLineItems) 
            let lineitemweightUnit=getLineItemWeightUnit(customLineItems) 
            setDefaultBoxDim({
                ...defaultBoxDim,
                "actual_weight": lineitemweight,
                "weight_unit": lineitemweightUnit
            })
      }
    }, [customLineItems])

    useEffect(() => {
        if (addresssender && addresssender.data) {
            addresssender.data.map((item) => {
                if (item && item.default) {
                    setSenderAddressObjectId(item._id)
                    setValues({
                        ...values,
                        ['preferred_sender_id']: item._id,
                    })
                }
            })
        }
    }, [addresssender]);

    useEffect(() => {
        if (orders && orders.notification && orders.notification.messageContent
            && orders.notification.messageContent[0] === 'Shipment Created Successfully' && isLabelOpened == false && rateDrawervisible == false && isLabelPrint== true && isFailedLabelPrinted==true) {
            setPrintUrl(orders.message.label_carrier)
            setIsPrinted(false)
            setIsLabelOpened(true);
            ondrawerclose()
        }else if(orders && orders.notification && orders.notification.messageContent
                 && orders.notification.messageContent[0] === 'Shipment Created Successfully' && isFailedLabelPrinted==false) {
                    setCreateButtonDisable(true)
                    ondrawerclose()
                }
    }, [orders]);

    useEffect(() => {
        if (values.province) {
            let provincesize = values.province.length
            if (provincesize > 2) {
                let provicelowcase = values.province = values.province.toLowerCase()
                let finalprovince = provicelowcase.charAt(0).toUpperCase() + provicelowcase.slice(1)
                state && state.data.map((key) => {
                    if (finalprovince.toLowerCase() == key.name.toLowerCase()) {
                        setValues({
                            ...values,
                            ['province']: key.code,
                        })
                    }
                })
            }
        }
    }, [state]);

    const ondrawerclose = () => {
        let senderDefault = ''
        if (addresssender && addresssender.data) {
            addresssender.data.map((item) => {
                if (item && item.default) {
                    senderDefault = item._id
                }
            })
        }
        setSenderAddressObjectId(senderDefault)
        initialFValues["preferred_sender_id"] = senderDefault
        setValues(initialFValues);
        setNewOrderFormData("");
        setErrors({ name: "" })
        setDimensionsErrors({})
        setDrawer(false)
        onClose(false)
        setIsReturnOrder(false)
        setIsRateShown(false)
        dispatch(resetRateState())
        setCustomLineItems([])
        setMultiPackageFields([initialDimValues])
    }

    const formatRate = () => {
        let estimatedRate = "-"
        let carrierService = {
            "CANADAPOST" : "DOM.EP",
            "UPS" : "11",
            "ICS" : "GR"
        }
        if(rates.data && Array.isArray(rates.data) && rates.data.length > 0){
            rates.data.forEach(function(rate) {
                let carrier = (rate && rate.carrier_name) ? rate.carrier_name.toUpperCase() : ""
                let serviceType = (carrierService[carrier]) ? carrierService[carrier] : ""
                if (rate && rate.service_code && rate.service_code === serviceType && rate.total_charges) {
                    estimatedRate = rate.total_charges
                }
            });
            if(estimatedRate === "-"){
                estimatedRate = (rates.data[0] && rates.data[0].total_charges) ? rates.data[0].total_charges : "-"
            }
        }
        return estimatedRate
    }
    return (
        <>
            <Printer isPrinted={isPrinted} setIsPrinted={setIsPrinted} print_url={printUrl} />
            <Drawer
                anchor="right"
                open={visible}
                onClose={ondrawerclose}
                PaperProps={{
                    sx: { width: "80%" },
                }}
            >
                {/* {country_code && country_code.loading === true &&
                    <Loader />
                }
                {orders && orders.loading === true &&
                    <Loader />
                } */}
                <div style={{ padding: "0 30px" }}>
                {(rates && rates.notification && rates.notification.show && rates.notification.show===true) &&
                            <Notification
                                is_open={rates.notification.show}
                                messageType={rates.notification.messageType}
                                messages={rates.notification.messageContent}
                                handleClose={() => dispatch(resetRateState())}
                            />
                        }
                    <Card sx={{ margin: '20px 0' }} className="sc--cardhead--details">
                        <CardHeader title={ isShipmentCreationFlow  ? ("Create Shipment " + orderId ): isDuplicateOrder && !isReturnOrder ? ("Duplicate Shipment " + orderId ):((isReturnOrder) ? ("Return Shipment " + orderId ) : "Create new order") } action={
                            <div>
                                <IconButton aria-label="settings" onClick={ondrawerclose}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        } />
                        {(country_code && country_code.loading === true || orders && orders.loading === true) &&
                            <LinearProgress />
                        }
                        <Divider />
                    </Card>

                    <div className="sc--content--body">
                        <div className="sc--content--card" style={{ margin: 'auto' }}>
                        <Grid
                                container
                                direction='row'
                                spacing={2}
                                alignnewOrderFormDatas="stretch"
                            >
                                <LineItems
                                    lineItems={newOrderFormData && newOrderFormData.line_items}
                                    dispatchLineItemCallback={dispatchLineItemCallback}
                                    isDuplicateOrder={isDuplicateOrder}
                                    customLineItems={customLineItems}
                                    setCustomLineItems={setCustomLineItems}
                                    isReturnOrder={isReturnOrder}
                                />


                                <SenderAddressSelect
                                    errors={errors}
                                    addresssender={addresssender}
                                    senderAddressObjectId={senderAddressObjectId}
                                    dispatchSenderAddressCallback={dispatchSenderAddressCallback}
                                    dispatchInputChangeCallback={dispatchInputChangeCallback}
                                    dispatchSenderCountryCallback={dispatchSenderCountryCallback}
                                    values={values}
                                    isReturnOrder={isReturnOrder}
                                />
                            </Grid>
                            <Grid
                                container
                                direction='row'
                                spacing={2}
                                alignnewOrderFormDatas="stretch"
                            >
                                <MultiPackageDimensions
                                    errors={dimensionsErrors}
                                    values={values}
                                    multiPackageFields={multiPackageFields}
                                    onMultiPackageAdd={onMultiPackageAdd}
                                    onMultiPackageRemove={onMultiPackageRemove}
                                    handleMultiPackageDimensionInputChange={handleMultiPackageDimensionInputChange}
                                    dispatchShipmentTypeInputChangeCallback={dispatchShipmentTypeInputChangeCallback}
                                />

                                <RecipientAddressSelect
                                    errors={errors}
                                    values={values}
                                    dispatchInputChangeCallback={dispatchInputChangeCallback}
                                    dispatchCheckboxChangeCallback={dispatchCheckboxChangeCallback}
                                    dispatchCountryCallback={dispatchCountryCallback}
                                    newOrderFormData={newOrderFormData}
                                    setValues={setValues}
                                    isReturnOrder={isReturnOrder}
                                    addresssender={addresssender}
                                />
                            </Grid>
                            <div className="sc--form--center" style={{ margin: '30px 0' }}>
                                {(addresssender && addresssender.loading === false)
                                    && addresssender.data && addresssender.data.length === 0 &&
                                    <>
                                        <Button
                                            variant="outlined"
                                            onClick={handleSubmit}
                                            disabled
                                            style={{ border: '1px solid rgba(0, 0, 0, 0.12)', margin: '0 15px' }}
                                        >
                                            <SendIcon style={{ paddingRight: '10px' }} /> Fetch Rates
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            onClick={handleSubmit}
                                            disabled
                                        >
                                            <AddCircleOutlineOutlinedIcon style={{ paddingRight: '10px' }} /> Create + Print
                                        </Button>
                                    </>
                                }
                                {(addresssender && addresssender.loading === false)
                                    && addresssender.data && addresssender.data.length !== 0 &&
                                    <>
                                        <LoadingButton
                                            variant="outlined"
                                            onClick={e => handleSubmit(e, "FETCH")}
                                            style={{ margin: '0 15px' }}
                                            loading={
                                                rates && rates.loading}
                                        >
                                            <SendIcon style={{
                                                paddingRight: '10px'
                                            }} /> Fetch Rates
                                    </LoadingButton>
                                    {(profile && profile.profile && profile.profile.hide_rate_drawer === true) &&
                                    (rates && rates.loading === false) &&
                                    (rates && rates.data && rates.data.length !== 0) && isRateShown &&
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            style={{ marginRight: '15px', cursor: "unset" }}
                                        >
                                            <AttachMoneyIcon style={{
                                                paddingRight: '10px'
                                            }} />
                                            <span style={{ fontWeight: '600' }} >{ formatRate() }</span>
                                            </Button>
                                        }
                                        {isFailedShipment &&
                                        <LoadingButton
                                            variant="outlined"
                                            onClick={e => handleSubmit(e, "FAILEDGENERATE")}
                                            disabled={((rates && rates.loading) || createButtonDisable == true || orderId == "# " + currentOrderId) ? true: false}
                                            style={{ marginRight: '15px'}}
                                            loading={
                                                orders && orders.loading}
                                        >
                                            <AddCircleOutlineOutlinedIcon style={{
                                                paddingRight: '10px'
                                            }} /> Create
                                        </LoadingButton>
                                        }
                                        <LoadingButton
                                            variant="outlined"
                                            onClick={e => handleSubmit(e, "GENERATE")}
                                            disabled={((rates && rates.loading) || createButtonDisable == true || orderId == "# " + currentOrderId) ? true: false}
                                            loading={
                                                orders && orders.loading}
                                        >
                                            <AddCircleOutlineOutlinedIcon style={{
                                                paddingRight: '10px'
                                            }} /> Create + Print
                                    </LoadingButton>
                                    </>
                                }
                            </div>
                        </div>
                        <RateDrawer
                            visible={rateDrawervisible}
                            onClose={rateDraweronClose}
                            meta={meta}
                            formDataValues={formDataValues}
                            newOrderFormData={newOrderFormData}
                            isDuplicateOrder={isDuplicateOrder}
                            lineItemId={lineItemId}
                            customLineItems={customLineItems}
                            setValues={setValues}
                            setNewOrderFormData={setNewOrderFormData}
                            setCustomLineItems={setCustomLineItems}
                            setRateDrawervisible={setRateDrawervisible}
                            setNewOrderCreateDrawerVisible={setNewOrderCreateDrawerVisible}
                            isReturnOrder={isReturnOrder}
                            isFailedShipment={isFailedShipment}
                        />
                    </div>

                </div>
            </Drawer >
        </>
    )
}


export default NewOrderCreateDrawer;