import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import AppsIcon from '@mui/icons-material/Apps';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import StorefrontIcon from '@mui/icons-material/Storefront';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import CarRentalIcon from '@mui/icons-material/CarRental';
import IntegrationInstructionsOutlinedIcon from '@mui/icons-material/IntegrationInstructionsOutlined';
import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import {
  Button,
  Typography,
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import MuiAlert from '@mui/material/Alert';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import AddBusinessOutlinedIcon from '@mui/icons-material/AddBusinessOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import BoltOutlinedIcon from '@mui/icons-material/BoltOutlined';
import ReceiptIcon from '@mui/icons-material/Receipt';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import Menu from '@mui/material/Menu';
import AlertTitle from '@mui/material/AlertTitle';
import LogoutIcon from '@mui/icons-material/Logout';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import IconButton from '@mui/material/IconButton';

import Header from './Header'
import { doLogout } from '../../pages/Auth/authSlice';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Sidebar = ({ currentPage, connectQZ, disconnectQZ }) => {
  const params = new URLSearchParams(window.location.search)
  let urlParam = params.get('report')
  const logout = () => {
    window.location.reload();
    dispatch(doLogout());
  }
  const profile = useSelector(state => state.auth);
  const qzState = useSelector(state => state.qz);
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [showDrawer, setShowDrawer] = useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuopen = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDrawerClick = () => {
    setOpen(!open);
  };

  const dispatch = useDispatch();
  const drawerWidth = 240;

  const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden'
  });

  const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(6)} + 1px)`,
    },
  });

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    }),
  );
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };
  const showdrawerhandle = () => {
    setShowDrawer(showDrawer ? false : true)
  }

  return (
    <>
      
      <Menu
        id="basic-menu"
        className="logout--menu"
        anchorEl={anchorEl}
        open={menuopen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <ListItem button component={Link} to="/dashboard" onClick={handleClose}>
          <ListItemIcon>
            <AppsIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
        <ListItem button component={Link} to="/settings/invoices" onClick={handleClose}>
          <ListItemIcon>
            <ReceiptIcon />
          </ListItemIcon>
          <ListItemText primary="Invoices" />
        </ListItem>
        <ListItem button component={Link} to="/settings/profile" onClick={handleClose}>
          <ListItemIcon>
            <SettingsOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Settings" />
        </ListItem>
        <Divider />
        <ListItem button component={Link} to="/" onClick={logout}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </Menu>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerClick}
            edge="start"
            sx={{
              marginRight: '36px',
              ...(open && { visibility: 'hidden' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Header />
          <DrawerHeader style={{ cursor: 'pointer' }} aria-controls={menuopen ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={menuopen ? 'true' : undefined}
            onClick={handleClick}>
            <Avatar style={{ marginRight: "5px" }} className='sc--header--avatar'>{profile.profile.name.charAt(0).toUpperCase()} </Avatar>
            <span>{profile.profile.name}</span>
            <KeyboardArrowDownIcon />
          </DrawerHeader>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <a href="/" className="logo-link">
            <img className="loginlogo" src="/assets/images/shippingchimp_logo.png" alt="logo-dark" />
          </a>
          <IconButton onClick={handleDrawerClick}>
            {(open === false) ? <ChevronRightIcon style={{position:'relative',left:'10px'}}/> : <ChevronLeftIcon style={{position:'relative',top:'3px'}}/>}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem button component={Link} to="/dashboard" selected={currentPage === "dashboard" ? true : false} onClick={(event) => handleListItemClick(event, 0)}>
            <ListItemIcon>
              <AppsIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
          <ListItem button component={Link} to="/orders/all">
            <ListItemIcon>
              <ArticleOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Orders" />
          </ListItem>
          <List component="div" disablePadding>
            <List component="div" disablePadding>
              <ListItemButton className={open ? "sc--submenu--leftalign" : "sc--menu--drawerclose"} sx={{ pl: 4 }} component={Link} to="/orders/all" selected={currentPage === "orders/all" &&  urlParam==null ? true : false} onClick={(event) => handleListItemClick(event, 2)}>
                <ListItemIcon>
                  <ArticleOutlinedIcon />
                </ListItemIcon>
                <ListItemText className="sc--submenu--text" primary="All Orders" />
              </ListItemButton>
            </List>
            <List component="div" disablePadding>
              <ListItemButton className={open ? "sc--submenu--leftalign" : "sc--menu--drawerclose"} sx={{ pl: 4 }} component={Link} to="/orders/all?report=openorders" selected={urlParam === "openorders" ? true : false} onClick={(event) => handleListItemClick(event, 2)}>
                <ListItemIcon>
                  <FolderOpenOutlinedIcon />
                </ListItemIcon>
                <ListItemText className="sc--submenu--text" primary="Open Orders" />
              </ListItemButton>
            </List>
            {/* <ListItemButton className={open ? "sc--submenu--leftalign" : "sc--menu--drawerclose"} sx={{ pl: 4 }} component={Link} to="/orders/new" selected={currentPage === "orders/new" ? true : false} onClick={(event) => handleListItemClick(event, 3)}>
              <ListItemIcon>
                <AddBoxOutlinedIcon />
              </ListItemIcon>
              <ListItemText className="sc--submenu--text" primary="New Order" />
            </ListItemButton> */}
          </List>
          <List component="div" disablePadding>
            <ListItemButton className={open ? "sc--submenu--leftalign" : "sc--menu--drawerclose"} sx={{ pl: 4 }} component={Link} to="/orders/bulk" selected={currentPage === "orders/bulk" ? true : false} onClick={(event) => handleListItemClick(event, 4)}>
              <ListItemIcon>
                <AddBusinessOutlinedIcon />
              </ListItemIcon>
              <ListItemText className="sc--submenu--text" primary="Bulk Order" />
            </ListItemButton>
          </List>
          <List component="div" disablePadding>
            <ListItemButton className={open ? "sc--submenu--leftalign" : "sc--menu--drawerclose"} sx={{ pl: 4 }} component={Link} to="/orders/picklist" selected={currentPage === "orders/picklist" ? true : false} onClick={(event) => handleListItemClick(event, 4)}>
              <ListItemIcon>
                <FactCheckOutlinedIcon />
              </ListItemIcon>
              <ListItemText className="sc--submenu--text" primary="Order Picklist" />
            </ListItemButton>
          </List>
          <List component="div" disablePadding style={{ display: (profile && profile.profile && profile.profile.is_labelgen_extra_menu_visible) ? "block" : "none" }}>
            <ListItemButton className={open ? "sc--submenu--leftalign" : "sc--menu--drawerclose"} sx={{ pl: 4 }} component={Link} to="/orders/quick" selected={currentPage === "orders/quick" ? true : false} onClick={(event) => handleListItemClick(event, 4)}>
              <ListItemIcon>
                <BoltOutlinedIcon />
              </ListItemIcon>
              <ListItemText className="sc--submenu--text" primary="Quick Order" />
            </ListItemButton>
          </List>
          <List component="div" disablePadding style={{ display: (profile && profile.profile && profile.profile.is_labelgen_extra_menu_visible) ? "block" : "none" }}>
            <ListItemButton className={open ? "sc--submenu--leftalign" : "sc--menu--drawerclose"} sx={{ pl: 4 }} component={Link} to="/manifests" selected={currentPage === "manifests" ? true : false} onClick={(event) => handleListItemClick(event, 6)}>
              <ListItemIcon>
                <PlaylistRemoveIcon />
              </ListItemIcon>
              <ListItemText className="sc--submenu--text" primary="Open Manifest" />
            </ListItemButton>
          </List>
          <List component="div" disablePadding>
            <ListItemButton className={open ? "sc--submenu--leftalign" : "sc--menu--drawerclose"} sx={{ pl: 4 }} component={Link} to="/reports/manifests" selected={currentPage === "reports/manifests" ? true : false} onClick={(event) => handleListItemClick(event, 6)}>
              <ListItemIcon>
                <AssignmentTurnedInOutlinedIcon />
              </ListItemIcon>
              <ListItemText className="sc--submenu--text" primary="Manifests" />
            </ListItemButton>
          </List>
          <List component="div" disablePadding style={{ display: (profile && profile.profile && profile.profile.enable_pickup_menu) ? "block" : "none" }}>
            <ListItemButton className={open ? "sc--submenu--leftalign" : "sc--menu--drawerclose"} sx={{ pl: 4 }} component={Link} to="/reports/pickup" selected={currentPage === "reports/pickup" ? true : false} onClick={(event) => handleListItemClick(event, 7)}>
              <ListItemIcon>
                <LocalMallOutlinedIcon />
              </ListItemIcon>
              <ListItemText className="sc--submenu--text" primary="Pickup" />
            </ListItemButton>
          </List>
          <ListItem button component={Link} to="/reports/deliveries" selected={currentPage === "reports/deliveries"  ? true : false} onClick={(event) => handleListItemClick(event, 5)}>
            <ListItemIcon>
              <LocalShippingOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Deliveries" />
          </ListItem>
          {/* <ListItem button component={Link} to="/claims" selected={currentPage === "claims"  ? true : false} onClick={(event) => handleListItemClick(event, 5)}>
            <ListItemIcon>
              <NewspaperIcon />
            </ListItemIcon>
            <ListItemText primary="Claims" />
          </ListItem> */}
          <ListItem button component={Link} to="/claims" selected={currentPage === "claims"  ? true : false} onClick={(event) => handleListItemClick(event, 5)}>
            <ListItemIcon>
              <NewspaperIcon />
            </ListItemIcon>
            <ListItemText primary="Claims" />
          </ListItem>
          {/* <List component="div" disablePadding>
            <ListItemButton className={open ? "sc--submenu--leftalign" : "sc--menu--drawerclose"} sx={{ pl: 4 }} component={Link} to="/reports/notifications" selected={currentPage === "reports/notifications" ? true : false} onClick={(event) => handleListItemClick(event, 6)}>
              <ListItemIcon>
                <NotificationsActiveOutlinedIcon />
              </ListItemIcon>
              <ListItemText className="sc--submenu--text" primary="Notifications" />
            </ListItemButton>
          </List> */}
          {/* <List component="div" disablePadding>
            <ListItemButton className={open ? "sc--submenu--leftalign" : "sc--menu--drawerclose"} sx={{ pl: 4 }} component={Link} to="/reports/feedback" selected={currentPage === "reports/feedback" ? true : false} onClick={(event) => handleListItemClick(event, 6)}>
              <ListItemIcon>
                <FeedbackOutlinedIcon />
              </ListItemIcon>
              <ListItemText className="sc--submenu--text" primary="Feedback" />
            </ListItemButton>
          </List>
          <List component="div" disablePadding>
            <ListItemButton className={open ? "sc--submenu--leftalign" : "sc--menu--drawerclose"} sx={{ pl: 4 }} component={Link} to="/reports/widget-chat" selected={currentPage === "reports/widget-chat" ? true : false} onClick={(event) => handleListItemClick(event, 6)}>
              <ListItemIcon>
                <ChatOutlinedIcon />
              </ListItemIcon>
              <ListItemText className="sc--submenu--text" primary="Chatbots" />
            </ListItemButton>
          </List> */}
          {(profile && profile.profile && (profile.profile.id == profile.profile.parent_id)) && <>
          <ListItem button component={Link} to="/integration/marketplace">
            <ListItemIcon>
              <IntegrationInstructionsOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Integration" />
          </ListItem>
          <List component="div" disablePadding>
            <ListItemButton className={open ? "sc--submenu--leftalign" : "sc--menu--drawerclose"} sx={{ pl: 4 }} component={Link} to="/integration/marketplace" selected={currentPage === "integration/marketplace" && urlParam !== "scheduledemo"  ? true : false} onClick={(event) => handleListItemClick(event, 7)}>
              <ListItemIcon>
                <StorefrontIcon />
              </ListItemIcon>
              <ListItemText className="sc--submenu--text" primary="Marketplace" />
            </ListItemButton>
          </List>
          <List component="div" disablePadding style={{ display: (profile && profile.profile && (profile.profile.hide_carrier === false)) ? "block" : "none" }}>
            <ListItemButton className={open ? "sc--submenu--leftalign" : "sc--menu--drawerclose"} sx={{ pl: 4 }} component={Link} to="/integration/carriercredentials" selected={currentPage === "integration/carriercredentials" ? true : false} onClick={(event) => handleListItemClick(event, 7)}>
              <ListItemIcon>
                <CarRentalIcon />
              </ListItemIcon>
              <ListItemText className="sc--submenu--text" primary="Shipments" />
            </ListItemButton>
          </List>
          <List component="div" disablePadding>
            <ListItemButton className={open ? "sc--submenu--leftalign" : "sc--menu--drawerclose"} sx={{ pl: 4 }} component={Link} to="/integration/notification" selected={currentPage === "integration/notification" ? true : false} onClick={(event) => handleListItemClick(event, 7)}>
              <ListItemIcon>
                <NotificationsNoneOutlinedIcon />
              </ListItemIcon>
              <ListItemText className="sc--submenu--text" primary="Notification" />
            </ListItemButton>
          </List>
          </>
          }
          <ListItem button component={Link} to="/settings" selected={currentPage.includes("settings") ? true : false} onClick={(event) => handleListItemClick(event, 8)}>
            <ListItemIcon>
              <SettingsOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItem>
          <ListItem button component={Link} to={{ pathname: "https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ1zZ8qhs6dpVoGQ53GUA-TU39VuoVvdypM5AeiiibgPmgd4CzgnBgM972phfZso-7OgiNr57mnZ?pli=1?name="+profile.profile.name+"&email="+profile.profile.email }} target="_blank" selected={urlParam === "scheduledemo" ? true : false} onClick={(event) => handleListItemClick(event, 8)}>
            <ListItemIcon>
              <CalendarMonthOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Schedule Demo" />
          </ListItem>
          <ListItem >
            {qzState.is_active &&
              <Alert 
              severity="success"
              iconMapping={{
                success: <LocalPrintshopIcon fontSize="inherit" />,
              }}
              style={{whiteSpace: "initial",maxWidth: "200px",wordWrap:"break-word"}}
              >
                <AlertTitle style={{color:"white"}}>
                  {"QZ Mode"}
                { (qzState.printer != "") ? " ("+qzState.printer: "" }
                { (qzState.queue_count && qzState.queue_count >= 0) ? " - " + qzState.queue_count + ")" : ""}
                { (qzState.printer != "" && qzState.queue_count <= 0) ? ")" : ""}
                </AlertTitle>
                <a style={{fontSize:"0.875rem",cursor:"pointer",textDecoration:"underline"}} onClick={() => disconnectQZ()}>Switch to Browser Mode</a>
              </Alert>
            }
            {!qzState.is_active &&
              <Alert 
              severity="info"
              iconMapping={{
                info: <LocalPrintshopIcon fontSize="inherit" />,
              }}
              >
                <AlertTitle style={{color:"white"}}>Browser Mode</AlertTitle>
                <a style={{fontSize:"0.875rem",cursor:"pointer",textDecoration:"underline"}} onClick={() => connectQZ()}>{ (qzState.progress) ? "Switching..." :"Switch to QZ Mode" }</a>
              </Alert>
            }
          </ListItem>
        </List>
      </Drawer>
    </>
  )

}

export default Sidebar;
