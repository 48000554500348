import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// mui components
import {
  Paper,
  Divider,
  styled,
  Grid,
  Box,
  Button,
  Modal,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import RateDrawer from "../../pages/General/RateDrawer";
import Notification from "../../pages/General/Notification";
import TrackingDrawer from "../../pages/General/TrackingDrawer";
import {
  doGetRate,
  resetRateState,
} from "../../pages/Shipments/NewShipment/rateSlice";
import { resetGenerateLabel } from "../../pages/Shipments/NewShipment/createShipmentSlice";
import { fetchTrackingDetail } from "../../pages/Reports/Deliveries/trackingEventsSlice";
import {
  fetchOnboardingData,
  useraccountactivate,
  resetOnboarding,
} from "./onboardingSlice";
import { fetchLabelData, resetLabelData } from "./dashboardSlice";
import { resetOrderNotifications } from "../../pages/Orders/ordersSlice";
import NewOrderCreateDrawer from "../General/NewOrder/NewOrderCreateDrawer";
import BulkOrderCreateDrawer from "../../pages/General/BulkOrderCreateDrawer";
import Printer from "../../pages/General/Printer";
import {
  fetchCountry,
  fetchState,
} from "../../pages/General/countryStateCitySlice";
import { storeQuestionerData } from "../../pages/Auth/authSlice";
import { fetchProfile } from '../Settings/Profile/profileSlice';

import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import LoadingButton from "@mui/lab/LoadingButton";
import Chip from "@mui/material/Chip";
import Slider from "@mui/material/Slider";
import MuiInput from "@mui/material/Input";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from '@mui/material/IconButton';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import Slide from "@mui/material/Slide";
import { set } from "date-fns";
const Input = styled(MuiInput)`
  width: 42px;
`;

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  outline: "none",
  p: 4,
};

const modalStyleLabel = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  outline: "none",
  p: 4,
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  boxShadow: "unset",
  border: "1px solid rgba(0, 0, 0, 0.12)",
  color: theme.palette.text.secondary,
  height: "100%",
  scrollbarColor: "red green",
  "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
    backgroundColor: "rgb(187 182 182 / 87%)",
    width: "5px",
  },
  "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
    borderRadius: 8,
    backgroundColor: "#1976d2",
    minHeight: 24,
    border: "1px solid #1976d2",
  },
  "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
    backgroundColor: "#1976d2",
  },
  "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
    backgroundColor: "#1976d2",
  },
  "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#1976d2",
  },
  "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
    backgroundColor: "#1976d2",
  },
}));

const Iconstyle = {
  height: "40px",
  width: "40px",
  marginRight: "15px",
  color: "#1976d2",
};

const DashboardPage = ({}) => {
  const [countryVolume, setCountryVolume] = React.useState({
    canada: 80,
    us: 50,
    international: 30,
  });
  const [otherStoreVisible, setOtherStoreVisible] = React.useState(false);
  const [otherStoreValue, setOtherStoreValue] = React.useState("");

  const handleSliderChange = (event) => {
    setCountryVolume({
      ...countryVolume,
      ...{
        [event.target.name]: event.target.value,
      },
    });
  };

  const handlePickupinputChange = (event) => {
    setPickuppostalcode(event.target.value)
  };

  const handleInputChange = (event) => {
    setCountryVolume({
      ...countryVolume,
      ...{
        [event.target.name]: event.target.value.replace("%", ""),
      },
    });
  };

  const listOfChips = [
    { id: "0-100 Shipments", name: "0-100 Shipments" },
    { id: "101-500 Shipments", name: "101-500 Shipments" },
    { id: "501-1000 Shipments", name: "501-1000 Shipments" },
    { id: "1001-3000 Shipments", name: "1001-3000 Shipments" },
    { id: "3001-5000 Shipments", name: "3001-5000 Shipments" },
    { id: "5000+ Shipments", name: "5000+ Shipments" },
  ];

  const listOfChipsLogos = [
    { id: "Shopify", name: "shopify" },
    { id: "BigCommerce", name: "bigcommerce" },
    { id: "WooCommerce", name: "woocommerce" },
    { id: "Amazon", name: "amazon" },
    { id: "Magento", name: "magento" },
    { id: "Etsy", name: "etsy" },
    { id: "Other", name: "other" },
  ];
  const [allChips, setAllChips] = React.useState(listOfChips);
  const [selectedVolume, setSelectedVolume] = React.useState("");

  const [selectedStore, setSelectedStore] = React.useState(new Set());

  const [allChipsLogos, setAllChipsLogos] = React.useState(listOfChipsLogos);

  // const [countryShipments, setCountryShipments] = React.useState(listOfChipsLogos);

  function handleSelectionChanged(id) {
    // treat state as immutable
    // React only does a shallow comparison so we need a new Set
    if (id != "Other") {
      const newSet = new Set(selectedStore);
      if (newSet.has(id)) newSet.delete(id);
      else newSet.add(id);
      setSelectedStore(newSet);
      setOtherStoreVisible(false);
    } else {
      setOtherStoreVisible(true);
    }
  }

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const profile = useSelector(state => state.profile);
  const orders = useSelector((state) => state.orders);
  const box = useSelector((state) => state.box);
  const newcreateshipment = useSelector((state) => state.createshipment);
  const onboarding = useSelector(
    (state) => state.onboarding && state.onboarding.notificationdata
  );
  const activation = useSelector(
    (state) => state.onboarding && state.onboarding.activation
  );
  const labelsearch = useSelector(
    (state) => state.dashboard && state.dashboard.labelsearch
  );
  const country = useSelector((state) => state.countrystate.country);
  const state = useSelector((state) => state.countrystate.state);
  const filteredCountryData = country.data.filter(({ code }) => code );
  const [alert, setAlert] = useState(false);
  const [visible, setVisible] = useState(false);
  const [formDataValues, setFormDataValues] = useState("");
  const [trackingNumber, settrackingNumber] = useState("");
  const [orderpickid, setorderpickid] = useState("");
  const [orderpickidErrors, setoderpickidErrors] = useState(null);
  const [trackingNumberErrors, setTrackingNumberErrors] = useState(null);
  const [trackingDrawerVisible, setTrackingDrawerVisible] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [newOrderCreateDrawerVisible, setNewOrderCreateDrawerVisible] =
    useState(false);
  const [bulkOrderCreateDrawerVisible, setBulkOrderCreateDrawerVisible] =
    useState(false);
  const [newOrderFormData, setNewOrderFormData] = useState("");
  const [labelPrinted, setLabelPrinted] = useState(false);
  const [printUrl, setPrintUrl] = useState("");
  const [isPrinted, setIsPrinted] = useState(true);
  const [isRateShown, setIsRateShown] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [storeerror, setStoreError] = useState(null);
  const [volumeError, setVolumeError] = useState(null);
  const [pickuppostalcodeError, setPickuppostalcodeError] = useState(null);
  const [countryvolumeError, setCountryvolumeError] = useState(null);
  const [questionVisible, SetQuestionVisible] = useState(false);
  const [pickupvaluesError, setpickupvaluesError] = useState(null);
  const [isMultiPiece, setIsMultiPiece] = useState(false)
  const [isReturnOrder, setIsReturnOrder] = useState(false);
  const [carrier, setCarrier] = useState("");
  const [storeurl, setStoreurl] = useState("");
  const [pickuppostalcode, setPickuppostalcode] = useState("");

  const initialPickupValues = {
    pickup: false,
    dropoff: false,
  };
  const [pickupValues, setPickupValues] = useState(initialPickupValues);
  const onLabelChange = (e) => {
    const { name, value } = e.target;
    setPickupValues({
      ...pickupValues,
      [name]: e.target.checked,
    });
  };

  const [labelSearchValues, setLabelSearchValues] = useState({
    search_by: "order_id",
    search_value: "",
  });
  const [questinerData, setQuestinerData] = useState({
    volume: "",
    country: "",
    store: "",
  });

  const [multipleLabelModalVisible, setMultipleLabelModalVisible] =
    useState(false);
  const [multipleLabelData, setMultipleLabelData] = useState([]);
  const rates = useSelector((state) => state.rates);

  const bulkOrderCreateCallback = (item) => {
    setBulkOrderCreateDrawerVisible(true);
  };
  const bulkOrderCreateOnClose = (values) => {
    setBulkOrderCreateDrawerVisible(false);
  };
  const newOrderCreateCallback = (item) => {
    setNewOrderCreateDrawerVisible(true);
  };
  const newOrderCreateOnClose = (values) => {
    setNewOrderCreateDrawerVisible(false);
  };
  const handleClose = () => {
    setSnackOpen(false);
    dispatch(resetOnboarding());
    dispatch(resetRateState());
  };
  const initialValues = {
    postal_code: "",
    country_code: "",
    province: "",
    address1:"",
    city:"",
    weight: "",
    weight_unit: "lb",
    length: "",
    height: "",
    width: "",
    dimension_unit: "in",
  };

  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});


  const [scheduleOpen, setscheduleOpen] = React.useState(false);

  const scheduleHandleClose = () => {
    setscheduleOpen(false);
    window.location.href =
        window.location.origin + "/integration/marketplace?report=scheduledemo";
  };

  const columns = [
    {
      field: "order_id",
      headerName: "Order Id",
      headerClassName: "super-app-theme--header",
      width: 200,
      renderCell: (params) => {
        if (params.row.order_id) {
          return params.row.order_id;
        } else {
          return "-";
        }
      },
    },
    {
      field: "tracking_number",
      headerName: "Tracking Number",
      headerClassName: "super-app-theme--header",
      width: 200,
      renderCell: (params) => {
        if (params.row.tracking_number) {
          return params.row.tracking_number;
        } else {
          return "-";
        }
      },
    },
    {
      field: "reference_number",
      headerName: "Reference Number",
      headerClassName: "super-app-theme--header",
      width: 200,
      renderCell: (params) => {
        if (params.row.reference_number) {
          return params.row.reference_number;
        } else {
          return "-";
        }
      },
    },
    {
      field: "label_url",
      headerName: "Action",
      headerClassName: "super-app-theme--header",
      width: 100,
      renderCell: (params) => {
        if (params.row.label_url) {
          return (
            <LocalPrintshopIcon
              onClick={() => OpenLabelClose(params.row.label_url)}
              style={{
                color: "#1890ff",
                pointerEvents: "auto",
                cursor: "pointer",
              }}
            />
          );
        } else {
          return "-";
        }
      },
    },
  ];

  const validator = (fieldValues) => {
    let temp = { ...errors };
    if ("postal_code" in fieldValues)
      temp.postal_code = fieldValues.postal_code
        ? ""
        : "This field is required.";
    if (
      fieldValues &&
      fieldValues.postal_code &&
      fieldValues.postal_code.match(/[^0-9a-z]/i)
    ) {
      temp.postal_code = "Postal code only accepts alpha numeric";
    }
    if (
      (fieldValues &&
        fieldValues.postal_code &&
        fieldValues.postal_code.length < 5) ||
      fieldValues.postal_code === null
    )
      temp.postal_code = "Postalcode required minimum 6 characters";
    if ("weight" in fieldValues)
      temp.weight = fieldValues.weight ? "" : "This field is required.";
    if ("weight_unit" in fieldValues)
      temp.weight_unit = fieldValues.weight_unit
        ? ""
        : "This field is required.";
    if ("length" in fieldValues)
      temp.length =
        fieldValues.length && fieldValues.length > 0
          ? ""
          : "length is required and should be valid";
    if ("weight" in fieldValues)
      temp.weight =
        fieldValues.weight && fieldValues.weight > 0
          ? ""
          : "weight is required and should be valid";
    if ("height" in fieldValues)
      temp.height =
        fieldValues.height && fieldValues.height > 0
          ? ""
          : "height is required and should be valid";
    if ("width" in fieldValues)
      temp.width =
        fieldValues.width && fieldValues.width > 0
          ? ""
          : "width is required and should be valid";
    if ("dimension_unit" in fieldValues)
      temp.dimension_unit = fieldValues.dimension_unit
        ? ""
        : "This field is required.";
    if ("country_code" in fieldValues)
      temp.country_code = fieldValues.country_code
        ? ""
        : "This field is required.";
    if(fieldValues && fieldValues.country_code && fieldValues.country_code!=='CA'){
      console.log(fieldValues.country_code)
      if ("address1" in fieldValues)
        temp.address1 = fieldValues.address1 ? "" : "This field is required.";
      if ("city" in fieldValues)
        temp.city = fieldValues.city ? "" : "This field is required.";
    }else{
      temp.address1 =  "";
      temp.city = "";
    }
    if(fieldValues && fieldValues.country_code && (fieldValues.country_code==='CA' || fieldValues.country_code==='US')){
      if ("province" in fieldValues)
        temp.province = fieldValues.province ? "" : "This field is required.";
    }

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    validator({ [name]: value });
  };

  const formatValues = (recommended_rate_only) => {
    let formValues = values;
    formValues["package_dimension"] =
      values.length + "x" + values.width + "x" + values.height;
    formValues["recommended_rate_only"] = recommended_rate_only;
    let line_items=[{
          "line_item_id":"e029ac7d-e577-4079-b8f3-228a2f9b12ec",
          "product_id":18936,
          "product_desc":"Light Teal - mobile cover",
          "product_url":"https://www.shippingchimp.com/",
          "product_price":7.99,
          "hs_tariff_code":"",
          "hs_tariff_code_country":"",
          "quantity":2,
          "tracking_number":"",
          "origin_country_code":""
        }]
        line_items=JSON.stringify(line_items);
      formValues["line_items"] = line_items;
    return formValues;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = validator(values);
    if (isValid) {
      let recommended_rate_only = false;
      setVisible(true);
      setIsRateShown(false);
      let apikey = profile.profile.api_token ? profile.profile.api_token : "";
      if (
        profile &&
        profile.profile &&
        profile.profile.hide_rate_drawer === true
      ) {
        recommended_rate_only = true;
        setVisible(false);
        setIsRateShown(true);
      }
      setFormDataValues(values);
      dispatch(doGetRate(formatValues(recommended_rate_only), apikey));
    }
  };

  const orderpickValidate = () => {
    if (orderpickid!=="") {
        setoderpickidErrors("");
     }
    }

  const trackOrderHandleSubmit = () => {
    if (orderpickid == "") {
      setoderpickidErrors("Order id is required");
    }  else {
      setoderpickidErrors("");
      window.location.href = window.location.origin + "/orders/picklist?orderid="+orderpickid;
    }
  };


  const validateerror = document.getElementsByClassName("validateErrors");
  if (validateerror) {
    setTimeout(function () {
      SetQuestionVisible(true);
    }, 5000);
  }
  const questionHandleSubmit = () => {
    let selectedStores = Array.from(selectedStore);
    if (otherStoreValue != "") {
      selectedStores.push(otherStoreValue);
    }
    if (
      selectedVolume != "" && 
      (countryVolume.canada != 0 ||
        countryVolume.us != 0 ||
        countryVolume.international != 0) && (pickupValues.dropoff!==false || pickupValues.pickup===true && pickuppostalcode!=='') && pickuppostalcodeError===null && 
      Object.keys(selectedStores).length != 0 && (pickupValues.pickup!==false || pickupValues.dropoff!==false)
    ) {
      let questionerData={}
       if(pickuppostalcode!==''){
          questionerData = {
            volume: selectedVolume,
            country: countryVolume,
            stores: selectedStores,
            carrier: carrier,
            store_url: storeurl,
            pickup: pickupValues.pickup,
            pickup_postal_code:pickuppostalcode,
            dropoff: pickupValues.dropoff,
          }
        }else{
          questionerData = {
            volume: selectedVolume,
            country: countryVolume,
            stores: selectedStores,
            carrier: carrier,
            store_url: storeurl,
            pickup: pickupValues.pickup,
            dropoff: pickupValues.dropoff,
          }
        }
      dispatch(storeQuestionerData(questionerData));
      setDialogOpen(false)
      setscheduleOpen(true)
    } else {
      if (
        selectedVolume == "" ||
        selectedVolume == null ||
        selectedVolume == "undefined"
      ) {
        setVolumeError("Please choose your shipment volume");
      } else {
        setVolumeError(null);
      }
      if (
        countryVolume.canada == 0 ||
        countryVolume.us == 0 ||
        countryVolume.international == 0
      ) {
        setCountryvolumeError("Please choose your ship country");
      } else {
        setCountryvolumeError(null);
      }
      if (Object.keys(selectedStores).length == 0) {
        setStoreError("Store required");
      } else {
        setStoreError(null);
      }
      if(pickupValues.pickup==false && pickupValues.dropoff==false){
        setpickupvaluesError("pickup preference required")
      }else{
        setpickupvaluesError(null);
      }
      if(pickupValues.pickup==true && pickuppostalcode===''){
        setPickuppostalcodeError("pickup postal code required")
      }else{
        setPickuppostalcodeError(null);
      }
    }
  };

  const onSelectCountryChange = (countryCode) => {
    setValues({
      ...values,
      country_code: countryCode,
    });
    setErrors({ sender_country_code: "" });
    dispatch(fetchState(countryCode));
  };

  const onSearchLabelFieldsChange = (event) => {
    let labelSearchValuesTemp = { ...labelSearchValues };
    labelSearchValuesTemp[event.target.name] = event.target.value;
    setLabelSearchValues(labelSearchValuesTemp);
    if (event.charCode === 13) {
      dispatch(fetchLabelData(labelSearchValuesTemp));
      setLabelPrinted(false);
    }
  };

  const onClose = (values) => {
    setVisible(false);
    setIsRateShown(false);
    // dispatch(resetLabelGeneratedOrder())
  };

  
 
  const trackingNumberValidate = (event) => {
    if (event.target.value.trim() == "") {
      setTrackingNumberErrors("Tracking number is required");
    } else if (
      event.target.value.match(
        /[\!\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g
      )
    ) {
      setTrackingNumberErrors("Tracking number is invalid");
    } else {
      setTrackingNumberErrors("");
    }
  };
  const trackHandleSubmit = () => {
    setIsMultiPiece(false)
    if (trackingNumber.trim() == "") {
      setTrackingNumberErrors("Tracking number is required");
    } else if (
      trackingNumber.match(
        /[\!\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g
      )
    ) {
      setTrackingNumberErrors("Tracking number is invalid");
    } else {
      setTrackingNumberErrors("");
      let apikey = profile.profile.api_token ? profile.profile.api_token : "";
      const data = {
        carrier_auto_detect: true,
        tracking_number: trackingNumber,
      };
      let firstChar=trackingNumber.charAt(0);
      if(firstChar=='M'){
        setIsMultiPiece(true)
      }
      dispatch(fetchTrackingDetail(data, apikey));
      setTrackingDrawerVisible(true);
    }
  };

  const trackingCallback = (carriername, trackingNumber) => {
    let apikey =
      profile && profile.profile && profile.profile.api_token
        ? profile.profile.api_token
        : "";
    const data = { carrier_name: carriername, tracking_number: trackingNumber };
    dispatch(fetchTrackingDetail(data, apikey));
    setTrackingDrawerVisible(true);
  };
  const trackingDraweronClose = (values) => {
    setTrackingDrawerVisible(false);
  };

  const handleRequest = () => {
    dispatch(useraccountactivate());
  };
  useEffect(() => {
    if (activation && activation.message) {
      setAlert(true);
      setSnackOpen(true);
      dispatch(fetchOnboardingData());
    } else if (activation && activation.hasErrors == true) {
      setAlert(true);
      setSnackOpen(true);
    }
  }, [dispatch, activation && activation.message]);

  useEffect(() => {
    dispatch(fetchOnboardingData());
    dispatch(fetchCountry());
    dispatch(fetchProfile())
  }, [dispatch]);

  useEffect(() => {
    if (rates && rates.message && rates.message.length !== 0) {
      setSnackOpen(true);
    }
  }, [rates]);

  useEffect(() => {
    if(Object.keys(profile && profile.profile).length !== 0 ){
      if (profile && profile.profile && profile.profile.questioner_data) {
        setDialogOpen(false);
      } else if (
        profile &&
        profile.notification &&
        profile.notification &&
        profile.notification.messageContent != ""
      ) {
        setDialogOpen(false);
        
      } else {
        setDialogOpen(true);
      }
  }
  }, [profile]);

  useEffect(() => {
    if (
      (orders &&
        orders.createShipment &&
        orders.createShipment.notification &&
        orders.createShipment.notification.show &&
        orders.createShipment.notification.messageType === "success") ||
      (newcreateshipment &&
        newcreateshipment.notification &&
        newcreateshipment.notification.show &&
        newcreateshipment.notification.messageType === "success")
    ) {
      const delayDebounceFn = setTimeout(() => {
        window.location.href = window.location.origin + "/orders/all";
      }, 1000);
    }
  }, [orders]);

  const OpenLabelClose = (label_url) => {
    setPrintUrl(label_url);
    setIsPrinted(false);
  };

  const onLabelModalClose = () => {
    let labelSearchValuesTemp = { ...labelSearchValues };
    labelSearchValuesTemp[`search_value`] = "";
    setLabelSearchValues(labelSearchValuesTemp);
    setLabelPrinted(true);
    setMultipleLabelModalVisible(false);
    dispatch(resetLabelData());
  };

  const formatRate = () => {
    let estimatedRate = "-";
    let carrierService = {
      CANADAPOST: "DOM.EP",
      UPS: "11",
      ICS: "GR",
    };
    if (rates.data && Array.isArray(rates.data) && rates.data.length > 0) {
      rates.data.forEach(function (rate) {
        let carrier =
          rate && rate.carrier_name ? rate.carrier_name.toUpperCase() : "";
        let serviceType = carrierService[carrier]
          ? carrierService[carrier]
          : "";
        if (
          rate &&
          rate.service_code &&
          rate.service_code === serviceType &&
          rate.total_charges
        ) {
          estimatedRate = rate.total_charges;
        }
      });
      if (estimatedRate === "-") {
        estimatedRate =
          rates.data[0] && rates.data[0].total_charges
            ? rates.data[0].total_charges
            : "-";
      }
    }
    return estimatedRate;
  };

  useEffect(() => {
    if (
      labelsearch &&
      labelsearch.label &&
      labelsearch.label != "" &&
      labelsearch.label.length != 0 &&
      labelPrinted == false
    ) {
      let printed = false;
      let multipleLabelDataTemp = [];
      Array.from(labelsearch.label).map((text, item) => {
        if (
          text.shipment &&
          text.shipment.tracking &&
          text.shipment.tracking.length != 0
        ) {
          for (var key of Object.keys(text.shipment.tracking)) {
            if (
              text.shipment.tracking[key] &&
              text.shipment.tracking[key].label &&
              ((text.shipment.tracking[key].label.url_detachable &&
                text.shipment.tracking[key].label.url_detachable != "") ||
                (text.shipment.tracking[key].label.url_direct_detachable &&
                  text.shipment.tracking[key].label.url_direct_detachable !=
                    "") ||
                (text.shipment.tracking[key].label.url &&
                  text.shipment.tracking[key].label.url != "") ||
                (text.shipment.tracking[key].label.url_direct &&
                  text.shipment.tracking[key].label.url_direct != ""))
            ) {
              let labelUrl = "";
              if (
                text.shipment.tracking[key].label.url_detachable &&
                text.shipment.tracking[key].label.url_detachable != ""
              ) {
                labelUrl = text.shipment.tracking[key].label.url_detachable;
              } else if (
                text.shipment.tracking[key].label.url_direct_detachable &&
                text.shipment.tracking[key].label.url_direct_detachable != ""
              ) {
                labelUrl =
                  text.shipment.tracking[key].label.url_direct_detachable;
              } else if (
                text.shipment.tracking[key].label.url &&
                text.shipment.tracking[key].label.url != ""
              ) {
                labelUrl = text.shipment.tracking[key].label.url;
              } else if (
                text.shipment.tracking[key].label.url_direct &&
                text.shipment.tracking[key].label.url_direct != ""
              ) {
                labelUrl = text.shipment.tracking[key].label.url_direct;
              }
              if (labelsearch.label.length == 1) {
                OpenLabelClose(labelUrl);
                printed = true;
                break;
              } else {
                multipleLabelDataTemp.push({
                  order_id: text.order && text.order.id ? text.order.id : "",
                  tracking_number: key,
                  reference_number:
                    text.reference && text.reference.no
                      ? text.reference.no
                      : "",
                  label_url: labelUrl,
                });
              }
            }
          }
        }
      });
      if (multipleLabelDataTemp.length != 0) {
        setMultipleLabelData(multipleLabelDataTemp);
        setMultipleLabelModalVisible(true);
      }
      if (printed == true) {
        let labelSearchValuesTemp = { ...labelSearchValues };
        labelSearchValuesTemp[`search_value`] = "";
        setLabelSearchValues(labelSearchValuesTemp);
        setLabelPrinted(true);
        dispatch(resetLabelData());
      }
    }
  }, [labelsearch]);

  return (
    <>
      {activation && activation.loading === true && (
        <>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </>
      )}
      {newcreateshipment &&
        newcreateshipment.notification &&
        newcreateshipment.notification.show &&
        newcreateshipment.notification.messageType !== "success" && (
          <Notification
            is_open={newcreateshipment.notification.show}
            messageType={newcreateshipment.notification.messageType}
            messages={newcreateshipment.notification.messageContent}
            handleClose={() => dispatch(resetGenerateLabel())}
          />
        )}

      {profile && profile.notification && profile.notification.shw && (
        <Notification
          is_open={profile.notification.show}
          messageType={profile.notification.messageType}
          messages={profile.notification.messageContent}
          // handleClose={() => dispatch(resetOrderNotifications())}
        />
      )}
      {orders &&
        orders.createShipment.notification &&
        orders.createShipment.notification.show && (
          <Notification
            is_open={orders.createShipment.notification.show}
            messageType={orders.createShipment.notification.messageType}
            messages={orders.createShipment.notification.messageContent}
            handleClose={() => dispatch(resetOrderNotifications())}
          />
        )}
      {alert && (
        <Snackbar
          open={snackOpen}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            severity={
              activation && activation.hasErrors === true ? "error" : "success"
            }
            style={{ justifyContent: "center", margin: "10px 0" }}
          >
            <span> {activation && activation.message}</span>
          </Alert>
        </Snackbar>
      )}
      {snackOpen && (
        <div className="sc--cardhead--details">
          <Notification
            is_open={snackOpen}
            messageType={rates.hasErrors == true ? "error" : "success"}
            messages={rates.message}
            handleClose={handleClose}
          />
        </div>
      )}
      <Printer
        isPrinted={isPrinted}
        setIsPrinted={setIsPrinted}
        print_url={printUrl}
        closable={true}
      />
      <Modal
        open={
          labelsearch &&
          labelsearch.loading == false &&
          multipleLabelModalVisible
        }
        onClose={onLabelModalClose}
      >
        <Box sx={modalStyleLabel}>
          <h2>
            {"Duplicates found for " +
              labelSearchValues["search_by"].replace("_", " ") +
              " " +
              labelSearchValues["search_value"]}
          </h2>
          <div className="sc--form--center" style={{ margin: "20px 0" }}>
            <DataGrid
              getRowId={(row) =>
                Math.random() + Math.random() + row.tracking_number
              }
              rows={multipleLabelData}
              pageSize={5}
              columns={columns}
              autoHeight={true}
              disableColumnFilter={true}
              disableColumnMenu={true}
              disableSelectionOnClick={true}
            />
          </div>
        </Box>
      </Modal>
      <Modal
        open={
          labelsearch &&
          labelsearch.loading == false &&
          labelsearch.hasErrors == true &&
          labelsearch.message != ""
        }
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div style={{ textAlign: "center" }}>
              {labelsearch &&
                labelsearch.loading == false &&
                labelsearch.hasErrors == true &&
                labelsearch.message != "" &&
                labelsearch.message}
            </div>
            <div style={{ textAlign: "center", marginTop: "10px" }}>
              <Button
                variant="outlined"
                onClick={() => dispatch(resetLabelData())}
              >
                OK
              </Button>
            </div>
          </Typography>
        </Box>
      </Modal>
      <div>
      <Dialog
        keepMounted
        open={
          scheduleOpen
        }
        onClose={scheduleHandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
          <DialogTitle style={{display:'flex',alignItems:'center',justifyContent:'space-between',padding:'0px 24px 0px 35px',marginTop:'20px'}}>
          <h6 style={{textAlign:'center',fontSize:'20px',margin:'10px 0',padding:'0 45px'}}>Would you like to schedule a quick call?</h6>
        <IconButton onClick={scheduleHandleClose}>
            <CloseOutlinedIcon onClick={scheduleHandleClose}/>
        </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          <Typography sx={{  mb: 1,padding:'10px 30px',textAlign:'center' }}>We would love to give you a walkthrough of our platform and answer all your questions.
        <div style={{textAlign:'center'}}><Link to={{ pathname: "https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ1zZ8qhs6dpVoGQ53GUA-TU39VuoVvdypM5AeiiibgPmgd4CzgnBgM972phfZso-7OgiNr57mnZ?pli=1?name="+profile.profile.name+"&email="+profile.profile.email }} target="_blank"><Button
        variant="outlined"
        color="primary"
        style={{ marginRight: "15px",marginTop:"15px", cursor: "pointer" }}
        >
        <CalendarMonthOutlinedIcon
        style={{
            paddingRight: "10px",
        }}
        />
      Schedule a call
    </Button></Link>
    </div>
    </Typography>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      </div>
      <div>
        <Dialog
          keepMounted
          // onClose={handleClose}
          open={dialogOpen}
          aria-describedby="alert-dialog-slide-description"
          className="trial__form__dialog"
        >
          <DialogTitle>
            {"You Made it! Lets Tailor your ShippingChimp experience"}
            <span className="trial__form__wavinghand">
              <img src="/assets/images/trial-form/waving-hand-emoji.png" />
            </span>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <div>
                <p>1. What was our shipping volume in the previous month?</p>

                <p>
                  <React.Fragment>
                    {allChips.map((c) => (
                      <Chip
                        key={c.id}
                        onClick={() => setSelectedVolume(c.id)}
                        variant={selectedVolume == c.id ? "filled" : "outlined"}
                        label={c.name}
                        color="primary"
                      ></Chip>
                    ))}
                  </React.Fragment>
                </p>
                {/* {!questionVisible && <> {volumeError && <p className="validateErrors" style={{ color: 'red' }}>{volumeError}</p>}</>} */}
                {volumeError && <p style={{ color: "#d32f2f",fontSize:'12px' }}>{volumeError}</p>}
                <p style={{ marginTop: "50px" }}>2. Where do you ship to?</p>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  style={{ width: "60%" }}
                >
                  <Grid item>
                    <Typography gutterBottom variant="p" component="div">
                      Within Canada
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Slider
                      name="canada"
                      value={countryVolume.canada}
                      onChange={handleSliderChange}
                      aria-labelledby="input-slider"
                    />
                  </Grid>
                  <Grid item>
                    <Input
                      name="canada"
                      value={countryVolume.canada + "%"}
                      size="small"
                      onChange={handleInputChange}
                      inputProps={{
                        step: 10,
                        min: 0,
                        max: 100,
                        type: "text",
                        "aria-labelledby": "input-slider",
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  style={{ width: "60%" }}
                >
                  <Grid item>
                    <Typography gutterBottom variant="p" component="div">
                      Canada to US
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Slider
                      name="us"
                      value={countryVolume.us}
                      onChange={handleSliderChange}
                      aria-labelledby="input-slider"
                    />
                  </Grid>
                  <Grid item>
                    <Input
                      name="us"
                      value={countryVolume.us + "%"}
                      size="small"
                      onChange={handleInputChange}
                      inputProps={{
                        step: 10,
                        min: 0,
                        max: 100,
                        type: "text",
                        "aria-labelledby": "input-slider",
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  style={{ width: "60%" }}
                >
                  <Grid item>
                    <Typography gutterBottom variant="p" component="div">
                      Internationally
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Slider
                      name="international"
                      value={countryVolume.international}
                      onChange={handleSliderChange}
                      aria-labelledby="input-slider"
                    />
                  </Grid>
                  <Grid item>
                    <Input
                      name="international"
                      value={countryVolume.international + "%"}
                      size="small"
                      onChange={handleInputChange}
                      inputProps={{
                        step: 10,
                        min: 0,
                        max: 100,
                        type: "text",
                        "aria-labelledby": "input-slider",
                      }}
                    />
                  </Grid>
                </Grid>
                {/* {!questionVisible && <> {countryvolumeError && <p className="validateErrors" style={{ color: 'red' }}>{countryvolumeError}</p>}</>} */}
                {countryvolumeError && (
                  <p style={{ color: "#d32f2f",fontSize:'12px' }}>{countryvolumeError}</p>
                )}
                <p style={{ marginTop: "50px" }}>
                  3. What is your current shipping carrier?
                  <div className="sc--form--center" style={{justifyContent:'left'}}>
                  <TextField
                      label="carrier"
                      name="carrier"
                      inputProps={{ maxLength: 35 }}
                      onChange={(e) => {
                        setCarrier(e.target.value);
                      }}
                      variant="standard" style={{ width: "50%", margin: '8px' }}
                    />
                    </div>
                </p>
                <p style={{ marginTop: "50px" }}>
                  4. What is your store URL?
                  <div className="sc--form--center" style={{justifyContent:'left'}}>
                  <TextField
                      label="store URL"
                      name="store_url"
                      inputProps={{ maxLength: 35 }}
                      onChange={(e) => {
                        setStoreurl(e.target.value);
                      }}
                      variant="standard" style={{ width: "50%", margin: '8px' }}
                    />
                    </div>
                </p>
                <p style={{ marginTop: "50px" }}>
                  5. Which store front do you use?
                </p>
                {/* <p ><span className='trial__form__chip'><Chip icon={<img alt="shopify" src="/assets/images/trial-form/shopify.png" style={{maxWidth:'85px',padding:'0 5px'}}/>} color="primary" variant="outlined" /></span> <span className='trial__form__chip'><Chip icon={<img alt="bigcommerce" src="/assets/images/trial-form/bigcommerce.png" style={{maxWidth:'85px',padding:'0 5px'}}/>} color="primary" variant="outlined" /></span> <span className='trial__form__chip'><Chip  icon={<img alt="woocommerce" src="/assets/images/trial-form/woocommerce.png" style={{maxWidth:'85px',padding:'0 5px'}}/>} color="primary" variant="outlined" /></span><span style={{padding:'0 5px'}} className='trial__form__chip'><Chip icon={<img alt="amazon" src="/assets/images/trial-form/amazon.png" style={{maxWidth:'85px',padding:'0 5px'}}/>} color="primary" variant="outlined" />
                        </span>
                       <span className='trial__form__chip'><Chip icon={<img alt="magento" src="/assets/images/trial-form/magento.png" style={{maxWidth:'85px',padding:'0 5px'}}/>} color="primary" variant="outlined" style={{paddingRight:'unset'}}/></span><span style={{padding:'0 5px'}} className='trial__form__chip'><Chip icon={<img alt="etsy" src="/assets/images/trial-form/etsy.png" style={{maxWidth:'60px',padding:'0 5px'}}/>} color="primary" variant="outlined"  /></span><span><Chip label="Others" color="primary" variant="outlined" /></span></p> */}
                <p>
                  <React.Fragment>
                    {listOfChipsLogos.map((c) => (
                      <Chip
                        key={c.id}
                        onClick={() => handleSelectionChanged(c.id)}
                        variant={
                          selectedStore.has(c.id) ? "filled" : "outlined"
                        }
                        color="primary"
                        icon={
                          <img
                            alt={c.name}
                            src={`/assets/images/trial-form/${c.name}.png`}
                            style={{ maxWidth: "85px", padding: "0 5px" }}
                          />
                        }
                      ></Chip>
                    ))}
                  </React.Fragment>
                </p>
                {otherStoreVisible && (
                  <TextField
                    label="Other"
                    variant="standard"
                    name="other_store"
                    type="text"
                    inputProps={{ min: 1 }}
                    value={otherStoreValue}
                    onChange={(event) => {
                      setOtherStoreValue(event.target.value);
                    }}
                    style={{ width: "100%" }}
                  />
                )}
                {storeerror && <p style={{ color: "#d32f2f",fontSize:'12px' }}>{storeerror}</p>}

                <p style={{ marginTop: "50px" }}>6. Your Pickup Preference?</p>
                <div
                  className="sc--form--center"
                  style={{ justifyContent: "flex-start" }}
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={pickupValues.pickup}
                          name="pickup"
                          onChange={onLabelChange}
                        />
                      }
                      label="Pick up"
                      labelPlacement="end"
                    />
                  </FormGroup>

                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={pickupValues.dropoff}
                          name="dropoff"
                          onChange={onLabelChange}
                        />
                      }
                      label="Drop off"
                      labelPlacement="end"
                    />
                  </FormGroup>
                  </div>
                  {pickupvaluesError && <p style={{ color: "#d32f2f",fontSize:'12px' }}>{pickupvaluesError}</p>}
                    <div className="sc--form--center" style={{justifyContent:'left'}}>
                        {pickupValues.pickup && (
                          <TextField
                            label="Pickup Postal Code"
                            variant="standard"
                            name="pickup_postal_code"
                            type="text"
                            inputProps={{ min: 1 }}
                            style={{ width: "50%" }}
                            onChange={handlePickupinputChange}
                            error={pickuppostalcodeError}
                            helperText={pickuppostalcodeError}
                          />
                      )}
                  </div>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={handleClose}>Disagree</Button> */}
            <Button
              onClick={questionHandleSubmit}
              variant="contained"
              style={{ display: "flex", margin: "10px auto" }}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Typography variant="h5" gutterBottom component="div">
        <div>Hello {profile.profile.name}, Welcome back!</div>
      </Typography>
      <p>Quick Links</p>
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          md={4}
          style={{ padding: "10px", cursor: "pointer" }}
        >
          <Item
            className="sc--dashboard--item"
            onClick={newOrderCreateCallback}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "11px 0px",
              }}
            >
              <AddBoxOutlinedIcon sx={Iconstyle} />
              <div>
                <h4 style={{ margin: "unset", textAlign: "left" }}>
                  Create Order
                </h4>
                <p style={{ paddingTop: 6, margin: "unset" }}>
                  Create new shipment using the create order option
                </p>
              </div>
            </div>
          </Item>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          style={{ padding: "10px", cursor: "pointer" }}
        >
          <Item
            className="sc--dashboard--item"
            onClick={bulkOrderCreateCallback}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "11px 0px",
              }}
            >
              <UploadFileOutlinedIcon sx={Iconstyle} />
              <div>
                <h4 style={{ margin: "unset", textAlign: "left" }}>
                  Import Bulk Order
                </h4>
                <p style={{ paddingTop: 6, margin: "unset" }}>
                  Create multiple shipment using the import bulk order option
                </p>
              </div>
            </div>
          </Item>
        </Grid>
        <Grid item xs={12} md={4} style={{ padding: "10px" }}>
          <Link
            to="/integration/marketplace"
            style={{ textDecoration: "none" }}
          >
            <Item className="sc--dashboard--item">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "11px 0px",
                }}
              >
                <StorefrontOutlinedIcon sx={Iconstyle} />
                <div>
                  <h4 style={{ margin: "unset", textAlign: "left" }}>
                    Integration
                  </h4>
                  <p style={{ paddingTop: 6, margin: "unset" }}>
                    Create shipment for the orders of integrated platform
                  </p>
                </div>
              </div>
            </Item>
          </Link>
        </Grid>
      </Grid>

      <p>ShippingChimp Dashboard</p>
      {/* <Grid container direction="row" spacing={1}> */}
      {/* <Grid item xs={12} sm={12} md={12} lg={8} style={{ padding: '10px' }}> */}
      <Grid container direction="row" spacing={1}>
        <Grid item xs={12} md={4} style={{ padding: "10px" }}>
          <Item style={{ height: "unset" }}>
          <h4 style={{ margin: "unset"}}>
                  Tracking
                </h4>
            <Grid
              container
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{ mb: 2 }}
            >
              <Grid xs={12} sm={12} md={12} lg={8} style={{ padding: "0px 10px" }}>
                <TextField
                  id="input-with-icon-textfield"
                  name="tracking_number"
                  label="Tracking number"
                  variant="standard"
                  onChange={(e) => {
                    settrackingNumber(e.target.value);
                  }}
                  style={{ width: "100%", margin: "12px 0" }}
                  onKeyUp={trackingNumberValidate}
                  error={trackingNumberErrors}
                  helperText={trackingNumberErrors}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={3}>
                <Button
                  variant="outlined"
                  sx={{ mt: 2, ml: 2, width: "100%" }}
                  onClick={trackHandleSubmit}
                >
                  Track
                </Button>
              </Grid>
            </Grid>
          </Item>
        </Grid>
        <Grid item xs={12} md={4} style={{ padding: "10px" }}>
          <Item style={{ height: "unset" }}>
            <h4 style={{ margin: "unset" }}>Scan and Print Label</h4>
            {labelsearch && labelsearch.loading === true && (
              <Grid item xs={12} md={12}>
                <LinearProgress />
              </Grid>
            )}
            <Grid
              container
              direction="row"
              alignItems="center"
              spacing={1}
              style={{ margin: "12px 0" }}
            >
              <Grid item xs={12} sm={12} md={6} lg={5}>
                <FormControl variant="standard" style={{ width: "100%" }}>
                  <Select
                    name="search_by"
                    onChange={onSearchLabelFieldsChange}
                    value={labelSearchValues.search_by}
                    label="Search By"
                    style={{ width: "100%", marginTop: "13px" }}
                  >
                    <MenuItem value="order_id">Order Id</MenuItem>
                    <MenuItem value="tracking_number">Tracking Number</MenuItem>
                    <MenuItem value="reference_number">
                      Reference Number
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  placeholder={
                    "Enter " + labelSearchValues.search_by.replace("_", " ")
                  }
                  label={labelSearchValues.search_by.replace("_", " ")}
                  variant="standard"
                  name="search_value"
                  value={labelSearchValues.search_value}
                  inputProps={{ min: 2, maxLength: 25 }}
                  onChange={onSearchLabelFieldsChange}
                  onKeyPress={onSearchLabelFieldsChange}
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
          </Item>
        </Grid>

        <Grid item xs={12} md={4} style={{ padding: "10px" }}>
          <Item style={{ height: "unset" }}>
          <h4 style={{ margin: "unset", paddingBottom: "5px" }}>
                  Order Picklist
                </h4>
            <Grid
              container
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{ mb: 2 }}
            >
              <Grid xs={12} sm={12} md={12} lg={8} style={{ padding: "0px 10px" }}>
                <TextField
                  id="input-with-icon-textfield"
                  name="order_id"
                  label="Order Id"
                  variant="standard"
                  onChange={(e) => {
                    setorderpickid(e.target.value);
                  }}
                  onKeyUp={(event) => {
                    if (event.key == "Enter"){
                        trackOrderHandleSubmit();
                    }else{
                        orderpickValidate()
                    }
                  }}
                  style={{ width: "100%", margin: "10px 0" }}
                  error={orderpickidErrors}
                  helperText={orderpickidErrors}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={3}>
                <Button
                  variant="outlined"
                  sx={{ mt: 2, ml: 2, width: "100%" }}
                  onClick={trackOrderHandleSubmit}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Item>
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={8} style={{ padding: "10px" }}>
                <Grid item xs={12} md={12}>
                    <Item>
                    {/* <Grid item xs={12} md={12}> */}
                    <h4 style={{ margin: "unset", paddingBottom: "5px" }}>
                        Rate Calculator
                    </h4>
                    <p style={{ margin: "unset", paddingBottom: "5px" }}>
                        Get an estimate with our shipping calculator!
                    </p>
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        style={{ margin: "10px 0" }}
                    >
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                        <TextField
                            label="Postal Code"
                            variant="standard"
                            name="postal_code"
                            value={values.postal_code.toUpperCase()}
                            inputProps={{ maxLength: 6 }}
                            onChange={onInputChange}
                            error={errors.postal_code}
                            helperText={errors.postal_code}
                            style={{ width: "100%" }}
                        />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                        <TextField
                            label="City"
                            variant="standard"
                            name="city"
                            value={values.city}
                            inputProps={{ maxLength: 30 }}
                            onChange={onInputChange}
                            error={errors.city}
                            helperText={errors.city}
                            style={{ width: "100%" }}
                        />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                        <FormControl
                            variant="standard"
                            className="help-text-error"
                            style={{ width: "100%" }}
                        >
                            <InputLabel id="demo-simple-select-standard-label">
                            Country
                            </InputLabel>
                            <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-error"
                            name="country_code"
                            error={errors.country_code}
                            onChange={(event) => {
                                onSelectCountryChange(event.target.value);
                            }}
                            label="Country"
                            >
                            {filteredCountryData.map((key) => {
                                return (
                                <MenuItem value={key.code}>
                                    {key.icon} {key.name}
                                </MenuItem>
                                );
                            })}
                            </Select>
                            <FormHelperText>{errors.country_code}</FormHelperText>
                        </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                        <FormControl
                            variant="standard"
                            className="help-text-error"
                            style={{ width: "100%" }}
                        >
                            <InputLabel id="demo-simple-select-standard-label">
                            Province / State
                            </InputLabel>
                            <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            onChange={onInputChange}
                            name="province"
                            error={errors.province}
                            label="Province / State"
                            >
                            {state &&
                                state.data.map((key) => {
                                return (
                                    <MenuItem key={key.code} value={key.code}>
                                    {key.name}
                                    </MenuItem>
                                );
                                })}
                            </Select>
                            <FormHelperText>{errors.province}</FormHelperText>
                        </FormControl>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        style={{ margin: "10px 0" }}
                    >
                      <Grid item xs={12} sm={12} md={6} lg={3}>
                        <TextField
                            label="Address"
                            variant="standard"
                            name="address1"
                            value={values.address1}
                            inputProps={{ maxLength: 35 }}
                            onChange={onInputChange}
                            error={errors.address1}
                            helperText={errors.address1}
                            style={{ width: "100%" }}
                        />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                        <TextField
                            label="Length"
                            variant="standard"
                            name="length"
                            type="number"
                            inputProps={{ min: 1 }}
                            value={values.length}
                            onChange={onInputChange}
                            error={errors.length}
                            helperText={errors.length}
                            style={{ width: "100%" }}
                        />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                        <TextField
                            label="Width"
                            variant="standard"
                            name="width"
                            type="number"
                            inputProps={{ min: 1 }}
                            value={values.width}
                            onChange={onInputChange}
                            error={errors.width}
                            helperText={errors.width}
                            style={{ width: "100%" }}
                        />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                        <TextField
                            label="Height"
                            variant="standard"
                            name="height"
                            type="number"
                            inputProps={{ min: 1 }}
                            value={values.height}
                            onChange={onInputChange}
                            error={errors.height}
                            helperText={errors.height}
                            style={{ width: "100%" }}
                        />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        style={{ margin: "10px 0" }}
                    >
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                        <FormControl
                            variant="standard"
                            className="help-text-error"
                            style={{ width: "100%" }}
                        >
                            <InputLabel id="demo-simple-select-standard-label">
                            {" "}
                            Dimension Unit{" "}
                            </InputLabel>
                            <Select
                            labelId="demo-simple-select-standard-label"
                            name="dimension_unit"
                            onChange={onInputChange}
                            value={values.dimension_unit}
                            label="Dimension Unit"
                            id="filled-error-helper-text"
                            >
                            <MenuItem value="in">in</MenuItem>
                            <MenuItem value="cm">cm</MenuItem>
                            </Select>
                            <FormHelperText>{errors.dimension_unit}</FormHelperText>
                        </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                        <TextField
                            label="Weight"
                            variant="standard"
                            name="weight"
                            inputProps={{ min: 1 }}
                            type="number"
                            value={values.weight}
                            onChange={onInputChange}
                            error={errors.weight}
                            helperText={errors.weight}
                            style={{ width: "100%" }}
                        />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                        <FormControl
                            variant="standard"
                            className="help-text-error"
                            style={{ width: "100%" }}
                        >
                            <InputLabel id="demo-simple-select-standard-label">
                            {" "}
                            Weight Unit{" "}
                            </InputLabel>
                            <Select
                            labelId="demo-simple-select-standard-label"
                            name="weight_unit"
                            onChange={onInputChange}
                            value={values.weight_unit}
                            label="Weight Unit"
                            >
                            <MenuItem value="kg">kg</MenuItem>
                            <MenuItem value="lb">lb</MenuItem>
                            </Select>
                            <FormHelperText>{errors.weight_unit}</FormHelperText>
                        </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div style={{ textAlign: "center" }}>
                        <LoadingButton
                            variant="outlined"
                            onClick={handleSubmit}
                            style={{ margin: "0 15px" }}
                            loading={rates && rates.loading}
                        >
                            Get Rates
                        </LoadingButton>
                        {profile &&
                            profile.profile &&
                            profile.profile.hide_rate_drawer === true &&
                            rates &&
                            rates.loading === false &&
                            rates &&
                            rates.data &&
                            rates.data.length !== 0 &&
                            isRateShown && (
                            <Button
                                variant="outlined"
                                color="primary"
                                style={{ marginRight: "15px", cursor: "unset" }}
                            >
                                <AttachMoneyIcon
                                style={{
                                    paddingRight: "10px",
                                }}
                                />
                                <span style={{ fontWeight: "600" }}>
                                {formatRate()}
                                </span>
                            </Button>
                            )}
                        </div>
                    </Grid>
                    </Item>
                </Grid>
            </Grid>

        <Grid item xs={12} sm={12} md={12} lg={4} style={{ padding: "10px" }}>
          <Item 
              style={{ 
                    height: (onboarding &&
                      onboarding.data &&
                      onboarding.data.length !== 0) ? "445px" :"340px", 
                    overflowY: "auto" 
                }}
            >
            <Grid container direction="row" spacing={1} sx={{ mb: 2 }}>
              <Grid item xs={12} md={12}>
                <h3 style={{ margin: "unset", paddingBottom: "5px" }}>
                  Account Notifications
                </h3>
                {onboarding && onboarding.loading === true && (
                  <LinearProgress />
                )}
              </Grid>
              <Grid item xs={12} md={12}>
                {onboarding &&
                  onboarding.data &&
                  onboarding.data.length !== 0 &&
                  onboarding.loading === false && (
                    <>
                      {onboarding && onboarding.data && (
                        <>
                          {Object.entries(onboarding.data).map(
                            ([key, value]) => (
                              <>
                                {value.title != "Carrier Credentials" &&
                                  value.title !=
                                    "Carrier Credentials Usage" && (
                                    <>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <div
                                          style={{
                                            padding: "0 20px",
                                            width: "80%",
                                          }}
                                        >
                                          <h4
                                            style={{
                                              paddingTop: "10px",
                                              margin: "unset",
                                            }}
                                          >
                                            {value.title}
                                          </h4>
                                          <p style={{ margin: "10px 0" }}>
                                            {value.message}
                                          </p>
                                        </div>
                                        {value.title === "Billing Address" && (
                                          <div
                                            style={{
                                              paddingBottom: "10px",
                                              width: "20%",
                                            }}
                                          >
                                            <Link
                                              to="/settings/address-billing"
                                              style={{ color: "#1976d2" }}
                                            >
                                              Configure
                                            </Link>
                                          </div>
                                        )}
                                        {(value.title === "Sender Address" ||
                                          value.title ===
                                            "Sender Address Default") && (
                                          <div
                                            style={{
                                              paddingBottom: "10px",
                                              width: "20%",
                                            }}
                                          >
                                            <Link
                                              to="/settings/address-sender"
                                              style={{ color: "#1976d2" }}
                                            >
                                              Configure
                                            </Link>
                                          </div>
                                        )}
                                        {(value.title === "Box Dimensions" ||
                                          value.title ===
                                            "Box Dimensions Default") && (
                                          <div
                                            style={{
                                              paddingBottom: "10px",
                                              width: "20%",
                                            }}
                                          >
                                            <Link
                                              to="/settings/box"
                                              style={{ color: "#1976d2" }}
                                            >
                                              Configure
                                            </Link>
                                          </div>
                                        )}
                                        {(value.title ===
                                          "Carrier Credentials" ||
                                          value.title ===
                                            "Carrier Credentials Usage") && (
                                          <div
                                            style={{
                                              paddingBottom: "10px",
                                              width: "20%",
                                            }}
                                          >
                                            <Link
                                              to="/integration/carriercredentials"
                                              style={{ color: "#1976d2" }}
                                            >
                                              Configure
                                            </Link>
                                          </div>
                                        )}
                                        {value.title ===
                                          "Account Activation" && (
                                          <div
                                            style={{
                                              paddingBottom: "10px",
                                              width: "20%",
                                            }}
                                          >
                                            <p
                                              onClick={handleRequest}
                                              style={{
                                                color: "#1976d2",
                                                cursor: "pointer",
                                                textDecoration: "underline",
                                              }}
                                            >
                                              Request
                                            </p>
                                          </div>
                                        )}
                                      </div>
                                      <Divider />
                                    </>
                                  )}
                              </>
                            )
                          )}
                        </>
                      )}
                    </>
                  )}
              </Grid>

              {onboarding &&
                onboarding.data &&
                onboarding.data.length === 0 && (
                  <Grid item xs={12} md={12}>
                    <div
                      style={{
                        display: "flex",
                        minHeight: "200px",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <h4>No notifications</h4>
                    </div>
                  </Grid>
                )}
            </Grid>
          </Item>
        </Grid>
      </Grid>
      <RateDrawer
        visible={visible}
        onClose={onClose}
        formDataValues={formDataValues}
        reportName="dashboard"
      />
      <TrackingDrawer
        visible={trackingDrawerVisible}
        isMultiPiece={isMultiPiece}
        onClose={trackingDraweronClose}
      />
      {newOrderCreateDrawerVisible && (
        <NewOrderCreateDrawer
          visible={newOrderCreateDrawerVisible}
          reportName={"Dashboard"}
          newOrderFormData={newOrderFormData}
          setNewOrderFormData={setNewOrderFormData}
          onClose={newOrderCreateOnClose}
          setNewOrderCreateDrawerVisible={setNewOrderCreateDrawerVisible}
          isReturnOrder={isReturnOrder}
          setIsReturnOrder={setIsReturnOrder}
        />
      )}
      {bulkOrderCreateDrawerVisible && (
        <BulkOrderCreateDrawer
          visible={bulkOrderCreateDrawerVisible}
          onClose={bulkOrderCreateOnClose}
        />
      )}
    </>
  );
};

export default DashboardPage;
