import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled, useTheme } from '@mui/material/styles';
import { BrowserRouter as Router, Route, Switch, Redirect, useLocation } from 'react-router-dom';
import MuiAlert from '@mui/material/Alert';
import _ from 'lodash';
import * as Sentry from "@sentry/react";
import { LABELGENAPI } from './API';

// mui libraries
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

// Auth
import LoginPage from './pages/Auth/LoginPage';
import RegisterPage from './pages/Auth/RegisterPage';
import PasswordresetPage from './pages/Auth/PasswordresetPage';
import UserVerifyPage from './pages/Auth/UserVerifyPage';
import NotFoundPage from './pages/Auth/NotFoundPage';

// Dashboard
import DashboardPage from './pages/Dashboard/DashboardPage';

// Claims
import ClaimsPage from './pages/Claims/ClaimsPage';

// Orders
import OrdersPage from './pages/Orders/OrdersPage';
import NewShipmentsPage from './pages/Shipments/NewShipment/NewShipmentsPage';
import QuickShipmentsPage from './pages/Shipments/QuickOrder/QuickShipmentsPage';
import BulkOrdersPage from './pages/BulkOrder/BulkOrderPage';

// Reports
import ManifestDetailsPage from './pages/ManifestDetail/ManifestDetailsPage';
import OrderDetailsPage from './pages/OrderDetail/OrderDetailsPage';
import GroupedManifestPage from './pages/Manifest/GroupedManifestsPage';
import DeliveriesPage from './pages/Reports/Deliveries/DeliveriesPage';
import DeliveriesMysqlPage from './pages/Reports/Deliveries/DeliveriesMysqlPage';
import NotificationsDashboardPage from './pages/Reports/Notifications/NotificationsDashboardPage';
import CustomerFeedbackDashboardPage from './pages/Reports/Feedback/CustomerFeedbackDashboardPage';
import WidgetAnalyticsDashboardPage from './pages/Reports/Widgets/WidgetAnalyticsDashboardPage';
import ManifestsPage from './pages/Reports/Manifest/ManifestsPage';
import PickupPage from './pages/Reports/Pickup/PickupPage';

import OrderPicklistPage from './pages/OrderPicklist/OrderPicklistPage';

// Integrations
import IntegrationPage from './pages/Integration/Marketplace/IntegrationPage';
import CarrierCredentialsPage from './pages/Integration/Carrier/CarrierCredentialsPage';
import NotificationPage from './pages/Integration/Notification/IntegrationPage';
import NotificationTemplatePage from './pages/Integration/Notification/NotificationTemplatePage';
// Settings
import SettingsPage from './pages/Settings/SettingsPage';


// components
import Header from './pages/General/Header';
import Sidebar from './pages/General/Sidebar';
import Footer from './pages/General/Footer';
import Notification from './pages/General/Notification'
import Claims from './pages/General/Claims'

// slice
import { authenticate } from './pages/Auth/authSlice';
import { setQzStateInfo, setQzNotifications, resetQzNotifications, setQzInProgress } from './qzSlice';
// css
import './App.css';

const qz = require("qz-tray");

const verifyQzSecuritySignature = () => {
  console.log("Verifying qz signature")
  qz.security.setCertificatePromise((resolve, reject) => {
    LABELGENAPI.
      get("/security/digital-certificate").
      then(function (response) {
        resolve(response.data)
      })
      .catch(function (error) {
        console.log(error.response)
        reject(error.response)
      });
  });
  qz.security.setSignatureAlgorithm("SHA512");
  qz.security.setSignaturePromise((toSign) => {
    return (resolve, reject) => {
      LABELGENAPI.
        get("/security/private-key?data=" + toSign).
        then(function (response) {
          resolve(response.data)
        })
        .catch(function (error) {
          console.log(error.response)
          reject(error.response)
        });
    };
  });
}

const requestQZConnect = (dispatch, manualCall = false) => {
  dispatch(setQzInProgress())
  verifyQzSecuritySignature();
  qz.websocket.connect().then(() => {
    dispatch(setQzStateInfo({
      "is_active": true,
      "qz": qz,
      "printer": ""
    }))
    qz.websocket.setClosedCallbacks(() => {
      dispatch(setQzStateInfo({
        "is_active": false,
        "printer": "",
        "qz": ""
      }))
    })
    return qz.printers.getDefault();
  }).then((printer) => {
    if (printer !== "undefined" && typeof printer !== "undefined" && typeof printer == "string" && printer.length != 0) {
      dispatch(setQzStateInfo({
        "is_active": true,
        "printer": printer,
        "qz": qz
      }))
    }
  }).catch((e) => {
    console.log(e.message)
    if (e.message === "Unable to establish connection with QZ") {
      dispatch(setQzStateInfo({
        "is_active": false,
        "printer": "",
        "qz": ""
      }))
      if (manualCall == true) {
        dispatch(setQzNotifications({
          "message": [e.message],
          "error": true
        }))
      }
    } else if (e.message === "An open connection with QZ Tray already exists") {
      qz.printers.getDefault().then(function (printer) {
        if (printer !== "undefined" && typeof printer !== "undefined" && typeof printer == "string" && printer.length != 0) {
          dispatch(setQzStateInfo({
            "is_active": true,
            "printer": printer,
            "qz": qz
          }))
        }
      })
    }
  })
  return true
}

const requestQZDisconnect = (dispatch, qzState) => {
  qzState.qz.websocket.disconnect().then(() => {
    console.log("Disconnected")
  }).catch((e) => {
    console.log(e.message);
    dispatch(setQzNotifications({
      "message": [e.message],
      "error": true
    }))
  })
}

const App = ({ }) => {
  const { REACT_APP_DEBUG } = process.env;

  const auth = useSelector(state => state.auth);
  // console.log(auth)
  const qzState = useSelector(state => state.qz);
  const profile = useSelector(state => state.auth.profile);
  const dispatch = useDispatch();
  const locations = useLocation();
  const currentPageActive = locations.pathname.replace('/', '');

  useEffect(() => {
    dispatch(authenticate())
    requestQZConnect(dispatch)
  }, [dispatch]);

  useEffect(() => { //Tawk Chat Widget

    if (!_.isUndefined(REACT_APP_DEBUG) && REACT_APP_DEBUG == "false") {
      Sentry.setUser({
        name: profile && profile.name,
        email: profile && profile.email,
        source: (!_.isUndefined(profile.source)) ? profile.source : ""
      });
      // window.Intercom('boot', {
      //   app_id: "j05s0gtf",
      //   name: profile && profile.name,
      //   email: profile && profile.email,
      //   source: (!_.isUndefined(profile.source)) ? profile.source : ""
      // });
      // window.intercomSettings = {
      //   app_id: "j05s0gtf",
      //   "alignment": 'left',
      //   "payment_pending": profile && profile.payment_pending
      // };
      if (!document.getElementById('tawkId')) {
        const script = document.createElement("script");
        script.id = 'tawkId';
        script.async = true;
        script.src = 'https://embed.tawk.to/602563009c4f165d47c26567/1eu90lf34';
        script.charset = 'UTF-8';
        script.setAttribute('crossorigin', '*');
        const first_script_tag = document.getElementsByTagName("script")[0];
        first_script_tag.parentNode.insertBefore(script, first_script_tag)
        window.Tawk_API = window.Tawk_API || {};
        window.Tawk_API.customStyle = {
          visibility: {
          //for desktop only
            desktop: {
              position: 'bl', // bottom-right
              xOffset: 10, // 15px away from right
              yOffset: 50 // 40px up from bottom
            }
          }
        }
      }

      if (!_.isUndefined(profile) && !_.isUndefined(profile.name) && !_.isUndefined(profile.email)) {
        window.dataLayer.push({'dl_user_id': profile.id });
        console.log("data layer pused id")
        window.Tawk_API = window.Tawk_API || {};
        window.Tawk_LoadStart = new Date();
        window.Tawk_API.onLoad = function () {
          if (_.isFunction(window.Tawk_API.setAttributes)) {
            window.Tawk_API.setAttributes({
              name: profile.name,
              email: profile.email
            }, function (error) { });
          }
        }
      }

      if (_.isFunction(window.Autopilot.run)) {
        let splittedName = _.split(profile && profile.name, ' ', 2);
        window.Autopilot.run("associate", {
          Email: profile && profile.email,
          FirstName: (!_.isUndefined(splittedName[0])) ? splittedName[0] : "",
          LastName: (!_.isUndefined(splittedName[1])) ? splittedName[1] : "",
          Status: (!_.isUndefined(profile && profile.status)) ? profile && profile.status : "",
          LeadSource: (!_.isUndefined(profile && profile.source)) ? profile && profile.source : "",
          MailingCountry: (!_.isUndefined(profile && profile.country)) ? profile && profile.country : "",
          custom: {
            "date--email_verified_at": (!_.isUndefined(profile && profile.email_verified_at)) ? profile && profile.email_verified_at : "",
            "date--signup_date": (!_.isUndefined(profile && profile.created_at)) ? profile && profile.created_at : "",
            "string--api_token": (!_.isUndefined(profile && profile.api_token)) ? profile && profile.api_token : "",
            "integer--uid": (!_.isUndefined(profile && profile.id)) ? profile && profile.id : "",
          }
        });
      }
    }
  }, [currentPageActive, profile])

  window.localStorage.setItem("roles", JSON.stringify(auth.loginLoading))
  const roles = () => {
    window.localStorage.setItem("roles", JSON.stringify(auth.profile.name))
  }
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  const Reload = () => {
    window.location.reload();
  }

  const connectQZ = () => {
    console.log("Rechecking QZ")
    requestQZConnect(dispatch, true)
  }

  const disconnectQZ = () => {
    if (qzState.queue_count <= 0) {
      requestQZDisconnect(dispatch, qzState)
    } else {
      dispatch(setQzNotifications({
        "message": ["Please wait for pending queues to be printed"],
        "error": true
      }))
    }
  }

  return (
    <>
      <div>
        {(auth.logoutLoading === true) && <>
          <Reload />
        </>
        }
        {(auth.loginLoading == true) && <>
          <div className="overlay-loader">
            <CircularProgress />
          </div>
        </>
        }
        {(auth.resetLoading == true) && <>
          <div className="overlay-loader">
            <CircularProgress />
          </div>
        </>
        }
        {(currentPageActive === "register") &&
          <>
            <RegisterPage />
          </>
        }
        {(auth.isLoggedIn == false && auth.loginLoading === false && currentPageActive !== "passwordreset" && currentPageActive !== "register") &&
          <>
            <Redirect to="/login" push />
            <LoginPage />
          </>
        }
        {(auth.isLoggedIn == true && auth.loginLoading === false && profile && profile.email_verified_at == null) &&
          <>
            <Redirect to="/userverify" push />
            <UserVerifyPage />
          </>
        }
        {(auth.isLoggedIn == false && currentPageActive == "passwordreset") &&
          <>
            <PasswordresetPage />
          </>
        }

        {(auth.isLoggedIn == true && auth.profile && profile.email_verified_at !== null) && <>
          {(qzState && qzState.notification && qzState.notification.show) &&
            <Notification
              is_open={qzState.notification.show}
              messageType={qzState.notification.messageType}
              messages={qzState.notification.messageContent}
              handleClose={() => dispatch(resetQzNotifications())}
            />
          }
          <Box sx={{ display: 'flex' }} onLoad={roles}>
            <Sidebar currentPage={currentPageActive} connectQZ={connectQZ} disconnectQZ={disconnectQZ} />
            <Box component="main" sx={{ flexGrow: 1, p: 3, background: '#fcfcfc',width:'100%',overflow:'auto' }}>
              <DrawerHeader />
              <Switch>
                <Redirect exact from="/" to="/dashboard" />
                <Redirect exact from="/register" to="/dashboard" />
                <Redirect exact from="/login" to="/dashboard" />
                <Redirect exact from="/orders" to="/orders/all" />
                <Redirect exact from="/integration" to="/integration/marketplace" />
                <Route path='/dashboard' component={DashboardPage} />
                <Route path='/orders/all' component={OrdersPage} />
                <Route path='/orders/open?report=openorders' component={OrdersPage} />
                <Route path='/orders/new' component={NewShipmentsPage} />
                <Route path='/orders/quick' component={QuickShipmentsPage} />
                <Route path='/orders/bulk' component={BulkOrdersPage} />
                <Route path='/orders/picklist' component={OrderPicklistPage} />
                <Route path='/orders/:orderId' component={OrderDetailsPage} />
                <Route path='/manifests/:manifestId' component={ManifestDetailsPage} />
                <Route path='/manifests' component={GroupedManifestPage} />
                {/* <Route path='/claims' component={Claims} /> */}
                <Route path='/claims' component={ClaimsPage} />
                <Route path='/reports/deliveries' component={DeliveriesMysqlPage} />
                <Route path='/reports/manifests' component={ManifestsPage} />
                <Route path='/reports/pickup' component={PickupPage} />
                <Route path='/reports/notifications' component={NotificationsDashboardPage} />
                <Route path='/reports/feedback' component={CustomerFeedbackDashboardPage} />
                <Route path='/reports/widget-chat' component={WidgetAnalyticsDashboardPage} />
                <Route path='/settings' component={SettingsPage} />
                <Route path='/integration/marketplace' component={IntegrationPage} />
                {profile && (profile.is_reliable_user || profile.hide_carrier === false) && <Route path='/integration/carriercredentials' component={CarrierCredentialsPage} />}
                <Route path='/integration/notification' component={NotificationPage} />
                <Route path='/notification/template/:event_type/:notification_type/:is_enabled/:id' component={NotificationTemplatePage} />
                <Route path='*' component={NotFoundPage} />
              </Switch>

            </Box>
          </Box>
        </>
        }

      </div>
    </>
  )
};

export default App;
