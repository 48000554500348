import { createSlice } from '@reduxjs/toolkit';
import { LABELGENAPI} from '../../../API';

export const labelsettingsslice = createSlice({
    name: 'labelSettings',
    initialState: {
        data: [],
        loading: false,
        hasErrors: false,
        message: ""
    },
    reducers: {
        getLabelData: (state) => {
            return {
                ...state,
                loading: true,
                hasErrors: false
            }
        },
        getLabelDataSuccess: (state, action) => {
            return {
                ...state,
                data: action && action.payload && action.payload.data && action.payload.data.data,
                hasErrors: false,
                loading: false
            }
        },
        getLabelDataFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                hasErrors: true,
                message: action && action.payload && action.payload.data && action.payload.data.message
            }
        },
        
        postLabelData: (state) => {
            return {
                ...state,
                loading: true,
                hasErrors: false
            }
        },
        postLabelDataSuccess: (state, action) => {
            return {
                ...state,
                hasErrors: false,
                loading: false,
                message: action && action.payload && action.payload.data && action.payload.data.message
            }
        },
        postLabelDataFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                hasErrors: true,
                message: action && action.payload && action.payload.data && action.payload.data.message
            }
        },
        deleteLabelData: (state) => {
            return {
                ...state,
                loading: true,
                hasErrors: false
            }
        },
        deleteLabelDataSuccess: (state, action) => {
            return {
                ...state,
                hasErrors: false,
                loading: false,
                message: action && action.payload && action.payload.data && action.payload.data.message
            }
        },
        deleteLabelDataFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                hasErrors: true,
                message: action && action.payload && action.payload.data && action.payload.data.message
            }
        },
        resetlabel: (state) => {
            return {
                ...state,
                loading: false,
                hasError: false,
                message: "",
            }
        },


    }
})
export const { getLabelData, getLabelDataSuccess, getLabelDataFailure,  postLabelData, postLabelDataSuccess, postLabelDataFailure,
    deleteLabelData,deleteLabelDataSuccess,deleteLabelDataFailure,resetlabel } = labelsettingsslice.actions;

export const getLabel = data => dispatch => {
    dispatch(postLabelData());
    try {
        LABELGENAPI.get('/settings/label-settings')
            .then(function (response) {
                dispatch(getLabelDataSuccess(response));
            }).catch(function (error) {
                dispatch(getLabelDataFailure(error.response));
            })
    }
    catch (error) {
        dispatch(getLabelDataFailure(error.response));
    }
}
export const postlabel = (label_format,logoDelete,data) => dispatch => {
    dispatch(postLabelData());
    try {
        LABELGENAPI.post('/settings/label-settings?label_format=' + label_format+'&logo_remove='+logoDelete, data)
            .then(function (response) {
                dispatch(postLabelDataSuccess(response));
                dispatch(getLabel());
            }).catch(function (error) {
                dispatch(postLabelDataFailure(error.response));
            })
    }
    catch (error) {
        dispatch(postLabelDataFailure(error.response));
    }
}
export const deletelabel = data => dispatch => {
    dispatch(deleteLabelData());
    try {
        LABELGENAPI.delete('/settings/label-settings')
            .then(function (response) {
                dispatch(deleteLabelDataSuccess(response));
            }).catch(function (error) {
                dispatch(deleteLabelDataFailure(error.response));
            })
    }
    catch (error) {
        dispatch(deleteLabelDataFailure(error.response));
    }
}
export const resetLabelState = data => dispatch => {
    dispatch(resetlabel());
}

export default labelsettingsslice.reducer;
