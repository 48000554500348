import { createSlice } from '@reduxjs/toolkit';
import { LABELGENAPI} from '../../../API';

export const configslice = createSlice({
    name: 'config',
    initialState: {
        data: [],
        loading: false,
        hasErrors: false,
        message: ""
    },
    reducers: {
        getConfig: (state) => {
            return {
                ...state,
                loading: true,
                hasErrors: false
            }
        },
        getConfigSuccess: (state, action) => {
            return {
                ...state,
                data: action && action.payload && action.payload.data && action.payload.data.data,
                hasErrors: false,
                loading: false
            }
        },
        getConfigFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                hasErrors: true,
                message: action && action.payload && action.payload.data && action.payload.data.message
            }
        }
    }
})


export const { getConfig, getConfigSuccess, getConfigFailure } = configslice.actions;

export const fetchConfig = () => dispatch => {
    dispatch(getConfig());
    try {
        LABELGENAPI
            .get("/settings/config")
            .then(function (response) {
                dispatch(getConfigSuccess(response));
            })
            .catch(function (error) {
                dispatch(getConfigFailure(error.response));
            });
    } catch (error) {
        dispatch(getConfigFailure());
    }
};

export default configslice.reducer;