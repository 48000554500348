import { createSlice } from '@reduxjs/toolkit';
import { LABELGENAPI } from '../../API';
import axios from 'axios';

/* canceling previous requests if multiple requests is triggered */
const CancelToken = axios.CancelToken;
let source = CancelToken.source();

export const hubslice = createSlice({
    name: 'hub',
    initialState: {
        data: {},
        loading: false,
        notification: { show: false, messageContent: "", messageType: "success" }
    },
    reducers: {
        getHub: (state) => {
            return {
                ...state,
                loading: true
            }
        },
        getHubSuccess: (state, action) => {
            return {
                ...state,
                data: action && action.payload && action.payload.data && action.payload.data.data,
                loading: false
            }
        },
        getHubFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                notification: { show: true, messageContent: action.data && action.data.message, messageType: "error" }
            }
        },
    }
})


export const { getHub, getHubSuccess, getHubFailure } = hubslice.actions;

export const fetchHubs = (meta) => {

    return (dispatch) => {

        dispatch(getHub());
        try {
            LABELGENAPI
                .get('/hubs')
                .then(function (response) {
                    dispatch(getHubSuccess(response));
                })
                .catch(function (error) {
                    dispatch(getHubFailure(error.response));
                });
        } catch (error) {
            dispatch(getHubFailure());
        }
    }
};

export const { resetNotification } = hubslice.actions;

export const resetHubNotification = () => {
    return (dispatch) => {
        dispatch(resetNotification());
    }
}

export default hubslice.reducer;