import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Autocomplete from '@mui/material/Autocomplete';
import LoadingButton from "@mui/lab/LoadingButton";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Snackbar from "@mui/material/Snackbar";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import { styled } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import DeleteIcon from "@mui/icons-material/Delete";
import LinearProgress from "@mui/material/LinearProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import Typography from "@mui/material/Typography";
import Notification from '../../../pages/General/Notification';

import {
    fetchTimezoneData,
    storeTimezoneData,
    resetTimezoneState,
} from "./timezoneSettingsSlice";

const TimezoneSettingsPage = () => {
  const dispatch = useDispatch();
  const timezoneData = useSelector(state => state.timezone);

  const [timezoneSelect,setTimezoneSelect]=useState("System Default");
  const timezones=[
    { label: 'System Default'},
    { label: 'Africa/Abidjan'},
    { label: 'Africa/Accra'},
    { label: 'Africa/Addis_Ababa'},
    { label: 'Africa/Algiers'},
    { label: 'Africa/Asmara'},
    { label: 'Africa/Asmera'},
    { label: 'Africa/Bamako'},
    { label: 'Africa/Bangui'},
    { label: 'Africa/Banjul'},
    { label: 'Africa/Bissau'},
    { label: 'Africa/Blantyre'},
    { label: 'Africa/Brazzaville'},
    { label: 'Africa/Bujumbura'},
    { label: 'Africa/Cairo'},
    { label: 'Africa/Casablanca'},
    { label: 'Africa/Ceuta'},
    { label: 'Africa/Conakry'},
    { label: 'Africa/Dakar'},
    { label: 'Africa/Dar_es_Salaam'},
    { label: 'Africa/Djibouti'},
    { label: 'Africa/Douala'},
    { label: 'Africa/El_Aaiun'},
    { label: 'Africa/Freetown'},
    { label: 'Africa/Gaborone'},
    { label: 'Africa/Harare'},
    { label: 'Africa/Johannesburg'},
    { label: 'Africa/Juba'},
    { label: 'Africa/Kampala'},
    { label: 'Africa/Khartoum'},
    { label: 'Africa/Kigali'},
    { label: 'Africa/Kinshasa'},
    { label: 'Africa/Lagos'},
    { label: 'Africa/Libreville'},
    { label: 'Africa/Lome'},
    { label: 'Africa/Luanda'},
    { label: 'Africa/Lubumbashi'},
    { label: 'Africa/Lusaka'},
    { label: 'Africa/Malabo'},
    { label: 'Africa/Maputo'},
    { label: 'Africa/Maseru'},
    { label: 'Africa/Mbabane'},
    { label: 'Africa/Mogadishu'},
    { label: 'Africa/Monrovia'},
    { label: 'Africa/Nairobi'},
    { label: 'Africa/Ndjamena'},
    { label: 'Africa/Niamey'},
    { label: 'Africa/Nouakchott'},
    { label: 'Africa/Ouagadougou'},
    { label: 'Africa/Porto-Novo'},
    { label: 'Africa/Sao_Tome'},
    { label: 'Africa/Timbuktu'},
    { label: 'Africa/Tripoli'},
    { label: 'Africa/Tunis'},
    { label: 'Africa/Windhoek'},
    { label: 'America/Adak'},
    { label: 'America/Anchorage'},
    { label: 'America/Anguilla'},
    { label: 'America/Antigua'},
    { label: 'America/Araguaina'},
    { label: 'America/Argentina/Buenos_Aires'},
    { label: 'America/Argentina/Catamarca'},
    { label: 'America/Argentina/ComodRivadavia'},
    { label: 'America/Argentina/Cordoba'},
    { label: 'America/Argentina/Jujuy'},
    { label: 'America/Argentina/La_Rioja'},
    { label: 'America/Argentina/Mendoza'},
    { label: 'America/Argentina/Rio_Gallegos'},
    { label: 'America/Argentina/Salta'},
    { label: 'America/Argentina/San_Juan'},
    { label: 'America/Argentina/San_Luis'},
    { label: 'America/Argentina/Tucuman'},
    { label: 'America/Argentina/Ushuaia'},
    { label: 'America/Aruba'},
    { label: 'America/Asuncion'},
    { label: 'America/Atikokan'},
    { label: 'America/Atka'},
    { label: 'America/Bahia'},
    { label: 'America/Bahia_Banderas'},
    { label: 'America/Barbados'},
    { label: 'America/Belem'},
    { label: 'America/Belize'},
    { label: 'America/Blanc-Sablon'},
    { label: 'America/Boa_Vista'},
    { label: 'America/Bogota'},
    { label: 'America/Boise'},
    { label: 'America/Buenos_Aires'},
    { label: 'America/Cambridge_Bay'},
    { label: 'America/Campo_Grande'},
    { label: 'America/Cancun'},
    { label: 'America/Caracas'},
    { label: 'America/Catamarca'},
    { label: 'America/Cayenne'},
    { label: 'America/Cayman'},
    { label: 'America/Chicago'},
    { label: 'America/Chihuahua'},
    { label: 'America/Coral_Harbour'},
    { label: 'America/Cordoba'},
    { label: 'America/Costa_Rica'},
    { label: 'America/Creston'},
    { label: 'America/Cuiaba'},
    { label: 'America/Curacao'},
    { label: 'America/Danmarkshavn'},
    { label: 'America/Dawson'},
    { label: 'America/Dawson_Creek'},
    { label: 'America/Denver'},
    { label: 'America/Detroit'},
    { label: 'America/Dominica'},
    { label: 'America/Edmonton'},
    { label: 'America/Eirunepe'},
    { label: 'America/El_Salvador'},
    { label: 'America/Ensenada'},
    { label: 'America/Fort_Nelson'},
    { label: 'America/Fort_Wayne'},
    { label: 'America/Fortaleza'},
    { label: 'America/Glace_Bay'},
    { label: 'America/Godthab'},
    { label: 'America/Goose_Bay'},
    { label: 'America/Grand_Turk'},
    { label: 'America/Grenada'},
    { label: 'America/Guadeloupe'},
    { label: 'America/Guatemala'},
    { label: 'America/Guayaquil'},
    { label: 'America/Guyana'},
    { label: 'America/Halifax'},
    { label: 'America/Havana'},
    { label: 'America/Hermosillo'},
    { label: 'America/Indiana/Indianapolis'},
    { label: 'America/Indiana/Knox'},
    { label: 'America/Indiana/Marengo'},
    { label: 'America/Indiana/Petersburg'},
    { label: 'America/Indiana/Tell_City'},
    { label: 'America/Indiana/Vevay'},
    { label: 'America/Indiana/Vincennes'},
    { label: 'America/Indiana/Winamac'},
    { label: 'America/Indianapolis'},
    { label: 'America/Inuvik'},
    { label: 'America/Iqaluit'},
    { label: 'America/Jamaica'},
    { label: 'America/Jujuy'},
    { label: 'America/Juneau'},
    { label: 'America/Kentucky/Louisville'},
    { label: 'America/Kentucky/Monticello'},
    { label: 'America/Knox_IN'},
    { label: 'America/Kralendijk'},
    { label: 'America/La_Paz'},
    { label: 'America/Lima'},
    { label: 'America/Los_Angeles'},
    { label: 'America/Louisville'},
    { label: 'America/Lower_Princes'},
    { label: 'America/Maceio'},
    { label: 'America/Managua'},
    { label: 'America/Manaus'},
    { label: 'America/Marigot'},
    { label: 'America/Martinique'},
    { label: 'America/Matamoros'},
    { label: 'America/Mazatlan'},
    { label: 'America/Mendoza'},
    { label: 'America/Menominee'},
    { label: 'America/Merida'},
    { label: 'America/Metlakatla'},
    { label: 'America/Mexico_City'},
    { label: 'America/Miquelon'},
    { label: 'America/Moncton'},
    { label: 'America/Monterrey'},
    { label: 'America/Montevideo'},
    { label: 'America/Montreal'},
    { label: 'America/Montserrat'},
    { label: 'America/Nassau'},
    { label: 'America/New_York'},
    { label: 'America/Nipigon'},
    { label: 'America/Nome'},
    { label: 'America/Noronha'},
    { label: 'America/North_Dakota/Beulah'},
    { label: 'America/North_Dakota/Center'},
    { label: 'America/North_Dakota/New_Salem'},
    { label: 'America/Ojinaga'},
    { label: 'America/Panama'},
    { label: 'America/Pangnirtung'},
    { label: 'America/Paramaribo'},
    { label: 'America/Phoenix'},
    { label: 'America/Port-au-Prince'},
    { label: 'America/Port_of_Spain'},
    { label: 'America/Porto_Acre'},
    { label: 'America/Porto_Velho'},
    { label: 'America/Puerto_Rico'},
    { label: 'America/Punta_Arenas'},
    { label: 'America/Rainy_River'},
    { label: 'America/Rankin_Inlet'},
    { label: 'America/Recife'},
    { label: 'America/Regina'},
    { label: 'America/Resolute'},
    { label: 'America/Rio_Branco'},
    { label: 'America/Rosario'},
    { label: 'America/Santa_Isabel'},
    { label: 'America/Santarem'},
    { label: 'America/Santiago'},
    { label: 'America/Santo_Domingo'},
    { label: 'America/Sao_Paulo'},
    { label: 'America/Scoresbysund'},
    { label: 'America/Shiprock'},
    { label: 'America/Sitka'},
    { label: 'America/St_Barthelemy'},
    { label: 'America/St_Johns'},
    { label: 'America/St_Kitts'},
    { label: 'America/St_Lucia'},
    { label: 'America/St_Thomas'},
    { label: 'America/St_Vincent'},
    { label: 'America/Swift_Current'},
    { label: 'America/Tegucigalpa'},
    { label: 'America/Thule'},
    { label: 'America/Thunder_Bay'},
    { label: 'America/Tijuana'},
    { label: 'America/Toronto'},
    { label: 'America/Tortola'},
    { label: 'America/Vancouver'},
    { label: 'America/Virgin'},
    { label: 'America/Whitehorse'},
    { label: 'America/Winnipeg'},
    { label: 'America/Yakutat'},
    { label: 'America/Yellowknife'},
    { label: 'Antarctica/Casey'},
    { label: 'Antarctica/Davis'},
    { label: 'Antarctica/DumontDUrville'},
    { label: 'Antarctica/Macquarie'},
    { label: 'Antarctica/Mawson'},
    { label: 'Antarctica/McMurdo'},
    { label: 'Antarctica/Palmer'},
    { label: 'Antarctica/Rothera'},
    { label: 'Antarctica/South_Pole'},
    { label: 'Antarctica/Syowa'},
    { label: 'Antarctica/Troll'},
    { label: 'Antarctica/Vostok'},
    { label: 'Arctic/Longyearbyen'},
    { label: 'Asia/Aden'},
    { label: 'Asia/Almaty'},
    { label: 'Asia/Amman'},
    { label: 'Asia/Anadyr'},
    { label: 'Asia/Aqtau'},
    { label: 'Asia/Aqtobe'},
    { label: 'Asia/Ashgabat'},
    { label: 'Asia/Ashkhabad'},
    { label: 'Asia/Atyrau'},
    { label: 'Asia/Baghdad'},
    { label: 'Asia/Bahrain'},
    { label: 'Asia/Baku'},
    { label: 'Asia/Bangkok'},
    { label: 'Asia/Barnaul'},
    { label: 'Asia/Beirut'},
    { label: 'Asia/Bishkek'},
    { label: 'Asia/Brunei'},
    { label: 'Asia/Calcutta'},
    { label: 'Asia/Chita'},
    { label: 'Asia/Choibalsan'},
    { label: 'Asia/Chongqing'},
    { label: 'Asia/Chungking'},
    { label: 'Asia/Colombo'},
    { label: 'Asia/Dacca'},
    { label: 'Asia/Damascus'},
    { label: 'Asia/Dhaka'},
    { label: 'Asia/Dili'},
    { label: 'Asia/Dubai'},
    { label: 'Asia/Dushanbe'},
    { label: 'Asia/Famagusta'},
    { label: 'Asia/Gaza'},
    { label: 'Asia/Harbin'},
    { label: 'Asia/Hebron'},
    { label: 'Asia/Ho_Chi_Minh'},
    { label: 'Asia/Hong_Kong'},
    { label: 'Asia/Hovd'},
    { label: 'Asia/Irkutsk'},
    { label: 'Asia/Istanbul'},
    { label: 'Asia/Jakarta'},
    { label: 'Asia/Jayapura'},
    { label: 'Asia/Jerusalem'},
    { label: 'Asia/Kabul'},
    { label: 'Asia/Kamchatka'},
    { label: 'Asia/Karachi'},
    { label: 'Asia/Kashgar'},
    { label: 'Asia/Kathmandu'},
    { label: 'Asia/Katmandu'},
    { label: 'Asia/Khandyga'},
    { label: 'Asia/Kolkata'},
    { label: 'Asia/Krasnoyarsk'},
    { label: 'Asia/Kuala_Lumpur'},
    { label: 'Asia/Kuching'},
    { label: 'Asia/Kuwait'},
    { label: 'Asia/Macao'},
    { label: 'Asia/Macau'},
    { label: 'Asia/Magadan'},
    { label: 'Asia/Makassar'},
    { label: 'Asia/Manila'},
    { label: 'Asia/Muscat'},
    { label: 'Asia/Nicosia'},
    { label: 'Asia/Novokuznetsk'},
    { label: 'Asia/Novosibirsk'},
    { label: 'Asia/Omsk'},
    { label: 'Asia/Oral'},
    { label: 'Asia/Phnom_Penh'},
    { label: 'Asia/Pontianak'},
    { label: 'Asia/Pyongyang'},
    { label: 'Asia/Qatar'},
    { label: 'Asia/Qyzylorda'},
    { label: 'Asia/Rangoon'},
    { label: 'Asia/Riyadh'},
    { label: 'Asia/Saigon'},
    { label: 'Asia/Sakhalin'},
    { label: 'Asia/Samarkand'},
    { label: 'Asia/Seoul'},
    { label: 'Asia/Shanghai'},
    { label: 'Asia/Singapore'},
    { label: 'Asia/Srednekolymsk'},
    { label: 'Asia/Taipei'},
    { label: 'Asia/Tashkent'},
    { label: 'Asia/Tbilisi'},
    { label: 'Asia/Tehran'},
    { label: 'Asia/Tel_Aviv'},
    { label: 'Asia/Thimbu'},
    { label: 'Asia/Thimphu'},
    { label: 'Asia/Tokyo'},
    { label: 'Asia/Tomsk'},
    { label: 'Asia/Ujung_Pandang'},
    { label: 'Asia/Ulaanbaatar'},
    { label: 'Asia/Ulan_Bator'},
    { label: 'Asia/Urumqi'},
    { label: 'Asia/Ust-Nera'},
    { label: 'Asia/Vientiane'},
    { label: 'Asia/Vladivostok'},
    { label: 'Asia/Yakutsk'},
    { label: 'Asia/Yangon'},
    { label: 'Asia/Yekaterinburg'},
    { label: 'Asia/Yerevan'},
    { label: 'Atlantic/Azores'},
    { label: 'Atlantic/Bermuda'},
    { label: 'Atlantic/Canary'},
    { label: 'Atlantic/Cape_Verde'},
    { label: 'Atlantic/Faeroe'},
    { label: 'Atlantic/Faroe'},
    { label: 'Atlantic/Jan_Mayen'},
    { label: 'Atlantic/Madeira'},
    { label: 'Atlantic/Reykjavik'},
    { label: 'Atlantic/South_Georgia'},
    { label: 'Atlantic/St_Helena'},
    { label: 'Atlantic/Stanley'},
    { label: 'Australia/ACT'},
    { label: 'Australia/Adelaide'},
    { label: 'Australia/Brisbane'},
    { label: 'Australia/Broken_Hill'},
    { label: 'Australia/Canberra'},
    { label: 'Australia/Currie'},
    { label: 'Australia/Darwin'},
    { label: 'Australia/Eucla'},
    { label: 'Australia/Hobart'},
    { label: 'Australia/LHI'},
    { label: 'Australia/Lindeman'},
    { label: 'Australia/Lord_Howe'},
    { label: 'Australia/Melbourne'},
    { label: 'Australia/NSW'},
    { label: 'Australia/North'},
    { label: 'Australia/Perth'},
    { label: 'Australia/Queensland'},
    { label: 'Australia/South'},
    { label: 'Australia/Sydney'},
    { label: 'Australia/Tasmania'},
    { label: 'Australia/Victoria'},
    { label: 'Australia/West'},
    { label: 'Australia/Yancowinna'},
    { label: 'Brazil/Acre'},
    { label: 'Brazil/DeNoronha'},
    { label: 'Brazil/East'},
    { label: 'Brazil/West'},
    { label: 'CET'},
    { label: 'CST6CDT'},
    { label: 'Canada/Atlantic'},
    { label: 'Canada/Central'},
    { label: 'Canada/Eastern'},
    { label: 'Canada/Mountain'},
    { label: 'Canada/Newfoundland'},
    { label: 'Canada/Pacific'},
    { label: 'Canada/Saskatchewan'},
    { label: 'Canada/Yukon'},
    { label: 'Chile/Continental'},
    { label: 'Chile/EasterIsland'},
    { label: 'Cuba'},
    { label: 'EET'},
    { label: 'EST'},
    { label: 'EST5EDT'},
    { label: 'Egypt'},
    { label: 'Eire'},
    { label: 'Etc/GMT'},
    { label: 'Etc/GMT+0'},
    { label: 'Etc/GMT+1'},
    { label: 'Etc/GMT+10'},
    { label: 'Etc/GMT+11'},
    { label: 'Etc/GMT+12'},
    { label: 'Etc/GMT+2'},
    { label: 'Etc/GMT+3'},
    { label: 'Etc/GMT+4'},
    { label: 'Etc/GMT+5'},
    { label: 'Etc/GMT+6'},
    { label: 'Etc/GMT+7'},
    { label: 'Etc/GMT+8'},
    { label: 'Etc/GMT+9'},
    { label: 'Etc/GMT-0'},
    { label: 'Etc/GMT-1'},
    { label: 'Etc/GMT-10'},
    { label: 'Etc/GMT-11'},
    { label: 'Etc/GMT-12'},
    { label: 'Etc/GMT-13'},
    { label: 'Etc/GMT-14'},
    { label: 'Etc/GMT-2'},
    { label: 'Etc/GMT-3'},
    { label: 'Etc/GMT-4'},
    { label: 'Etc/GMT-5'},
    { label: 'Etc/GMT-6'},
    { label: 'Etc/GMT-7'},
    { label: 'Etc/GMT-8'},
    { label: 'Etc/GMT-9'},
    { label: 'Etc/GMT0'},
    { label: 'Etc/Greenwich'},
    { label: 'Etc/UCT'},
    { label: 'Etc/UTC'},
    { label: 'Etc/Universal'},
    { label: 'Etc/Zulu'},
    { label: 'Europe/Amsterdam'},
    { label: 'Europe/Andorra'},
    { label: 'Europe/Astrakhan'},
    { label: 'Europe/Athens'},
    { label: 'Europe/Belfast'},
    { label: 'Europe/Belgrade'},
    { label: 'Europe/Berlin'},
    { label: 'Europe/Bratislava'},
    { label: 'Europe/Brussels'},
    { label: 'Europe/Bucharest'},
    { label: 'Europe/Budapest'},
    { label: 'Europe/Busingen'},
    { label: 'Europe/Chisinau'},
    { label: 'Europe/Copenhagen'},
    { label: 'Europe/Dublin'},
    { label: 'Europe/Gibraltar'},
    { label: 'Europe/Guernsey'},
    { label: 'Europe/Helsinki'},
    { label: 'Europe/Isle_of_Man'},
    { label: 'Europe/Istanbul'},
    { label: 'Europe/Jersey'},
    { label: 'Europe/Kaliningrad'},
    { label: 'Europe/Kiev'},
    { label: 'Europe/Kirov'},
    { label: 'Europe/Lisbon'},
    { label: 'Europe/Ljubljana'},
    { label: 'Europe/London'},
    { label: 'Europe/Luxembourg'},
    { label: 'Europe/Madrid'},
    { label: 'Europe/Malta'},
    { label: 'Europe/Mariehamn'},
    { label: 'Europe/Minsk'},
    { label: 'Europe/Monaco'},
    { label: 'Europe/Moscow'},
    { label: 'Europe/Nicosia'},
    { label: 'Europe/Oslo'},
    { label: 'Europe/Paris'},
    { label: 'Europe/Podgorica'},
    { label: 'Europe/Prague'},
    { label: 'Europe/Riga'},
    { label: 'Europe/Rome'},
    { label: 'Europe/Samara'},
    { label: 'Europe/San_Marino'},
    { label: 'Europe/Sarajevo'},
    { label: 'Europe/Saratov'},
    { label: 'Europe/Simferopol'},
    { label: 'Europe/Skopje'},
    { label: 'Europe/Sofia'},
    { label: 'Europe/Stockholm'},
    { label: 'Europe/Tallinn'},
    { label: 'Europe/Tirane'},
    { label: 'Europe/Tiraspol'},
    { label: 'Europe/Ulyanovsk'},
    { label: 'Europe/Uzhgorod'},
    { label: 'Europe/Vaduz'},
    { label: 'Europe/Vatican'},
    { label: 'Europe/Vienna'},
    { label: 'Europe/Vilnius'},
    { label: 'Europe/Volgograd'},
    { label: 'Europe/Warsaw'},
    { label: 'Europe/Zagreb'},
    { label: 'Europe/Zaporozhye'},
    { label: 'Europe/Zurich'},
    { label: 'GB'},
    { label: 'GB-Eire'},
    { label: 'GMT'},
    { label: 'GMT+0'},
    { label: 'GMT-0'},
    { label: 'GMT0'},
    { label: 'Greenwich'},
    { label: 'HST'},
    { label: 'Hongkong'},
    { label: 'Iceland'},
    { label: 'Indian/Antananarivo'},
    { label: 'Indian/Chagos'},
    { label: 'Indian/Christmas'},
    { label: 'Indian/Cocos'},
    { label: 'Indian/Comoro'},
    { label: 'Indian/Kerguelen'},
    { label: 'Indian/Mahe'},
    { label: 'Indian/Maldives'},
    { label: 'Indian/Mauritius'},
    { label: 'Indian/Mayotte'},
    { label: 'Indian/Reunion'},
    { label: 'Iran'},
    { label: 'Israel'},
    { label: 'Jamaica'},
    { label: 'Japan'},
    { label: 'Kwajalein'},
    { label: 'Libya'},
    { label: 'MET'},
    { label: 'MST'},
    { label: 'MST7MDT'},
    { label: 'Mexico/BajaNorte'},
    { label: 'Mexico/BajaSur'},
    { label: 'Mexico/General'},
    { label: 'NZ'},
    { label: 'NZ-CHAT'},
    { label: 'Navajo'},
    { label: 'PRC'},
    { label: 'PST8PDT'},
    { label: 'Pacific/Apia'},
    { label: 'Pacific/Auckland'},
    { label: 'Pacific/Bougainville'},
    { label: 'Pacific/Chatham'},
    { label: 'Pacific/Chuuk'},
    { label: 'Pacific/Easter'},
    { label: 'Pacific/Efate'},
    { label: 'Pacific/Enderbury'},
    { label: 'Pacific/Fakaofo'},
    { label: 'Pacific/Fiji'},
    { label: 'Pacific/Funafuti'},
    { label: 'Pacific/Galapagos'},
    { label: 'Pacific/Gambier'},
    { label: 'Pacific/Guadalcanal'},
    { label: 'Pacific/Guam'},
    { label: 'Pacific/Honolulu'},
    { label: 'Pacific/Johnston'},
    { label: 'Pacific/Kiritimati'},
    { label: 'Pacific/Kosrae'},
    { label: 'Pacific/Kwajalein'},
    { label: 'Pacific/Majuro'},
    { label: 'Pacific/Marquesas'},
    { label: 'Pacific/Midway'},
    { label: 'Pacific/Nauru'},
    { label: 'Pacific/Niue'},
    { label: 'Pacific/Norfolk'},
    { label: 'Pacific/Noumea'},
    { label: 'Pacific/Pago_Pago'},
    { label: 'Pacific/Palau'},
    { label: 'Pacific/Pitcairn'},
    { label: 'Pacific/Pohnpei'},
    { label: 'Pacific/Ponape'},
    { label: 'Pacific/Port_Moresby'},
    { label: 'Pacific/Rarotonga'},
    { label: 'Pacific/Saipan'},
    { label: 'Pacific/Samoa'},
    { label: 'Pacific/Tahiti'},
    { label: 'Pacific/Tarawa'},
    { label: 'Pacific/Tongatapu'},
    { label: 'Pacific/Truk'},
    { label: 'Pacific/Wake'},
    { label: 'Pacific/Wallis'},
    { label: 'Pacific/Yap'},
    { label: 'Poland'},
    { label: 'Portugal'},
    { label: 'ROC'},
    { label: 'ROK'},
    { label: 'Singapore'},
    { label: 'Turkey'},
    { label: 'UCT'},
    { label: 'US/Alaska'},
    { label: 'US/Aleutian'},
    { label: 'US/Arizona'},
    { label: 'US/Central'},
    { label: 'US/East-Indiana'},
    { label: 'US/Eastern'},
    { label: 'US/Hawaii'},
    { label: 'US/Indiana-Starke'},
    { label: 'US/Michigan'},
    { label: 'US/Mountain'},
    { label: 'US/Pacific'},
    { label: 'US/Pacific-New'},
    { label: 'US/Samoa'},
    { label: 'UTC'},
    { label: 'Universal'},
    { label: 'W-SU'},
    { label: 'WET'},
    { label: 'Zulu'}
  ];

  const onSubmit = () => {
    if ( timezoneSelect !== "") {
        let data={"time_zone":timezoneSelect}
        dispatch(storeTimezoneData(data));
    }
  };
  useEffect(() => {
    dispatch(fetchTimezoneData());
}, [dispatch]);

useEffect(() => {
    if(timezoneData && timezoneData.data && timezoneData.data.time_zone){
        setTimezoneSelect(timezoneData && timezoneData.data && timezoneData.data.time_zone)
    }else{
      setTimezoneSelect("System Default")
    }
}, [timezoneData]);
  return (
    <div className="sc--settings--card">
        {(timezoneData && timezoneData.notification && timezoneData.notification.messageContent!='') &&
            <Notification
                is_open={timezoneData.notification.show}
                messageType={timezoneData.notification.messageType}
                messages={timezoneData.notification.messageContent}
                handleClose={() => dispatch(resetTimezoneState())}
            />}
      <Card
        sx={{ minWidth: '100%', boxShadow: "unset" }}
        className="sc--cardhead--details"
      >
        <CardHeader title="Timezone Settings" className="sc--cardhead--details" />
        <Divider />
          <CardContent className="sc--label--settings">
            <div style={{width:'500px',margin:'10px auto'}}>
                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={timezones}
                    sx={{ width: 300 }}
                    value={timezoneSelect}
                    onChange={(event, value) => {
                        setTimezoneSelect(value.label)
                      }}
                    renderInput={(params) => <TextField {...params} label="Select Timezone" />}
                    />
            </div>
            <div className="sc--form--center">
              <LoadingButton
                variant="outlined"
                onClick={onSubmit}
                style={{ margin: "15px 15px" }}
                disabled={timezoneSelect==='' ? true :false}
                loading={timezoneData && timezoneData.loading}
              >
                Submit
              </LoadingButton>
            </div>
          </CardContent>
      </Card>
    </div>
  );
};

export default TimezoneSettingsPage;
