import { createSlice } from '@reduxjs/toolkit';
import { LABELGENAPI } from '../../../API';

export const paymentslice = createSlice({
    name: 'payment',
    initialState: {
        addPayment: {
            data: [],
            loading: false,
            hasErrors: false,
            notification: { show: false, messageContent: "", messageType: "success" }
        },
        paymentIntent: {
            data: {},
            loading: false,
            hasErrors: false,
            notification: { show: false, messageContent: "", messageType: "success" }
        },
        removePayment: {
            data: [],
            loading: false,
            hasErrors: false,
            notification: { show: false, messageContent: "", messageType: "success" }
        },
        getActivePayment: {
            data: [],
            loading: false,
            hasErrors: false,
            notification: { show: false, messageContent: "", messageType: "success" }
        },
    },
    reducers: {

        getSubscriptionData: (state) => {
            return {
                ...state,
                getActivePayment: {
                    ...state.getActivePayment,
                    loading: true,
                    hasErrors: false,
                }
            }
        },
        getSubscriptionDataSuccess: (state, action) => {
            return {
                ...state,
                getActivePayment: {
                    ...state.getActivePayment,
                    data: action && action.payload && action.payload.data && action.payload.data.data,
                    hasErrors: false,
                    loading: false,
                    notification: { show: true, messageContent: [action && action.payload && action.payload.data && action.payload.data.data.message], messageType: "success" }
                }
            }
        },
        getSubscriptionDataFailure: (state, action) => {
            return {
                ...state,
                getActivePayment: {
                    ...state.getActivePayment,
                    loading: false,
                    hasErrors: true,
                    notification: { show: true, messageContent: [action && action.payload && action.payload.data && action.payload.data.data.message], messageType: "error" }
                }
            }
        },
        getPaymentIntent: (state) => {
            return {
                ...state,
                paymentIntent: {
                    ...state.paymentIntent,
                    loading: true,
                    hasErrors: false
                }
            }
        },
        getPaymentIntentSuccess: (state, action) => {
            return {
                ...state,
                paymentIntent: {
                    ...state.paymentIntent,
                    data: action && action.payload && action.payload.data && action.payload.data.data,
                    loading: false,
                    hasErrors: false,
                    notification: { show: true, messageContent: [action && action.payload && action.payload.data && action.payload.data.data.message], messageType: "success" }
                }
            }
        },
        getPaymentIntentFailure: (state, action) => {
            return {
                ...state,
                paymentIntent: {
                    ...state.paymentIntent,
                    loading: false,
                    hasErrors: true,
                    notification: { show: true, messageContent: [action && action.payload && action.payload.data && action.payload.data.data.message], messageType: "error" }
                }
            }
        },
        addPaymentData: (state) => {
            return {
                ...state,
                addPayment: {
                    ...state.addPayment,
                    loading: true,
                    hasErrors: false
                }
            }
        },
        addPaymentDataSuccess: (state, action) => {
            return {
                ...state,
                addPayment: {
                    ...state.addPayment,
                    data: action && action.payload && action.payload.data && action.payload.data.data,
                    loading: false,
                    hasErrors: false,
                    notification: { show: true, messageContent: [action && action.payload && action.payload.data && action.payload.data.data.message], messageType: "success" }
                }
            }
        },
        addPaymentDataFailure: (state, action) => {
            return {
                ...state,
                addPayment: {
                    ...state.addPayment,
                    loading: false,
                    hasErrors: true,
                    notification: { show: true, messageContent: [action && action.payload && action.payload.data && action.payload.data.data.message], messageType: "error" }
                }
            }
        },
        removePaymentData: (state) => {
            return {
                ...state,
                removePayment: {
                    ...state.removePayment,
                    loading: true,
                    hasErrors: false
                }
            }
        },
        removePaymentDataSuccess: (state, action) => {
            return {
                ...state,
                removePayment: {
                    ...state.removePayment,
                    data:[],
                    hasErrors: false,
                    loading: false,
                    notification: { show: true, messageContent: [action && action.payload && action.payload.data && action.payload.data.data.message], messageType: "success" }
                }
            }
        },
        removePaymentDataFailure: (state, action) => {
            return {
                ...state,
                removePayment: {
                    ...state.removePayment,
                    loading: false,
                    hasErrors: true,
                    notification: { show: true, messageContent: [action && action.payload && action.payload.data && action.payload.data.data.message], messageType: "error" }
                }
            }
        },
        resetPaymentNotification: (state, action) => {
            return {
                ...state,
                paymentIntent: {
                    ...state.paymentIntent,
                    data: {},
                    loading: false,
                    hasErrors: false,
                    notification: { show: false, messageContent: "", messageType: "success" }
                },
                addPayment: {
                    ...state.addPayment,
                    loading: false,
                    hasErrors: false,
                    notification: { show: false, messageContent: "", messageType: "success" }
                },
                removePayment: {
                    ...state.removePayment,
                    loading: false,
                    hasErrors: false,
                    notification: { show: false, messageContent: "", messageType: "success" }
                },
                getActivePayment: {
                    ...state.getActivePayment,
                    loading: false,
                    hasErrors: false,
                    notification: { show: false, messageContent: "", messageType: "success" }
                },
            }
        },
    }
})
export const { getPaymentIntent, getPaymentIntentSuccess, getPaymentIntentFailure, getSubscriptionData, getSubscriptionDataSuccess, getSubscriptionDataFailure,
    addPaymentData, addPaymentDataSuccess, addPaymentDataFailure,
    removePaymentData, removePaymentDataSuccess, removePaymentDataFailure, resetPaymentNotification } = paymentslice.actions;

export const getPaymentIntentData = data => dispatch => {
    dispatch(getPaymentIntent());
    try {
        LABELGENAPI.get('/payment/intent')
            .then(function (response) {
                dispatch(getPaymentIntentSuccess(response));
            }).catch(function (error) {
                dispatch(getPaymentIntentFailure(error.response));
            })
    }
    catch (error) {
        dispatch(getPaymentIntentFailure(error.response));
    }
}

export const addPayment = data => dispatch => {
    dispatch(addPaymentData());
    try {
        LABELGENAPI.post('/payment/add', data)
            .then(function (response) {
                dispatch(addPaymentDataSuccess(response));
                dispatch(getSubscription())
            }).catch(function (error) {
                dispatch(addPaymentDataFailure(error.response));
            })
    }
    catch (error) {
        dispatch(addPaymentDataFailure(error.response));
    }
}

export const removePayment = data => dispatch => {
    dispatch(removePaymentData());
    try {
        LABELGENAPI.get('/payment/delete')
            .then(function (response) {
                dispatch(removePaymentDataSuccess(response));
                dispatch(getSubscription())
            }).catch(function (error) {
                dispatch(removePaymentDataFailure(error.response));
            })
    }
    catch (error) {
        dispatch(removePaymentDataFailure(error.response));
    }
}

export const getSubscription = (data) => dispatch => {
    dispatch(resetPaymentNotifications())
    dispatch(getSubscriptionData());
    try {
        LABELGENAPI.get('/payment/active?checkout=' + data)
            .then(function (response) {
                dispatch(getSubscriptionDataSuccess(response));
            }).catch(function (error) {
                dispatch(getSubscriptionDataFailure(error.response));
            })
    }
    catch (error) {
        dispatch(getSubscriptionDataFailure(error.response));
    }
}

export const resetPaymentNotifications = () => {
    return (dispatch) => {
        dispatch(resetPaymentNotification());
    }
}

export default paymentslice.reducer;