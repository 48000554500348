import { createSlice } from '@reduxjs/toolkit';
import { LABELGENAPI, LABELINGCHIMP_SAM_API } from '../../../API';
import axios from 'axios';

/* canceling previous requests if multiple requests is triggered */
const CancelToken = axios.CancelToken;
let source = CancelToken.source();

export const manifestsslice = createSlice({
    name: 'manifests',
    initialState: {
        data: [],
        message: [],
        meta: {
            shippingchimp_manifest_number: "",
            manifestStatus: "",
            date: "",
            manifestNumber: "",
            sorting: JSON.stringify([{
                field: 'created_at',
                sort: 'desc',
              }]),
            page: 1,
            per_page: 10
        },
        loading: false,
        notification: { show: false, messageContent: "", messageType: "success" },
        manifestClose: {
            loading: false,
            notification: { show: false, messageContent: "", messageType: "success" }
        }
    },
    reducers: {
        getManifest: (state) => {
            return {
                ...state,
                data: [],
                loading: true
            }
        },
        getManifestSuccess: (state, action) => {
            return {
                ...state,
                data: action && action.payload && action.payload.data && action.payload.data.data,
                meta: action && action.payload && action.payload.data && action.payload.data.meta,
                un_closed_shipments_count: action && action.payload && action.payload.data && action.payload.data.un_closed_shipments_count,
                manifest_all_in_progress: action && action.payload && action.payload.data && action.payload.data.manifest_all_in_progress,
                loading: false
            }
        },
        getManifestFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                notification: { show: true, messageContent: action.payload && action.payload.data && action.payload.data.message && [action.payload.data.message], messageType: "error" }
            }
        },
        postTrackingNumberCloseManifest: (state, action) => {
            return {
                ...state,
                manifestClose: {
                    ...state.manifestClose,
                    loading: true
                }
            }
        },
        postTrackingNumberCloseManifestSuccess: (state, action) => {
            return {
                ...state,
                manifestClose: {
                    ...state.manifestClose,
                    loading: false,
                    notification: { show: true, messageContent: (action.payload && action.payload.data && action.payload.data.desc && action.payload.data.desc === "success") ? ["Manifest close triggered successfully"] : [""], messageType: "success" }
                }
            }
        },
        postTrackingNumberCloseManifestFailure: (state, action) => {
            return {
                ...state,
                manifestClose: {
                    ...state.manifestClose,
                    loading: false,
                    notification: { show: true, messageContent: action.payload && action.payload.data && action.payload.data.message, messageType: "error" }
                }
            }
        },
        resetNotification: (state, action) => {
            return {
                ...state,
                notification: { show: false, messageContent: "", messageType: "success" },
                manifestClose: {
                    ...state.manifestClose,
                    notification: { show: false, messageContent: "", messageType: "success" }

            }
        }
        }
    }
})

export const { getManifest, getManifestSuccess, getManifestFailure } = manifestsslice.actions;

export const fetchManifest = (meta) => {

    return (dispatch) => {
        const qs = Object.keys(meta)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(meta[key])}`)
            .join('&');

        dispatch(getManifest());
        try {
            LABELGENAPI
                .get('/manifests?status=CLOSED&' + qs)
                .then(function (response) {
                    dispatch(getManifestSuccess(response));
                })
                .catch(function (error) {
                    dispatch(getManifestFailure(error.response));
                });
        } catch (error) {
            dispatch(getManifestFailure());
        }
    }
};

export const { postTrackingNumberCloseManifest, postTrackingNumberCloseManifestSuccess, postTrackingNumberCloseManifestFailure } = manifestsslice.actions;

export const dopostTrackingNumberCloseManifest = (data, api_token, meta) => {
    return (dispatch) => {
        dispatch(postTrackingNumberCloseManifest());
        try {
            LABELINGCHIMP_SAM_API
                .post('/close-manifest', data, { headers: { 'x-api-key': api_token, 'Content-Type': 'application/json' } })
                .then(function (response) {
                    dispatch(postTrackingNumberCloseManifestSuccess(response));
                    try {
                        if (response.data.desc === "success") {
                            dispatch(fetchManifest(meta));
                        }
                    } catch (error) {
                        dispatch(postTrackingNumberCloseManifestFailure(error.response));
                    }
                })
                .catch(function (error) {
                    dispatch(postTrackingNumberCloseManifestFailure(error.response));
                });
        } catch (error) {
            dispatch(postTrackingNumberCloseManifestFailure());
        }
    };
};

export const { resetNotification } = manifestsslice.actions;

export const resetGenerateManifestNotification = () => {
    return (dispatch) => {
        dispatch(resetNotification());
    }
}

export default manifestsslice.reducer;
