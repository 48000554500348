import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";

import Typography from "@mui/material/Typography";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import InputAdornment from "@mui/material/InputAdornment";
import { IconButton } from "@mui/material";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { doRegister } from "./authSlice";

const RegisterPage = ({}) => {
  let params = new URLSearchParams(window.location.search);
  const auth = useSelector((state) => state.auth);
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [alert, setAlert] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () =>
    setConfirmPassword(!showConfirmPassword);
  const handleMouseDownConfirmPassword = () =>
    setConfirmPassword(!showConfirmPassword);

  const [newphone, setNewPhone] = useState("");
  const [phoneError, setPhoneError] = useState(null);
  const [phonevisible, SetPhoneVisible] = useState(false);
  const validateerror = document.getElementsByClassName("validateErrors");
  if (validateerror) {
    setTimeout(function () {
      SetPhoneVisible(true);
    }, 5000);
  }
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const initialValues = {
    plan: "ACTIVE",
    source: "LABELGEN",
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
    coupon: "",
  };

  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});

  const validator = (fieldValues) => {
    let temp = { ...errors };
    if ("name" in fieldValues)
      temp.name = fieldValues.name ? "" : "This field is required.";
    if ("email" in fieldValues)
      temp.email = fieldValues.email ? "" : "This field is required.";
    if (
      "email" in fieldValues &&
      !/^([\+\w.-]+)@(\[(\d{1,3}\.){3}|(?!hotmail|gmail|yahoo|proton)(([a-zA-Z\d-]+\.)+))([a-zA-Z]{2,4}|\d{1,3})(\]?)$/.test(
        fieldValues && fieldValues.email
      )
    ) {
      temp.email = "Enter valid business email";
    }
    if ("password" in fieldValues)
      temp.password = fieldValues.password ? "" : "This field is required.";
    if (
      (fieldValues &&
        fieldValues.password &&
        fieldValues.password.length < 8) ||
      fieldValues.password === null
    )
      temp.password = "Password required minimum 8 characters";
    if ("password_confirmation" in fieldValues)
      temp.password_confirmation = fieldValues.password_confirmation
        ? ""
        : "This field is required.";

    if (fieldValues && fieldValues.coupon && fieldValues.coupon.length < 5)
      temp.coupon = "coupon code required minimum 5 characters";
    else {
      temp.coupon = "";
    }
    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    validator({ [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = validator(values);
    console.log(newphone.length)
    if (isValid && newphone != "" && newphone.length >= 10) {
      values["phone"] = newphone;
      setPhoneError(null);
      dispatch(doRegister(values));
      setOpen(true);
    } else {
      if (newphone == "" && newphone.length ==0) {
        setPhoneError("This field is required.");
        SetPhoneVisible(false);
      }else if(newphone.length < 11){
        setPhoneError("Phone number must be at least 10 numbers");
        SetPhoneVisible(false);
      }
      setOpen(true);
    }
  };
  const snackhandleClose = () => {
    setAlert(false);
    setSnackOpen(false);
  };

  const handleOnChange = (value) => {
    setNewPhone(value);
    setPhoneError(null);
  };

  const handleOnChangephone = (e) => {
    setNewPhone(e.target.value);
  };
  useEffect(() => {
    if (auth && auth.notification && auth.notification.messageContent) {
      setAlert(true);
      setSnackOpen(true);
    }
  }, [auth]);

  return (
    <>
      <div>
        <Grid container spacing={2} className="height__center__align">
          <Grid
            item
            xs={12}
            md={3}
            className="testimonial__section trial__custom__col__4"
          >
            <div>
              <img
                src="/assets/images/register-img.png"
                alt="testimonial"
                className="registerimg"
              />
              {/* <h5 className='white__font testi__head'>Joshua</h5>
						<p className='white__font testi__paragraph'>CEO, Activewear</p> */}
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                className="white__font testi__font"
              >
                ShippingChimp is an excellent service for delivering packages. Shippingchimp beats all the major larger shipping services out there. Their app works great with our Shopify store and everything integrates easily.
              </Typography>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={9}
            className="trial__custom__col__8"
            style={{ paddingLeft: "unset", paddingTop: "unset" }}
          >
            <div style={{ height: "100%" }}>
              {alert && (
                <Snackbar
                  open={snackOpen}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  autoHideDuration={6000}
                  onClose={snackhandleClose}
                >
                  <Alert
                    severity={
                      auth &&
                      auth.notification &&
                      auth.notification.messageType === "error"
                        ? "error"
                        : "success"
                    }
                    style={{ justifyContent: "center", margin: "10px 0" }}
                  >
                    <span>{auth && auth.notification.messageContent}</span>
                  </Alert>
                </Snackbar>
              )}
              <Paper
                elevation={3}
                sx={{
                  paddingLeft: "32px",
                  paddingRight: "32px",
                  height: "100%",
                  boxShadow: "unset",
                }}
                square={false}
              >
                <div className="registerlayout--image">
                  <a href="/login">
                    <img
                      className="trial__form__logo"
                      src="/assets/images/shippingchimp_logo.png"
                      alt="logo-dark"
                    />
                  </a>
                </div>
                <div>
                  <Stack
                    component="form"
                    className="trial__form"
                    sx={{
                      "& > :not(style)": { m: 1 },
                      width: "400px",
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    {/* <div className='form-title'>Let's set up your account</div> */}
                    <div style={{ padding: "10px 0px" }}>
                      <Typography
                        variant="h6"
                        component="div"
                        className="black__font form__title"
                        style={{
                          fontWeight: 500,
                          textAlign: "center",
                          fontSize: "1.25rem",
                        }}
                      >
                        Let's set up your account
                      </Typography>
                    </div>
                    <Typography
                      variant="p"
                      component="p"
                      className="black__font"
                    >
                      Name
                    </Typography>
                    <TextField
                      variant="outlined"
                      required
                      size="small"
                      name="name"
                      placeholder="John Doe"
                      value={values.name}
                      onChange={onInputChange}
                      error={errors.name}
                      helperText={errors.name}
                    />
                    <Typography
                      variant="p"
                      component="p"
                      className="black__font"
                    >
                      Work Email
                    </Typography>

                    <TextField
                      required
                      type="email"
                      variant="outlined"
                      size="small"
                      name="email"
                      placeholder="name@yourcompany.com"
                      value={values.email}
                      onChange={onInputChange}
                      error={errors.email}
                      helperText={errors.email}
                    />
                    <Typography
                      variant="p"
                      component="p"
                      className="black__font"
                    >
                      Phone
                    </Typography>
                    <PhoneInput
                      name="phone"
                      autoCorrect="off"
                      placeholder="Enter a Valid Phone Number"
                      country={"ca"}
                      onlyCountries={["ca", "us", "gb", "in"]}
                      value={newphone}
                      onChange={handleOnChange}
                    />
                    <input
                      style={{ display: "none" }}
                      placeholder="Enter a Valid Phone Number"
                      autoCorrect="off"
                      id="multipleErrorInput4"
                      name="phone"
                      value={newphone}
                      onChange={handleOnChangephone}
                    />
                    {!phonevisible && (
                      <>
                        {" "}
                        {phoneError && (
                          <p
                            className="validateErrors"
                            style={{ color: "#d32f2f", fontSize: "12px" }}
                          >
                            {phoneError}
                          </p>
                        )}
                      </>
                    )}
                    <Typography
                      variant="p"
                      component="p"
                      className="black__font"
                    >
                      Set password
                    </Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      name="password"
                      value={values.password}
                      placeholder="Password"
                      required
                      type={showPassword ? "text" : "password"}
                      onChange={onInputChange}
                      error={errors.password}
                      helperText={errors.password}
                      InputProps={{
                        // <-- This is where the toggle button is added.
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Typography
                      variant="p"
                      component="p"
                      className="black__font"
                    >
                      Confirm password
                    </Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      name="password_confirmation"
                      placeholder="Confirm password"
                      value={values.password_confirmation}
                      required
                      type={showConfirmPassword ? "text" : "password"}
                      onChange={onInputChange}
                      error={errors.password_confirmation}
                      helperText={errors.password_confirmation}
                      InputProps={{
                        // <-- This is where the toggle button is added.
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowConfirmPassword}
                              onMouseDown={handleMouseDownConfirmPassword}
                            >
                              {showConfirmPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Typography
                      variant="p"
                      component="p"
                      className="black__font"
                    >
                      Coupon code
                    </Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      name="coupon"
                      placeholder="Coupon code"
                      value={values.coupon}
                      onChange={onInputChange}
                      error={errors.coupon}
                      helperText={errors.coupon}
                    />

                    <FormGroup className="trial__page__checkbox">
                      <FormControlLabel
                        control={<Checkbox />}
                        label="I want to be included in all the marketing communications"
                      />
                    </FormGroup>

                    <Button
                      variant="contained"
                      className="trial__button"
                      onClick={handleSubmit}
                    >
                      Complete signup
                    </Button>
                    <Typography
                      variant="h6"
                      component="div"
                      className="p__tb-30 black__font"
                      style={{ textAlign: "center", margin: "15px 0" }}
                    >
                      Trusted by popular Canadian brands
                    </Typography>
                    <Grid container spacing={4}>
                      <Grid item xs={6} md={3} className="grid__item__size">
                        <img
                          src="/assets/images/tawse.png"
                          alt="logo-dark"
                          className="trial__form__logos"
                        />
                      </Grid>
                      <Grid item xs={6} md={3} className="grid__item__size">
                        <img
                          src="/assets/images/sleepcountry.png"
                          alt="logo-dark"
                          className="trial__form__logos"
                        />
                      </Grid>
                      <Grid item xs={6} md={3} className="grid__item__size">
                        <img
                          src="/assets/images/hatley.png"
                          alt="logo-dark"
                          className="trial__form__logos"
                        />
                      </Grid>
                      <Grid item xs={6} md={3} className="grid__item__size">
                        <img
                          src="/assets/images/homedics.png"
                          alt="logo-dark"
                          className="trial__form__logos"
                        />
                      </Grid>
                    </Grid>
                  </Stack>
                </div>
                <div></div>
              </Paper>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default RegisterPage;
