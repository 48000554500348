import React from "react";
import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from "@mui/material";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';

const ShipmentRate = ({ billed_rate = null, api_rate = null, is_multi_piece = null, is_voided = null }) => {
    let multi_piece = (is_multi_piece && is_multi_piece === true) ? true : false
    let voided = (is_voided && is_voided === true) ? true : false
    if (voided && voided == true) {
        return <>-</>
    }
    if (billed_rate && billed_rate !== null && billed_rate !== "undefined") {
        return (
            <span>
                {parseFloat(billed_rate).toFixed(2)}
                {multi_piece && multi_piece === true &&
                    <Tooltip title="Multi piece shipment rate" placement="right-start">
                        <QuizOutlinedIcon color="warning" style={{ position: "relative", left: "5px", top: "5px" }} fontSize="small" />
                    </Tooltip>
                }
            </span>
        )
    } else if (api_rate && api_rate !== null && api_rate !== "undefined") {
        return (
            <span>
                {parseFloat(api_rate).toFixed(2)}
                <Tooltip title="This  is excluding tax and may change if there is any dimensional / weight adjustment" placement="right-start">
                    <InfoIcon style={{ position: "relative", left: "5px", top: "5px" }} fontSize="small" />
                </Tooltip>
                {multi_piece && multi_piece === true &&
                    <Tooltip title="Multi piece shipment rate" placement="right-start">
                        <QuizOutlinedIcon color="warning" style={{ position: "relative", left: "5px", top: "5px" }} fontSize="small" />
                    </Tooltip>
                }
            </span>
        )
    }
    else {
        return <>-</>
    }

}

export default ShipmentRate;
