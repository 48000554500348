import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// Our Components
import TrackingDrawer from '../../../pages/General/TrackingDrawer'
import FeedbackDrawer from '../../../pages/General/FeedbackDrawer'
import Carrier from '../../General/Carrier'
import OrderStatus from '../../General/OrderStatus'
import Customer from '../../../pages/General/Customer'
import Filters from './Filters'
import Search from './Search'
import DynamicColumns from '../../../pages/General/DynamicColumns'
import Notification from '../../../pages/General/Notification'
import NorowsData from '../../../pages/General/NorowsData'
import IntegrationPlatform from '../../../pages/General/IntegrationPlatform'
import DateTime from '../../../pages/General/DateTime'
import Avatar from '../../../pages/General/Avatar'
import Rating from '@mui/material/Rating';


import _ from "lodash";
// mui components
import {
    Typography,
    FormControl,
    Pagination,
    IconButton,
    Tooltip
} from "@mui/material";
import Chip from '@mui/material/Chip';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import {
    DataGrid,
    GridToolbarContainer
} from '@mui/x-data-grid';
import {
    fetchCustomerFeedbackReport, fetchCustomerFeedbackPreview
} from './customerFeedbackSlice';

import SearchIcon from '@mui/icons-material/Search';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ViewColumnSharpIcon from '@mui/icons-material/ViewColumnSharp';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
const CustomerFeedbackDashboardPage = () => {
    const dispatch = useDispatch();
    const report = useSelector((state) => state.customerfeedbackdashboard.report);
    const [meta, setMeta] = useState(report.meta);
    const [showFilter, setShowFilter] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [trackingDrawerVisible, setTrackingDrawerVisible] = useState(false);

    const onMenuClick = (type, show) => {
        setShowSearch((type === "search" && show === true) ? true : false)
        setShowFilter((type === "filter" && show === true) ? true : false)
    }


    const onChangePerPage = (event) => {
        setMeta({
            ...meta,
            ...{
                page: 1,
                per_page: event.target.value
            }
        });
    }

    const onChangePagination = (event, page) => {
        setMeta({
            ...meta,
            ...{
                page: page
            }
        });
    }

    const trackingCallback = (item) => {
        dispatch(fetchCustomerFeedbackPreview(item));
        setTrackingDrawerVisible(true)
    }
    const onClose = (values) => {
        setTrackingDrawerVisible(false);
    };

    const columns = [
        {
            field: 'order_id',
            headerName: 'Order Id',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            renderCell: (params) => {
                return <Link className="sc--link--style" to={"/orders/" + params.row.order_id}>{params.row.order_id}</Link>
            }
        },
        {
            field: 'tracking_number',
            headerName: 'Tracking Number',
            headerClassName: 'super-app-theme--header',
            flex: 1
        },
        {
            field: 'created_at',
            headerName: 'Customer',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            renderCell: (params) => {
                if (!_.isUndefined(params.row.customer)) {
                    let name = ""
                    if (params.row.customer && typeof params.row.customer.first_name !== "undefined" && params.row.customer.first_name !== null) {
                        name = params.row.customer.first_name;
                    }
                    if (params.row.customer && typeof params.row.customer.last_name !== "undefined" && params.row.customer.last_name !== null) {
                        name = name + " " + params.row.customer.last_name
                    }
                    return (
                        <Customer name={name} />
                    )
                }
                else {
                    return "-";
                }
            },
        },
        {
            field: 'rating',
            headerName: 'Rating',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            renderCell: (params) => {
                if ((params.row.rating)) {
                    return <Rating name="size-small" defaultValue={params.row.rating} size="small" readOnly />;
                } else {
                    return "-";
                }
            }
        },
        {
            field: 'updated_at',
            headerName: 'Date',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            renderCell: (params) => {
                if (!_.isUndefined(params.row.updated_at)) {
                    return <DateTime datetime={params.row.updated_at} />;
                } else {
                    return "-";
                }
            }
        },
        {
            field: 'preview',
            headerName: 'Action',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            renderCell: (params) => {
                return (
                    <div>
                        <span style={{ marginRight: '10px' }}>
                            <Tooltip title="Preview" placement="left-start">
                                <IconButton>
                                    <RemoveRedEyeIcon style={{ color: "#1890ff", cursor: 'pointer' }} onClick={e => { trackingCallback(params.row._id) }} />
                                </IconButton>
                            </Tooltip>
                        </span>
                    </div>
                )

            }
        },
    ];



    function CustomTotalRows() {

        return (
            <div className="custom-total-rows">
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    Showing {report && report.data && report.data.length} of {(report && report.meta && report.meta.total) ? report.meta.total : 0}
                </FormControl>
            </div>
        )
    }


    function CustomPagesizeoptions() {

        return (
            <div className="custom-page-size-options">
                <span>
                    Show <select
                        value={meta && meta.per_page}
                        onChange={onChangePerPage}
                    >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </select> entries
                </span>
            </div>
        )
    }

    function CustomToolbar() {

        return (
            <GridToolbarContainer>
                <CustomPagesizeoptions />
                <CustomTotalRows />
            </GridToolbarContainer>
        );
    }

    function CustomPagination() {

        return (
            <Pagination
                color="primary"
                shape="circular"
                page={report && report.meta && report.meta.current_page}
                count={report && report.meta && report.meta.last_page}
                onChange={onChangePagination}
            />
        );
    }

    useEffect(() => {
        dispatch(fetchCustomerFeedbackReport({ ...meta }));
    }, [meta]);

    return (
        <>
            <div className="scmaincard">
                <div className="sccardhead">
                    <Typography variant="h5" gutterBottom component="div">
                        Feedback
                       </Typography>
                    <ul className="features-list" style={{ position: "absolute", float: "right", right: "16px" }}>
                        <li>
                            <Tooltip title="Seacrh" placement="top-start">
                                <IconButton onClick={() => onMenuClick("search", !showSearch)}>
                                    <SearchIcon style={{ color: 'rgb(25, 118, 210)' }} />
                                </IconButton>
                            </Tooltip>
                        </li>
                        <li>
                            <Tooltip title="Filter" placement="top-start">
                                <IconButton onClick={() => onMenuClick("filter", !showFilter)}>
                                    <FilterAltOutlinedIcon style={{ color: 'rgb(25, 118, 210)' }} />
                                </IconButton>
                            </Tooltip>
                        </li>
                    </ul>
                </div>
            </div>
            <div>
                <Search visible={showSearch} meta={meta} setMeta={setMeta} />
                <Filters visible={showFilter} meta={meta} setMeta={setMeta} />
                <div style={{ width: '100%', background: '#fff', marginTop: "20px" }}>
                    <DataGrid
                        style={{ paddingTop: "37px" }}
                        getRowId={(row) => row._id}
                        rows={report && report.data}
                        columns={columns}
                        autoHeight={true}
                        disableColumnFilter={true}
                        disableColumnMenu={true}
                        sortingMode="server"
                        disableRowSelectionOnClick
                        slots={{
                            pagination: CustomPagination,
                            toolbar: CustomToolbar,
                            NorowsData
                        }}
                        loading={
                            (report && report.loading)
                        }
                    />
                </div>

            </div>
            <FeedbackDrawer
                visible={trackingDrawerVisible} onClose={onClose}
            />

        </>
    )
}

export default CustomerFeedbackDashboardPage;
