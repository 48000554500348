import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Box from '@mui/material/Box';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import LinearProgress from '@mui/material/LinearProgress';
import {
    DataGrid,
    GridToolbarContainer
} from '@mui/x-data-grid';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Carrier from '../../pages/General/Carrier'
import Notification from '../../pages/General/Notification'
import NorowsData from '../../pages/General/NorowsData'
import Printer from '../../pages/General/Printer'
import { doGetRate, resetRateState } from '../../pages/Shipments/NewShipment/rateSlice';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { generateLabel, resetGenerateLabel } from '../../pages/Shipments/NewShipment/createShipmentSlice';
import { dopostDataCreateShipment } from '../../pages/Orders/ordersSlice';

const RateDrawer = ({ visible, reportName, formDataValues, onClose, newOrderFormData, meta, isDuplicateOrder,isReturnOrder, lineItemId, customLineItems, setValues,setNewOrderFormData, setCustomLineItems, setRateDrawervisible = () => { }, setNewOrderCreateDrawerVisible = () => { },isFailedShipment=false }) => {
    const dispatch = useDispatch();
    const orders = useSelector(state => state.orders.createShipment);
    const rates = useSelector(state => state.rates);
    const profile = useSelector(state => state.auth);
    const createshipment = useSelector(state => state.createshipment);
    const [rateData, setRateData] = useState([]);
    const [labelShowData, setLabelShowData] = useState(false);
    const [drawer, setDrawer] = useState(true);
    const [snackOpen, setsnackOpen] = useState(false);
    const [printUrl, setPrintUrl] = useState("")
    const [isPrinted, setIsPrinted] = useState(true)
    const [isLabelOpened, setIsLabelOpened] = useState(true)
    const [isFailedLabelPrinted, setIsFailedLabelPrinted] = useState(true)
    const initialFValues = {
        name: '',
        company: '',
        phone: '',
        email: '',
        address: '',
        address2: '',
        city: '',
        postal_code: '',
        country_code: '',
        weight: '',
        box_weight: '',
        actual_weight: '',
        province: '',
        preferred_sender_id: '',
        length: '',
        height: '',
        width: '',
        dimension_unit: 'in',
        weight_unit: 'lb',
        insurance_amount: "",
        choosemeasurement: '',
        preferred_carrier: '',
        preferred_carrier_reference_id: '',
        preferred_service: '',
        sender_reference_no:'',
        return_shipment_sender_id:"",
        shipment_type:"PACKAGE",
        is_signature_required:"",
    }
    const ondrawerclose = () => {
        setDrawer(false)
        onClose(false)
        // dispatch(resetRateState())
        dispatch(resetGenerateLabel())
    }

    const toggleDrawer = (anchor, open) => (event) => {
        let hidedrawer = ''
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
    };

    useEffect(() => {
        if (rates && rates.message && rates.message.length !== 0) {
            setsnackOpen(true)
        }
    }, [rates]);

    const handleClose = () => {
        dispatch(resetRateState())
        setsnackOpen(false)
    };

    const generatelabel = (rate_row,status) => {
        let apikey = (profile.profile.api_token) ? profile.profile.api_token : "";
        const formData = formDataValues;
        let referenceno = ""
        let preferred_carrier = ""
        if(!isDuplicateOrder && newOrderFormData && newOrderFormData.reference && newOrderFormData.reference.no){
            referenceno = newOrderFormData.reference.no
        }
        if (rate_row.carrier_name && typeof rate_row.carrier_name !== "undefined" && rate_row.carrier_name.toUpperCase() == "SHIPPINGCHIMP"){
            preferred_carrier = "SHIPPINGCHIMP"
        }else if(rate_row.original_carrier_name && typeof rate_row.original_carrier_name !== "undefined"){
            preferred_carrier = rate_row.original_carrier_name
        }else{
            preferred_carrier = rate_row.carrier_name
        }

        let order_id_check=""
        let document_reference={}
        if(isReturnOrder){
            order_id_check=(newOrderFormData && newOrderFormData.order && newOrderFormData.order.id)
                ? "R"+newOrderFormData.order.id : ""
            document_reference={"order_id": order_id_check}
        }else if(isDuplicateOrder){
            document_reference={}
        }else{
            order_id_check=(newOrderFormData && newOrderFormData.order && newOrderFormData.order.id)
            ? newOrderFormData.order.id : ""
            document_reference={
            "doc_id": (newOrderFormData && newOrderFormData._id) ? newOrderFormData._id : "",
            "order_id": order_id_check,
            "item_ids": lineItemId
            }
        }
        if(status=='LabelFailed'){
            setIsFailedLabelPrinted(false)
            setDrawer(false)
            onClose(false)
            setRateDrawervisible(false)
            setNewOrderCreateDrawerVisible(false)
        }else{
            setIsFailedLabelPrinted(true)
        }
        const ratedata = {
            "quantity": "1",
            "referenceno": formData.sender_reference_no!=='' ? formData.sender_reference_no:referenceno,
            "preferred_carrier": preferred_carrier,
            "preferred_carrier_reference_id": (rate_row.carrier_name && typeof rate_row.carrier_name !== "undefined" && rate_row.carrier_name.toUpperCase() == "SHIPPINGCHIMP") ? "" : rate_row.carrier_reference_id,
            "preferred_service": (rate_row.carrier_name && typeof rate_row.carrier_name !== "undefined" && rate_row.carrier_name.toUpperCase() == "SHIPPINGCHIMP" && typeof rate_row.service_code !== "undefined" && rate_row.service_code != "PRI") ? "" : rate_row.service_code,
            "request_cheapest_carrier" : false,
            "standard_service_only": false,
            "reference": document_reference
        }

        if (customLineItems.length != 0) {
            ratedata["line_items"] = JSON.stringify(customLineItems)
        }
        const mergeobjectdata = Object.assign(formData, ratedata);
        dispatch(dopostDataCreateShipment(mergeobjectdata, apikey, meta))
        // dispatch(generateLabel(mergeobjectdata, apikey));
        setLabelShowData(true)
        setIsLabelOpened(false);
    }

    let columns = [
        {
            field: 'service_type',
            headerName: 'Service Type',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            resizable: false,
            renderCell: (params) => {
                if (params.row.service_type) {
                    return params.row.service_type
                } else {
                    return "-"
                }
            }
        },
        {
            field: 'total_charges',
            headerName: 'Amount',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            minWidth: 120,
            maxWidth: 120,
            resizable: false,
            renderCell: (params) => {
                if (params.row.total_charges) {
                    return params.row.total_charges
                } else {
                    return "-"
                }
            }
        },
        {
            field: 'action',
            headerName: 'Label',
            flex: 1,
            resizable: false,
            hide: reportName == 'dashboard' ? true : false,
            renderCell: (params) => {
                return <>{isFailedShipment && <div className="hide--outline"><Button disabled={orders.loading} variant="outlined"  style={{ margin: '0 15px' }} onClick={(event) => {
                    generatelabel(params.row,"LabelFailed")
                }
                }> <AddCircleOutlineOutlinedIcon style={{
                    paddingRight: '10px'
                }} /> Create </Button></div>
                }
                <div className="hide--outline"><Button disabled={orders.loading} variant="outlined" onClick={(event) => {
                    generatelabel(params.row,"LabelSuccess")
                }
                }> <AddCircleOutlineOutlinedIcon style={{
                    paddingRight: '10px'
                }} /> Create + Print </Button></div></>

            }
        }
    ];
    if(profile && profile.profile && (profile.profile.hide_carrier === false)){
        columns.splice(0, 0, {
            field: 'carrier_name',
            headerName: 'Carrier Name',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            renderCell: (params) => {
                if (params.row.carrier_name) {
                    return params.row.carrier_name
                } else {
                    return "-"
                }
            }
        })
    }
    useEffect(() => {
        if (orders && orders.notification && orders.notification.messageContent && orders.notification.messageContent[0] === 'Shipment Created Successfully' && visible == true && isLabelOpened == false && isFailedLabelPrinted==true) {
            setIsLabelOpened(true);
            setPrintUrl(orders.message.label_carrier)
            setIsPrinted(false)
            setDrawer(false)
            onClose(false)
            setRateDrawervisible(false)
            setNewOrderCreateDrawerVisible(false)
            setCustomLineItems([])
            setValues(initialFValues)
            setNewOrderFormData("")
        }
    }, [orders]);

    return (
        <>
            <Printer isPrinted={isPrinted} setIsPrinted={setIsPrinted} print_url={printUrl} />
            <Drawer
                anchor="right"
                open={visible}
                onClose={ondrawerclose}
                PaperProps={{
                    sx: { width: "70%" },
                }}
            >
                <div style={{ padding: "0 30px" }}>
                {(rates && rates.notification && rates.notification.show && rates.notification.show===true) &&
                            <Notification
                                is_open={rates.notification.show}
                                messageType={rates.notification.messageType}
                                messages={rates.notification.messageContent}
                                handleClose={() => dispatch(resetRateState())}
                            />
                        }

                    {(createshipment.notification.messageContent.length === 0 || createshipment.notification.messageType === 'error') &&
                        <>
                            <Card sx={{ minWidth: 1000, margin: '20px 0' }} className="sc--cardhead--details">
                                <CardHeader title='Rates' action={
                                    <div>
                                        <IconButton aria-label="settings" onClick={ondrawerclose}>
                                            <CloseIcon />
                                        </IconButton>
                                    </div>
                                } />
                                <Divider />
                            </Card>

                            <DataGrid sx={{ width: '100%' }}
                                disableRowSelectionOnClick={true}
                                getRowId={(row) => row.service_code}
                                rows={rates && rates.data}
                                columns={columns}
                                autoHeight={true}
                                hideFooter={true}
                                slots={{
                                    loadingOverlay: LinearProgress,
                                    NorowsData
                                }}
                                loading={rates && rates.loading || orders && orders.loading}
                            />
                        </>
                    }
                    {(createshipment.notification.messageContent).length !== 0 && (createshipment.notification.messageType !== 'error') &&
                        <>
                            <Card sx={{ minWidth: 1000, margin: '20px 0' }} className="sc--cardhead--details">
                                <CardHeader title='Labels' action={
                                    <div>
                                        <IconButton aria-label="settings" onClick={ondrawerclose}>
                                            <CloseIcon />
                                        </IconButton>
                                    </div>
                                } />
                                <Divider />
                            </Card>
                            <Card sx={{ minWidth: 1000, margin: '20px 0' }} className="sc--cardhead--details">
                                <Divider />
                                <div className="" style={{ margin: 'auto' }}>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px' }}>
                                        <CheckCircleIcon style={{ textAlign: 'center', fontSize: '40px', color: 'green' }} />
                                    </div>
                                    <h2 style={{ textAlign: 'center' }}>Successfully generated label</h2>
                                    <div style={{ marginBottom: '20px', textAlign: 'center' }}>
                                        <p style={{ marginBottom: '20px' }}>Tracking Number: {createshipment && createshipment.order && createshipment.order.trackingnumber}</p>
                                        <p style={{ marginBottom: '20px' }}>Carrier: {createshipment && createshipment.order && createshipment.order.carrier_name}</p>
                                        <a href={createshipment && createshipment.order && createshipment.order.instant_label_url} target='_blank'
                                            style={{ marginBottom: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', textDecoration: 'unset' }}>
                                            <RemoveRedEyeIcon />View Label
                                            </a>
                                    </div>
                                </div>

                            </Card>
                        </>
                    }
                </div>
            </Drawer >
        </>
    )
}


export default RateDrawer;