import { createSlice } from '@reduxjs/toolkit';
import { LABELGENAPI, LABELINGCHIMP_SAM_API } from '../../../API';

export const pickupslice = createSlice({
    name: 'pickup',
    initialState: {
        pickupData: {
        data: {},
        meta: [],
        loading: false,
        hasErrors: false,
        message: ""
    },
    createPickup: {
        loading: false,
        message:"",
        notification: { show: false, messageContent: "", messageType: "success" }
    },
    },
    reducers: {
        getPickupDetails: (state) => {
            return {
                ...state,
                pickupData: {
                    ...state.pickupData,
                loading: true
                }
            }
        },
        getPickupDetailsSuccess: (state, action) => {
            return {
                ...state,
                pickupData: {
                    ...state.pickupData,
                data: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.data,
                meta: action && action.payload && action.payload.data && action.payload.data.data,
                loading: false,
                hasErrors: false
                }
            }
        },
        getPickupDetailsFailure: (state, action) => {
            return {
                ...state,
                pickupData: {
                    ...state.pickupData,
                loading: false,
                hasErrors: true
                }
            }
        },
        postDataCreatePickup: (state, action) => {
            return {
                ...state,
                createPickup: {
                    ...state.createPickup,
                    loading: true
                }
            }
        },
        postDataCreatePickupSuccess: (state, action) => {
            return {
                ...state,
                createPickup: {
                    ...state.createPickup,
                    message: action && action.payload && action.payload.data && action.payload.data.message  && action.payload.data.message.carrier_response,
                    loading: false,
                    notification: { show: true, messageContent: (action.payload && action.payload.data && action.payload.data.desc && action.payload.data.desc === "success") ? ["Pickup Created Successfully"] : [""], messageType: "success" }
                }
            }
        },
        postDataCreatePickupFailure: (state, action) => {
            return {
                ...state,
                createPickup: {
                    ...state.createPickup,
                    loading: false,
                    notification: { show: true, messageContent: action.payload && action.payload.data && action.payload.data.message ? [action.payload.data.message] : [""], messageType: "error" }
                }
            }
        },
        resetPickupNotification: (state, action) => {
            return {
                ...state,
                createPickup: {
                    ...state.createPickup,
                    notification: { show: false, messageContent: "", messageType: "success" }
                },
            }
        }
    }
})
export const { getPickupDetails, getPickupDetailsSuccess, getPickupDetailsFailure} = pickupslice.actions;


export const fetchPickupDetails = (meta) => {
    const qs = Object.keys(meta)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(meta[key])}`)
    .join('&');

    return (dispatch) => {
        dispatch(getPickupDetails());
        try {
            LABELGENAPI
                .get('/pickup/custom?'+qs)
                .then(function (response) {
                    dispatch(getPickupDetailsSuccess(response));
                })
                .catch(function (error) {
                    dispatch(getPickupDetailsFailure(error.response));
                });
        } catch (error) {
            dispatch(getPickupDetailsFailure());
        }
    }
};

export const { postDataCreatePickup, postDataCreatePickupSuccess, postDataCreatePickupFailure } = pickupslice.actions;

export const dopostDataCreatePickup = (data, api_token,meta) => {
    return (dispatch) => {
        dispatch(postDataCreatePickup());
        try {
            LABELINGCHIMP_SAM_API
                .post('/custom-pickup-request', data, { headers: { 'x-api-key': api_token, 'Content-Type': 'application/json' } })
                .then(function (response) {
                    dispatch(postDataCreatePickupSuccess(response));
                    dispatch(fetchPickupDetails(meta))
                })
                .catch(function (error) {
                    dispatch(postDataCreatePickupFailure(error.response));
                });
        } catch (error) {
            dispatch(postDataCreatePickupFailure());
        }
    };
};

export const { resetPickupNotification } = pickupslice.actions;

export const resetPickupNotifications = () => {
    return (dispatch) => {
        dispatch(resetPickupNotification());
    }
}
export default pickupslice.reducer;
