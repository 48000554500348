import { createSlice } from '@reduxjs/toolkit';
import { LABELGENAPI } from '../../../API';


export const addressbillingslice = createSlice({
    name: 'addressbilling',
    initialState: {
        data: {},
        meta: [],
        loading: false,
        hasErrors: false,
        message: "",
    },
    reducers: {
        getAddressBilling: (state) => {
            return {
                ...state,
                loading: true,
                hasErrors: false
            }
        },
        getAddressBillingSuccess: (state, action) => {
            return {
                ...state,
                data: action && action.payload && action.payload.data && action.payload.data.data,
                hasErrors: false,
                loading: false
            }
        },
        getAddressBillingFailure: (state, action) => {
            return {
                ...state,
                message:  action && action.payload && action.payload.data && action.payload.data.message,
                loading: false,
                hasErrors: true
            }
        },
        putSenderAddress: (state) => {
            return {
                ...state,
                loading: true,
                hasErrors: false,
                message: ""
            }
        },
        putSenderAddressSuccess: (state, action) => {
            return {
                ...state,
                loading: false,
                hasErrors: false,
                message: action.data.message
            }
        },
        putSenderAddressFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                hasErrors: true,
                message:  action.payload && action.payload.data && action.payload.data.message
            }
        },

        postAddressBilling: (state) => {
            return {
                ...state,
                loading: true,
                hasErrors: false,
                message: ""
            }
        },
        postAddressBillingSuccess: (state, action) => {
            return {
                ...state,
                loading: false,
                hasErrors: false,
                message: action && action.payload && action.payload.data && action.payload.data.message,
            }
        },
        postAddressBillingFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                hasErrors: true,
                message: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.message,
            }
        },

        putAddressBilling: (state) => {
            return {
                ...state,
                loading: true,
                hasErrors: false,
                message: ""
            }
        },
        putAddressBillingSuccess: (state, action) => {
            return {
                ...state,
                loading: false,
                hasErrors: false,
                message: action && action.payload && action.payload.data && action.payload.data.message,
            }
        },
        putAddressBillingFailure: (state, action) => {
            return {
                ...state,
                message: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.message,
                loading: false,
                hasErrors: true,
            }
        },


    }
})
export const { getAddressBilling, getAddressBillingSuccess, getAddressBillingFailure, postAddressBilling,
    postAddressBillingSuccess, postAddressBillingFailure, putAddressBilling, putAddressBillingSuccess,
    putAddressBillingFailure } = addressbillingslice.actions;


export const fetchAddressBilling = data => dispatch => {
    dispatch(getAddressBilling());
    try {
        LABELGENAPI
            .get('/settings/address-billing')
            .then(function (response) {
                dispatch(getAddressBillingSuccess(response));
            })
            .catch(function (error) {
                dispatch(getAddressBillingFailure(error.response));
            });
    } catch (error) {
        dispatch(getAddressBillingFailure());
    }
};

export const storeAddressBilling = data => dispatch => {
    dispatch(postAddressBilling());

    try {
        LABELGENAPI.post('/settings/address-billing', data)
            .then(function (response) {
                dispatch(postAddressBillingSuccess(response));
            }).catch(function (error) {
                dispatch(postAddressBillingFailure(error.response));
            })
    }
    catch (error) {
        dispatch(postAddressBillingFailure(error.response));
    }
}


export const updateAddressBilling = (id, data) => dispatch => {
    dispatch(putAddressBilling());
    try {
        LABELGENAPI.patch('/settings/address-billing/' + id, data)
            .then(function (response) {
                dispatch(putAddressBillingSuccess(response));
                dispatch(getAddressBillingSuccess());
            }).catch(function (error) {
                dispatch(putAddressBillingFailure(error.response));
            })
    }
    catch (error) {
        dispatch(putAddressBillingFailure(error.response));
    }
}

export default addressbillingslice.reducer;
