import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { useraccountactivate, resetOnboarding } from '../../pages/Dashboard/onboardingSlice';
import { authenticate } from '../../pages/Auth/authSlice';

const Header = ({ }) => {
    const dispatch = useDispatch();
    const profile = useSelector(state => state.auth.profile);    
    const activation = useSelector(state => state.onboarding && state.onboarding.activation);  
    const [alert, setAlert] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const handleClose = () => {
        setSnackOpen(false);
        dispatch(resetOnboarding());
    };
    const handleVerify = () => {
        dispatch(useraccountactivate())
    }
    useEffect(() => {
        if (activation && activation.message && activation.hasErrors == false ) {
            setAlert(true)
            setSnackOpen(true)            
        } else if (activation && activation.hasErrors == true) {
            setAlert(true)
            setSnackOpen(true)
        }
    }, [activation && activation.message])

 
    return (
        <>
            {alert &&
                <Snackbar
                    open={snackOpen}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    autoHideDuration={6000}
                    onClose={handleClose}
                >
                    <Alert severity={activation && activation.hasErrors === true ? "error" : "success"}
                        style={{ justifyContent: 'center', margin: "10px 0" }}
                    >
                        <span > {activation && activation.message}</span>
                    </Alert>
                </Snackbar>
            }
            {profile && profile.status === 'PENDING' &&

                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {profile && profile.status === 'PENDING' && profile && profile.activation_requested === true &&
                        <p style={{ margin: "0px 10px" }}>Your account is on verifying process yet to be activated </p>
                    }
                    {profile && profile.status === 'PENDING' && profile && profile.activation_requested === false &&
                        <>
                            <p style={{ margin: "0px 10px" }}>Your account is not yet activated. Please request for activation </p>
                            <Link to="/settings/payment"><Button variant="outlined"  style={{ color: '#fff', border: '1px solid #fff' }}>Request</Button></Link>
                        </>
                    }
                </div>
            }
        </>
    )

}

export default Header;
