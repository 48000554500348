import { createSlice } from '@reduxjs/toolkit';
import { OMSAPI } from '../../../API';
import axios from 'axios';

/* canceling previous requests if multiple requests is triggered */
const CancelToken = axios.CancelToken;
let source = CancelToken.source();

export const customerfeedbackslice = createSlice({
    name: 'feedback',
    initialState: {
        stats: {
            data: {},
            loading: false,
            hasErrors: false,
        },
        report: {
            data: [],
            meta: {},
            loading: false,
            hasErrors: false,
        },
        preview: {
            data: [],
            loading: false,
            hasErrors: false
        }
    },

    reducers: {
        getCustomerFeedbackReport: (state) => {
            return {
                ...state,
                report: {
                    ...state.report,
                    loading: true
                }
            };
        },
        getCustomerFeedbackReportSuccess: (state, action) => {
            return {
                ...state,
                report: {
                    data: action && action.payload && action.payload.data && action.payload.data.data,
                    meta: action && action.payload && action.payload.data  && action.payload.data.meta,
                    hasErrors: false,
                    loading: false
                }

            };
        },
        getCustomerFeedbackReportFailure: (state, action) => {
            return {
                ...state,
                report: {
                    ...state.report,
                    loading: false,
                    hasErrors: true

                }

            };
        },
        getCustomerFeedbackPreview: (state) => {
            return {
                ...state,
                preview: {
                    ...state.preview,
                    loading: true
                }
            };
        },
        getCustomerFeedbackPreviewSuccess: (state, action) => {
            return {
                ...state,
                preview: {
                    data: action && action.payload && action.payload.data && action.payload.data.data,
                    hasErrors: false,
                    loading: false
                }

            };            
        },
        getCustomerFeedbackPreviewFailure: (state, action) => {
            return {
                ...state,
                preview: {
                    ...state.preview,
                    loading: false,
                    hasErrors: true

                }

            };
        },
    }
})

export const { getCustomerFeedbackReport, getCustomerFeedbackReportSuccess, getCustomerFeedbackReportFailure,getCustomerFeedbackPreview,getCustomerFeedbackPreviewSuccess,getCustomerFeedbackPreviewFailure } = customerfeedbackslice.actions;

export const fetchCustomerFeedbackReport = (meta) => {

    const qs = Object.keys(meta)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(meta[key])}`)
        .join('&');


    return (dispatch) => {
        dispatch(getCustomerFeedbackReport());
        try {

            source && source.cancel('Operation canceled due to new request.');
            source = axios.CancelToken.source();

            OMSAPI
                .get('/customer-feedback/report?' + qs)
                .then(function (response) {
                    dispatch(getCustomerFeedbackReportSuccess(response));
                })
                .catch(function (error) {
                    dispatch(getCustomerFeedbackReportFailure(error.response));
                });
        } catch (error) {
            dispatch(getCustomerFeedbackReportFailure());
        }
    };
};

export const fetchCustomerFeedbackPreview = (id) => {
    return (dispatch) => {
        dispatch(getCustomerFeedbackPreview());
        try {
            OMSAPI
                .get('/customer-feedback/preview/' + id)
                .then(function (response) {
                    dispatch(getCustomerFeedbackPreviewSuccess(response));
                })
                .catch(function (error) {
                    dispatch(getCustomerFeedbackPreviewFailure(error.response));
                });
        } catch (error) {
            dispatch(getCustomerFeedbackPreviewFailure());
        }
    };
};

export const { resetNotification } = customerfeedbackslice.actions;

export const resetGenerateManifestNotification = () => {
    return (dispatch) => {
        dispatch(resetNotification());
    }
}

export default customerfeedbackslice.reducer;
