import { createSlice } from '@reduxjs/toolkit';
import {LABELGENAPI } from '../../../API';

export const pickupaddressslice = createSlice({
    name: 'pickupaddress',
    initialState: {
        data: [],
        meta: [],
        loading: false,
        hasErrors: false,
        message: "",
        messageType:""
    },
    reducers: {
        getPickupAddress: (state) => {
            return {
                ...state,
                loading: true,
                hasErrors: false
            }
        },
        getPickupAddressSuccess: (state, action) => {
            return {
                ...state,
                data: action && action.payload && action.payload.data && action.payload.data.data,
                hasErrors: false,
                loading: false
            }
        },
        getPickupAddressFailure: (state, action) => {
            return {
                ...state,              
                loading: false,
                hasErrors: true,
                message: action && action.payload && action.payload.data && action.payload.data.message
            }
        },
        putPickupAddress: (state) => {
            return {
                ...state,
                loading: true,
                hasErrors: false,
                message: ""
            }
        },
        putPickupAddressSuccess: (state, action) => {
            return {
                ...state,
                loading: false,
                hasErrors: false,
                messageType:"success",
                message: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.message
            }
        },
        putPickupAddressFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                hasErrors: true,
                message: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.message
            }
        },
        deletePickupAddress: (state) => {
            return {
                ...state,
                loading: true,
                hasErrors: false,
                message: ""
            }
        },
        deletePickupAddressSuccess: (state, action) => {
            return {
                ...state,
                loading: false,
                hasErrors: false,
                message: action && action.payload && action.payload.data && action.payload.data.message
            }
        },
        deletePickupAddressFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                hasErrors: true,
                message: action && action.payload && action.payload.data && action.payload.data.message
            }
        },
        postPickupAddress: (state) => {
            return {
                ...state,
                loading: true,
                hasErrors: false,
                message: ""
            }
        },
        postPickupAddressSuccess: (state, action) => {
            return {
                ...state,
                loading: false,
                hasErrors: false,
                messageType:"success",
                message: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.message
            }                
            
        },
        postPickupAddressFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                hasErrors: true,
                messageType:"failure",
                message: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.message
            }
            
        },
        resetPickupAddress: (state) => {
            return {
                ...state,
                loading: false,
                hasError: false,
                message: "",
            }
        },

        


    }
})
export const { getPickupAddress, getPickupAddressSuccess, getPickupAddressFailure, 
    putPickupAddress, putPickupAddressSuccess, putPickupAddressFailure, deletePickupAddress, deletePickupAddressSuccess, deletePickupAddressFailure,
    postPickupAddress, postPickupAddressSuccess, postPickupAddressFailure, resetPickupAddress } = pickupaddressslice.actions;


export const fetchPickupAddress = data =>dispatch => {    
    dispatch(getPickupAddress());
    try {
        LABELGENAPI
            .get('/settings/address-pickup')
            .then(function (response) {
                dispatch(getPickupAddressSuccess(response));
            })
            .catch(function (error) {
                dispatch(getPickupAddressFailure(error.response));
            });
    } catch (error) {
        dispatch(getPickupAddressFailure());
    }
};

export const storePickupAddress = data => dispatch => {
    dispatch(postPickupAddress());
    try {
        LABELGENAPI.post('/settings/address-pickup', data)
            .then(function (response) {
                dispatch(postPickupAddressSuccess(response));
                dispatch(fetchPickupAddress());
            }).catch(function (error) {
                dispatch(postPickupAddressFailure(error.response));
            })
    }
    catch (error) {
        dispatch(postPickupAddressFailure(error.response));
    }
}



export const updatePickupAddress = (id, data) => dispatch => {   
    dispatch(putPickupAddress());

    try {
        LABELGENAPI.patch('/settings/address-pickup/' + id, data)
            .then(function (response) {
                dispatch(putPickupAddressSuccess(response));
                dispatch(fetchPickupAddress());
            }).catch(function (error) {
                dispatch(putPickupAddressFailure(error.response));
            })
    }
    catch (error) {
        dispatch(putPickupAddressFailure(error.response));
    }

}

export const destoryPickupAddress = id => dispatch => {
    dispatch(deletePickupAddress());

    try {
        LABELGENAPI
            .delete("/settings/address-pickup/" + id)
            .then(function (response) {
                dispatch(deletePickupAddressSuccess(response));
                dispatch(fetchPickupAddress());
            })
            .catch(function (error) {
                dispatch(deletePickupAddressFailure(error.response));
            });
    } catch (error) {
        dispatch(deletePickupAddressFailure(error));
    }
};
export const resetPickupAddressState = data => dispatch => {    
    dispatch(resetPickupAddress());
}


export default pickupaddressslice.reducer;
