import { createSlice } from '@reduxjs/toolkit';
import { LABELINGCHIMP_SAM_API, OMSAPI } from '../../../API';


export const trackingEventsSlice = createSlice({
    name: 'Tracking',
    initialState: {
        tracking: {},
        loading: false,
        meta: [],
        hasErrors: false,
        showSlider: false,
        notification: { show: false, messageContent: "", messageType: "success" }
    },
    reducers: {
        getTrackingDetail: (state) => {
            return {
                ...state,
                loading: true,
                showSlider: true
            }
        },
        getTrackingDetailSuccess: (state, action) => {
            return {
                ...state,
                tracking: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data,
                hasErrors: false,
                loading: false,
                notification: { show: true, messageContent: action && action.payload && action.payload.data && action.payload.data && action.payload.data.meta, messageType: "success" },
                showSlider: true
            }
        },
        getTrackingDetailFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                notification: { show: true, messageContent: action && action.payload && action.payload.data && action.payload.data.message && action.payload.data.message.meta, messageType: "error" },
                tracking: {},
            }
        },
        resetTrackingNotification: (state, action) => {
            return {
                ...state,
                tracking: {},
                notification: { show: false, messageContent: "", messageType: "success" }
            }
        }
    }
})
export const { getTrackingDetail, getTrackingDetailSuccess, getTrackingDetailFailure, resetTrackingNotification } = trackingEventsSlice.actions;


export const fetchTrackingDetail = (data, apikey) => dispatch => {
    const qs = Object.keys(data)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
        .join('&');
    dispatch(getTrackingDetail());
    let trackingNumber = ""
    if("tracking_number" in data){
        trackingNumber = data["tracking_number"];
    }
    dispatch(updateTrackingDetail(trackingNumber, apikey));
    try {
        LABELINGCHIMP_SAM_API.get('/track?' + qs, {
            crossDomain: true,
            headers: {
                "x-api-key": apikey
            }
        })
            .then(function (response) {
                dispatch(getTrackingDetailSuccess(response));
            })
            .catch(function (error) {
                dispatch(getTrackingDetailFailure(error.response));
            });
    } catch (error) {
        dispatch(getTrackingDetailFailure(error));
    }
};

export const updateTrackingDetail = (trackingNumber, apikey) => dispatch => {
    OMSAPI.get('/track/' + trackingNumber, {
        crossDomain: true,
        headers: {
            "x-api-key": apikey
        }
    })
};

export const resetTrackingEventNotification = data => dispatch => {
    dispatch(resetTrackingNotification());
}


export default trackingEventsSlice.reducer;
