import React from "react";
import moment from 'moment';

const DateTime = ({ datetime = null }) => {
    return (
        <>
            {datetime && moment(datetime).isValid() && <span className="tb-sub">{moment(datetime).format('DD/MM/YYYY HH:mm:ss')}</span>}
            {datetime && !moment(datetime).isValid() && moment.utc(datetime).isValid() && <span className="tb-sub">{moment.utc(datetime).local().format('DD/MM/YYYY HH:mm:ss')}</span>}
            {(!datetime || (!moment(datetime).isValid() && !moment.utc(datetime).isValid())) && <span className="tb-sub"> - </span>}
        </>
    );
};

export default DateTime;