import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Drawer from '@mui/material/Drawer';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import moment from "moment";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import InfoIcon from '@mui/icons-material/Info';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import OrderStatus from '../../pages/General/OrderStatus'
import { CardContent, Typography ,Tooltip} from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { resetTrackingEventNotification } from '../../pages/Reports/Deliveries/trackingEventsSlice';
import Carrier from './Carrier'


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    boxShadow: 'unset',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    color: theme.palette.text.secondary,
}));

const TrackingDrawer = ({ visible, message = null, onClose,isMultiPiece }) => {
    const dispatch = useDispatch();
    const trackingDatas = useSelector(state => state.tracking);
    const [trackingData, setTrackingData] = useState({});
    const [trackVisible, setTrackVisible] = useState(false);
    const profile = useSelector(state => state.auth.profile);
    const [trackEventIcon, setTrackEventIcon] = useState("");
    const [trackingEventCircle, setTrackingEventCircle] = useState('');
    const [tag, setTag] = useState("");
    const [shipmentStatus, setShipmentStatus] = useState("");
    const [carrierName, setCarrierName] = useState("");
    const [dateCheck, setDateCheck] = useState("");
    const [open, setOpen] = React.useState(false);
   
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const columns = [
        {
             field: 'tracking_number', 
             headerName: 'Tracking number',
             flex: 1,
             renderCell: (params) => {
                return (
                    <p>{params.row.tracking_number}</p> 
                   
                )
            }
        },
        
        {
            field: 'carrier_name_formatted',
            headerName: 'Carrier Name',
            flex: 1,
            hide: profile.hide_carrier === false ? false:true,
            renderCell: (params) => {
                return (
                    <img src={"/assets/images/carriers/" + params.row.carrier_name_formatted + ".jpg"}
                    style={{width:params.row.carrier_name_formatted =='UPS' ? "50px" : "90px"}}/>
                )
            }
        },
      
        {
            field: 'current_status',
            headerName: 'Status',
            flex: 1,
            renderCell: (params) => {
                return (
                    <OrderStatus orderStatus={params.row.current_status}
                    />
                )
            }
        },
        {
            field: 'action',
            headerName: 'Shipment Details',
            minWidth: 200,
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                const trackingNumber = params.row.tracking_number ? params.row.tracking_number : "";
                    return <div>
                        <span style={{ marginRight: '10px' }}><Tooltip title="View" placement="left-start">
                            <IconButton>
                                <RemoveRedEyeIcon style={{ color: "#1890ff", cursor: 'pointer' }} onClick={e => { trackingCallback(params.row.tracking_number) }} />
                            </IconButton>
                        </Tooltip>
                        </span>
                    </div>
            }
        }
    ]
    const trackingCallback = (trackingNumber) => {
       let output = trackingDatas.tracking.filter(({ tracking_number }) =>[trackingNumber].includes(tracking_number));
        let finaloutput=Object.assign({}, output[0]);
        setTrackingData(finaloutput)
        setTrackVisible(true)
        
           if (typeof finaloutput !== "undefined") {
            if (finaloutput && finaloutput.current_status_formatted === "DELIVERED") {
                setTag("rgb(0, 153, 0)")
                setShipmentStatus("delivered")
            } else if (finaloutput && finaloutput.current_status_formatted &&
                (finaloutput.current_status_formatted === "IN TRANSIT" || finaloutput.current_status_formatted === "PICKED UP" || finaloutput.current_status_formatted === "MIDDLE MILE")) {
                setTag("#09c2de")
                setShipmentStatus("transit")
            } else if (finaloutput && finaloutput.current_status_formatted === "EXCEPTION") {
                setTag("#e85347")
                setShipmentStatus("exception")
                setTrackingEventCircle("exception-md-step-circle md-step-circle")
                setTrackEventIcon("!")
            }
            else if (finaloutput && finaloutput.current_status_formatted === "RETURN") {
                setTag("#e85347")
                setShipmentStatus("exception")
                setTrackingEventCircle("exception-md-step-circle md-step-circle")
                setTrackEventIcon("↵")
            }
            else if (finaloutput && finaloutput.current_status_formatted &&
                (finaloutput.current_status_formatted === "ATTEMPTED" || finaloutput.current_status_formatted === "SHIPMENT ON HOLD" ||
                finaloutput.current_status_formatted === "OUT FOR DELIVERY")) {
                setTag("#e85347")
                setShipmentStatus("exception")
                setTrackingEventCircle("otherevents-md-step-circle md-step-circle")
                setTrackEventIcon("➜")
            }
            else if (finaloutput && finaloutput.current_status_formatted && (finaloutput.current_status_formatted === "ORDER CREATED" || finaloutput.current_status_formatted === "INFO RECEIVED" || finaloutput.current_status_formatted === "LABEL GENERATED")) {
                setTag("rgb(0, 153, 0)")
                setShipmentStatus("ordercreated")
            }
        }
        setDateCheck((finaloutput && finaloutput["events"] && finaloutput["events"][0] && finaloutput["events"][0]["date"]) ? finaloutput["events"][0]["date"] : "");
        setCarrierName(typeof finaloutput && finaloutput && (finaloutput.carrier_name !== "undefined" ? finaloutput.carrier_name : ""));

        
   
    }
    
    const ondrawerclose = () => {
            onClose(false)
            setTrackVisible(false)
            setTrackingData({})
            setOpen(false);
            dispatch(resetTrackingEventNotification())
    }
    let iteration = 1;
    let dateExist = 1;
    React.useEffect(() => {
        if (typeof trackingDatas !== "undefined" && isMultiPiece==false) {
            let finaloutput=trackingDatas && trackingDatas.tracking;
            setTrackingData(finaloutput)
            setTrackVisible(true)
            if (typeof finaloutput !== "undefined") {
                if (finaloutput && finaloutput.current_status_formatted === "DELIVERED") {
                    setTag("rgb(0, 153, 0)")
                    setShipmentStatus("delivered")
                } else if (finaloutput && finaloutput.current_status_formatted &&
                    (finaloutput.current_status_formatted === "IN TRANSIT" || finaloutput.current_status_formatted === "PICKED UP" || finaloutput.current_status_formatted === "MIDDLE MILE")) {
                    setTag("#09c2de")
                    setShipmentStatus("transit")
                } else if (finaloutput && finaloutput.current_status_formatted === "EXCEPTION") {
                    setTag("#e85347")
                    setShipmentStatus("exception")
                    setTrackingEventCircle("exception-md-step-circle md-step-circle")
                    setTrackEventIcon("!")
                }
                else if (finaloutput && finaloutput.current_status_formatted === "RETURN") {
                    setTag("#e85347")
                    setShipmentStatus("exception")
                    setTrackingEventCircle("exception-md-step-circle md-step-circle")
                    setTrackEventIcon("↵")
                }
                else if (finaloutput && finaloutput.current_status_formatted &&
                    (finaloutput.current_status_formatted === "ATTEMPTED" || finaloutput.current_status_formatted === "SHIPMENT ON HOLD" ||
                    finaloutput.current_status_formatted === "OUT FOR DELIVERY")) {
                    setTag("#e85347")
                    setShipmentStatus("exception")
                    setTrackingEventCircle("otherevents-md-step-circle md-step-circle")
                    setTrackEventIcon("➜")
                }
                else if (finaloutput && finaloutput.current_status_formatted && (finaloutput.current_status_formatted === "ORDER CREATED" || finaloutput.current_status_formatted === "INFO RECEIVED" || finaloutput.current_status_formatted === "LABEL GENERATED")) {
                    setTag("rgb(0, 153, 0)")
                    setShipmentStatus("ordercreated")
                }
            }
            setDateCheck((finaloutput && finaloutput["events"] && finaloutput["events"][0] && finaloutput["events"][0]["date"]) ? finaloutput["events"][0]["date"] : "");
            setCarrierName(typeof finaloutput && finaloutput && (finaloutput.carrier_name !== "undefined" ? finaloutput.carrier_name : ""));        
        }else if(trackingDatas && trackingDatas.tracking == undefined){
            setTrackVisible(true) 
            console.log("success hello")
        }

    }, [trackingDatas])

    const copyToken = () => {
        navigator.clipboard.writeText("https://track.shippingchimp.com/?t="+trackingData.tracking_number);
        setOpen(true);

    }

    const action = (
        <>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </>
    );

    return (
        <>
            <Drawer
                anchor="right"
                open={visible}
                onClose={ondrawerclose}
                PaperProps={{
                    sx: { width: "70%" },
                }}
            >
                <div style={{ padding: "0 30px", height: '100%' }}>
                    <Card sx={{ margin: '20px 0' }} className="sc--cardhead--details">
                        <CardHeader title='Tracking Events' action={
                            <div>
                                <IconButton aria-label="settings" onClick={ondrawerclose}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        } />
                        {trackingDatas && trackingDatas.loading === true && <>
                            <LinearProgress />
                        </>
                        }
                        <Divider />
                    </Card>
                    

                    {trackingDatas && trackingDatas.loading === false &&  isMultiPiece && trackingDatas && trackingDatas.tracking !== undefined && trackingDatas.notification.messageContent.carrier_status==true &&<>
                        <CardContent>
                            <div style={{ maxWidth: '100%' }}>
                                <DataGrid
                                    getRowId={(row) => row.tracking_number}
                                    disableSelectionOnClick={true}
                                    rows={trackingDatas && trackingDatas.tracking}
                                    columns={columns}
                                    autoHeight={true}
                                    slots={{
                                        loadingOverlay: LinearProgress,
                                    }}
                                    hideFooter={true}
                                    loading={trackingDatas && trackingDatas.loading}
                                />
                            </div>
                         </CardContent>
                         </>
                    }
                        {trackingDatas.notification.messageContent.carrier_status==false &&
                            <>
                                <Item>
                                    {
                                        message === null &&
                                        <Alert severity="warning">
                                            <AlertTitle className = 'custom-typography' style={{ fontWeight: '600'}}> <Typography><span>Shipping information not found for&nbsp;
                                                <b>{(trackingDatas && trackingDatas.notification && trackingDatas.notification.messageContent && trackingDatas.notification.messageContent.tracking_number)}</b></span></Typography>
                                            </AlertTitle>
                                            <p style={{ padding: '0 30px' }}>We don’t have information for this tracking number due to one of the following reasons.</p>
                                            <ul>
                                                <li style={{ padding: '5px 0px' }}>Might be latest shipment information not available with carrier</li>
                                                <li style={{ padding: '5px 0px' }}>Might be shipment manifest not yet closed</li>
                                            </ul>
                                        </Alert>
                                    }
                                    {
                                        message !== null &&
                                        <Alert severity="warning">
                                            <AlertTitle className = 'custom-typography' style={{ fontWeight: '600'}}> <Typography>{message}</Typography></AlertTitle>
                                        </Alert>
                                    }
                                </Item>
                            </>
                        }
                        {trackingDatas && trackingDatas.loading === false && trackingDatas && trackingDatas.tracking !== undefined && (trackVisible || isMultiPiece==false) &&
                          
                        <div className="track__details">
                            {trackingData.tracking_number && <h5 className="sc--text--center my-2" style={{ fontSize: '16px' }}>{trackingData.tracking_number}    <Tooltip title="Copy Tracking url" placement="top-start"><Button variant="outlined" style={{border:'unset'}} onClick={copyToken}><ContentCopyOutlinedIcon  /></Button></Tooltip></h5>}
                            <h3 style={{ color: tag, textAlign: 'center' }}>
                                {(trackingData.current_status_formatted && trackingData.current_status_formatted !== "") && trackingData.current_status_formatted}
                                {(trackingData && trackingData && trackingData.current_status_formatted && trackingData.current_status_formatted !== ""
                                    && trackingData.disputes && trackingData.disputes.returns === true) && <span style={{ paddingLeft: '7px' }} ><Chip color="warning" size="small" label="Returns"/></span>}
                                {(!trackingData && trackingData.current_status_formatted && trackingData.message) && trackingData.message}
                            </h3>
                            {trackingData && trackingData.pod_file_url && trackingData.pod_file_url!=='' && <p style={{textAlign:'center'}}><a href={trackingData.pod_file_url} style={{color:'#0467C6',paddingBottom:'20px'}} target='_blank'>View delivery confirmation</a></p>}
                            {trackingData && 
                                trackingData["events"] &&
                                <>
                                    <p className="sc--text--center my-2">
                                        {trackingData["events"] && trackingData["events"][0] && <>
                                            {(trackingData["events"][0]["utc_date"] && trackingData["events"][0]["utc_date"] != "" && moment.utc(trackingData["events"][0]["utc_date"]+" "+ trackingData["events"][0]["utc_time"]).local().format("dddd"))}
                                            {(trackingData["events"][0]["utc_date"] && trackingData["events"][0]["utc_date"] != "" && ", " + moment.utc(trackingData["events"][0]["utc_date"]+" "+ trackingData["events"][0]["utc_time"]).local().format("MMM"))}
                                            {(trackingData["events"][0]["utc_date"] && trackingData["events"][0]["utc_date"] != "" && " " + moment.utc(trackingData["events"][0]["utc_date"]+" "+ trackingData["events"][0]["utc_time"]).local().format("DD"))}
                                            {(trackingData["events"][0]["utc_time"] && trackingData["events"][0]["utc_time"] != "" && " at " + moment.utc(trackingData["events"][0]["utc_date"]+" "+ trackingData["events"][0]["utc_time"]).local().format("HH:mm"))}
                                        </>}
                                    </p>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6} md={6} style={{ padding: '15px' }}>
                                            <Item>
                                                <List className="sc--track--details">
                                                    {(profile.hide_carrier === false) &&
                                                        <ListItem disablePadding>
                                                            <ListItemButton>
                                                                <ListItemIcon>
                                                                    <p><strong style={{ marginRight: '15px' }}>Carrier :</strong></p>
                                                                </ListItemIcon>
                                                                <ListItemText primary={carrierName === "" ? '-' : <Carrier carrierName={carrierName} width={"30%"} />} />
                                                            </ListItemButton>
                                                        </ListItem>
                                                    }
                                                    <Divider />
                                                    <ListItem disablePadding>
                                                        <ListItemButton>
                                                            <ListItemIcon>
                                                                <p><strong style={{ marginRight: '15px' }}>Tracking Number :</strong></p>
                                                            </ListItemIcon>
                                                            <ListItemText primary={trackingData.tracking_number === "" ? '-' : trackingData.tracking_number} />
                                                        </ListItemButton>
                                                    </ListItem>
                                                    <Divider />
                                                    <ListItem disablePadding>
                                                        <ListItemButton>
                                                            <ListItemIcon>
                                                                <p><strong style={{ marginRight: '15px' }}>Service Type :</strong></p>
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary={trackingData.service['service'] && trackingData["service"]['type'] === "" ?
                                                                    '-' : trackingData["service"]['type']}
                                                            />
                                                        </ListItemButton>
                                                    </ListItem>
                                                </List>
                                            </Item>
                                        </Grid>
                                        <Grid item xs={6} md={6} style={{ padding: '15px' }}>
                                            <Item>
                                                <List className="sc--track--details">
                                                    <ListItem disablePadding>
                                                        <ListItemButton>
                                                            <ListItemIcon>
                                                                <p><strong style={{ marginRight: '15px' }}>Ship date :</strong></p>
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary={trackingData["date"] && trackingData["date"]["shipped"] === "" ?
                                                                    '-' : trackingData["date"] && trackingData["date"]["shipped"]}
                                                            />
                                                        </ListItemButton>
                                                    </ListItem>
                                                    <Divider />
                                                    <ListItem disablePadding>
                                                        <ListItemButton>
                                                            <ListItemIcon>
                                                                <p><strong style={{ marginRight: '15px' }}>Est delivery :</strong></p>
                                                            </ListItemIcon>
                                                            <ListItemText primary={<Typography style={{ fontSize: '14px',display:'flex',alignItems:'center' }}>{trackingData && trackingData["date"] && trackingData["date"]["estimated"] === "" ?
                                                                    '-' : trackingData && trackingData["date"] && trackingData["date"]["estimated"]}
                                                                    {trackingData && trackingData["date"] && trackingData["date"]["estimated"]!=='' && 
                                                                        <Tooltip title="The estimated delivery date is approximate, and your packages may arrive within one day earlier or later than the estimated date." placement="top"> 
                                                                            <InfoIcon style={{paddingLeft:'5px'}}/>
                                                                        </Tooltip>
                                                                    }
                                                                     </Typography>}
                                                                    />
                                                        </ListItemButton>
                                                    </ListItem>
                                                    <Divider />
                                                    <ListItem disablePadding>
                                                        <ListItemButton>
                                                            <ListItemIcon>
                                                                <p><strong style={{ marginRight: '15px' }}>Delivery date :</strong></p>
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary={trackingData && trackingData["date"] && trackingData["date"]["delivery"] === "" ?
                                                                    '-' : trackingData && trackingData["date"] && trackingData["date"]["delivery"]}
                                                            />
                                                        </ListItemButton>
                                                    </ListItem>
                                                </List>
                                            </Item>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={1}>
                                        <Grid item xs={6} md={12} style={{ padding: '15px' }}>
                                            <Item>
                                                {shipmentStatus == 'ordercreated' &&
                                                    <div className="md-stepper-horizontal orange">
                                                        <div className="md-step active done">
                                                            <div className="md-step-circle"><span>✓</span></div>
                                                            <div className="step-title">{trackingData.current_status_formatted}</div>
                                                            <div className="md-step-bar-right transit-md-step-bar-right"></div>
                                                        </div>
                                                        <div className="md-step active">
                                                            <div className="md-step-circle transit-md-step-circle"><span>✓</span></div>
                                                            <div className="md-step-bar-left transit-md-step-bar-left"></div>
                                                            <div className="md-step-bar-right transit-md-step-bar-right"></div>
                                                        </div>
                                                        <div className="md-step active">
                                                            <div className="md-step-circle transit-md-step-circle"><span>✓</span></div>
                                                            <div className="md-step-bar-left transit-md-step-bar-left"></div>
                                                            <div className="md-step-bar-right transit-md-step-bar-right"></div>
                                                        </div>
                                                        <div className="md-step active">
                                                            <div className="transit-md-step-circle md-step-circle"><span>✓</span></div>
                                                            <div className="transit-md-step-bar-left md-step-bar-left"></div>
                                                        </div>
                                                    </div>
                                                }
                                                {shipmentStatus == 'transit' &&
                                                    <div className="md-stepper-horizontal orange">
                                                        <div className="md-step active done">
                                                            <div className="md-step-circle"><span>✓</span></div>
                                                            <div className="step-title">CREATED</div>
                                                            <div className="md-step-bar-right"></div>
                                                        </div>
                                                        <div className="md-step active editable">
                                                            <div className="md-step-circle"><span>➜</span></div>
                                                            <div className="step-title">{trackingData.current_status_formatted}</div>
                                                            <div className="md-step-bar-left"></div>
                                                            <div className="md-step-bar-right transit-md-step-bar-right"></div>
                                                        </div>
                                                        <div className="md-step active">
                                                            <div className="md-step-circle transit-md-step-circle"><span>✓</span></div>
                                                            <div className="md-step-bar-left transit-md-step-bar-left"></div>
                                                            <div className="md-step-bar-right transit-md-step-bar-right"></div>
                                                        </div>
                                                        <div className="md-step active">
                                                            <div className="transit-md-step-circle md-step-circle"><span>✓</span></div>
                                                            <div className="transit-md-step-bar-left md-step-bar-left"></div>
                                                        </div>
                                                    </div>
                                                }
                                                {shipmentStatus == 'delivered' &&
                                                    <div className="md-stepper-horizontal orange">
                                                        <div className="md-step active done">
                                                            <div className="md-step-circle"><span>✓</span></div>
                                                            <div className="step-title">CREATED</div>
                                                            <div className="md-step-bar-right"></div>
                                                        </div>
                                                        <div className="md-step active editable">
                                                            <div className="md-step-circle"><span>✓</span></div>
                                                            <div className="step-title">PICKED UP</div>
                                                            <div className="md-step-bar-left"></div>
                                                            <div className="md-step-bar-right"></div>
                                                        </div>
                                                        <div className="md-step active">
                                                            <div className="md-step-circle"><span>✓</span></div>
                                                            <div className="step-title">IN TRANSIT</div>
                                                            <div className="md-step-bar-left"></div>
                                                            <div className="md-step-bar-right"></div>
                                                        </div>
                                                        <div className="md-step active">
                                                            <div className="md-step-circle"><span>✓</span></div>
                                                            <div className="step-title">{trackingData.current_status_formatted}</div>
                                                            <div className="md-step-bar-left"></div>
                                                        </div>
                                                    </div>
                                                }
                                                {shipmentStatus == 'exception' &&
                                                    <div className="md-stepper-horizontal orange">
                                                        <div className="md-step active done">
                                                            <div className="md-step-circle"><span>✓</span></div>
                                                            <div className="step-title">CREATED</div>
                                                            <div className="md-step-bar-right"></div>
                                                        </div>
                                                        <div className="md-step active">
                                                            <div className="md-step-circle"><span>✓</span></div>
                                                            <div className="step-title">PICKED UP</div>
                                                            <div className="md-step-bar-left"></div>
                                                            <div className="md-step-bar-right"></div>
                                                        </div>
                                                        <div className="md-step active editable">
                                                            <div className={trackingEventCircle}><span>{trackEventIcon}</span></div>
                                                            <div className="step-title">{trackingData.current_status_formatted}</div>
                                                            <div className="md-step-bar-left"></div>
                                                            <div className="md-step-bar-right transit-md-step-bar-right"></div>
                                                        </div>
                                                        <div className="md-step active">
                                                            <div className="md-step-circle transit-md-step-circle"><span>✓</span></div>
                                                            <div className="md-step-bar-left transit-md-step-bar-right"></div>
                                                        </div>
                                                    </div>
                                                }
                                            </Item>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6} md={12} style={{ padding: '15px' }}>
                                            <Item style={{ border: 'unset' }}>
                                                <h5 className="my-4" style={{ fontSize: '16px' }}>Delivery Progress</h5>
                                                {trackingData["events"] && trackingData["events"].map((text, item) => {

                                                    if (dateExist !== moment.utc(text.utc_date+" "+text.utc_time).local().format("YYYY-MM-DD") || iteration === 1) {
                                                        dateExist = moment.utc(text.utc_date+" "+text.utc_time).local().format("YYYY-MM-DD")
                                                        iteration = iteration + 1
                                                        return <>
                                                            <>
                                                                <div className="card-header" style={{ textAlign: 'left' }}>{dateExist}</div>
                                                                <ul className="list-group-flush">
                                                                    <li className="list-item">{moment.utc(text.utc_date+" "+text.utc_time).local().format("HH:mm:ss")}</li>
                                                                    <li className="list-item" style={{ width: "30%" }}>{text.city} {text.province_code}</li>
                                                                    <li className="list-item">{text.event}</li>
                                                                </ul>
                                                            </>
                                                        </>
                                                    } else {
                                                        return <>
                                                            <ul className="list-group-flush">
                                                                <li className="list-item">{moment.utc(text.utc_date+" "+text.utc_time).local().format("HH:mm:ss")} </li>
                                                                <li className="list-item" style={{ width: "30%" }}>{text.city} {text.province_code}</li>
                                                                <li className="list-item">{text.event}</li>
                                                            </ul>
                                                        </>
                                                    }
                                                    })}
                                            </Item>
                                        </Grid>
                                    </Grid>
                                </>
                            }
                        </div>
                    }
                </div>
                <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    message="Tracking url has copied to the clipboard"
                    action={action}
                />
            </Drawer>
        </>
    )
}
export default TrackingDrawer;