import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import LinearProgress from '@mui/material/LinearProgress';
import Autocomplete from "@mui/material/Autocomplete";
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';

import FormGroup from "@mui/material/FormGroup";
import { useForm } from '../../General/useForm';
import Loader from '../../General/Loader';
import { fetchCountry, fetchState } from '../../General/countryStateCitySlice';

import { fetchProfile, updateProductSettings,resetProfile } from '../Profile/profileSlice';
import Notification from '../../../pages/General/Notification'

const ProductSettingsPage = () => {
    const dispatch = useDispatch();
    const profile = useSelector(state => state.profile);
    const country = useSelector(state => state.countrystate.country);
  
    const initialValues = {
        country_of_origin: '',
        fda: '',
    }
    const [values, setValues] = useState(initialValues);

    const [errors, setErrors] = useState(initialValues);
    const filteredCountryData = country.data.filter(({ code }) => code );

    const onSelectCountryChange = (countryCode) => {
        setValues({
          ...values,
          country_of_origin: countryCode,
        });
        setErrors({ country_of_origin: "" });
      };
    const getSelectedCountry = (country_code) => {
        let filteredValue = { code: "", name: "" };
        filteredCountryData.map((key) => {
          if (key.code == country_code) {
            filteredValue = key;
          }
        });
         return filteredValue;
      };
      const handleChange = (event, type) => {
        setValues({
          ...values,
          fda: event.target.value,
        });
        setErrors({ fda: "" });
    };
    const handleSubmit = e => {
        e.preventDefault()
        if (typeof values.country_of_origin ==="undefined") {
            setErrors({
                ...errors,
                country_of_origin: "This field is required"
            })
        } else if (typeof values.fda ==="undefined") {
            setErrors({
                ...errors,
                fda: "This field is required"
            })
        }else{
            dispatch(updateProductSettings(values))
            dispatch(fetchProfile())
        }
    }
   
  
    useEffect(() => {
        dispatch(fetchCountry());
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchProfile())
    }, [dispatch])

   
    useEffect(() => {
        if (profile) {
            setValues({
                ...values,
                country_of_origin:profile && profile.profile  && profile.profile.country_of_origin,
                fda: profile && profile.profile  && profile.profile.fda,
            })
        }
    }, [profile]);
    return (
       
        <div className="sc--settings--card">
             {(profile && profile.notification && profile.notification.show) &&
            <Notification
                is_open={profile.notification.show}
                messageType={profile.notification.messageType}
                messages={profile.notification.messageContent}
                handleClose={() => dispatch(resetProfile())}
            />}
                <Card sx={{ minWidth: 1000, boxShadow: 'unset' }} className="sc--cardhead--details">
                    <CardHeader title='Product Settings' className="sc--cardhead--details" />
                    {profile && profile.loading === true &&
                        <LinearProgress />
                    }
                    <Divider />
                    <CardContent>
                    <div className="" style={{ width: "500px", margin: 'auto' }}>
                        <div className="sc--form--center">
                        <Autocomplete
                                className="help-text-error"
                                disablePortal
                                name="country_of_origin"
                                id="country-select-demo"
                                sx={{ width: "100%",margin: '8px' }}
                                options={filteredCountryData}
                                autoHighlight
                                loading={country && country.loading}
                                getOptionLabel={(option) => option.name}
                                value={getSelectedCountry(values && values.country_of_origin)}
                                renderOption={(props, option) => (
                                <MenuItem {...props} value={option.code} key={option.code}>
                                    {option.icon} {option.name}
                                </MenuItem>
                                )}
                                onChange={(event, value) => {
                                if (value !== null) {
                                    onSelectCountryChange(value.code);
                                    setErrors({ country_of_origin: "" });
                                } else {
                                    onSelectCountryChange("");
                                    setErrors({
                                        country_of_origin: "This field is required",
                                    });
                                }
                                }}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Country of Origin"
                                    variant="standard"
                                    helperText={errors.country_of_origin}
                                />
                                )}
                            />
                        </div>
                        <div
                  className="sc--form--center" style={{justifyContent:'flex-start',paddingLeft:'10px',paddingTop:'20px'}}
                >
                 <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">FDA</FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={values.fda}
                        onChange={event => handleChange(event)}
                    >
                        <FormControlLabel value="Y" control={<Radio />} label="YES" />
                        <FormControlLabel value="N" control={<Radio />} label="NO" />
                                          </RadioGroup>
                        <FormHelperText style={{color:"#d32f2f"}}>{errors.fda}</FormHelperText>
                                          
                    </FormControl>
                </div>
                        <div className="sc--form--center" style={{ margin: '15px 0' }}>
                            <Button variant="contained" onClick={handleSubmit} >Save</Button>
                        </div>
                    </div>
                    </CardContent>
                </Card>
        </div>
    )
}

export default ProductSettingsPage;
