import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { Tooltip } from "@mui/material";
import {
    DataGrid,
} from '@mui/x-data-grid';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Printer from '../../pages/General/Printer'
import ShipmentAccordion from '../../pages/General/ShipmentAccordion'
import NewOrderCreateDrawer from '../General/NewOrder/NewOrderCreateDrawer'
import Notification from '../../pages/General/Notification';
import NorowsData from '../../pages/General/NorowsData'
import OrderDetailsEditDrawer from '../../pages/General/OrderDetailsEditDrawer'
import CarrierTracking from './CarrierTracking'
import { doGetRate, resetRateState } from '../../pages/Shipments/NewShipment/rateSlice';
import Date from '../General/Date'
import { resetGenerateLabel } from '../../pages/Shipments/NewShipment/createShipmentSlice';
import { fetchCountry, fetchState, fetchMultiStates } from '../../pages/General/countryStateCitySlice';
import { resetOrderUpdateNotifications } from '../../pages/Orders/ordersSlice';

const OrderDrawer = ({ visible, reportName, formDataValues, onClose, orderRow = null, meta = null, orderId = null, invalidDocIds = null }) => {
    const dispatch = useDispatch();
    const [drawer, setDrawer] = useState(true);
    const orderDetailData = useSelector(state => state.orderdetails.data);
    const orderDetails = useSelector(state => state.orderdetails);
    const orderUpdate = useSelector(state => state.orders && state.orders.orderUpdate);
    const orders = useSelector(state => state.orders);
    const profile = useSelector(state => state.profile.profile);
    const [newOrderCreateDrawerVisible, setNewOrderCreateDrawerVisible] = useState(false);
    const [newOrderFormData, setNewOrderFormData] = useState("");
    const [isDuplicateOrder, setIsDuplicateOrder] = useState(false);
    const [isShipmentCreationFlow, setIsShipmentCreationFlow] = useState(false);
    const [choosenTrackingNumber, setChoosenTrackingNumber] = useState(0);
    const [printUrl, setPrintUrl] = useState("")
    const [isPrinted, setIsPrinted] = useState(true)
    const [isReturnOrder, setIsReturnOrder] = useState(false);
    const [orderdetailsEditDrawerVisible, setOrderdetailsEditDrawerVisible] = useState(false);


    const initialValues = {
        customer_firstname: '',
        customer_lastname:'',
        customer_email: '',
        customer_phone: '',
        receiver_first_name:'',
        receiver_last_name:'',
        address1: '',
        address2: '',
        city: '',
        province: '',
        country: '',
        zip: '',
    }
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});


    const dispatchInputChangeCallback = (name, value) => {
        setValues({
            ...values,
            [name]: value
        })
    }
    const dispatchCountryCallback = (id) => {
            setValues({
                ...values,
                ['country']: id
            })
            dispatch(fetchState(id));
    }
    const dispatchProvinceCallback = (id) => {
            setValues({
                ...values,
                ['province']: id
            })
    }
    
    useEffect(() => {
        if (orderDetails !== '') {
            let customer_firstname = "";
            let customer_lastname = "";
            let customer_email = "";
            let customer_phone = "";
            let address1 = "";
            let address2 = "";
            let province = "";
            let city="";
            let zip = "";
            let country = "";
            let receiver_first_name = "";
            let receiver_last_name = "";
            customer_firstname=orderDetailData && orderDetailData.customer_details && orderDetailData.customer_details.first_name && orderDetailData.customer_details.first_name!=='' ? orderDetailData.customer_details.first_name :""
            customer_lastname=orderDetailData && orderDetailData.customer_details && orderDetailData.customer_details.last_name && orderDetailData.customer_details.last_name!=='' ? orderDetailData.customer_details.last_name :"" 
            customer_email=orderDetailData && orderDetailData.customer_details && orderDetailData.customer_details.email && orderDetailData.customer_details.email!=='' ? orderDetailData.customer_details.email :"" 
            customer_phone=orderDetailData && orderDetailData.customer_details && orderDetailData.customer_details.phone && orderDetailData.customer_details.phone!=='' ? orderDetailData.customer_details.phone :"" 
            receiver_first_name=orderDetailData.shipping_address && orderDetailData.shipping_address.first_name ? orderDetailData.shipping_address.first_name: ""
            receiver_last_name=orderDetailData.shipping_address && orderDetailData.shipping_address.last_name ? orderDetailData.shipping_address.last_name : ""
            address1=orderDetailData.shipping_address && orderDetailData.shipping_address.address1 ? orderDetailData.shipping_address.address1: ""
            address2=orderDetailData.shipping_address && orderDetailData.shipping_address.address2 ? orderDetailData.shipping_address.address2: ""
            city=orderDetailData.shipping_address && orderDetailData.shipping_address.city ? orderDetailData.shipping_address.city : ""
            province=(orderDetailData.shipping_address && orderDetailData.shipping_address.province) ?  orderDetailData.shipping_address.province : ""
            country=orderDetailData.shipping_address && orderDetailData.shipping_address.country ? orderDetailData.shipping_address.country : ""
            zip=orderDetailData.shipping_address && orderDetailData.shipping_address.zip ? orderDetailData.shipping_address.zip : ""

            setValues({
                ...values,
                ['customer_firstname']: customer_firstname,
                ['customer_lastname']: customer_lastname,
                ['customer_email']: customer_email,
                ['customer_phone']: customer_phone,
                ['receiver_first_name']: receiver_first_name,
                ['receiver_last_name']: receiver_last_name,
                ['address1']: address1,
                ['address2']: address2,
                ['province']: province,
                ['zip']: zip,
                ['city']: city,
                ['country']: country,
            })
        }
    },[orderDetails])
    useEffect(() => {
        if ((orders && orders.createShipment && orders.createShipment.message && orders.createShipment.message !== "")|| (invalidDocIds!==null && Object.keys(invalidDocIds).length>0 && orderUpdate && orderUpdate.notification && orderUpdate.notification.messageContent && orderUpdate.notification.messageContent[0] === 'Order updated successfully')) {
            setDrawer(false)
            onClose(false)
        }
    }, [orders.createShipment.message || orderUpdate])

    const ondrawerclose = () => {
        setDrawer(false)
        onClose(false)
        dispatch(resetOrderUpdateNotifications())
        dispatch(resetRateState())
        dispatch(resetGenerateLabel())
    }
    const orderDetailsEditCallback = () => {
        setOrderdetailsEditDrawerVisible(true);
    }
    const orderDetailsEditOnClose = () => {
        setOrderdetailsEditDrawerVisible(false);
    }

    const createShipmentButton = (item) => {
        let createShipmentButtonDisable = true;
        if(item && (typeof item.line_items =='undefined' || item.line_items.length==0)&& (profile && profile.disable_create_shipment ===false || typeof profile.disable_create_shipment == "undefined")){
            createShipmentButtonDisable = false;
        }else if(item && item.line_items && item.line_items.length !== 0 && (profile && profile.disable_create_shipment ===false || typeof profile.disable_create_shipment == "undefined")) {
            item.line_items.forEach(function (key) {
                if (!key.tracking_number || key.tracking_number === "") {
                    createShipmentButtonDisable = false;
                }
            });
        }
        return createShipmentButtonDisable;
    }

    const newOrderCreateCallback = (item) => {
        setIsShipmentCreationFlow(true)
        setNewOrderFormData(item);
        setNewOrderCreateDrawerVisible(true)
    }

    const newOrderCreateOnClose = () => {
        setNewOrderCreateDrawerVisible(false);
    }

    const productinfocolumns = [
        {
            field: 'length',
            headerName: 'Length',
            sortable: false,
            flex: 1,
            resizable: false,
            renderCell: (params) => {
                if (params.row.dimensions && params.row.dimensions.length && typeof params.row.dimensions.length !== "undefined") {
                    return params.row.dimensions.length
                } else {
                    return "-"
                }
            }
        },
        {
            field: 'width',
            headerName: 'Width',
            sortable: false,
            flex: 1,
            resizable: false,
            renderCell: (params) => {
                if (params.row.dimensions && params.row.dimensions.width && typeof params.row.dimensions.width !== "undefined") {
                    return params.row.dimensions.width
                } else {
                    return "-"
                }
            }
        },
        {
            field: 'height',
            headerName: 'Height',
            sortable: false,
            flex: 1,
            resizable: false,
            renderCell: (params) => {
                if (params.row.dimensions && params.row.dimensions.height && typeof params.row.dimensions.height !== "undefined") {
                    return params.row.dimensions.height
                } else {
                    return "-"
                }
            }
        },
        {
            field: 'actual_weight',
            headerName: 'Weight',
            sortable: false,
            flex: 1,
            resizable: false,
            renderCell: (params) => {
                if (params.row.actual_weight && typeof params.row.actual_weight !== "undefined") {
                    return params.row.actual_weight
                } else {
                    return "-"
                }
            }
        },
        {
            field: 'length_unit',
            headerName: 'Dimension Unit',
            sortable: false,
            flex: 1,
            resizable: false,
            renderCell: (params) => {
                if (params.row.dimensions && params.row.dimensions.length_unit && typeof params.row.dimensions.length_unit !== "undefined") {
                    return params.row.dimensions.length_unit
                } else {
                    return "-"
                }
            }
        },
        {
            field: 'weight_unit',
            headerName: 'Weight Unit',
            sortable: false,
            flex: 1,
            resizable: false,
            renderCell: (params) => {
                if (params.row.weight_unit && typeof params.row.weight_unit !== "undefined") {
                    return params.row.weight_unit
                } else {
                    return "-"
                }
            }
        },
    ]

    const columns = [
        {
            field: 'product_id',
            headerName: 'Product Id',
            sortable: false,
            flex: 1,
            resizable: false,
            renderCell: (params) => {
                if (params.row.product_id && typeof params.row.product_id !== "undefined") {
                    return params.row.product_id
                } else {
                    return "-"
                }
            }
        },
        {
            field: 'product_desc',
            headerName: 'Product Desc',
            sortable: false,
            flex: 1,
            resizable: false,
            renderCell: (params) => {
                if (params.row.product_desc && typeof params.row.product_desc !== "undefined") {
                    return <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{params.row.product_desc}</span>
                } else {
                    return "-"
                }
            }
        },
        {
            field: 'tracking_number',
            headerName: 'Tracking Number',
            sortable: false,
            flex: 1,
            resizable: false,
            renderCell: (params) => {
                let tracking_number = (params.row.tracking_number && typeof params.row.tracking_number !== "undefined" && params.row.tracking_number !== null)
                    ? params.row.tracking_number : "";
                let carrier_name = (params.row.fulfilment_company && typeof params.row.fulfilment_company !== "undefined" && params.row.fulfilment_company !== null)
                    ? params.row.fulfilment_company : "";
                let tracking = { "tracking_number": tracking_number, "carrier_name": carrier_name }
                return (
                    <CarrierTracking trackingItems={tracking} carrierImageWidth="60px" />
                )
            }
        },
        {
            field: 'quantity',
            headerName: 'Quantity',
            sortable: false,
            flex: 1,
            resizable: false,
            renderCell: (params) => {
                if (params.row.quantity && typeof params.row.quantity !== "undefined") {
                    return params.row.quantity
                } else {
                    return "-"
                }
            }
        }
    ]

    return (
        <>
            <Printer isPrinted={isPrinted} setIsPrinted={setIsPrinted} print_url={printUrl} />
            <Drawer
                anchor="right"
                open={visible}
                onClose={ondrawerclose}
                PaperProps={{
                    sx: { width: "70%" },
                }}
            >

                {(orderUpdate && orderUpdate.notification && orderUpdate.notification.show) &&
                    <Notification
                        is_open={orderUpdate.notification.show}
                        messageType={orderUpdate.notification.messageType}
                        messages={orderUpdate.notification.messageContent}
                        handleClose={() => dispatch(resetOrderUpdateNotifications())}
                    />
                }

                {orderDetails && orderDetails.loading === true && <>
                    <Box style={{
                        position: 'absolute', left: '50%', top: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}>
                        <CircularProgress />
                    </Box>
                </>
                }
                {orderDetails && orderDetails.loading === false &&
                    <div style={{ padding: "0 30px" }}>
                        <Card sx={{ margin: '20px 0' }} className="sc--cardhead--details">
                            <CardHeader title="Order Details" action={
                                <div>
                                 <span style={{ marginRight: '15px' }}>
                                        <Tooltip title="Edit order details" placement="left-start">
                                            <Button
                                                onClick={orderDetailsEditCallback}
                                                style={{
                                                    color: createShipmentButton(orderRow) ? 'rgba(0,0,0,.54)' : '#1890ff',
                                                    cursor: createShipmentButton(orderRow) ? 'not-allowed' : 'pointer',
                                                    pointerEvents: createShipmentButton(orderRow) ? 'none' : 'auto'
                                                }}
                                            >
                                                <EditOutlinedIcon /> <span style={{ marginLeft: "5px" }}>Edit</span>
                                            </Button>
                                        </Tooltip>
                                    </span>
                                    <span style={{ marginRight: '15px' }}>
                                        <Tooltip title="Create Shipment" placement="left-start">
                                            <Button
                                                variant="outlined"
                                                disabled={createShipmentButton(orderRow)}
                                                onClick={() => newOrderCreateCallback(orderRow)}
                                                style={{
                                                    color: createShipmentButton(orderRow) ? 'rgba(0,0,0,.54)' : '#1890ff',
                                                    cursor: createShipmentButton(orderRow) ? 'not-allowed' : 'pointer',
                                                    pointerEvents: createShipmentButton(orderRow) ? 'none' : 'auto'
                                                }}
                                            >
                                                <AddCircleOutlineRoundedIcon /> <span style={{ marginLeft: "5px" }}>Label</span>
                                            </Button>
                                        </Tooltip>
                                    </span>
                                    <IconButton aria-label="settings" onClick={ondrawerclose}>
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                            } />
                            <Divider />
                        </Card>
                        <Card>
                            <CardHeader title={orderDetailData && orderDetailData.order_details && orderDetailData.order_details.orderid ? 'Order ID# :' + orderDetailData.order_details.orderid : 'Order ID# : - '} className="sc--cardhead--details" />
                            <Divider />
                            <CardContent>
                                <>
                                    <Grid container direction="row" spacing={1}>
                                        <Grid item xs={12} md={4} style={{ padding: '10px' }}>
                                            <Card sx={{ maxWidth: 1200,maxHeight:'235px',overflow:'auto'  }}>
                                                <CardHeader title='Customer Information' className="sc--cardhead--details" />
                                                <Divider />
                                                <CardContent style={{ padding: '10px 16px' }}>
                                                    <p><span style={{ fontWeight: '500' }}>Name: </span><span>{orderDetailData && orderDetailData.customer_details && orderDetailData.customer_details.first_name} {orderDetailData && orderDetailData.customer_details && orderDetailData.customer_details.last_name}</span></p>
                                                    <Divider />
                                                    <p><span style={{ fontWeight: '500' }}>Email: </span><span>{orderDetailData && orderDetailData.customer_details && orderDetailData.customer_details.email}</span></p>
                                                    <Divider />
                                                    {orderDetailData && orderDetailData.shipment_details && orderDetailData.shipment_details.length != 0 ?
                                                        <p><span style={{ fontWeight: '500' }}>Phone: </span><span>{orderDetailData && orderDetailData.shipment_details && orderDetailData.shipment_details[0] && orderDetailData.shipment_details[0].customer && orderDetailData.shipment_details[0].customer.phone}</span></p> :
                                                        <p><span style={{ fontWeight: '500' }}>Phone: </span><span>{orderDetailData && orderDetailData.customer_details && orderDetailData.customer_details.phone}</span></p>
                                                    }
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} md={4} style={{ padding: '10px' }}>
                                            <Card sx={{ maxWidth: 1200,maxHeight:'235px',overflow:'auto'  }}>
                                                <CardHeader title='Delivery Address' className="sc--cardhead--details" />
                                                <Divider />
                                                <CardContent style={{ padding: '10px 16px' }}>
                                                    <p><span style={{ fontWeight: '500' }}>Name: </span><span>{orderDetailData.shipping_address && orderDetailData.shipping_address.name}</span></p>
                                                    <Divider />
                                                    <p><span style={{ fontWeight: '500' }}>Address: </span><span>{orderDetailData.shipping_address && orderDetailData.shipping_address.address1} {orderDetailData.shipping_address && orderDetailData.shipping_address.address2} {orderDetailData.shipping_address && orderDetailData.shipping_address.city}</span></p>
                                                    <Divider />
                                                    <p><span style={{ fontWeight: '500' }}>Province / Zip: </span><span>{orderDetailData.shipping_address && orderDetailData.shipping_address.province} {orderDetailData.shipping_address && orderDetailData.shipping_address.country} {orderDetailData.shipping_address && orderDetailData.shipping_address.zip}</span></p>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} md={4} style={{ padding: '10px' }}>
                                            <Card sx={{ maxWidth: 1200,maxHeight:'235px',overflow:'auto' }}>
                                                <CardHeader title='Order Summary' className="sc--cardhead--details" />
                                                <Divider />
                                                <CardContent style={{ padding: '10px 16px' }}>
                                                    <p><span style={{ fontWeight: '500' }}>Ordered On: </span><span><Date date={orderDetailData.order_details && orderDetailData.order_details.order_date} /></span></p>
                                                    <Divider />
                                                    <p><span style={{ fontWeight: '500' }}>Platform: </span><span>{orderDetailData.platform} </span></p>
                                                    <Divider />
                                                    <p><span style={{ fontWeight: '500' }}>Order Price: </span><span>{orderDetailData.order_details && orderDetailData.order_details.total} </span></p>
                                                    <Divider />
                                                    <p><span style={{ fontWeight: '500' }}>No of Quantity: </span><span>{orderDetailData.total_quantity} </span></p>
                                                    <Divider />
                                                    <p>
                                                        <span style={{ fontWeight: '500' }}>No of Items: </span>
                                                        <span>{orderDetailData.product_details && Object.keys(orderDetailData.product_details).length}</span></p>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </>
                            </CardContent>
                        </Card>
                        {orderDetailData && orderDetailData.shipment_details && orderDetailData.shipment_details.length != 0 &&

                            <Card style={{ marginTop: '15px' }}>
                                <CardHeader title="Shipment Details" className="sc--cardhead--details" />
                                <Divider />
                                <CardContent>
                                    <ShipmentAccordion shipment={orderDetailData} setPrintUrl={setPrintUrl} setIsPrinted={setIsPrinted} />
                                </CardContent>
                            </Card>
                        }
                        {orderDetailData && orderDetailData.product_info && orderDetailData.product_info.length != 0 &&
                            < Card style={{ marginTop: '15px' }}>
                                <CardHeader title="Product Info" className="sc--cardhead--details" />
                                <Divider />
                                <CardContent className="sc--product--table">
                                    <DataGrid
                                        getRowId={(row) => row.line_item_id + Math.random()}
                                        rows={orderDetailData && orderDetailData.product_info}
                                        columns={productinfocolumns}
                                        autoHeight={true}
                                        hideFooter={true}
                                        disableRowSelectionOnClick
                                        slots={{
                                            NorowsData,
                                            loadingOverlay: LinearProgress,
                                        }}
                                        loading={
                                            (orderDetails && orderDetails.loading)
                                        }
                                    />
                                </CardContent>
                            </Card>
                        }
                        {orderDetailData && orderDetailData.product_details && orderDetailData.product_details.length != 0 &&
                            < Card style={{ marginTop: '15px' }}>
                                <CardHeader title="Product Details" className="sc--cardhead--details" />
                                <Divider />
                                <CardContent className="sc--product--table">

                                    <DataGrid
                                        getRowId={(row) => row.line_item_id + Math.random()}
                                        rows={orderDetailData && orderDetailData.product_details}
                                        columns={columns}
                                        autoHeight={true}
                                        hideFooter={true}
                                        disableRowSelectionOnClick
                                        slots={{
                                            NorowsData,
                                            loadingOverlay: LinearProgress,
                                        }}
                                        loading={
                                            (orderDetails && orderDetails.loading)
                                        }
                                    />
                                </CardContent>
                            </Card>
                        }
                        {
                            !orderDetailData.order_details &&
                            <h4 style={{ textAlign: 'center' }}>Order details not found</h4>
                        }
                    </div>
                }
                <NewOrderCreateDrawer
                    visible={newOrderCreateDrawerVisible}
                    reportName={"orders"}
                    newOrderFormData={newOrderFormData}
                    setNewOrderFormData={setNewOrderFormData}
                    meta={meta}
                    onClose={newOrderCreateOnClose}
                    isDuplicateOrder={isDuplicateOrder}
                    setNewOrderCreateDrawerVisible={setNewOrderCreateDrawerVisible}
                    choosenTrackingNumber={choosenTrackingNumber}
                    isShipmentCreationFlow={isShipmentCreationFlow}
                    isReturnOrder={isReturnOrder}
                    setIsReturnOrder={setIsReturnOrder}
                />
                {orderdetailsEditDrawerVisible &&
                    <OrderDetailsEditDrawer
                        visible={orderdetailsEditDrawerVisible}
                        onClose={orderDetailsEditOnClose}
                        values={values}
                        dispatchInputChangeCallback={dispatchInputChangeCallback}
                        errors={errors}
                        lineItemsData={orderDetailData && orderDetailData.product_details}
                        dispatchCountryCallback={dispatchCountryCallback}
                        dispatchProvinceCallback={dispatchProvinceCallback}
                        invalidDocIds={invalidDocIds}
                    />
                }
            </Drawer >
        </>
    )
}


export default OrderDrawer;