import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { styled } from "@mui/material/styles";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

// Our components
import Carrier from '../General/Carrier'
import NorowsData from '../General/NorowsData'
import Date from '../../pages/General/Date'
import Notification from '../../pages/General/Notification';
import Filters from './Filters';
import Search from './Search';
// mui components
import {
    Typography,
    FormControl,
    Pagination,
    Tooltip,
    Card,
    CardHeader,
    CardContent,
    Divider,
    IconButton
} from "@mui/material";
import {
    DataGrid,
    GridToolbarContainer
} from '@mui/x-data-grid';


import { fetchClaimsData, storeClaimsData, resetClaimsState } from './claimsSlice';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper'
};
const Input = styled("input")({
    display: "none",
  });
const ClaimsPage = () => {

    const dispatch = useDispatch();
    const claims = useSelector(state => state.claims);
    const [meta, setMeta] = useState(claims.meta);
    const [modalVisible, setModalVisible] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [fileName, setFileName] = useState("")

    const initialValues = {
        tracking_number:"",
        package_value: "",
        claim_type: "",
        claim_status: "PENDING",
        file:""
      };
      const [values, setValues] = useState(initialValues);
      const [errors, setErrors] = useState({});   
      
      const validator = (fieldValues) => {
        let temp = { ...errors }
        if ('tracking_number' in fieldValues)
            temp.tracking_number = fieldValues.tracking_number ? "" : "This field is required."
        if ('package_value' in fieldValues)
            temp.package_value = fieldValues.package_value ? "" : "This field is required."
        if ('claim_type' in fieldValues)
            temp.claim_type = fieldValues.claim_type ? "" : "This field is required."
        if(values.claim_type==='DAMAGE'){
            if ('file' in fieldValues)
                temp.file = fieldValues.file ? "" : "This field is required."
        }
        setErrors({
            ...temp
        })

        if (fieldValues === values) {
            return Object.values(temp).every(x => x == "")
        }
    }

    const onInputChange = e => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
        validator({ [name]: value })
    }
    const onFileUpload = (e) => {
        e.preventDefault();
        const { name, value } = e.target
        const objectUrl = URL.createObjectURL(e.target.files[0]);
        setFileName(objectUrl)
        setValues({
            ...values,
            [name]:e.target.files[0]
        })
        validator({ [name]: value })
      };
    const handleSubmit = e => {
        e.preventDefault()
        const isValid = validator(values)
        if (isValid) {
            if(values.claim_type=='DAMAGE'){
                var formData = new FormData();
                formData.append('file', values.file);
                formData.append("tracking_number", values.tracking_number);
                formData.append("package_value", values.package_value);
                formData.append("claim_type", values.claim_type);
                formData.append("claim_status", "PENDING");
                dispatch(storeClaimsData(formData,meta))
                onModalClose()
            }else{
                values.claim_status="PENDING"
                dispatch(storeClaimsData(values,meta))
                onModalClose()
            }
        }
    }
    const columns = [
        {
            field: 'tracking_number',
            headerName: 'Tracking number',
            sortable: false,
            headerClassName: 'super-app-theme--header',
            minWidth: 200,
        },
        {
            field: 'claim_type',
            headerName: 'Claim type',
            sortable: false,
            headerClassName: 'super-app-theme--header',
            minWidth: 200,
            renderCell: (params) => {
                if(params.row.claim_type=='LOST'){
                    return <Chip label={params.row.claim_type} style={{ color: "white",width:'120px',height:'28px' }} color="primary" />
                }else if(params.row.claim_type=='DAMAGE'){
                    return <Chip label={params.row.claim_type}  style={{ color: "white", backgroundColor: '#e52b2b',width:'120px',height:'28px' }} color="success" />
                }else{
                    return <Chip label={params.row.claim_type}  style={{ color: "white", backgroundColor: '#ffa500',width:'120px',height:'28px' }} color="success" />
                }
            }  
        },
        {
            field: 'package_value',
            headerName: <Typography style={{fontWeight:'600',fontSize:'14px'}}>Commercial Value of Package</Typography>,
            sortable: false,
            headerClassName: 'super-app-theme--header',
            align:'right',
            minWidth: 200,
            renderCell: (params) => {
                return "$"+params.row.package_value
            }
        },
        {
            field: 'claim_status',
            headerName: 'Claim Status',
            sortable: false,
            headerClassName: 'super-app-theme--header',
            minWidth: 200,
            renderCell: (params) => {
                if(params.row.claim_status=='PENDING'){
                    return <Chip label={params.row.claim_status} color="primary"  style={{ width:'120px',height:'28px' }}/>
                }else if(params.row.claim_status=='APPROVED'){
                    return <Chip label={params.row.claim_status} color="success"  style={{ width:'120px',height:'28px' }}/>
                }else if(params.row.claim_status=='DENIED'){
                    return <Chip label={params.row.claim_status} style={{ color: "white", backgroundColor: '#e52b2b',width:'120px',height:'28px'}} color="success" />
                }
            }
        },
        {
            field: 'claimsurl',
            headerName: 'Document',
            sortable: false,
            headerClassName: 'super-app-theme--header',
            minWidth: 200,
            renderCell: (params) => {
                if(params && params.row && params.row.claimsurl && params.row.claimsurl!=''){
                    return <div>
                    <span style={{ marginRight: '10px' }}><Tooltip title="View" placement="left-start">
                        <IconButton 
                            onClick={() => window.open(params.row.claimsurl, '_blank')}
                            style={{
                                color: '#1890ff',
                            }}
                            >
                            <RemoveRedEyeIcon />
                        </IconButton>
                    </Tooltip>
                    </span>
                </div>
                }else{
                    return "-"
                }
            }
        },
        {
            field: 'created_at',
            headerName: 'Claim Submitted at',
            headerClassName: 'super-app-theme--header',
            minWidth: 200,
            renderCell: (params) => {
                return <Date date={params.row.created_at} />
            }         
        },
        {
            field: 'refund_amount',
            headerName: 'Refunded Amount',
            sortable: false,
            headerClassName: 'super-app-theme--header',
            align:'right',
            minWidth: 200,
            renderCell: (params) => {
                let refundAmount=0
                if(params && params.row && params.row.refund_amount && params.row.refund_amount!=''){
                    refundAmount=params.row.refund_amount;
                }
                return "$"+refundAmount
            }
        },
        {
            field: 'invoice_number',
            headerName: 'Adjusted Invoice Number',
            sortable: false,
            headerClassName: 'super-app-theme--header',
            minWidth: 200,
            renderCell: (params) => {
                if(params && params.row && params.row.invoice_number && params.row.invoice_number!=''){
                    return params.row.invoice_number
                }else{
                    return "-"
                }
            }
        }
    ];

    const onSortChange = (currentSorting) => {
        setMeta({
            ...meta,
            ...{
                sorting: JSON.stringify(currentSorting)
            }
        });
    }

    const onChangePerPage = (event) => {
        setMeta({
            ...meta,
            ...{
                page: 1,
                per_page: event.target.value
            }
        });
    }

    const onChangePagination = (event, page) => {
        setMeta({
            ...meta,
            ...{
                page: page
            }
        });
    }

    const onMenuClick = (type, show) => {
        setShowSearch((type === "search" && show === true) ? true : false)
        setShowFilter((type === "filter" && show === true) ? true : false)
    }

    function CustomPagination() {

        return (
            <Pagination
                color="primary"
                shape="circular"
                page={claims && claims.meta && claims.meta.current_page}
                count={claims && claims.meta && claims.meta.last_page}
                onChange={onChangePagination}
            />
        );
    }

    function CustomTotalRows() {

        return (
            <div className="custom-total-rows">
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    Showing {claims && claims.data && claims.data.length} of {(claims && claims.meta && claims.meta.total) ? claims.meta.total : 0}
                </FormControl>
            </div>
        )
    }


    function CustomPagesizeoptions() {

        return (
            <div className="custom-page-size-options">
                <span>
                    Show <select
                        value={meta && meta.per_page}
                        onChange={onChangePerPage}
                    >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </select> entries
                </span>
            </div>
        )
    }

    function CustomToolbar() {

        return (
            <GridToolbarContainer>
                <CustomPagesizeoptions />
                <CustomTotalRows />
            </GridToolbarContainer>
        );
    }

    const onModalClose = () => {
        setModalVisible(false)
        setErrors({})
        setFileName("")
        setValues(initialValues)
    }


    useEffect(() => {
        dispatch(fetchClaimsData(meta));
    }, [meta]);

    return (
        <>

        <Modal
            open={modalVisible}
            onClose={onModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Card sx={{ minWidth: 600 }} className="sc--cardhead--details">
                    <CardHeader title='Submit Claim' />
                    <Divider />
                    <CardContent>
                         <div className="sc--form--center" style={{ margin: '20px 0' }}>
                            <FormControl
                                variant="standard"
                                className="help-text-error"
                                style={{ width: "100%", margin: '8px' }}
                            >
                                <InputLabel id="demo-simple-select-standard-label">Claim Type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-error"
                                    name="claim_type"
                                    defaultValue={values && values.claim_type}
                                    error={errors.claim_type}
                                    onChange={onInputChange}
                                    label="Claim Type"
                                >
                                    <MenuItem value={"LOST"}>Lost</MenuItem>
                                    <MenuItem value={"DAMAGE"}>Damage</MenuItem>
                                    <MenuItem value={"MISDELIVERY"}>Misdelivery</MenuItem>

                                </Select>
                                <FormHelperText>{errors.claim_type}</FormHelperText>
                            </FormControl>
                        </div>
                        <div className="sc--form--center" style={{ margin: '20px 0' }}>
                            <TextField
                                label="Tracking Number"
                                name="tracking_number"
                                defaultValue={values && values.tracking_number}
                                onChange={onInputChange}
                                error={errors.tracking_number}
                                helperText={errors.tracking_number}
                                style={{ width: "100%", margin: '8px' }}
                                variant="standard"
                            />

                        </div>
                        <div className="sc--form--center" style={{ margin: '20px 0' }}>
                            <TextField
                                label="Commercial Value of Package"
                                type='number'
                                name="package_value"
                                defaultValue={values && values.package_value}
                                onChange={onInputChange}
                                error={errors.package_value}
                                helperText={errors.package_value}
                                variant="standard" style={{ width: "100%", margin: '8px' }}
                            />
                        </div>
                        {values && values.claim_type =='DAMAGE' &&
                            <div className="sc--form--center" style={{ margin: '20px 0',justifyContent:'left' }}>
                            <label htmlFor="contained-button-file">
                                    <Input
                                    accept="image/*,application/pdf"
                                    id="contained-button-file"
                                    onChange={onFileUpload}
                                    multiple
                                    name="file"
                                    type="file"
                                    />
                                    <Button
                                    variant="contained"
                                    component="span"
                                    style={{ margin: "0 10px" }}
                                    >
                                    Upload damage package proof
                                    </Button>
                                    {fileName && <><br /><span style={{ fontSize: '10px' }}>Filename:{fileName}</span></>}
                                    <FormHelperText style={{color:'#d32f2f',padding:'0 10px'}}>{errors.file}</FormHelperText>
                                </label>
                            </div>
                        }
                        <div className="sc--form--center" style={{ margin: '30px 0' }}>
                            <Button variant="outlined" onClick={handleSubmit}>Submit</Button>
                        </div>
                    </CardContent>
                </Card>
            </Box>
            </Modal >
            {(claims && claims.notification && claims.notification.messageContent!='') &&
            <Notification
                is_open={claims.notification.show}
                messageType={claims.notification.messageType}
                messages={claims.notification.messageContent}
                handleClose={() => dispatch(resetClaimsState())}
            />}
            <div className="scmaincard">
                <div className="sccardhead">
                        <div  style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="h5" gutterBottom component="div">
                                Claims
                            </Typography>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <ul className="features-list">
                                <li>
                                <Tooltip title="Create new order" placement="top-start">
                                    <Button variant="outlined" onClick={() => { setModalVisible(true) }}>
                                        <AddCircleOutlineRoundedIcon  /> <span style={{ marginLeft: "8px" }}>New Claim</span>
                                    </Button>
                                </Tooltip>
                                </li>
                                <li>
                                    <Tooltip title="Seacrh" placement="top-start">
                                        <IconButton style={(showSearch) ? { backgroundColor: 'rgb(230, 230, 230)' } : {}} onClick={() => onMenuClick("search", !showSearch)}>
                                            <SearchIcon style={{ color: 'rgb(25, 118, 210)' }} />
                                        </IconButton>
                                    </Tooltip>
                                </li>
                                <li>
                                    <Tooltip title="Filter" placement="top-start">
                                        <IconButton style={(showFilter) ? { backgroundColor: 'rgb(230, 230, 230)' } : {}} onClick={() => onMenuClick("filter", !showFilter)}>
                                            <FilterAltOutlinedIcon style={{ color: 'rgb(25, 118, 210)' }} />
                                        </IconButton>
                                    </Tooltip>
                                </li>
                            </ul>
                        </div>
                </div>
                {showSearch && <Search visible={showSearch} meta={meta} setMeta={setMeta} />}
                {showFilter && <Filters visible={showFilter} meta={meta} setMeta={setMeta} />}
                <div style={{ width: '100%', background: '#fff', marginTop: "20px" }}>
                    <DataGrid
                        style={{ paddingTop: "37px"}}
                        getRowId={(row) => row._id}
                        rows={claims && claims.data}
                        columns={columns}
                        autoHeight={true}
                        disableColumnFilter={true}
                        disableColumnMenu={true}
                        sortingMode="server"
                        onSortModelChange={(newSortModel) => onSortChange(newSortModel)}
                        sortModel={meta && meta.sorting && JSON.parse(meta.sorting)}
                        disableRowSelectionOnClick
                        slots={{
                            loadingOverlay: LinearProgress,
                            pagination: CustomPagination,
                            toolbar: CustomToolbar,
                            NorowsData
                        }}
                        loading={
                            (claims && claims.loading)
                        }
                    />
                </div>
            </div >
        </>
    );
}

export default ClaimsPage;