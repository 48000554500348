import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
    Divider,
    Button,
    Card,
    CardHeader,
    CardContent,
    IconButton,
    Tooltip
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Drawer from '@mui/material/Drawer';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {
    DataGrid, gridColumnsTotalWidthSelector,
} from '@mui/x-data-grid';
import SendIcon from '@mui/icons-material/Send';
import LinearProgress from '@mui/material/LinearProgress';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import NorowsData from '../General/NorowsData'
import AddPickup from '../General/AddPickup'
import Printer from '../General/Printer'

import { fetchSenderAddress } from '../../pages/Settings/AddressSender/addressSenderSlice';
import { fetchBoxData } from '../../pages/Settings/Box/boxSlice';
import { fetchPickupDetails, dopostDataCreatePickup } from '../../pages/Reports/Pickup/pickupSlice';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    boxShadow: 'unset',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    color: theme.palette.text.secondary,
}));
const getUniqueId = () => {
    return "SCIT" + Date.now().toString(36) + Math.random().toString(36).substr(2)
}
const PickupDrawer = ({ visible, pickupOnClose, pickupItems = {} }) => {
    const dispatch = useDispatch();
    const pickup = useSelector(state => state.pickup && state.pickup.pickupData);
    const pickupCreate = useSelector(state => state.pickup && state.pickup.createPickup);
    const [meta, setMeta] = useState(pickup && pickup.meta);
    const addresssender = useSelector(state => state.addresssender);
    const profile = useSelector(state => state.auth);
    const box = useSelector(state => state.box);
    const [senderAddressObjectId, setSenderAddressObjectId] = useState('');
    const [choosenMeasurementId, setChoosenMeasurementId] = useState(0);
    const [drawer, setDrawer] = useState(true);
    const [printUrl, setPrintUrl] = useState("")
    const [isPrinted, setIsPrinted] = useState(true)

    const [addPickupItemVisible, setAddPickupItemVisible] = useState(false)
    const [removePickupItemId, setRemovePickupItemId] = useState("")
    const [editPickupItemId, setEditPickupItemId] = useState("")
    const [customPickupItems, setCustomPickupItems] = useState([])
    const [addPickupItemData, setAddPickupItemData] = useState({
        "pickup_item_id": getUniqueId(),
        "length": "",
        "width": "",
        "height": "",
        "weight": "",
        "dimension_unit": "in",
        "weight_unit": "lb",
        "choosemeasurement": "",
        "edit_pickup_item_id": "",
        "quantity": "",
        "package_dimension": "",
        "preferred_sender_id": ''
    })
    const [pickupItemErrors, setPickupItemsErrors] = useState({
        "pickup_item_id": "",
        "length": "",
        "width": "",
        "height": "",
        "weight": "",
        "dimension_unit": "",
        "weight_unit": "",
        "choosemeasurement": "",
        "quantity": "",
        "package_dimension": "",
        "preferred_sender_id": ''
    });

    const isIntegerValue = (value) => {
        value = Number(value);
        if (Number.isInteger(value)) {
            return true;
        }
        return false;
    }

    const validatePickupItemData = (data) => {
        let isValid = true
        let pickupItemErrorsTemp = {
            "pickup_item_id": "",
            "length": "",
            "width": "",
            "height": "",
            "dimension_unit": "",
            "weight": "",
            "weight_unit": "",
            "choosemeasurement": "",
            "quantity": "",
            "package_dimension": "",
            "preferred_sender_id": ''
        };

        if (data.length == "" || Number(data.length) <= 0) {
            isValid = false
            pickupItemErrorsTemp.length = "Length is required and should be valid";
        }
        if (data.width == "" || Number(data.width) <= 0) {
            isValid = false
            pickupItemErrorsTemp.width = "Width is required and should be valid";
        }

        if (data.height == "" || Number(data.height) <= 0) {
            isValid = false
            pickupItemErrorsTemp.height = "Height is required and should be valid";
        }

        if (data.weight == "" || Number(data.weight) <= 0) {
            isValid = false
            pickupItemErrorsTemp.weight = "Weight is required and should be valid";
        }

        if (data.quantity == "" || Number(data.quantity) <= 0 || !Number.isInteger(Number(data.quantity))) {
            isValid = false
            pickupItemErrorsTemp.quantity = "Quantity is required and should be valid";
        }

        setPickupItemsErrors(pickupItemErrorsTemp)
        return isValid
    }



    const initialColumns = [
        {
            field: 'package_dimension',
            headerName: 'Package Dimension',
            headerClassName: 'super-app-theme--header',
            sortable: false,
            flex: 1,
            resizable: false,
            renderCell: (params) => {
                if (params.row.package_dimension) {
                    return params.row.package_dimension
                } else {
                    return "-"
                }
            }
        },
        {
            field: 'dimension_unit',
            headerName: 'Dimension Unit',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            resizable: false,
            renderCell: (params) => {
                if (params.row.dimension_unit) {
                    return params.row.dimension_unit
                } else {
                    return "-"
                }
            }
        },
        {
            field: 'weight',
            headerName: 'Weight',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            resizable: false,
            renderCell: (params) => {
                if (params.row.weight) {
                    return params.row.weight
                } else {
                    return "-"
                }
            }
        },
        {
            field: 'weight_unit',
            headerName: 'Weight Unit',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            resizable: false,
            renderCell: (params) => {
                if (params.row.weight_unit) {
                    return params.row.weight_unit
                } else {
                    return "-"
                }
            }
        },
        {
            field: 'quantity',
            headerName: 'Quantity',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            resizable: false,
            renderCell: (params) => {
                if (params.row.quantity) {
                    return params.row.quantity
                } else {
                    return "-"
                }
            }
        },
        {
            field: 'action',
            headerName: 'Action',
            headerClassName: 'super-app-theme--header',
            width: 100,
            resizable: false,
            hide: (customPickupItems.length == 0),
            renderCell: (params) => {
                if (params.row && params.row.pickup_item_id && params.row.pickup_item_id != "") {
                    return <>
                        <span>
                            <Tooltip title="Edit" placement="left-start" disableFocusListener={false}>
                                <IconButton>
                                    <EditIcon style={{ color: 'rgba(0,0,0,.54)', cursor: 'pointer' }} onClick={() => setEditPickupItemId(params.row.pickup_item_id)} />
                                </IconButton>
                            </Tooltip>
                        </span>
                        <span>
                            <Tooltip title="Delete" placement="left-start" disableFocusListener={false}>
                                <IconButton>
                                    <DeleteOutlineOutlinedIcon style={{ color: 'rgba(0,0,0,.54)', cursor: 'pointer' }}
                                        onClick={() => setRemovePickupItemId(params.row.pickup_item_id)}
                                    />
                                </IconButton>
                            </Tooltip>
                        </span>
                    </>;
                } else {
                    return <>-</>;
                }
            }

        }
    ];

    const [columns, setColumns] = useState(initialColumns)

    const handlePackageDimensionInputChange = (event) => {
        if (event.target.name == "choosenmeasurement") {
            let pickupItemErrorsInitial = {
                "pickup_item_id": "",
                "length": "",
                "width": "",
                "height": "",
                "dimension_unit": "",
                "weight": "",
                "weight_unit": "",
                "choosemeasurement": "",
                "quantity": "",
                "package_dimension": "",
                "preferred_sender_id": ''
            };
            if (event.target.value != 0) {
                box && box.data && box.data.map((boxData) => {
                    if (boxData._id === event.target.value) {
                        setAddPickupItemData({
                            ...addPickupItemData,
                            ["pickup_item_id"]: getUniqueId(),
                            ['length']: parseFloat(boxData.box_length).toFixed(2),
                            ['width']: parseFloat(boxData.box_length).toFixed(2),
                            ['height']: parseFloat(boxData.box_length).toFixed(2),
                            ['weight']: parseFloat(boxData.box_length).toFixed(2),
                            ['weight_unit']: boxData.box_weight_unit,
                            ['dimension_unit']:boxData.box_length_unit,
                            ["choosemeasurement"]: event.target.value
                        })
                        setPickupItemsErrors(pickupItemErrorsInitial)
                    }
                })
            } else {
                setAddPickupItemData({
                    "pickup_item_id": getUniqueId(),
                    "length": "",
                    "width": "",
                    "height": "",
                    "dimension_unit": "in",
                    "edit_pickup_item_id": "",
                    "weight": "",
                    "weight_unit": "lb",
                    "choosemeasurement": "0",
                    "quantity": "",
                    "package_dimension": "",
                })
                setPickupItemsErrors(pickupItemErrorsInitial)
            }
        }
    }
    const dispatchSenderAddressCallback = (id) => {
        setSenderAddressObjectId(id)
        setAddPickupItemData({
            ...addPickupItemData,
            ['preferred_sender_id']: id,
        })

        setPickupItemsErrors({ "preferred_sender_id": "" })
    }
    const formDataHandler = e => {
        const { name, value } = e.target
        setAddPickupItemData({
            ...addPickupItemData,
            [name]: value
        })
        validatePickupItemData({
            ...addPickupItemData,
            [name]: value
        });
    }

    const onAddPickupModalClose = () => {
        setRemovePickupItemId("")
        setEditPickupItemId("")
        setAddPickupItemData({
            "pickup_item_id": getUniqueId(),
            "length": "",
            "width": "",
            "height": "",
            "dimension_unit": "in",
            "weight": "",
            "weight_unit": "lb",
            "choosemeasurement": "",
            "edit_pickup_item_id": "",
            "quantity": "",
            "package_dimension": "",
        })
        setPickupItemsErrors({
            "pickup_item_id": "",
            "length": "",
            "width": "",
            "height": "",
            "weight": "",
            "dimension_unit": "",
            "weight_unit": "",
            "choosemeasurement": "",
            "quantity": "",
            "package_dimension": ""
        })
        setAddPickupItemVisible(false)
    }
    const onAddPickupItemClick = () => {
       
        if (validatePickupItemData(addPickupItemData)) {
           
            let customPickupItemData = [...customPickupItems];
            if (addPickupItemData.edit_pickup_item_id == "") {
                setAddPickupItemData({
                    ...addPickupItemData,
                    ['package_dimension']: parseFloat(addPickupItemData.length).toFixed(2) + "x" + parseFloat(addPickupItemData.width).toFixed(2) + "x" + parseFloat(addPickupItemData.height).toFixed(2)
                })
                customPickupItemData.push(addPickupItemData)
                setCustomPickupItems(customPickupItemData)
                setAddPickupItemData({
                    "pickup_item_id": getUniqueId(),
                    "length": "",
                    "width": "",
                    "height": "",
                    "weight": "",
                    "dimension_unit": "",
                    "weight_unit": "",
                    "choosemeasurement": "",
                    "quantity": "",
                    "package_dimension": ""
                })
            } else if (addPickupItemData.edit_pickup_item_id != "") {
                setAddPickupItemData({
                    ...addPickupItemData,
                    ['package_dimension']: addPickupItemData.length + "x" + addPickupItemData.width + "x" + addPickupItemData.height
                })
                let customPickupItemData = [...customPickupItems];
                let newPickupItemData = [];
                customPickupItemData.map((item) => {
                    if (item && item.pickup_item_id && item.pickup_item_id == addPickupItemData.edit_pickup_item_id) {
                        newPickupItemData.push(addPickupItemData)
                    } else {
                        newPickupItemData.push(item)
                    }
                })
                setCustomPickupItems(newPickupItemData)
            }
            onAddPickupModalClose()

        } else {
            console.log("Errors in line items")
        }
    }
    const handleSubmit = (e) => {
        let apikey = (profile.profile.api_token) ? profile.profile.api_token : "";
        let finalData = {}
        finalData = {
            "sender_id": senderAddressObjectId,
            "items": customPickupItems
        }
        dispatch(dopostDataCreatePickup(finalData, apikey,meta))
    }

    const ondrawerclose = () => {
        setCustomPickupItems([])
        setDrawer(false)
        pickupOnClose(false)
    }

    useEffect(() => {
       
        customPickupItems.forEach(object => {
            object.package_dimension =parseFloat(object.length).toFixed(2) + "x" + parseFloat(object.width).toFixed(2) + "x" + parseFloat(object.height).toFixed(2);
          });
        setColumns(initialColumns)
    }, [customPickupItems])

    useEffect(() => {
        if (removePickupItemId != "" && removePickupItemId != "undefined") {
            let customPickupItemData = [...customPickupItems];
            let newPickupItemData = [];
            customPickupItemData.map((item) => {
                if (item && item.pickup_item_id && item.pickup_item_id != removePickupItemId) {
                    newPickupItemData.push(item)
                }
            })
            setCustomPickupItems(newPickupItemData)
        }
    }, [removePickupItemId])

    useEffect(() => {
        if (editPickupItemId != "" && editPickupItemId != "undefined" && !_.isUndefined(_.find(customPickupItems, ['pickup_item_id', editPickupItemId]))) {
            console.log("Found edit")
            let editData = _.find(customPickupItems, ['pickup_item_id', editPickupItemId])
            editData[`edit_pickup_item_id`] = editPickupItemId
            setAddPickupItemData(editData)
            setAddPickupItemVisible(true)
        }
    }, [editPickupItemId])

    useEffect(() => {
        if (addresssender && addresssender.data) {
            addresssender.data.map((item) => {
                if (item && item.default) {
                    setSenderAddressObjectId(item._id)
                    setAddPickupItemData({
                        ...addPickupItemData,
                        ['preferred_sender_id']: item._id,
                    })
                }
            })
        }
    }, [addresssender]);

    useEffect(() => {
        dispatch(fetchSenderAddress());
        dispatch(fetchBoxData(false));
    }, [dispatch]);

    useEffect(() => {
        if (pickupCreate && pickupCreate.notification && pickupCreate.notification.messageContent
            && pickupCreate.notification.messageContent[0] === 'Pickup Created Successfully') {
                if(pickupCreate && pickupCreate.message && pickupCreate.message.label_url_direct){
                    setPrintUrl(pickupCreate.message.label_url_direct)
                    setIsPrinted(false)
                    ondrawerclose()
                }
        }
    }, [pickupCreate]);
    return (
        <>
            <Printer isPrinted={isPrinted} setIsPrinted={setIsPrinted} print_url={printUrl} />

            <Drawer
                anchor="right"
                open={visible}
                onClose={ondrawerclose}
                PaperProps={{
                    sx: { width: "60%" },
                }}
            >

                <div style={{ padding: "0 30px" }}>

                    <Card sx={{ margin: '20px 0' }} className="sc--cardhead--details">
                        <CardHeader title="Pickup Drawer" action={
                            <div>
                                <IconButton aria-label="settings" onClick={ondrawerclose}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        } />
                         {(pickupCreate && pickupCreate.loading === true) &&
                            <LinearProgress />
                        }
                        <Divider />
                    </Card>
                    <CardContent>
                        <Paper variant='outlined'>
                            <Card
                                sx={{ minWidth: 275, margin: '0px 0', boxShadow: 'unset' }}
                                className="sc--cardhead--details">
                                <CardHeader title='Pickup Address Details' />
                                <Divider />
                                <CardContent>
                                    <div className="sc--form--center" style={{ margin: '8px 0', padding: "0 70px" }}>
                                        <FormControl
                                            variant="standard"
                                            style={{ width: "68ch", margin: '8px' }} >
                                            <InputLabel
                                                id="demo-simple-select-standard-label"
                                            >Pickup Address
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                name="preferred_sender_id"
                                                value={senderAddressObjectId}
                                                error={pickupItemErrors.preferred_sender_id}
                                                onChange={e => {
                                                    dispatchSenderAddressCallback(e.target.value)
                                                    setPickupItemsErrors({ "preferred_sender_id": "" })
                                                }}
                                                label="Pickup Address"
                                            >
                                                {addresssender && addresssender.data && addresssender.data.map((item) => {
                                                    return (
                                                        <MenuItem value={item._id}>
                                                            <p style={{ margin: 'unset' }}>{item.sender_name} ({item.sender_company})<br />
                                                                {item.sender_address}, {item.sender_city}, {item.sender_state_code}, {item.sender_country_code} - {item.sender_postal_code}
                                                            </p>
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>

                                            <FormHelperText>
                                                {(addresssender && addresssender.loading === false)
                                                    && addresssender && addresssender.data && addresssender.data.length == 0 &&
                                                    <span style={{ color: "red" }}>
                                                        Please add pickup address
                                                        <Link to="/settings/address-sender" style={{ color: 'blue', textDecoration: 'none' }}> here </Link>
                                                    </span>
                                                }
                                                {/* {errors.preferred_sender_id && addresssender && addresssender.data && addresssender.data.length !== 0 &&
                                                    <span style={{ color: "red" }}>
                                                        This field is required.
                                                    </span>
                                                } */}
                                            </FormHelperText>
                                        </FormControl>
                                    </div>
                                </CardContent>
                            </Card>

                        </Paper>
                        <Card
                            sx={{ minWidth: 275, boxShadow: 'unset', marginTop: "30px", border: '1px solid rgba(0, 0, 0, 0.12)' }}
                            className="sc--cardhead--details">
                            <CardHeader
                                title='Package Details'
                                action={
                                    <>
                                        <Button variant="outlined" aria-label="settings" onClick={() => setAddPickupItemVisible(true)}>
                                            Add Package
                                        </Button>
                                    </>
                                }
                            />
                            <Divider />
                            <CardContent>
                                {addPickupItemVisible &&
                                    <AddPickup
                                        addPickupItemVisible={addPickupItemVisible}
                                        onAddPickupModalClose={onAddPickupModalClose}
                                        formDataHandler={formDataHandler}
                                        onAddPickupItemClick={onAddPickupItemClick}
                                        addPickupItemData={addPickupItemData}
                                        editPickupItemId={editPickupItemId}
                                        handlePackageDimensionInputChange={handlePackageDimensionInputChange}
                                        errors={pickupItemErrors}
                                    />
                                }
                                <div style={{ width: '100%', background: '#fff', marginTop: "20px" }}>
                                    <DataGrid
                                        getRowId={(row) => Math.random() + Math.random()}
                                        rows={customPickupItems}
                                        columns={columns}
                                        autoHeight={true}
                                        disableColumnFilter={true}
                                        disableColumnMenu={true}
                                        sortingMode="server"
                                        pageSize={5}
                                        disableRowSelectionOnClick
                                        slots={{
                                            NorowsData,
                                        }}
                                        loading={
                                            (pickup && pickup.loading)
                                        }
                                    />
                                </div>
                            </CardContent>
                        </Card>
                        <div className="sc--form--center" style={{ margin: '30px 50px' }}>
                            <LoadingButton
                                            variant="outlined"
                                            onClick={e => handleSubmit(e)}
                                            disabled={customPickupItems && customPickupItems.length!=0 ? false:true}
                                            loading={
                                                pickupCreate && pickupCreate.loading}
                                        >
                                            <SendIcon style={{
                                                paddingRight: '10px'
                                            }} />  Create Pickup
                            </LoadingButton>
                        </div>
                    </CardContent>
                </div>

            </Drawer >
        </>
    )
}


export default PickupDrawer;