import React from "react";
import Avatar from '@mui/material/Avatar';
import { deepOrange, deepPurple } from '@mui/material/colors';

const Customer = ({ name = "", avatar = null }) => {
    if (name !== "" && name !== null && typeof name !== "undefined" && name.trim() !== "" && 'null' != name) {
        // name = decodeURIComponent(name);
        function stringToColor(string) {
            let hash = 0;
            let i;

            /* eslint-disable no-bitwise */
            for (i = 0; i < string.length; i += 1) {
                hash = string.charCodeAt(i) + ((hash << 5) - hash);
            }

            let color = '#';

            for (i = 0; i < 3; i += 1) {
                const value = (hash >> (i * 8)) & 0xff;
                color += `00${value.toString(16)}`.substr(-2);
            }
            /* eslint-enable no-bitwise */

            return color;
        }

        function stringAvatar(name) {
            return {
                sx: {
                    bgcolor: stringToColor(name),
                },
                children: `${(typeof name.split(' ')[0][0] !== "undefined") ? name.split(' ')[0][0].toUpperCase() : ""}`+`${(name.split(' ').length > 1 && typeof name.split(' ')[1][0] !== "undefined") ? name.split(' ')[1][0].toUpperCase() : ""}`,
            };
        }
        return (
            <>
                {!avatar && <div style={{ display: 'flex', alignItems: 'center' }}><span><Avatar sx={{ bgcolor: deepPurple[500] }} {...stringAvatar(name)} /></span><span style={{ paddingLeft: '10px',textTransform:'capitalize' }}>{name}</span></div>}
            </>
        )
    } else {
        return (
            <>
                <div className="user-card"><div className="user-avatar sm"><span></span></div><div className="user-name"><span className="tb-lead"></span></div></div>
            </>
        )
    }

}
export default Customer;