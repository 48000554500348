import { createSlice } from '@reduxjs/toolkit';
import { OMSAPI } from '../../../../../API';
import { fetchIntegratedPlatform } from '../../integratedSlice';

export const skulabsslice = createSlice({
    name: 'skulabs',
    initialState: {
        progress: {
            data: {
                access_token: "",
                is_update_shipment: false,
                is_update_shipment_on_create: false,
                is_update_shipment_on_close: true,
            },
            message: "",
            loading: false,
            hasErrors: false
        },
        configure: {
            data: {},
            message: "",
            loading: false,
            hasErrors: false
        }
    },
    reducers: {
        fetchSkulabsDataDetails: (state) => {
            return {
                ...state,
                progress: {
                    loading: true
                }
            }
        },
        fetchSkulabsDataDetailsSuccess: (state, action) => {
            return {
                ...state,
                progress: {
                    message: action && action.payload && action.payload.data && action.payload.data.message,
                    data: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data,
                    loading: false,
                    hasErrors: false,
                }
            }
        },
        fetchSkulabsDataDetailsFailure: (state, action) => {
            return {
                ...state,
                progress: {
                    loading: false,
                    hasErrors: false,
                }
            }
        },
        postSkulabsConfigure: (state) => {
            return {
                ...state,
                configure: {
                    loading: true
                }
            }
        },
        postSkulabsConfigureSuccess: (state, action) => {
            return {
                ...state,
                configure: {
                    data: {},
                    message: action && action.payload && action.payload.data && action.payload.data.message,
                    loading: false,
                    hasErrors: false,
                }
            }
        },
        postSkulabsConfigureFailure: (state, action) => {
            return {
                ...state,
                configure: {
                    message: action && action.payload && action.payload.data && action.payload.data.message,
                    loading: false,
                    hasErrors: true,
                }
            }
        },
        resetConfigureStateDetails: (state, action) => {
            return {
                ...state,
                configure: {
                    data: {},
                    message: '',
                    loading: false,
                    hasErrors: false,
                }
            }
        },
        resetSkulabsStateDetails: (state, action) => {
            return {
                ...state,
                progress: {
                    data: {
                        access_token: "",
                        is_update_shipment: false,
                        is_update_shipment_on_create: false,
                        is_update_shipment_on_close: true,
                    },
                    message: "",
                    loading: false,
                    hasErrors: false
                },
                configure: {
                    data: {},
                    message: '',
                    loading: false,
                    hasErrors: false,
                }
            }
        },
    }
})
export const { fetchSkulabsDataDetails, fetchSkulabsDataDetailsSuccess, fetchSkulabsDataDetailsFailure,
    postSkulabsConfigure, postSkulabsConfigureSuccess, postSkulabsConfigureFailure, resetConfigureStateDetails, resetSkulabsStateDetails } = skulabsslice.actions;

export const configureStore = data => dispatch => {
    dispatch(postSkulabsConfigure());
    try {
        OMSAPI.post('/skulabs/configure', data)
            .then(function (response) {
                dispatch(postSkulabsConfigureSuccess(response));
                dispatch(fetchIntegratedPlatform());
            }).catch(function (error) {
                dispatch(postSkulabsConfigureFailure(error.response));
            })
    }
    catch (error) {
        dispatch(postSkulabsConfigureFailure(error.response));
    }
}

export const fetchSkulabsData = data => dispatch => {
    dispatch(fetchSkulabsDataDetails())
    try {
        OMSAPI.post('/skulabs/show', data)
            .then(function (response) {
                dispatch(fetchSkulabsDataDetailsSuccess(response));
            }).catch(function (error) {
                dispatch(fetchSkulabsDataDetailsFailure());
            })
    }
    catch (error) {
        dispatch(fetchSkulabsDataDetailsFailure())
    }
}

export const resetConfigureState = data => dispatch => {
    dispatch(resetConfigureStateDetails());
}

export const resetSkulabsState = data => dispatch => {
    dispatch(resetSkulabsStateDetails());
}
export default skulabsslice.reducer;