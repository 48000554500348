import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} {...props} />;
});

const Notification = ({ is_open, messageType, handleClose, messages }) => {
  return <><Snackbar autoHideDuration={2500} open={is_open} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
    <Alert variant="filled" severity={messageType} sx={{ width: '100%' }}>
    {messages && messages.length !== 0 && typeof messages[0] !== "object" && messages.map(function (value) {
        return <>{value}<br /></>
      })
      }
      {messages && messages.length !== 0 && typeof messages[0] === "object" && messages.map((key, value) => { 
        return <>{key.error}<br /></>
      })
      }
    </Alert>
  </Snackbar>
  </>;
}

export default Notification;