import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { DataGrid } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Snackbar from "@mui/material/Snackbar";
import Tooltip from "@mui/material/Tooltip";
import Alert from "@mui/material/Alert";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import Autocomplete from "@mui/material/Autocomplete";
import FormControlLabel from "@mui/material/FormControlLabel";
import SettingsStatus from "../../../pages/General/SettingsStatus";
import {
  fetchSenderAddress,
  destorySenderAddress,
  storeSenderAddress,
  updateSenderAddress,
  resetSenderAddressState,
} from "./addressSenderSlice";
import {
  fetchCountry,
  fetchState,
} from "../../../pages/General/countryStateCitySlice";

import NorowsData from "../../../pages/General/NorowsData";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
};
const deleteModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
const Modalpopup = ({
  modalVisible,
  setModalVisible,
  formValues,
  setFormValues,
  snackbar,
  buttonStatus
}) => {
  const dispatch = useDispatch();
  const addresssender = useSelector((state) => state.addresssender);
  const country = useSelector((state) => state.countrystate.country);
  const state = useSelector((state) => state.countrystate.state);
  const filteredCountryData = country.data.filter(({ code }) => code);
  console.log(formValues)
  const initialSenderAddressValues = {
    sender_name: "",
    sender_address: "",
    sender_address2: "",
    sender_city: "",
    sender_company: "",
    sender_country_code: "",
    sender_email: "",
    sender_phone: "",
    sender_postal_code: "",
    sender_state_code: "",
    status: true,
    default: "",
  };

  const [values, setValues] = useState(
    formValues && Object.keys(formValues).length === 0
      ? initialSenderAddressValues
      : formValues
  );
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (formValues && formValues.sender_country_code) {
      dispatch(fetchState(formValues && formValues.sender_country_code));
    }
  }, [formValues]);

  useEffect(() => {
    if (formValues && Object.keys(formValues).length !== 0) {
        if(buttonStatus){
            setValues({
                ...values,
                sender_name: "",
                sender_address: "",
                sender_address2: "",
                sender_city: "",
                sender_company: "",
                sender_country_code: "",
                sender_email: "",
                sender_phone: "",
                sender_postal_code: "",
                sender_state_code: "",
                status: true,
                default: "",
            })
        }else{
            let tempValues = {
                sender_name: formValues["sender_name"],
                sender_address: formValues["sender_address"],
                sender_address2: formValues["sender_address2"],
                sender_city: formValues["sender_city"],
                sender_company: formValues["sender_company"],
                sender_country_code: formValues["sender_country_code"],
                sender_email: formValues["sender_email"],
                sender_phone: formValues["sender_phone"],
                sender_postal_code: formValues["sender_postal_code"],
                sender_state_code: formValues["sender_state_code"],
                status: formValues["status"],
                default: formValues["default"],
            };
            setValues(tempValues);
            setErrors({});
        }
    }else{
        setValues({
            ...values,
            sender_name: "",
            sender_address: "",
            sender_address2: "",
            sender_city: "",
            sender_company: "",
            sender_country_code: "",
            sender_email: "",
            sender_phone: "",
            sender_postal_code: "",
            sender_state_code: "",
            status: true,
            default: "",
        })
    }
  }, [formValues]);

  const onModalClose = () => {
    setModalVisible(false);
  };

  const validator = (fieldValues) => {
    let temp = { ...errors };
    if ("sender_name" in fieldValues)
      temp.sender_name = fieldValues.sender_name
        ? ""
        : "This field is required.";
    if (
      fieldValues &&
      fieldValues.sender_name &&
      fieldValues.sender_name.length < 3
    )
      temp.sender_name = "Sender Name required minimum 3 characters";
    if ("sender_address" in fieldValues)
      temp.sender_address = fieldValues.sender_address
        ? ""
        : "This field is required.";
    if ("sender_city" in fieldValues)
      temp.sender_city = fieldValues.sender_city
        ? ""
        : "This field is required.";
    if ("sender_company" in fieldValues)
      temp.sender_company = fieldValues.sender_company
        ? ""
        : "This field is required.";
    if ("sender_country_code" in fieldValues)
      temp.sender_country_code = fieldValues.sender_country_code
        ? ""
        : "This field is required.";
    if ("sender_email" in fieldValues)
      temp.sender_email = fieldValues.sender_email
        ? ""
        : "This field is required.";
    if ("sender_phone" in fieldValues)
      temp.sender_phone = fieldValues.sender_phone
        ? ""
        : "This field is required.";
    if (
      fieldValues &&
      fieldValues.sender_phone &&
      fieldValues.sender_phone.match(/[^0-9+().,]/i)
    ) {
      temp.sender_phone = "phone number must be valid";
    }
    if ("sender_postal_code" in fieldValues)
      temp.sender_postal_code = fieldValues.sender_postal_code
        ? ""
        : "This field is required.";
    if (
      (fieldValues &&
        fieldValues.sender_postal_code &&
        fieldValues.sender_postal_code.length < 3) ||
      (fieldValues &&
        fieldValues.sender_postal_code &&
        fieldValues.sender_postal_code === null)
    )
      temp.sender_postal_code =
        "Sender Postalcode required minimum 3 characters";
    if (
      fieldValues &&
      fieldValues.sender_postal_code &&
      fieldValues.sender_postal_code.match(/[^0-9a-z-\s]/i)
    ) {
      temp.sender_postal_code = "Postal code only accepts alpha numeric";
    }
    if ("sender_state_code" in fieldValues)
      temp.sender_state_code = fieldValues.sender_state_code
        ? ""
        : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x == "");
    }
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    validator({ [name]: value });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: e.target.checked,
    });
    validator({ [name]: value });
  };

  const onSelectCountryChange = (countryCode) => {
    setValues({
      ...values,
      sender_country_code: countryCode,
    });
    setErrors({ sender_country_code: "" });
    dispatch(fetchState(countryCode));
  };

  const getSelectedCountry = (country_code) => {
    let filteredValue = { code: "", name: "" };
    filteredCountryData.map((key) => {
      if (key.code == country_code) {
        filteredValue = key;
      }
    });
     return filteredValue;
  };

  const onSelectStateChange = (currentstate) => {
    setValues({
      ...values,
      sender_state_code: currentstate,
    });
    setErrors({ sender_state_code: "" });
  };

  const getSelectedState = (currentstate) => {
    let filteredStateValue = { code: "", name: "" };
    let tempState = "";
    state &&
      state.data &&
      state.data.map((key) => {
        if (key.code == currentstate && tempState == "") {
          filteredStateValue = key;
          tempState = "success";
        }
      });
    return filteredStateValue;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = validator(values);
    if (isValid) {
      if ("_id" in formValues) {
        dispatch(updateSenderAddress(formValues._id, values));
        snackbar(true);
      } else {
        dispatch(storeSenderAddress(values));
        snackbar(true);
      }
    }
  };
  useEffect(() => {
    if (addresssender && addresssender.message) {
      if (addresssender && addresssender.messageType == "success") {
        setValues(initialSenderAddressValues);
        setErrors({});
      }
    }
  }, [addresssender]);

  return (
    <Modal
      open={modalVisible}
      onClose={onModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Card sx={{ minWidth: 600 }} className="sc--cardhead--details">
          <CardHeader title="Sender Address" />
          <Divider />
          <CardContent>
            <div className="sc--form--center" style={{ margin: "20px 0" }}>
              <TextField
                label="Name"
                name="sender_name"
                defaultValue={formValues && formValues.sender_name}
                onChange={onInputChange}
                error={errors.sender_name}
                helperText={errors.sender_name}
                inputProps={{ maxLength: 40 }}
                style={{ width: "100%", margin: "8px" }}
                variant="standard"
              />
              <TextField
                label="Company"
                name="sender_company"
                defaultValue={formValues && formValues.sender_company}
                onChange={onInputChange}
                error={errors.sender_company}
                helperText={errors.sender_company}
                inputProps={{ maxLength: 40 }}
                variant="standard"
                style={{ width: "100%", margin: "8px" }}
              />
            </div>
            <div className="sc--form--center" style={{ margin: "20px 0" }}>
              <TextField
                label="Phone"
                name="sender_phone"
                defaultValue={formValues && formValues.sender_phone}
                onChange={onInputChange}
                error={errors.sender_phone}
                inputProps={{ maxLength: 13 }}
                helperText={errors.sender_phone}
                variant="standard"
                style={{ width: "100%", margin: "8px" }}
              />
              <TextField
                label="Email"
                name="sender_email"
                defaultValue={formValues && formValues.sender_email}
                onChange={onInputChange}
                error={errors.sender_email}
                inputProps={{ maxLength: 60 }}
                helperText={errors.sender_email}
                variant="standard"
                style={{ width: "100%", margin: "8px" }}
              />
            </div>
            <div className="sc--form--center" style={{ margin: "20px 0" }}>
              <TextField
                label="Address1"
                name="sender_address"
                defaultValue={formValues && formValues.sender_address}
                onChange={onInputChange}
                error={errors.sender_address}
                inputProps={{ maxLength: 35 }}
                helperText={errors.sender_address}
                variant="standard"
                style={{ width: "100%", margin: "8px" }}
              />
              <TextField
                label="Address2"
                name="sender_address2"
                defaultValue={formValues && formValues.sender_address2}
                onChange={onInputChange}
                error={errors.sender_address2}
                inputProps={{ maxLength: 35 }}
                helperText={errors.sender_address2}
                variant="standard"
                style={{ width: "100%", margin: "8px" }}
              />
            </div>
            <div className="sc--form--center" style={{ margin: "20px 0" }}>
              <TextField
                label="Postal Code"
                name="sender_postal_code"
                defaultValue={formValues && formValues.sender_postal_code}
                onChange={onInputChange}
                inputProps={{ maxLength: 6 }}
                error={errors.sender_postal_code}
                helperText={errors.sender_postal_code}
                variant="standard"
                style={{ width: "100%", margin: "8px" }}
              />
              <TextField
                label="City"
                name="sender_city"
                defaultValue={formValues && formValues.sender_city}
                onChange={onInputChange}
                error={errors.sender_city}
                inputProps={{ maxLength: 30 }}
                helperText={errors.sender_city}
                variant="standard"
                style={{ width: "100%", margin: "8px" }}
              />
            </div>
            <div className="sc--form--center" style={{ margin: "20px 0" }}>
              <Autocomplete
                className="help-text-error"
                disablePortal
                name="sender_country_code"
                id="country-select-demo"
                sx={{ width: "100%",margin: '8px' }}
                options={filteredCountryData}
                autoHighlight
                loading={country && country.loading}
                getOptionLabel={(option) => option.name}
                value={getSelectedCountry(values && values.sender_country_code)}
                renderOption={(props, option) => (
                  <MenuItem {...props} value={option.code} key={option.code}>
                    {option.icon} {option.name}
                  </MenuItem>
                )}
                onChange={(event, value) => {
                  if (value !== null) {
                    onSelectCountryChange(value.code);
                    setErrors({ sender_country_code: "" });
                  } else {
                    onSelectCountryChange("");
                    setErrors({
                      sender_country_code: "This field is required",
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="country"
                    variant="standard"
                    helperText={errors.sender_country_code}
                  />
                )}
              />
              <Autocomplete
                className="help-text-error"
                disablePortal
                name="sender_state_code"
                id="state-select-demo"
                sx={{ width: "100%",margin: '8px' }}
                options={state && state.data}
                autoHighlight
                loading={
                  (state && state.loading) || (country && country.loading)
                }
                getOptionLabel={(option) => option.name}
                value={getSelectedState(values && values.sender_state_code)}
                renderOption={(props, option) => (
                  <MenuItem {...props} value={option.code} key={option.code}>
                    {option.name}
                  </MenuItem>
                )}
                onChange={(event, value) => {
                  if (value !== null) {
                    onSelectStateChange(value.code);
                  } else {
                    onSelectStateChange("");
                    setErrors({ sender_state_code: "This field is required" });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="state"
                    variant="standard"
                    helperText={errors.sender_state_code}
                  />
                )}
              />
            </div>
            <div style={{ margin: "20px 0" }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      defaultChecked={formValues && formValues.default}
                      name="default"
                      onChange={handleChange}
                    />
                  }
                  label="Default"
                />
              </FormGroup>
            </div>
            <div className="sc--form--center" style={{ margin: "30px 0" }}>
              <Button variant="outlined" onClick={handleSubmit}>
                Submit
              </Button>
            </div>
          </CardContent>
        </Card>
      </Box>
    </Modal>
  );
};
const AddressSenderPage = () => {
  const dispatch = useDispatch();
  const addresssender = useSelector((state) => state.addresssender);

  const [alert, setAlert] = useState(false);
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [deleteModalopen, setDeleteModalopen] = React.useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [deleteid, setDeleteId] = React.useState("");
  const [clickAddbuttonStatus, setClickAddbuttonStatus] = React.useState(false);

  useEffect(() => {
    dispatch(fetchSenderAddress());
    dispatch(fetchCountry());
  }, [dispatch]);

  useEffect(() => {
    if (addresssender && addresssender.message) {
      setAlert(true);
      setSnackOpen(true);
      if (addresssender && addresssender.messageType == "success") {
        setModalVisible(false);
        setSelectedItem({});
      }
    }
  }, [addresssender]);

  const handleClick = (id) => {
    setDeleteId(id);
    setDeleteModalopen(true);
  };

  const deleteModalClose = () => {
    setDeleteModalopen(false);
  };

  const deleteaddress = (id) => {
    dispatch(destorySenderAddress(id));
    setAlert(true);
    setSnackOpen(true);
    setDeleteModalopen(false);
  };
  const snackhandleClose = () => {
    setAlert(false);
    setSnackOpen(false);
    dispatch(resetSenderAddressState());
  };

  const snackbar = (value) => {
    setSnackOpen(value);
  };

  const columns = [
    { field: "_id", headerName: "Address Id", flex:1 },
    { field: "sender_name", headerName: "Name", flex:1  },
    { field: "sender_company", headerName: "Company Name", flex:1  },
    { field: "sender_phone", headerName: "Phone Number", flex:1  },
    { field: "sender_email", headerName: "Email", flex:1  },
    { field: "sender_address", headerName: "Address", flex:1  },
    { field: "sender_city", headerName: "City", flex:1  },
    { field: "sender_state_code", headerName: "Province", flex:1  },
    { field: "sender_country_code", headerName: "Country", flex:1  },
    { field: "sender_postal_code", headerName: "Postal", flex:1  },
    {
      field: "status",
      headerName: "Status",
      flex:1,
      renderCell: (params) => {
        return <SettingsStatus settingsStatus={params.row.status} />;
      },
    },
    {
      field: "default",
      headerName: "Default",
      width: 100,
      renderCell: (params) => {
        return (
          <div>
            {params.row.default == true && (
              <Chip
                label="Yes"
                color="success"
                style={{
                  fontSize: "10px",
                  height: "20px",
                  borderRadius: "5px",
                }}
              />
            )}
            {params.row.default == false && (
              <Chip
                label="No"
                color="success"
                style={{
                  fontSize: "10px",
                  height: "20px",
                  borderRadius: "5px",
                }}
              />
            )}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      flex:1,
      renderCell: (params) => {
        return (
          <div>
            <span>
              <Tooltip
                title="Edit"
                placement="left-start"
                disableFocusListener={false}
              >
                <IconButton>
                  <EditIcon
                    style={{ color: "rgba(0,0,0,.54)", cursor: "pointer" }}
                    onClick={() => {
                      setSelectedItem(params.row);
                      setModalVisible(true);
                      setClickAddbuttonStatus(false);
                    }}
                  />
                </IconButton>
              </Tooltip>
            </span>
            <span>
              <Tooltip
                title="Delete"
                placement="left-start"
                disableFocusListener={false}
              >
                <IconButton>
                  <DeleteOutlineOutlinedIcon
                    style={{ color: "rgba(0,0,0,.54)", cursor: "pointer" }}
                    onClick={(e) => {
                      handleClick(params.row._id);
                    }}
                  />
                </IconButton>
              </Tooltip>
            </span>
          </div>
        );
      },
    },
  ];
  return (
    <div className="sc--settings--card">
      <Modal
        open={deleteModalopen}
        onClose={deleteModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={deleteModalStyle}>
          <Typography sx={{ p: 2 }}>
            Are you sure want to delete this sender address?
            <br />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <span style={{ margin: "0 10px" }}>
                <Button
                  variant="contained"
                  style={{ margin: "10px 0" }}
                  onClick={(event) => {
                    deleteaddress(deleteid);
                  }}
                >
                  Yes
                </Button>
              </span>
              <span>
                <Button
                  variant="outlined"
                  style={{ margin: "10px 0" }}
                  onClick={deleteModalClose}
                >
                  No
                </Button>
              </span>
            </div>
          </Typography>
        </Box>
      </Modal>
      <Modalpopup
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        formValues={clickAddbuttonStatus==false ? selectedItem: {}}
        setFormValues={setSelectedItem}
        snackbar={snackbar}
        buttonStatus={clickAddbuttonStatus}
      />
      {alert && addresssender && addresssender.loading === false && (
        <Snackbar
          open={snackOpen}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          autoHideDuration={6000}
          onClose={snackhandleClose}
        >
          <Alert
            severity={
              addresssender && addresssender.hasErrors === true
                ? "error"
                : "success"
            }
            style={{ justifyContent: "center", margin: "10px 0" }}
          >
            <span>{addresssender && addresssender.message}</span>
          </Alert>
        </Snackbar>
      )}
      <Card
        sx={{ width: "100%", boxShadow: "unset" }}
        className="sc--cardhead--details"
      >
        <CardHeader
          title="Sender Address"
          className="sc--cardhead--details"
          action={
            <div>
              <Button
                variant="contained"
                onClick={() => {
                  setModalVisible(true);
                  setClickAddbuttonStatus(true);
                }}
              >
                Add Address
              </Button>
            </div>
          }
        />
        <Divider />
        <CardContent>
          <div>
            <DataGrid
              getRowId={(row) => row._id}
              disableRowSelectionOnClick={true}
              autoHeight={true}
              rows={addresssender && addresssender.data}
              columns={columns}
              slots={{
                loadingOverlay: LinearProgress,
                NorowsData,
              }}
              hideFooter={true}
              loading={addresssender && addresssender.loading}
            />
          </div>
        </CardContent>
      </Card>
      <Modalpopup />
    </div>
  );
};
export default AddressSenderPage;
