import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import JSONPretty from "react-json-pretty";
import { Typography } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import _ from 'lodash';

import {
    DataGrid,
    GridToolbarContainer
} from '@mui/x-data-grid';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import DateTime from '../../pages/General/DateTime'


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    boxShadow: 'unset',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    color: theme.palette.text.secondary,
    height: '100%'
}));

const NotficationDrawer = ({ visible, reportName, formDataValues, onClose }) => {
    const dispatch = useDispatch();
    const preview = useSelector(state => state.notificationdashboard.preview);
    const [drawer, setDrawer] = useState(true);


    const ondrawerclose = () => {
        setDrawer(false)
        onClose(false)
    }
    const toggleDrawer = (anchor, open) => (event) => {
        let hidedrawer = ''
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
    };

    return (
        <>

            <Drawer
                anchor="right"
                open={visible}
                onClose={ondrawerclose}
                PaperProps={{
                    sx: { width: "1300px" },
                }}
            >
                <div style={{ padding: "0 30px" }}>
                    <Card sx={{ margin: '20px 0' }} className="sc--cardhead--details">
                        <CardHeader title={preview && preview.data && preview.data.log_data && "Notification Log Details for #" + preview.data.log_data.orderid} action={
                            <div>
                                <IconButton aria-label="settings" onClick={ondrawerclose}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        } />
                        <Divider />
                    </Card>
                    <Grid container direction="row" spacing={1}>
                        {preview.data.customer_data &&
                            preview.data.customer_data.first_name &&
                            preview.data.customer_data.last_name && (
                                <>
                                    <Grid item md={3} style={{ padding: '10px' }}>
                                        <Item>
                                            <h4 className="overline-title-alt mb-2">USER DETAILS</h4>
                                            <div className="sc--order--details--list">
                                                <List>
                                                    {
                                                        preview.data.order_data.id && (
                                                            <ListItem disablePadding>
                                                                <ListItemButton style={{ padding: '5px 0' }}>
                                                                    <ListItemIcon>
                                                                        <AccountCircleOutlinedIcon />
                                                                    </ListItemIcon>
                                                                    <ListItemText primary={<Typography style={{ fontSize: '14px' }}>{preview.data.customer_data.first_name}</Typography>}
                                                                    />
                                                                </ListItemButton>
                                                            </ListItem>
                                                        )}
                                                    {preview.data.customer_data &&
                                                        preview.data.customer_data.email && (
                                                            <ListItem disablePadding>
                                                                <ListItemButton style={{ padding: '5px 0' }}>
                                                                    <ListItemIcon>
                                                                        <EmailIcon />
                                                                    </ListItemIcon>
                                                                    <ListItemText primary={<Typography style={{ fontSize: '14px' }}>{preview.data.customer_data.email}</Typography>}
                                                                    />
                                                                </ListItemButton>
                                                            </ListItem>
                                                        )}
                                                    {preview.data.customer_data &&
                                                        preview.data.customer_data.phone && (
                                                            <ListItem disablePadding>
                                                                <ListItemButton style={{ padding: '5px 0' }}>
                                                                    <ListItemIcon>
                                                                        <LocalPhoneIcon />
                                                                    </ListItemIcon>
                                                                    <ListItemText primary={<Typography style={{ fontSize: '14px' }}>{preview.data.customer_data.phone}</Typography>}
                                                                    />
                                                                </ListItemButton>
                                                            </ListItem>
                                                        )}
                                                </List>
                                            </div>
                                        </Item>
                                    </Grid>
                                </>
                            )}
                        {preview.data.order_data && (
                            <>

                                <Grid item md={3} style={{ padding: '10px' }}>
                                    <Item>
                                        <h4 className="overline-title-alt mb-2">ORDER DETAILS</h4>
                                        <div className="sc--order--details--list">
                                            <List>

                                                {preview.data.order_data.id && (
                                                    <ListItem disablePadding>
                                                        <ListItemButton style={{ padding: '5px 0' }}>
                                                            <ListItemIcon>
                                                                <p style={{ margin: 'unset' }}> Order Id :</p>
                                                            </ListItemIcon>
                                                            <ListItemText primary={<Typography style={{ fontSize: '14px' }}>{preview.data.order_data.id}</Typography>}
                                                            />
                                                        </ListItemButton>
                                                    </ListItem>
                                                )}
                                                {preview.data.order_data.status && (
                                                    <ListItem disablePadding>
                                                        <ListItemButton style={{ padding: '5px 0' }}>
                                                            <ListItemIcon>
                                                                <p style={{ margin: 'unset' }}>Order Status :</p>
                                                            </ListItemIcon>
                                                            <ListItemText primary={<Typography style={{ fontSize: '14px' }}>{preview.data.order_data.status}</Typography>}
                                                            />
                                                        </ListItemButton>
                                                    </ListItem>
                                                )}
                                                {preview.data.order_data.date && (
                                                    <ListItem disablePadding>
                                                        <ListItemButton style={{ padding: '5px 0' }}>
                                                            <ListItemIcon>
                                                                <p style={{ margin: 'unset' }}>Order Date :</p>
                                                            </ListItemIcon>
                                                            <ListItemText primary={<Typography style={{ fontSize: '14px' }}><DateTime
                                                                datetime={
                                                                    preview.data.order_data.date
                                                                }
                                                            /></Typography>}
                                                            />
                                                        </ListItemButton>
                                                    </ListItem>
                                                )}

                                            </List>
                                        </div>
                                    </Item>
                                </Grid>
                            </>
                        )}
                        {preview.data.log_data && (
                            <>
                                <Grid item md={3} style={{ padding: '10px' }}>
                                    <Item>
                                        <h4 className="overline-title-alt mb-2">SHIPPING DETAILS</h4>
                                        <div className="sc--order--details--list">
                                            <List>

                                                {
                                                    preview.data.log_data.tracking_number && (
                                                        <ListItem disablePadding>
                                                            <ListItemButton style={{ padding: '5px 0', display: 'block' }}>
                                                                <ListItemIcon>
                                                                    <p style={{ margin: 'unset' }}> Tracking Number  :</p>
                                                                </ListItemIcon>
                                                                <ListItemText primary={<Typography style={{ fontSize: '14px' }}>{preview.data.log_data
                                                                    .tracking_number}</Typography>}
                                                                />
                                                            </ListItemButton>
                                                        </ListItem>
                                                    )}

                                            </List>
                                        </div>
                                    </Item>
                                </Grid>
                            </>
                        )}
                        {preview.data.log_data && (
                            <>
                                <Grid item md={3} style={{ padding: '10px' }}>
                                    <Item>
                                        <h4 className="overline-title-alt mb-2">NOTIFICATION DETAILS</h4>
                                        <div className="sc--order--details--list">
                                            <List>

                                                {
                                                    preview.data.log_data.platform && (
                                                        <ListItem disablePadding>
                                                            <ListItemButton style={{ padding: '5px 0' }}>
                                                                <ListItemIcon>
                                                                    <p style={{ margin: 'unset' }}>Platform :</p>
                                                                </ListItemIcon>
                                                                <ListItemText primary={<Typography style={{ fontSize: '14px' }}>{_.capitalize(
                                                                    preview.data.log_data.platform)}</Typography>
                                                                }
                                                                />
                                                            </ListItemButton>
                                                        </ListItem>
                                                    )}
                                                {preview.data.log_data.event && (
                                                    <ListItem disablePadding>
                                                        <ListItemButton style={{ padding: '5px 0' }}>
                                                            <ListItemIcon>
                                                                <p style={{ margin: 'unset' }}>Event :</p>
                                                            </ListItemIcon>
                                                            <ListItemText primary={<Typography style={{ fontSize: '14px' }}>{_.capitalize(
                                                                preview.data.log_data.event
                                                            )}</Typography>}
                                                            />
                                                        </ListItemButton>
                                                    </ListItem>
                                                )}
                                                {preview.data.log_data.created_at && (
                                                    <ListItem disablePadding>
                                                        <ListItemButton style={{ padding: '5px 0' }}>
                                                            <ListItemIcon>
                                                                <p style={{ margin: 'unset' }}>Log Date :</p>
                                                            </ListItemIcon>
                                                            <ListItemText primary={<Typography style={{ fontSize: '14px' }}><DateTime
                                                                datetime={
                                                                    preview.data.log_data.created_at
                                                                }
                                                            /></Typography>}
                                                            />
                                                        </ListItemButton>
                                                    </ListItem>
                                                )}

                                            </List>
                                        </div>
                                    </Item>
                                </Grid>
                            </>
                        )}
                    </Grid>

                    {preview.data.log_data &&
                        preview.data.log_data.subject &&
                        preview.data.log_data.content && (
                            <>
                                <Card sx={{ margin: '20px 0' }} className="sc--cardhead--details">
                                    <CardHeader title={'Log Details'} />
                                    <Divider />
                                </Card>

                                <CardContent>
                                    {
                                        preview &&
                                        preview.data &&
                                        preview.data.log_data &&
                                        preview.data.log_data.raw_response &&
                                        preview.data.log_data.raw_response
                                            .custom_error && (
                                            <span className="badge badge-sm has-bg d-none d-mb-inline-flex badge-danger">
                                                {
                                                    preview.data.log_data.raw_response
                                                        .custom_error
                                                }
                                            </span>
                                        )}
                                    <div className="orderdetail-card-border-less-wrapper">
                                        {
                                            <div>

                                                <div className="orderdetail-card-border-less-wrapper-background--white mt-2" style={{ padding: '16px', background: "#efefef" }}>
                                                    <b>Subject :</b>   {preview.data.log_data.subject}
                                                </div>
                                            </div>
                                        }
                                        {
                                            <>

                                                <span>
                                                    {/* <br />
                                                        <b>Content</b> */}
                                                    <div className="mt-2 orderdetail-bg-white mb-2" style={{ padding: '30px', background: '#dbdbdb' }}>
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html:
                                                                    preview.data.log_data.content,
                                                            }}
                                                        />
                                                    </div>
                                                </span>
                                            </>
                                        }
                                    </div>
                                </CardContent>

                            </>
                        )}
                    {preview.data.log_data &&
                        !preview.data.log_data.subject &&
                        preview.data.log_data.raw_request && (
                            <>
                                <Card sx={{ margin: '20px 0' }} className="sc--cardhead--details">
                                    <CardHeader title={'Log Details'} />
                                    <Divider />
                                </Card>

                                <CardContent>
                                    {
                                        preview &&
                                        preview.data &&
                                        preview.data.log_data &&
                                        preview.data.log_data.raw_response &&
                                        preview.data.log_data.raw_response
                                            .custom_error && (
                                            <span className="badge badge-sm has-bg d-none d-mb-inline-flex badge-danger">
                                                {
                                                    preview.data.log_data.raw_response
                                                        .custom_error
                                                }
                                            </span>
                                        )
                                    }
                                    <div className="orderdetail-card-border-less-wrapper" style={{ padding: '30px', background: '#dbdbdb' }}>
                                        <span>
                                            <b>Request Data</b> <br />
                                        </span>
                                        <div className="mt-2">
                                            <JSONPretty
                                                id="json-pretty"
                                                data={preview.data.log_data.raw_request}
                                            ></JSONPretty>
                                        </div>
                                    </div>

                                </CardContent>

                            </>
                        )}

                </div>
            </Drawer >
        </>
    )
}


export default NotficationDrawer;