import { createSlice } from '@reduxjs/toolkit';
import { OMSAPI } from '../../../API';
import { LABELGENAPI, LABELINGCHIMP_SAM_API, LABELINGCHIMP_LABEL_API } from '../../../API';
import axios from 'axios';

/* canceling previous requests if multiple requests is triggered */
const CancelToken = axios.CancelToken;
let source = CancelToken.source();

export const createshipmentslice = createSlice({
    name: 'rate',
    initialState: {
        order: {},
        notification: { show: false, messageContent: "", messageType: "success" },
        loading: false,
        hasErrors: false,
    },
    reducers: {
        doGenerateLabel: (state) => {
            return {
                ...state,
                loading: true,
                hasErrors: false,
                order: {},
                message: [],
            }
        },
        doGenerateLabelSuccess: (state, action) => {
            return {
                ...state,
                loading: false,
                hasErrors: false,
                order: action && action.payload && action.payload.data && action.payload.data.message,
                notification: { show: true, messageContent:"Shipment Created Successfully", messageSubject: action && action.payload && action.payload.data && action.payload.data.message, messageType: "success" }
            }
        },
        doGenerateLabelFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                hasErrors: true,
                order: "",
                notification: { show: true, messageContent: action && action.payload && action.payload.data && action.payload.data.message, messageType: "error" }
            }
        },
        resetLabel: (state) => {
            return {
                ...state,
                loading: false,
                hasError: false,
                notification: { show: false, messageContent: "", messageType: "success" },
            }
        },

    }
})
export const { doGenerateLabel, doGenerateLabelSuccess, doGenerateLabelFailure, resetLabel } = createshipmentslice.actions;
export const generateLabel = (data, apikey) => dispatch => {
    dispatch(doGenerateLabel());
    try {
        LABELINGCHIMP_SAM_API.post('/create-shipment', data, {
            crossDomain: true,
            headers: {
                "x-api-key": apikey
            }
        })
            .then(function (response) {
                dispatch(doGenerateLabelSuccess(response));
            }).catch(function (error) {              
                dispatch(doGenerateLabelFailure(error.response));
            })
    }
    catch (error) {      
        dispatch(doGenerateLabelFailure(error.response));
    }
}
export const resetGenerateLabel = data => dispatch => {
    dispatch(resetLabel());
}


export default createshipmentslice.reducer;
