import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete from "@mui/material/Autocomplete";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";


const CountryDropdown = ({ values=null,dispatchCountryCallback }) => {
    const country_code = useSelector(state => state.countrystate.country);
    const filteredCountryData = country_code.data.filter(({ code }) => code );
    const onChangeCountry = id => {
        dispatchCountryCallback(id)
    }
    const getSelectedCountry = (country_code) => {
        let filteredValue = { code: "", name: "" };
        filteredCountryData.map((key) => {
          if (key.code == country_code) {
            filteredValue = key;
          }
        });
         return filteredValue;
      };
    return (
       <>
            <Autocomplete
                className="help-text-error"
                disablePortal
                name="country"
                id="country-select-demo"
                sx={{ width: "50%",marginRight: '8px'}}
                options={filteredCountryData}
                autoHighlight
                loading={country_code && country_code.loading}
                getOptionLabel={(option) => option.name}
                value={getSelectedCountry(values && values.country)}
                renderOption={(props, option) => (
                <MenuItem {...props} value={option.code} key={option.code}>
                    {option.icon} {option.name}
                </MenuItem>
                )}
                onChange={(event, value) => {
                    if (value !== null) {
                        onChangeCountry(value.code);
                    } else {
                        onChangeCountry("");
                    }
                }}
                renderInput={(params) => (
                <TextField
                    {...params}
                    label="country"
                    variant="standard"
                    // helperText={errors.country_code}
                />
                )}
            />
       </>
    )
}


export default CountryDropdown;