import { createSlice } from "@reduxjs/toolkit";
import { LABELGENAPI } from "../../../API";

export const pickupsettingsslice = createSlice({
  name: "pickupsettings",
  initialState: {
    data: [],
    loading: false,
    hasErrors: false,
    message: "",
  },
  reducers: {
    getPickupSettingsData: (state) => {
      return {
        ...state,
        loading: true,
        hasErrors: false,
      };
    },
    getPickupSettingsDataSuccess: (state, action) => {
      return {
        ...state,
        data:
          action &&
          action.payload &&
          action.payload.data &&
          action.payload.data.data,
        hasErrors: false,
        loading: false,
      };
    },
    getPickupSettingsDataFailure: (state, action) => {
      return {
        ...state,
        loading: false,
        hasErrors: true,
        message:
          action &&
          action.payload &&
          action.payload.data &&
          action.payload.data.message,
      };
    },

    postPickupSettingsData: (state) => {
      return {
        ...state,
        loading: true,
        hasErrors: false,
      };
    },
    postPickupSettingsDataSuccess: (state, action) => {
      return {
        ...state,
        hasErrors: false,
        loading: false,
        message:
          action &&
          action.payload &&
          action.payload.data &&
          action.payload.data.message,
      };
    },
    postPickupSettingsDataFailure: (state, action) => {
      return {
        ...state,
        loading: false,
        hasErrors: true,
        message:
          action &&
          action.payload &&
          action.payload.data &&
          action.payload.data.message,
      };
    },
    resetPickupSettings: (state) => {
      return {
        ...state,
        loading: false,
        hasError: false,
        message: "",
      };
    },
  },
});
export const {
  getPickupSettingsData,
  getPickupSettingsDataSuccess,
  getPickupSettingsDataFailure,
  postPickupSettingsData,
  postPickupSettingsDataSuccess,
  postPickupSettingsDataFailure,
  resetPickupSettings,
} = pickupsettingsslice.actions;

export const getPickupSettings = (data) => (dispatch) => {
  dispatch(getPickupSettingsData());
  try {
    LABELGENAPI.get("/settings/pickup-settings")
      .then(function (response) {
        dispatch(getPickupSettingsDataSuccess(response));
      })
      .catch(function (error) {
        dispatch(getPickupSettingsDataFailure(error.response));
      });
  } catch (error) {
    dispatch(getPickupSettingsDataFailure(error.response));
  }
};
export const postPickupSettings = (data) => (dispatch) => {
  dispatch(postPickupSettingsData());
  try {
    LABELGENAPI.post("/settings/pickup-settings", data)
      .then(function (response) {
        dispatch(postPickupSettingsDataSuccess(response));
        dispatch(getPickupSettings());
      })
      .catch(function (error) {
        dispatch(postPickupSettingsDataFailure(error.response));
      });
  } catch (error) {
    dispatch(postPickupSettingsDataFailure(error.response));
  }
};
export const resetPickupSettingsState = (data) => (dispatch) => {
  dispatch(resetPickupSettings());
};

export default pickupsettingsslice.reducer;
