import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'


import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import LoadingButton from '@mui/lab/LoadingButton';
import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined';
import LinearProgress from '@mui/material/LinearProgress';
import {
    Box,
    Alert
 } from '@mui/material';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import Chip from '@mui/material/Chip';
import { useStripe, useElements, Elements, CardElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Card from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import {
    FormControl,
    Pagination,
    IconButton,
    Tooltip,
} from "@mui/material";
import { getSubscription, getPaymentIntentData, addPayment, removePayment, resetPaymentNotifications } from './paymentSlice';
import { fetchAddressBilling } from '../AddressBilling/addressBillingSlice';
import { useraccountactivate, resetOnboarding } from '../../../pages/Dashboard/onboardingSlice';

import Notification from '../../../pages/General/Notification'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };


const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: "#32325d",
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#aab7c4",
            },
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
        },
    },
};

const CheckoutForm = ({ client_secret, dispatch, setShowError, setShowMessageType, setShowMessage, setloadingEnable, loading, addressbilling }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [cardHolderName, setCardHolderName] = useState("")
    const handleSubmit = async (event) => {
        event.preventDefault();
        if(cardHolderName.trim() == ""){
            setShowMessage(["Card holder name is required"])
            setShowMessageType("error")
            setShowError(true)
            return false;
        }

        setloadingEnable(true)

        if (elements == null) {
            return;
        }
        const { setupIntent, error } = await stripe.confirmCardSetup(client_secret, {
            payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {
                    name: cardHolderName,
                    address: {
                        line1: addressbilling && addressbilling.data && addressbilling.data.billing_address,
                        city: addressbilling && addressbilling.data && addressbilling.data.billing_city,
                        state: addressbilling && addressbilling.data && addressbilling.data.billing_state_code,
                        postal_code: addressbilling && addressbilling.data && addressbilling.data.billing_postal_code,
                        country: addressbilling && addressbilling.data && addressbilling.data.billing_country_code,
                    }
                }
            }
        })
        if (error) {
            // Display "error.message" to the user...
            setShowMessage([error.message])
            setShowMessageType("error")
            setShowError(true)
        } else {
            // The card has been verified successfully...
            setShowMessage(["Card added successfully"])
            setShowMessageType("success")
            dispatch(useraccountactivate())
            setShowError(true)
            dispatch(addPayment(setupIntent))
        }
        setloadingEnable(false)
    };
    return (
        <Grid container justifyContent="center" spacing={2}>
            <Grid item sm={12}>
            <h3 style={{textAlign:"center"}}>Payment Details</h3>
            <hr style={{backgroundColor:"#79afe5"}}/>
            <div style={{
                    padding: "5px"
                }}>
                    <form className="stripe_card_sc" style={{marginTop:"30px"}} onSubmit={handleSubmit}>
                        <label>Card Holder Name</label>
                        <input id="card_holder_name" onChange={(e) => setCardHolderName(e.target.value) } class="StripeElement"/>
                        <br/><br/>
                        <label>Card Details</label>
                        <CardElement options={CARD_ELEMENT_OPTIONS} />
                        <div style={{textAlign:"center"}}>
                            <LoadingButton
                                style={{ marginTop: "20px" }}
                                variant="outlined"
                                type="submit"
                                disabled={!stripe || !elements}
                                loading = {loading}
                                >
                                Submit
                            </LoadingButton>
                        </div>
                    </form>
                </div>
            </Grid>
        </Grid>
    );
};

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const PaymentPage = () => {
    const dispatch = useDispatch();
    const profile = useSelector(state => state.auth.profile);    
    const activation = useSelector(state => state.onboarding && state.onboarding.activation);  
    const addPaymentData = useSelector(state => state.payment.addPayment);
    const paymentIntentData = useSelector(state => state.payment.paymentIntent);
    const removePaymentData = useSelector(state => state.payment.removePayment);
    const activePaymentData = useSelector(state => state.payment.getActivePayment);
    const addressbilling = useSelector(state => state.addressbilling);
    const [loadingEnable, setloadingEnable] = useState(false);
    const [queryCheckout, setQueryCheckout] = useState("");
    const [alertOpen, setAlertOpen] = useState(false);
    const [isPoll, setIsPoll] = useState(true)
    const [pollingId, setPollingId] = useState(0);
    const [showError, setShowError] = useState(false)
    const [showMessageType, setShowMessageType] = useState("error")
    const [showMessage, setShowMessage] = useState([])
    const [showCardModal, setShowCardModal] = useState(false)
   
    useEffect(() => {
        const params = new URLSearchParams(window.location.search)
        let checkout = params.get('checkout')
        if (checkout && checkout !== "undefined") {
            setQueryCheckout(checkout);
        }
    }, [])

    useEffect(() => {
        dispatch(fetchAddressBilling())
        dispatch(getSubscription(queryCheckout))
    }, [queryCheckout])

    const polling = () => {
        const intervalID = setInterval(() => {
            setIsPoll(false)
            dispatch(getSubscription("is_removed"))
        }, 3000);
        setPollingId(intervalID);
    }

    useEffect(() => {
        if (activePaymentData.data) {
            if (activePaymentData.data.reload && activePaymentData.data.reload === true) {
                setloadingEnable(true)
                if (isPoll === true) {
                    polling()
                }
                setTimeout(function () {
                    setloadingEnable(false)
                    clearInterval(pollingId);
                }, 10000)
            }
            if (activePaymentData.data.reload === false) {
                setloadingEnable(false)
                clearInterval(pollingId);
            }
        }
    }, [activePaymentData]);

    const onGetPaymentIntent = () => {
        setShowCardModal(true)
        dispatch(getPaymentIntentData())
    }

    const onRemovePayment = () => {
        setAlertOpen(false);
        dispatch(removePayment())
        dispatch(getSubscription("is_removed"))
    }

    const handleAlertOpen = () => {
        setAlertOpen(true);
    };

    const handleAlertClose = () => {
        setAlertOpen(false);
    };


    return (<>
        {(addPaymentData && addPaymentData.notification && addPaymentData.notification.show && addPaymentData.notification.messageContent != "") &&
            <Notification
                is_open={addPaymentData.notification.show}
                messageType={addPaymentData.notification.messageType}
                messages={addPaymentData.notification.messageContent}
                handleClose={() => dispatch(resetPaymentNotifications())}
            />
        }
        {(removePaymentData && removePaymentData.notification && removePaymentData.notification.show && removePaymentData.notification.messageContent != "") &&
            <Notification
                is_open={removePaymentData.notification.show}
                messageType={removePaymentData.notification.messageType}
                messages={removePaymentData.notification.messageContent}
                handleClose={() => dispatch(resetPaymentNotifications())}
            />
        }
        {(activePaymentData && activePaymentData.notification && activePaymentData.notification.show && activePaymentData.notification.messageContent != "") &&
            <Notification
                is_open={activePaymentData.notification.show}
                messageType={activePaymentData.notification.messageType}
                messages={activePaymentData.notification.messageContent}
                handleClose={() => dispatch(resetPaymentNotifications())}
            />
        }
        <Notification
            is_open={showError}
            messageType={showMessageType}
            messages={showMessage}
            handleClose={() => setShowError(false)}
        />
        <div sx={{ minWidth: 1000, boxShadow: 'unset' }} className="sc--cardhead--details">
            <CardHeader title='Payment' className="sc--cardhead--details" action={
                <div>
                    <Tooltip title="Back" placement="top-start">
                        <Link to="/settings/billing">
                            <IconButton style={{ position: "relative", left: '-20px' }}>
                                <ArrowBackIosNewIcon style={{ color: "#1890ff", cursor: 'pointer' }} />
                            </IconButton>
                        </Link>
                    </Tooltip>
                </div>
            }
            />
            {((activePaymentData && activePaymentData.loading === true) || loadingEnable || (paymentIntentData && paymentIntentData.loading) || (activation && activation.loading)) &&
                <LinearProgress />
            }
            <Divider />
            <CardContent>
            {profile && profile.status === 'PENDING' && profile && profile.activation_requested === false &&
            <Stack sx={{ width: '100%' }} spacing={2}>
                 <Alert severity="error">Please add your credit card information to validate your account. The account will be activated in one working day.</Alert>
            </Stack>
            }
                {(activePaymentData && activePaymentData.loading === false) && !(addressbilling && addressbilling.data && addressbilling.data.billing_name) &&
                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item sm={6}>
                            <div style={{textAlign:"center",marginTop:"15px"}}>
                                <Alert severity="warning">Please update billing address to add payment method</Alert>
                            </div>
                        </Grid>
                    </Grid>
                }
                <Grid container justifyContent="center" spacing={2} style={{ margin: '50px auto' }}>
                    <Grid item sm={6}>
                            {(activePaymentData && activePaymentData.data && activePaymentData.data.length != 0 && activePaymentData.data[0].type && activePaymentData.data[0].type == "card") && <>
                                <Card
                                    number={"************" + activePaymentData.data[0].card.last4}
                                    name={activePaymentData.data[0].billing_details.name}
                                    expiry={activePaymentData.data[0].card.exp_month + "/" + activePaymentData.data[0].card.exp_year}
                                    preview={true}
                                    issuer={activePaymentData.data[0].card.brand && activePaymentData.data[0].card.brand}
                                />
                                <div style={{
                                    padding: "20px 0px",
                                    textAlign: "center"
                                }}>
                                    <LoadingButton
                                        variant="outlined"
                                        disabled={
                                            ((activePaymentData.data.paddle_status && activePaymentData.data.paddle_status == "deleted") ? true : false) ||
                                            !(addressbilling && addressbilling.data && addressbilling.data.billing_name)
                                        }
                                        size="small"
                                        style={{ margin: '0 40px' }}
                                        onClick={onGetPaymentIntent}
                                        loading={
                                            (addPaymentData && addPaymentData.loading) ||
                                            (paymentIntentData && paymentIntentData.loading) ||
                                            (loadingEnable) ||
                                            (activePaymentData && activePaymentData.loading === true)
                                        }
                                    >
                                        update
                                        </LoadingButton>
                                    <Dialog
                                        open={alertOpen}
                                        onClose={handleAlertClose}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">
                                            {"Do you want to remove this payment method?"}
                                        </DialogTitle>

                                        <DialogActions>
                                            <Button onClick={handleAlertClose}>No</Button>
                                            <Button onClick={onRemovePayment} autoFocus>
                                                Yes
                                                </Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>
                            </>}
                            {(activePaymentData && activePaymentData.data && activePaymentData.data.length == 0 &&
                                <div className="sc--form--center" style={{ margin: '50px 0' }}>
                                    <LoadingButton
                                        variant="outlined"
                                        style={{ margin: '15px auto' }}
                                        onClick={onGetPaymentIntent}
                                        disabled={!(addressbilling && addressbilling.data && addressbilling.data.billing_name)}
                                        loading={
                                            (addPaymentData && addPaymentData.loading) ||
                                            (paymentIntentData && paymentIntentData.loading) ||
                                            (loadingEnable) ||
                                            (activePaymentData && activePaymentData.loading === true)
                                        }
                                    >
                                        <AddCardOutlinedIcon style={{ paddingRight: '10px', fontSize: '50px' }} />Add Payment Method
                                    </LoadingButton>
                                </div>
                            )}
                    </Grid>
                </Grid>
            </CardContent>
            <Modal
                open={showCardModal && (paymentIntentData && paymentIntentData.data && paymentIntentData.data.client_secret)}
                onClose={()=> setShowCardModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {paymentIntentData && paymentIntentData.data && paymentIntentData.data.client_secret &&
                        <Elements stripe={stripePromise}>
                            <CheckoutForm
                                client_secret={paymentIntentData.data.client_secret}
                                dispatch={dispatch}
                                setShowError={setShowError}
                                setShowMessageType={setShowMessageType}
                                setShowMessage={setShowMessage}
                                setloadingEnable={setloadingEnable}
                                loading={
                                    (addPaymentData && addPaymentData.loading) ||
                                    (paymentIntentData && paymentIntentData.loading) ||
                                    (loadingEnable) ||
                                    (activePaymentData && activePaymentData.loading === true)
                                }
                                addressbilling={addressbilling}
                            />
                        </Elements>
                    }
                </Box>
            </Modal>
        </div>
    </>
    )
}
export default PaymentPage;