import { createSlice } from '@reduxjs/toolkit';
import { OMSAUTH, OMSPROFILE } from '../../API';

export const authslice = createSlice({
    name: 'postLogin',
    initialState: {
        isLoggedIn: false,
        profile: {},
        notification: { showMessage: false, messageContent: "", messageType: "success" },
        loginLoading: true,
        logoutLoading: false,
        resetLoading: false,
        resetFail: false
    },

    reducers: {
        postLogin: (state) => {
            return {
                ...state,
                loginLoading: true
            }
        },
        postLoginSuccess: (state, action) => {
            return {
                ...state,
                isLoggedIn: true,
                loginLoading: false,
                profile: action && action.payload && action.payload.data && action.payload.data.data
            }
        },
        postLoginFailure: (state, action) => {
            return {
                ...state,
                isLoggedIn: false,
                loginLoading: false,
                notification: { showMessage: true, messageContent: action && action.payload && action.payload.data && action.payload.data.data ? action.payload : 'Failed to authenticate', messageType: "error" },
            }
        },

        resetNotificationData: (state, action) => {
            return {
                ...state,
                loading: true,
                notification: { showMessage: false, messageContent: "", messageType: "success" }
            }
        },
        verifyAuth: (state, action) => {
            return {
                ...state,
                loginLoading: true
            }
        },
        verifyAuthSuccess: (state, action) => {
            return {
                ...state,
                isLoggedIn: true,
                loginLoading: false,
                logoutLoading: false,
                profile: action && action.payload && action.payload.data && action.payload.data.data
            }
        },
        verifyAuthFailure: (state, action) => {
            return {
                ...state,
                isLoggedIn: false,
                loginLoading: false,
                logoutLoading: false,
            }
        },
        postLogout: (state) => {
            return {
                ...state,
                logoutLoading: true
            }
        },
        postLogoutSuccess: (state) => {
            return {
                ...state,
                isLoggedIn: false,
                logoutLoading: false,
            }
        },
        postLogoutFailure: (state) => {
            return {
                ...state,
                isLoggedIn: false,
                logoutLoading: false,
            }
        },
        postRegister: (state) => {
            return {
                ...state,
                loginLoading: true,
                notification: { showMessage: false, messageContent: "", messageType: "success" }
            }
        },
        postRegisterSuccess: (state, action) => {
            return {
                ...state,
                isLoggedIn: true,
                loginLoading: false,
                profile: action && action.payload && action.payload.data && action.payload.data.data
            }
        },
        postRegisterFailure: (state, action) => {
            return {
                ...state,
                isLoggedIn: false,
                loginLoading: false,
                logoutLoading: false,
                notification: { showMessage: true, messageContent: action.payload && action.payload.data && action.payload.data.message, messageType: "error" }
            }
        },
        postPasswordReset: (state) => {
            return {
                ...state,
                resetLoading: true
            }
        },
        postPasswordResetSuccess: (state, action) => {
            return {
                ...state,
                resetLoading: false,
                isLoggedIn: false,
                notification: { showMessage: true, messageContent: action.payload && action.payload.data && action.payload.data.msg, messageType: "success" }
            }
        },
        postPasswordResetFailure: (state, action) => {
            return {
                ...state,
                resetLoading: false,
                isLoggedIn: false,
                notification: { showMessage: true, messageContent: action.payload && action.payload.data && action.payload.data.errors, messageType: "error" }
            }
        },
        postQuestionerData: (state) => {
            return {
                ...state,
                loading: true,
                hasErrors: false
            }
        },
        postQuestionerDataSuccess: (state, action) => {
            return {
                ...state,
                hasErrors: false,
                loading: false,
                notification: { show: true, messageContent: [action.payload && action.payload.data && action.payload.data.message], messageType: "success" }

            }
        },
        postQuestionerDataFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                hasErrors: true,
                notification: { show: true, messageContent: [action.payload && action.payload.data && action.payload.data.message], messageType: "error" }
            }
        },
        postResendEmail: (state) => {
            return {
                ...state,
                resentEmailloading: true,
                resentEmail: false
            }
        },
        postResendEmailSuccess: (state, action) => {
            return {
                ...state,
                resentEmail: true,
                resentEmailloading: false,
                notification: { showMessage: true, messageContent: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.message ? [action.payload.data.data.message] : "Something went wrong", messageType: "success" },
            }
        },
        postResendEmailFailure: (state, action) => {
            return {
                ...state,
                resentEmail: false,
                resentEmailloading: false,
                notification: { showMessage: true, messageContent: action && action.payload && action.payload.data && action.payload.data.data  && action.payload.data.data.message ? ["Something went wrong"] : ["Something went wrong"], messageType: "error" },
            }
        },

    }
})

export const doLogin = data => dispatch => {
    dispatch(postLogin());
    try {

        OMSAUTH.get('/csrf-cookie').then(response => {
            OMSAUTH.post('/login', data).then(function (response) {
                OMSPROFILE.get('/profile').then(function (response) {
                    dispatch(postLoginSuccess(response));
                    dispatch(resetNotification());
                }).catch(function (error) {
                    dispatch(postLoginFailure(error.response));
                    dispatch(resetNotification());
                })
            }).catch(function (error) {
                dispatch(postLoginFailure(error.response));
            })
        }).catch(function (error) {
            dispatch(postLoginFailure(error.response));
        });
    }
    catch (error) {
        dispatch(postLoginFailure(error));
    }
}

export const authenticate = data => dispatch => {
    dispatch(verifyAuth());
    try {
        OMSPROFILE.get('/profile')
            .then(function (response) {
                dispatch(verifyAuthSuccess(response));
            }).catch(function (error) {
                dispatch(verifyAuthFailure(error));
            })
    }
    catch (error) {
        dispatch(verifyAuthFailure(error));
    }
}

export const doLogout = data => dispatch => {

    dispatch(postLogout())

    try {
        OMSAUTH.post('/logout')
            .then(function (response) {
                dispatch(postLogoutSuccess());
            })
            .catch(function (error) {
                dispatch(postLogoutFailure());
            })
    }
    catch (error) {
        dispatch(postLogoutFailure())
    }
}

export const doRegister = data => dispatch => {
    dispatch(postRegister());
    try {
        OMSAUTH.get('/csrf-cookie').then(response => {
            OMSAUTH.post('/register', data).then(function (response) {
                OMSPROFILE.get('/profile').then(function (response) {
                    dispatch(postRegisterSuccess(response));
                }).catch(function (error) {
                    dispatch(postRegisterFailure(error.response));
                })
            }).catch(function (error) {
                dispatch(postRegisterFailure(error.response));
            })
        }).catch(function (error) {
            dispatch(postRegisterFailure(error.response));
        });

    }
    catch (error) {
        dispatch(postRegisterFailure(error.response));
    }
}

export const doPasswordReset = data => dispatch => {
    dispatch(postPasswordReset());
    try {
        OMSAUTH.post('/resetpassword', data).then(function (response) {
            dispatch(postPasswordResetSuccess(response));
        }).catch(function (error) {
            dispatch(postPasswordResetFailure(error.response));
        })

    }
    catch (error) {
        dispatch(postPasswordResetFailure(error));
    }
}


export const storeQuestionerData = data => dispatch => {
    dispatch(postQuestionerData());
    console.log(data)
    try {
        OMSPROFILE.post('/questioner-data', data)
            .then(function (response) {
                dispatch(postQuestionerDataSuccess(response));
            }).catch(function (error) {
                dispatch(postQuestionerDataFailure(error.response));
            })
    }
    catch (error) {
        dispatch(postQuestionerDataFailure(error.response));
    }
}

export const resendEmail = email => dispatch => {
    dispatch(postResendEmail());
    try {
        OMSAUTH.post('/verify/resend/'+email+'/LABELGEN', {})
            .then(function (response) {
                dispatch(postResendEmailSuccess(response));
            }).catch(function (error) {
                dispatch(postResendEmailFailure(error.response));
            })
    }
    catch (error) {
        dispatch(postRegisterFailure(error.response));
    }
}

export const resetNotification = data => dispatch => {    
    dispatch(resetNotificationData());
}
export const { postLogin, postLoginSuccess, postLoginFailure, verifyAuth, verifyAuthSuccess, verifyAuthFailure, postLogout, postLogoutSuccess, postLogoutFailure, postRegister, postRegisterSuccess, postRegisterFailure, postPasswordReset, postPasswordResetSuccess, postPasswordResetFailure, resetNotificationData,postQuestionerData,postQuestionerDataSuccess,postQuestionerDataFailure, postResendEmail,postResendEmailSuccess, postResendEmailFailure } = authslice.actions;

export default authslice.reducer;
