import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import IconButton from '@mui/material/IconButton';

const Iconstyle = {
    height: '40px',
    width: "40px",
    marginRight: '15px',
    color: '#1976d2'
}

const BillingPage = () => {

    return (<>
        <Card sx={{ minWidth: 1000, boxShadow: 'unset' }} className="sc--cardhead--details">
            <CardHeader title='Billing' className="sc--cardhead--details" />
            <Divider />
            <CardContent>
                <Grid container style={{ justifyContent: 'center' }} spacing={1}>
                    <Grid item xs={12} md={8} >
                        <Link to="/settings/payment">
                            <Card sx={{ margin: '20px 0' }} className="sc--cardhead--details sc-custom-card">
                                <CardHeader
                                    avatar={
                                        <AddCardOutlinedIcon sx={Iconstyle} />
                                    }
                                    title="Billing and payment information"
                                    subheader="Update your billing information and payment method"
                                    action={
                                        <div>
                                            <Link to="/settings/payment">
                                                <IconButton>
                                                    <ArrowForwardIcon />
                                                </IconButton>
                                            </Link>
                                        </div>
                                    } />
                                <Divider />
                            </Card>
                        </Link>
                    </Grid>

                    <Grid item xs={12} md={8} >
                        <Link to="/settings/invoices">
                            <Card sx={{ margin: '20px 0' }} className="sc--cardhead--details sc-custom-card">
                                <CardHeader
                                    avatar={
                                        <ReceiptIcon sx={Iconstyle} />
                                    }
                                    title="Invoices"
                                    subheader="Your invoices can be paid"
                                    action={
                                        <div>
                                            <Link to="/settings/invoices">
                                                <IconButton>
                                                    <ArrowForwardIcon />
                                                </IconButton>
                                            </Link>
                                        </div>
                                    } />
                                <Divider />
                            </Card>
                        </Link>
                    </Grid>
                </Grid>
            </CardContent>
        </Card >
    </>
    )
}
export default BillingPage;
