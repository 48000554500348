import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Paper from '@mui/material/Paper';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';

import ShopifyIntegration from '../Integration/Marketplace/PlatformIntegrations/Shopify/ShopifyIntegration'
import DesktopshipperIntegration from '../Integration/Marketplace/PlatformIntegrations/Desktopshipper/DesktopshipperIntegration'
import BrightpearlIntegration from '../Integration/Marketplace/PlatformIntegrations/Brightpearl/BrightpearlIntegration'
import WoocommerceIntegration from '../Integration/Marketplace/PlatformIntegrations/Woocommerce/WoocommerceIntegration'
import BigcommerceIntegration from '../Integration/Marketplace/PlatformIntegrations/Bigcommerce/BigcommerceIntegration'
import TwoShipIntegration from '../Integration/Marketplace/PlatformIntegrations/Twoship/TwoShipIntegration'
import MagentoIntegration from '../Integration/Marketplace/PlatformIntegrations/Magento/MagentoIntegration'
import CentricIntegration from '../Integration/Marketplace/PlatformIntegrations/Centric/CentricIntegration'
import SkuLabsIntegration from '../Integration/Marketplace/PlatformIntegrations/SkuLabs/SkuLabsIntegration'
import EmailIntegration from '../Integration/Notification/PlatformIntegrations/Email/EmailIntegration'
import WebhookIntegration from '../Integration/Notification/PlatformIntegrations/Webhook/WebhookIntegration'
import KlaviyoIntegration from '../Integration/Notification/PlatformIntegrations/Klaviyo/KlaviyoIntegration'
import ShipStationIntegration from '../Integration/Marketplace/PlatformIntegrations/ShipStation/ShipStationIntegration'
import EtsyIntegration from '../Integration/Marketplace/PlatformIntegrations/Etsy/EtsyIntegration'

import { resetShopifyState } from '../Integration/Marketplace/PlatformIntegrations/Shopify/shopifyappSlice';
import { resetSkulabsState } from '../Integration/Marketplace/PlatformIntegrations/SkuLabs/skulabsSlice';
import { resetWoocommerceState } from '../Integration/Marketplace/PlatformIntegrations/Woocommerce/woocommerceSlice';
import { resetBrightpearlState } from '../Integration/Marketplace/PlatformIntegrations/Brightpearl/brightpearlSlice';

const PlatformDrawer = ({ visible, platform, storeName, storeId, stepNumber, formDataValues, onClose }) => {
    const dispatch = useDispatch();
    const shopifyProgress = useSelector(state => state.shopifyapp && state.shopifyapp.progress && state.shopifyapp.progress);
    const brightpearlProgress = useSelector(state => state.brightpearl && state.brightpearl.progress);
    const woocommerceProgress = useSelector(state => state.woocommerce && state.woocommerce.progress);
    const bigcommerceProgress = useSelector(state => state.bigcommerce && state.bigcommerce.progress);
    const skulabsProgress = useSelector(state => state.skulabs && state.skulabs.progress);
    const progress = useSelector(state => state.klaviyo && state.klaviyo.progress);
    const shipstationprogress = useSelector(state => state.shipstation && state.shipstation.progress);
    const etsyProgress = useSelector(state => state.etsy && state.etsy.progress);
    const etsyconfigdetails = useSelector(state => state.etsy && state.etsy.configure);

    const [drawer, setDrawer] = useState(true);
    const ondrawerclose = () => {
        dispatch(resetShopifyState());
        dispatch(resetSkulabsState());
        dispatch(resetWoocommerceState());
        dispatch(resetBrightpearlState());
        setDrawer(false)
        onClose(false)
    }       
    const params = new URLSearchParams(window.location.search) // id=123
    let platformname = params.get('platform')
    let storeEtsy=''
    if(platformname==='Etsy'){
        storeEtsy= params.get('store')
    }
    let storeParam = params.get('store_url')
    return (
        <>

            <Drawer
                anchor="right"
                open={visible}
                onClose={ondrawerclose}
                PaperProps={{
                    sx: { width: "70%" },
                }}
            >
                <div style={{ padding: "0 30px" }}>
                    <Card sx={{ margin: '20px 0' }} className="sc--cardhead--details">
                        <CardHeader title={`${platform} Integration`} action={
                            <div>
                                <IconButton aria-label="settings" onClick={ondrawerclose}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        } />
                        {((shopifyProgress && shopifyProgress.loading === true) || (brightpearlProgress && brightpearlProgress.loading === true) ||
                            (woocommerceProgress && woocommerceProgress.loading === true) || (bigcommerceProgress && bigcommerceProgress.loading === true) || 
                            (skulabsProgress && skulabsProgress.loading === true) || (progress && progress.loading === true) || (shipstationprogress && shipstationprogress.loading === true)
                            || (etsyProgress && etsyProgress.loading === true) || (etsyconfigdetails && etsyconfigdetails.loading === true)) && <>
                                <LinearProgress />
                            </>
                        }
                        <Divider />
                    </Card>
                    {(platform === 'Shopify APP' || platform === 'SHOPIFY_APP' || platform === 'SHOPIFY') &&
                        <ShopifyIntegration storeName={storeName} storeId={storeId} stepNumber={stepNumber} />
                    }
                    {platform === 'Desktop Shipper' &&
                        <DesktopshipperIntegration />
                    }
                    {(platform === 'Brightpearl' || platform === 'brightpearl' || platform === 'BRIGHTPEARL') &&
                        <BrightpearlIntegration storeName={storeName} />
                    }
                    {(platform === 'WooCommerce' || platformname === 'woocommerce' || platform === 'WOOCOMMERCE') &&
                        <WoocommerceIntegration storeName={storeName} platformName={platformname} storeParam={storeParam} />
                    }
                    {(platform === 'Bigcommerce' || platformname === 'bigcommerce' || platform === 'BIGCOMMERCE' )&&
                        <BigcommerceIntegration storeName={storeName}  platformName={platform}/>
                    }
                    {(platform === 'Skulabs' || platformname === 'skulabs' || platform === 'SKULABS' )&&
                        <SkuLabsIntegration storeName={storeName}  platformName={platform}/>
                    }
                    {(platform === 'ShipStation' || platform === 'SHIPSTATION')&&
                        <ShipStationIntegration storeName={storeName}  platformName={platform}/>
                    }
                    {(platform === 'Etsy' || platform === 'ETSY')&&
                        <EtsyIntegration storeName={storeName} storeEtsy={storeEtsy} platformName={platform}/>
                    }
                    {platform === '2Ship' &&
                        <TwoShipIntegration />
                    }
                    {platform === 'Magento' &&
                        <MagentoIntegration />
                    }
                    {(platform === 'Email' || platform === 'EMAIL') &&
                        <EmailIntegration />
                    }
                    {(platform === 'Webhook' || platform === 'WEBHOOK') &&
                        <WebhookIntegration />
                    }
                    {(platform === 'Klaviyo') &&
                        <KlaviyoIntegration />
                    }
                    {(platform === 'Api') &&
                        <CentricIntegration />
                    }
                </div>
            </Drawer >
        </>
    )
}


export default PlatformDrawer;