import { createSlice } from '@reduxjs/toolkit';
import { LABELGENAPI} from '../../../API';

export const manifestsettingsslice = createSlice({
    name: 'manifestsettings',
    initialState: {
        data: [],
        loading: false,
        hasErrors: false,
        message: ""
    },
    reducers: {
        getManifestSettingsData: (state) => {
            return {
                ...state,
                loading: true,
                hasErrors: false
            }
        },
        getManifestSettingsDataSuccess: (state, action) => {
            return {
                ...state,
                data: action && action.payload && action.payload.data && action.payload.data.data,
                hasErrors: false,
                loading: false
            }
        },
        getManifestSettingsDataFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                hasErrors: true,
                message: action && action.payload && action.payload.data && action.payload.data.message
            }
        },
        
        postManifestSettingsData: (state) => {
            return {
                ...state,
                loading: true,
                hasErrors: false
            }
        },
        postManifestSettingsDataSuccess: (state, action) => {
            return {
                ...state,
                hasErrors: false,
                loading: false,
                message: action && action.payload && action.payload.data && action.payload.data.message
            }
        },
        postManifestSettingsDataFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                hasErrors: true,
                message: action && action.payload && action.payload.data && action.payload.data.message
            }
        },
        resetManifestSettings: (state) => {
            return {
                ...state,
                loading: false,
                hasError: false,
                message: "",
            }
        },


    }
})
export const { getManifestSettingsData, getManifestSettingsDataSuccess, getManifestSettingsDataFailure,  
    postManifestSettingsData, postManifestSettingsDataSuccess, postManifestSettingsDataFailure,
    resetManifestSettings } = manifestsettingsslice.actions;

export const getManifestSettings = data => dispatch => {
    dispatch(getManifestSettingsData());
    try {
        LABELGENAPI.get('/settings/manifest-settings')
            .then(function (response) {
                dispatch(getManifestSettingsDataSuccess(response));
            }).catch(function (error) {
                dispatch(getManifestSettingsDataFailure(error.response));
            })
    }
    catch (error) {
        dispatch(getManifestSettingsDataFailure(error.response));
    }
}
export const postManifestSettings = (data) => dispatch => {
    console.log(data);
    dispatch(postManifestSettingsData());
    try {
        LABELGENAPI.post('/settings/manifest-settings', data)
            .then(function (response) {
                dispatch(postManifestSettingsDataSuccess(response));
                dispatch(getManifestSettings());
            }).catch(function (error) {
                dispatch(postManifestSettingsDataFailure(error.response));
            })
    }
    catch (error) {
        dispatch(postManifestSettingsDataFailure(error.response));
    }
}
export const resetManifestSettingsState = data => dispatch => {
    dispatch(resetManifestSettings());
}

export default manifestsettingsslice.reducer;
