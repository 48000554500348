import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import Stack from '@mui/material/Stack';
import moment from 'moment';

const Filters = ({ visible, meta, setMeta }) => {
    const invoice = useSelector(state => state.invoice.invoiceData);
    const [formValues, setFormValues] = useState(meta)
    const [filterButtonDisabled, setFilterButtonDisabled] = useState(true)
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [checkFormUse, setCheckFormUse] = useState("")

    const submitFilterValues = () => {
        setMeta(prevState => ({
            ...prevState,
            [`start_date`]: formValues["start_date"],
            [`end_date`]: formValues["end_date"],
            [`page`]: 1
        }));
        setCheckFormUse(true)
    }

    const onDateChange = (date_type, date) => {
        if(date != "" && typeof date !== "undefined"){
           let date_formatted = (date != null) ? moment(date).format('YYYY-MM-DD') : date
           if(date_type == "start_date"){
            setStartDate(date_formatted)
            setFormValues({
                ...formValues,
                [`start_date`]: date_formatted
            });
           }else{
            setEndDate(date_formatted)
            setFormValues({
                ...formValues,
                [`end_date`]: date_formatted
            });
           }
        }
    }

    const handleKeyUp = (event) => {
        if (event.keyCode === 13) {
            submitFilterValues();
        }
    }

    const resetFields = () => {
        setStartDate(null)
        setEndDate(null)
        setFormValues({
            ...formValues,
            [`start_date`]: "",
            [`end_date`]: "",
        });
        setMeta(prevState => ({
            ...prevState,
            [`start_date`]: "",
            [`end_date`]: "",
            [`page`]: 1,
        }));
    }

    useEffect(() => {
        setCheckFormUse("")
        setFilterButtonDisabled(checkFormHasValues())
    }, [formValues])

    const checkFormHasValues = () => {
        let disableButton = true
        if (formValues["start_date"] !== ""  || formValues["end_date"] !== "") {
            disableButton = false
        }
        return disableButton
    }

    useEffect(() => {
        if ((formValues["start_date"] !== ""  || formValues["end_date"] !== "") && checkFormUse==='') {
            setFormValues({
                ...formValues,
                [`start_date`]: "",
                [`end_date`]: "",
            });
            setMeta(prevState => ({
                ...prevState,
                [`start_date`]: "",
                [`end_date`]: "",
                [`page`]: 1,
            }));
            setStartDate(null)
            setEndDate(null)
        }
    }, [invoice])

    return (
        <>
            {visible &&
                <div className="filtercontainer">
                    <div className="filteraccordion" onKeyUp={handleKeyUp}>
                        <Box
                            component="form"
                            style={{width:'1500px',margin:'auto',display:'flex',justifyContent:'center'}}
                            noValidate
                            autoComplete="off"
                        >
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 315 }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Stack spacing={3} style={{flexDirection:'row'}}>
                                        <DatePicker
                                            label="Start Date"
                                            value={startDate}
                                            disableFuture={true}
                                            inputFormat={"yyyy-MM-dd"}
                                            onChange={(newValue) => {
                                                onDateChange("start_date",newValue);
                                            }}
                                            renderInput={(params) => (
                                                <FormControl className="sc__minwidth--align" variant="standard" sx={{ m: 1, minWidth: 200 }}>
                                                <TextField
                                                variant="standard"
                                                label="Star Date"
                                                size={'small'}
                                                {...params}
                                                />
                                                </FormControl>
                                            )}
                                        />
                                        <DatePicker
                                            label="End Date"
                                            value={endDate}
                                            disableFuture={true}
                                            inputFormat={"yyyy-MM-dd"}
                                            onChange={(newValue) => {
                                                onDateChange("end_date",newValue);
                                            }}
                                            renderInput={(params) => (
                                                <FormControl className="sc__minwidth--align" variant="standard" sx={{ m: 1, minWidth: 200 }}>
                                                <TextField
                                                variant="standard"
                                                label="End Date"
                                                size={'small'}
                                                {...params}
                                                />
                                                </FormControl>
                                            )}
                                        />
                                    </Stack>
                                </LocalizationProvider>
                            </FormControl>
                        </Box>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={12}>
                                <div className="filterfooter">
                                    <Button variant="contained" onClick={submitFilterValues} disabled={filterButtonDisabled}>Filter</Button>
                                    <Button variant="outlined" onClick={resetFields} disabled={filterButtonDisabled}>Clear</Button>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            }
        </>
    )
}

export default Filters;
