import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import FormControlLabel from '@mui/material/FormControlLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { fetchEtsyData, fetchProgressData, configureStore, resetEtsyState } from './etsySlice';
import Notification from '../../../../../pages/General/Notification';
import ShipmentUpdateFlag from '../../../../General/ShipmentUpdateFlag';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    height: '100%',
    padding: '20px 0'
}));

const EtsyIntegration = ({ storeName, storeEtsy, platformName, storeParam, storeId  }) => {
    const dispatch = useDispatch();
    const profile = useSelector(state => state.auth);
    const etsyconfigdetails = useSelector(state => state.etsy && state.etsy.configure);
    const etsyshowdata = useSelector(state => state.etsy && state.etsy.showdata);
    const [storeUrl, setStoreUrl] = useState("")
    const [values, setValues] = useState({})

    const configurestore = e => {
        e.preventDefault()
        const data = { 
            "store_name": storeUrl,
            "is_update_shipment": values.is_update_shipment,
            "is_update_shipment_on_create": values.is_update_shipment_on_create,
            "is_update_shipment_on_close": values.is_update_shipment_on_close,
            }
        dispatch(configureStore(data));
    }

    useEffect(() => {
        if (storeName || storeEtsy) {
            let getStoreId=""
            if(storeName){
                getStoreId=storeName
                setStoreUrl(storeName)
            }else{
                getStoreId=storeEtsy
                setStoreUrl(storeEtsy)
            }
            let apikey = profile.profile.api_token
            const data = { apikey: apikey, store_name: getStoreId }
            dispatch(fetchEtsyData(data));
        }
    }, [storeName || storeEtsy]);

    const updateCallBackValue = (updateValue) => {
        setValues({
            ...values,
            ['is_update_shipment']: updateValue.is_update_shipment,
            ['is_update_shipment_on_create']: updateValue.is_update_shipment_on_create,
            ['is_update_shipment_on_close']: updateValue.is_update_shipment_on_close,
        });
    };

    useEffect(() => {
        if ((storeName || storeEtsy) && etsyshowdata && etsyshowdata.data && Object.keys(etsyshowdata.data).length>0) {
            console.log("success")
            setValues({
                ...values,
                ['is_update_shipment']: etsyshowdata && etsyshowdata.data && etsyshowdata.data.is_update_shipment,
                ['is_update_shipment_on_create']: etsyshowdata && etsyshowdata.data && etsyshowdata.data.is_update_shipment_on_create,
                ['is_update_shipment_on_close']: etsyshowdata && etsyshowdata.data && etsyshowdata.data.is_update_shipment_on_close,
            })
        }
    }, [etsyshowdata]);

    useEffect(() => {  
        dispatch(fetchProgressData())
    }, [dispatch]);

    return (
        <>
         {(etsyconfigdetails && etsyconfigdetails.notification && etsyconfigdetails.notification.messageContent!='') &&
            <Notification
                is_open={etsyconfigdetails.notification.show}
                messageType={etsyconfigdetails.notification.messageType}
                messages={etsyconfigdetails.notification.messageContent}
                handleClose={() => dispatch(resetEtsyState())}
            />}
            {storeUrl=='' &&
                <Grid container spacing={1}>
                    <Grid item xs={12} md={12} style={{ textAlign: 'center' }}>
                        <Item>
                            <div style={{ marginBottom: '20px' }}>
                                <img
                                    src="/assets/images/integration/ETSY.png"
                                    alt="Etsy"
                                    style={{ margin: 'auto', display: 'flex' }} />
                                <Button
                                    variant="contained"
                                    href={`https://www.etsy.com/oauth/connect?response_type=code&redirect_uri=${process.env.REACT_APP_INTEGRATIONS_URL}/etsy/callback&scope=transactions_r%20transactions_w%20email_r%20billing_r%20address_r%20shops_r&client_id=lorux0f6efzr3shvfc1maaii&state=${profile && profile.profile && profile.profile.id}&code_challenge=EFJQPpJgc83fWhV5DC2amk-KGi2SYE4xZt5izK-m4a0&code_challenge_method=S256`}
                                    // target="_blank"
                                >Install Etsy App
                                </Button>
                                <p>The term <b>'Etsy'</b> is a trademark of Etsy, Inc. This application uses the Etsy API but is not endorsed or certified by Etsy, Inc.</p>
                            </div>
                        </Item>
                    </Grid>
                </Grid>
            }
             
      {storeUrl!=='' &&

          <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                  <Item>
                      <div
                          className="sc--form--center"
                          style={{ margin: 'auto', textAlign: 'center', width: '30%' }}>
                          <TextField
                              id="standard-numbe"
                              label="Etsy Store"
                              value={storeUrl}
                              InputLabelProps={{
                                  shrink: true,
                              }}
                              InputProps={{
                                  readOnly: true,
                              }}
                              size="small"
                              style={{ width: "100%", margin: '8px' }}
                              variant="filled"
                          />
                      </div>
                      <ShipmentUpdateFlag
                          updateFlag={values.is_update_shipment}
                          onCreateFlag={values.is_update_shipment_on_create}
                          onCloseFlag={values.is_update_shipment_on_close}
                          updateCallBack={updateCallBackValue}
                          message={"Do you want to update shipment information to etsy store?"}
                      />
                      <div
                          className="sc--form--center"
                          style={{ margin: '10px 0', textAlign: 'center' }}
                      >
                          <Button
                              variant="contained"
                              onClick={configurestore}
                          >Configure
                          </Button>
                      </div>
                  </Item>
              </Grid>
          </Grid>
        }
        </>
    )
}

export default EtsyIntegration;