import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Mui Libraries
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';

// Slice
import { fetchPlatform, fetchCategory } from './integrationsSlice';

// Components
import PlatformDrawer from '../../General/PlatformDrawer';

const NonintegratedPage = () => {

    const dispatch = useDispatch();
    const integrations = useSelector(state => state.integrations);


    const [currentCategory, setCurrentCategory] = useState('all');
    const [platformName, setPlatformName] = useState('all');
    const [stepNumber, setStepNumber] = useState('all');
    const [orderDrawerVisible, setOrderDrawerVisible] = useState(false);

    const platformCallback = (item) => {
        if (item === 'Shopify APP') {
            item = 'SHOPIFY'

            setPlatformName(item)
            setOrderDrawerVisible(true)
        } else if (item === 'Brightpearl' || item === 'WooCommerce' || item === 'Bigcommerce' || item === 'Api' || item === 'Skulabs' || item === 'ShipStation' || item === 'Etsy') {
            setPlatformName(item)
            setOrderDrawerVisible(true)
        }
    }

    const orderOnClose = (values) => {
        setOrderDrawerVisible(false);
    };

    useEffect(() => {
        const params = new URLSearchParams(window.location.search) // id=123
        let platform = params.get('platform')
        if (platform === 'Shopify APP') {
            platform = 'SHOPIFY'
        }
        let step = params.get('step')
        setPlatformName(platform)
        setStepNumber(step)
        if (platform) {
            setOrderDrawerVisible(true)
        }
    }, [])

    useEffect(() => {
        dispatch(fetchCategory());
        dispatch(fetchPlatform());
    }, [dispatch]);




    const PlatformComponent = ({ platform = [], currentCategory, searchField }) => {
        return (
            <>
                {
                    platform && platform.map((item) => {
                        if (item.name === 'Shopify APP' || item.name === 'Brightpearl' || item.name === 'WooCommerce' || item.name === 'Bigcommerce' || item.name === 'Api' || item.name === 'Skulabs' || item.name === 'ShipStation'  || item.name === 'Etsy') {
                            return (
                                <Grid
                                    key={item.name}
                                    item xs={6} md={4}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            justifyContent: 'center',
                                            '& > :not(style)': {
                                                m: 1,
                                                width: 120,
                                            },
                                        }}
                                    >
                                        <div
                                            onClick={e => { platformCallback(item.name) }}
                                        >

                                            <Paper
                                                sx={{ height: 100 }}>
                                                <div
                                                    className="nonintegration--images">
                                                    <img
                                                        className="integration--logo"
                                                        src={item.image}
                                                        data-src={item.image}
                                                        alt={item.name}
                                                        title={item.name}
                                                    />
                                                </div>
                                            </Paper>
                                            <h4 title={item.name === 'Shopify APP' ? 'Shopify' : item.name === 'Api' ? 'Marketplace API' : item.name}>
                                                {item.name === 'Shopify APP' ? 'Shopify' : item.name === 'Api' ? 'Marketplace API' : item.name}
                                            </h4>

                                        </div>
                                    </Box>
                                </Grid>
                            )
                        }
                    })
                }
            </>
        )
    }
    return (
        <>
            <Grid container spacing={2}>
                {integrations && integrations.platform.loading === false &&
                    <PlatformComponent
                        platform=
                        {integrations && integrations.platform.data}
                        currentCategory={currentCategory}
                    />
                }
            </Grid>
            <PlatformDrawer
                visible={orderDrawerVisible}
                onClose={orderOnClose}
                platform={platformName}
                stepNumber={stepNumber}
            />
        </>
    );
};
export default NonintegratedPage;
