import React, { useEffect, useState, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import InputAdornment from '@mui/material/InputAdornment';
import { IconButton } from '@mui/material';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useDispatch, useSelector } from 'react-redux';
import { doLogin } from './authSlice';
const LoginPage = () => {
	const auth = useSelector(state => state.auth);
	const dispatch = useDispatch();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [error, setErrors] = useState(null);
	const [emailerror, setemailErrors] = useState(null);
	const [passworderror, setpasswordErrors] = useState(null);
	const [visible, SetVisible] = useState(false)
	const [showPassword, setShowPassword] = useState(false);
	const handleClickShowPassword = () => setShowPassword(!showPassword);
	const handleMouseDownPassword = () => setShowPassword(!showPassword);

	const validateerror = document.getElementsByClassName("validateErrors")	
	if (validateerror) {
		setTimeout(function () {
			SetVisible(true);
		}, 5000);
	}

	const handleSubmit = () => {
		if (email != '' && password != '') {
			const values = { 'email': email, 'password': password }
			dispatch(doLogin(values))
		} else {
			setErrors("All the fields must be entered");
		}
	}

	const emailValidate = () => {
		const emailValidation = !/\S+@\S+\.\S+/.test(email);
		if (emailValidation) {
			setemailErrors("Email address is invalid");
		} else {
			setemailErrors(null);
		}
	};

	const passwordValidate = () => {		
		if (password.length < 7) {
			setpasswordErrors("Password should be eight characters");
		} else {
			setpasswordErrors(null);
		}
	};


	return (
		<>
			<div className="vertical-center">
				<Paper elevation={3} sx={{ m: "10px", padding: "48px 32px" }} square={false}>
					<div className="loginlayout--image">
						<a href="/" className="logo-link">
							<img className="loginlogo" src="/assets/images/shippingchimp_logo.png" alt="logo-dark" />
						</a>
					</div>
					<Stack
						component="form"
						sx={{
							'& > :not(style)': { m: 1 }, width: "400px"
						}}
						Validate
						autoComplete="off"
					>
						{!visible && <> {error && <p className="validateErrors" style={{ color: 'red' }}>{error}</p>}</>}
						<TextField id="email" label="Email" size="small" required variant="outlined"
							onInput={e => setEmail(e.target.value)} onBlur={emailValidate} />
						{emailerror && <p style={{ color: 'red' }}>{emailerror}</p>}
						<TextField
							id="password"
							label="Password"
							size="small"
							onBlur={passwordValidate}
							required type={showPassword ? "text" : "password"}
							variant="outlined" value={password}
							onInput={e => setPassword(e.target.value)}
							onKeyUp={(event) => {
								if (event.key == 'Enter')
									handleSubmit()
							}}
							InputProps={{ // <-- This is where the toggle button is added.
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={handleClickShowPassword}
											onMouseDown={handleMouseDownPassword}
										>
											{showPassword ? <Visibility /> : <VisibilityOff />}
										</IconButton>
									</InputAdornment>
								)
							}} />
						{passworderror && <p style={{ color: 'red' }}>{passworderror}</p>}
						<div className="resetpassword"><a href="/passwordreset">Reset Password</a></div>
						{!visible && <>{(auth.notification.messageContent && auth.notification.messageContent.data) && <><div className="validateErrors" style={{ color: 'red' }}>{auth.notification.messageContent.data.message}</div></>}</>}
						{!visible && <>{(auth.notification.messageContent && auth.notification.messageContent) && <><div className="validateErrors" style={{ color: 'red' }}>{auth.notification.messageContent}</div></>}</>}

						<Button variant="contained" onClick={handleSubmit}>Login</Button>
					</Stack>
					<div className="logindefaultview--signup">
						<p>Don't have an account?  <a href="/register">Register</a></p>
					</div>
				</Paper>
			</div>
		</>
	)

}

export default LoginPage