import { createSlice } from '@reduxjs/toolkit';
import { OMSAPI } from '../../../../../API';

export const shipstationslice = createSlice({
    name: 'shipstation',
    initialState: {
        progress: {
            data: {},
            location: [],
            message: "",
            loading: false,
            hasErrors: false
        },
        configure: {
            data: {},
            message: "",
            loading: false,
            hasErrors: false
        }
    },
    reducers: {
        fetchShipStationDataDetails: (state) => {
            return {
                ...state,
                progress: {
                    loading: true
                }
            }
        },
        fetchShipStationDataDetailsSuccess: (state, action) => {
            return {
                ...state,
                progress: {
                    message: action && action.payload && action.payload.data && action.payload.data.messages,
                    data: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data,
                    location: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.location,
                    loading: false,
                    hasErrors: false,
                }
            }
        },
        fetchShipStationDataDetailsFailure: (state, action) => {
            return {
                ...state,
                progress: {
                    loading: false,
                    hasErrors: false,
                }
            }
        },
        postShipStationConfigure: (state) => {
            return {
                ...state,
                configure: {
                    loading: true
                }
            }
        },
        postShipStationConfigureSuccess: (state, action) => {
            return {
                ...state,
                configure: {
                    data: {},
                    message: action && action.payload && action.payload.data  && action.payload.data.message,
                    loading: false,
                    hasErrors: false,
                }
            }
        },
        postShipStationConfigureFailure: (state, action) => {
            return {
                ...state,
                configure: {
                    message: action && action.payload && action.payload.data && action.payload.data && action.payload.data.message,
                    loading: false,
                    hasErrors: true,
                }
            }
        },
        resetConfigureStateDetails: (state, action) => {
            return {
                ...state,
                configure: {
                    data: {},
                    message: '',
                    loading: false,
                    hasErrors: false,
                }
            }
        },
    }
})
export const { fetchShipStationDataDetails, fetchShipStationDataDetailsSuccess, fetchShipStationDataDetailsFailure,
    postShipStationConfigure, postShipStationConfigureSuccess, postShipStationConfigureFailure, resetConfigureStateDetails } = shipstationslice.actions;

export const configureStore = data => dispatch => {
    dispatch(postShipStationConfigure());
    try {
        OMSAPI.post('/shipstation/configure', data)
            .then(function (response) {
                dispatch(postShipStationConfigureSuccess(response));
            }).catch(function (error) {
                dispatch(postShipStationConfigureFailure(error.response));
            })
    }
    catch (error) {
        dispatch(postShipStationConfigureFailure(error.response));
    }
}

export const fetchShipStationData = data => dispatch => {

    dispatch(fetchShipStationDataDetails())
    try {
        OMSAPI.post('/shipstation/show', data)
            .then(function (response) {
                dispatch(fetchShipStationDataDetailsSuccess(response));
            }).catch(function (error) {
                dispatch(fetchShipStationDataDetailsFailure());
            })
    }
    catch (error) {
        dispatch(fetchShipStationDataDetailsFailure())
    }
}

export const resetConfigureState = data => dispatch => {
    dispatch(resetConfigureStateDetails());
}
export default shipstationslice.reducer;
