import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// mui
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { useForm } from '../../../pages/General/useForm';
import FormHelperText from '@mui/material/FormHelperText';
import SendIcon from '@mui/icons-material/Send';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
// Our components
import RateDrawer from '../../../pages/General/RateDrawer'
// slice
import { doGetRate } from './rateSlice';
import { fetchSenderAddress } from '../../../pages/Settings/AddressSender/addressSenderSlice';
import { fetchCountry, fetchState } from '../../../pages/General/countryStateCitySlice';
import { fetchBoxData } from '../../../pages/Settings/Box/boxSlice';

//Custom Components Import Ends

const NewShipmentsPage = () => {
    const dispatch = useDispatch();
    // state
    const box = useSelector(state => state.box);
    const addresssender = useSelector(state => state.addresssender);
    const profile = useSelector(state => state.auth);
    const country_code = useSelector(state => state.countrystate.country);
    const state = useSelector(state => state.countrystate.state);
    // const
    const [chooseMeasurement, setChooseMeasurement] = useState("");
    const [formDataValues, setFormDataValues] = useState("")
    const [senderAddressObjectId, setSenderAddressObjectId] = useState('');
    const [visible, setVisible] = useState(false);
    const filteredCountryData = country_code.data.filter(({ code }) => code );
    const onClose = (values) => {
        setVisible(false);
    };
    const initialFValues = {
        name: '',
        company: '',
        email: '',
        address: '',
        city: '',
        postal_code: '',
        country_code: '',
        weight: '',
        province: '',
        preferred_sender_id: '',
        length: '',
        weight: '',
        height: '',
        width: '',
        dimension_unit: 'in',
        weight_unit: 'lb',
        choosemeasurement: ''
    }
    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('name' in fieldValues)
            temp.name = fieldValues.name ? "" : "This field is required."
        if ('company' in fieldValues)
            temp.company = fieldValues.company ? "" : "This field is required."
        if ('email' in fieldValues)
            temp.email = fieldValues.email ? "" : "This field is required."
        if ('address' in fieldValues)
            temp.address = fieldValues.address ? "" : "This field is required."
        if ('city' in fieldValues)
            temp.city = fieldValues.city ? "" : "This field is required."
        if ('postal_code' in fieldValues)
            temp.postal_code = fieldValues.postal_code ? "" : "This field is required."
        if (fieldValues && fieldValues.postal_code && fieldValues.postal_code.match(/[^0-9a-z]/i)) {
            temp.postal_code = "Postal code only accepts alpha numeric"
        }
        if ('country_code' in fieldValues)
            temp.country_code = fieldValues.country_code ? "" : "This field is required."
        if ('province' in fieldValues)
            temp.province = fieldValues.province ? "" : "This field is required."
        if ('preferred_sender_id' in fieldValues)
            temp.preferred_sender_id = fieldValues.preferred_sender_id ? "" : "This field is required."
        if (chooseMeasurement === 'custom') {
            if ('length' in fieldValues)
                temp.length = fieldValues.length ? "" : "This field is required."
        }
        if (chooseMeasurement === 'custom') {
            if ('weight' in fieldValues)
                temp.weight = fieldValues.weight ? "" : "This field is required."
        }
        if (chooseMeasurement === 'custom') {
            if ('height' in fieldValues)
                temp.height = fieldValues.height ? "" : "This field is required."
        }
        if (chooseMeasurement === 'custom') {
            if ('width' in fieldValues)
                temp.width = fieldValues.width ? "" : "This field is required."
        }
        if (chooseMeasurement === 'custom') {
            if ('weight_unit' in fieldValues)
                temp.weight_unit = fieldValues.weight_unit ? "" : "This field is required."
        }
        if (chooseMeasurement === 'custom') {
            if ('dimension_unit' in fieldValues)
                temp.dimension_unit = fieldValues.dimension_unit ? "" : "This field is required."
        }
        setErrors({
            ...temp
        })
        if (fieldValues === values)
            return Object.values(temp).every(x => x === "")
    }
    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange
    } = useForm(initialFValues, validate, true);

    const onsenderaddresschange = (id) => {
        setSenderAddressObjectId(id)
        setValues({
            ...values,
            ['preferred_sender_id']: id,
        })
    }
    const onChangeMeasurement = (value) => {
        setChooseMeasurement(value)
        setValues({
            ...values,
            ['choosemeasurement']: value,
        })
        if (value === 'custom') {
            setValues({
                ...values,
                ['length']: "",
                ['height']: "",
                ['weight']: "",
                ['width']: ""
            })
        } else {
            box && box.data && box.data.map((item) => {
                if (item.box_name === value) {
                    setValues({
                        ...values,
                        ['length']: item.box_length,
                        ['height']: item.box_height,
                        ['weight_unit']: item.box_weight_unit,
                        ['weight']: item.box_weight,
                        ['width']: item.box_width,
                        ['dimension_unit']: "in",
                    })
                    setErrors({ name: "" })
                }
            })
        }
    }
    const onChangeCountry = (countryCode) => {
        setValues({
            ...values,
            ['country_code']: countryCode
        })
        setErrors({ "country_code": "" })
        dispatch(fetchState(countryCode));
    }
    const handleSubmit = e => {
        e.preventDefault()
        if (validate()) {
            const dmunit = values.length + "x" + values.width + "x" + values.height
            const dm = { "package_dimension": dmunit }
            Object.assign(values, dm);
            let apikey = (profile.profile.api_token) ? profile.profile.api_token : "";
            setFormDataValues(values)
            dispatch(doGetRate(values, apikey));
            setVisible(true)
        }
    }
    // useEffect
    useEffect(() => {
        dispatch(fetchBoxData())
        dispatch(fetchCountry());
        dispatch(fetchSenderAddress());
    }, [dispatch]);

    useEffect(() => {
        if (addresssender && addresssender.data) {
            addresssender.data.map((item) => {
                if (item && item.default) {
                    setSenderAddressObjectId(item._id)
                    setValues({
                        ...values,
                        ['preferred_sender_id']: item._id,
                    })
                }
            })
        }
    }, [addresssender]);

    useEffect(() => {
        if (box && box.data && box.data.length !== 0) {
            box && box.data && box.data.map((item) => {
                if (item.box_default === true) {
                    setChooseMeasurement(item.box_name)
                    setValues({
                        ...values,
                        ['choosemeasurement']: item.box_name,
                        ['length']: item.box_length,
                        ['height']: item.box_height,
                        ['weight_unit']: item.box_weight_unit,
                        ['weight']: item.box_weight,
                        ['width']: item.box_width,
                        ['dimension_unit']: "in",
                    })
                }
            })
        } else {
            setChooseMeasurement('custom')
            setValues({
                ...values,
                ['choosemeasurement']: 'custom',
            })
        }
    }, [box && box.data]);

    return (
        <>
            {(box.loading === true) && <>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={true}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </>
            }
            {(box.loading === false) && <>
                <div className="scmaincard">
                    <div className="sccardhead">
                        <Typography variant="h5" gutterBottom component="div">
                            New Order
                    </Typography>
                    </div>
                    <div className="sc--content--body">
                        <div className="sc--content--card" style={{ margin: 'auto' }}>
                            <Grid
                                container
                                direction='row'
                                spacing={2}
                                alignItems="stretch"
                            >
                                <Grid item xs={6} md={6} style={{ height: '100%', width: '50%' }}>
                                    <Card
                                        sx={{ minWidth: 275, boxShadow: 'unset', border: '1px solid rgba(0, 0, 0, 0.12)' }}
                                        className="sc--cardhead--details">
                                        <CardHeader title='Sender' />
                                        <Divider />
                                        <CardContent>
                                            <Box
                                                component="form"
                                                sx={{
                                                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                                                }}
                                                noValidate
                                                autoComplete="off"
                                            >
                                                <div className="sc--form--center" style={{ margin: '20px 0' }}>
                                                    <FormControl
                                                        variant="standard"
                                                        style={{ width: "68ch", margin: '8px' }} >
                                                        <InputLabel
                                                            id="demo-simple-select-standard-label"
                                                            style={{ color: errors.preferred_sender_id ? "#D32F2F" : '' }}
                                                        >Sender Address
                                                    </InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-standard-label"
                                                            id="demo-simple-select-standard"
                                                            name="preferred_sender_id"
                                                            value={senderAddressObjectId}
                                                            error={errors.preferred_sender_id}
                                                            onChange={e => {
                                                                onsenderaddresschange(e.target.value)
                                                                setErrors({ "preferred_sender_id": "" })
                                                            }}
                                                            label="Sender Address"
                                                        >
                                                            {addresssender && addresssender.data && addresssender.data.map((item) => {
                                                                return (
                                                                    <MenuItem value={item._id}>
                                                                        <p style={{ margin: 'unset' }}>{item.sender_name} ({item.sender_company})<br />
                                                                            {item.sender_address}, {item.sender_city}, {item.sender_state_code}, {item.sender_country_code} - {item.sender_postal_code}
                                                                        </p>
                                                                    </MenuItem>
                                                                )
                                                            })}
                                                        </Select>

                                                        <FormHelperText>
                                                            {(addresssender && addresssender.loading === false)
                                                                && addresssender && addresssender.data && addresssender.data.length == 0 &&
                                                                <span style={{ color: "red" }}>
                                                                    Please add sender address
                                                                   <Link to="/settings/address-sender" style={{ color: 'blue', textDecoration: 'none' }}> here </Link>
                                                                </span>
                                                            }
                                                            {errors.preferred_sender_id && addresssender && addresssender.data && addresssender.data.length !== 0 &&
                                                                <span style={{ color: "red" }}>
                                                                    This field is required.
                                                            </span>
                                                            }
                                                        </FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                    <Card
                                        sx={{ minWidth: 275, marginTop: '20px', boxShadow: 'unset', border: '1px solid rgba(0, 0, 0, 0.12)' }}
                                        className="sc--cardhead--details">
                                        <CardHeader title='Measurement' />
                                        <Divider />
                                        <CardContent>
                                            <Box
                                                component="form"
                                                sx={{
                                                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                                                }}
                                                noValidate
                                                autoComplete="off"
                                            >
                                                {box && box.data.length > 0 &&
                                                    <div className="sc--form--center" style={{ margin: '20px 0' }}>
                                                        <FormControl
                                                            variant="standard"
                                                            className="help-text-error"
                                                            style={{ width: '68ch', margin: '8px' }}
                                                        >
                                                            <InputLabel
                                                                id="demo-simple-select-standard-label"
                                                            >Choose Measurement
                                                        </InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-standard-label"
                                                                id="demo-simple-select-standard"
                                                                name="choosemeasurement"
                                                                value={chooseMeasurement}
                                                                onChange={(e) => {
                                                                    onChangeMeasurement(e.target.value)
                                                                }
                                                                }
                                                                error={errors.chooseMeasurement}
                                                                label="Choose measurement"
                                                            >

                                                                {box && box.data && box.data.map((item) => {
                                                                    return (
                                                                        <MenuItem value={item.box_name}>
                                                                            <p style={{ margin: 'unset', color: 'rgba(0, 0, 0, 0.87)' }}>
                                                                                {item.box_name}(
                                                                                {item.box_length}{item.box_length_unit} <span style={{ color: '#0082ff' }}> x </span>
                                                                                {item.box_width}{item.box_width_unit} <span style={{ color: '#0082ff' }}> x </span>
                                                                                {item.box_height}{item.box_height_unit}<span>, </span>
                                                                                {item.box_weight}{item.box_weight_unit})
                                                                            </p>
                                                                        </MenuItem>
                                                                    )
                                                                    // }

                                                                })}
                                                                <MenuItem value="custom">Custom</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                }
                                                {box && box.loading === false && (chooseMeasurement && chooseMeasurement === 'custom' || box && box.data.length === 0) &&
                                                    <>
                                                        <div className="sc--form--center" style={{ margin: '10px 0' }}>
                                                            <TextField id="standard-basic"
                                                                label="Length"
                                                                variant="standard"
                                                                name="length"
                                                                type="number"
                                                                inputProps={{ min: 1 }}
                                                                value={values.length}
                                                                onChange={handleInputChange}
                                                                error={errors.length}
                                                                helperText={errors.length}
                                                                style={{ width: '16%' }}
                                                            />
                                                            <TextField id="standard-basic"
                                                                label="Width"
                                                                variant="standard"
                                                                name="width"
                                                                type="number"
                                                                inputProps={{ min: 1 }}
                                                                value={values.width}
                                                                onChange={handleInputChange}
                                                                error={errors.width}
                                                                helperText={errors.width}
                                                                style={{ width: '16%' }}
                                                            />
                                                            <TextField id="standard-basic"
                                                                label="Height"
                                                                variant="standard"
                                                                name="height"
                                                                type="number"
                                                                inputProps={{ min: 1 }}
                                                                value={values.height}
                                                                onChange={handleInputChange}
                                                                error={errors.height}
                                                                helperText={errors.height}
                                                                style={{ width: '16%' }}
                                                            />
                                                            <FormControl
                                                                variant="standard"
                                                                className="help-text-error"
                                                                style={{ width: '16%', margin: '8px' }}
                                                            >
                                                                <InputLabel
                                                                    style={{ color: errors.dimension_unit ? "#D32F2F" : '' }}
                                                                > Dim Unit
                                                              </InputLabel>
                                                                <Select
                                                                    name="dimension_unit"
                                                                    onChange={handleInputChange}
                                                                    value={values.dimension_unit}
                                                                    error={errors.dimension_unit}
                                                                    label="Dimension Unit"
                                                                    id="filled-error-helper-text"
                                                                >
                                                                    <MenuItem value="in">in</MenuItem>
                                                                    <MenuItem value="cm">cm</MenuItem>

                                                                </Select>
                                                                <FormHelperText>{errors.dimension_unit}</FormHelperText>
                                                            </FormControl>
                                                            <TextField id="standard-basic"
                                                                label="Weight"
                                                                variant="standard"
                                                                name="weight"
                                                                type="number"
                                                                inputProps={{ min: 1 }}
                                                                value={values.weight}
                                                                onChange={handleInputChange}
                                                                error={errors.weight}
                                                                helperText={errors.weight}
                                                                style={{ width: '16%' }}
                                                            />
                                                            <FormControl
                                                                variant="standard"
                                                                className="help-text-error"
                                                                style={{ width: '16%', margin: '8px' }}
                                                            >
                                                                <InputLabel
                                                                    id="demo-simple-select-standard-label"
                                                                    style={{ color: errors.weight_unit ? "#D32F2F" : '' }}
                                                                > Weight Unit
                                                              </InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-standard-label"
                                                                    name="weight_unit"
                                                                    onChange={handleInputChange}
                                                                    value={values.weight_unit}
                                                                    error={errors.weight_unit}
                                                                    label="Weight Unit"
                                                                    id="filled-error-helper-text"
                                                                >
                                                                    <MenuItem value="kg">kg</MenuItem>
                                                                    <MenuItem value="lb">lb</MenuItem>

                                                                </Select>
                                                                <FormHelperText>{errors.weight_unit}</FormHelperText>
                                                            </FormControl>
                                                        </div>
                                                    </>
                                                }
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={6} md={6} style={{ width: '50%' }}>
                                    <Card sx={{ minWidth: 275, height: '100%', boxShadow: 'unset', border: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                        <CardHeader title='Recipient' className="sc--cardhead--details" />
                                        <Divider />
                                        <CardContent>
                                            <Box
                                                component="form"
                                                sx={{
                                                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                                                }}
                                                noValidate
                                                autoComplete="off"
                                            >
                                                <div className="sc--form--center">
                                                    <TextField id="standard-basic"
                                                        label="Name"
                                                        variant="standard"
                                                        name="name"
                                                        value={values.name}
                                                        onChange={handleInputChange}
                                                        error={errors.name}
                                                        helperText={errors.name}
                                                        style={{ width: 300 }}
                                                    />
                                                    <TextField id="standard-basic"
                                                        label="Company"
                                                        variant="standard"
                                                        name="company"
                                                        value={values.company}
                                                        onChange={handleInputChange}
                                                        error={errors.company}
                                                        helperText={errors.company}
                                                        style={{ width: 300 }} />

                                                </div>
                                                <div className="sc--form--center">
                                                    <TextField id="standard-basic"
                                                        label="Phone Number"
                                                        name="phone"
                                                        variant="standard"
                                                        onChange={handleInputChange}
                                                        style={{ width: 300 }}
                                                    />
                                                    <TextField id="standard-basic"
                                                        label="Email"
                                                        variant="standard"
                                                        name="email"
                                                        value={values.email}
                                                        onChange={handleInputChange}
                                                        error={errors.email}
                                                        helperText={errors.email}
                                                        style={{ width: 300 }} />
                                                </div>
                                                <div className="sc--form--center">
                                                    <TextField id="standard-basic"
                                                        label="Address"
                                                        variant="standard"
                                                        name="address"
                                                        value={values.address}
                                                        onChange={handleInputChange}
                                                        error={errors.address}
                                                        helperText={errors.address}
                                                        style={{ width: '68ch' }} />
                                                </div>
                                                <div className="sc--form--center">
                                                    <TextField id="standard-basic"
                                                        label="City"
                                                        variant="standard"
                                                        name="city"
                                                        value={values.city}
                                                        onChange={handleInputChange}
                                                        error={errors.city}
                                                        helperText={errors.city}
                                                        style={{ width: 300 }} />
                                                    <TextField id="standard-basic"
                                                        label="Postal Code"
                                                        variant="standard"
                                                        name="postal_code"
                                                        inputProps={{ maxLength: 6 }}
                                                        value={values.postal_code.toUpperCase()}
                                                        onChange={handleInputChange}
                                                        error={errors.postal_code}
                                                        helperText={errors.postal_code}
                                                        style={{ width: 300 }} />
                                                </div>
                                                <div className="sc--form--center">
                                                    <FormControl variant="standard" className="help-text-error" style={{ width: 300, margin: '8px' }} >
                                                        <InputLabel id="demo-simple-select-standard-label" style={{ color: errors.country_code ? "#D32F2F" : '' }}>Country</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-standard-label"
                                                            id="demo-simple-select-error"
                                                            name="country_code"
                                                            error={errors.country_code}
                                                            onChange={(event) => {
                                                                onChangeCountry(event.target.value);
                                                            }
                                                            }
                                                            label="Country"
                                                        >
                                                            {filteredCountryData.map((key) => {
                                                                return <MenuItem value={key.code}>{key.icon} {key.name}</MenuItem>
                                                            })}
                                                        </Select>
                                                        <FormHelperText>{errors.country_code}</FormHelperText>
                                                    </FormControl>
                                                    <FormControl variant="standard" className="help-text-error" style={{ width: 300, margin: '8px' }} >
                                                        <InputLabel id="demo-simple-select-standard-label" style={{ color: errors.province ? "#D32F2F" : '' }}>Province / State</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-standard-label"
                                                            id="demo-simple-select-standard"
                                                            onChange={handleInputChange}
                                                            value={values.province}
                                                            name="province"
                                                            error={errors.province}
                                                            label="Province / State"
                                                        >
                                                            {state && state.data.map((key) => {
                                                                return <MenuItem value={key.code}>{key.name}</MenuItem>
                                                            })}
                                                        </Select>
                                                        <FormHelperText>{errors.province}</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                            <div className="sc--form--center" style={{ margin: '30px 0' }}>
                                {(addresssender && addresssender.loading === false) && addresssender.data && addresssender.data.length === 0 &&
                                    <Button
                                        variant="outlined"
                                        onClick={handleSubmit}
                                        disabled
                                    >
                                        <SendIcon style={{ paddingRight: '10px' }} /> Fetch Rates
                                </Button>
                                }
                                {(addresssender && addresssender.loading === false) && addresssender.data && addresssender.data.length !== 0 &&
                                    <Button
                                        variant="outlined"
                                        onClick={handleSubmit}
                                        style={{ border: '1px solid rgba(0, 0, 0, 0.12)' }}
                                    >
                                        <SendIcon style={{
                                            paddingRight: '10px'
                                        }} /> Fetch Rates
                                </Button>
                                }
                            </div>
                        </div>
                        <RateDrawer
                            visible={visible} onClose={onClose} formDataValues={formDataValues}
                        />
                    </div>
                </div>
            </>
            }
        </>
    );
}

export default NewShipmentsPage;
