import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LinearProgress from '@mui/material/LinearProgress';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { DataGrid } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Snackbar from '@mui/material/Snackbar';
import Tooltip from '@mui/material/Tooltip';
import Alert from '@mui/material/Alert';
import Modal from '@mui/material/Modal';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import SettingsStatus from '../../../pages/General/SettingsStatus'
import { fetchPickupAddress, destoryPickupAddress, storePickupAddress, updatePickupAddress, resetPickupAddressState } from './pickupAddressSlice';
import { fetchCountry, fetchState } from '../../../pages/General/countryStateCitySlice';

import NorowsData from '../../../pages/General/NorowsData'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper'
};
const deleteModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};
const Modalpopup = ({ modalVisible, setModalVisible, formValues, setFormValues, snackbar }) => {
    const dispatch = useDispatch();
    const addressPickup = useSelector(state => state.addressPickup);
    const country = useSelector(state => state.countrystate.country);
    const state = useSelector(state => state.countrystate.state);
    const filteredCountryData = country.data.filter(({ code }) => code );
    const initialPickupAddressValues = {
        name: '',
        address: '',
        address2: '',
        city: '',
        company: '',
        country_code: '',
        email: '',
        phone: '',
        postal_code: '',
        state_code: '',
        status: true,
        default: ''
    }

    const [values, setValues] = useState(formValues && Object.keys(formValues).length === 0 ? initialPickupAddressValues : formValues);
    const [errors, setErrors] = useState({});    

    useEffect(() => {
        if (formValues && formValues.country_code) {
            dispatch(fetchState(formValues && formValues.country_code));
        }
    }, [formValues]);

    useEffect(() => {
        if (formValues && Object.keys(formValues).length !== 0) {
            let tempValues = {
                name: formValues['name'],
                address: formValues['address'],
                address2: formValues['address2'],
                city: formValues['city'],
                company: formValues['company'],
                country_code: formValues['country_code'],
                email: formValues['email'],
                phone: formValues['phone'],
                postal_code: formValues['postal_code'],
                state_code: formValues['state_code'],
                status: formValues['status'],
                default: formValues['default']
            };
            setValues(tempValues)
        }
    }, [formValues])

    const onModalClose = () => {
        setModalVisible(false)
    }


    const validator = (fieldValues) => {
        let temp = { ...errors }
        if ('name' in fieldValues)
            temp.name = fieldValues.name ? "" : "This field is required."
        if (fieldValues && fieldValues.name && fieldValues.name.length < 3)
            temp.name = "Name required minimum 3 characters"
        if ('address' in fieldValues)
            temp.address = fieldValues.address ? "" : "This field is required."
        if ('city' in fieldValues)
            temp.city = fieldValues.city ? "" : "This field is required."
        if ('company' in fieldValues)
            temp.company = fieldValues.company ? "" : "This field is required."
        if ('country_code' in fieldValues)
            temp.country_code = fieldValues.country_code ? "" : "This field is required."
        if ('email' in fieldValues)
            temp.email = fieldValues.email ? "" : "This field is required."
        if ('phone' in fieldValues)
            temp.phone = fieldValues.phone ? "" : "This field is required."
        if (fieldValues && fieldValues.phone && fieldValues.phone.match(/[^0-9+().,]/i)) {
            temp.phone = "phone number must be valid"
        }
        if ('postal_code' in fieldValues)
            temp.postal_code = fieldValues.postal_code ? "" : "This field is required."
        if ((fieldValues && fieldValues.postal_code && fieldValues.postal_code.length < 6) ||
            (fieldValues && fieldValues.postal_code && fieldValues.postal_code === null))
            temp.postal_code = "Postalcode required minimum 6 characters"
        if (fieldValues && fieldValues.postal_code && fieldValues.postal_code.match(/[^0-9a-z]/i)) {
            temp.postal_code = "Postal code only accepts alpha numeric"
        }
        if ('state_code' in fieldValues)
            temp.state_code = fieldValues.state_code ? "" : "This field is required."


        setErrors({
            ...temp
        })

        if (fieldValues === values) {
            return Object.values(temp).every(x => x == "")
        }
    }


    const onInputChange = e => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
        validator({ [name]: value })
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: e.target.checked,
        });
        validator({ [name]: value })
    };

    const onSelectCountryChange = (countryCode) => {
        setValues({
            ...values,
            country_code: countryCode
        })
        setErrors({ "country_code": "" })
        dispatch(fetchState(countryCode));
    }

    const handleSubmit = e => {
        e.preventDefault()
        const isValid = validator(values)
        if (isValid) {
            if ("_id" in formValues) {
                dispatch(updatePickupAddress(formValues._id, values))
                snackbar(true)
            } else {
                dispatch(storePickupAddress(values))
                snackbar(true)
            }
        }

    }
    useEffect(() => {
        if (addressPickup && addressPickup.message) {
            if(addressPickup && addressPickup.messageType=='success'){
                setValues(initialPickupAddressValues)
                setErrors({})
            }
        }
    }, [addressPickup]);
    return (

        <Modal
            open={modalVisible}
            onClose={onModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Card sx={{ minWidth: 600 }} className="sc--cardhead--details">
                    <CardHeader title='Pickup Address' />
                    <Divider />
                    <CardContent>
                        <div className="sc--form--center" style={{ margin: '20px 0' }}>
                            <TextField
                                label="Name"
                                name="name"
                                defaultValue={formValues && formValues.name}
                                onChange={onInputChange}
                                error={errors.name}
                                helperText={errors.name}
                                inputProps={{ maxLength: 40 }}
                                style={{ width: "100%", margin: '8px' }}
                                variant="standard"
                            />
                            <TextField
                                label="Company"
                                name="company"
                                defaultValue={formValues && formValues.company}
                                onChange={onInputChange}
                                error={errors.company}
                                helperText={errors.company}
                                inputProps={{ maxLength: 40 }}
                                variant="standard" style={{ width: "100%", margin: '8px' }}
                            />
                        </div>
                        <div className="sc--form--center" style={{ margin: '20px 0' }}>
                            <TextField
                                label="Phone"
                                name="phone"
                                defaultValue={formValues && formValues.phone}
                                onChange={onInputChange}
                                error={errors.phone}
                                inputProps={{ maxLength: 13 }}
                                helperText={errors.phone}
                                variant="standard" style={{ width: "100%", margin: '8px' }}
                            />
                            <TextField
                                label="Email"
                                name="email"
                                defaultValue={formValues && formValues.email}
                                onChange={onInputChange}
                                error={errors.email}
                                inputProps={{ maxLength: 60 }}
                                helperText={errors.email}
                                variant="standard" style={{ width: "100%", margin: '8px' }}
                            />
                        </div>
                        <div className="sc--form--center" style={{ margin: '20px 0' }}>
                            <TextField
                                label="Address1"
                                name="address"
                                defaultValue={formValues && formValues.address}
                                onChange={onInputChange}
                                error={errors.address}
                                inputProps={{ maxLength: 35 }}
                                helperText={errors.address}
                                variant="standard" style={{ width: "100%", margin: '8px' }}
                            />
                            <TextField
                                label="Address2"
                                name="address2"
                                defaultValue={formValues && formValues.address2}
                                onChange={onInputChange}
                                error={errors.address2}
                                inputProps={{ maxLength: 35 }}
                                helperText={errors.address2}
                                variant="standard" style={{ width: "100%", margin: '8px' }}
                            />
                        </div>
                        <div className="sc--form--center" style={{ margin: '20px 0' }}>
                            <TextField
                                label="Postal Code"
                                name="postal_code"
                                defaultValue={formValues && formValues.postal_code}
                                onChange={onInputChange}
                                inputProps={{ maxLength: 6 }}
                                error={errors.postal_code}
                                helperText={errors.postal_code}
                                variant="standard" style={{ width: "100%", margin: '8px' }}
                            />
                            <TextField
                                label="City"
                                name="city"
                                defaultValue={formValues && formValues.city}
                                onChange={onInputChange}
                                error={errors.city}
                                inputProps={{ maxLength: 30 }}
                                helperText={errors.city}
                                variant="standard" style={{ width: "100%", margin: '8px' }}
                            />
                        </div>
                        <div className="sc--form--center" style={{ margin: '20px 0' }}>
                            <FormControl variant="standard" className="help-text-error" style={{ width: "100%", margin: '8px' }} >
                                <InputLabel id="demo-simple-select-standard-label">Country</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-error"
                                    name="country_code"
                                    defaultValue={formValues && formValues.country_code}
                                    error={errors.country_code}
                                    onChange={(event) => {
                                        onSelectCountryChange(event.target.value);
                                    }
                                    }
                                    label="Country"
                                >
                                    {filteredCountryData.map((key) => {
                                        return <MenuItem value={key.code}>{key.icon} {key.name}</MenuItem>
                                    })}
                                </Select>
                                <FormHelperText>{errors.country_code}</FormHelperText>
                            </FormControl>

                            <FormControl variant="standard" className="help-text-error" style={{ width: "100%", margin: '8px' }} >
                                <InputLabel id="demo-simple-select-standard-label">Province / State</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    onChange={onInputChange}
                                    name="state_code"
                                    defaultValue={formValues && formValues.state_code}
                                    error={errors.state_code}
                                    label="Province / State"
                                >
                                    {state && state.data.map((key) => {
                                        return <MenuItem key={key.code} value={key.code}>{key.name}</MenuItem>
                                    })}
                                </Select>
                                <FormHelperText>{errors.state_code}</FormHelperText>
                            </FormControl>
                        </div>
                        <div style={{ margin: '20px 0' }}>
                            <FormGroup>
                                <FormControlLabel control={<Switch defaultChecked={formValues && formValues.default} name="default" onChange={handleChange} />} label="Default" />
                            </FormGroup>
                        </div>



                        <div className="sc--form--center" style={{ margin: '30px 0' }}>
                            <Button variant="outlined" onClick={handleSubmit}>Submit</Button>
                        </div>
                    </CardContent>
                </Card>
            </Box>
        </Modal >
    )
}
const PickupAddressPage = () => {

    const dispatch = useDispatch();
    const pickupaddress = useSelector(state => state.pickupaddress);

    const [alert, setAlert] = useState(false);
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [deleteModalopen, setDeleteModalopen] = React.useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [deleteid, setDeleteId] = React.useState("");

    useEffect(() => {
        dispatch(fetchPickupAddress());
        dispatch(fetchCountry());
    }, [dispatch]);

    useEffect(() => {
        if (pickupaddress && pickupaddress.message) {
            setAlert(true)
            setSnackOpen(true)
            if(pickupaddress && pickupaddress.messageType=='success'){
                setModalVisible(false)
                setSelectedItem({})
            }
        }
    }, [pickupaddress]);

    const handleClick = (id) => {
        setDeleteId(id)
        setDeleteModalopen(true);
    };

    const deleteModalClose = () => {
        setDeleteModalopen(false);
    };

    const deleteaddress = (id) => {
        dispatch(destoryPickupAddress(id))
        setAlert(true)
        setSnackOpen(true)
        setDeleteModalopen(false);
    }
    const snackhandleClose = () => {
        setAlert(false)
        setSnackOpen(false)
        dispatch(resetPickupAddressState())
    };

    const snackbar = (value) => {
        setSnackOpen(value)
    }

    const columns = [
        { field: 'name', headerName: 'Name', width: 100 },
        { field: 'company', headerName: 'Company Name', width: 130 },
        { field: 'phone', headerName: 'Phone Number', width: 100 },
        { field: 'email', headerName: 'Email', width: 130 },
        { field: 'address', headerName: 'Address', width: 130 },
        { field: 'city', headerName: 'City', width: 80 },
        { field: 'state_code', headerName: 'Province', width: 50 },
        { field: 'country_code', headerName: 'Country', width: 50 },
        { field: 'postal_code', headerName: 'Postal', width: 80 },
        {
            field: 'status',
            headerName: 'Status',
            width: 80,
            renderCell: (params) => {
                return <SettingsStatus settingsStatus={params.row.status} />
            }
        },{
            field: 'default',
            headerName: 'Default',
            width: 80,
            renderCell: (params) => {
                return <div>
                    {params.row.default == true && <Chip label="Yes" color="success" style={{
                            fontSize: '10px', height: '20px', borderRadius: '5px'}} />}
                    {params.row.default == false && <Chip label="No" color="success" style={{
                            fontSize: '10px', height: '20px', borderRadius: '5px'}} />}
                </div>
            }
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 100,
            renderCell: (params) => {
                return <div>
                    <span>
                        <Tooltip title="Edit" placement="left-start" disableFocusListener={false}>
                            <IconButton>
                                <EditIcon style={{ color: 'rgba(0,0,0,.54)', cursor: 'pointer' }} onClick={() => {
                                    setSelectedItem(params.row);
                                    setModalVisible(true)
                                }} />
                            </IconButton>
                        </Tooltip>
                    </span>
                    <span>
                        <Tooltip title="Delete" placement="left-start" disableFocusListener={false}>
                            <IconButton>
                                <DeleteOutlineOutlinedIcon style={{ color: 'rgba(0,0,0,.54)', cursor: 'pointer' }}
                                    onClick={(e) => {
                                        handleClick(params.row._id)
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                    </span>
                </div>
            }
        }
    ];
    return (
        <div className="sc--settings--card">
            <Modal
                open={deleteModalopen}
                onClose={deleteModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={deleteModalStyle}>
                    <Typography sx={{ p: 2 }}>Are you sure want to delete this pickuo address?<br />
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <span style={{ margin: '0 10px' }}>
                                <Button
                                    variant="contained"
                                    style={{ margin: "10px 0" }}
                                    onClick={(event) => {
                                        deleteaddress(deleteid)
                                    }}
                                >
                                    Yes
                                </Button>
                            </span>
                            <span>
                                <Button
                                    variant="outlined"
                                    style={{ margin: "10px 0" }}
                                    onClick={deleteModalClose}
                                >
                                    No
                                 </Button>
                            </span>
                        </div>
                    </Typography>
                </Box>
            </Modal>
            <Modalpopup
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}
                formValues={selectedItem}
                setFormValues={setSelectedItem}
                snackbar={snackbar}
            />
            {alert && (pickupaddress && pickupaddress.loading === false) &&
                <Snackbar
                    open={snackOpen}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    autoHideDuration={6000}
                    onClose={snackhandleClose}
                >
                    <Alert
                        severity={pickupaddress && pickupaddress.hasErrors === true ? "error" : "success"}
                        style={{ justifyContent: 'center', margin: "10px 0" }}>
                        <span >{pickupaddress && pickupaddress.message}</span>
                    </Alert>
                </Snackbar>
            }
            <Card sx={{ width: '100%', boxShadow: 'unset' }} className="sc--cardhead--details">
                <CardHeader title='Pickup Address' className="sc--cardhead--details" action={
                    <div>
                        <Button variant="contained" onClick={() => { setModalVisible(true); }}>Add Address</Button>
                    </div>
                } />
                <Divider />
                <CardContent>
                    <div style={{ maxWidth: '100%' }}>
                        <DataGrid
                            getRowId={(row) => row._id}
                            disableSelectionOnClick={true}
                            autoHeight={true}
                            rows={pickupaddress && pickupaddress.data}
                            columns={columns}
                            slots={{
                                loadingOverlay: LinearProgress,
                                NorowsData
                            }}
                            hideFooter={true}
                            loading={pickupaddress && pickupaddress.loading}
                        />
                    </div>
                </CardContent>
            </Card>
            <Modalpopup />
        </div>
    )
}
export default PickupAddressPage;
