import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  Typography,
  FormControl,
  Pagination,
  IconButton,
  Tooltip,
  Modal,
  Box,
  Button,
  Card,
  Alert,
  CardContent,
  CardHeader,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import ClearIcon from "@mui/icons-material/Clear";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import Snackbar from "@mui/material/Snackbar";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { fetchOrderDetail } from "../../pages/OrderDetail/orderdetailsSlice";
import NorowsData from "../../pages/General/NorowsData";
import LinearProgress from "@mui/material/LinearProgress";
import Printer from "../../pages/General/Printer";
import { resetOrderNotifications } from "../../pages/Orders/ordersSlice";

import {
  fetchPickOrderDetail,
  fetchPickOrderDetailitems,
  putOrderItemsDetails,
  resetOrderPickNotification
} from "./orderpicklistSlice";

import styled from "@emotion/styled";
import { columnsFromBackend } from "./KanbanData";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import NewOrderCreateDrawer from "../General/NewOrder/NewOrderCreateDrawer";
import Notification from "../../pages/General/Notification";

import TaskCard from "./TaskCard";
import { current } from "@reduxjs/toolkit";
import { Check } from "@material-ui/icons";

// import Date from "../../../pages/General/Date";

//Custom Components Import Ends
const Container = styled.div`
  display: flex;
`;

const TaskList = styled.div`
  max-height: 400px;
  overflow-x: scroll;
  display: flex;
  flex-direction: column;
  background: #f3f3f3;
  min-width: 341px;
  border-radius: 5px;
  padding: 15px 15px;
  margin-right: 45px;
`;

const TaskColumnStyles = styled.div`
  margin: 8px;
  display: flex;
  width: 100%;
`;

const Title = styled.span`
  color: #131313;
  font-weight: 600;
  background: #deebff;
  padding: 2px 10px;
  border-radius: 5px;
  align-self: flex-start;
  text-transform: uppercase;
`;
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};
const OrderPicklistPage = () => {
  const dispatch = useDispatch();
  const params = new URLSearchParams(window.location.search) // id=123
  let orderidfromurl = params.get('orderid')
  const orderpicklist = useSelector(
    (state) => state.orderpicklist && state.orderpicklist.orderpickdetailitems
  );
  const orderpicklistitems = useSelector((state) => state.orderpicklist);
  const orders = useSelector((state) => state.orders);
  const profile = useSelector(state => state.auth);
  const ordersNotification = useSelector(state => state.orders.createShipment);
  const [meta, setMeta] = useState(orders.ordersData.meta);
  const [orderId, setOrderId] = useState("");
  const [sku, setSku] = useState("");
  const [printUrl, setPrintUrl] = useState("");
  const [isPrinted, setIsPrinted] = useState(true);
  const [alert, setAlert] = useState(false);
  const [open, setOpen] = useState(false);
  const [modalopen, setmodalopen] = useState(false) 
  const [yetpackedcount, setyetpackedcount] = useState(0)
  const [packedcount, setpackedcount] = useState(0)
  const [pageLoad, setpageLoad] = useState(false);
  const [orderSubmit, setorderSubmit] = useState(false);
  const [newOrderCreateDrawerVisible, setNewOrderCreateDrawerVisible] = useState(false);
  const [newOrderFormData, setNewOrderFormData] = useState("");
  const [isShipmentCreationFlow, setIsShipmentCreationFlow] = useState(false);
  const [isLabelButtonDisable, setIsLabelButtonDisable] = useState(false);
  const [isRateAutoEnabled, setIsRateAutoEnabled]= useState(false)
  const [isReturnOrder, setIsReturnOrder] = useState(false);

  const newOrderCreateCallback = (item,value) => {
    let finaldata={}
    Object.entries(initialData).map((entry) => {
      entry.map((item) => {
        if (item.title == "YET to be packed") {
          for (var key in item.items) {
            let current_item = item.items[key];
            finaldata=current_item
        } 
      }
    });
    });
    if(Object.keys(finaldata).length>0 && value=='check'){
      setmodalopen(true)
    }else{
      setIsShipmentCreationFlow(true);
      setNewOrderFormData(item);
      setNewOrderCreateDrawerVisible(true);
      setmodalopen(false)
    }
  };
  const newOrderCreateCallbackProceed = (item) => {
      setIsShipmentCreationFlow(true);
      setNewOrderFormData(item);
      setNewOrderCreateDrawerVisible(true);
      setmodalopen(false)
  };

  const newOrderCreateOnClose = () => {
    setNewOrderCreateDrawerVisible(false);
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(resetOrderPickNotification())
  };

  const handleSubmit = () => {
    setorderSubmit(true)
    setIsLabelButtonDisable(false)
    dispatch(fetchPickOrderDetail(orderId));
    dispatch(fetchPickOrderDetailitems(orderId));
  };
  const handleSKUSubmit = () => {
    setorderSubmit(true)
    let finaldata = {};
    Object.entries(initialData).map((entry) => {
      entry.map((item) => {
        for (var key in item.items) {
          let current_item = item.items[key];
          if (current_item.sku == sku) {
            finaldata = {
              items: [current_item],
              packed: true,
              doc_id:
                orderpicklist &&
                orderpicklist.data &&
                orderpicklist.data.doc_id,
              order_id:
                orderpicklist &&
                orderpicklist.data &&
                orderpicklist.data.order_id,
            };
          }
        }
      });
    });
    dispatch(putOrderItemsDetails(finaldata, orderpicklist &&
      orderpicklist.data &&
      orderpicklist.data.order_id));
    setSku("");
  };

  const movePacked=()=>{
    setorderSubmit(true)
    let finaldata = {};
    let packed=true;
    let doc_id=orderpicklist &&orderpicklist.data && orderpicklist.data.doc_id;
    let order_id= orderpicklist && orderpicklist.data && orderpicklist.data.order_id;
    finaldata={
      "doc_id":doc_id,
      "order_id":order_id,
      "packed":packed,
    }
    let items=[];
    Object.entries(initialData).map((entry) => {
      entry.map((item) => {
        if (item.title == "YET to be packed") {
          for (var key in item.items) {
            let current_item = item.items[key];
            items.push(current_item)
        } 
      }
    });
    });
    finaldata.items=items;
    dispatch(putOrderItemsDetails(finaldata, orderpicklist &&
      orderpicklist.data &&
      orderpicklist.data.order_id));
  }

  const moveYetPacked=()=>{
    setorderSubmit(true)
    let finaldata = {};
    let packed=false;
    let doc_id=orderpicklist &&orderpicklist.data && orderpicklist.data.doc_id;
    let order_id= orderpicklist && orderpicklist.data && orderpicklist.data.order_id;
    finaldata={
      "doc_id":doc_id,
      "order_id":order_id,
      "packed":packed,
    }
    let items=[];
    Object.entries(initialData).map((entry) => {
      entry.map((item) => {
        if (item.title == "Packed") {
          for (var key in item.items) {
            let current_item = item.items[key];
            items.push(current_item)
        } 
      }
    });
    });
    finaldata.items=items;
    dispatch(putOrderItemsDetails(finaldata, orderpicklist &&
      orderpicklist.data &&
      orderpicklist.data.order_id));
  }


  const openLabelClick = (item) => {
    let labels = []
    if (item &&
      item.shipment &&
      item.shipment.tracking &&
      item.shipment.tracking.length != 0) {
        for (var key of Object.keys(item.shipment.tracking)) {
            let dataTemp = typeof item.shipment.tracking[key] == "object" ? JSON.parse(JSON.stringify(item.shipment.tracking[key])) : {}
         if (item.shipment.tracking[key] &&
                item.shipment.tracking[key].label &&
                item.shipment.tracking[key].label.url &&
                item.shipment.tracking[key].label.url != "") {
                if (!labels.includes(item.shipment.tracking[key].label.url)) {
                    labels.push(item.shipment.tracking[key].label.url)
                }
            }
        }
    }
    if (labels.length == 1) {
        setPrintUrl(labels[0])
        setIsPrinted(false)
    }
}

  const onInputClear = () => {
    setOrderId("");
    setorderSubmit(false)
  };
  const columns1 = [
    {
      field: "Store order ID",
      headerName: "Store order ID",
      sortable: false,
      minWidth:200,
      renderCell: (params) => {
        if (orderpicklistitems &&
          orderpicklistitems.orderpickdetail &&
          orderpicklistitems.orderpickdetail.data &&
          orderpicklistitems.orderpickdetail.data.order && 
          orderpicklistitems.orderpickdetail.data.order.custom_fields &&
          orderpicklistitems.orderpickdetail.data.order.custom_fields.store_order_id) {
            let order_id = orderpicklistitems.orderpickdetail.data.order.custom_fields.store_order_id;
            return order_id.replace("CA", "").replace("US", "").replace("GB", "");
        } else {
          return "-";
        }
      },
    },
    {
      field: "product_id",
      headerName: "Product Id",
      sortable: false,
      minWidth:300,
      renderCell: (params) => {
        if (params.row.product_id && typeof params.row.product_id !== "undefined") {
          return params.row.product_id;
        } else {
          return "-";
        }
      },
    },
    {
      field: "product_desc",
      headerName: "Product Desc",
      sortable: false,
      minWidth:300,
      renderCell: (params) => {
        if (
          params.row.product_desc &&
          typeof params.row.product_desc !== "undefined"
        ) {
          return (
                  <Tooltip
                        title={params.row.product_desc}
                        placement="top"
                      >
                    <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                      {params.row.product_desc}
                    </span>
                  </Tooltip>
          );
        } else {
          return "-";
        }
      },
    },
    {
      field: "sku",
      headerName: "SKU",
      sortable: false,
      minWidth:300,
      renderCell: (params) => {
        if (params.row.sku && typeof params.row.sku !== "undefined") {
          return params.row.sku;
        } else {
          return "-";
        }
      },
    },
    {
      field: "quantity",
      headerName: "Quantity",
      sortable: false,
      minWidth:100,
      renderCell: (params) => {
        if (params.row.quantity && typeof params.row.quantity !== "undefined") {
          return params.row.quantity;
        } else {
          return "-";
        }
      },
    },
    {
      field: "product_price",
      headerName: "Product Price",
      sortable: false,
      minWidth:150,
      renderCell: (params) => {
        if (params.row.product_price && typeof params.row.product_price !== "undefined") {
          return params.row.product_price;
        } else {
          return "-";
        }
      },
    },
  ];

  const [columns, setColumns] = useState({
    yet_packed: {
      title: "YET to be packed",
      items: [],
    },
    packed: {
      title: "Packed",
      items: [],
    },
  });

  const [initialData, setInitialData] = useState({
    yet_packed: {
      title: "YET to be packed",
      items: [],
    },
    packed: {
      title: "Packed",
      items: [],
    },
  });

  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;
    if (source.droppableId !== destination.droppableId) {
      setorderSubmit(true)
      let sourceColumn = columns[source.droppableId];
      let destColumn = columns[destination.droppableId];
      let sourceItems = [...sourceColumn.items];
      let destItems = [...destColumn.items];
      let [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);

      let tempColumns = { ...columns };
      sourceColumn["items"] = sourceItems;
      tempColumns[source.droppableId] = sourceColumn;

      destColumn["items"] = destItems;
      tempColumns[destination.droppableId] = destColumn;
      setColumns(tempColumns);
      let finaldata = {};
      let packed = true;
      if (destination.droppableId == "yet_packed") {
        packed = false;
      }
      Object.entries(initialData).map((entry) => {
        entry.map((item) => {
          if (packed == true && item.title == "YET to be packed") {
            for (var key in item.items) {
              let current_item = item.items[key];
              if (current_item.id == result.draggableId) {
                finaldata = {
                  items: [current_item],
                  packed: true,
                  doc_id:
                    orderpicklist &&
                    orderpicklist.data &&
                    orderpicklist.data.doc_id,
                  order_id:
                    orderpicklist &&
                    orderpicklist.data &&
                    orderpicklist.data.order_id,
                };
              }
            }
          } else if (packed == false && item.title == "Packed") {
            for (var key in item.items) {
              let current_item = item.items[key];
              if (current_item.id == result.draggableId) {
                finaldata = {
                  items: [current_item],
                  packed: false,
                  doc_id:
                    orderpicklist &&
                    orderpicklist.data &&
                    orderpicklist.data.doc_id,
                  order_id:
                    orderpicklist &&
                    orderpicklist.data &&
                    orderpicklist.data.order_id,
                };
              }
            }
          }
        });
      });
      
      dispatch(putOrderItemsDetails(finaldata, orderpicklist &&
        orderpicklist.data &&
        orderpicklist.data.order_id));
    } else {
      setorderSubmit(true)
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  };

  useEffect(() => {
    if (
      Object.keys(
        orderpicklistitems &&
          orderpicklistitems.orderpickdetailitems &&
          orderpicklistitems.orderpickdetailitems.data
      ).length != 0
    ) {
      setColumns({
        yet_packed: {
          title: "YET to be packed",
          items:
            orderpicklistitems &&
            orderpicklistitems.orderpickdetailitems &&
            orderpicklistitems.orderpickdetailitems.data &&
            orderpicklistitems.orderpickdetailitems.data.unpacked_items,
        },
        packed: {
          title: "Packed",
          items:
            orderpicklistitems &&
            orderpicklistitems.orderpickdetailitems &&
            orderpicklistitems.orderpickdetailitems.data &&
            orderpicklistitems.orderpickdetailitems.data.packed_items,
        },
      });
      setInitialData({
        yet_packed: {
          title: "YET to be packed",
          items:
            orderpicklistitems &&
            orderpicklistitems.orderpickdetailitems &&
            orderpicklistitems.orderpickdetailitems.data &&
            orderpicklistitems.orderpickdetailitems.data.unpacked_items,
        },
        packed: {
          title: "Packed",
          items:
            orderpicklistitems &&
            orderpicklistitems.orderpickdetailitems &&
            orderpicklistitems.orderpickdetailitems.data &&
            orderpicklistitems.orderpickdetailitems.data.packed_items,
        },
      });
      setyetpackedcount(orderpicklistitems.orderpickdetailitems.data.unpacked_items.length)
      setpackedcount(orderpicklistitems.orderpickdetailitems.data.packed_items.length)
    }
  }, [orderpicklistitems.orderpickdetailitems.data]);

  useEffect(() => {
    if(orderpicklistitems && orderpicklistitems.orderpickdetail && orderpicklistitems.orderpickdetail.notification && orderpicklistitems.orderpickdetail.notification.messageType=='error'){
        setAlert(true)
        setOpen(true)
    }else{
      setAlert(false)
      setOpen(false)
    }

  },[orderpicklistitems.orderpickdetail])

  useEffect(() => {
    if (orderidfromurl !== "" && orderidfromurl!=null && orderidfromurl!=undefined) {
      setOrderId(orderidfromurl);
      dispatch(fetchPickOrderDetail(orderidfromurl));
      dispatch(fetchPickOrderDetailitems(orderidfromurl));
    }
  }, [orderidfromurl]);

  useEffect(() => {
    if (orderId=='' && pageLoad==false) {
        dispatch(resetOrderPickNotification())
        setColumns({
          yet_packed: {
            title: "YET to be packed",
            items: [],
          },
          packed: {
            title: "Packed",
            items: [],
          },
        })
        setyetpackedcount(0)
        setpackedcount(0)
        setpageLoad(true)
       
    }
  }, [orderpicklistitems]);

  useEffect(() => {
    if (ordersNotification && ordersNotification.notification && ordersNotification.notification.messageContent
        && ordersNotification.notification.messageContent[0] === 'Shipment Created Successfully') {
          dispatch(fetchPickOrderDetail(orderpicklist &&
            orderpicklist.data &&
            orderpicklist.data.order_id));
          setOrderId("");
          setorderSubmit(false)
          setIsLabelButtonDisable(true)
        }
        }, [ordersNotification]);

  useEffect(() => {
    if (profile && profile.profile && profile.profile.auto_fetch_rate === true) {
          setIsRateAutoEnabled(true)
        }
        }, [profile]);
      

  return (
    <>

      {orders &&
        orders.createShipment.notification &&
        orders.createShipment.notification.show && (
          <Notification
            is_open={orders.createShipment.notification.show}
            messageType={orders.createShipment.notification.messageType}
            messages={orders.createShipment.notification.messageContent}
            handleClose={() => dispatch(resetOrderNotifications())}
          />
        )}     
      {alert && (
        <Snackbar
          open={open}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            severity={"error"}
            style={{ justifyContent: "center", margin: "10px 0" }}
          >
            <span> {orderpicklistitems.orderpickdetail.notification.messageContent.message}</span>
          </Alert>
        </Snackbar>
      )}
      <div>
         <Modal
            open={modalopen}
            aria-labelledby="manifest-confirm-modal-title"
            aria-describedby="manifest-confirm-modal-description"
        >
            <Box sx={style}>
                <Typography style={{ textAlign: "center" }} id="manifest-confirm-modal-description" sx={{ mt: 2 }}>
                You have some unpacked items for this order. <br />Still do you want to proceed?
                </Typography>
              <Typography style={{ marginTop: "30px", textAlign: "center" }}>
                    <Button variant="contained"  onClick={()=>newOrderCreateCallbackProceed(orderpicklistitems.orderpickdetail.data)}>Yes</Button>
                    <Button variant="outlined" style={{ marginLeft: "15px" }} onClick={() => { setmodalopen(false) }}>No</Button>
                </Typography>
            </Box>
        </Modal>
        </div>
      <div>
        <div className="sccardhead">
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h5" gutterBottom component="div">
              Picklist Verification
            </Typography>
          </div>
        </div>
      </div>
      <div className="scmaincard">
        <Card style={{ padding: "40px 0" }}>
          <div className="sc--form--center">
            <TextField
              value={orderId}
              name={"shippingchimp_manifest_number"}
              label="Order Id"
              size={"small"}
              // onChange={onInputChange}
              onInput={(e) => {
                setOrderId(e.target.value)
                setorderSubmit(false)
              }
            }
              variant="standard"
              InputProps={{
                endAdornment: orderId ? (
                  <IconButton size="small" onClick={() => onInputClear()}>
                    <ClearIcon />
                  </IconButton>
                ) : undefined,
              }}
              onKeyUp={(event) => {
                if (event.key == "Enter") handleSubmit();
              }}
              inputRef={(orderinput) => {
                if (orderId=='' && orderSubmit==false) 
                orderinput && orderinput.focus()
              }
              }
              style={{ width: "200px" }}
            />
            <Button
              variant="contained"
              onClick={handleSubmit}
              style={{
                color: orderId == "" ? "rgba(0, 0, 0, 0.26)" : "#fff",
                backgroundColor:
                  orderId == "" ? "rgba(0, 0, 0, 0.12)" : "#1976d2",
                cursor: orderId == "" ? "not-allowed" : "pointer",
                pointerEvents: orderId == "" ? "none" : "auto",
                marginLeft: "20px",
              }}
            >
              Search
            </Button>
          </div>
        </Card>

        <Divider />

        {orderpicklistitems &&
          orderpicklistitems.orderpickdetail &&
          orderpicklistitems.orderpickdetail.loading && <LinearProgress />}

        {orderpicklistitems &&
          orderpicklistitems.orderpickdetail &&
          orderpicklistitems.orderpickdetail.data &&
          orderpicklistitems.orderpickdetail.data &&
          orderpicklistitems.orderpickdetail.data.line_items &&
          orderpicklistitems.orderpickdetail.data.line_items.length != 0 && (
            <Card sx={{ marginTop: "20px" }} className="sc--cardhead--details">
              <div style={{ width: "100%", background: "#fff" }}>
                <CardHeader
                  title="Line Item Detail"
                  action={
                    <>
                      <Tooltip
                        title="Print package slip"
                        placement="left-start"
                      >
                        <Button variant="outlined">
                          <LocalPrintshopOutlinedIcon
                            style={{ color: "#1890ff", cursor: "pointer" }}
                            onClick={() => {
                              setPrintUrl(profile && profile.profile && profile.profile.label_size_packaging_slip && profile.profile.label_size_packaging_slip==true ? "https://integrations.shippingchimp.com/package-customslip-order?doc_id=" +
                              orderpicklistitems.orderpickdetail.data._id :
                                "https://integrations.shippingchimp.com/package-slip-order?doc_id=" +
                                  orderpicklistitems.orderpickdetail.data._id
                              );
                              setIsPrinted(false);
                            }}
                          />
                        </Button>
                      </Tooltip>
                    </>
                  }
                />
              </div>
              <Divider />
              <DataGrid
                 style={{ paddingTop: "37px",width:'1570px'}}
                getRowId={(row) => row.line_item_id + Math.random()}
                rows={
                  orderpicklistitems &&
                  orderpicklistitems.orderpickdetail &&
                  orderpicklistitems.orderpickdetail.data &&
                  orderpicklistitems.orderpickdetail.data.line_items
                }
                columns={columns1}
                autoHeight={true}
                hideFooter={true}
                disableColumnFilter={true}
                disableColumnMenu={true}
                slots={{
                  NorowsData,
                }}
                loading={
                  orderpicklistitems &&
                  orderpicklistitems.orderpickdetail &&
                  orderpicklistitems.orderpickdetail.loading
                }
              />
            </Card>
          )}
        
              <Card
                sx={{ marginTop: "20px" }}
                className="sc--cardhead--details"
              >
                <div style={{ width: "100%", background: "#fff" }}>
                  <CardHeader
                    title="Order Package Details"
                    action={
                      <>
                        {orderpicklistitems &&
                          orderpicklistitems.orderpickdetailitems &&
                          orderpicklistitems.orderpickdetailitems.data &&
                          Object.keys(
                            orderpicklistitems.orderpickdetailitems.data
                          ).length != 0 && (
                            <>
                              {orderpicklistitems &&
                              orderpicklistitems.orderpickdetailitems &&
                              orderpicklistitems.orderpickdetailitems.data &&
                              orderpicklistitems.orderpickdetailitems.data
                                .shipment ? (
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  onClick={()=>{
                                    setOrderId("")
                                    setorderSubmit(false)
                                    openLabelClick(orderpicklistitems.orderpickdetail.data)
                                  }}
                                  style={{
                                    marginRight: "15px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <LocalPrintshopOutlinedIcon
                                    style={{
                                      paddingRight: "10px",
                                    }}
                                  />
                                  Print Label
                                </Button>
                              ) : (
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  disabled={isLabelButtonDisable && (profile && profile.profile && profile.profile.disable_create_shipment ===false || typeof profile.profile.disable_create_shipment == "undefined")}
                                  style={{
                                    marginRight: "15px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    newOrderCreateCallback(orderpicklistitems.orderpickdetail.data,"check")
                                  }
                                >
                                  <AddCircleOutlineOutlinedIcon
                                    style={{
                                      paddingRight: "10px",
                                    }}
                                  />
                                  Create Label
                                </Button>
                              )}
                            </>
                          )}
                      </>
                    }
                  />
                  <Divider />
                  {orderpicklistitems &&
                    orderpicklistitems.orderpickdetailitemsupdate &&
                    orderpicklistitems.orderpickdetailitemsupdate.loading && (
                      <LinearProgress />
                    )}
                  <CardContent>
                    <div className="sc--form--center" >
                      <TextField
                        disabled={Object.keys(orderpicklistitems.orderpickdetailitems.data).length != 0 ? false:true}
                        name={"scan_sku"}
                        label="Scan SKU"
                        size={"small"}
                        value={sku}
                        onInput={(e) => {
                          setSku(e.target.value)
                          setorderSubmit(true)
                        }
                      }
                        variant="standard"
                        onKeyUp={(event) => {
                          if (event.key == "Enter") handleSKUSubmit();
                        }}
                        inputRef={(skuinput) => {
                          if(orderId!='' && orderSubmit)
                          skuinput && skuinput.focus()
                        }
                      }
                        style={{ width: "200px" }}
                      />
                      <Button
                        variant="contained"
                        onClick={handleSKUSubmit}
                        style={{
                          color: sku == "" ? "rgba(0, 0, 0, 0.26)" : "#fff",
                          backgroundColor:
                            sku == "" ? "rgba(0, 0, 0, 0.12)" : "#1976d2",
                          cursor: sku == "" ? "not-allowed" : "pointer",
                          pointerEvents: sku == "" ? "none" : "auto",
                          marginLeft: "20px",
                        }}
                      >
                        Submit
                      </Button>
                    </div>
                    
                    {columns && (
                      <>
                      <DragDropContext
                        onDragEnd={(result) =>
                          onDragEnd(result, columns, setColumns)
                        }
                      >
                        <div
                          className="sc--form--center"
                          style={{ margin: "40px 0",pointerEvents:orderpicklistitems.orderpickdetailitemsupdate.loading ? "none":"all"}}
                        >
                          <Container>
                            <TaskColumnStyles>
                              {Object.entries(columns).map(
                                ([columnId, column], index) => {
                                  return (
                                    <Droppable
                                      key={columnId}
                                      droppableId={columnId}
                                    >
                                      {(provided, snapshot) => (
                                        <TaskList
                                          ref={provided.innerRef}
                                          {...provided.droppableProps}
                                        >
                                          <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                                          <Title>{column.title}</Title>
                                          {column.title =="YET to be packed" &&<>
                                          <Tooltip
                                           title="yet to be packed count"
                                           placement="left-start"
                                         ><span><b>{yetpackedcount}</b></span>
                                         </Tooltip>
                                           <Tooltip
                                           title="Move to be packed"
                                           placement="left-start"
                                         >
                                          <Button
                                              variant="outlined"
                                              color="primary"
                                              style={{
                                                cursor: "pointer",
                                                pointerEvents:orderpicklistitems.orderpickdetailitemsupdate.loading ? "none":"all"
                                              }}
                                              onClick={movePacked}
                                            >
                                              <ArrowForwardIosIcon
                                              />
                                          </Button>
                                          </Tooltip>
                                          </>
                                         }
                                          {column.title =="Packed" &&<>
                                          <Tooltip
                                           title="packed count"
                                           placement="left-start"
                                         ><span><b>{packedcount}</b></span>
                                         </Tooltip>
                                           <Tooltip
                                           title="Move yet to be packed"
                                           placement="left-start"
                                         >
                                          <Button
                                              variant="outlined"
                                              color="primary"
                                              style={{
                                                cursor: "pointer",
                                              }}
                                              onClick={moveYetPacked}
                                            >
                                              <ArrowBackIosIcon
                                              />
                                          </Button>
                                          </Tooltip>
                                          </>
                                          }
                                          </div>
                                          {column.items.map((item, index) => (
                                            <TaskCard
                                              key={item}
                                              item={item}
                                              index={index}
                                            />
                                          ))}
                                          {provided.placeholder}
                                        </TaskList>
                                      )}
                                    </Droppable>
                                  );
                                }
                              )}
                            </TaskColumnStyles>
                          </Container>
                        </div>
                      </DragDropContext>
                    
                   </>
                    )}
                  </CardContent>
                </div>
              </Card>
      </div>
      <Printer
        isPrinted={isPrinted}
        setIsPrinted={setIsPrinted}
        print_url={printUrl}
      />

      <NewOrderCreateDrawer
        visible={newOrderCreateDrawerVisible}
        reportName={"orders"}
        newOrderFormData={newOrderFormData}
        setNewOrderFormData={setNewOrderFormData}
        meta={meta}
        onClose={newOrderCreateOnClose}
        // isDuplicateOrder={isDuplicateOrder}
        setNewOrderCreateDrawerVisible={setNewOrderCreateDrawerVisible}
        // choosenTrackingNumber={choosenTrackingNumber}
        isShipmentCreationFlow={isShipmentCreationFlow}
        isRateAutoEnabled={isRateAutoEnabled}
        isReturnOrder={isReturnOrder}
        setIsReturnOrder={setIsReturnOrder}

      />
             
    </>
  );
};

export default OrderPicklistPage;
