import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setQzNotifications, setQzQueueCounts } from '../../qzSlice';

const initiatePrinting = (dispatch, qzState, print_url) => {
    if (qzState.printer !== "undefined" && typeof qzState.printer !== "undefined" && typeof qzState.printer != "") {
        let config = qzState.qz.configs.create(qzState.printer, {
            size: { width: 4, height: 6 },
            units: 'in',
            interpolation: "nearest-neighbor",
            margins: { top: 0.25, right: 0.25, bottom: 0.25, left: 0 }
        });
        var data = [{
            type: 'pixel',
            format: 'pdf',
            flavor: 'file',
            data: print_url,
            options: { ignoreTransparency: true }
        }];
        dispatch(setQzQueueCounts({ "added": true }))
        return qzState.qz.print(config, data).then(() => {
            console.log("Print success")
            dispatch(setQzQueueCounts({ "added": false }))
        }).catch(function (e) {
            console.log("Print failure");
            console.log(e);
            dispatch(setQzQueueCounts({ "added": false }))
            if (e.message.includes("Cannot find printer")) {
                dispatch(setQzNotifications({
                    "message": ["Printer not connected. please check!"],
                    "error": true
                }))
            }
        });
    } else {
        dispatch(setQzNotifications({
            "message": ["Printer not connected. please check!"],
            "error": true
        }))
    }
}

const Printer = ({ isPrinted, setIsPrinted, print_url, blank = false, closable = false }) => {
    const dispatch = useDispatch();
    const qzState = useSelector(state => state.qz)
    useEffect(() => {
        if (print_url != "" && isPrinted == false) {
            if (qzState.is_active) {
                initiatePrinting(dispatch, qzState, print_url)
            } else {
                if(closable == true){
                    print_url = process.env.REACT_APP_API_DOMAIN + '/utility/pdf/print-close?q=' + print_url
                }else{
                    print_url = process.env.REACT_APP_API_DOMAIN + '/utility/pdf/print?q=' + print_url
                }
                if (blank == true) {
                    window.open(print_url, '_blank');
                } else {
                    window.open(print_url)
                }
            }
        }
        setIsPrinted(true)
    }, [isPrinted])

    return "";
}

export default Printer;