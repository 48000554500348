import { createSlice } from '@reduxjs/toolkit';
import { OMSAPI, NOTIFICATIONAPI } from '../../../API';
import axios from 'axios';

/* canceling previous requests if multiple requests is triggered */
const CancelToken = axios.CancelToken;
let source = CancelToken.source();

export const notificationsslice = createSlice({
    name: 'notifications',
    initialState: {
        report: {
            data: [],
            meta: {
                page: 1,
                per_page: 10,
                platform: "",
                email: "",
                name: "",
                trackingnumber: "",
                event: "",
                date: "",
            },
            loading: false,
            hasErrors: false,
        },
        preview: {
            data: [],
            loading: false,
            hasErrors: false,
        },
        resend: {
            notification: { showMessage: false, messageContent: "", messageType: "success" },
            loading: false,
            hasErrors: false,
        },
        totalcount: {
            data: {},
            loading: false,
            hasErrors: false,
        },
        categoriescount: {
            data: {},
            loading: false,
            hasErrors: false,
        },
        totalintegratedplatforms: {
            data: {},
            loading: false,
            hasErrors: false,
        },
        overtimecount: {
            data: {},
            loading: false,
            hasErrors: false,
        },
    },

    reducers: {
        getNotificationReport: (state) => {
            return {
                ...state,
                report: {
                    ...state.report,
                    data: [],
                    loading: true,
                },
            }
        },
        getNotificationReportSuccess: (state, action) => {
            return {
                ...state,
                report: {
                    data: action && action.payload && action.payload.data && action.payload.data.data,
                    meta: action && action.payload && action.payload.data && action.payload.data.meta,
                    hasErrors: false,
                    loading: false,
                },
            }
        },
        getNotificationReportFailure: (state, action) => {
            return {
                ...state,
                report: {
                    ...state.report,
                    loading: false,
                    hasErrors: true,
                },
            }
        },
        getNotificationPreview: (state) => {
            return {
                ...state,
                preview: {
                    ...state.preview,
                    loading: true,
                },
            };
        },
        getNotificationPreviewSuccess: (state, action) => {
            return {
                ...state,
                preview: {
                    data: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data,
                    hasErrors: false,
                    loading: false,
                },
            };
        },
        getNotificationPreviewFailure: (state, action) => {
            return {
                ...state,
                preview: {
                    ...state.preview,
                    loading: false,
                    hasErrors: true,
                },
            };
        },
        postResendNotification: (state) => {
            return {
                ...state,
                resend: {
                    ...state.resend,
                    loading: true,
                },
            };
        },
        postResendNotificationSuccess: (state, action) => {
            return {
                ...state,
                resend: {
                    notification: { showMessage: true, messageContent: [action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.message], messageType: "success" },
                    hasErrors: false,
                    loading: false,
                },
            };
        },
        postResendNotificationFailure: (state, action) => {
            return {
                ...state,
                resend: {
                    ...state.resend,
                    notification: { showMessage: true, messageContent: [action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.message], messageType: "error" },
                    loading: false,
                    hasErrors: true,
                },
            };
        },

        getNotificationTotalIntegratedPlatfrom: (state) => {
            return {
                ...state,
                totalintegratedplatforms: {
                    ...state.total_integrated_platforms,
                    loading: true,
                },
            };
        },
        getNotificationTotalIntegratedPlatfromSuccess: (state, action) => {
            return {
                ...state,
                totalintegratedplatforms: {
                    data: action && action.payload && action.payload.data && action.payload.data.data,
                    hasErrors: false,
                    loading: false,
                },
            };
        },
        getNotificationTotalIntegratedPlatfromFailure: (state, action) => {
            return {
                ...state,
                totalintegratedplatforms: {
                    ...state.totalintegratedplatforms,
                    loading: false,
                    hasErrors: true,
                },
            };
        },

        getNotificationCategoriesCount: (state) => {
            return {
                ...state,
                categoriescount: {
                    ...state.categoriescount,
                    loading: true,
                },
            };
        },
        getNotificationTotalCategoriesCountSuccess: (state, action) => {
            return {
                ...state,
                categoriescount: {
                    data: action && action.payload && action.payload.data && action.payload.data.data,
                    hasErrors: false,
                    loading: false,
                },
            };
        },
        getNotificationTotalCategoriesCountFailure: (state, action) => {
            return {
                ...state,
                categoriescount: {
                    ...state.categoriescount,
                    loading: false,
                    hasErrors: true,
                },
            };
        },
        resetNotification: (state, action) => {
            return {
                ...state,
                resend: {
                    ...state.resend,
                    notification: { showMessage: false, messageContent: "", messageType: "success" },
                },
            };
        },
    }
})

export const { getNotificationReport, getNotificationReportSuccess, getNotificationReportFailure, getNotificationPreview, getNotificationPreviewSuccess, getNotificationPreviewFailure,
    postResendNotification, postResendNotificationSuccess, postResendNotificationFailure, getNotificationTotalIntegratedPlatfrom,
    getNotificationTotalIntegratedPlatfromSuccess, getNotificationTotalIntegratedPlatfromFailure, getNotificationCategoriesCount,
    getNotificationTotalCategoriesCountSuccess, getNotificationTotalCategoriesCountFailure, resetNotification } = notificationsslice.actions;

export const fetchNotificationReport = (meta) => {
    const qs = Object.keys(meta)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(meta[key])}`)
        .join("&");

    return (dispatch) => {
        dispatch(getNotificationReport());
        try {
            source && source.cancel("Operation canceled due to new request.");
            source = axios.CancelToken.source();

            OMSAPI.get("/notification/report?" + qs, {
                cancelToken: source.token,
            })
                .then(function (response) {
                    dispatch(getNotificationReportSuccess(response));
                })
                .catch(function (error) {
                    if (!axios.isCancel(error)) {
                        dispatch(getNotificationReportFailure(error.response));
                    }
                });
        } catch (error) {
            if (!axios.isCancel(error)) {
                dispatch(getNotificationReportFailure());
            }
        }
    };
};


export const fetchNotificationpreview = (orderid) => {
    return (dispatch) => {
        dispatch(getNotificationPreview());
        try {
            OMSAPI.get("/notification/preview/" + orderid)
                .then(function (response) {
                    dispatch(getNotificationPreviewSuccess(response));
                })
                .catch(function (error) {
                    dispatch(getNotificationPreviewFailure(error.response));
                });
        } catch (error) {
            dispatch(getNotificationPreviewFailure());
        }
    };
};
export const fetchNotificationTotalIntegratedPlatform = () => {
    return (dispatch) => {
        dispatch(getNotificationTotalIntegratedPlatfrom());
        try {
            OMSAPI.get("/notification?q=integratedplatforms")
                .then(function (response) {
                    dispatch(getNotificationTotalIntegratedPlatfromSuccess(response));
                })
                .catch(function (error) {
                    dispatch(
                        getNotificationTotalIntegratedPlatfromFailure(error.response)
                    );
                });
        } catch (error) {
            dispatch(getNotificationTotalIntegratedPlatfromFailure());
        }
    };
};

export const fetchNotificationCategoriesCount = () => {
    return (dispatch) => {
        dispatch(getNotificationCategoriesCount());
        try {
            OMSAPI.get("/notification?q=categoriescount")
                .then(function (response) {
                    dispatch(getNotificationTotalCategoriesCountSuccess(response));
                })
                .catch(function (error) {
                    dispatch(getNotificationTotalCategoriesCountFailure(error.response));
                });
        } catch (error) {
            dispatch(getNotificationTotalCategoriesCountFailure());
        }
    };
};
export const resendNotification = (data) => {
    return (dispatch) => {
        dispatch(postResendNotification());
        try {
            NOTIFICATIONAPI.post("/resend", data)
                .then(function (response) {
                    dispatch(postResendNotificationSuccess(response));
                })
                .catch(function (error) {
                    dispatch(postResendNotificationFailure(error.response));
                });
        } catch (error) {
            dispatch(postResendNotificationFailure(error.response));
        }
    };
};


export const resetResendNotification = () => {
    return (dispatch) => {
        dispatch(resetNotification());
    }
}

export default notificationsslice.reducer;
