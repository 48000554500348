import React, { useEffect, useState, useRef } from 'react';
import { OMSAUTH, OMSPROFILE } from '../../API';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import { resendEmail,resetNotification } from './authSlice';
import Notification from '../../pages/General/Notification'
import Loader from '../../pages/General/Loader';

const UserVerifyPage = () => {
	const auth = useSelector(state => state.auth);
	const profile = useSelector(state => state.auth.profile);
	console.log(auth)
	const dispatch = useDispatch();

	const handleRequest = () => {
		dispatch(resendEmail(profile.email));
	}

	const logoutRegister = () => {
		console.log("Logout")
		window.location = process.env.REACT_APP_API_DOMAIN + "/auth/logout/register"
	}

	return (
		<>
			  {auth && auth.resentEmailloading === true &&
                <Loader />
            }
			{(auth && auth.notification && auth.notification.showMessage) &&
                <Notification
                    is_open={auth.notification.showMessage}
                    messageType={auth.notification.messageType}
                    messages={auth.notification.messageContent}
                    handleClose={() => dispatch(resetNotification())}
                />}
			<div className="vertical-center">
				<Paper elevation={3} sx={{ m: "10px", padding: "48px 32px" }} square={false}>
					<p style={{ textAlign: 'center' }}><img src="/assets/images/trial-form/inbox.png" /></p>
					<h3 style={{ textAlign: "center" }}>Check your inbox</h3>
					<p style={{ textAlign: "center" }}>We've sent an email to <b>{profile && profile.email}</b> with a link to complete your account setup</p>
					<p style={{ textAlign: "center" }}>Having trouble?<span onClick={handleRequest} style={{ color: '#1976d2', cursor: 'pointer', textDecoration: 'underline' }}> Resend email</span> or <span onClick={logoutRegister} style={{ color: '#1976d2', cursor: 'pointer', textDecoration: 'underline' }}>Try a different email</span></p>
				</Paper>
			</div>
		</>
	)
}

export default UserVerifyPage