import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';

const Search = ({ visible, meta, setMeta }) => {
    const deliveries = useSelector(state => state.deliveries);
    const [formValues, setFormValues] = useState(meta)
    const [searchButtonDisabled, setSearchButtonDisabled] = useState(true)
    const [checkFormUse, setCheckFormUse] = useState("")
    const [errors, setErrors] = useState({});
    const validator = (fieldValues) => {
		let temp = { ...errors }
        if(/\`|\~|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\.|\>|\?|\/|\""|\;|\:|\-|\s/g.test(fieldValues && fieldValues.tracking_number)){
            temp.tracking_number = "Please enter valid tracking number"
        }else{
            temp.tracking_number=""
        }
		setErrors({
			...temp
		})

		if (fieldValues == formValues)
			return Object.values(temp).every(x => x == "")
	}
    const onInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value
        });
        validator({ [name]: value })
    };

    const checkFormHasValues = () => {
        let disableButton = true
        if (formValues["tracking_number"] != "" ){
            disableButton = false
        }

        return disableButton
    }

    const handleKeyUp = (event) => {
        if (event.keyCode === 13) {
            const isValid = validator(formValues)
            if (isValid) {
                setMeta(prevState => ({
                    ...prevState,
                    ["tracking_number"]: formValues["tracking_number"],
                    [`page`]: 1,
                }));
                setCheckFormUse(true)
            }
        }
    }

    const submitSearchValues =e => {
        e.preventDefault()
		const isValid = validator(formValues)
		if (isValid) {
            setMeta(prevState => ({
                ...prevState,
                ["tracking_number"]: formValues["tracking_number"],
                [`page`]: 1,
            }));
            setCheckFormUse(true)
        }
    }

    const resetFields = () => {
        setFormValues({
            ...formValues,
            ["tracking_number"]: ""
        });
        setMeta(prevState => ({
            ...prevState,
            ["tracking_number"]: ""
        }));
    }

    const onInputClear = (name) => {
        setErrors({ name: "" })
        setFormValues({
            ...formValues,
            [name]: "",
        });
        setMeta(prevState => ({
            ...prevState,
            [name]: ""
        }));
    };

    useEffect(() => {
        setCheckFormUse("") 
        setSearchButtonDisabled(checkFormHasValues())
    }, [formValues])

    return (
        <>
            {visible &&
                <div className="filtercontainer">
                    <div className="filteraccordion" onKeyUp={handleKeyUp}>
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { m: 1, width: '25ch' },
                            }}

                            noValidate
                            autoComplete="off"
                            onSubmit={e => { e.preventDefault(); }}
                        >
                            <TextField
                                value={formValues && formValues.tracking_number}
                                name={"tracking_number"}
                                label="Tracking Number"
                                size={'small'}
                                onChange={onInputChange}
                                InputProps={{
                                    endAdornment: (formValues && formValues.tracking_number) ? (
                                        <IconButton size="small" onClick={() => onInputClear("tracking_number")}>
                                            <ClearIcon />
                                        </IconButton>
                                    ) : undefined
                                }}
                                variant="standard"
                                error={errors.tracking_number}
                                helperText={errors.tracking_number}
                            />
                            
                        </Box>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={12}>
                                <div className="filterfooter">
                                    <Button variant="contained" onClick={submitSearchValues} disabled={searchButtonDisabled}>Search</Button>
                                    <Button variant="outlined" onClick={resetFields} disabled={searchButtonDisabled}>Clear</Button>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            }
        </>
    )

}

export default Search;
