import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    Typography,
    FormControl,
    Pagination,
    IconButton,
    Tooltip,
    Modal,
    Box,
    Button
} from "@mui/material";

import LinearProgress from '@mui/material/LinearProgress';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SearchIcon from '@mui/icons-material/Search';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';


import {
    DataGrid,
    GridToolbarContainer
} from '@mui/x-data-grid';
import { fetchManifest, generateManifest, resetGenerateManifestNotification } from './groupedmanifestsSlice';
import { fetchHubs } from '../General/hubslice';

//Custom Components Import Starts
import Carrier from '../General/Carrier'
import NorowsData from '../General/NorowsData'
import Filters from './Filters'
import Search from './Search'
import Notification from '../General/Notification'
//Custom Components Import Ends

const ManifestsPage = () => {

    const dispatch = useDispatch();
    const manifest = useSelector(state => state.groupedmanifest);
    const profile = useSelector(state => state.auth.profile);
    const hubs = useSelector(state => state.hubs);
    const [meta, setMeta] = useState(manifest.meta);
    const [showFilter, setShowFilter] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [closeManifestGroupId, setCloseManifestGroupId] = useState('')
    const [openCloseManifestConfirmModal, setOpenCloseManifestConfirmModal] = useState(false)
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };
    const columns = [

        {
            field: 'carrier_name',
            headerName: 'Carrier',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            renderCell: (params) => {
                return (
                    <Carrier carrierName={params.row.carrier_name} width={"90px"} />
                )
            }
        },
        {
            field: 'shippingchimp_group_id',
            headerName: 'Group ID',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            renderCell: (params) => {
                return params.row.shippingchimp_group_id
            }
        },
        {
            field: 'total_shipments',
            headerName: 'Shipment Count',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            renderCell: (params) => {
                if (params.row.total_shipments) {
                    return params.row.total_shipments
                } else {
                    return "-"
                }
            }
        },
        {
            field: 'hub',
            headerName: 'Hub',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            renderCell: (params) => {
                if (params.row.hub && hubs && typeof hubs.data[`params.row.hub`] !== "undefined" && typeof hubs.data[`params.row.hub`]["hub"] !== "undefined") {
                    return hubs.data[params.row.hub]["hub"]
                } else {
                    return params.row.hub
                }
            }
        },
        {
            field: 'created_at',
            headerName: 'Created Date',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            renderCell: (params) => {
                if (params.row.created_at) {
                    return params.row.created_at
                } else {
                    return "-"
                }
            }
        },
        {
            field: 'action',
            headerName: 'Action',
            flex: 1,
            renderCell: (params) => {
                if (params && params.row && params.row.shippingchimp_group_id && params.row.shippingchimp_group_id !== "") {

                    if (params.row.is_closed === false) {
                        return (
                            <Tooltip title="Close Manifest" placement="top-start">
                                <IconButton variant="outlined" style={{
                                    color: '#1890ff'
                                }}
                                    onClick={() => { generateManifestTrigger(params.row.shippingchimp_group_id) }}>
                                    <AssignmentTurnedInOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                        )
                    } else {
                        return "-"
                    }

                } else {
                    return "-"
                }
            }
        }
    ];

    const onSortChange = (currentSorting) => {
        setMeta({
            ...meta,
            ...{
                sorting: JSON.stringify(currentSorting)
            }
        });
    }

    const onChangePerPage = (event) => {
        setMeta({
            ...meta,
            ...{
                page: 1,
                per_page: event.target.value
            }
        });
    }

    const onChangePagination = (event, page) => {
        setMeta({
            ...meta,
            ...{
                page: page
            }
        });
    }

    const onMenuClick = (type, show) => {
        setShowSearch((type === "search" && show === true) ? true : false)
        setShowFilter((type === "filter" && show === true) ? true : false)
    }

    const manifestClose = () => {
        dispatch(generateManifest(meta, closeManifestGroupId, profile.api_token))
        setOpenCloseManifestConfirmModal(false)
    }

    const generateManifestTrigger = (group_id) => {
        setCloseManifestGroupId(group_id)
        setOpenCloseManifestConfirmModal(true)
    }

    function CustomTotalRows() {

        return (
            <div className="custom-total-rows">
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    Showing {manifest && manifest.data && manifest.data.length} of {(manifest && manifest.meta && manifest.meta.total) ? manifest.meta.total : 0}
                </FormControl>
            </div>
        )
    }


    function CustomPagesizeoptions() {

        return (
            <div className="custom-page-size-options">
                <span>
                    Show <select
                        value={meta && meta.per_page}
                        onChange={onChangePerPage}
                    >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </select> entries
                </span>
            </div>
        )
    }

    function CustomToolbar() {

        return (
            <GridToolbarContainer>
                <CustomPagesizeoptions />
                <CustomTotalRows />
            </GridToolbarContainer>
        );
    }

    function CustomPagination() {

        return (
            <Pagination
                color="primary"
                shape="circular"
                page={manifest && manifest.meta && manifest.meta.current_page}
                count={manifest && manifest.meta && manifest.meta.last_page}
                onChange={onChangePagination}
            />
        );
    }

    useEffect(() => {
        setMeta({
            ...meta,
            ...{
                page: 1
            }
        });
        dispatch(fetchHubs())
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchManifest(meta))
    }, [meta]);

    return (
        <>
            { (manifest && manifest.notification && manifest.notification.show) &&
                <Notification
                    is_open={manifest.notification.show}
                    messageType={manifest.notification.messageType}
                    messages={manifest.notification.messageContent}
                    handleClose={() => dispatch(resetGenerateManifestNotification())}
                />}
            <Modal
                open={openCloseManifestConfirmModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography style={{ textAlign: "center" }} id="manifest-confirm-modal-description" sx={{ mt: 2 }}>
                        Do you want to close manifest for this group?
                    </Typography>
                    <Typography style={{ marginTop: "10px", textAlign: "center" }}>
                        <Button variant="outlined" onClick={() => { setOpenCloseManifestConfirmModal(false) }}>No</Button>
                        <Button variant="contained" style={{ marginLeft: "15px" }} onClick={() => { manifestClose() }}>Yes</Button>
                    </Typography>
                </Box>
            </Modal>
            <div className="scmaincard">
                <div className="sccardhead">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="h5" gutterBottom component="div">
                            Grouped Manifests
                    </Typography>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ul className="features-list">
                            <li>
                                <Tooltip title="Seacrh" placement="top-start">
                                    <IconButton style={(showSearch) ? { backgroundColor: 'rgb(230, 230, 230)' } : {}} onClick={() => onMenuClick("search", !showSearch)}>
                                        <SearchIcon style={{ color: 'rgb(25, 118, 210)' }} />
                                    </IconButton>
                                </Tooltip>
                            </li>
                            <li>
                                <Tooltip title="Filter" placement="top-start">
                                    <IconButton style={(showFilter) ? { backgroundColor: 'rgb(230, 230, 230)' } : {}} onClick={() => onMenuClick("filter", !showFilter)}>
                                        <FilterAltOutlinedIcon style={{ color: 'rgb(25, 118, 210)' }} />
                                    </IconButton>
                                </Tooltip>
                            </li>
                        </ul>
                    </div>
                </div>
                <Search visible={showSearch} meta={meta} setMeta={setMeta} />
                <Filters visible={showFilter} meta={meta} setMeta={setMeta} />
                <div style={{ width: '100%', background: '#fff', marginTop: "20px" }}>
                    <DataGrid
                        style={{ paddingTop: "37px" }}
                        getRowId={(row) => row._id}
                        rows={manifest && manifest.data}
                        columns={columns}
                        autoHeight={true}
                        disableColumnFilter={true}
                        disableColumnMenu={true}
                        sortingMode="server"
                        onSortModelChange={(newSortModel) => onSortChange(newSortModel)}
                        sortModel={meta && meta.sorting && JSON.parse(meta.sorting)}
                        disableSelectionOnClick
                        slots={{
                            pagination: CustomPagination,
                            toolbar: CustomToolbar,
                            NorowsData,
                            loadingOverlay: LinearProgress,
                        }}
                        loading={
                            (manifest && manifest.loading)
                        }
                    />
                </div>

            </div>
        </>
    );
}

export default ManifestsPage;
