import { createSlice } from '@reduxjs/toolkit';
import { OMSPROFILE } from '../../../API';

export const passwordslice = createSlice({
    name: 'password',
    initialState: {
        loading: false,
        status: null,
        message: "", 
        hasErrors: false,      
    },
    reducers: {       
        putPassword: (state) => {
            return {
                ...state,
                loading: true
            }
        },
        putPasswordSuccess: (state, action) => {
            return {
                ...state,
                loading: false,
                hasErrors: false, 
                message: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.message
            }
        },
        putPasswordFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                hasErrors: true,
                message: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.message
            }
        },

    }
})
export const { putPassword,
    putPasswordSuccess, putPasswordFailure } = passwordslice.actions;


export const updatePassword = (data) => {

    return (dispatch) => {

        dispatch(putPassword());

        try {
            OMSPROFILE.patch('/changepassword/1', data)
                .then(function (response) {
                    dispatch(putPasswordSuccess(response));                
                }).catch(function (error) {
                    dispatch(putPasswordFailure(error.response));                
                })
        }
        catch (error) {
            dispatch(putPasswordFailure(error.response));
        }

    }
}

export default passwordslice.reducer;
