import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// our components
import TrackingDrawer from './TrackingDrawer'
import NorowsData from './NorowsData'
import Carrier from './Carrier'
import OrderStatus from './OrderStatus'
import Customer from './Customer'
import CarrierTracking from './CarrierTracking'
import LoadingButton from "@mui/lab/LoadingButton";

// mui components
import {
    Typography,
    FormControl,
    Pagination,
    Card,
    CardContent,
    Stack,
    IconButton,
    Button,
    Box,
    Modal,
    Drawer,
    Divider,
    CardHeader,
    Paper
} from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import LinearProgress from '@mui/material/LinearProgress';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';

import { styled } from '@mui/material/styles';

import {
    DataGrid,
    GridToolbarContainer
} from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';
import Date from './Date'
import OrderDrawer from '../../pages/General/OrderDrawer'
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';

import {
    fetchOrderDetail
} from '../../pages/OrderDetail/orderdetailsSlice';
import { fetchTrackingDetail } from '../../pages/Reports/Deliveries/trackingEventsSlice';
import NewOrderCreateDrawer from '../General/NewOrder/NewOrderCreateDrawer'
import Printer from '../General/Printer'
import { getBulkLabels,resetBulkLabel } from '../../pages/BulkOrder/bulkPrintSlice';

import { fetchBulkOrderDetail, fetchSuccessOrderDetail, fetchFailedOrderDetail, resetBulkOrders } from '../../pages/BulkOrder/bulkOrderSlice';
import {
    printBulkLabels,
    dopostDataBulkShipment,
    postBulkOrderValidation
} from '../../pages/Orders/ordersSlice';
import { resetOrderNotifications } from "../../pages/Orders/ordersSlice";
import Notification from "../General/Notification";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    boxShadow: 'unset',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    color: theme.palette.text.secondary,
    height: '100%'
}));
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};
const BulkOrderDrawer = ({ visible, referenceId, bulkDraweronClose}) => {
    const dispatch = useDispatch();
    const bulkorderdata = useSelector(state => state.bulkorder && state.bulkorder.bulkorderdetails);
    const bulkvalidationOrdersData = useSelector(state => state.orders && state.orders.bulkOrderValidation);
    const successorderdetails = useSelector(state => state.bulkorder && state.bulkorder.successordersdetails);
    const failedorderdetails = useSelector(state => state.bulkorder && state.bulkorder.failureordersdetails);
    const newbulklabelprint = useSelector(state => state.bulkprintlabel);
    const orders = useSelector(state => state.orders);
    const addresssender = useSelector(state => state.addresssender);
    const addressbilling = useSelector(state => state.addressbilling);
    const box = useSelector(state => state.box)
    const ordersNotification = useSelector(state => state.orders.createShipment);
    const profile = useSelector(state => state.auth);
    const [meta, setMeta] = useState(successorderdetails.meta);
    const [trackingDrawerVisible, setTrackingDrawerVisible] = useState(false);
    const [orderDrawerVisible, setOrderDrawerVisible] = useState(false);
    const [trackingMessage, setTrackingMessage] = useState(null);
    const [drawer, setDrawer] = useState(true);
    const [success, setSuccess] = useState(false);
    const [failed, setFailed] = useState(false);
    const [isReturnOrder, setIsReturnOrder] = useState(false);

    const [choosenTrackingNumber, setChoosenTrackingNumber] = useState(0);
    const [isShipmentCreationFlow, setIsShipmentCreationFlow] = useState(false)
    const [newOrderCreateDrawerVisible, setNewOrderCreateDrawerVisible] = useState(false);
    const [newOrderFormData, setNewOrderFormData] = useState("");
    const [isDuplicateOrder, setIsDuplicateOrder] = useState(false);
    const [printUrl, setPrintUrl] = useState("")
    const [isPrinted, setIsPrinted] = useState(true)
    const [bulkLabelUrls, setbulkLabelUrls] = useState([])
    const [isBulkPrint, setisBulkPrint] = useState(false)
    const [isMultiPiece, setIsMultiPiece] = useState(false)
    const [currentOrderId,setCurrentOrderId]=useState("")
    const [currentNewOrderId,setCurrentNewtOrderId]=useState("")

    const [checkedIds, setCheckedIds] = useState([]);
    const [checkedItems, setCheckedItems] = useState([]);
    const [openBoxModal, setOpenBoxModal] = useState(false)
    const [openSenderAddressModal, setOpenSenderAddressModal] = useState(false)
    const [openBillingAddressModal, setOpenBillingAddressModal] = useState(false)
    const [customboxDimensions, setcustomboxDimensions] = useState({})
    const [modalVisible, setModalVisible] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [validCount, setValidCount] = useState(0);
    const [isBulkCreate, setIsBulkCreate] = useState(false);
    const [boxValues, setBoxValues] = useState([])

    const initialDimValues = {
        "length": "",
        "width": "",
        "height": "",
        "dimension_unit": "",
        "actual_weight": "",
        "weight_unit": "",
        "use_actual_weight":false,
    }
    const [defaultBoxDim, setDefaultBoxDim] = useState(initialDimValues)

    const orderCallback = (item) => {
        dispatch(fetchOrderDetail(item));
        setOrderDrawerVisible(true)
    }
    const orderOnClose = (values) => {
        setOrderDrawerVisible(false);
    };
    const ondrawerclose = () => {
        setValidCount(0)
        setTotalCount(0)
        setCheckedIds([])
        setCheckedItems([])
        setDrawer(false)
        bulkDraweronClose(false)
    }

    const onPageRefresh = () => {
        if (referenceId) {
            const data = { 'reference_id': referenceId }
            dispatch(fetchSuccessOrderDetail(data))
            dispatch(fetchFailedOrderDetail(data))
            dispatch(fetchBulkOrderDetail(referenceId))
            dispatch(getBulkLabels(referenceId))
        }
    }

    const newOrderCreateCallback = (item, is_duplicate_order = false) => {
        setCurrentOrderId(item.order.id)
        if (item != "" && is_duplicate_order == false) {
            setIsShipmentCreationFlow(true)
        } else {
            setIsShipmentCreationFlow(false)
        }
        setNewOrderFormData(item);
        setNewOrderCreateDrawerVisible(true)
        setIsDuplicateOrder(is_duplicate_order)
    }

    const newOrderCreateOnClose = () => {
        setNewOrderCreateDrawerVisible(false);
    }


    const printLabelBulk = (status) => {
            let userid=profile && profile.profile && profile.profile.id
            dispatch(printBulkLabels(bulkLabelUrls, userid))
            setisBulkPrint(status)
    }

    const onSelectionChange = (currentSelectedIds, currentAllItems) => {
        let tempCheckedIds = [...checkedIds]
        let tempCheckedItems = [...checkedItems];
        currentAllItems.map((item) => (
            checkBoxCompute(tempCheckedIds, tempCheckedItems, item, currentSelectedIds)
        ))
        setCheckedIds(tempCheckedIds)
        setCheckedItems(tempCheckedItems)
    }

    const checkBoxCompute = (tempCheckedIds, tempCheckedItems, item, currentSelectedIds) => {
        const index = tempCheckedIds.indexOf(item._id);
        const method = (currentSelectedIds.indexOf(item._id) > -1)
        if (method) {
            if (index === -1) {
                tempCheckedIds.push(item._id);
                tempCheckedItems.push(item)
            }
        } else if (!method) {
            if (index > -1) {
                tempCheckedIds.splice(index, 1);
                tempCheckedItems.splice(index, 1);
            }
        }
        return [tempCheckedIds, tempCheckedItems];
    }

    const createShipmentBulkData = () => {
        if (addresssender && addresssender.data && addresssender.data.length == 0) {
            setModalVisible(false)
            setOpenSenderAddressModal(true)
            return false;
        } else if (addresssender && addresssender.data && addresssender.data.length != 0) {
            let defaultSenderAddressFound = false
            addresssender.data.map((addressData) => {
                if (addressData && addressData.default) {
                    defaultSenderAddressFound = true
                }
            })
            if (defaultSenderAddressFound == false) {
                setModalVisible(false)
                setOpenSenderAddressModal(true)
                return false;
            }
        }

        if (addressbilling && addressbilling.data && addressbilling.data.length == 0) {
            setModalVisible(false)
            setOpenBillingAddressModal(true)
            return false;
        }

        let datas = checkedItems
        let bulkShipmentRequestData = []
        let dimensionError=false
        let serialnumber=0
        datas.forEach((item) => {
            let row = typeof item == "object" ? JSON.parse(JSON.stringify(item)) : {}
            if (bulkvalidationOrdersData && bulkvalidationOrdersData.data && Object.keys(bulkvalidationOrdersData.data).length!==0) {
                for (let key = 0; key < bulkvalidationOrdersData.data.valid_order_docids.length; key++) {
                    if (row._id===bulkvalidationOrdersData.data.valid_order_docids[key]){
                        serialnumber = serialnumber + 1;
                        let formatted_order = formatOrder(item,serialnumber)
                        if (formatted_order !== false) {
                            bulkShipmentRequestData.push(formatted_order)
                        }else{
                            dimensionError=true
                        }
                        break
                    }
                }
            }// Orders with tracking numbers will be skipped
        })
        setModalVisible(false)
        if(dimensionError==true){
            setOpenBoxModal(true);
        }else{
            if (bulkShipmentRequestData.length !== 0) {
                    let finalBulkOrderData={
                        "order_docids":bulkShipmentRequestData,
                        "bulk_reference_id": referenceId
                    }
                    dispatch(dopostDataBulkShipment(finalBulkOrderData, profile.profile.api_token, { ...meta }))
                    onSelectionChange([], orders.ordersData.data)
            } else {
                setModalVisible(true)
                return false
            }
        }
    }

    const validOrdersCount = (type) => {
        let  datas = checkedItems
        let  totalCount = checkedItems.length
        let orderIdDatas=[]
        datas.forEach((item) => {
            let row = typeof item == "object" ? JSON.parse(JSON.stringify(item)) : {}
            let docids=row && row._id;
            orderIdDatas.push(docids)
        })
        let final_data={
            "order_docids":orderIdDatas,
            "request_type":"CREATE_SHIPMENT",
            "bulk_reference_id":referenceId
        }
        if(orderIdDatas.length>0){
            dispatch(postBulkOrderValidation(final_data))
        }
    }

    const formatOrder = (item,serialnumber) => {
        let dimensions = "";
        let weight = "";
        let weight_unit = "";
        let dimension_unit = "";
        let skucheck=[]
        let doc_id=(item && item._id) ? item._id : ""
        if(item && item.product_info &&  (item.product_info.actual_weight ||  item.product_info.actual_weight=='') && item.product_info.dimensions){
            let length= item.product_info.dimensions && item.product_info.dimensions.length && item.product_info.dimensions.length >0 ? parseFloat(item.product_info.dimensions.length).toFixed(2) : ""
             let width= item.product_info.dimensions && item.product_info.dimensions.width && item.product_info.dimensions.width>0 ? parseFloat(item.product_info.dimensions.width).toFixed(2) : ""
             let height= item.product_info.dimensions && item.product_info.dimensions.height && item.product_info.dimensions.height >0 ? parseFloat(item.product_info.dimensions.height).toFixed(2) : ""
             weight= item.product_info.actual_weight >0 ?parseFloat(item.product_info.actual_weight).toFixed(2) : ""
             dimension_unit= item.product_info.dimensions && item.product_info.dimensions.height_unit!=='' ? item.product_info.dimensions.height_unit :""
             weight_unit= item.product_info && item.product_info.weight_unit!=='' ? item.product_info.weight_unit :""
             dimensions =  length + "x" + width + "x" + height;
        }else if(item && item.shipping_address && item.order && item.order.total_price && item.order.total_price!=='' && item.shipping_address.country_code && item.shipping_address.country_code!=='' && boxValues.length>0 && (item.shipping_address.country_code=='US' || item.shipping_address.country_code=='GB')){
            let orderTotalPrice=item && item.order && item.order.total_price;
            let boxTag='';
            if(item.shipping_address.country_code =='US' && orderTotalPrice<20){
                boxTag=1;
            }else if(item.shipping_address.country_code =='US' && orderTotalPrice >=20 && orderTotalPrice<35){
                boxTag=2;
            }
            else if(item.shipping_address.country_code =='US' && orderTotalPrice >35){
                boxTag=3;
            }
            else if(item.shipping_address.country_code =='GB' && orderTotalPrice <15){
                boxTag=4;
            }
            else if(item.shipping_address.country_code =='GB' && orderTotalPrice >=15){
                boxTag=5;
            }
            boxValues.map((item) => {
                if (boxTag==item.box_tag) {
                    weight =item.box_weight;
                    weight_unit =item.box_weight_unit;
                    dimension_unit = item.box_length_unit;
                    dimensions =  parseFloat(item.box_length).toFixed(2) + "x" + parseFloat(item.box_width).toFixed(2) + "x" + parseFloat(item.box_height);
                }
            })
        }else{
            let lineItemAcutualWeight=0;
            if(item && item.line_items && item.line_items.length!==0 && defaultBoxDim.use_actual_weight===true){
                item.line_items.map((item_value) => {
                    lineItemAcutualWeight = (item_value && item_value.actual_weight && item_value.actual_weight!="") ? parseFloat(lineItemAcutualWeight)+parseFloat(item_value.actual_weight) : parseFloat(lineItemAcutualWeight);
                })
            }
            if(skucheck.length==1 && skucheck[0] in customboxDimensions){
                weight =customboxDimensions[skucheck[0]].box_weight;
                weight_unit =customboxDimensions[skucheck[0]].box_weight_unit
                dimension_unit = customboxDimensions[skucheck[0]].box_width_unit
                dimensions = customboxDimensions[skucheck[0]].box_length + "x" + customboxDimensions[skucheck[0]].box_width + "x" + customboxDimensions[skucheck[0]].box_height;
            }else{
                if(lineItemAcutualWeight && lineItemAcutualWeight!==0 && defaultBoxDim.use_actual_weight===true){
                    weight = lineItemAcutualWeight;
                }else{
                    if (defaultBoxDim && defaultBoxDim.actual_weight !== "") {
                        weight = defaultBoxDim.actual_weight;
                    }
                }
                if (defaultBoxDim && defaultBoxDim.weight_unit !== "") {
                    weight_unit = defaultBoxDim.weight_unit;
                }
                if (defaultBoxDim  && defaultBoxDim.dimension_unit !== "") {
                    dimension_unit = defaultBoxDim.dimension_unit;
                }
                if (defaultBoxDim && defaultBoxDim.length !== "" && defaultBoxDim.width !== "" && defaultBoxDim.height !== "" ) {
                    dimensions = defaultBoxDim.length + "x" + defaultBoxDim.width + "x" + defaultBoxDim.height;
                }
            }
        }
        if (weight === "" || weight_unit === "" || dimension_unit === "" || dimensions === "") {
            return false;
        }else{
            return doc_id
        }
    }

    const errorColumns = [
        { 
            field: 'orderid',
            headerName: 'Order Id',
            flex:1,
            renderCell: (params) => {
                if(params && params.row && params.row.orderid ){
                    return <span style={{ color: 'rgb(24, 144, 255)', cursor: 'pointer' }} onClick={e => { 
                        orderCallback(params.row.orderid)
                        
                     }}>{params.row.orderid}</span>
                }else{
                    return <span style={{ color: 'rgb(24, 144, 255)', cursor: 'pointer' }}>-</span>
                }
        }},
        { 
            field: 'reason',
            headerName: 'Failure Reason',
             flex:1,
        }
    ]

    const errorTable=(errorvalues) =>{
        return (
            <>
                <DataGrid style={{marginTop:'20px',marginBottom:'20px'}}
                getRowId={(row) => row.docIds}
                rows={errorvalues}
                columns={errorColumns}
                initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                  }}
                  autoHeight={true}
                  pageSizeOptions={[5]}
                  disableColumnFilter={true}
                  disableColumnMenu={true}
                  disableRowSelectionOnClick={true}
                />
                </>
            )
    }


    useEffect(() => {
        let boxdimensionTemp={}
        if (box && box.data && box.data.length !== 0) {
            box.data.map((item) => {
                if (item.box_default === true) {
                    setDefaultBoxDim({
                        "length": parseFloat(item.box_length).toFixed(2),
                        "width": parseFloat(item.box_width).toFixed(2),
                        "height": parseFloat(item.box_height).toFixed(2),
                        "dimension_unit": item.box_length_unit,
                        "actual_weight": parseFloat(item.box_weight).toFixed(2),
                        "weight_unit": item.box_weight_unit,
                        "choosenmeasurement": item._id,
                        "insurance_amount": "100",
                        "use_actual_weight":item.use_actual_weight
                    })
                }
                if(!(item.box_name in boxdimensionTemp)){
                    boxdimensionTemp[item.box_name.toUpperCase()]=item
                }
            })
            setcustomboxDimensions(boxdimensionTemp)
            let boxData = box && box.data.filter(function( obj ) {
                return  obj.country;
             });
             setBoxValues(boxData)
        }
    }, [box && box.data]);
   

    useEffect(() => {
        if (newbulklabelprint && newbulklabelprint.bulklabeldata && newbulklabelprint.bulklabeldata.length!==0) {
            setbulkLabelUrls(newbulklabelprint.bulklabeldata)
           
        }
        },[newbulklabelprint.bulklabeldata])


    const columns = [{
        field: 'order.id',
        headerName: 'Order',
        headerClassName: 'super-app-theme--header',
        flex: 1,
        sortable: true,
        resizable: false,
        renderCell: (params) => {
            return <span style={{ color: 'rgb(24, 144, 255)', cursor: 'pointer' }} onClick={e => { orderCallback(params.row.order.id) }}>{params.row.order.id}</span>

        }
    },
    {
        field: 'line_items.tracking_number',
        headerName: 'Tracking Number',
        flex: 1,
        resizable: false,
        renderCell: (params) => {
            let tracking_number = (params.row.line_items[0] && params.row.line_items[0].tracking_number
                && typeof params.row.line_items[0].tracking_number !== "undefined" && params.row.line_items[0].tracking_number !== null)
                ? params.row.line_items[0].tracking_number : "";
            let carrier_name = (params.row.line_items[0] && params.row.line_items[0].fulfilment_company
                && typeof params.row.line_items[0].fulfilment_company !== "undefined" && params.row.line_items[0].fulfilment_company !== null)
                ? params.row.line_items[0].fulfilment_company : "";
            let message = (params.row.shipment && params.row.shipment.tracking && tracking_number !== "" && params.row.shipment.tracking[tracking_number] &&
                params.row.shipment.tracking[tracking_number].manifest && params.row.shipment.tracking[tracking_number].manifest.is_closed === false)
                ? "This shipment manifest is not yet closed" : null;
            let tracking = { "tracking_number": tracking_number, "carrier_name": carrier_name }
            return (
                <CarrierTracking trackingItems={tracking} dispatchCallback={trackingCallback} carrierImageWidth="60px" message={message}/>
            )
        }
    },
    {
        field: 'order.status',
        headerName: 'Shipment Status',
        flex: 1,
        resizable: false,
        renderCell: (params) => {
            return (
                <OrderStatus orderStatus={params.row.order.status}
                />
            )
        }
    },
    {
        field: 'customer.first_name',
        headerName: 'Customer',
        flex: 1,
        resizable: false,
        renderCell: (params) => {
            let name = ""
            if (params.row.customer && typeof params.row.customer.first_name !== "undefined" && params.row.customer.first_name !== null) {
                name = params.row.customer.first_name;
            }
            if (params.row.customer && typeof params.row.customer.last_name !== "undefined" && params.row.customer.last_name !== null && params.row.customer.last_name !== params.row.customer.first_name) {
                name = name + " " + params.row.customer.last_name
            }
            return (
                <Customer name={name} />
            )
        }
    },
    {
        field: 'order.date',
        headerName: 'Order Date',
        flex: 1,
        resizable: false,
        renderCell: (params) => {
            return <Date date={params.row.order.date} />
        }
    }]

    const failedcolumns = [
        {
            field: 'order.id',
            headerName: 'Order',
            headerClassName: 'super-app-theme--header',
            minWidth: 180,
            maxWidth: 180,
            flex: 1,
            sortable: true,
            resizable: false,
            renderCell: (params) => {
                return <span style={{ color: 'rgb(24, 144, 255)', cursor: 'pointer' }} onClick={e => { orderCallback(params.row.order.id) }}>{params.row.order.id}</span>
    
            }
        },
        {
            field: 'order.status',
            headerName: 'Shipment Status',
            minWidth: 180,
            maxWidth: 180,
            flex: 1,
            resizable: false,
            renderCell: (params) => {
                return (
                    <OrderStatus orderStatus={params.row.order.status}
                    />
                )
            }
        },
        {
            field: 'error',
            headerName: 'Error',
            minWidth: 370,
            maxWidth: 370,
            flex: 1,
            resizable: false,
            renderCell: (params) => {
                let errors = ""
                let message = ""
                if (params && params.row && params.row.shipment && params.row.shipment.bulk && params.row.shipment.bulk.errors && params.row.shipment.bulk.errors.length !== 0 && Array.isArray(params.row.shipment.bulk.errors)===true) {
                    params.row.shipment.bulk.errors.forEach(function (item) {
                        message = item.charAt(0).toUpperCase() + item.slice(1)
                        errors += item.charAt(0).toUpperCase() + item.slice(1) + ", "
                    })
                    return (
                        <Tooltip title={errors} placement="top-start">
                            <span style={{ cursor: "pointer",display:'flex',alignItems:'center' }}><InfoOutlinedIcon style={{paddingRight:'6px'}}/> {message}</span>
                        </Tooltip>
                    )
                }else if(params && params.row && params.row.shipment && params.row.shipment.bulk && params.row.shipment.bulk.errors && params.row.shipment.bulk.errors!==''){
                    return (
                        <Tooltip title={params.row.shipment.bulk.errors} placement="top-start">
                            <span style={{ cursor: "pointer",display:'flex',alignItems:'center' }}><InfoOutlinedIcon style={{paddingRight:'6px'}}/> {params.row.shipment.bulk.errors}</span>
                        </Tooltip>
                    )
                }else{
                    return <span>-</span>  
                }

            }
        },
        {
            field: 'customer.first_name',
            headerName: 'Customer',
            minWidth: 180,
            maxWidth: 180,
            flex: 1,
            resizable: false,
            renderCell: (params) => {
                let name = ""
                if (params.row.customer && typeof params.row.customer.first_name !== "undefined" && params.row.customer.first_name !== null) {
                    name = params.row.customer.first_name;
                }
                if (params.row.customer && typeof params.row.customer.last_name !== "undefined" && params.row.customer.last_name !== null && params.row.customer.last_name!==params.row.customer.first_name) {
                    name = name + " " + params.row.customer.last_name
                }
                return (
                    <Customer name={name} />
                )
            }
        },
        {
            field: 'order.date',
            headerName: 'Date',
            minWidth: 180,
            maxWidth: 180,
            flex: 1,
            resizable: false,
            renderCell: (params) => {
                return <Date date={params.row.order.date} />
            }
        },
        {
            field: 'action',
            headerName: 'Action',
            minWidth: 120,
            maxWidth: 120,
            flex: 1,
            sortable: false,
            headerAlign: 'center',
            align: 'right',
            resizable: false,
            renderCell: (params) => {
                let row = params.row
                return <div>
                    <span style={{ marginRight: '5px' }}>
                        <Tooltip title="Create Shipment" placement="left-start">
                            <Button
                            disabled={ bulkorderdata && bulkorderdata.data && bulkorderdata.data.data && bulkorderdata.data.data[0].status && bulkorderdata.data.data[0].status==='PENDING' || isBulkCreate===true || profile && profile.profile && profile.profile.disable_create_shipment ===true ? true : false}
                                variant="outlined"
                                onClick={() => newOrderCreateCallback(row)}
                            >
                                <AddCircleOutlineRoundedIcon /> <span style={{ marginLeft: "5px" }}>Label</span>
                            </Button>
                        </Tooltip>
                    </span>
                </div >
    
            }
        }
    ]

    const onSortChange = (currentSorting) => {
        setMeta({
            ...meta,
            ...{
                sorting: JSON.stringify(currentSorting)
            }
        });
    }

    const onChangePerPage = (event) => {
        setSuccess(true)
        setFailed(false)
        setMeta({
            ...meta,
            ...{
                page: 1,
                per_page: event.target.value
            }
        });
    }

    const onChangePagination = (event, page) => {
        setSuccess(true)
        setFailed(false)
        setMeta({
            ...meta,
            ...{
                page: page
            }
        });
    }

    const onChangePerPage1 = (event) => {
        setSuccess(false)
        setFailed(true)
        setMeta({
            ...meta,
            ...{
                page: 1,
                per_page: event.target.value
            }
        });
    }

    const onChangePagination1 = (event, page) => {
        setSuccess(false)
        setFailed(true)
        setMeta({
            ...meta,
            ...{
                page: page
            }
        });
    }

    useEffect(() => {
        if (referenceId) {
            setIsBulkCreate(false)
            dispatch(resetOrderNotifications())
            dispatch(fetchSuccessOrderDetail({
                ...meta,
                ...{
                    "reference_id": referenceId
                }
            }));
            dispatch(fetchFailedOrderDetail({
                ...meta,
                ...{
                    "reference_id": referenceId
                }
            }));
        }
    },[referenceId])

    useEffect(() => {
        if (referenceId) {
            if (success) {
                dispatch(fetchSuccessOrderDetail({
                    ...meta,
                    ...{
                        "reference_id": referenceId
                    }
                }));
            } else if (failed) {
                dispatch(fetchFailedOrderDetail({
                    ...meta,
                    ...{
                        "reference_id": referenceId
                    }
                }));
            }
            else  {
                dispatch(fetchSuccessOrderDetail({
                    ...meta,
                    ...{
                        "reference_id": referenceId
                    }
                }));
                dispatch(fetchFailedOrderDetail({
                    ...meta,
                    ...{
                        "reference_id": referenceId
                    }
                }));
                dispatch(fetchBulkOrderDetail(referenceId))
            }
        }
    }, [meta])

    useEffect(() => {
        if (orders.bulkLabel && orders.bulkLabel.filename && orders.bulkLabel.filename !== "" && orders.bulkLabel.is_shown === false && isBulkPrint) {
            setPrintUrl(process.env.REACT_APP_LABELINGCHIMP_LABEL_API_DOMAIN + "/merge-pdf?filename=" + orders.bulkLabel.filename)
            setIsPrinted(false)
            setisBulkPrint(false)
        }
    }, [orders.bulkLabel])

    useEffect(() => {
        if (orders && orders.createShipmentBulk.notification && orders.createShipmentBulk.notification.show===true && orders.createShipmentBulk.notification.messageType==='success') {
            ondrawerclose()
            setValidCount(0)
            setTotalCount(0)
            setCheckedIds([])
            setCheckedItems([])
            setIsBulkCreate(true)
        }
    }, [orders.createShipmentBulk])
 
    function CustomTotalRows() {

        return (
            <div className="custom-total-rows">
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    Showing {successorderdetails && successorderdetails.data && successorderdetails.data.length} of {(successorderdetails && successorderdetails.meta && successorderdetails.meta.total) ? successorderdetails.meta.total : 0}
                </FormControl>
            </div>
        )
    }

    function CustomPagesizeoptions() {

        return (
            <div className="custom-page-size-options">
                <span>
                    Show <select
                        value={meta && meta.per_page}
                        onChange={onChangePerPage}
                    >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </select> entries
                </span>
            </div>
        )
    }

    function CustomToolbar() {

        return (
            <GridToolbarContainer>
                <CustomPagesizeoptions />
                <CustomTotalRows />
            </GridToolbarContainer>
        );
    }

    function CustomPagination() {

        return (
            <Pagination
                color="primary"
                shape="circular"
                page={successorderdetails && successorderdetails.data && successorderdetails.meta && successorderdetails.meta.current_page}
                count={successorderdetails && successorderdetails.meta && successorderdetails.meta.last_page}
                onChange={onChangePagination}
            />
        );
    }

    function CustomFailedOrderTotalRows() {

        return (
            <div className="custom-total-rows">
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    Showing {failedorderdetails && failedorderdetails.data && failedorderdetails.data.length} of {(failedorderdetails && failedorderdetails.meta && failedorderdetails.meta.total) ? failedorderdetails.meta.total : 0}
                </FormControl>
            </div>
        )
    }

    function CustomFailedOrderPagesizeoptions() {

        return (
            <div className="custom-page-size-options">
                <span>
                    Show <select
                        value={meta && meta.per_page}
                        onChange={onChangePerPage1}
                    >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </select> entries
                </span>
            </div>
        )
    }

    function CustomFailedOrderToolbar() {

        return (
            <GridToolbarContainer>
                <CustomFailedOrderPagesizeoptions />
                <CustomFailedOrderTotalRows />
            </GridToolbarContainer>
        );
    }

    function CustomFailedOrderPagination() {

        return (
            <Pagination
                color="primary"
                shape="circular"
                page={failedorderdetails && failedorderdetails.data && failedorderdetails.meta && failedorderdetails.meta.current_page}
                count={failedorderdetails && failedorderdetails.meta && failedorderdetails.meta.last_page}
                onChange={onChangePagination1}
            />
        );
    }

    const trackingCallback = (carriername, trackingNumber, message = null) => {
        setIsMultiPiece(false)
        setTrackingMessage(message)
        let apikey = (profile && profile.profile && profile.profile.api_token) ? profile.profile.api_token : "";
        const data = { "carrier_name": carriername, 'tracking_number': trackingNumber }
        dispatch(fetchTrackingDetail(data, apikey))
        setTrackingDrawerVisible(true)
        let firstChar=trackingNumber.charAt(0);
        if(firstChar=='M'){
            setIsMultiPiece(true)
        }
    }
    const onClose = (values) => {
        setTrackingDrawerVisible(false);
    };

    useEffect(() => {
        if (orders && orders.createShipment && orders.createShipment.notification &&  orders.createShipment.notification.messageContent
            && orders.createShipment.notification.messageContent[0] === 'Shipment Created Successfully') {
                setTimeout(()=>{
                    onPageRefresh()
                    newOrderCreateOnClose()
                    setCurrentNewtOrderId(currentOrderId)
               }, 3000)
        }
    }, [orders]);

    return (
        <>

            {orders &&
            orders.createShipment.notification &&
            orders.createShipment.notification.show && (
                <Notification
                    is_open={orders.createShipment.notification.show}
                    messageType={orders.createShipment.notification.messageType}
                    messages={orders.createShipment.notification.messageContent}
                    handleClose={() => dispatch(resetOrderNotifications())}
                />
            )}  
            {(orders && orders.createShipmentBulk.notification && orders.createShipmentBulk.notification.show) &&
                <Notification
                    is_open={orders.createShipmentBulk.notification.show}
                    messageType={orders.createShipmentBulk.notification.messageType}
                    messages={orders.createShipmentBulk.notification.messageContent}
                    handleClose={() => dispatch(resetOrderNotifications())}
                />}
            <Printer isPrinted={isPrinted} setIsPrinted={setIsPrinted} print_url={printUrl} />
            <Modal
                open={openBoxModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} style={{ textAlign: "center" }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Box settings not configured
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Please configure Box settings to create shipment
                    </Typography>
                    <Button variant="contained" style={{marginTop:'15px'}} onClick={() => { window.location = '/settings/box'; }}>Ok</Button>
                </Box>
            </Modal>
            <Modal
                open={openSenderAddressModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Sender address not configured or default not set
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Please configure sender address to create shipment
                    </Typography>
                    <Button variant="contained" onClick={() => { window.location = '/settings/address-sender'; }}>Ok</Button>
                </Box>
            </Modal>
            <Modal
                open={modalVisible}
                aria-labelledby="bulk-modal-title"
                aria-describedby="bulk-modal-description"
            >
                <Box sx={style} style={{ textAlign: "center" }}>
                    {bulkvalidationOrdersData && bulkvalidationOrdersData.loading === true && (
                        <LinearProgress />
                    )}
                    <Typography id="bulk-modal-title" variant="h6" component="h2">
                    Create Shipment
                    </Typography>
                    <Typography id="bulk-modal-description" sx={{ mt: 2 }} style={{ marginBottom: "20px" }}>
                            {bulkvalidationOrdersData && bulkvalidationOrdersData.data  && Object.keys(bulkvalidationOrdersData.data).length!==0 && bulkvalidationOrdersData.data.valid_order_docids.length} out of {checkedItems.length} were eligible to create shipment <br />
                            <span>Note : Already created shipments will get ignored.</span>
                    </Typography>
                    {bulkvalidationOrdersData && bulkvalidationOrdersData.data && Object.keys(bulkvalidationOrdersData.data).length!==0  && bulkvalidationOrdersData.data.invalid_order_docids && bulkvalidationOrdersData.data.invalid_order_docids.length>0 && 
                            errorTable( bulkvalidationOrdersData.data.invalid_order_docids)
                        }
                    <Button variant="outlined" onClick={() => { setModalVisible(false) }}>Cancel</Button>
                    <Button variant="contained" style={{ marginLeft: "15px" }} onClick={() => { createShipmentBulkData() }}>Ok</Button>
                </Box>
            </Modal>
            <Modal
                open={openBillingAddressModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Billing address not configured
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Please configure billing address to create shipment
                    </Typography>
                    <Button variant="contained" onClick={() => { window.location = '/settings/address-billing'; }}>Ok</Button>
                </Box>
            </Modal>
            <Drawer
                anchor="right"
                open={visible}
                onClose={ondrawerclose}
                PaperProps={{
                    sx: { width: "75%" },
                }}
            >
                <div style={{ padding: "0 30px" }}>
                    <Card sx={{ margin: '20px 0' }} className="sc--cardhead--details">
                        <CardHeader title={`Bulk Order Details for ${referenceId}`} action={
                            <div>
                                <IconButton aria-label="settings" onClick={onPageRefresh}>
                                    <RefreshIcon />
                                </IconButton>
                                <IconButton aria-label="settings" onClick={ondrawerclose}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        } />
                        <Divider />
                    </Card>

                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography variant="h6">
                                <b>Overview</b>
                            </Typography>
                            <Stack direction="row" spacing={30} style={{ marginTop: "10px" }}>
                                {
                                    bulkorderdata && bulkorderdata.data && bulkorderdata.data.data && bulkorderdata.data.data.map((value, key) => {
                                        return (
                                            <>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={4} md={3}>
                                                        <p><span className="text-soft">Total shipments</span></p>
                                                        <p>{value.total_shipments}</p>
                                                    </Grid>
                                                    <Grid item xs={4} md={3}>
                                                        <p><span className="text-soft">Successful shipments</span></p>
                                                        <p>{value.success_count}</p>
                                                    </Grid>
                                                    <Grid item xs={4} md={3}>
                                                        <p><span className="text-soft">Failed shipments</span></p>
                                                        <p>{value.failure_count}</p>
                                                    </Grid>
                                                    <Grid item xs={4} md={3}>
                                                        <p><span className="text-soft">Created date</span></p>
                                                        <p>{value.created_at}</p>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        )
                                    }

                                    )
                                }

                            </Stack>
                        </CardContent>
                    </Card>

                    <Card sx={{ marginTop: "20px" }} className="sc--cardhead--details">
                        <div style={{ width: '100%', background: '#fff' }}>
                            <CardHeader title="Failed shipments" action={
                                <>
                                    <div>
                                            <Button
                                                variant="outlined"
                                                disabled={failedorderdetails && failedorderdetails.data && failedorderdetails.data.length > 0 &&  bulkorderdata && bulkorderdata.data && bulkorderdata.data.data && bulkorderdata.data.data[0].status && bulkorderdata.data.data[0].status==='COMPLETED' && checkedIds.length>0 && isBulkCreate===false ? false : true }
                                                style={{marginRight:'10px'}}
                                                onClick={() =>{ setModalVisible(true)
                                                               validOrdersCount('createshipment')
                                                }}
                                            >
                                                <AddCircleOutlineRoundedIcon style={{ paddingRight: '10px' }} /> Bulk Create Label
                                             </Button>
                                    
                                    {failedorderdetails && failedorderdetails.data && failedorderdetails.data.length > 0 &&
                                        < a href={process.env.REACT_APP_API_DOMAIN +`/labelgen/bulk-shipments/export?reference_id=${referenceId}`} style={{ textDecoration: 'unset' }} target="_blank" rel="noopener noreferrer" download>
                                            <Button variant="outlined">
                                                <FileDownloadOutlinedIcon style={{ paddingRight: '10px' }} /> Export
                                         </Button>
                                        </a>
                                    }
                                    {failedorderdetails && failedorderdetails.data && failedorderdetails.data.length === 0 &&
                                            <Button
                                                variant="outlined"
                                                disabled
                                            >
                                                <FileDownloadOutlinedIcon style={{ paddingRight: '10px' }} /> Export
                                             </Button>
                                    }
                                    </div>
                                </>
                            }
                            />
                            <DataGrid
                                style={{ paddingTop: "37px"}}
                                getRowId={(row) => row._id}
                                rows={failedorderdetails && failedorderdetails.data}
                                columns={failedcolumns}
                                autoHeight={true}
                                disableColumnFilter={true}
                                disableColumnMenu={true}
                                sortingMode="server"
                                checkboxSelection
                                onRowSelectionModelChange={(newSelectionModel) => { onSelectionChange(newSelectionModel, failedorderdetails && failedorderdetails.data) }}
                                rowSelectionModel={checkedIds}
                                onSortModelChange={(newSortModel) => onSortChange(newSortModel)}
                                sortModel={meta && meta.sorting && JSON.parse(meta.sorting)}
                                disableRowSelectionOnClick
                                slots={{
                                    loadingOverlay: LinearProgress,
                                    pagination: CustomFailedOrderPagination,
                                    toolbar: CustomFailedOrderToolbar,
                                    NorowsData
                                }}
                                loading={
                                    (failedorderdetails && failedorderdetails.loading) ||
                                    (orders &&  orders.createShipment && orders.createShipment.loading) ||
                                    (orders && orders.createShipmentBulk && orders.createShipmentBulk.loading)
                                }
                            />
                        </div>
                    </Card>

                    <Card sx={{ marginTop: "20px", marginBottom: '30px' }} className="sc--cardhead--details">
                        <div style={{ width: '100%', background: '#fff' }}>
                            <CardHeader title="Successful shipments"   action={
                                <>
                                  {newbulklabelprint && newbulklabelprint.bulklabeldata && newbulklabelprint.bulklabeldata.length!==0 &&
                                    <LoadingButton variant="outlined" onClick={e=>printLabelBulk(true)} loading={orders && orders.bulkLabel && orders.bulkLabel.loading}>
                                        <LocalPrintshopIcon style={{ paddingRight: '10px' }} /> Print Label
                                    </LoadingButton>
                                    }
                                     {newbulklabelprint && newbulklabelprint.bulklabeldata && newbulklabelprint.bulklabeldata.length==0 &&
                                        <div>
                                            <Button
                                                variant="outlined"
                                                disabled
                                            >
                                                <LocalPrintshopIcon style={{ paddingRight: '10px' }} /> Print Label
                                             </Button>
                                        </div>
                                    }
                                </>
                            }
                            />
                            <DataGrid
                                style={{ paddingTop: "37px"}}
                                getRowId={(row) => row._id}
                                rows={successorderdetails && successorderdetails.data}
                                columns={columns}
                                autoHeight={true}
                                disableColumnFilter={true}
                                disableColumnMenu={true}
                                sortingMode="server"
                                onSortModelChange={(newSortModel) => onSortChange(newSortModel)}
                                sortModel={meta && meta.sorting && JSON.parse(meta.sorting)}
                                disableRowSelectionOnClick
                                slots={{
                                    loadingOverlay: LinearProgress,
                                    pagination: CustomPagination,
                                    toolbar: CustomToolbar,
                                    NorowsData
                                }}
                                loading={
                                    (successorderdetails && successorderdetails.loading) ||
                                        (orders &&  orders.createShipment && orders.createShipment.loading) ||
                                        (orders && orders.createShipmentBulk && orders.createShipmentBulk.loading) 
                                }
                            />
                        </div>
                    </Card>

                    <TrackingDrawer
                        visible={trackingDrawerVisible} isMultiPiece={isMultiPiece} message={trackingMessage} onClose={onClose}
                    />
                     <OrderDrawer
                        visible={orderDrawerVisible} onClose={orderOnClose}
                    />
                </div>
            </Drawer >
            

            <NewOrderCreateDrawer
                visible={newOrderCreateDrawerVisible}
                reportName={"orders"}
                newOrderFormData={newOrderFormData}
                setNewOrderFormData={setNewOrderFormData}
                meta={meta}
                onClose={newOrderCreateOnClose}
                isDuplicateOrder={isDuplicateOrder}
                setNewOrderCreateDrawerVisible={setNewOrderCreateDrawerVisible}
                choosenTrackingNumber={choosenTrackingNumber}
                isShipmentCreationFlow={isShipmentCreationFlow}
                isLabelPrint={true}
                isFailedShipment={true}
                isReturnOrder={isReturnOrder}
                setIsReturnOrder={setIsReturnOrder}
                currentOrderId={currentNewOrderId}
            />
        </>
    )
}


export default BulkOrderDrawer;