import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import { DataGrid } from '@mui/x-data-grid';
import LinearProgress from '@mui/material/LinearProgress';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';

// import NorowsData from '../../../pages/General/NorowsData'

import { fetchNotificationEvents, fetchchannelconfig, updatechannelconfig, updateNotificationEvents, resetConfigureState } from './emailSlice';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    height: '100%',
    padding: '20px 0'
}));
const EmailIntegration = ({ storeName, platformName, storeParam, storeId }) => {
    const dispatch = useDispatch();
    const email = useSelector(state => state.email && state.email.event);
    const notificationconfig = useSelector(state => state.email && state.email.notificationconfig);
    const channelconfig = useSelector(state => state.email && state.email.channelconfig);

    const [alert, setAlert] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const [choosenSMTPId, setChoosenSMTPId] = useState('default');
    const handleClose = () => {
        dispatch(resetConfigureState())
        setSnackOpen(false);
        setAlert(false);
    };


    const initialValues = {
        id: '',
        from_name: '',
        reply_to_email: '',
        smtp_method: 'default',
        notification_type: 'EMAIL',
        from_email: "",
        host: "",
        username: "",
        password: "",
        port: '',
        encryption: '',
        is_enabled: false
    }
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});

    const validator = (fieldValues) => {
        let temp = { ...errors }
        if ('from_name' in fieldValues)
            temp.from_name = fieldValues.from_name ? "" : "This field is required."
        if ('reply_to_email' in fieldValues)
            temp.reply_to_email = fieldValues.reply_to_email ? "" : "This field is required."

        setErrors({
            ...temp
        })

        if (fieldValues === values)
            return Object.values(temp).every(x => x == "")
    }

    const onChangeSMTP = (items) => {
        setChoosenSMTPId(items)
        setValues({
            ...values,
            smtp_method: items
        })
    }

    const onInputChange = e => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
        validator({ [name]: value })
    }
    const handleChange = (e) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: e.target.checked,
        });
        validator({ [name]: value })
    };
    const handleSubmit = e => {
        e.preventDefault()
        const isValid = validator(values)
        if (isValid) {
            let data = ''
            if (values.smtp_method === 'default') {
                data = { "id": values.id, "from_name": values.from_name, "notification_type": values.notification_type, "reply_to_email": values.reply_to_email, "smtp_method": values.smtp_method, "is_enabled": values.is_enabled }
            } else {
                data = values
            }
            dispatch(updatechannelconfig(data));
        }
    }

    const onChangeEventConfigure = (e, values) => {
        let data = { 'event_key': values.event_key, 'id': values._id, 'is_enabled': String(e), 'notification_type': values.notification_type }
        dispatch(updateNotificationEvents(data));
    }
    const columns = [
        { field: 'event_name', headerName: 'Event Type', flex: 1,resizable: false },
        // { field: 'notification_type', headerName: 'Email', flex: 1 },
        {
            field: 'notification_type',
            headerName: 'Email',
            flex: 1,
            resizable: false,
            renderCell: (params) => {
                return (
                    <Switch
                        defaultChecked={params.row.is_enabled}
                        name="is_update_shipment"
                        onChange={(e) => {
                            onChangeEventConfigure(e.target.checked, params.row)
                        }
                        }
                    />
                )
            }
        },
        {
            field: 'Template',
            resizable: false,
            renderCell: (params) => {
                return (
                    <Link to={`/notification/template/${params.row.event_key}/${params.row.notification_type}/${params.row.is_enabled}/${params.row._id}`} style={{ display: 'flex', alignItems: 'center', textDecoration: 'unset', color: '#1976d2' }}><EditIcon /> <span style={{ paddingLeft: '10px' }}>Edit</span></Link>
                )
            },
            flex: 1
        }
    ]
    useEffect(() => {
        if (channelconfig && channelconfig.data && channelconfig.data.length !== 0 && channelconfig.loading === false) {
            channelconfig && channelconfig.data && channelconfig.data.map((item) => {
                if (item.notification_type === 'EMAIL') {
                    setValues({
                        ...values,
                        id: item._id,
                        from_name: item.from_name,
                        reply_to_email: item.reply_to_email,
                        from_email: item.from_email,
                        host: item.host,
                        username: item.username,
                        password: item.password,
                        port: item.port,
                        encryption: item.encryption,
                        is_enabled: item.is_enabled
                    })
                    setChoosenSMTPId(item.smtp_method)
                }
            })

        }
    }, [channelconfig && channelconfig.data])

    useEffect(() => {
        dispatch(fetchNotificationEvents());
        dispatch(fetchchannelconfig());
    }, [dispatch])
    useEffect(() => {
        if (notificationconfig && notificationconfig.notification && notificationconfig.notification.showMessage) {
            setAlert(true)
            setSnackOpen(true)
        }

    }, [notificationconfig])
    return (
        <>
            {alert &&
                <Snackbar
                    open={snackOpen}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    autoHideDuration={6000}
                    onClose={handleClose}
                >
                    <Alert
                        variant="filled"
                        severity=
                        {notificationconfig && notificationconfig.notification && notificationconfig.notification.messageType === "success" ? "success" : "error"}
                        style={{ justifyContent: 'center', margin: "10px 0" }}
                    >
                        <span > {notificationconfig && notificationconfig.notification && notificationconfig.notification.messageContent}</span>
                    </Alert>
                </Snackbar>
            }
            {(email && email.loading === true) && (channelconfig && channelconfig.loading === true) && <>
                <div className="vertical-center">
                    <CircularProgress />
                </div>
                </>
            } 
            {(email && email.loading === false) && (channelconfig && channelconfig.loading === false) && <>

            <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                    <Card sx={{ marginBottom: '30px' }} className="sc--cardhead--details">
                        <div style={{ width: '100%', background: '#fff' }}>
                            <CardHeader title="SMTP Settings" />
                            <Divider />
                            
                                <div style={{ margin: 'auto', width: '60%' }}>
                                    <div
                                        className="sc--form--center"
                                        style={{ margin: '20px 0' }}>
                                        <TextField
                                            label="From Name"
                                            name="from_name"
                                            value={values.from_name}
                                            onChange={onInputChange}
                                            error={errors.from_name}
                                            helperText={errors.from_name}
                                            style={{ width: "100%", margin: '8px' }}
                                            variant="standard"
                                        />

                                        <TextField
                                            label="Reply to email"
                                            name="reply_to_email"
                                            value={values.reply_to_email}
                                            onChange={onInputChange}
                                            error={errors.reply_to_email}
                                            helperText={errors.reply_to_email}
                                            style={{ width: "100%", margin: '8px' }}
                                            variant="standard"
                                        />
                                    </div>
                                    <div className="sc--form--center" style={{ margin: '20px 0' }}>
                                        <FormControl
                                            variant="standard"
                                            className="help-text-error"
                                            style={{ width: '100%', margin: '8px' }}
                                        >
                                            <InputLabel
                                                id="demo-simple-select-standard-label"
                                            >SMTP
                                             </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                name="smtp_method"
                                                value={choosenSMTPId}
                                                onChange={(e) => {
                                                    onChangeSMTP(e.target.value)
                                                }
                                                }
                                                // error={errors.chooseMeasurement}
                                                label="SMTP"
                                            >
                                                <MenuItem value={'default'}>Use ShippingChimp SMTP</MenuItem>
                                                <MenuItem value={'custom'}>Use Custom SMTP</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    {choosenSMTPId === 'custom' &&
                                        <>
                                            <div
                                                className="sc--form--center"
                                                style={{ margin: '20px 0' }}>
                                                <TextField
                                                    label="From Email"
                                                    name="from_email"
                                                    value={values.from_email}
                                                    onChange={onInputChange}
                                                    error={errors.from_email}
                                                    helperText={errors.from_email}
                                                    style={{ width: "100%", margin: '8px' }}
                                                    variant="standard"
                                                />
                                                <TextField
                                                    label="SMTP Host"
                                                    name="host"
                                                    value={values.host}
                                                    onChange={onInputChange}
                                                    error={errors.host}
                                                    helperText={errors.host}
                                                    style={{ width: "100%", margin: '8px' }}
                                                    variant="standard"
                                                />
                                            </div>
                                            <div
                                                className="sc--form--center"
                                                style={{ margin: '20px 0' }}>
                                                <TextField
                                                    label="SMTP Username"
                                                    name="username"
                                                    value={values.username}
                                                    onChange={onInputChange}
                                                    error={errors.username}
                                                    helperText={errors.username}
                                                    style={{ width: "100%", margin: '8px' }}
                                                    variant="standard"
                                                />
                                                <TextField
                                                    label="SMTP Password"
                                                    name="password"
                                                    type="password"
                                                    value={values.password}
                                                    onChange={onInputChange}
                                                    error={errors.password}
                                                    helperText={errors.password}
                                                    style={{ width: "100%", margin: '8px' }}
                                                    variant="standard"
                                                />
                                            </div>
                                            <div
                                                className="sc--form--center"
                                                style={{ margin: '20px 0' }}>
                                                <TextField
                                                    label="SMTP Port"
                                                    name="port"
                                                    value={values.port}
                                                    onChange={onInputChange}
                                                    error={errors.port}
                                                    helperText={errors.port}
                                                    style={{ width: "100%", margin: '8px' }}
                                                    variant="standard"
                                                />
                                                <TextField
                                                    label="SMTP Encryption"
                                                    name="encryption"
                                                    value={values.encryption}
                                                    onChange={onInputChange}
                                                    error={errors.encryption}
                                                    helperText={errors.encryption}
                                                    style={{ width: "100%", margin: '8px' }}
                                                    variant="standard"
                                                />
                                            </div>
                                        </>
                                    }
                                    <div
                                        style={{ margin: '20px 0' }}
                                    >
                                        <FormGroup>
                                            <FormControlLabel
                                                control={<Switch
                                                    defaultChecked={channelconfig && channelconfig.data && channelconfig.data[0] && channelconfig.data[0].is_enabled ? channelconfig && channelconfig.data && channelconfig.data[0] && channelconfig.data[0].is_enabled : values.is_enabled}
                                                    name="is_enabled"
                                                    onChange={handleChange}
                                                />
                                                }
                                                label="Status"
                                            />
                                        </FormGroup>
                                    </div>
                                    <div className="sc--form--center" style={{ margin: '30px 0' }}>
                                        <Button variant="outlined" onClick={handleSubmit}>Save</Button>
                                    </div>
                                </div>
                            
                        </div>
                    </Card>
                </Grid>
            </Grid>

                <Card sx={{ marginBottom: '30px' }} className="sc--cardhead--details">
                    <div style={{ width: '100%', background: '#fff' }}>
                        <CardHeader title="Configure Notification" />
                        <div style={{ maxWidth: '100%' }}>
                            <DataGrid
                                getRowId={(row) => row._id}
                                disableRowSelectionOnClick={true}
                                rows={email && email.data}
                                columns={columns}
                                autoHeight={true}
                                slots={{
                                    loadingOverlay: LinearProgress,
                                }}
                                hideFooter={true}
                                loading={notificationconfig && notificationconfig.loading}
                            />
                        </div>
                    </div>
                </Card>
                </>
            }
        </>

    )
}


export default EmailIntegration;