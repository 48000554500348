import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';

import {
    Paper,
    Divider,
    styled,
    Grid,
    Box,
    Button,
    Modal,
    Typography,
    Card,
    CardHeader,
    CardContent
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

import { fetchAddressBookDetails } from './addressbookSlice';
import { fetchCountry, fetchState, fetchMultiStates } from '../../../pages/General/countryStateCitySlice';

const SenderAddressSelect = ({ values,errors, addresssender, isReturnOrder, senderAddressObjectId, dispatchSenderAddressCallback, dispatchSenderCountryCallback, dispatchInputChangeCallback }) => {
    const dispatch = useDispatch();
    const country_code = useSelector(state => state.countrystate.country);
    const state = useSelector(state => state.countrystate.multistates);
    const filteredCountryData = country_code.data.filter(({ code }) => code );
    const [isReturnStates, setisReturnStates] = useState(true)
    const onChangeCountry = id => {
        dispatchSenderCountryCallback(id)
    }

    const onsenderaddresschange = (id) => {
        dispatchSenderAddressCallback(id)
    }
    
    const handleInputChange = e => {
        const { name, value } = e.target
        dispatchInputChangeCallback(name, value)
    }

    useEffect(() => {
        if (values.sender_country_code && values.sender_country_code!='' && isReturnStates) {
            dispatch(fetchMultiStates(values.sender_country_code));
        }
    }, [isReturnStates]);

    
      const getSelectedCountry = (country_code) => {
        let filteredValue = { code: "", name: "" };
        filteredCountryData.map((key) => {
          if (key.code == country_code) {
            filteredValue = key;
          }
        });
         return filteredValue;
      };

      const onSelectStateChange = (currentstate) => {
        dispatchInputChangeCallback("sender_state_code", currentstate)
      };
    
      const getSelectedState = (currentstate) => {
        let filteredStateValue = { code: "", name: "" };
        let tempState = "";
        state &&
          state.data &&
          state.data.map((key) => {
            if (key.code == currentstate && tempState == "") {
              filteredStateValue = key;
              tempState = "success";
            }
          });
        return filteredStateValue;
      };


    return (
        <>
            <Grid xs={6} md={6} style={{ width: '50%', padding: '10px' }}>
                <Card
                    sx={{ minWidth: 275, boxShadow: 'unset', border: '1px solid rgba(0, 0, 0, 0.12)', height: '100%' }}
                    className="sc--cardhead--details">
                    <CardHeader title='Sender' />
                    <Divider />
                    <CardContent>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '25ch' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            {isReturnOrder ?
                             <>
                            
                            <div className="sc--form--center">
                                <TextField id="standard-basic"
                                    label="Name"
                                    variant="standard"
                                    name="sender_name"
                                    value={values.sender_name}
                                    onChange={handleInputChange}
                                    error={errors.sender_name}
                                    helperText={errors.sender_name}
                                    style={{ width: 300 }}
                                />
                                <TextField id="standard-basic"
                                    label="Company"
                                    variant="standard"
                                    name="sender_company"
                                    value={values.sender_company!==""?values.sender_company:values.sender_name}
                                    onChange={handleInputChange}
                                    error={errors.sender_company}
                                    helperText={errors.sender_company}
                                    style={{ width: 300 }} />

                            </div>
                            <div className="sc--form--center">
                                <TextField id="standard-basic"
                                    label="Phone Number"
                                    name="sender_phone"
                                    value={values.sender_phone}
                                    variant="standard"
                                    onChange={handleInputChange}
                                    style={{ width: 300 }}
                                />
                                <TextField id="standard-basic"
                                    label="Email"
                                    variant="standard"
                                    name="sender_email"
                                    value={values.sender_email}
                                    onChange={handleInputChange}
                                    error={errors.sender_email}
                                    helperText={errors.sender_email}
                                    style={{ width: 300 }} />
                            </div>
                            <div className="sc--form--center">
                                <TextField id="standard-basic"
                                    label="Address1"
                                    variant="standard"
                                    name="sender_address"
                                    value={values.sender_address}
                                    inputProps={{ maxLength: 35 }}
                                    onChange={handleInputChange}
                                    error={errors.sender_address}
                                    helperText={errors.sender_address}
                                    style={{ width: 300 }} />

                                <TextField id="standard-basic"
                                    label="Address2"
                                    variant="standard"
                                    name="sender_address2"
                                    value={values.sender_address2}
                                    inputProps={{ maxLength: 35 }}
                                    onChange={handleInputChange}
                                    error={errors.sender_address2}
                                    helperText={errors.sender_address2}
                                    style={{ width: 300 }} />
                            </div>
                            <div className="sc--form--center">
                                <TextField id="standard-basic"
                                    label="City"
                                    variant="standard"
                                    name="sender_city"
                                    value={values.sender_city}
                                    onChange={handleInputChange}
                                    error={errors.sender_city}
                                    helperText={errors.sender_city}
                                    style={{ width: 300 }} />
                                <TextField id="standard-basic"
                                    label="Postal Code"
                                    variant="standard"
                                    name="sender_postal_code"
                                    inputProps={{ maxLength: 10}}
                                    value={values.sender_postal_code}
                                    onChange={handleInputChange}
                                    error={errors.sender_postal_code}
                                    helperText={errors.sender_postal_code}
                                    style={{ width: 300 }} />
                            </div>
                            <div className="sc--form--center" style={{alignItems:"unset",justifyContent:"unset",margin:"0 10px"}}>
                            <Autocomplete
                                className="help-text-error"
                                disablePortal
                                name="sender_country_code"
                                id="country-select-demo"
                                
                                options={filteredCountryData}
                                autoHighlight
                                loading={country_code && country_code.loading}
                                getOptionLabel={(option) => option.name}
                                value={getSelectedCountry(values && values.sender_country_code)}
                                renderOption={(props, option) => (
                                <MenuItem {...props} value={option.code} key={option.code}>
                                    {option.icon} {option.name}
                                </MenuItem>
                                )}
                                onChange={(event, value) => {
                                if (value !== null) {
                                    onChangeCountry(value.code);
                                } else {
                                    onChangeCountry("");
                                }
                                }}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="country"
                                    variant="standard"
                                    helperText={errors.sender_country_code}
                                    sx={{ width: "34ch !important",margin: '8px' }}
                                />
                                )}
                            />
                            <Autocomplete
                                className="help-text-error"
                                disablePortal
                                name="sender_state_code"
                                id="state-select-demo"
                               
                                options={state && state.data}
                                autoHighlight
                                loading={
                                (state && state.loading) || (country_code && country_code.loading)
                                }
                                getOptionLabel={(option) => option.name}
                                value={getSelectedState(values && values.sender_state_code)}
                                renderOption={(props, option) => (
                                <MenuItem {...props} value={option.code} key={option.code}>
                                    {option.name}
                                </MenuItem>
                                )}
                                onChange={(event, value) => {
                                    if (value !== null) {
                                      onSelectStateChange(value.code);
                                    } else {
                                      onSelectStateChange("");
                                    }
                                  }}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="state"
                                    variant="standard"
                                    helperText={errors.sender_state_code}
                                    sx={{ width: "34ch !important",margin: '8px' }}
                                />
                                )}
                            />
                            </div>
                            </>
                            :
                            <>
                            <div className="sc--form--center" style={{ margin: '20px 0' }}>
                                <FormControl
                                    variant="standard"
                                    style={{ width: "68ch", margin: '8px' }} >
                                    <InputLabel
                                        id="demo-simple-select-standard-label"
                                        style={{ color: errors.preferred_sender_id ? "#D32F2F" : '' }}
                                    >Sender Address
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        name="preferred_sender_id"
                                        value={senderAddressObjectId}
                                        error={errors.preferred_sender_id}
                                        onChange={e => {
                                            onsenderaddresschange(e.target.value,false)
                                            // setErrors({ "preferred_sender_id": "" })
                                        }}
                                        label="Sender Address"
                                    >
                                        {addresssender && addresssender.data && addresssender.data.map((item) => {
                                            return (
                                                <MenuItem value={item._id}>
                                                    <p style={{ margin: 'unset' }}>{item.sender_name} ({item.sender_company})<br />
                                                        {item.sender_address}, {item.sender_city}, {item.sender_state_code}, {item.sender_country_code} - {item.sender_postal_code}
                                                    </p>
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>

                                    <FormHelperText>
                                        {(addresssender && addresssender.loading === false)
                                            && addresssender && addresssender.data && addresssender.data.length == 0 &&
                                            <span style={{ color: "red" }}>
                                                Please add sender address
                                            <Link to="/settings/address-sender" style={{ color: 'blue', textDecoration: 'none' }}> here </Link>
                                            </span>
                                        }
                                        {errors.preferred_sender_id && addresssender && addresssender.data && addresssender.data.length !== 0 &&
                                            <span style={{ color: "red" }}>
                                                This field is required.
                                            </span>
                                        }
                                    </FormHelperText>
                                </FormControl>
                            </div>
                            </>
                            }

                            <div className="sc--form--center" style={{ margin: '20px 0' }}>
                            <TextField id="standard-basic"
                                    label="Reference no"
                                    variant="standard"
                                    name="sender_reference_no"
                                    value={values.sender_reference_no}
                                    inputProps={{ maxLength: 35 }}
                                    onChange={handleInputChange}
                                    style={{ width: "68ch", margin: '8px' }}
                             />
                            </div>

                        </Box>
                    </CardContent>
                </Card>
            </Grid>
        </>
    )
};

export default SenderAddressSelect;