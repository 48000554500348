import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import _ from 'lodash';
// our components
import TrackingDrawer from '../../pages/General/TrackingDrawer'
import OrderStatus from '../../pages/General/OrderStatus'
import MultipleTracking from '../../pages/General/MultipleTracking'
import Filters from './Filters'
import Search from './Search'
import DynamicColumns from '../../pages/General/DynamicColumns'
import NorowsData from '../../pages/General/NorowsData'
import Notification from '../../pages/General/Notification'
import Date from '../../pages/General/Date'
import Printer from '../../pages/General/Printer'
import {
    fetchOrderDetail
} from '../../pages/OrderDetail/orderdetailsSlice';

// mui components
import {
    Typography,
    FormControl,
    Pagination,
    IconButton,
    Tooltip,
    Modal,
    Box,
    Button,
    List,
    ListItem,
    ListItemText,
    ListItemButton,
    ListItemIcon,
    Popover,
    Card,
    CardHeader,
    CardContent,
    Divider,
    TextField,
    Select,
    MenuItem,
    InputLabel
} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import MuiPagination from '@mui/material/Pagination';
import LinearProgress from '@mui/material/LinearProgress';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ViewColumnSharpIcon from '@mui/icons-material/ViewColumnSharp';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import PrintIcon from '@mui/icons-material/Print';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import TagOutlinedIcon from '@mui/icons-material/TagOutlined';
import Chip from '@mui/material/Chip';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import KeyboardReturnOutlinedIcon from '@mui/icons-material/KeyboardReturnOutlined';
import SyncIcon from '@mui/icons-material/Sync';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import FormHelperText from '@mui/material/FormHelperText';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';

import {
    // DataGrid,
    GridToolbarContainer,
    GridFooterContainer,
    useGridApiRef,
    DataGridPro,
    useGridApiContext,
    gridColumnLookupSelector
} from '@mui/x-data-grid-pro';
import { DataGrid } from '@mui/x-data-grid';

//actions
import {
    fetchOrders,
    fetchOrderColumns,
    saveOrdersColumns,
    doPostTrackingNumberVoid,
    dopostTrackingNumberCloseManifest,
    printBulkLabels,
    dopostDataBulkShipment,
    resetOrderNotifications,
    fetchDistinctCarriers,
    fetchPlatformTags,
    fetchLineItemsWeight,
    updateAssignTag,
    fetchBulkSelectedOrders,
    syncOrderDatas,
    postBulkOrderValidation,
    fetchOrdersUI,
    storeDatagridData,
    updateBulkDimension,
    postdeleteOrdersData
} from './ordersSlice';
import { fetchProfile } from '../Settings/Profile/profileSlice';
import { resetGenerateLabel } from '../../pages/Shipments/NewShipment/createShipmentSlice';
import { fetchTrackingDetail } from '../Reports/Deliveries/trackingEventsSlice';
import OrderDrawer from '../General/OrderDrawer'
import ShipmentRate from '../General/ShipmentRate'
import NewOrderCreateDrawer from '../General/NewOrder/NewOrderCreateDrawer'
import LineItemsDrawer from '../General/LineItemsDrawer'
import PageSearch from '../General/PageSearch'
import CarrierTracking from '../General/CarrierTracking'
import { fetchTagData } from '../Settings/TagSettings/tagSettingsSlice';


const OrdersPage = () => {

    const dispatch = useDispatch();
    const orders = useSelector(state => state.orders);
    const bulkSelectedOrdersData = useSelector(state => state.orders && state.orders.bulkSelectedOrdersData);
    const bulkvalidationOrdersData = useSelector(state => state.orders && state.orders.bulkOrderValidation);
    const orderUIUpdate = useSelector(state => state.orders && state.orders.orderUIUpdate);
    const addresssender = useSelector(state => state.addresssender);
    const addressbilling = useSelector(state => state.addressbilling);
    const box = useSelector(state => state.box)
    const tags = useSelector(state => state.tags)
    const profile = useSelector(state => state.profile.profile);
    const newcreateshipment = useSelector(state => state.createshipment);
    const [meta, setMeta] = useState(orders.ordersData.meta);
    const [showFilter, setShowFilter] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [showDynamicColumn, setShowDynamicColumn] = useState(false);
    const [columnCheckedObject, setColumnCheckedObject] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const [actionAnchorEl, setActionAnchorEl] = useState(null);
    const [openBoxModal, setOpenBoxModal] = useState(false)
    const [openSenderAddressModal, setOpenSenderAddressModal] = useState(false)
    const [openBillingAddressModal, setOpenBillingAddressModal] = useState(false)
    const actionOpen = Boolean(actionAnchorEl);
    const actionId = actionOpen ? 'simple-popover' : undefined;
    const [viewActionAnchorEl, setViewActionAnchorEl] = useState(null);
    const viewActionOpen = Boolean(viewActionAnchorEl);
    const viewActionId = viewActionOpen ? 'view-action-simple-popover' : undefined;
    
    const [viewMoreActionAnchorEl, setViewMoreActionAnchorEl] = useState(null);
    const viewMoreActionOpen = Boolean(viewMoreActionAnchorEl);
    const viewMoreActionId = viewMoreActionOpen ? 'view-more-action-simple-popover' : undefined;
    const [currentViewItem, setCurrentViewItem] = useState("")
    const [modalVisible, setModalVisible] = useState(false);
    const [tagModalVisible, setTagModalVisible] = useState(false);
    const [bulkDimensionModalVisible, setBulkDimensionModalVisible] = useState(false);
    const [deleteOrdersModalVisible, setDeleteOrdersModalVisible] = useState(false);
    const [modalDetails, setModalDetails] = useState("default")
    const [checkedIds, setCheckedIds] = useState([]);
    const [checkedItems, setCheckedItems] = useState([]);
    const [trackingDrawerVisible, setTrackingDrawerVisible] = useState(false);
    const [trackingMessage, setTrackingMessage] = useState(null);
    const [orderDrawerVisible, setOrderDrawerVisible] = useState(false);
    const [newOrderCreateDrawerVisible, setNewOrderCreateDrawerVisible] = useState(false);
    const [newOrderFormData, setNewOrderFormData] = useState("");
    const [isDuplicateOrder, setIsDuplicateOrder] = useState(false);
    const [lineItemsDrawerVisible, setLineItemsDrawerVisible] = useState(false);
    const [lineItemsData, setLineItemsData] = useState("");
    const [duplicateActionAnchorEl, setDuplicateActionAnchorEl] = useState(null);
    const duplicateActionOpen = Boolean(duplicateActionAnchorEl);
    const duplicateActionId = duplicateActionOpen ? 'view-action-simple-popover' : undefined;
    const [returnAnchorEl, setReturnActionAnchorEl] = useState(null);
    const returnActionOpen = Boolean(returnAnchorEl);
    const returnActionId = returnActionOpen ? 'view-action-simple-popover' : undefined;
    const [choosenTrackingNumber, setChoosenTrackingNumber] = useState(0);
    const [isShipmentCreationFlow, setIsShipmentCreationFlow] = useState(false)
    const [pageNumber, setPageNumber] = useState("")
    const [orderRow, setOrderRow] = useState(null)
    const [orderId, setOrderId] = useState("")
    const [printUrl, setPrintUrl] = useState("")
    const [isPrinted, setIsPrinted] = useState(true)
    const [isMultiPiece, setIsMultiPiece] = useState(false)
    const [isReturnOrder, setIsReturnOrder] = useState(false);
    const [urlParam, seturlParam] = useState("")
    const [assignTagValue, setAssignTagValue] = useState("")
    const [assignTagIds, setAssignTagIds] = useState([])
    const [bulkDimensionIds, setBulkDimensionIds] = useState([])
    const [deleteOrderIds, setDeleteOrderIds] = useState([])
    const [notDeleteOrders, setNotDeletedOrders] = useState([])
    const [customboxDimensions, setcustomboxDimensions] = useState({})
    const [totalSelectedItems, setTotalSelectedItems] = useState("")
    const [totalOrders, setTotalOrders] = useState("")
    const [selectItemTextVisible, setSelectItemTextVisible] = useState(false)
    const [selectItemView, setSelectItemView] = useState(false)
    const [viewMoreActionsRow, setViewMoreActionsRow] = useState([])
    const [boxValues, setBoxValues] = useState([])
    const [invalidDocIds, setInvalidDocIds] = useState([])
    const [notes, setNotes] = useState("")
    const [datagridSave, setDatagridSave] = useState(true)
    const [ordersDataGridConfig, setOrdersDataGridConfig] = useState({})
    const [boxData, setBoxData] = useState([])
    const inititalColumnSettings={
        "Order" : { "label" : "Order","width" : 300,"index" : 1},
        "Shipment" : {"label" : "Shipment","width" : 300,"index" : 2},
        "Order Status" : {"label" : "Order Status","width" : 150,"index" : 3},
        "Order Date" : {"label" : "Order Date","width" : 150,"index" : 4},
        "Rate" : {"label" : "Rate","width" : 100,"index" : 5},
        "Weight" : {"label" : "Weight","width" : 100,"index" : 6},
        "Sender Name" : {"label" : "Sender Name","width" : 154,"index" : 7},
        "Sender Company" : {"label" : "Sender Company","width" : 100,"index" : 8},
        "Sender Address" : {"label" : "Sender Address","width" : 100, "index" : 9},
        "Sender City" : {"label" : "Sender City","width" : 100,"index" : 10},
        "Sender Province" : {"label" : "Sender Province","width" : 100,"index" : 11},
        "Sender Province Code" : {"label" : "Sender Province Code","width" : 100,"index" : 12},
        "Sender Postal Code" : {"label" : "Sender Postal Code","width" : 100,"index" : 13},
        "Sender Country" : {"label" : "Sender Country","width" : 100,"index" : 14},
        "Sender Country Code" : {"label" : "Sender Country Code","width" : 100,"index" : 15},
        "Receiver Name" : {"label" : "Receiver Name","width" : 100,"index" : 16},
        "Receiver Company" : {"label" : "Receiver Company","width" : 100,"index" : 17},
        "Receiver Address" : {"label" : "Receiver Address","width" : 100,"index" : 18},
        "Receiver City" : {"label" : "Receiver City","width" : 100,"index" : 19},
        "Receiver Province" : {"label" : "Receiver Province","width" : 100,"index" : 20},
        "Receiver Province Code" : {"label" : "Receiver Province Code", "width" : 100,"index" : 21},
        "Receiver Postal Code" : { "label" : "Receiver Postal Code","width" : 100,"index" : 22},
        "Receiver Country" : {"label" : "Receiver Country","width" : 100, "index" : 23},
        "Receiver Country Code" : {"label" : "Receiver Country Code", "width" : 100,"index" : 24},
        "Early Location" : { "label" : "Early Location","width" : 100,"index" : 25},
        "Goods Out Note" : {"label" : "Goods Out Note","width" : 100,"index" : 26},
        "Tags" : {"label" : "Tags", "width" : 100,"index" : 27},
        "Platform" : {"label" : "Platform","width" : 100,"index" : 28},
        "Customer" : {"label" : "Customer","width" : 100,"index" : 29},
        "Platform Tags" : {"label" : "Platform Tags","width" : 100,"index" : 30},
        "Reference No" : {"label" : "Reference No","width" : 100,"index" : 31},
        "Action" : {"label" : "Action","width" : 177,"index" : 32}
    }
    const initialBoxValues = {
        "_id":0,
        "box_name": 'custom',
        "box_length": "",
        "box_width": "",
        "box_height": "",
        "box_dimension_unit": "",
        "box_actual_weight": "",
        "box_weight_unit": "",
        "insurance_amount":"100"
    }
    const [dimensionValue, setDimensionValue] = useState(initialBoxValues);
    const [dimensionsErrors, setDimensionsErrors] = useState({});
    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: (option) => option.box_name,
      });

    const getChooseMeasurement = (currentvalue) => {
    let filteredCurrentValue = {};
    let tempValue = "";
    boxData &&
    boxData.map((key) => {
        if (key._id == currentvalue && tempValue == "") {
            filteredCurrentValue = key;
            tempValue = "success";
        }
        });
    return filteredCurrentValue;
    };

    const onBoxDimensionChange=(event)=>{
        let packageFieldData = {...dimensionValue};
        if(event.target.name==='choosenmeasurement'){
            if (event.target.value != 0) {
                box && box.data && box.data.map((boxData) => {
                    if (boxData._id === event.target.value) {
                        setDimensionValue({
                            ...dimensionValue,
                            "_id": event.target.value,
                            "box_name": boxData.box_name,
                            "box_length": parseFloat(boxData.box_length).toFixed(2),
                            "box_width": parseFloat(boxData.box_width).toFixed(2),
                            "box_height": parseFloat(boxData.box_height).toFixed(2),
                            "box_dimension_unit": boxData.box_length_unit,
                            "box_actual_weight": parseFloat(boxData.box_weight).toFixed(2),
                            "box_weight_unit": boxData.box_weight_unit,
                            "insurance_amount": "100"
                        });
                        setDimensionsErrors({})
                    }
                })
            }else{
                setDimensionValue({
                    ...dimensionValue,
                    "_id":0,
                    "box_name": 'custom',
                    "box_length": "",
                    "box_width": "",
                    "box_height": "",
                    "box_dimension_unit": "",
                    "box_actual_weight": "",
                    "box_weight_unit": "",
                    "insurance_amount": "100"
                });
                setDimensionsErrors({})
            }
        }else{
            packageFieldData[event.target.name] = event.target.value;
            setDimensionValue(packageFieldData);
            validator({ [event.target.name]: event.target.value })
        }
    }
    const validator = (fieldValues) => {
        let temp = { ...dimensionsErrors }
        if ('box_name' in fieldValues)
            temp.box_name = fieldValues.box_name ? "" : "This field is required."
        if (fieldValues && fieldValues.box_name && fieldValues.box_name.length < 3)
            temp.box_name = "Box Name required minimum 3 characters"
        if ('box_length' in fieldValues)
            temp.box_length = fieldValues.box_length ? "" : "This field is required."
        if (fieldValues && fieldValues.box_length && fieldValues.box_length <= 0)
            temp.box_length = "Length is not a negative number or 0"
        if ('box_dimension_unit' in fieldValues)
            temp.box_dimension_unit = fieldValues.box_dimension_unit ? "" : "This field is required."
        if ('box_width' in fieldValues)
            temp.box_width = fieldValues.box_width ? "" : "This field is required."
        if (fieldValues && fieldValues.box_width && fieldValues.box_width <= 0)
            temp.box_width = "Width is not a negative number  or 0"
        if ('box_height' in fieldValues)
            temp.box_height = fieldValues.box_height ? "" : "This field is required."
        if (fieldValues && fieldValues.box_height && fieldValues.box_height <= 0)
            temp.box_height = "Height is not a negative number  or 0"
        if ('box_actual_weight' in fieldValues)
            temp.box_actual_weight = fieldValues.box_actual_weight ? "" : "This field is required."
        if (fieldValues && fieldValues.box_actual_weight && fieldValues.box_actual_weight <= 0)
            temp.box_actual_weight = "Weight is not a negative number  or 0"
        if ('box_weight_unit' in fieldValues)
            temp.box_weight_unit = fieldValues.box_weight_unit ? "" : "This field is required."

        setDimensionsErrors({
            ...temp
        })

        if (fieldValues === dimensionValue)
            return Object.values(temp).every(x => x == "")
    }

    const addBulkDimensionsForm =(ids,values)=>{
        const isValid = validator(values)
        if (isValid) {
            let docid={'docids':ids}
            Object.assign(values, docid);
            dispatch(updateBulkDimension(values,meta))
            setBulkDimensionModalVisible(false);
            setDimensionValue(initialBoxValues)
            setDimensionsErrors({})
        }
    }


    const deleteOrdersValues =()=>{
        setDeleteOrdersModalVisible(false)
        dispatch(postdeleteOrdersData({"order_docids":deleteOrderIds}))
    }

    let urlParams=""
    const params = new URLSearchParams(window.location.search)
    urlParams = params.get('report')

    const onColumnResizeCustom =(params,event,details)=>{
        setDatagridSave(false)
        let ordersDataGridConfigNew = JSON.parse(JSON.stringify({...ordersDataGridConfig}));
        if(params.colDef.headerName in ordersDataGridConfigNew){
            ordersDataGridConfigNew[params.colDef.headerName]['width']=params.colDef.width;
            ordersDataGridConfigNew[params.colDef.headerName]['index']=parseInt(params.element.ariaColIndex)-1;
        }else{
            ordersDataGridConfigNew[params.colDef.headerName]={
                "label":params.colDef.headerName,
                "width":params.colDef.width,
                "index":parseInt(params.element.ariaColIndex)-1
            }
        }
        setOrdersDataGridConfig(ordersDataGridConfigNew);
    }
    const onColumnReorderingCustom =(params,event,details)=>{
        setDatagridSave(false)
        let ordersDataGridConfigNew = JSON.parse(JSON.stringify({...ordersDataGridConfig}));
        if(params.column.headerName in ordersDataGridConfigNew){
            ordersDataGridConfigNew[params.column.headerName]['width']=params.column.width;
            ordersDataGridConfigNew[params.column.headerName]['index']=parseInt(params.targetIndex);
        }else{
            ordersDataGridConfigNew[params.column.headerName]={
                "label":params.column.headerName,
                "width":params.column.width,
                "index":parseInt(params.targetIndex)
            }
        }
        if(parseInt(params.targetIndex)!==parseInt(params.oldIndex)){
            Object.keys(ordersDataGridConfigNew).map(function(key) {
                if(ordersDataGridConfigNew[key]["label"]!==params.column.headerName){
                    if(parseInt(params.targetIndex)<parseInt(params.oldIndex)){
                        if('index' in ordersDataGridConfigNew[key] && ordersDataGridConfigNew[key]["index"]>=parseInt(params.targetIndex) && ordersDataGridConfigNew[key]["index"]<=parseInt(params.oldIndex)){
                            ordersDataGridConfigNew[key]["index"]=ordersDataGridConfigNew[key]["index"]+1;
                        }
                    }else{
                        if('index' in ordersDataGridConfigNew[key] && ordersDataGridConfigNew[key]["index"]<=parseInt(params.targetIndex) && ordersDataGridConfigNew[key]["index"]>=parseInt(params.oldIndex)){
                            ordersDataGridConfigNew[key]["index"]=ordersDataGridConfigNew[key]["index"]-1;
                        }
                    }
                }
            })
        }
        setOrdersDataGridConfig(ordersDataGridConfigNew);
    }
    const onDatagridSave =()=>{
        let orderDataConfig = {};
        orderDataConfig['report_name']="orders";
        orderDataConfig['fields']=ordersDataGridConfig;
        dispatch(storeDatagridData(orderDataConfig))
    }

    const initialDimValues = {
        "length": "",
        "width": "",
        "height": "",
        "dimension_unit": "",
        "actual_weight": "",
        "weight_unit": "",
        "use_actual_weight":false,
    }
    const [defaultBoxDim, setDefaultBoxDim] = useState(initialDimValues)

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };
    const tagstyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper'
    };
    let defaultColumns = [{
        field: 'order.id',
        headerName: 'Order',
        headerClassName: 'super-app-theme--header',
        width: 180,
        sortable: true,
        renderCell: (params) => {
            if(params && params.row && params.row.order && params.row.order.id){
                return <span style={{ color: 'rgb(24, 144, 255)', cursor: 'pointer' }} onClick={e => { orderCallback(params.row.order.id, params.row) }}>{params.row.order.id}</span>
            }else{
                return <span style={{ color: 'rgb(24, 144, 255)', cursor: 'pointer' }}>-</span>
            }
        }
    },
    {
        field: 'line_items.tracking_number',
        headerName: 'Shipment',
        width: 300,
        sortable: false,
        renderCell: (params) => {
            let row = params.row

            if(params.row && params.row.shipment && params.row.shipment.master){
                let tracking_number = (params.row.shipment.master.tracking_number
                    && typeof params.row.shipment.master.tracking_number !== "undefined" && params.row.shipment.master.tracking_number !== null)
                    ? params.row.shipment.master.tracking_number : "";
                let carrier_name = (params.row.shipment.master.carrier_name
                    && typeof params.row.shipment.master.carrier_name !== "undefined" && params.row.shipment.master.carrier_name !== null)
                    ? params.row.shipment.master.carrier_name : "";
                let message = (params.row && params.row.is_manifest_closed === false)
                    ? "This shipment manifest is not yet closed" : null;
                let tracking = { "tracking_number": tracking_number, "carrier_name": carrier_name }
                return (
                    <CarrierTracking trackingItems={tracking} dispatchCallback={trackingCallback} carrierImageWidth="60px"  message={message} is_multi_piece={(params.row.shipment.master) ? true :false}/>
                )
            }else{
                return (
                    <MultipleTracking trackingItems={params.row.line_items}  dispatchCallback={trackingCallback} carrierImageWidth="60px" moreCallBack={() => lineItemsDrawerOpen(row)} orderData={params.row} is_multi_piece={(params.row.shipment && typeof params.row.shipment.master!== "undefined") ? true :false}/>
                )
            }
        }
    },
    {
        field: 'action',
        headerName: 'Action',
        width: 350,
        sortable: false,
        headerAlign: 'left',
        align: 'center',
        renderCell: (params) => {
            let row = params.row
            return <div>
                <span style={{ marginRight: '5px' }}>
                    <Tooltip title="Create Shipment" placement="top">
                        <Button
                            variant="outlined"
                            disabled={createShipmentButton(row)}
                            onClick={() => newOrderCreateCallback(row)}
                            style={{
                                color: createShipmentButton(row) ? 'rgba(0,0,0,.54)' : '#1890ff',
                                cursor: createShipmentButton(row) ? 'not-allowed' : 'pointer',
                                pointerEvents: createShipmentButton(row) ? 'none' : 'auto'
                            }}
                        >
                            <AddCircleOutlineRoundedIcon /> <span style={{ marginLeft: "5px" }}>Label</span>
                        </Button>
                    </Tooltip>
                </span>
                <span style={{marginLeft:'10px'}}>
                    <Tooltip title="More Actions" placement="top">
                        <IconButton onClick={(event) =>handleViewMoreActionClick(event, row)}>
                            <MoreHorizRoundedIcon />
                        </IconButton>
                    </Tooltip>
                </span>
            </div >
        }
    }
]
    const [columns, setColumns] = useState(defaultColumns);

    useEffect(() => {
        if (showFilter === true)
            dispatch(fetchDistinctCarriers())
        dispatch(fetchPlatformTags())
    }, [dispatch, showFilter])

    useEffect(() => {
        dispatch(fetchOrdersUI())
        dispatch(fetchOrderColumns())
        dispatch(fetchLineItemsWeight())
        dispatch(fetchProfile())
        dispatch(fetchTagData())
        dispatch(resetOrderNotifications())
    }, [dispatch])

    useEffect(() => {
        if(urlParams=="openorders"){
            seturlParam(urlParams)
        }else{
            seturlParam("")
            if (_.has(meta, 'columns'))
                dispatch(fetchOrders({ ...meta }));
        }
    }, [urlParams,dispatch])

    useEffect(() => {
        if (_.has(meta, 'columns'))
            dispatch(fetchOrders({ ...meta }));
            setSelectItemTextVisible(false)
            setSelectItemView(false);
    }, [dispatch, meta])

    useEffect(() => {
        if (orders.bulkLabel && orders.bulkLabel.filename && orders.bulkLabel.filename !== "" && orders.bulkLabel.is_shown === false) {
            setPrintUrl(process.env.REACT_APP_LABELINGCHIMP_LABEL_API_DOMAIN + "/merge-pdf?filename=" + orders.bulkLabel.filename)
            setIsPrinted(false)
        }
    }, [orders.bulkLabel])

    useEffect(() => {
        let boxdimensionTemp={}
        if (box && box.data && box.data.length !== 0) {
            box.data.map((item) => {
                if (item.box_default === true) {
                    setDefaultBoxDim({
                        "length": parseFloat(item.box_length).toFixed(2),
                        "width": parseFloat(item.box_width).toFixed(2),
                        "height": parseFloat(item.box_height).toFixed(2),
                        "dimension_unit": item.box_length_unit,
                        "actual_weight": parseFloat(item.box_weight).toFixed(2),
                        "weight_unit": item.box_weight_unit,
                        "choosenmeasurement": item._id,
                        "insurance_amount": "100",
                        "use_actual_weight":item.use_actual_weight
                    })
                }
                if(!(item.box_name in boxdimensionTemp)){
                    boxdimensionTemp[item.box_name.toUpperCase()]=item
                }
            })
            setcustomboxDimensions(boxdimensionTemp)
            let boxData = box && box.data.filter(function( obj ) {
                return  obj.country;
             });
             setBoxValues(boxData)
        }

        let tempBoxData=[]
        let customValue={"_id":0,"box_name":"custom"}
        tempBoxData.push(customValue)
        let boxOriginalData = box && box.data.filter(function( obj ) {
            return  obj.hide !== true;
            });
        if (boxOriginalData.length !== 0) {
            boxOriginalData.map((item) => {
                tempBoxData.push(item)
            })
            setBoxData(tempBoxData)
        }else{
            setBoxData(tempBoxData)
        }
    }, [box && box.data]);

    useEffect(() => {
        setDatagridSave(true)
        if (orderUIUpdate.loading===false && orderUIUpdate && orderUIUpdate.data && orderUIUpdate.data.fields) {
           setOrdersDataGridConfig(orderUIUpdate.data.fields)
        }
        else{
            if(orderUIUpdate.loading===false && orderUIUpdate && orderUIUpdate.data && typeof orderUIUpdate.data.fields==='undefined'){
                setOrdersDataGridConfig(inititalColumnSettings)
            }
        }
    }, [orderUIUpdate])

    useEffect(() => {
       
        if (orders.ordersColumns.columns && typeof orders.ordersColumns.columns.user_columns !== "undefined" && orderUIUpdate.loading===false && orderUIUpdate && orderUIUpdate.data) {
            if (orders.ordersColumns.columns.user_columns.length !== 0) {
                setMeta(prevState => ({
                    ...prevState,
                    [`columns`]: JSON.stringify(orders.ordersColumns.columns.user_columns)
                }));
                Object.entries(orders.ordersColumns.columns.user_columns).map(([key, value]) => (
                    defaultColumns.splice(defaultColumns.length - 1, 0, {
                        field: value,
                        width: 100,
                        sortable: true,
                        headerName: key,
                        headerClassName: 'super-app-theme--header',
                        renderCell: (params) => {
                            if (key === "Platform Tags") {
                                let tagsArray = getValueFromObject("row." + value, params);
                                if (Array.isArray(tagsArray) && tagsArray.length > 0) {
                                    const joinArray = tagsArray.join('\n')
                                    let formatedTag = tagsArray.map((tag) => {
                                        return (
                                            <Tooltip title={<div style={{ whiteSpace: 'normal' }}>{joinArray}</div>}>
                                                <Chip
                                                    size="small"
                                                    label={tag}
                                                    style={{ fontSize: "9px" }}
                                                />
                                            </Tooltip>
                                        );
                                    })
                                    return formatedTag
                                } else if (tagsArray && !Array.isArray(tagsArray) && tagsArray !== "") {
                                    return (
                                        <Tooltip title={tagsArray}>
                                            <Chip
                                                size="small"
                                                label={tagsArray}
                                                style={{ fontSize: "9px" }}
                                            />
                                        </Tooltip>
                                    );
                                } else {
                                    return ""
                                }
                            }else if (key === "Order Date") {
                                return <Date date={params.row.order.date} />
                            }else if (key === "Order Status") {
                                return  <OrderStatus orderStatus={params.row.order.status} />
                            }else if (key === "Rate") {
                                let is_multi_piece_order = (params.row && params.row.shipment && params.row.shipment.multi_piece_shipment && params.row.shipment.multi_piece_shipment === true) ? true : false
                                let rate = (params.row && params.row.shipment && params.row.shipment.tracking && Object.keys(params.row.shipment.tracking).length > 0) ? getOrderShipmentRate(params.row.shipment.tracking, is_multi_piece_order) : "-"
                                return rate
                            }else if (key === "Weight") {
                                let lineItemActualWeight=0;
                                let weight_unit=params.row && params.row.line_items && params.row.line_items && params.row.line_items.length>0 && params.row.line_items[0].weight_unit ? params.row.line_items[0].weight_unit :"";
                                if(params.row.shipment && params.row.shipment.tracking && Object.keys(params.row.shipment.tracking).length != 0){
                                    for (let key in params.row.shipment.tracking) {
                                        if (params.row.shipment.tracking[key] && params.row.shipment.tracking[key].product_info ) {
                                            lineItemActualWeight = (params.row.shipment.tracking[key].product_info.actual_weight && params.row.shipment.tracking[key].product_info.actual_weight!=='') ? parseFloat(params.row.shipment.tracking[key].product_info.actual_weight)+parseFloat(lineItemActualWeight) : parseFloat(lineItemActualWeight);
                                        }
                                    }
                                }
                                else{
                                    params.row.line_items && params.row.line_items.map((item) => {
                                        lineItemActualWeight = (item && item.actual_weight && item.actual_weight!="") ? parseFloat(lineItemActualWeight)+parseFloat(item.actual_weight) : parseFloat(lineItemActualWeight);
                                    })
                                }
                                return  <span>{(lineItemActualWeight!==0) ? parseFloat(lineItemActualWeight).toFixed(2)  +" "+weight_unit: '-'}</span>
                            }else {
                                return getValueFromObject("row." + value, params)
                            }
                        }
                    })
                ))
            } else {
                setMeta(prevState => ({
                    ...prevState,
                    [`columns`]: "",
                }));
            }
            if(orderUIUpdate && orderUIUpdate.data && orderUIUpdate.data.fields && Object.keys(orderUIUpdate.data.fields).length>0){
                for (let [key, value] of Object.entries(orderUIUpdate.data.fields)) {
                    let output='';
                    let index='';
                    let field = defaultColumns.filter(function(x) {
                        return x.headerName === key;
                    })[0];
                    index = defaultColumns.indexOf(field);
                    output = defaultColumns.filter(({ headerName }) =>[key].includes(headerName));
                    if(output.length===1){
                        var tmp = defaultColumns.splice(index, 1);
                        tmp[0].width=value.width
                        defaultColumns.splice(value.index-1, 0, tmp[0]);
                    }
                }
            }
        }
        setColumns(defaultColumns)
    }, [orders.ordersColumns && orders.ordersColumns.columns, orders.bulkLabel, orderUIUpdate])

    useEffect(() => {
        if (orders && orders.ordersData && orders.ordersData.data && orders.ordersData.data.length === 0) {
            setCheckedIds([]);
            setCheckedItems([]);
            setDimensionValue(initialBoxValues)
            setDimensionsErrors({})
            setNotes("")
        }else{
            setSelectItemTextVisible(false)
            setSelectItemView(false);
            handleViewMoreActionClose();
        }
    }, [orders && orders.ordersData && orders.ordersData.data])

    useEffect(() => {
        if (orders && orders.deleteOrders && orders.deleteOrders.data && orders.deleteOrders.data.non_voided_tracking_numbers && orders.deleteOrders.data.manifest_closed_tracking_numbers && orders.deleteOrders.data.billed_tracking_numbers || (orders.deleteOrders.data.non_voided_tracking_numbers >0 || 
            orders.deleteOrders.data.manifest_closed_tracking_numbers >0 || orders.deleteOrders.data.billed_tracking_numbers >0 || orders.deleteOrders.notification.messageContent==='Orders successfully deleted')
        ) {
            let notdeletedTrackingnumbers=[];
            if(orders.deleteOrders.data.manifest_closed_tracking_numbers.length>0){
                orders.deleteOrders.data.manifest_closed_tracking_numbers.map((item) => {
                    notdeletedTrackingnumbers.push({
                        "tracking_number":item,
                        "reason":"Manifest closed tracking number"
                    })
                })
            }
            if(orders.deleteOrders.data.billed_tracking_numbers.length>0){
                orders.deleteOrders.data.billed_tracking_numbers.map((item) => {
                    notdeletedTrackingnumbers.push({
                        "tracking_number":item,
                        "reason":"Billed tracking number"
                    })
                })
            }
            if(orders.deleteOrders.data.non_voided_tracking_numbers.length>0){
                orders.deleteOrders.data.non_voided_tracking_numbers.map((item) => {
                    notdeletedTrackingnumbers.push({
                        "tracking_number":item,
                        "reason":"Non voided tracking number"
                    })
                })
            }
            if(notdeletedTrackingnumbers.length>0){
                setDeleteOrdersModalVisible(true)
            }
           setNotDeletedOrders(notdeletedTrackingnumbers)
        }
    }, [orders && orders.deleteOrders && orders.deleteOrders.data])

    const openLabelButtonView = (item) => {
        let labelsNotExists = true
        if (item &&
            item.shipment &&
            item.shipment.tracking &&
            item.shipment.tracking.length != 0) {
            for (var key of Object.keys(item.shipment.tracking)) {
                let dataTemp = typeof item.shipment.tracking[key] == "object" ? JSON.parse(JSON.stringify(item.shipment.tracking[key])) : {}
                if (item.shipment.tracking[key] &&
                    item.shipment.tracking[key].label &&
                    typeof item.shipment.tracking[key].label.is_voided == "undefined" &&
                    (   (item.shipment.tracking[key].label.url_detachable && item.shipment.tracking[key].label.url_detachable != "") ||
                        (item.shipment.tracking[key].label.url_direct_detachable && item.shipment.tracking[key].label.url_direct_detachable != "") ||
                        (item.shipment.tracking[key].label.url && item.shipment.tracking[key].label.url != "") ||
                        (item.shipment.tracking[key].label.url_direct && item.shipment.tracking[key].label.url_direct != "")
                    )
                ) {
                    labelsNotExists = false
                }
            }
        }
        return labelsNotExists;

    }
    const returnlabelButton = (item) => {
        let isreturnlabelButtonDisable = false;
        if (item &&
            item.shipment &&
            item.shipment.tracking &&
            item.shipment.tracking.length != 0) {
            for (var key of Object.keys(item.shipment.tracking)) {
                if ((item.shipment.tracking[key] &&
                    item.shipment.tracking[key].label &&
                    item.shipment.tracking[key].label.is_return &&
                    item.shipment.tracking[key].label.is_return === true)||
                    (item.shipment.tracking[key].label.is_voided &&
                        item.shipment.tracking[key].label.is_voided === true)) {
                            isreturnlabelButtonDisable = true;
                }else{
                    isreturnlabelButtonDisable = false;
                    break
                }
            }
           
        }else{
            isreturnlabelButtonDisable = true;
        }
        if((item &&
            item.shipment &&
            item.shipment.tracking &&
            item.shipment.tracking.length == 0) || (item && item.line_items && 
            item.line_items[0] && item.line_items[0].tracking_number)){
                isreturnlabelButtonDisable = false;
        }
        return isreturnlabelButtonDisable;
    }

    const openLabelClick = (item) => {
        let labels = []
        if (item &&
            item.shipment &&
            item.shipment.tracking &&
            item.shipment.tracking.length != 0) {
            for (var key of Object.keys(item.shipment.tracking)) {
                let dataTemp = typeof item.shipment.tracking[key] == "object" ? JSON.parse(JSON.stringify(item.shipment.tracking[key])) : {}
                if (item.shipment.tracking[key] &&
                    item.shipment.tracking[key].label &&
                    item.shipment.tracking[key].label.url_detachable &&
                    item.shipment.tracking[key].label.url_detachable != "") {
                    if (!labels.includes(item.shipment.tracking[key].label.url_detachable)) {
                        labels.push(item.shipment.tracking[key].label.url_detachable)
                    }
                } else if (item.shipment.tracking[key] &&
                    item.shipment.tracking[key].label &&
                    item.shipment.tracking[key].label.url_direct_detachable &&
                    item.shipment.tracking[key].label.url_direct_detachable != "") {
                    if (!labels.includes(item.shipment.tracking[key].label.url_direct_detachable)) {
                        labels.push(item.shipment.tracking[key].label.url_direct_detachable)
                    }
                } else if (item.shipment.tracking[key] &&
                    item.shipment.tracking[key].label &&
                    item.shipment.tracking[key].label.url &&
                    item.shipment.tracking[key].label.url != "") {
                    if (!labels.includes(item.shipment.tracking[key].label.url)) {
                        labels.push(item.shipment.tracking[key].label.url)
                    }
                } else if (item.shipment.tracking[key] &&
                    item.shipment.tracking[key].label &&
                    item.shipment.tracking[key].label.url_direct &&
                    item.shipment.tracking[key].label.url_direct != "") {
                    if (!labels.includes(item.shipment.tracking[key].label.url_direct)) {
                        labels.push(item.shipment.tracking[key].label.url_direct)
                    }
                }
            }
        }
        
        if (labels.length == 1) {
            console.log(labels[0])
            setPrintUrl(labels[0])
            setIsPrinted(false)
        } else if (labels.length > 1) {
            dispatch(printBulkLabels(labels, profile.id))
        }
    }

    const orderCallback = (item, order = null, invalidids = null) => {
        dispatch(fetchOrderDetail(item));
        if(order==null || order==''){
            order = orders && orders.ordersData && orders.ordersData.data.filter(row => row.order.id==item);
        }
        setOrderRow(order)
        setOrderId(item)
        setInvalidDocIds(invalidids)
        setOrderDrawerVisible(true)
    }

    const handleViewActionClick = (event) => {
        setViewActionAnchorEl(event.currentTarget);
    }

    const handleViewMoreActionClick = (event,row) => {
        setViewMoreActionsRow(row)
        setViewMoreActionAnchorEl(event.currentTarget);
    }

    const handleViewActionClose = () => {
        setViewActionAnchorEl(null);
    }

    const handleViewMoreActionClose = () => {
        setViewMoreActionsRow([]);
        setViewMoreActionAnchorEl(null);
    }

    const handleDuplicateActionClick = (event, row) => {
        setDuplicateActionAnchorEl(event.currentTarget);
    }

    const handleDuplicateActionClose = () => {
        setDuplicateActionAnchorEl(null);
    }

    const handleReturnActionClick = (event, row) => {
        setReturnActionAnchorEl(event.currentTarget);
    }

    const handleReturnActionClose = () => {
        setReturnActionAnchorEl(null);
    }

    const orderOnClose = () => {
        setOrderDrawerVisible(false);
    }

    const lineItemsDrawerClose = () => {
        setLineItemsDrawerVisible(false);
    }

    const lineItemsDrawerOpen = (item) => {
        setLineItemsData(item);
        setLineItemsDrawerVisible(true);
    }

    const newOrderCreateCallback = (item, is_duplicate_order = false) => {
        if (item != "" && is_duplicate_order == false) {
            setIsShipmentCreationFlow(true)
        } else {
            setIsShipmentCreationFlow(false)
        }
        setNewOrderFormData(item);
        setNewOrderCreateDrawerVisible(true)
        setIsDuplicateOrder(is_duplicate_order)
        setDuplicateActionAnchorEl(null);
    }
    const returnOrderCreateCallback = (item, is_return,tracking_number) => {
        setNewOrderFormData(item);
        setChoosenTrackingNumber(tracking_number)
        setNewOrderCreateDrawerVisible(true)
        setIsReturnOrder(is_return)
        setReturnActionAnchorEl(null);
    }

    const newOrderCreateOnClose = () => {
        setNewOrderCreateDrawerVisible(false);
    }

    const trackingCallback = (carriername, trackingNumber, message = null,is_multi_piece) => {
        let apikey = (profile && profile.api_token) ? profile.api_token : "";
        setTrackingMessage(message)
        const data = { "carrier_name": carriername, 'tracking_number': trackingNumber }
        dispatch(fetchTrackingDetail(data, apikey))
        setTrackingDrawerVisible(true)
        setIsMultiPiece(is_multi_piece)

    }
    const updateAssignTagHandle = (ids,value) => {
        let data={}
        data={
            'docids':ids,
            'tag':value
        }
        dispatch(updateAssignTag(data,meta))
        setTagModalVisible(false);
        setAssignTagValue("");
        setAssignTagIds("");
    }
    
    const onClose = () => {
        setTrackingDrawerVisible(false);
    };

    const onChangePerPage = (event) => {
        setPageNumber('')
        setMeta({
            ...meta,
            ...{
                page: 1,
                per_page: event.target.value
            }
        });
    }

    const onChangePagination = (event, page) => {
        setPageNumber("")
        setMeta({
            ...meta,
            ...{
                page: page
            }
        });
    }
    const onInputChangePagination = (page) => {
        setPageNumber(page)
        let pagenumber = ''
        if (page == '') {
            pagenumber = 1
        } else {
            pagenumber = page
        }
        setMeta({
            ...meta,
            ...{
                page: pagenumber
            }
        });
    }

    const updateDynamicColumns = (clear = false) => {
        let data = columnCheckedObject
        if (clear === true) {
            data = {}
            setColumnCheckedObject({})
        }
        dispatch(saveOrdersColumns(data))
    }

    const onMenuClick = (type, show) => {
        setShowSearch((type === "search" && show === true) ? true : false)
        setShowFilter((type === "filter" && show === true) ? true : false)
    }

    const onExportClick = () => {
        const qs = Object.keys(meta)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(meta[key])}`)
            .join('&');
        let url = process.env.REACT_APP_API_DOMAIN + '/labelgen/orders/export?' + qs;
        window.open(url,"_blank")
    }

    const handleColumnsClick = (event) => {
        setAnchorEl(event.currentTarget);
        setShowDynamicColumn(!showDynamicColumn)
    };

    const handleActionsClick = (event) => {
        setActionAnchorEl(event.currentTarget);
    };

    const handleColumnsClose = () => {
        setAnchorEl(null);
        setShowDynamicColumn(!showDynamicColumn)
    };

    const checkBoxCompute = (tempCheckedIds, tempCheckedItems, item, currentSelectedIds) => {
        const index = tempCheckedIds.indexOf(item._id);
        const method = (currentSelectedIds.indexOf(item._id) > -1)
        if (method) {
            if (index === -1) {
                tempCheckedIds.push(item._id);
                tempCheckedItems.push(item)
            }
        } else if (!method) {
            if (index > -1) {
                tempCheckedIds.splice(index, 1);
                tempCheckedItems.splice(index, 1);
            }
        }
        return [tempCheckedIds, tempCheckedItems];
    }

    const onSortChange = (currentSorting) => {
        setMeta({
            ...meta,
            ...{
                sorting: JSON.stringify(currentSorting)
            }
        });
    }

    const onSelectionChange = (currentSelectedIds, currentAllItems) => {
        let currentSelectedIdsLength= currentSelectedIds.length;
        let currentAllItemsLength= currentAllItems.length;
        let tempCheckedIds = [...checkedIds]
        let tempCheckedItems = [...checkedItems];
        currentAllItems.map((item) => (
            checkBoxCompute(tempCheckedIds, tempCheckedItems, item, currentSelectedIds)
        ))
        setCheckedIds(tempCheckedIds)
        setCheckedItems(tempCheckedItems)
        if(currentSelectedIdsLength===currentAllItemsLength){
            setTotalOrders(orders && orders.ordersData && orders.ordersData.meta && orders.ordersData.meta.total)
            setTotalSelectedItems(tempCheckedIds.length)
            setSelectItemTextVisible(true)
        }else{
            setSelectItemTextVisible(false)
            setSelectItemView(false)
        }
    }

    const handleBulkSelectedOrders = () => {
       let bulkOrderMeta=meta
       let bulkOrderMetaData = {...bulkOrderMeta, per_page: 2500};
       let bulkOrderMetaData1 = {...bulkOrderMetaData, page: 1};
        dispatch(fetchBulkSelectedOrders(bulkOrderMetaData1))
        setSelectItemView(true)
    }
    
    const handleClearOrders = () => {
        setCheckedIds([])
        setCheckedItems([])
        setSelectItemView(false)
        setSelectItemTextVisible(false)
    }
    

    const handleActionClose = () => {
        setActionAnchorEl(null);
    }
    const errorColumns = [
        { 
            field: 'orderid',
            headerName: 'Order Id',
            flex:1,
            renderCell: (params) => {
                if(params && params.row && params.row.orderid && params.row.type && params.row.type==='Miscellaneous'){
                    return <span>{params.row.orderid}</span>
                }else if(params && params.row && params.row.orderid){
                    let orderdatavalue="";
                    return <span style={{ color: 'rgb(24, 144, 255)', cursor: 'pointer' }} onClick={e => { 
                        orderCallback(params.row.orderid,orderdatavalue,bulkvalidationOrdersData.data)
                     }}>{params.row.orderid}</span>
                }else{
                    return <span style={{ color: 'rgb(24, 144, 255)', cursor: 'pointer' }}>-</span>
                }
        }},
        { 
            field: 'reason',
            headerName: 'Failure Reason',
             flex:1,
        }
    ]
    const notDeletedColumns = [
        { 
            field: 'tracking_number',
            headerName: 'Tracking Number',
            flex:1
        },
        { 
            field: 'reason',
            headerName: 'Failure Reason',
             flex:1,
        }
    ]
    const errorTable=(errorvalues) =>{
        return (
            <>
                <DataGrid style={{marginTop:'20px'}}
                getRowId={(row) => row.docIds}
                rows={errorvalues}
                columns={errorColumns}
                initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                  }}
                  autoHeight={true}
                  pageSizeOptions={[5]}
                  disableColumnFilter={true}
                  disableColumnMenu={true}
                  disableRowSelectionOnClick={true}
                />
                </>
            )
    }
    const notDeleteOrdersTable=(values) =>{
        return (
            <>
                <DataGrid style={{marginTop:'20px'}}
                getRowId={(row) => row.tracking_number}
                rows={values}
                columns={notDeletedColumns}
                initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                  }}
                  autoHeight={true}
                  pageSizeOptions={[5]}
                  disableColumnFilter={true}
                  disableColumnMenu={true}
                  disableRowSelectionOnClick={true}
                />
                </>
            )
    }
    const createShipmentButton = (item) => {
        let createShipmentButtonDisable = true;
        if(item && (typeof item.line_items =='undefined' || item.line_items.length==0) && (profile && profile.disable_create_shipment ===false || typeof profile.disable_create_shipment == "undefined")){
            createShipmentButtonDisable = false;
        }else if (item && item.line_items && item.line_items.length !== 0 && (profile && profile.disable_create_shipment ===false || typeof profile.disable_create_shipment == "undefined")) {
            item.line_items.forEach(function (key) {
                if (!key.tracking_number || key.tracking_number === "") {
                    createShipmentButtonDisable = false;
                }
            });
        }
        return createShipmentButtonDisable;
    }

    const createShipmentBulkData = () => {

        if (addresssender && addresssender.data && addresssender.data.length == 0) {
            setModalVisible(false)
            setOpenSenderAddressModal(true)
            return false;
        } else if (addresssender && addresssender.data && addresssender.data.length != 0) {
            let defaultSenderAddressFound = false
            addresssender.data.map((addressData) => {
                if (addressData && addressData.default) {
                    defaultSenderAddressFound = true
                }
            })
            if (defaultSenderAddressFound == false) {
                setModalVisible(false)
                setOpenSenderAddressModal(true)
                return false;
            }
        }

        if (addressbilling && addressbilling.data && addressbilling.data.length == 0) {
            setModalVisible(false)
            setOpenBillingAddressModal(true)
            return false;
        }

        let datas = '';
        if(selectItemView===true){
            datas = bulkSelectedOrdersData && bulkSelectedOrdersData.data
        }else{
            datas = checkedItems
        }
        let bulkShipmentRequestData = []
        datas.forEach((item) => {
            let row = typeof item == "object" ? JSON.parse(JSON.stringify(item)) : {}
            if (bulkvalidationOrdersData && bulkvalidationOrdersData.data && Object.keys(bulkvalidationOrdersData.data).length!==0) {
                for (let key = 0; key < bulkvalidationOrdersData.data.valid_order_docids.length; key++) {
                    if (row._id===bulkvalidationOrdersData.data.valid_order_docids[key]){
                        bulkShipmentRequestData.push(bulkvalidationOrdersData.data.valid_order_docids[key])
                    }
                }
            }// Orders with tracking numbers will be skipped
        })
        setModalVisible(false)
        if (bulkShipmentRequestData.length !== 0) {
                let finalBulkOrderData={
                    "order_docids":bulkShipmentRequestData,
                    "bulk_reference_id": "",
                    "notes":notes
                }
                setActionAnchorEl(null);
                dispatch(dopostDataBulkShipment(finalBulkOrderData, profile.api_token, { ...meta }))
                onSelectionChange([], orders.ordersData.data)
        } else {
            setModalDetails("novalidcreateshipment")
            setModalVisible(true)
            return false
        }
    }

    const manifestCloseBulkData = () => {
        let trackingNumbers = []
        if (bulkvalidationOrdersData && bulkvalidationOrdersData.data && Object.keys(bulkvalidationOrdersData.data).length!==0) {
            for (let key = 0; key < bulkvalidationOrdersData.data.valid_tracking_nos.length; key++) {
                if (!trackingNumbers.includes(bulkvalidationOrdersData.data.valid_tracking_nos[key])) {
                    trackingNumbers.push(bulkvalidationOrdersData.data.valid_tracking_nos[key])
                }
            }
        }
        setModalVisible(false)
        if (trackingNumbers.length !== 0) {
            var postData = {
                "tracking_numbers": trackingNumbers,
                "ignore_errors": true,
                "request_medium":"UI"

            }
            dispatch(dopostTrackingNumberCloseManifest(postData, profile.api_token, { ...meta }))
            setActionAnchorEl(null);
            onSelectionChange([], orders.ordersData.data)
        }
    }

    const voidShipmentBulkData = () => {
        let trackingNumbers = []
        let doc_ids = []
        if (bulkvalidationOrdersData && bulkvalidationOrdersData.data && Object.keys(bulkvalidationOrdersData.data).length!==0) {
            for (let key = 0; key < bulkvalidationOrdersData.data.valid_tracking_nos.length; key++) {
                if (!trackingNumbers.includes(bulkvalidationOrdersData.data.valid_tracking_nos[key])) {
                    trackingNumbers.push(bulkvalidationOrdersData.data.valid_tracking_nos[key])
                }
            }
        }
        setModalVisible(false)
        if (trackingNumbers.length !== 0) {
            var postData = {
                "tracking_numbers": trackingNumbers,
                "ignore_errors": true,
                "request_medium":"UI"
            }
            var updateOrderData = { "doc_ids": doc_ids, "update_type": "void" }
            dispatch(doPostTrackingNumberVoid(postData, profile.api_token, updateOrderData, { ...meta }))
            setActionAnchorEl(null);
            onSelectionChange([], orders.ordersData.data)
        }
    }

    const printLabelBulk = () => {
        let urls = []
        checkedItems.forEach((item) => {
            let row = typeof item == "object" ? JSON.parse(JSON.stringify(item)) : {}
            if (row && row.line_items && row.line_items.length !== 0) {
                row.line_items.forEach((line) => {
                    if (
                        typeof line.tracking_number !== "undefined" &&
                        typeof line.is_generated_by_sc !== "undefined" &&
                        line.tracking_number !== "" &&
                        line.is_generated_by_sc == true &&
                        row.shipment &&
                        row.shipment.tracking &&
                        typeof row.shipment.tracking[line.tracking_number] !== "undefined" &&
                        typeof row.shipment.tracking[line.tracking_number].label !== "undefined" &&
                        typeof row.shipment.tracking[line.tracking_number].label.url_detachable !== "undefined" &&
                        typeof row.shipment.tracking[line.tracking_number].label.is_void_in_progress === "undefined"
                    ) {
                        if (!urls.includes(row.shipment.tracking[line.tracking_number].label.url_detachable)) {
                            urls.push(row.shipment.tracking[line.tracking_number].label.url_detachable)
                        }
                    } else if (
                        typeof line.tracking_number !== "undefined" &&
                        typeof line.is_generated_by_sc !== "undefined" &&
                        line.tracking_number !== "" &&
                        line.is_generated_by_sc == true &&
                        row.shipment &&
                        row.shipment.tracking &&
                        typeof row.shipment.tracking[line.tracking_number] !== "undefined" &&
                        typeof row.shipment.tracking[line.tracking_number].label !== "undefined" &&
                        typeof row.shipment.tracking[line.tracking_number].label.url_direct_detachable !== "undefined" &&
                        typeof row.shipment.tracking[line.tracking_number].label.is_void_in_progress === "undefined"
                    ) {
                        if (!urls.includes(row.shipment.tracking[line.tracking_number].label.url_direct_detachable)) {
                            urls.push(row.shipment.tracking[line.tracking_number].label.url_direct_detachable)
                        }
                    } else if (
                        typeof line.tracking_number !== "undefined" &&
                        typeof line.is_generated_by_sc !== "undefined" &&
                        line.tracking_number !== "" &&
                        line.is_generated_by_sc == true &&
                        row.shipment &&
                        row.shipment.tracking &&
                        typeof row.shipment.tracking[line.tracking_number] !== "undefined" &&
                        typeof row.shipment.tracking[line.tracking_number].label !== "undefined" &&
                        typeof row.shipment.tracking[line.tracking_number].label.url !== "undefined" &&
                        typeof row.shipment.tracking[line.tracking_number].label.is_void_in_progress === "undefined"
                    ) {
                        if (!urls.includes(row.shipment.tracking[line.tracking_number].label.url)) {
                            urls.push(row.shipment.tracking[line.tracking_number].label.url)
                        }
                    } else if (
                        typeof line.tracking_number !== "undefined" &&
                        typeof line.is_generated_by_sc !== "undefined" &&
                        line.tracking_number !== "" &&
                        line.is_generated_by_sc == true &&
                        row.shipment &&
                        row.shipment.tracking &&
                        typeof row.shipment.tracking[line.tracking_number] !== "undefined" &&
                        typeof row.shipment.tracking[line.tracking_number].label !== "undefined" &&
                        typeof row.shipment.tracking[line.tracking_number].label.url_direct !== "undefined" &&
                        typeof row.shipment.tracking[line.tracking_number].label.is_void_in_progress === "undefined"
                    ) {
                        if (!urls.includes(row.shipment.tracking[line.tracking_number].label.url_direct)) {
                            urls.push(row.shipment.tracking[line.tracking_number].label.url_direct)
                        }
                    }
                })
            }
        })

        if (urls.length !== 0) {
            dispatch(printBulkLabels(urls, profile.id))
        } else {
            setModalDetails("novalidlabel")
            setModalVisible(true)
            return false
        }
    }
    const printPackageSlip = () => {
        let ids = []
        checkedItems.forEach((item) => {
            let row = typeof item == "object" ? JSON.parse(JSON.stringify(item)) : {}
            if (row && row.line_items && row.line_items.length !== 0) {
                ids.push(item._id)
            }
        })
        if (ids.length !== 0) {
            if(profile && profile.label_size_packaging_slip && profile.label_size_packaging_slip==true){
                setPrintUrl(process.env.REACT_APP_INTEGRATIONS_API_DOMAIN + "/package-customslip-order?doc_id=" + ids.toString())
            }else{
                setPrintUrl(process.env.REACT_APP_INTEGRATIONS_API_DOMAIN + "/package-slip-order?doc_id=" + ids.toString())
            }
            setIsPrinted(false)
        }
    }

    const assignTag = () => {
        let ids = []
        let datas = '';
        if(selectItemView===true){
            datas = bulkSelectedOrdersData && bulkSelectedOrdersData.data
        }else{
            datas = checkedItems
        }
        datas.forEach((item) => {
            let row = typeof item == "object" ? JSON.parse(JSON.stringify(item)) : {}
            if (row && row.line_items && row.line_items.length !== 0) {
                ids.push(item._id)
            }
        })
        setAssignTagIds(ids)
        setTagModalVisible(true)
           
    }

    const addbulkdimension = () => {
        let ids = []
        let datas = '';
        if(selectItemView===true){
            datas = bulkSelectedOrdersData && bulkSelectedOrdersData.data
        }else{
            datas = checkedItems
        }
        datas.forEach((item) => {
            let row = typeof item == "object" ? JSON.parse(JSON.stringify(item)) : {}
            if (row && row.line_items && row.line_items.length !== 0) {
                ids.push(item._id)
            }
        })
        setBulkDimensionIds(ids)
        setBulkDimensionModalVisible(true)
    }
    const deleteordersData = () => {
        let ids = []
        let datas = '';
        if(selectItemView===true){
            datas = bulkSelectedOrdersData && bulkSelectedOrdersData.data
        }else{
            datas = checkedItems
        }
        datas.forEach((item) => {
            let row = typeof item == "object" ? JSON.parse(JSON.stringify(item)) : {}
            if (row && row.order && row.order.id) {
                ids.push(item._id)
            }
        })
        setDeleteOrderIds(ids)
        setDeleteOrdersModalVisible(true)
    }
    

    const createShipmentOrderValidate=()=>{
        let datas='';
        if(selectItemView===true){
            datas = bulkSelectedOrdersData && bulkSelectedOrdersData.data
        }else{
            datas = checkedItems
        }
        let orderIdDatas=[]
        datas.forEach((item) => {
                let row = typeof item == "object" ? JSON.parse(JSON.stringify(item)) : {}
                let docids=row && row._id;
                orderIdDatas.push(docids)
        })
        let final_data={
            "order_docids":orderIdDatas,
            "request_type":"CREATE_SHIPMENT"
        }
        if(orderIdDatas.length>0){
            dispatch(postBulkOrderValidation(final_data))
        }
    }

    const bulkVoidOrderValidate=()=>{
        let datas='';
        if(selectItemView===true){
            datas = bulkSelectedOrdersData && bulkSelectedOrdersData.data
        }else{
            datas = checkedItems
        }
        let orderIdDatas=[]
        datas.forEach((item) => {
                let row = typeof item == "object" ? JSON.parse(JSON.stringify(item)) : {}
                let docids=row && row._id;
                orderIdDatas.push(docids)
        })
        let final_data={
            "order_docids":orderIdDatas,
            "request_type":"VOID"
        }
        if(orderIdDatas.length>0){
            dispatch(postBulkOrderValidation(final_data))
        }
    }

    const validOrdersCount = (type) => {
        let datas='';
        let totalCount='';
        if(selectItemView===true){
            datas = bulkSelectedOrdersData && bulkSelectedOrdersData.data
            totalCount = datas.length
        }else{
            datas = checkedItems
            totalCount = checkedItems.length
        }
        let validCount = 0
        let invalidCount = 0
        datas.forEach((item) => {
            let row = typeof item == "object" ? JSON.parse(JSON.stringify(item)) : {}
            let valid = false
            if (row && row.line_items && row.line_items.length !== 0) {
                for (let key = 0; key < row.line_items.length; key++) {
                    if (type === "createshipment") {
                        if (row.line_items[key] &&
                            (typeof row.line_items[key].tracking_number === "undefined" || row.line_items[key].tracking_number === "") &&
                            ((row.shipment && (typeof row.shipment.bulk == "undefined" || typeof row.shipment.bulk.errors == "undefined")) || typeof row.shipment == "undefined")
                        ) {
                            valid = true
                            break
                        }
                    } else if (type === "voidshipment" || type === "manifestclose") {
                        if (row &&
                            row.shipment &&
                            row.shipment.tracking &&
                            row.line_items[key] &&
                            row.line_items[key].tracking_number &&
                            row.line_items[key].tracking_number !== "" &&
                            row.shipment.tracking[row.line_items[key].tracking_number] &&
                            typeof row.shipment.tracking[row.line_items[key].tracking_number].label !== "undefined" &&
                            typeof row.shipment.tracking[row.line_items[key].tracking_number].manifest !== "undefined" &&
                            typeof row.shipment.tracking[row.line_items[key].tracking_number].label.is_void_in_progress === "undefined" &&
                            row.shipment.tracking[row.line_items[key].tracking_number].manifest.is_closed === false
                        ) {
                            valid = true
                            break
                        }
                    }
                }
            }

            if (valid === true) {
                validCount += 1;
            } else {
                invalidCount += 1;
            }
    })
        return [totalCount, validCount, invalidCount]
    }

    const getModelDescription = (type) => {
        let counts=""
        let description = ""
        if(modalDetails === "voidshipment"){
            if(bulkvalidationOrdersData && bulkvalidationOrdersData.data &&  Object.keys(bulkvalidationOrdersData.data).length!==0){
                counts=bulkvalidationOrdersData && bulkvalidationOrdersData.data
                let validOorderIdsCount=parseInt(counts.valid_order_count)
                let invalidOorderIdsCount=parseInt(counts.invalid_order_count)
                let totalCount=validOorderIdsCount+invalidOorderIdsCount
                description = counts.valid_order_count + " out of " + totalCount + " were eligible for void"
            }
        }else if(modalDetails === "closemanifest"){
            if(bulkvalidationOrdersData && bulkvalidationOrdersData.data &&  Object.keys(bulkvalidationOrdersData.data).length!==0){
                counts=bulkvalidationOrdersData && bulkvalidationOrdersData.data
                let validOorderIdsCount=parseInt(counts.valid_order_count)
                let invalidOorderIdsCount=parseInt(counts.invalid_order_count)
                let totalCount=validOorderIdsCount+invalidOorderIdsCount
                description = counts.valid_order_count + " out of " + totalCount + " were eligible for manifest close"
            }
        }else if (modalDetails === "shipment") {
            if(bulkvalidationOrdersData && bulkvalidationOrdersData.data &&  Object.keys(bulkvalidationOrdersData.data).length!==0){
                counts=bulkvalidationOrdersData && bulkvalidationOrdersData.data
                let validOorderIdsCount=parseInt(counts.valid_order_docids.length)
                let invalidOorderIdsCount=parseInt(counts.invalid_order_docids.length)
                let totalCount=validOorderIdsCount+invalidOorderIdsCount
                description = counts.valid_order_docids.length + " out of " + totalCount + " were eligible to create shipment"

            }
        }
        return description
    }

    const modalDetailsCollection = {
        lessthanone: {
            description: "Please select atleast one orders.",
            onOk: () => setModalVisible(false)

        },
        morethanthousand: {
            description: "Please select less than or equal to thousand.",
            onOk: () => setModalVisible(false)
        },
        shipment: {
            title: "Create Shipment",
            description: getModelDescription("createshipment"),
            note: "Already created shipments will get ignored.",
            onOk: createShipmentBulkData
        },
        closemanifest: {
            title: "Manifest Close",
            description: getModelDescription("manifestclose"),
            note: "Already closed shipments will get ignored.",
            onOk: manifestCloseBulkData

        },
        voidshipment: {
            title: "Void Shipment",
            description: getModelDescription("voidshipment"),
            note: "Already voided shipments will get ignored.",
            onOk: voidShipmentBulkData

        },
        geneartelabel: {
            title: "Print Label",
            description: "Already printed label will be ignored.",
            onOk: printLabelBulk
        },
        novalidcreateshipment: {
            title: "Create Shipment",
            description: "All orders selected have shipments.",
            onOk: () => setModalVisible(false)
        },
        novalidlabel: {
            title: "Print Label",
            description: "All the selected orders are not eligible to print labels",
            onOk: () => setModalVisible(false)
        },
        default: {
            title: "",
            description: null,

        }
    }

    const groupAction = (method) => {
        if (checkedIds && checkedIds.length < 1) {
            setModalVisible(true)
            setModalDetails("lessthanone")
            return false
        }
        if (checkedIds && checkedIds.length > 1000) {
            setModalVisible(true)
            setModalDetails("morethanthousand")
            return false
        }
        if (method === "creatshipment") {
            setModalVisible(true)
            setModalDetails("shipment")
            return false
        }
        if (method === "manifestclose") {
            setModalVisible(true)
            setModalDetails("closemanifest")
            return false

        }
        if (method === "voidshipment") {
            setModalVisible(true)
            setModalDetails("voidshipment")
            return false
        }

        if (method === "printlabel") {
            printLabelBulk()
        }

        if (method === "printpackageslip") {
            printPackageSlip()
        }

        if (method === "assigntag") {
            assignTag()
        }

        if (method === "addbulkdimension") {
            addbulkdimension()
        }
        if (method === "deleteorders") {
            deleteordersData()
        }
        handleActionClose()
    }

    const choosenhandleClick = (id) => {
        setChoosenTrackingNumber(id)
    };

    function getValueFromObject(path, sourceObject) {
        let paths = path.split(".")
        try {
            paths.map((key) => (
                sourceObject = sourceObject[key]
            ))
            return sourceObject
        }
        catch (err) {
            return "-"
        }

    }

    function CustomTotalRows() {

        return (
            <div className="custom-total-rows">
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    Showing {orders && orders.ordersData && orders.ordersData.data && orders.ordersData.data.length} of {(orders && orders.ordersData && orders.ordersData.meta && orders.ordersData.meta.total) ? orders.ordersData.meta.total : 0}
                </FormControl>
            </div>
        )
    }


    function CustomPagesizeoptions() {

        return (
            <div className="custom-page-size-options">
                <span>
                    Show <select
                        value={meta && meta.per_page}
                        onChange={onChangePerPage}
                    >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={1000}>1000</option>
                        {/* <option value={2000}>2000</option> */}
                    </select> entries
                </span>
            </div>
        )
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <CustomPagesizeoptions />
                <CustomTotalRows />
            </GridToolbarContainer>
        );
    }

    function CustomPagination() {
        return (
            <>
                <GridFooterContainer style={{justifyContent:'end'}}>
                    <Pagination
                        color="primary"
                        shape="circular"
                        page={orders && orders.ordersData && orders.ordersData.meta && orders.ordersData.meta.page}
                        count={orders && orders.ordersData && orders.ordersData.meta && orders.ordersData.meta.last_page}
                        onChange={onChangePagination}
                    />
                    <PageSearch pagenumber={pageNumber} getpagenumber={onInputChangePagination} />
                </GridFooterContainer>
            </>
        );
    }


    const getFormatedShipmentData = () => {
        let shipmentList = []
        for (var key in newOrderFormData.shipment.tracking) {
            if(newOrderFormData.shipment.tracking[key] && newOrderFormData.shipment.tracking[key].label && typeof newOrderFormData.shipment.tracking[key].label.is_void_in_progress === "undefined"){
                let currentKey = key;
                shipmentList.push(<ListItem style={{ cursor: "pointer" }} onClick={() => newOrderCreateCallback(newOrderFormData, true)}>
                    <ListItemText onClick={(e) => choosenhandleClick(currentKey)} primary={currentKey} />
                </ListItem>)
            }
        }
        return shipmentList
    }

    const getFormatedReturnShipmentData = () => {
        let shipmentList = []
        for (var key in newOrderFormData.shipment.tracking) {
            let currentKey = key;
            if ((newOrderFormData.shipment.tracking[key] &&
                newOrderFormData.shipment.tracking[key].label &&
                newOrderFormData.shipment.tracking[key].label.is_return &&
                newOrderFormData.shipment.tracking[key].label.is_return === true)||
                (newOrderFormData.shipment.tracking[key].label.is_voided &&
                    newOrderFormData.shipment.tracking[key].label.is_voided === true)) {
            shipmentList.push(<ListItem style={{ cursor: "not-allowed",pointerEvents:"none",color:"rgba(0,0,0,.54)" }} onClick={() => returnOrderCreateCallback(newOrderFormData, true,currentKey)}>
                <ListItemText onClick={(e) => choosenhandleClick(currentKey)} primary={currentKey} />
            </ListItem>)
            }else{
                shipmentList.push(<ListItem style={{ cursor: "pointer" }} onClick={() => returnOrderCreateCallback(newOrderFormData, true,currentKey)}>
                <ListItemText onClick={(e) => choosenhandleClick(currentKey)} primary={currentKey} />
            </ListItem>)
            }
        }
        return shipmentList
    }

    const formattedRate = (shipment, isApiRate) => {
        let shipmentRate = 0
        if (shipment && shipment["rate"] && shipment["rate"]["billed"] && shipment["rate"]["billed"]["total"]) {
            shipmentRate = parseFloat(shipment["rate"]["billed"]["total"])
        } else if (shipment && shipment["rate"] && shipment["rate"]["total_charges"]) {
            shipmentRate = parseFloat(shipment["rate"]["total_charges"])
            isApiRate = true
        } else {
            shipmentRate = 0
        }
        return [shipmentRate, isApiRate]
    }

    const labelButton = (row) => {
        let labelButtonDisable = true;
        if (row &&
            row.label &&
            row.manifest &&
            typeof row.label.is_void_in_progress === "undefined" &&
            (row.label.url_detachable || row.label.url_direct_detachable || row.label.url || row.label.url_direct)
        ) {
            labelButtonDisable = false;
        }
        return labelButtonDisable;
    }

    const getOrderShipmentRate = (shipment, is_multi_piece_order) => {
        let calculatedRate = 0
        let fetchedRateArray = []
        let fetchedRate = 0
        let iteration = 0
        let isApiRate = false
        for (var key of Object.keys(shipment)) {
            let dataTemp = typeof shipment[key] == "object" ? JSON.parse(JSON.stringify(shipment[key])) : {};
            let voided = labelButton(dataTemp)
            if (voided && voided === true) {
                continue;
            }
            if (is_multi_piece_order && dataTemp && dataTemp["label"] && dataTemp["label"]["multi_piece"] && dataTemp["label"]["multi_piece"] === true && typeof dataTemp["label"]["master_tracking_number"]=="undefined" && (typeof dataTemp["label"]["is_multiple_shipment"]=="undefined" || dataTemp["label"]["is_multiple_shipment"] === false)) {
                iteration = iteration + 1;
            }
            if (is_multi_piece_order && dataTemp && dataTemp["label"] && dataTemp["label"]["multi_piece"] && dataTemp["label"]["multi_piece"] === true && iteration > 1 && typeof dataTemp["label"]["master_tracking_number"]=="undefined" && (typeof dataTemp["label"]["is_multiple_shipment"]=="undefined" || dataTemp["label"]["is_multiple_shipment"] === false)) {
                continue;
            }
            dataTemp["tracking_number"] = key;
            fetchedRateArray = formattedRate(dataTemp, isApiRate);
            fetchedRate = (fetchedRateArray[0]) ? fetchedRateArray[0] : 0;
            isApiRate = (fetchedRateArray[1]) ? fetchedRateArray[1] : false;
            calculatedRate = (fetchedRate !== NaN) ? calculatedRate + fetchedRate : calculatedRate;
        }
        if (calculatedRate === 0) {
            return "-"
        }
        calculatedRate = calculatedRate.toFixed(2)
        if (isApiRate) {
            return (
                <ShipmentRate billed_rate={null} api_rate={calculatedRate} is_multi_piece={is_multi_piece_order} is_voided={null} />
            )
        } else {
            return (
                <ShipmentRate billed_rate={calculatedRate} api_rate={null} is_multi_piece={is_multi_piece_order} is_voided={null} />
            )
        }
    }

    return (
        <>
            {(orders && orders.ordersData && orders.ordersData.notification && orders.ordersData.notification.show) &&
                <Notification
                    is_open={orders.ordersData.notification.show}
                    messageType={orders.ordersData.notification.messageType}
                    messages={orders.ordersData.notification.messageContent}
                    handleClose={() => dispatch(resetOrderNotifications())}
                />}
            {(orders && orders.ordersColumns.notification && orders.ordersColumns.notification.show) &&
                <Notification
                    is_open={orders.ordersColumns.notification.show}
                    messageType={orders.ordersColumns.notification.messageType}
                    messages={orders.ordersColumns.notification.messageContent}
                    handleClose={() => dispatch(resetOrderNotifications())}
                />}
            {(newcreateshipment && newcreateshipment.notification && newcreateshipment.notification.show && newcreateshipment.notification.messageType !== 'success') &&
                <Notification
                    is_open={newcreateshipment.notification.show}
                    messageType={newcreateshipment.notification.messageType}
                    messages={newcreateshipment.notification.messageContent}
                    handleClose={() => dispatch(resetGenerateLabel())}
                />}

            {(orders && orders.createShipment.notification && orders.createShipment.notification.show) &&
                <Notification
                    is_open={orders.createShipment.notification.show}
                    messageType={orders.createShipment.notification.messageType}
                    messages={orders.createShipment.notification.messageContent}
                    handleClose={() => dispatch(resetOrderNotifications())}
                />}
            {(orders && orders.voidShipment.notification && orders.voidShipment.notification.show) &&
                <Notification
                    is_open={orders.voidShipment.notification.show}
                    messageType={orders.voidShipment.notification.messageType}
                    messages={orders.voidShipment.notification.messageContent}
                    handleClose={() => dispatch(resetOrderNotifications())}
                />}
            {(orders && orders.createShipmentBulk.notification && orders.createShipmentBulk.notification.show) &&
                <Notification
                    is_open={orders.createShipmentBulk.notification.show}
                    messageType={orders.createShipmentBulk.notification.messageType}
                    messages={orders.createShipmentBulk.notification.messageContent}
                    handleClose={() => dispatch(resetOrderNotifications())}
                />}
            {(orders && orders.manifestClose.notification && orders.manifestClose.notification.show) &&
                <Notification
                    is_open={orders.manifestClose.notification.show}
                    messageType={orders.manifestClose.notification.messageType}
                    messages={orders.manifestClose.notification.messageContent}
                    handleClose={() => dispatch(resetOrderNotifications())}
                />}
             {(orders && orders.assignTags.notification && orders.assignTags.notification.show) &&
                <Notification
                    is_open={orders.assignTags.notification.show}
                    messageType={orders.assignTags.notification.messageType}
                    messages={orders.assignTags.notification.messageContent}
                    handleClose={() => dispatch(resetOrderNotifications())}
                />}
            {(orders && orders.syncOrderData.notification && orders.syncOrderData.notification.show) &&
            <Notification
                is_open={orders.syncOrderData.notification.show}
                messageType={orders.syncOrderData.notification.messageType}
                messages={orders.syncOrderData.notification.messageContent}
                handleClose={() => dispatch(resetOrderNotifications())}
            />}
            {(orders && orders.orderUIUpdate.notification && orders.orderUIUpdate.notification.show) &&
            <Notification
                is_open={orders.orderUIUpdate.notification.show}
                messageType={orders.orderUIUpdate.notification.messageType}
                messages={orders.orderUIUpdate.notification.messageContent}
                handleClose={() => dispatch(resetOrderNotifications())}
            />}
            {(orders && orders.addBulkDimension.notification && orders.addBulkDimension.notification.show) &&
                <Notification
                    is_open={orders.addBulkDimension.notification.show}
                    messageType={orders.addBulkDimension.notification.messageType}
                    messages={orders.addBulkDimension.notification.messageContent}
                    handleClose={() => dispatch(resetOrderNotifications())}
            />}
            {(orders && orders.deleteOrders.notification && orders.deleteOrders.notification.show) &&
                <Notification
                    is_open={orders.deleteOrders.notification.show}
                    messageType={orders.deleteOrders.notification.messageType}
                    messages={orders.deleteOrders.notification.messageContent}
                    handleClose={() => dispatch(resetOrderNotifications())}
            />}
            <Printer isPrinted={isPrinted} setIsPrinted={setIsPrinted} print_url={printUrl} />
            <Popover
                id={actionId}
                open={actionOpen}
                anchorEl={actionAnchorEl}
                onClose={handleActionClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <List>
                    <ListItemButton style={{ cursor: "pointer" }}  disabled={selectItemView==true && totalOrders >100 ?  true : false } onClick={() => groupAction("printpackageslip")}>
                        <ListItemIcon>
                            <PrintIcon />
                        </ListItemIcon>
                        <ListItemText primary="Print Packaging Slip" />
                    </ListItemButton>
                    <ListItemButton disabled={(orders && orders.createShipmentBulk && orders.createShipmentBulk.loading === true || profile && profile.disable_create_shipment===true) ? true : false} style={{ cursor: "pointer" }} 
                    onClick={() => {
                        groupAction("creatshipment")
                        createShipmentOrderValidate()
                        setActionAnchorEl(null);
                    }}
                    >
                        <ListItemIcon>
                            <AddCircleOutlineRoundedIcon />
                        </ListItemIcon>
                        <ListItemText primary="New Shipment" />
                    </ListItemButton>
                    <ListItemButton style={{ cursor: "pointer" }} disabled={selectItemView==true  && totalOrders >100  ?  true : false } onClick={() => groupAction("printlabel")}>
                        <ListItemIcon>
                            <LocalPrintshopIcon />
                        </ListItemIcon>
                        <ListItemText primary="Print Label" />
                    </ListItemButton>
                    <ListItemButton disabled={orders && orders.voidShipment && orders.voidShipment.loading === true ? true : false} style={{ cursor: "pointer" }} 
                    onClick={() => {
                        groupAction("voidshipment")
                        bulkVoidOrderValidate()
                    }}>
                        <ListItemIcon>
                            <DeleteOutlineOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Void Shipment" />
                    </ListItemButton>
                    <ListItemButton disabled={orders && orders.manifestClose && orders.manifestClose.loading === true ? true : false} style={{ cursor: "pointer" }}
                        onClick={() => {
                            groupAction("manifestclose")
                            bulkVoidOrderValidate()
                        }}>
                        <ListItemIcon>
                            <AssignmentTurnedInOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Close Manifest (Selected Only)" />
                    </ListItemButton>
                    <ListItemButton disabled={tags && tags.loading === true ? true : false} style={{ cursor: "pointer" }} onClick={() => groupAction("assigntag")}>
                        <ListItemIcon>
                            <TagOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Assign tag" />
                    </ListItemButton>
                    <ListItemButton disabled={tags && tags.loading === true ? true : false} style={{ cursor: "pointer" }} onClick={() => groupAction("addbulkdimension")}>
                        <ListItemIcon>
                            <AllInboxIcon />
                        </ListItemIcon>
                        <ListItemText primary="Add Bulk Dimensions" />
                    </ListItemButton>
                    <ListItemButton disabled={tags && tags.loading === true ? true : false} style={{ cursor: "pointer" }} onClick={() => groupAction("deleteorders")}>
                        <ListItemIcon>
                            <DeleteForeverOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Delete Orders" />
                    </ListItemButton>
                </List>
            </Popover>
            <Popover
                id={viewActionId}
                open={viewActionOpen}
                anchorEl={viewActionAnchorEl}
                onClose={handleViewActionClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <List onMouseLeave={handleViewActionClose}>
                    <ListItem style={{ cursor: "pointer" }} onClick={() => { window.open(profile && profile.label_size_packaging_slip && profile.label_size_packaging_slip==true ? process.env.REACT_APP_INTEGRATIONS_API_DOMAIN + "/package-customslip-order?doc_id=" + currentViewItem._id :process.env.REACT_APP_INTEGRATIONS_API_DOMAIN + "/package-slip-order?doc_id=" + currentViewItem._id, "_blank"); }}>
                        <ListItemIcon>
                            <RemoveRedEyeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Package Slip" />
                    </ListItem>
                    <ListItem style={{ cursor: "pointer" }} onClick={() => { setPrintUrl(profile && profile.label_size_packaging_slip && profile.label_size_packaging_slip==true ? process.env.REACT_APP_INTEGRATIONS_API_DOMAIN + "/package-customslip-order?doc_id=" + currentViewItem._id : process.env.REACT_APP_INTEGRATIONS_API_DOMAIN + "/package-slip-order?doc_id=" + currentViewItem._id); setIsPrinted(false); }}>
                        <ListItemIcon>
                            <LocalPrintshopIcon />
                        </ListItemIcon>
                        <ListItemText primary="Package Slip" />
                    </ListItem>
                    {currentViewItem && currentViewItem.shipment && currentViewItem.shipment.tracking && currentViewItem.shipping_address && currentViewItem.shipping_address.country_code!='CA' &&<>
                    <ListItem style={{ cursor: "pointer" }} onClick={() => { window.open(process.env.REACT_APP_INTEGRATIONS_API_DOMAIN + "/package-invoice?doc_id=" + currentViewItem._id, "_blank"); }}>
                        <ListItemIcon>
                            <RemoveRedEyeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Customs Invoice" />
                    </ListItem>
                    <ListItem style={{ cursor: "pointer" }} onClick={() => { setPrintUrl(process.env.REACT_APP_INTEGRATIONS_API_DOMAIN + "/package-invoice?doc_id=" + currentViewItem._id); setIsPrinted(false); }}>
                        <ListItemIcon>
                            <LocalPrintshopIcon />
                        </ListItemIcon>
                        <ListItemText primary="Customs Invoice" />
                    </ListItem>
                    </>
                    }
                </List>
            </Popover>
            <Popover
                id={duplicateActionId}
                open={duplicateActionOpen}
                anchorEl={duplicateActionAnchorEl}
                onClose={handleDuplicateActionClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <List onMouseLeave={handleDuplicateActionClose}>
                    {(newOrderFormData && newOrderFormData.shipment && newOrderFormData.shipment.tracking && newOrderFormData.shipment.tracking.length != 0) &&
                        getFormatedShipmentData()
                    }
                </List>
            </Popover>
            <Popover
                id={returnActionId}
                open={returnActionOpen}
                anchorEl={returnAnchorEl}
                onClose={handleReturnActionClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <List onMouseLeave={handleReturnActionClose}>
                    {(newOrderFormData && newOrderFormData.shipment && newOrderFormData.shipment.tracking && newOrderFormData.shipment.tracking.length != 0) &&
                        getFormatedReturnShipmentData()
                    }
                </List>
            </Popover>
            <Popover
                    id={viewMoreActionId}
                    open={viewMoreActionOpen}
                    anchorEl={viewMoreActionAnchorEl}
                    onClose={handleViewMoreActionClose}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                      }}
                      style={{padding: '5px'}}
                >
                     {/* <Typography sx={{ p: 2 }}>The content of the Popover.</Typography> */}
                 <span style={{ marginRight: '5px' }}>
                    <Tooltip title="Label" placement="top">
                        <IconButton
                            disabled={openLabelButtonView(viewMoreActionsRow)}
                            style={{
                                color: openLabelButtonView(viewMoreActionsRow) ? 'rgba(0,0,0,.54)' : '#1890ff',
                                cursor: openLabelButtonView(viewMoreActionsRow) ? 'not-allowed' : 'pointer',
                                pointerEvents: openLabelButtonView(viewMoreActionsRow) ? 'none' : 'auto'
                            }}
                            onClick={() => openLabelClick(viewMoreActionsRow)}
                        >
                            <LocalPrintshopIcon />
                        </IconButton>
                    </Tooltip>
                </span>
                <span>
                    <Tooltip title="View" placement="top">
                        <IconButton onClick={handleViewActionClick}>
                            <RemoveRedEyeIcon onClick={() => setCurrentViewItem(viewMoreActionsRow)} style={{ color: "#1890ff", cursor: 'pointer' }} />
                        </IconButton>
                    </Tooltip>
                </span>
                <span style={{ marginRight: '5px' }}>
                    <Tooltip title="Duplicate Shipment" placement="top">
                        <IconButton
                            onClick={(event) => (viewMoreActionsRow && viewMoreActionsRow.shipment && viewMoreActionsRow.shipment.master) ? newOrderCreateCallback(viewMoreActionsRow, true) :(viewMoreActionsRow && viewMoreActionsRow.shipment && viewMoreActionsRow.shipment.tracking && Object.keys(viewMoreActionsRow.shipment.tracking).length > 1) ? handleDuplicateActionClick(event, viewMoreActionsRow) : newOrderCreateCallback(viewMoreActionsRow, true)}
                            style={{
                                color: '#1890ff'
                            }}
                        >
                            <ContentCopyOutlinedIcon onClick={() => (viewMoreActionsRow && viewMoreActionsRow.shipment && viewMoreActionsRow.shipment.tracking && Object.keys(viewMoreActionsRow.shipment.tracking).length > 1) ? setNewOrderFormData(viewMoreActionsRow) : ""} />
                        </IconButton>
                    </Tooltip>
                </span>
                <span>
                        <Tooltip title="Return Shipment" placement="top">
                            <IconButton
                                disabled={returnlabelButton(viewMoreActionsRow)}
                                style={{
                                    color: returnlabelButton(viewMoreActionsRow) ? 'rgba(0,0,0,.54)' : '#1890ff',
                                    pointerEvents: returnlabelButton(viewMoreActionsRow) ? 'none' : 'auto',
                                    cursor: returnlabelButton(viewMoreActionsRow) ? 'not-allowed' : 'pointer'
                                }}
                                onClick={(event) =>(viewMoreActionsRow && viewMoreActionsRow.shipment && viewMoreActionsRow.shipment.tracking && Object.keys(viewMoreActionsRow.shipment.tracking).length > 1) ? handleReturnActionClick(event, viewMoreActionsRow) : returnOrderCreateCallback(viewMoreActionsRow, true,viewMoreActionsRow.tracking_number)}
                                >
                                <KeyboardReturnOutlinedIcon onClick={() => (viewMoreActionsRow && viewMoreActionsRow.shipment && viewMoreActionsRow.shipment.tracking && Object.keys(viewMoreActionsRow.shipment.tracking).length > 1) ? setNewOrderFormData(viewMoreActionsRow) : ""}/>
                            </IconButton>
                        </Tooltip>
                    </span>
                    <span>
                        <Tooltip title="View Shipments" placement="top">
                            <IconButton onClick={() => lineItemsDrawerOpen(viewMoreActionsRow)} style={{color:'#1890ff'}}>
                                <LocalShippingOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                </span>
                </Popover>
            <Modal
                open={tagModalVisible}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={tagstyle}>
                    <Card sx={{ minWidth: 400 }} className="sc--cardhead--details">
                        <CardHeader title='Assign Tag' 
                         action={
                            <div>
                                <Link to={{ pathname: "/settings/tag-settings" }}><Button variant="contained" >Add New Tag</Button></Link>
                            </div>
                        }/>
                        <Divider />
                        <CardContent>
                            <div className="sc--form--center" style={{ margin: '20px 0' }}>
                                <FormControl
                                    variant="standard"
                                    className="help-text-error"
                                    style={{ width: '100%', margin: '8px' }}
                                >
                                    <InputLabel
                                        id="demo-simple-select-standard-label"
                                    >Choose tag
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        name="tag"
                                        value={assignTagValue}
                                        onChange={(e) => {
                                            setAssignTagValue(e.target.value)
                                        }
                                        }
                                        label="Choose tag"
                                    >
                                        {tags && tags.data && tags.data.map((item) => {
                                            return (
                                                <MenuItem value={item.tag_name}>
                                                    <p style={{ margin: 'unset', color: 'rgba(0, 0, 0, 0.87)' }}>
                                                        {item.tag_name}
                                                </p>
                                                </MenuItem>
                                            )
                                        })}
                                         <MenuItem value={""}>None</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="sc--form--center" style={{ margin: '30px 0' }}>
                                <Button variant="outlined" style={{marginRight:'10px'}} onClick={() =>{updateAssignTagHandle(assignTagIds,assignTagValue)}}>Submit</Button>
                                <Button variant="outlined" onClick={() => { setTagModalVisible(false) }}>Cancel</Button>
                            </div>
                        </CardContent>
                    </Card>
                </Box>
            </Modal >
            <Modal
                open={bulkDimensionModalVisible}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={tagstyle}>
                    <Card sx={{ minWidth: 600 }} className="sc--cardhead--details">
                        <CardHeader title='Bulk Dimension' 
                         action={
                            <div>
                                <Link to={{ pathname: "/settings/box" }}><Button variant="contained" >Add Box Dimension</Button></Link>
                            </div>
                        }/>
                        <Divider />
                        <CardContent>
                                <div className="sc--form--center" style={{ margin: '20px 0' }}>
                                    <Autocomplete
                                        className="help-text-error"
                                        disablePortal
                                        name="choosenmeasurement"
                                        id="state-select-demo"
                                        sx={{ width: "100%",margin:'8px'}}
                                        options={boxData}
                                        autoHighlight
                                        loading={
                                        (box && box.loading)
                                        }
                                        getOptionLabel={(option) => option.box_name=='custom' ? ""+option.box_name+"": ( ""+option.box_name+" ("+option.box_length+option.box_length_unit+"x "+ option.box_width+option.box_width_unit+"x "+ option.box_height+option.box_height_unit+", "+option.box_weight+option.box_weight_unit+")")}
                                        filterOptions={filterOptions}
                                        value={getChooseMeasurement(dimensionValue._id)}
                                        renderOption={(props, option) => (
                                        <MenuItem {...props} value={option._id} key={option._id}>
                                            {option.box_name =='custom' ?
                                            <p style={{ margin: 'unset', color: 'rgba(0, 0, 0, 0.87)' }}>
                                                {option.box_name}
                                            </p>:
                                            <p style={{ margin: 'unset', color: 'rgba(0, 0, 0, 0.87)' }}>
                                            {option.box_name}(
                                            {option.box_length}{option.box_length_unit} <span style={{ color: '#0082ff' }}> x </span>
                                                            {option.box_width}{option.box_width_unit} <span style={{ color: '#0082ff' }}> x </span>
                                                            {option.box_height}{option.box_height_unit}<span>, </span>
                                                            {option.box_weight}{option.box_weight_unit})
                                            </p>}
                                        </MenuItem>
                                        )}
                                        onChange={(event, value) => {
                                            if (value !== null) {
                                                let event={
                                                    "target":{
                                                        "name":"choosenmeasurement",
                                                        "value":value._id
                                                    }}
                                                onBoxDimensionChange(event);
                                            } 
                                        }}
                                        renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Choose Measurement"
                                            variant="standard"
                                        />
                                        )}
                                    />
                                </div>
                                <div className="sc--form--center" style={{ margin: '10px 0' }}>
                                    <TextField id="standard-basic"
                                        label="Length"
                                        variant="standard"
                                        name="box_length"
                                        type="number"
                                        inputProps={{ min: 1 }}
                                        value={dimensionValue.box_length}
                                        onChange={event => onBoxDimensionChange(event)}
                                        error={dimensionsErrors.box_length}
                                        helperText={dimensionsErrors.box_length}
                                        style={{ width: '17%' }}
                                    />
                                    <TextField id="standard-basic"
                                        label="Width"
                                        variant="standard"
                                        name="box_width"
                                        type="number"
                                        inputProps={{ min: 1 }}
                                        value={dimensionValue.box_width}
                                        onChange={event => onBoxDimensionChange(event)}
                                        error={dimensionsErrors.box_width}
                                        helperText={dimensionsErrors.box_width}
                                        style={{ width: '17%' }}
                                    />
                                    <TextField id="standard-basic"
                                        label="Height"
                                        variant="standard"
                                        name="box_height"
                                        type="number"
                                        inputProps={{ min: 1 }}
                                        value={dimensionValue.box_height}
                                        onChange={event => onBoxDimensionChange(event)}
                                        error={dimensionsErrors.box_height}
                                        helperText={dimensionsErrors.box_height}
                                        style={{ width: '17%' }}
                                    />
                                    <FormControl
                                        variant="standard"
                                        className="help-text-error"
                                        style={{ width: '29%', margin: '8px' }}
                                    >
                                        <InputLabel
                                            style={{ color: dimensionsErrors.box_dimension_unit ? "#D32F2F" : '' }}
                                        > Dim Unit
                                    </InputLabel>
                                        <Select
                                            name="box_dimension_unit"
                                            onChange={event => onBoxDimensionChange(event)}
                                            value={dimensionValue.box_dimension_unit}
                                            error={dimensionsErrors.box_dimension_unit}
                                            label="Dimension Unit"
                                            id="filled-error-helper-text"
                                        >
                                            <MenuItem value="in">in</MenuItem>
                                            <MenuItem value="cm">cm</MenuItem>
                                        </Select>
                                        <FormHelperText>{dimensionsErrors.box_dimension_unit}</FormHelperText>
                                    </FormControl>

                                </div>
                                <div className="sc--form--center" style={{ margin: '10px 0' }}>
                                    <TextField id="actual-weight-id"
                                        label="Actual weight"
                                        variant="standard"
                                        name="box_actual_weight"
                                        type="number"
                                        inputProps={{ min: 1 }}
                                        value={dimensionValue.box_actual_weight}
                                        autoFocus={true}
                                        onChange={event => onBoxDimensionChange(event)}
                                        error={dimensionsErrors.box_actual_weight}
                                        helperText={dimensionsErrors.box_actual_weight}
                                        style={{ width: '50%' }} />

                                    <FormControl
                                        variant="standard"
                                        className="help-text-error"
                                        style={{ width: '30%', margin: '8px' }}
                                    >
                                        <InputLabel
                                            id="demo-simple-select-standard-label"
                                            style={{ color: dimensionsErrors.box_weight_unit ? "#D32F2F" : '' }}
                                        > Weight Unit
                                    </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            name="box_weight_unit"
                                            onChange={event => onBoxDimensionChange(event)}
                                            value={dimensionValue.box_weight_unit}
                                            label="Weight Unit"
                                        >
                                            <MenuItem value="lb">lb</MenuItem>
                                            <MenuItem value="kg">kg</MenuItem>

                                        </Select>
                                        <FormHelperText>{dimensionsErrors.box_weight_unit}</FormHelperText>
                                    </FormControl>
                                </div>
                                <div className="sc--form--center" style={{ margin: '10px 0' }}>
                                    <TextField id="insurance-amount-id"
                                        label="Insurance Amount (Optional)"
                                        variant="standard"
                                        name="insurance_amount"
                                        type="number"
                                        inputProps={{ min: 1 }}
                                        value={dimensionValue.insurance_amount}
                                        autoFocus={true}
                                        onChange={event => onBoxDimensionChange(event)}
                                        style={{ width: '50%' }} />
                                </div>
                                <div className="sc--form--center" style={{ margin: '30px 0' }}>
                                    <Button variant="outlined" disabled={orders && orders.addBulkDimension && orders.addBulkDimension.loading === true ? true:false} style={{marginRight:'10px'}} onClick={() =>{addBulkDimensionsForm(bulkDimensionIds,dimensionValue)}}>Submit</Button>
                                    <Button variant="outlined" onClick={() => { 
                                        setBulkDimensionModalVisible(false)
                                        setDimensionValue(initialBoxValues)
                                        setDimensionsErrors({})
                                        }}>Cancel</Button>
                                </div>
                        </CardContent>
                    </Card>
                </Box>
            </Modal >
            <Modal
                open={modalVisible}
                aria-labelledby="bulk-modal-title"
                aria-describedby="bulk-modal-description"
            >
                <Box sx={style} style={{ textAlign: "center",width: modalDetails==='shipment' ? '700px' : '500px' }}>
                {bulkvalidationOrdersData && bulkvalidationOrdersData.loading === true && (
                        <LinearProgress />
                    )}
                    <Typography id="bulk-modal-title" variant="h6" component="h2">
                        {modalDetailsCollection[modalDetails].title}
                    </Typography>
                    <Typography id="bulk-modal-description" sx={{ mt: 2 }} style={{ marginBottom: "10px" }}>
                        {modalDetailsCollection[modalDetails].description}
                        {typeof modalDetailsCollection[modalDetails].note != "undefined" && <><br /><br />
                            <span>Note : {modalDetailsCollection[modalDetails].note}</span>
                        </>
                        }
                        {modalDetails==='shipment' && bulkvalidationOrdersData && bulkvalidationOrdersData.data && Object.keys(bulkvalidationOrdersData.data).length!==0  && bulkvalidationOrdersData.data.invalid_order_docids && bulkvalidationOrdersData.data.invalid_order_docids.length>0 && 
                            errorTable( bulkvalidationOrdersData.data.invalid_order_docids)
                        }
                        {modalDetails==='shipment' && <>
                            <div className="sc--form--center"  style={{ margin: '15px 0',  textAlign: 'center',position:'relative',left:'-3%' }}>
                                <TextField
                                    label="Notes"
                                    name="notes"
                                    inputProps={{ maxLength: 200 }}
                                    onInput={e => setNotes(e.target.value)}
                                    variant="standard" 
                                    style={{ width: "80%", margin: '8px' }}
                                />
                            </div>
                        </>
                        }
                    </Typography>
                    <Button variant="outlined" onClick={() => {
                         setModalVisible(false)}}>Cancel</Button>
                    <Button variant="contained" disabled={bulkvalidationOrdersData && bulkvalidationOrdersData.loading === true ? true:false} style={{ marginLeft: "15px" }} onClick={() => { modalDetailsCollection[modalDetails].onOk() }}>Ok</Button>
                </Box>
            </Modal>
            <Modal
                open={openSenderAddressModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Sender address not configured or default not set
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Please configure sender address to create shipment
                    </Typography>
                    <Button variant="contained" onClick={() => { window.location = '/settings/address-sender'; }}>Ok</Button>
                </Box>
            </Modal>
            <Modal
                open={openBillingAddressModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Billing address not configured
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Please configure billing address to create shipment
                    </Typography>
                    <Button variant="contained" onClick={() => { window.location = '/settings/address-billing'; }}>Ok</Button>
                </Box>
            </Modal>
            <Modal
                open={openBoxModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} style={{ textAlign: "center" }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Box settings not configured
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Please configure Box settings to create shipment
                    </Typography>
                    <Button variant="contained" style={{marginTop:'15px'}} onClick={() => { window.location = '/settings/box'; }}>Ok</Button>
                </Box>
            </Modal>
            <Modal
                open={deleteOrdersModalVisible}
                aria-labelledby="void-confirm-modal-title"
                aria-describedby="void-confirm-modal-description"
            >
                <Box sx={style} style={{ width: notDeleteOrders && notDeleteOrders.length>0 ? '700px' : '500px' }}>
                    {orders && orders.deleteOrders && orders.deleteOrders.loading === true && (
                        <LinearProgress />
                    )}
                    <Typography>
                        {notDeleteOrders && notDeleteOrders.length>0 &&
                            notDeleteOrdersTable(notDeleteOrders)
                        }
                    {notDeleteOrders && notDeleteOrders.length>0 &&  <Button variant="outlined" style={{margin:'20px auto',display:'flex'}} onClick={() => { 
                        setDeleteOrdersModalVisible(false)
                        setNotDeletedOrders([])
                         }}>Cancel</Button>}
                    </Typography>
                    {notDeleteOrders && notDeleteOrders.length===0 && <>
                    <Typography style={{ textAlign: "center" }} id="void-confirm-modal-description" sx={{ mt: 2 }}>
                    Are you sure? Do you want to remove all these orders ?
                    </Typography>
                    <Typography style={{ marginTop: "25px", textAlign: "center" }}>
                        <Button variant="outlined" onClick={() => { setDeleteOrdersModalVisible(false) }}>No</Button>
                        <LoadingButton  variant="contained" loading={orders && orders.deleteOrders && orders.deleteOrders.loading === true} style={{ marginLeft: "15px" }} onClick={() =>{deleteOrdersValues()}}>Yes</LoadingButton>
                    </Typography>
                    </>
                    }
                </Box>
            </Modal>
            <div className="scmaincard">
                <div className="sccardhead" style={{marginBottom:'30px'}}>
                    <Typography variant="h5" gutterBottom component="div">
                        Orders
                    </Typography>
                    <ul className="features-list" style={{ position: "absolute", float: "right", right: "16px" }}>
                        {profile && profile.id && profile.id===1604 && 
                        <li>
                            <Tooltip title="Create new order" placement="top-start">
                                <Button variant="outlined" onClick={() => dispatch(syncOrderDatas(profile && profile.api_token))}>
                                    <SyncIcon  /> <span style={{ marginLeft: "8px" }}>Sync Today Orders</span>
                                </Button>
                            </Tooltip>
                        </li>
                        }
                        {datagridSave===false &&
                         <li>
                            <Tooltip title="Save this column size and ordering" placement="top-start">
                                <LoadingButton variant="outlined" disabled={datagridSave} loading={orderUIUpdate && orderUIUpdate.loading} onClick={() => onDatagridSave()}>
                                     <span>Save this view</span>
                                </LoadingButton>
                            </Tooltip>
                        </li>
                        }
                        <li>
                            <Tooltip title="Create new order" placement="top-start">
                                <Button variant="outlined" disabled={profile && profile.disable_create_shipment ===true ? true :false} onClick={() => newOrderCreateCallback("")}>
                                    <AddCircleOutlineRoundedIcon  /> <span style={{ marginLeft: "8px" }}>Create Order</span>
                                </Button>
                            </Tooltip>
                        </li>
                        <li>
                            <Tooltip title="Seacrh" placement="top-start">
                                <IconButton style={(showSearch) ? { backgroundColor: 'rgb(230, 230, 230)' } : {}} onClick={() => onMenuClick("search", !showSearch)}>
                                    <SearchIcon style={{ color: 'rgb(25, 118, 210)' }} />
                                </IconButton>
                            </Tooltip>
                        </li>
                        {(checkedIds && checkedIds.length >= 1) &&
                            <li>
                                <Tooltip title="Action" placement="top-start">
                                    <IconButton style={(actionOpen) ? { backgroundColor: 'rgb(230, 230, 230)' } : {}} onClick={handleActionsClick}>
                                        <SettingsIcon style={{ color: 'rgb(25, 118, 210)' }} />
                                    </IconButton>
                                </Tooltip>
                            </li>
                        }
                        <li>
                            <Tooltip title="Filter" placement="top-start">
                                <IconButton style={(showFilter) ? { backgroundColor: 'rgb(230, 230, 230)' } : {}} onClick={() => onMenuClick("filter", !showFilter)}>
                                    <FilterAltOutlinedIcon style={{ color: 'rgb(25, 118, 210)' }} />
                                </IconButton>
                            </Tooltip>
                        </li>
                        <li>
                            <Tooltip title="Latest 5000 orders will be exported" placement="top-start">
                                <IconButton onClick={() => onExportClick()}>
                                    <FileDownloadIcon style={{ color: 'rgb(25, 118, 210)' }} />
                                </IconButton>
                            </Tooltip>
                        </li>
                        <li>
                            <Tooltip title="Columns" placement="top-start">
                                <IconButton
                                    id="dynamic-column-positioned-button"
                                    aria-controls={showDynamicColumn ? "dynamic-column-positioned-menu" : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={showDynamicColumn ? 'true' : undefined}
                                    style={(showDynamicColumn) ? { backgroundColor: 'rgb(230, 230, 230)' } : {}}
                                    onClick={handleColumnsClick}
                                >
                                    <ViewColumnSharpIcon style={{ color: 'rgb(25, 118, 210)' }} />
                                </IconButton>
                            </Tooltip>
                        </li>
                    </ul>
                </div>

                <div>
                    {anchorEl != null && <DynamicColumns
                        visible={showDynamicColumn}
                        anchorEl={anchorEl}
                        columns={orders && orders.ordersColumns && orders.ordersColumns.columns}
                        columnCheckedObject={columnCheckedObject}
                        setColumnCheckedObject={setColumnCheckedObject}
                        updateDynamicColumns={updateDynamicColumns}
                        handleColumnsClose={handleColumnsClose}
                        loading={orders && orders.ordersColumns && orders.ordersColumns.loading}
                    />}
                    <Search visible={showSearch} meta={meta} setMeta={setMeta} />
                    <Filters visible={showFilter} meta={meta} setMeta={setMeta} carriers={orders && orders.carriers} tags={orders && orders.platformTags} orderTags={tags && tags} report={urlParam} />
                </div>
                {(selectItemTextVisible && selectItemView==false) &&
                 <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}><p style={{margin:'unset'}}>All <b>{totalSelectedItems}</b> orders on this page are selected.<span className="order-selected-text" onClick={handleBulkSelectedOrders}> Select {totalOrders <= 2500 ?  totalOrders : 2500 } Orders </span></p>
                </div>
                }
                 {selectItemView &&
                 <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}><p style={{margin:'unset'}}> <b>{orders && orders.ordersData && orders.ordersData.meta && orders.ordersData.meta.total && orders.ordersData.meta.total <= 2500 ?  orders.ordersData.meta.total : 2500 }</b>  orders are selected..<span className="order-selected-text" onClick={handleClearOrders}> Clear selection </span></p>
                </div>
                }
                </div>
                <Box style={{ background: '#fff', marginTop: "20px",width: '100%'}}>
                    <DataGridPro
                        style={{ paddingTop: "37px"}}
                        getRowId={(row) => row._id}
                        rows={orders && orders.ordersData && orders.ordersData.data}
                        columns={columns}
                        autoHeight={true}
                        disableColumnFilter={true}
                        disableColumnMenu={true}
                        sortingMode="server"
                        checkboxSelection
                        onRowSelectionModelChange={(newSelectionModel) => { onSelectionChange(newSelectionModel, orders.ordersData.data) }}
                        rowSelectionModel={checkedIds}
                        onSortModelChange={(newSortModel) => onSortChange(newSortModel)}
                        sortModel={meta && meta.sorting && JSON.parse(meta.sorting)}
                        onColumnResize={onColumnResizeCustom}
                        onColumnOrderChange={onColumnReorderingCustom}
                        disableRowSelectionOnClick
                        slots={{
                            footer: CustomPagination,
                            loadingOverlay: LinearProgress,
                            toolbar: CustomToolbar,
                            NorowsData
                        }}
                        loading={
                            (orders && orders.ordersData && orders.ordersData.loading) ||
                            (orders && orders.createShipment && orders.createShipment.loading) ||
                            (orders && orders.createShipmentBulk && orders.createShipmentBulk.loading) ||
                            (orders && orders.voidShipment && orders.voidShipment.loading) ||
                            (orders && orders.manifestClose && orders.manifestClose.loading) ||
                            (orders && orders.bulkLabel && orders.bulkLabel.loading) || 
                            (orders && orders.bulkSelectedOrdersData && orders.bulkSelectedOrdersData.loading) ||
                            (orders && orders.deleteOrders && orders.deleteOrders.loading)
                        }
                    />
                </Box>
           
            <TrackingDrawer
                visible={trackingDrawerVisible} message={trackingMessage} isMultiPiece={isMultiPiece} onClose={onClose}
            />
            <OrderDrawer
                visible={orderDrawerVisible} onClose={orderOnClose} orderRow={orderRow} meta={meta} orderId={orderId} invalidDocIds={invalidDocIds}
            />
            <LineItemsDrawer
                visible={lineItemsDrawerVisible} order={lineItemsData} onClose={lineItemsDrawerClose}
            />
            <NewOrderCreateDrawer
                visible={newOrderCreateDrawerVisible}
                reportName={"orders"}
                newOrderFormData={newOrderFormData}
                setNewOrderFormData={setNewOrderFormData}
                meta={meta}
                onClose={newOrderCreateOnClose}
                isDuplicateOrder={isDuplicateOrder}
                isReturnOrder={isReturnOrder}
                setIsReturnOrder={setIsReturnOrder}
                setNewOrderCreateDrawerVisible={setNewOrderCreateDrawerVisible}
                choosenTrackingNumber={choosenTrackingNumber}
                isShipmentCreationFlow={isShipmentCreationFlow}
            />
        </>
    )

}

export default OrdersPage;
