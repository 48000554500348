import { createSlice } from '@reduxjs/toolkit';
import { OMSAPI } from '../../../../../API';
import { fetchIntegratedPlatform } from '../../integratedSlice';

export const woocommerceslice = createSlice({
    name: 'woocommerce',
    initialState: {
        progress: {
            data: {
                is_update_shipment: false,
                is_update_shipment_on_create: false,
                is_update_shipment_on_close: true
            },
            message: "",
            loading: false,
            hasErrors: false
        },
        configure: {
            data: {},
            message: "",
            loading: false,
            hasErrors: false
        },
        validate: {
            data: {},            
            loading: false,
            hasErrors: false
        }
    },
    reducers: {
        fetchWooCommerceDataDetails: (state) => {
            return {
                ...state,
                progress: {
                    loading: true
                }
            }
        },
        fetchWooCommerceDataDetailsSuccess: (state, action) => {
            return {
                ...state,
                progress: {
                    data: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data,
                    loading: false,
                    hasErrors: false,
                }
            }
        },
        fetchWooCommerceDataDetailsFailure: (state, action) => {
            return {
                ...state,
                progress: {
                    loading: false,
                    hasErrors: false,
                }
            }
        },
        postWooCommerceConfigure: (state) => {
            return {
                ...state,
                configure: {
                    loading: true
                }
            }
        },
        postWooCommerceConfigureSuccess: (state, action) => {
            return {
                ...state,
                configure: {
                    data: {},
                    message: action && action.payload && action.payload.data && action.payload.data.messages,
                    loading: false,
                    hasErrors: false,
                }
            }
        },
        postWooCommerceConfigureFailure: (state, action) => {
            return {
                ...state,
                configure: {
                    message: action && action.payload && action.payload.data && action.payload.data.messages,
                    loading: false,
                    hasErrors: true,
                }
            }
        },
        getWoocommerceAppCheckUrl: (state) => {
            return {
                ...state,
                validate: {
                    loading: true
                }
            }
        },
        getWoocommerceAppCheckUrlSuccess: (state, action) => {
            return {
                ...state,
                validate: {
                    data: action && action.payload && action.payload.data,                   
                    loading: false,
                    hasErrors: false,
                }
            }
        },
        getWoocommerceAppCheckUrlFailure: (state, action) => {
            return {
                ...state,
                validate: {
                    data: action && action.payload && action.payload.data,
                    loading: false,
                    hasErrors: true,
                }
            }
        },
        resetConfigureStateDetails: (state, action) => {
            return {
                ...state,
                configure: {
                    data: {},
                    message: '',
                    loading: false,
                    hasErrors: false,
                }
            }
        },
        resetWoocommerceStateDetails: (state, action) => {
            return {
                ...state,
                progress: {
                    data: {
                        is_update_shipment: false,
                        is_update_shipment_on_create: false,
                        is_update_shipment_on_close: true
                    },
                    message: "",
                    loading: false,
                    hasErrors: false
                },
                configure: {
                    data: {},
                    message: "",
                    loading: false,
                    hasErrors: false
                },
                validate: {
                    data: {},
                    loading: false,
                    hasErrors: false
                }
            }
        },
    }
})
export const { fetchWooCommerceDataDetails, fetchWooCommerceDataDetailsSuccess, fetchWooCommerceDataDetailsFailure,
    postWooCommerceConfigure, postWooCommerceConfigureSuccess, postWooCommerceConfigureFailure, resetConfigureStateDetails,getWoocommerceAppCheckUrl
,getWoocommerceAppCheckUrlSuccess,getWoocommerceAppCheckUrlFailure, resetWoocommerceStateDetails } = woocommerceslice.actions;

export const configureStore = data => dispatch => {
    dispatch(postWooCommerceConfigure());
    try {
        OMSAPI.post('/woocommerce/configure', data)
            .then(function (response) {
                dispatch(postWooCommerceConfigureSuccess(response));
                dispatch(fetchIntegratedPlatform());
            }).catch(function (error) {
                dispatch(postWooCommerceConfigureFailure(error.response));
            })
    }
    catch (error) {
        dispatch(postWooCommerceConfigureFailure(error.response));
    }
}

export const fetchWooCommerceData = data => dispatch => {

    dispatch(fetchWooCommerceDataDetails())
    try {
        OMSAPI.post('/woocommerce/show', data)
            .then(function (response) {
                dispatch(fetchWooCommerceDataDetailsSuccess(response));
            }).catch(function (error) {
                dispatch(fetchWooCommerceDataDetailsFailure());
            })
    }
    catch (error) {
        dispatch(fetchWooCommerceDataDetailsFailure())
    }
}
export const fetchWoocommerceAppCheckUrl = authorizeUrl => dispatch => {
    const woocommerce_authorize_url = authorizeUrl;
        dispatch(getWoocommerceAppCheckUrl())
        try {
            OMSAPI.post('/woocommerce/checkurl', { woocommerce_authorize_url })
                .then(function (response) {
                    dispatch(getWoocommerceAppCheckUrlSuccess(response));
                }).catch(function (error) {
                    dispatch(getWoocommerceAppCheckUrlFailure());
                })
        }
        catch (error) {
            dispatch(getWoocommerceAppCheckUrlFailure())
        }
    }

export const resetConfigureState = data => dispatch => {
    dispatch(resetConfigureStateDetails());
}

export const resetWoocommerceState = data => dispatch => {
    dispatch(resetWoocommerceStateDetails());
}
export default woocommerceslice.reducer;
