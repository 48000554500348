import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import InputAdornment from '@mui/material/InputAdornment';
import { IconButton } from '@mui/material';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useForm } from '../../../pages/General/useForm';

import { updatePassword } from './passwordSlice';

const PasswordPage = () => {
    const dispatch = useDispatch();

    const password = useSelector(state => state.password);    
    const [alert, setAlert] = useState(false);
    const [open, setOpen] = useState(false);
    const [checkFormSubmit, setCheckFormSubmit] = useState(false);
    const [visible, SetVisible] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);      
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    const handleClickshowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
    const handleMouseDownshowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
    const handleClickshowCurrentPassword = () => setShowCurrentPassword(!showCurrentPassword);
    const handleMouseDownshowCurrentPassword = () => setShowCurrentPassword(!showCurrentPassword);
    const initialFValues = {
        current_password: '',
        password: '',
        confirm_password: ''
    }

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('current_password' in fieldValues)
            temp.current_password = fieldValues.current_password ? "" : "This field is required."
        if ('password' in fieldValues)
            temp.password = fieldValues.password ? "" : "This field is required."
        if ('confirm_password' in fieldValues)
            temp.confirm_password = fieldValues.confirm_password ? "" : "This field is required."

        setErrors({
            ...temp
        })

        if (fieldValues == values)
            return Object.values(temp).every(x => x == "")
    }

    const handleClose = () => {
        setOpen(false)
    };

    const handleSubmit = e => {
        e.preventDefault()
        if (validate()) {
            setCheckFormSubmit(true)
            console.log(values)
            dispatch(updatePassword(values))
        }
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange
    } = useForm(initialFValues, validate, true);

    useEffect(() => {  
        
        if (checkFormSubmit && password && password.hasErrors===false) {
            setValues({
                ...values,
                current_password: "",            
                password: "",
                confirm_password: "",
            })
        }
        // 
        if (checkFormSubmit && password && password.message) {
            setAlert(true)
            setOpen(true)
        }
    }, [password]);

    return (
        <div className="sc--settings--card">
            {password && password.loading === true &&
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={true}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            }
            {password && password.loading === false && alert &&
                <Snackbar
                    open={open}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    autoHideDuration={6000}
                    onClose={handleClose}
                >
                    <Alert
                        severity={password.hasErrors == true ? "error" : "success"}
                        style={{ justifyContent: 'center', margin: "10px 0" }}>
                        <span>{password.message}</span>
                    </Alert>
                </Snackbar>
            }
            {password && password.loading === false &&
                <Card sx={{ minWidth: 1000, boxShadow: 'unset' }} className="sc--cardhead--details">
                    <CardHeader title='Change Password' className="sc--cardhead--details" />
                    <Divider />
                    <CardContent>
                        <div className="sc--form--center">
                            <TextField
                                label='Current Password'
                                size='small'
                                required type={showCurrentPassword ? "text" : "password"}
                                name="current_password"
                                value={values.current_password}
                                variant="standard"
                                style={{ width: 350, margin: '8px' }}
                                onChange={handleInputChange}
                                error={!!errors.current_password}
                                helperText={errors.current_password}
                                InputProps={{ // <-- This is where the toggle button is added.
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickshowCurrentPassword}
                                                onMouseDown={handleMouseDownshowCurrentPassword}
                                            >
                                                {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }} />
                        </div>
                        <div className="sc--form--center">
                            <TextField
                                label='New Password'
                                size='small'
                                name="password"
                                required type={showPassword ? "text" : "password"}
                                value={values.password}
                                onChange={handleInputChange}
                                error={!!errors.password}
                                variant="standard"
                                style={{ width: 350, margin: '8px' }}
                                helperText={errors.password}
                                InputProps={{ // <-- This is where the toggle button is added.
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }} />
                        </div>
                        <div className="sc--form--center">
                            <TextField
                                label='Confirm Password'
                                size='small'
                                name="confirm_password"
                                required type={showConfirmPassword ? "text" : "password"}
                                value={values.confirm_password}
                                onChange={handleInputChange}
                                error={!!errors.confirm_password}                                
                                helperText={errors.confirm_password}
                                variant="standard"
                                style={{ width: 350, margin: '8px' }}
                                InputProps={{ // <-- This is where the toggle button is added.
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickshowConfirmPassword}
                                                onMouseDown={handleMouseDownshowConfirmPassword}
                                            >
                                                {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }} />
                        </div>
                        <div className="sc--form--center" style={{ margin: '15px 0' }}>
                            <Button variant="contained" onClick={handleSubmit} >update</Button> </div>
                    </CardContent>
                </Card>
            }
        </div>
    )
}


export default PasswordPage;
