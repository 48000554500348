import { createSlice } from '@reduxjs/toolkit';
import { LABELGENAPI } from '../../API';
import axios from 'axios';

/* canceling previous requests if multiple requests is triggered */
const CancelToken = axios.CancelToken;
let source = CancelToken.source();

export const orderdetailsslice = createSlice({
    name: 'orderdetails',
    initialState: {
        loading: false,
        data : {},
        notification: { show: false, messageContent: "", messageType: "success" }
    },
    reducers: {
        getOrderDetail: (state, action) => {
            return {
                ...state,
                loading: true
            }
        },
        getOrderDetailSuccess: (state, action) => {
            return {
                ...state,
                loading: false,
                data: action && action.payload && action.payload.data && action.payload.data.data,
            }
        },
        getOrderDetailFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                notification: { show: true, messageContent: action.data && action.data.message, messageType: "error" }
            }
        },
        getRefreshOrderDetail: (state, action) => {
            return {
                ...state,
                loading: true
            }
        },
        getRefreshOrderDetailSuccess: (state, action) => {
            return {
                ...state,
                loading: false,
                data: action && action.payload && action.payload.data && action.payload.data.data,
            }
        },
        getRefreshOrderDetailFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                notification: { show: true, messageContent: action.data && action.data.message, messageType: "error" }
            }
        },
    }
})

export const { getOrderDetail, getOrderDetailSuccess, getOrderDetailFailure } = orderdetailsslice.actions;

export const fetchOrderDetail = (orderId, storeId) => {
    let url = '/orders/details/'+orderId;
    if(storeId != undefined){
        url += '?storeid='+storeId;
    }
    return (dispatch) => {
        dispatch(getOrderDetail());
        try {
            LABELGENAPI
                .get(url)
                .then(function (response) {
                    dispatch(getOrderDetailSuccess(response));
                })
                .catch(function (error) {
                    dispatch(getOrderDetailFailure(error.response));
                });
        } catch (error) {
            dispatch(getOrderDetailFailure());
        }
    };
};


export const { getRefreshOrderDetail, getRefreshOrderDetailSuccess, getRefreshOrderDetailFailure } = orderdetailsslice.actions;

export const fetchRefreshOrderDetail = (orderId) => {
    return dispatch => {
        dispatch(getRefreshOrderDetail());
        try {
            LABELGENAPI.get('/refresh-order/' + orderId)
                .then(function (response) {
                    dispatch(getRefreshOrderDetailSuccess(response));
                    dispatch(fetchOrderDetail(orderId));
                })
                .catch(function (error) {
                    dispatch(getRefreshOrderDetailFailure(error));
                });
        } catch (error) {
            dispatch(getRefreshOrderDetailFailure(error));
        }
    };
};


export default orderdetailsslice.reducer;