import { createSlice } from '@reduxjs/toolkit';
import { LABELINGCHIMP_SAM_API, LABELGENAPI, LABELINGCHIMP_LABEL_API } from '../../API';
import axios from 'axios';

/* canceling previous requests if multiple requests is triggered */
const CancelToken = axios.CancelToken;
let source = CancelToken.source();

export const bulkorderslice = createSlice({
    name: 'bulkorder',
    initialState: {
        uploadorder: {
            order: {},
            notification: { show: false, messageContent: "", messageType: "success" },
            loading: false,
            hasErrors: false,
        },
        bulkorderdata: {
            data: [],
            meta: [],
            loading: false,
            hasErrors: false,
            notification: { show: false, messageContent: "", messageType: "success" },
        },
        bulkorderdetails: {
            data: [],
            meta: [],
            loading: false,
            hasErrors: false
        },
        successordersdetails: {
            data: [],
            meta: {
                sorting: JSON.stringify([{
                    field: 'order.date',
                    sort: 'desc',
                }])
            },
            loading: false,
            hasErrors: false
        },
        failureordersdetails: {
            data: [],
            meta: {
                sorting: JSON.stringify([{
                    field: 'order.date',
                    sort: 'desc',
                }])
            },
            loading: false,
            hasErrors: false
        }
    },
    reducers: {
        postBulkOrderFile: (state) => {
            return {
                ...state,
                uploadorder: {
                    ...state.uploadorder,
                    loading: true,
                    hasErrors: false,
                    order: {},
                    notification: { show: false, messageContent: "", messageType: "success" }
                },
            }
        },
        postBulkOrderFileSuccess: (state, action) => {
            return {
                ...state,
                uploadorder: {
                    ...state.uploadorder,
                    loading: false,
                    hasErrors: false,
                    order: action && action.payload && action.payload.data,
                    notification: { show: true, messageContent: action && action.payload && action.payload.data && action.payload.data.message, messageType: "success" }
                },
            }
        },
        postBulkOrderFileFailure: (state, action) => {
            return {
                ...state,
                uploadorder: {
                    ...state.uploadorder,
                    loading: false,
                    hasErrors: true,
                    order: {},
                    notification: { show: true, messageContent: action && action.payload && action.payload.data && action.payload.data.message, messageType: "error" }
                },
            }
        },
        fetchBulkOrderReferenceData: (state) => {
            return {
                ...state,
                bulkorderdata: {
                    ...state.bulkorderdata,
                    data: [],
                    loading: true,
                    notification: { show: false, messageContent: "", messageType: "success" }
                },
            }
        },
        fetchBulkOrderReferenceDataSuccess: (state, action) => {
            return {
                ...state,
                bulkorderdata: {
                    ...state.bulkorderdata,
                    data: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.data,
                    meta: action && action.payload && action.payload.data && action.payload.data.data,
                    loading: false,
                    hasErrors: false,
                },
            }
        },
        fetchBulkOrderReferenceDataFailure: (state, action) => {
            return {
                ...state,
                bulkorderdata: {
                    ...state.bulkorderdata,
                    loading: false,
                    hasErrors: true,
                    data: [],
                    meta: [],
                    notification: { show: true, messageContent: action && action.payload && action.payload.data && action.payload.data.message, messageType: "error" }
                },
            }
        },
        fetchBulkOrderDetailData: (state) => {
            return {
                ...state,
                bulkorderdetails: {
                    ...state.bulkorderdetails,
                    loading: true,
                    hasErrors: false
                }

            }
        },
        fetchBulkOrderDetailDataSuccess: (state, action) => {
            return {
                ...state,
                bulkorderdetails: {
                    ...state.bulkorderdetails,
                    loading: false,
                    hasErrors: false,
                    data: action && action.payload && action.payload.data && action.payload.data.data,
                    meta: action && action.payload && action.payload.data && action.payload.data.meta,
                },
            }
        },
        fetchBulkOrderDetailDataFailure: (state, action) => {
            return {
                ...state,
                bulkorderdetails: {
                    ...state.bulkorderdetails,
                    loading: false,
                    hasErrors: true,
                    data: [],
                    meta: [],
                },
            }
        },
        fetchSuccessOrderDetailData: (state) => {
            return {
                ...state,
                successordersdetails: {
                    ...state.successordersdetails,
                    loading: true,
                    hasErrors: false
                }

            }
        },
        fetchSuccessOrderDetailDataSuccess: (state, action) => {
            return {
                ...state,
                successordersdetails: {
                    ...state.successordersdetails,
                    loading: false,
                    hasErrors: false,
                    data: action && action.payload && action.payload.data && action.payload.data.data,
                    meta: action && action.payload && action.payload.data && action.payload.data.meta,
                },
            }
        },
        fetchSuccessOrderDetailDataFailure: (state, action) => {
            return {
                ...state,
                successordersdetails: {
                    ...state.successordersdetails,
                    loading: false,
                    hasErrors: true,
                    data: [],
                },
            }
        },
        fetchFailedOrderDetailData: (state) => {
            return {
                ...state,
                failureordersdetails: {
                    ...state.failureordersdetails,
                    loading: true,
                    hasErrors: false
                }

            }
        },
        fetchFailedOrderDetailDataSuccess: (state, action) => {
            return {
                ...state,
                failureordersdetails: {
                    ...state.failureordersdetails,
                    loading: false,
                    hasErrors: false,
                    data: action && action.payload && action.payload.data && action.payload.data.data,
                    meta: action && action.payload && action.payload.data && action.payload.data.meta,
                },
            }
        },
        fetchFailedOrderDetailDataFailure: (state, action) => {
            return {
                ...state,
                failureordersdetails: {
                    ...state.failureordersdetails,
                    loading: false,
                    hasErrors: true,
                    data: [],
                    meta: [],
                },
            }
        },


        

        resetBulkOrdersData: (state, action) => {
            return {
                ...state,
                uploadorder: {
                    order: {},
                    notification: { show: false, messageContent: "", messageType: "success" },
                    loading: false,
                    hasErrors: false,
                },
                bulkorderdetails: {
                    data: [],
                    meta: [],
                    loading: false,
                    hasErrors: false
                },
            }
        },
        resetBulkOrderNotification: (state, action) => {
            return {
                ...state,
                bulkorderdata: {
                    ...state.bulkorderdata,
                    notification: { show: false, messageContent: "", messageType: "success" }
                }
            }
        }
    }
})
export const { postBulkOrderFile, postBulkOrderFileSuccess, postBulkOrderFileFailure, fetchBulkOrderReferenceData,
    fetchBulkOrderReferenceDataSuccess, fetchBulkOrderReferenceDataFailure, fetchBulkOrderDetailData, fetchBulkOrderDetailDataSuccess,
    fetchBulkOrderDetailDataFailure, fetchSuccessOrderDetailData, fetchSuccessOrderDetailDataSuccess, fetchSuccessOrderDetailDataFailure,
    fetchFailedOrderDetailData, fetchFailedOrderDetailDataSuccess, fetchFailedOrderDetailDataFailure, resetBulkOrdersData, resetBulkOrderNotification } = bulkorderslice.actions;


export const uploadBulkOrder = (data, apikey, uploadType,notes) => dispatch => {
    dispatch(postBulkOrderFile());
    try {
        LABELINGCHIMP_SAM_API.post('/bulk-create-shipment-upload?request_medium=UI&upload_type='+uploadType+'&notes='+notes, data, {
            crossDomain: true,
            headers: {
                "x-api-key": apikey,
                'content-type': 'multipart/form-data'
            }
        }).then(function (response) {
            dispatch(postBulkOrderFileSuccess(response));
        }).catch(function (error) {
            dispatch(postBulkOrderFileFailure(error.response));
        })
    }
    catch (error) {
        dispatch(postBulkOrderFileFailure(error.response));
    }

}

export const fetchBulkOrderReference = (meta) => {
    const qs = Object.keys(meta)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(meta[key])}`)
        .join('&');

    return (dispatch) => {
        dispatch(fetchBulkOrderReferenceData());
        try {
            LABELGENAPI
                .get('/bulk-shipments/info?' + qs)
                .then(function (response) {
                    dispatch(fetchBulkOrderReferenceDataSuccess(response));
                })
                .catch(function (error) {
                    dispatch(fetchBulkOrderReferenceDataFailure(error.response));
                });
        } catch (error) {
            dispatch(fetchBulkOrderReferenceDataFailure());
        }
    };
};

export const fetchBulkOrderDetail = (data) => {
    return (dispatch) => {
        dispatch(fetchBulkOrderDetailData());
        try {
            LABELGENAPI
                .get('/bulk-shipments/info?reference_id=' + data)
                .then(function (response) {
                    dispatch(fetchBulkOrderDetailDataSuccess(response));
                })
                .catch(function (error) {
                    dispatch(fetchBulkOrderDetailDataFailure(error.response));
                });
        } catch (error) {
            dispatch(fetchBulkOrderDetailDataFailure());
        }
    };
};
export const fetchSuccessOrderDetail = (meta) => {
    const qs = Object.keys(meta)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(meta[key])}`)
        .join('&');
    return (dispatch) => {
        dispatch(fetchSuccessOrderDetailData());
        try {
            LABELGENAPI
                .get('/bulk-shipments/shipment-details?shipment_status=success&' + qs)
                .then(function (response) {
                    dispatch(fetchSuccessOrderDetailDataSuccess(response));
                })
                .catch(function (error) {
                    dispatch(fetchSuccessOrderDetailDataFailure(error.response));
                });
        } catch (error) {
            dispatch(fetchSuccessOrderDetailDataFailure());
        }
    };
};
export const fetchFailedOrderDetail = (meta) => {
    const qs = Object.keys(meta)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(meta[key])}`)
        .join('&');
    return (dispatch) => {
        dispatch(fetchFailedOrderDetailData());
        try {
            LABELGENAPI
                .get('/bulk-shipments/shipment-details?shipment_status=failed&' + qs)
                .then(function (response) {
                    dispatch(fetchFailedOrderDetailDataSuccess(response));
                })
                .catch(function (error) {
                    dispatch(fetchFailedOrderDetailDataFailure(error.response));
                });
        } catch (error) {
            dispatch(fetchFailedOrderDetailDataFailure());
        }
    };
};




export const resetBulkOrders = data => dispatch => {
    dispatch(resetBulkOrdersData());
}

export const resetBulkOrderNotifications = () => {
    return (dispatch) => {
        dispatch(resetBulkOrderNotification());
    }
}

export default bulkorderslice.reducer;
