import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Paper from '@mui/material/Paper';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import FormHelperText from '@mui/material/FormHelperText';
import { useHistory } from "react-router-dom";

import { fetchShopifyData, fetchLocation, configureStore, resetConfigureState } from './shopifyappSlice';
import ShipmentUpdateFlag from '../../../../General/ShipmentUpdateFlag';

import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    height: '100%',
    padding: '20px 0'
}));

const ShopifyIntegration = ({ visible, stepNumber, platform, storeName, storeId, formDataValues, onClose }) => {
    const params = new URLSearchParams(window.location.search)
    let storeParam = params.get('store')
    const dispatch = useDispatch();
    const configure = useSelector(state => state.shopifyapp.configure);
    const location = useSelector(state => state.shopifyapp && state.shopifyapp.progress && state.shopifyapp.progress.location);
    const progress = useSelector(state => state.shopifyapp && state.shopifyapp.progress && state.shopifyapp.progress);
    const profile = useSelector(state => state.auth);
    const [alert, setAlert] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const [storeDetails, setStoreDetails] = useState("")
    const [locationId, setLocationId] = useState("")
    const [values, setValues] = useState(progress.data)
    const [errors, setErrors] = useState({});

    const handleClose = () => {
        setSnackOpen(false);
        dispatch(resetConfigureState())
        if (storeParam) {
            window.location.href = window.location.origin + "/integration/marketplace"
        }
    };

    const validator = (fieldValues) => {
        let temp = { ...errors }
        if ('selected_location_id' in fieldValues)
            temp.selected_location_id = fieldValues.selected_location_id ? "" : "This field is required."

        setErrors({
            ...temp
        })

        if (fieldValues == values) {
            return Object.values(temp).every(x => x == "")
        }
    }

    const onLocationChange = (id) => {
        setLocationId(id)
        setValues({
            ...values,
            ['selected_location_id']: id,
        })
    }

    const updateCallBackValue = (updateValue) => {
        setValues({
            ...values,
            ['is_update_shipment']: updateValue.is_update_shipment,
            ['is_update_shipment_on_create']: updateValue.is_update_shipment_on_create,
            ['is_update_shipment_on_close']: updateValue.is_update_shipment_on_close,
        });
    };

    const configurestore = e => {
        e.preventDefault()
        const isValid = validator(values)
        let storeLocationDetails = {}
        if (isValid) {
            if (storeName) {
                setStoreDetails(storeName)
            } else {
                setStoreDetails(storeParam)
            }
            location && location.map((item) => {
                if (values.selected_location_id === item.id) {
                    storeLocationDetails = item
                }
            })
            const store = { "store": storeDetails, 'selected_location': storeLocationDetails }
            const data = Object.assign(values, store);
            dispatch(configureStore(data))
            dispatch(fetchLocation(data));
        }
    }

    useEffect(() => {
        let apikey = profile.profile.api_token
        const data = { apikey: apikey, store_name: storeName, store_id: storeId }
        dispatch(fetchShopifyData(data));
    }, [dispatch]);

    useEffect(() => {
        if (storeParam) {
            setStoreDetails(storeParam)
            const data = {
                store: storeParam
            }
            dispatch(fetchLocation(data));
        } else if (storeName) {
            setStoreDetails(storeName)
        }
    }, []);

    useEffect(() => {
        if (configure && configure.message) {
            setAlert(true)
            setSnackOpen(true)
        } else if (configure && configure.hasErrors == true) {
            setAlert(true)
            setSnackOpen(true)
        }
    }, [dispatch, configure && configure.message])


    useEffect(() => {
        if (progress && progress.data && Object.keys(progress.data).length !== 0) {
            location && location.map((item) => {
                if (item.default === true) {
                    setLocationId(item.id)
                    setValues({
                        ...values,
                        ['selected_location_id']: item.id,
                        ['is_update_shipment']: progress && progress.data && progress.data.is_update_shipment,
                        ['is_update_shipment_on_create']: progress && progress.data && progress.data.is_update_shipment_on_create,
                        ['is_update_shipment_on_close']: progress && progress.data && progress.data.is_update_shipment_on_close,
                    })
                }
            })
        }
    }, [progress && progress.data])

    return (
        <>
            {/* {(shopifyapplocation && shopifyapplocation.loading === true) && <>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={true}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </>
            } */}
            {alert &&
                <Snackbar
                    open={snackOpen}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    autoHideDuration={6000}
                    onClose={handleClose}
                >
                    <Alert variant="filled" severity={configure && configure.hasErrors === true ? "error" : "success"}
                        style={{ justifyContent: 'center', margin: "10px 0" }}
                    >
                        <span > {configure && configure.message}</span>
                    </Alert>
                </Snackbar>
            }
            {(progress && progress.loading === false) && <>

                {!storeDetails &&
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={12} style={{ textAlign: 'center' }}>
                            <Item>
                                <div style={{ marginBottom: '20px' }}>
                                    <img
                                        src="/assets/images/integration/shopify-1.png"
                                        alt="Shopify"
                                        style={{ margin: 'auto', display: 'flex' }} />
                                    <Button
                                        variant="contained"
                                        href="https://apps.shopify.com/shippingchimp-2?surface_intra_position=1&surface_type=partners&surface_version=redesign"
                                        target="_blank"
                                    >Install Shopify App
                                    </Button>
                                </div>
                            </Item>
                        </Grid>
                    </Grid>
                }
                {storeDetails &&
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={12}>
                            <Item>
                                <div
                                    className="sc--form--center"
                                    style={{ margin: 'auto', textAlign: 'center', width: '30%' }}>
                                    <TextField
                                        id="standard-numbe"
                                        label="Shopify Store"
                                        value={storeDetails}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        size="small"
                                        style={{ width: "100%", margin: '8px' }}
                                        variant="filled"
                                    />
                                </div>

                                <div
                                    className="sc--form--center"
                                    style={{ margin: 'auto', textAlign: 'center', width: '30%' }}
                                >
                                    <FormControl
                                        variant="standard"
                                        style={{ width: '100%', margin: '15px', textAlign: 'left' }}
                                    >
                                        <InputLabel
                                            id="demo-simple-select-standard-label"
                                        > Shopfiy Store Location
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            name="selected_location_id"
                                            value={locationId}
                                            error={errors.selected_location_id}
                                            onChange={e => {
                                                onLocationChange(e.target.value)
                                                setErrors({ "selected_location_id": "" })
                                            }}
                                            label="Shopfiy Store Location"
                                            id="filled-error-helper-text"
                                        >
                                            {location && location.map((item) => {
                                                return <MenuItem value={item.id}>
                                                    <p style={{ margin: 'unset' }}>{item.name} ({item.id})<br />
                                                        {item.province}, {item.city}, {item.province_code}, {item.country} - {item.zip}
                                                    </p>
                                                </MenuItem>
                                            })}

                                        </Select>
                                        <FormHelperText style={{ color: '#d32f2f' }}>{errors.selected_location_id}</FormHelperText>
                                    </FormControl>
                                </div>
                                <ShipmentUpdateFlag
                                    updateFlag={values.is_update_shipment}
                                    onCreateFlag={values.is_update_shipment_on_create}
                                    onCloseFlag={values.is_update_shipment_on_close}
                                    updateCallBack={updateCallBackValue}
                                    message={"Do you want to update shipment information to shopify store?"}
                                />
                                <div
                                    className="sc--form--center"
                                    style={{ margin: '10px 0', textAlign: 'center' }}
                                >
                                    <Button
                                        variant="contained"
                                        onClick={configurestore}
                                    >Configure
                                    </Button>
                                </div>
                            </Item>
                        </Grid>
                    </Grid>
                }
            </>
            }
        </>
    )
}

export default ShopifyIntegration;