import { createSlice } from '@reduxjs/toolkit';
import { LABELGENAPI, LABELINGCHIMP_SAM_API, LABELINGCHIMP_LABEL_API } from '../../API';
import axios from 'axios';

/* canceling previous requests if multiple requests is triggered */
const CancelToken = axios.CancelToken;
let source = CancelToken.source();

export const groupedmanifestsslice = createSlice({
    name: 'groupedmanifests',
    initialState: {
        data: [],
        message: [],
        meta: {
            groupId: "",
            carrierName: "",
            manifestStatus: "",
            date: "",
            manifestNumber: "",
            page: 1,
            per_page: 10
        },
        loading: false,
        notification: { show: false, messageContent: "", messageType: "success" }
    },
    reducers: {
        getManifest: (state) => {
            return {
                ...state,
                data: [],
                loading: true
            }
        },
        getManifestSuccess: (state, action) => {
            return {
                ...state,
                data: action && action.payload && action.payload.data && action.payload.data.data,
                meta: action && action.payload && action.payload.data && action.payload.data.meta,
                loading: false
            }
        },
        getManifestFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                notification: { show: true, messageContent: action.payload && action.payload.data && action.payload.data.message && [action.payload.data.message], messageType: "error" }
            }
        },
        doGenerateManifest: (state) => {
            return {
                ...state,
                loading: true
            }
        },
        generateManifestSuccess: (state, action) => {
            return {
                ...state,
                loading: false,
                notification: { show: true, messageContent: (action.payload && action.payload.data && action.payload.data.desc && action.payload.data.desc === "success") ? ["Manifest Close Triggered Successfully"] : [""], messageType: "success" }
            }
        },
        generateManifestFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                notification: { show: true, messageContent: action.payload && action.payload.data && action.payload.data.message && [action.payload.data.message], messageType: "error" }
            }
        },
        resetNotification: (state, action) => {
            return {
                ...state,
                notification: { show: false, messageContent: "", messageType: "success" }
            }
        }


    }
})

export const { getManifest, getManifestSuccess, getManifestFailure } = groupedmanifestsslice.actions;

export const fetchManifest = (meta) => {

    return (dispatch) => {
        const qs = Object.keys(meta)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(meta[key])}`)
            .join('&');

        dispatch(getManifest());
        try {
            LABELGENAPI
                .get('/manifest/open?' + qs)
                .then(function (response) {
                    dispatch(getManifestSuccess(response));
                })
                .catch(function (error) {
                    dispatch(getManifestFailure(error.response));
                });
        } catch (error) {
            dispatch(getManifestFailure());
        }
    }
};

export const { doGenerateManifest, generateManifestSuccess, generateManifestFailure } = groupedmanifestsslice.actions;

export const generateManifest = (meta, id, apikey) => {
    return (dispatch) => {
        dispatch(doGenerateManifest());
        try {
            let data = {
                "group_ids": [
                    id
                ],
                "ignore_errors": true
            }
            LABELINGCHIMP_SAM_API.post('/close-manifest', data, {
                crossDomain: true,
                headers: {
                    "x-api-key": apikey
                }
            }).then(function (response) {
                dispatch(generateManifestSuccess(response));
                dispatch(fetchManifest(meta));
            })
                .catch(function (error) {
                    dispatch(generateManifestFailure(error.response));
                    dispatch(fetchManifest(meta));
                });
        } catch (error) {
            dispatch(generateManifestFailure());
        }
    }
};


export const { resetNotification } = groupedmanifestsslice.actions;

export const resetGenerateManifestNotification = () => {
    return (dispatch) => {
        dispatch(resetNotification());
    }
}

export default groupedmanifestsslice.reducer;
