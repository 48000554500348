import React from 'react';
import { Link, Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ProfilePage from '../Settings/Profile/ProfilePage';
import ProductSettingsPage from '../Settings/ProductSettings/ProductSettingsPage';
import AddressBillingPage from '../Settings/AddressBilling/AddressBillingPage';
import AddressSenderPage from '../Settings/AddressSender/AddressSenderPage';
import AddressRecipientPage from '../Settings/AddressRecipient/AddressRecipientPage';
import PasswordPage from '../Settings/Password/PasswordPage';
import WebTokenPage from '../Settings/Api/WebTokenPage';
import PaymentPage from '../Settings/Payment/PaymentPage';
import BillingPage from '../Settings/Billing/BillingPage';
import InvoicePage from '../Settings/Invoice/InvoicePage';
import BoxPage from "../Settings/Box/BoxPage";
import LabelSettingsPage from "../Settings/LabelSettings/LabelSettingsPage";
import TagSettingsPage from "../Settings/TagSettings/TagSettingsPage";
import TimezoneSettingsPage from "../Settings/TimezoneSettings/TimezoneSettingsPage";
import ManifestSettingsPage from "../Settings/ManifestSettings/ManifestSettingsPage";
import PickupSettingsPage from "../Settings/PickupSettings/PickupSettingsPage";
import PickupAddressPage from "../Settings/PickupAddress/PickupAddressPage";
import NotFoundPage from '../../pages/Auth/NotFoundPage';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import KeyIcon from '@mui/icons-material/Key';
import PaymentIcon from '@mui/icons-material/Payment';
import ReceiptIcon from '@mui/icons-material/Receipt';
import LockClockOutlinedIcon from '@mui/icons-material/LockClockOutlined';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import LabelIcon from '@mui/icons-material/Label';
import TagOutlinedIcon from '@mui/icons-material/TagOutlined';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
const SettingsPage = () => {

    const locations = useLocation();
    const currentPageActive = locations.pathname.replace('/', '');;
    const profile = useSelector(state => state.auth);
    return (
        <div className="scmaincard">
            <div className="sccardhead">
                <Typography variant="h5" gutterBottom component="div">
                    Settings
            </Typography>               
            </div>
            <div className="sc--main-content--body">
                <Grid container spacing={2} className="sc--settings--menu">
                    <Grid item xs={12} sm={12} md={12} lg={2}>
                        <Paper variant="outlined" sx={{ maxWidth: '100%', height: 'auto' }}>
                            <List>
                                <ListItem button component={Link} to="profile" selected={currentPageActive === "settings/profile" ? true : false}>
                                    <ListItemIcon>
                                        <AccountCircleIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Profile" />
                                </ListItem>
                                <ListItem button component={Link} to="/settings/productsettings" selected={currentPageActive === "settings/productsettings" ? true : false}>
                                    <ListItemIcon>
                                        <SettingsApplicationsIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Product Settings" />
                                </ListItem>
                                <ListItem button component={Link} to="password" selected={currentPageActive === "settings/password" ? true : false}>
                                    <ListItemIcon>
                                        <LockClockOutlinedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Password" />
                                </ListItem>
                                {(profile && profile.profile && (profile.profile.id == profile.profile.parent_id)) && <>
                                <ListItem button component={Link} to="/settings/address-billing" selected={currentPageActive === "settings/address-billing" ? true : false}>
                                    <ListItemIcon>
                                        <AccountBalanceWalletIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Billing Address" />
                                </ListItem>
                                <ListItem button component={Link} to="/settings/address-sender" selected={currentPageActive === "settings/address-sender" ? true : false}>
                                    <ListItemIcon>
                                        <ForwardToInboxIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Sender Address" />
                                </ListItem>
                                <ListItem button component={Link} to="/settings/address-recipient" selected={currentPageActive === "settings/address-recipient" ? true : false}>
                                    <ListItemIcon>
                                        <ContactMailIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Recipient Address" />
                                </ListItem>
                                <ListItem button component={Link} to="/settings/box" selected={currentPageActive === "settings/box" ? true : false}>
                                    <ListItemIcon>
                                        <AllInboxIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Box Dimensions" />
                                </ListItem>
                                {/* <ListItem button component={Link} to="/settings/pickup-address" selected={currentPageActive === "settings/pickup-address" ? true : false}>
                                    <ListItemIcon>
                                        <LocalShippingOutlinedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Pickup Address" />
                                </ListItem> */}
                                <ListItem button component={Link} to="/settings/manifest-settings" selected={currentPageActive === "settings/manifest-settings" ? true : false}>
                                    <ListItemIcon>
                                        <AssignmentTurnedInOutlinedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Manifest Settings" />
                                </ListItem>
                                <ListItem button component={Link} to="/settings/pickup-settings" selected={currentPageActive === "settings/pickup-settings" ? true : false}>
                                    <ListItemIcon>
                                        <LocalShippingOutlinedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Pickup Settings" />
                                </ListItem>
                                <ListItem button component={Link} to="/settings/label-settings" selected={currentPageActive === "settings/label-settings" ? true : false}>
                                    <ListItemIcon>
                                        <LabelIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Label Settings" />
                                </ListItem>
                                <ListItem button component={Link} to="/settings/tag-settings" selected={currentPageActive === "settings/tag-settings" ? true : false}>
                                    <ListItemIcon>
                                        <TagOutlinedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Tag Settings" />
                                </ListItem>
                                <ListItem button component={Link} to="/settings/timezone-settings" selected={currentPageActive === "settings/timezone-settings" ? true : false}>
                                    <ListItemIcon>
                                        <AccessTimeOutlinedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Timezone Settings" />
                                </ListItem>
                                <ListItem button component={Link} to="/settings/token" selected={currentPageActive === "settings/token" ? true : false}>
                                    <ListItemIcon>
                                        <KeyIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="API Token" />
                                </ListItem>
                                <ListItem button component={Link} to="/settings/billing" selected={(currentPageActive === "settings/billing" || currentPageActive === "settings/invoices" || currentPageActive === "settings/payment") ? true : false}>
                                    <ListItemIcon>
                                        <PaymentIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Billing" />
                                </ListItem>
                                </>
                                }
                                {/* <ListItem button component={Link} to="/settings/payment" selected={currentPageActive === "settings/payment" ? true : false}>
                                    <ListItemIcon>
                                        <PaymentIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Payment" />
                                </ListItem>
                                <ListItem button component={Link} to="/settings/invoices" selected={currentPageActive === "settings/invoices" ? true : false}>
                                    <ListItemIcon>
                                        <ReceiptIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Invoices" />
                                </ListItem> */}
                            </List>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={10}>
                        <Paper variant="outlined" sx={{ height: '100%' }} >
                            <Switch>
                                <Redirect exact from="/settings" to="/settings/profile" />
                                <Route path='/settings/profile' component={ProfilePage} />
                                <Route path='/settings/password' component={PasswordPage} />
                                <Route path='/settings/address-billing' component={AddressBillingPage} />
                                <Route path='/settings/address-sender' component={AddressSenderPage} />
                                <Route path='/settings/address-recipient' component={AddressRecipientPage} />
                                <Route path='/settings/token' component={WebTokenPage} />
                                <Route path='/settings/label-settings' component={LabelSettingsPage} />
                                <Route path='/settings/box' component={BoxPage} />
                                <Route path='/settings/pickup-address' component={PickupAddressPage} />
                                <Route path='/settings/productsettings' component={ProductSettingsPage} />
                                <Route path='/settings/billing' component={BillingPage} />
                                <Route path='/settings/manifest-settings' component={ManifestSettingsPage} />
                                <Route path='/settings/pickup-settings' component={PickupSettingsPage} />
                                <Route path='/settings/tag-settings' component={TagSettingsPage} />
                                <Route path='/settings/timezone-settings' component={TimezoneSettingsPage} />
                                <Route path='/settings/payment' component={PaymentPage} />
                                <Route path='/settings/invoices' component={InvoicePage} />
                                <Route path='*' component={NotFoundPage} />
                            </Switch>
                        </Paper>
                    </Grid>

                </Grid>
            </div>
        </div >

    )
}


export default SettingsPage;
