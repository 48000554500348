import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// Our components
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Backdrop from '@mui/material/Backdrop';
import Snackbar from '@mui/material/Snackbar';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Loader from '../../../pages/General/Loader';
// slice
import { fetchSenderAddress } from '../../../pages/Settings/AddressSender/addressSenderSlice';
import { fetchCarrierDetail } from '../../../pages/Integration/Carrier/carrierCredentialsSlice';
import { generateQuickLabel } from './quickLabelSlice';

//Custom Components Import Ends
const ShowMessage = () => {
    return (
        <span style={{ color: "red", margin: "0 50px" }}>Please enable quick ship
            <span>
                <Link to="/carriercredentials"
                    style={{ color: "blue", paddingLeft: '5px' }}>
                    here
             </Link>
            </span>
        </span>
    )
}
const QuickShipmentsPage = () => {
    const dispatch = useDispatch();
    const quick = useSelector(state => state.quicklabel);

    const addresssender = useSelector(state => state.addresssender);
    const profile = useSelector(state => state.auth);
    const carrier = useSelector(state => state.carriercredentials);
    const [weight, setWeight] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [senderAddressObjectId, setSenderAddressObjectId] = useState('');
    const [referenceNo, setReferenceNo] = useState('');
    const [print, setPrint] = useState(false);
    const [alert, setAlert] = useState(false);
    const [myArray, setMyArray] = useState("");
    const [errors, setErrors] = useState(null);
    const [weighterrors, setweightErrors] = useState(null);
    const [postalCodeerrors, setPostalCodeErrors] = useState(null);
    const [open, setOpen] = React.useState(false);
    const [senderAddressError, setSenderAddressError] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        dispatch(fetchSenderAddress());
        dispatch(fetchCarrierDetail())
    }, [dispatch]);

    useEffect(() => {
        if (addresssender && addresssender.data) {
            addresssender.data.map((item) => {
                if (item && item.default) {
                    setSenderAddressObjectId(item._id)
                }
            })
        }
    }, [addresssender]);

    useEffect(() => {
        if (print && quick && quick.message && quick.message.label_carrier) {
            window.open(process.env.REACT_APP_API_DOMAIN + '/utility/pdf/print?q=' + quick.message.label_carrier, '_blank');
            setPrint(false);
            setPostalCode("");
        }
        if (quick && quick.message) {
            if (quick.hasErrors) {
                setAlert(true)
                setOpen(true)
                const data = quick.message.split(",");
                setMyArray(data)
            }
        }
    }, [quick, print]);

    const weightValidate = () => {
        if (weight == '') {
            setweightErrors("Weight is required");
        } else if (weight < 0) {
            setweightErrors("Weight is not a negative number");
        } else {
            setweightErrors(null);
        }
    };

    const postalcodeValidate = () => {
        if (postalCode.length < 5) {
            setPostalCodeErrors("Please check your postal code");
        } else {
            setPostalCodeErrors(null);
        }
    };
    const senderAddressObjectIdValidate = () => {
        if (senderAddressObjectId == '') {
            setSenderAddressError("Please check your postal code");
        } else {
            setSenderAddressError(null);
        }
    }


    const generatelabel = () => {
        let apikey = (profile.profile.api_token) ? profile.profile.api_token : "";
        const receiver_data = {
            "preferred_sender_id": senderAddressObjectId,
            "postal_code": postalCode,
            weight,
            "weight_unit": "kg",
            "email": profile.profile.email,
            "referenceno": referenceNo
        }
        if (weight == '' && postalCode == '' && senderAddressObjectId == '') {
            setweightErrors("Weight is required");
            setPostalCodeErrors("Postal code is required");
            setSenderAddressError(true);
        }
        else if (weight == '') {
            setweightErrors("Weight is required");
        }
        else if (postalCode == '') {
            setPostalCodeErrors("Postal code is required");
        }
        else if (postalCode.length < 5) {
            setPostalCodeErrors("Please check your postal code");
        }
        else if (senderAddressObjectId == '') {
            setSenderAddressError(true)
        }
        else {
            dispatch(generateQuickLabel(receiver_data, apikey));
        }
        setPrint(true);
    }


    return (
        <>
            {(quick.loading == true) && <>
                <Loader />
            </>
            }
            {(quick.loading == false) && <>
                <div className="scmaincard">
                    <div className="sccardhead">
                        <Typography variant="h5" gutterBottom component="div">
                            Quick Order {(carrier && carrier.quickship && carrier.quickship.length == 0) && (carrier && carrier.loading == false) &&
                                <ShowMessage />
                            }
                        </Typography>

                    </div>
                    <div className="sc--content--body">
                        <div className="sc--content--card" >
                            {alert &&
                                <Snackbar
                                    open={open}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "center"
                                    }}
                                    onClose={handleClose}
                                >
                                    <Alert  variant="filled" severity="error" style={{ justifyContent: 'center', margin: "10px 0" }}>
                                        <span >{quick && quick.message}</span>
                                    </Alert>
                                </Snackbar>
                            }
                            <Grid container spacing={2} className="sc--settings--menu">
                                <Grid item xs={6} md={6}>
                                    <Paper variant='outlined' style={{ height: '100%' }}>
                                        <Card sx={{ minWidth: 275, boxShadow: 'unset' }} className="sc--cardhead--details">
                                            <CardHeader title='Carrier Details' />
                                            <Divider />
                                            <CardContent>
                                                <Box
                                                    component="form"
                                                    sx={{
                                                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                                                    }}
                                                    noValidate
                                                    autoComplete="off"
                                                >
                                                    <div className="sc--form--center" style={{ margin: '10px 0', padding: "0 70px" }}>
                                                        <TextField
                                                            id="standard-numbe"
                                                            label="Carrier Credential"
                                                            value={carrier && carrier.quickship && carrier.quickship.name}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            size="small"
                                                            style={{ width: "100%", margin: '8px' }}
                                                            variant="filled"
                                                        />
                                                    </div>
                                                    <div className="sc--form--center" style={{ margin: '10px 0', padding: "0 70px" }}>
                                                        <TextField
                                                            id="standard-number"
                                                            label="Carrier Name"
                                                            value={carrier && carrier.quickship && carrier.quickship.carrier_name}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            size="small"
                                                            InputProps={{
                                                                readOnly: true,
                                                            }} variant="standard" style={{ width: "100%", margin: '8px' }}
                                                            variant="filled"
                                                        />
                                                    </div>
                                                    <div className="sc--form--center" style={{ margin: '10px 0', padding: "0 70px" }}>
                                                        <TextField
                                                            id="standard-basic"
                                                            label="Service Type"
                                                            defaultValue="Canada Post Expedited Parcel"
                                                            size="small"
                                                            InputProps={{
                                                                readOnly: true,
                                                            }} variant="filled" style={{ width: "100%", margin: '8px' }} />
                                                    </div>
                                                    <div className="sc--form--center" style={{ margin: '10px 0', padding: "0 70px" }}>
                                                        <TextField
                                                            id="standard-basic"
                                                            label="Receiver Country"
                                                            defaultValue="Canada (CA)"
                                                            size="small"
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            variant="filled"
                                                            style={{ width: "100%", margin: '8px' }} />
                                                    </div>

                                                </Box>
                                            </CardContent>
                                        </Card>
                                    </Paper>
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <Paper variant='outlined' style={{ height: '100%' }}>
                                        <Card
                                            sx={{ minWidth: 275, margin: '0px 0', boxShadow: 'unset' }}
                                            className="sc--cardhead--details">
                                            <CardHeader title='Shipment Details' />
                                            <Divider />
                                            <CardContent>
                                                <Box
                                                    component="form"
                                                    sx={{
                                                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                                                    }}
                                                    noValidate
                                                    autoComplete="off"
                                                >

                                                    <div className="sc--form--center" style={{ margin: '8px 0', padding: "0 70px" }}>
                                                        <FormControl variant="standard" style={{ width: "100%", margin: '8px' }} >
                                                            <InputLabel id="demo-simple-select-standard-label">Sender Address</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-standard-label"
                                                                id="demo-simple-select-standard"
                                                                value={senderAddressObjectId}
                                                                onChange={e => { setSenderAddressObjectId(e.target.value) }}
                                                                label="Sender Address"
                                                                onBlur={senderAddressObjectIdValidate}
                                                            >
                                                                {addresssender && addresssender.data && addresssender.data.map((item) => {
                                                                    return <MenuItem value={item._id}>
                                                                        <p style={{ margin: 'unset' }}>{item.sender_name} ({item.sender_company})<br />
                                                                            {item.sender_address}, {item.sender_city}, {item.sender_state_code}, {item.sender_country_code} - {item.sender_postal_code}
                                                                        </p>
                                                                    </MenuItem>
                                                                })}
                                                            </Select>
                                                            <FormHelperText>
                                                                {(addresssender && addresssender.loading == false) && addresssender.data && addresssender.data.length == 0 &&
                                                                    <span style={{ color: "red" }}>
                                                                        Please add sender address
                                                                   <Link to="/settings/address-sender" style={{ color: 'blue', textDecoration: 'none' }}> here </Link>
                                                                    </span>
                                                                }
                                                                {senderAddressError && addresssender && addresssender.data
                                                                    && addresssender.data.length !== 0 &&
                                                                    <span style={{ color: "red" }}>
                                                                        This field is required.
                                                            </span>
                                                                }
                                                            </FormHelperText>
                                                        </FormControl>
                                                    </div>

                                                    <div className="sc--form--center" style={{ margin: '5px 0', padding: "0 70px" }}>
                                                        <TextField
                                                            type='number'
                                                            onInput={e => setWeight(e.target.value)}
                                                            label="Weight"
                                                            value={weight}
                                                            variant="standard"
                                                            inputProps={{ min: 1 }}
                                                            onChange={(event) =>
                                                                event.target.value < 0
                                                                    ? (event.target.value = 0)
                                                                    : event.target.value
                                                            }
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start">kg</InputAdornment>,
                                                            }}
                                                            onBlur={weightValidate}
                                                            onKeyUp={(event) => {
                                                                if (event.key == 'Enter')
                                                                    generatelabel()
                                                            }}
                                                            autoFocus={weight === ''}
                                                            style={{ width: "100%", margin: '8px' }}
                                                        />

                                                    </div>
                                                    {weighterrors &&
                                                        <div style={{ margin: 'unset', padding: "0 80px" }}>
                                                            <span style={{ color: 'red', fontSize: '12px' }}>
                                                                {weighterrors}
                                                            </span>
                                                        </div>
                                                    }

                                                    <div className="sc--form--center" style={{ margin: '5px 0', padding: "0 70px" }}>
                                                        <TextField
                                                            label="Receiver Postal Code"
                                                            onInput={e => setPostalCode(e.target.value)}
                                                            value={postalCode}
                                                            inputProps={{ maxLength: 6 }}
                                                            variant="standard"
                                                            style={{ width: "100%", margin: '8px' }}
                                                            onChange={postalcodeValidate}
                                                            onPaste={e => {
                                                                let postcode = e.clipboardData.getData('Text')
                                                                setPostalCode(postcode)
                                                                if (postcode.length < 5) {
                                                                    setPostalCodeErrors("Please check your postal code");
                                                                } else {
                                                                    setPostalCodeErrors(null);
                                                                }

                                                            }
                                                            }
                                                            autoFocus={weight !== ''}

                                                            onKeyUp={(event) => {
                                                                if (event.key == 'Enter')
                                                                    generatelabel()
                                                            }}
                                                        />
                                                    </div>

                                                    {postalCodeerrors &&
                                                        <div style={{ margin: 'unset', padding: "0 80px" }}>
                                                            <span style={{ color: 'red', fontSize: '12px' }}>{postalCodeerrors}</span>
                                                        </div>
                                                    }
                                                    <div className="sc--form--center" style={{ margin: '5px 0', padding: "0 70px" }}>
                                                        <TextField
                                                            label="Reference Number"
                                                            variant="standard"
                                                            style={{ width: "100%", margin: '8px' }}
                                                            onInput={e => setReferenceNo(e.target.value)}
                                                            onKeyUp={(event) => {
                                                                if (event.key == 'Enter')
                                                                    generatelabel()
                                                            }}
                                                        />
                                                    </div>
                                                </Box>
                                            </CardContent>
                                        </Card>
                                    </Paper>
                                </Grid>
                            </Grid>
                            <Grid style={{ margin: '25px 0' }}>
                                <Box
                                    component="form"
                                    sx={{
                                        '& .MuiTextField-root': { m: 1, width: '25ch', margin: '20px 0' },
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <div className="sc--form--center" style={{ margin: 'unset', padding: "10px 70px" }}>
                                        {carrier && carrier.quickship && carrier.quickship.length == 0 &&
                                            <Button
                                                variant="outlined"
                                                onClick={generatelabel}
                                                disabled
                                            >
                                                <LocalPrintshopIcon style={{ paddingRight: '10px' }} /> Generate & Print Label
                                             </Button>
                                        }
                                        {carrier && carrier.quickship && carrier.quickship.length !== 0 &&
                                            <Button
                                                variant="outlined"
                                                onClick={generatelabel}
                                                style={{ border: '1px solid rgba(0, 0, 0, 0.12)' }}
                                            >
                                                <LocalPrintshopIcon style={{ paddingRight: '10px' }} /> Generate & Print Label
                                            </Button>
                                        }
                                    </div>
                                    <div className="sc--form--center" style={{ margin: 'unset' }}>
                                        <p>Note: please unblock the pop up blocker for printing to work smoothly
                                             <a href="https://support.google.com/chrome/answer/95472?co=GENIE.Platform%3DDesktop&hl=en-GB"
                                                target="_blank" style={{ paddingLeft: '5px' }}>Refer Here
                                             </a>
                                        </p>
                                    </div>
                                </Box>
                            </Grid>
                        </div>
                    </div>
                </div>
            </>
            }
        </>
    );
}

export default QuickShipmentsPage;
