import { createSlice } from '@reduxjs/toolkit';
import { LABELGENAPI} from '../../../API';

export const tagslice = createSlice({
    name: 'tag',
    initialState: {
        data: [],
        meta: [],
        loading: false,
        hasErrors: false,
        notification: { show: false, messageContent: "", messageType: "success" }
    },
    reducers: {
        getTagData: (state) => {
            return {
                ...state,
                loading: true,
                hasErrors: false
            }
        },
        getTagDataSuccess: (state, action) => {
            return {
                ...state,
                data: action && action.payload && action.payload.data && action.payload.data.data,
                notification: { show: true, messageContent: [action && action.payload && action.payload.data && action.payload.data.message], messageType: "success" },
                hasErrors: false,
                loading: false
            }
        },
        getTagDataFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                hasErrors: true,
                notification: { show: true, messageContent: [action && action.payload && action.payload.data && action.payload.data.message], messageType: "error" }
            }
        },
        postTagData: (state) => {
            return {
                ...state,
                loading: true,
                hasErrors: false
            }
        },
        postTagDataSuccess: (state, action) => {
            return {
                ...state,
                hasErrors: false,
                loading: false,
                notification: { show: true, messageContent: [action && action.payload && action.payload.data && action.payload.data.message], messageType: "success" }
            }
        },
        postTagDataFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                hasErrors: true,
                notification: { show: true, messageContent: [action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.message], messageType: "error" }
            }
        },
        putTagData: (state) => {
            return {
                ...state,
                loading: true,
                hasErrors: false
            }
        },
        putTagDataSuccess: (state, action) => {
            return {
                ...state,
                hasErrors: false,
                loading: false,
                notification: { show: true, messageContent: [action && action.payload && action.payload.data && action.payload.data.message], messageType: "success" }
            }
        },
        putTagDataFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                hasErrors: true,
                notification: { show: true, messageContent: [action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.message], messageType: "error" }
            }
        },
        deleteTagData: (state) => {
            return {
                ...state,
                loading: true,
                hasErrors: false
            }
        },
        deleteTagDataSuccess: (state, action) => {
            return {
                ...state,
                hasErrors: false,
                loading: false,
                notification: { show: true, messageContent: [action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.message], messageType: "success" }
            }
        },
        deleteTagDataFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                hasErrors: true,
                notification: { show: true, messageContent: [action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.message], messageType: "error" }
            }
        },

        resetTag: (state) => {
            return {
                ...state,
                loading: false,
                hasError: false,
                notification: { show: false, messageContent: "", messageType: "success" }
            }
        },


    }
})
export const { getTagData, getTagDataSuccess, getTagDataFailure, postTagData, postTagDataSuccess, postTagDataFailure,
    putTagData, putTagDataSuccess, putTagDataFailure,
    deleteTagData, deleteTagDataSuccess, deleteTagDataFailure,resetTag } = tagslice.actions;


export const fetchTagData = () => dispatch => {
    dispatch(getTagData());
    try {
        LABELGENAPI
            .get("/settings/tag")
            .then(function (response) {
                dispatch(getTagDataSuccess(response));
            })
            .catch(function (error) {
                dispatch(getTagDataFailure(error.response));
            });
    } catch (error) {
        dispatch(getTagDataFailure());
    }
};

export const storeTagData = data => dispatch => {
    dispatch(postTagData());
    try {
        LABELGENAPI.post('/settings/tag', data)
            .then(function (response) {
                dispatch(postTagDataSuccess(response));
                dispatch(fetchTagData());
            }).catch(function (error) {
                dispatch(postTagDataFailure(error.response));
            })
    }
    catch (error) {
        dispatch(postTagDataFailure(error.response));
    }
}
export const updateTagData = (id, data) => dispatch => {
    dispatch(putTagData());
    try {
        LABELGENAPI.patch('/settings/tag/' + id, data)
            .then(function (response) {
                dispatch(putTagDataSuccess(response));
                dispatch(fetchTagData());
            }).catch(function (error) {
                dispatch(putTagDataFailure(error.response));
            })
    }
    catch (error) {
        dispatch(putTagDataFailure(error.response));
    }
}
export const destoryTagData = (id) => dispatch => {
    dispatch(deleteTagData());
    try {
        LABELGENAPI
            .delete("/settings/tag/" + id)
            .then(function (response) {
                dispatch(deleteTagDataSuccess(response));
                dispatch(fetchTagData());
            })
            .catch(function (error) {
                dispatch(deleteTagDataFailure(error.response));
            });
    } catch (error) {
        dispatch(deleteTagDataFailure(error));
    }
};
export const resetTagState = data => dispatch => {
    dispatch(resetTag());
}




export default tagslice.reducer;
