import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

// our components
import TrackingDrawer from '../../pages/General/TrackingDrawer'
import NorowsData from '../../pages/General/NorowsData'
import Notification from '../../pages/General/Notification'
import Carrier from '../../pages/General/Carrier'
import OrderStatus from '../../pages/General/OrderStatus'
import Customer from '../../pages/General/Customer'

// mui components
import {
    Typography,
    FormControl,
    Pagination,
    Card,
    CardContent,
    Stack,
    Tooltip,
    IconButton,
    Button,
    Backdrop,
    CircularProgress
} from "@mui/material";

import {
    DataGrid,
    GridToolbarContainer
} from '@mui/x-data-grid';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import PrintIcon from '@mui/icons-material/Print';

import { fetchManifestDetail, resetManifestDetailNotifications } from './manifestdetailSlice';
import { doGetDeliveries } from '../Reports/Deliveries/deliveriesSlice';
import { fetchProfile } from '../../pages/Settings/Profile/profileSlice';
import { fetchTrackingDetail } from '../Reports/Deliveries/trackingEventsSlice';
import { fetchHubs } from '../General/hubslice';

const ManifestDetailsPage = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const { manifestDetail, shipmentDetail } = useSelector(state => state.manifestdetails);
    const deliveries = useSelector(state => state.deliveries);
    const profile = useSelector(state => state.auth);
    const [meta, setMeta] = useState(deliveries.deliveriesData.meta);
    const [trackingDrawerVisible, setTrackingDrawerVisible] = useState(false);

    const hubs = useSelector(state => state.hubs);
    const { manifestId } = useParams();

    const columns = [{
        field: 'line_items.fulfilment_company',
        headerName: 'Carrier',
        flex: 1,
        renderCell: (params) => {
            return (
                <Carrier carrierName={params.row.line_items[0].fulfilment_company} width={"90px"} />
            )
        }
    }, {
        field: 'order.id',
        headerName: 'Order',
        headerClassName: 'super-app-theme--header',
        flex: 1,
        renderCell: (params) => {
            if (params.row.order.id) {
                return params.row.order.id
            } else {
                return "-"
            }
        }
    },
    {
        field: 'line_items.tracking_number',
        headerName: 'Tracking Number',
        flex: 1,
        renderCell: (params) => {
            return (
                <span style={{ color: 'rgb(24, 144, 255)', cursor: 'pointer' }} onClick={e => { trackingCallback(params.row.line_items[0].fulfilment_company, params.row.line_items[0].tracking_number) }}>{params.row.line_items[0].tracking_number}</span>
            )
        },
    },
    {
        field: 'order.status',
        headerName: 'Shipment Status',
        flex: 1,
        renderCell: (params) => {
            if(params.row && params.row.line_items.length != 0 && params.row.line_items[0].tracking_number && params.row.shipment && params.row.shipment.tracking && params.row.shipment.tracking[params.row.line_items[0].tracking_number] && params.row.shipment.tracking[params.row.line_items[0].tracking_number].status){
                return (
                    <OrderStatus orderStatus={params.row.shipment.tracking[params.row.line_items[0].tracking_number].status}
                    />
                )
            }
        }
    },
    {
        field: 'customer.first_name',
        headerName: 'Customer',
        flex: 1,
        renderCell: (params) => {
            let name = ""
            if (params.row.customer && typeof params.row.customer.first_name !== "undefined" && params.row.customer.first_name !== null) {
                name = params.row.customer.first_name;
            }
            if (params.row.customer && typeof params.row.customer.last_name !== "undefined" && params.row.customer.last_name !== null) {
                name = name + " " + params.row.customer.last_name
            }
            return (
                <Customer name={name} />
            )
        }
    },
    {
        field: 'order.date',
        headerName: 'Date',
        flex: 1,
        renderCell: (params) => {
            if (params.row.order.date) {
                return params.row.order.date
            } else {
                return "-"
            }
        }
    }]

    const groupColumns = [
        {
            field: 'carrier_name',
            headerName: 'Carrier',
            flex: 1,
            renderCell: (params) => {
                return (
                    <Carrier carrierName={params.row.carrier_name} width={"90px"} />
                )
            }
        },
        {
            field: 'hub',
            headerName: 'Hub',
            flex: 1,
            renderCell: (params) => {
                if (params.row.hub && hubs && typeof hubs.data[`params.row.hub`] !== "undefined" && typeof hubs.data[`params.row.hub`]["hub"] !== "undefined") {
                    return hubs.data[params.row.hub]["hub"]
                } else {
                    return params.row.hub
                }
            }
        },
        {
            field: 'total_shipments',
            headerName: 'Total Shipments',
            flex: 1,
            renderCell: (params) => {
                if (params.row.total_shipments) {
                    return params.row.total_shipments
                } else {
                    return "-"
                }
            }
        },
        {
            field: 'manifest_number',
            headerName: 'Manifest Number',
            flex: 1,
            renderCell: (params) => {
                return <> {(params.row.manifest_number != "") ? params.row.manifest_number : "-"} </>
            }
        },
        {
            field: 'manifest_url',
            headerName: 'Manifest PDF',
            flex: 1,
            renderCell: (params) => {
                if (params.row.manifest_url != "" && params.row.manifest_url != null) {
                    return (
                        <>
                            <Tooltip title="View Manifest PDF" placement="left-start">
                                <IconButton>
                                    <RemoveRedEyeIcon onClick={() => window.open(params.row.manifest_url)} style={{ color: "#1890ff", cursor: 'pointer' }} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Print Manifest PDF" placement="left-start">
                                <IconButton>
                                    <PrintIcon onClick={() => window.open(process.env.REACT_APP_API_DOMAIN + '/utility/pdf/print?q=' + params.row.manifest_url)} style={{ color: "#1890ff", cursor: 'pointer' }} />
                                </IconButton>
                            </Tooltip>
                        </>)
                } else {
                    return "-"
                }
            }
        }
    ]

    useEffect(() => {
        dispatch(fetchHubs())
        dispatch(fetchManifestDetail(manifestId))
        dispatch(doGetDeliveries({
            ...meta,
            ...{
                "shippingchimp_manifest_number": manifestId
            }
        }));
    }, [dispatch])

    useEffect(() => {
        dispatch(doGetDeliveries({
            ...meta,
            ...{
                "shippingchimp_manifest_number": manifestId
            }
        }));
    }, [meta])

    const onSortChange = (currentSorting) => {
        setMeta({
            ...meta,
            ...{
                sorting: JSON.stringify(currentSorting)
            }
        });
    }

    const onChangePerPage = (event) => {
        setMeta({
            ...meta,
            ...{
                page: 1,
                per_page: event.target.value
            }
        });
    }

    const onChangePagination = (event, page) => {
        setMeta({
            ...meta,
            ...{
                page: page
            }
        });
    }


    function CustomManifestDetailTitle() {

        return (
            <Typography variant="h6" style={{ position: "absolute", top: "9px", left: "15px" }}>
                <b>Carrier Manifest Detail</b>
            </Typography>
        )
    }

    function CustomManifestDetailToolbar() {

        return (
            <GridToolbarContainer>
                <CustomManifestDetailTitle />
            </GridToolbarContainer>
        );
    }

    function CustomTotalRows() {

        return (
            <div className="custom-total-rows">
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    Showing {deliveries && deliveries.deliveriesData && deliveries.deliveriesData.data && deliveries.deliveriesData.data.length} of {(deliveries && deliveries.deliveriesData && deliveries.deliveriesData.meta && deliveries.deliveriesData.meta.total) ? deliveries.deliveriesData.meta.total : 0}
                </FormControl>
            </div>
        )
    }


    function CustomPagesizeoptions() {

        return (
            <div className="custom-page-size-options">
                <span>
                    Show <select
                        value={meta && meta.per_page}
                        onChange={onChangePerPage}
                    >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </select> entries
                </span>
            </div>
        )
    }

    function CustomToolbar() {

        return (
            <GridToolbarContainer>
                <CustomPagesizeoptions />
                <CustomTotalRows />
            </GridToolbarContainer>
        );
    }

    function CustomPagination() {

        return (
            <Pagination
                color="primary"
                shape="circular"
                page={deliveries && deliveries.deliveriesData && deliveries.deliveriesData.meta && deliveries.deliveriesData.meta.current_page}
                count={deliveries && deliveries.deliveriesData && deliveries.deliveriesData.meta && deliveries.deliveriesData.meta.last_page}
                onChange={onChangePagination}
            />
        );
    }

    useEffect(() => {
        dispatch(fetchProfile());
    }, [dispatch])

    const trackingCallback = (carriername, trackingNumber) => {
        let apikey = (profile && profile.profile && profile.profile.api_token) ? profile.profile.api_token : "";
        const data = { "carrier_name": carriername, 'tracking_number': trackingNumber }
        dispatch(fetchTrackingDetail(data, apikey))
        setTrackingDrawerVisible(true)
    }
    const onClose = (values) => {
        setTrackingDrawerVisible(false);
    };


    return (
        <>
        {(manifestDetail && manifestDetail.loading === true) && <>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={true}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </>
            }

            {(manifestDetail && manifestDetail.notification && manifestDetail.notification.show) &&
                <Notification
                    is_open={manifestDetail.notification.show}
                    messageType={manifestDetail.notification.messageType}
                    messages={manifestDetail.notification.messageContent}
                    handleClose={() => dispatch(resetManifestDetailNotifications())}
                />}
            {(shipmentDetail.notification && shipmentDetail.notification.show) &&
                <Notification
                    is_open={shipmentDetail.notification.show}
                    messageType={shipmentDetail.notification.messageType}
                    messages={shipmentDetail.notification.messageContent}
                    handleClose={() => dispatch(resetManifestDetailNotifications())}
                />}
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '15px' }}>
                <Button variant="outlined" onClick={() => { history.goBack() }}><ArrowBackIosIcon /> Back</Button>
            </div>

            {manifestDetail && manifestDetail.loading === false &&

                <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                        <Typography variant="h6">
                            <b>Manifest Overview</b>
                        </Typography>
                        <Stack direction="row" spacing={30} style={{ marginTop: "10px" }}>
                            <div>
                                <p><span className="text-soft">Manifest Number</span></p>
                                <p>{manifestId}</p>
                            </div>
                            <div>
                                <p><span className="text-soft">Status</span></p>
                                <p>CLOSED</p>
                            </div>
                            <div>
                                <p><span className="text-soft">Carriers</span></p>
                                <p>{manifestDetail && manifestDetail.data && manifestDetail.data.carriersCount}</p>
                            </div>
                            <div>
                                <p><span className="text-soft">Hubs</span></p>
                                <p>{manifestDetail && manifestDetail.data && manifestDetail.data.hubsCount}</p>
                            </div>
                            <div>
                                <p><span className="text-soft">Shipments</span></p>
                                <p>{manifestDetail && manifestDetail.data && manifestDetail.data.shipmentsCount}</p>
                            </div>
                        </Stack>
                    </CardContent>
                </Card>
            }

            <div style={{ width: '100%', background: '#fff', marginTop: "20px" }}>
                <DataGrid
                    style={{ paddingTop: "37px" }}
                    getRowId={(row) => row.id}
                    rows={manifestDetail && manifestDetail.data && manifestDetail.data.groupedData}
                    columns={groupColumns}
                    autoHeight={true}
                    disableColumnFilter={true}
                    disableColumnMenu={true}
                    pageSize={5}
                    rowsPerPageOptions={[]}
                    slots={{
                        toolbar: CustomManifestDetailToolbar,
                        NorowsData
                    }}
                    loading={
                        (manifestDetail && manifestDetail.loading)
                    }
                />
            </div>
            <Card sx={{ marginTop: "20px" }}>
                <div style={{ width: '100%', background: '#fff', marginTop: "20px" }}>
                    <CardContent>
                        <Typography variant="h6" style={{ position: "absolute", marginTop: "-30px" }}>
                            <b>Shipment Details</b>
                        </Typography>
                    </CardContent>
                    <DataGrid
                        style={{ paddingTop: "37px" }}
                        getRowId={(row) => row._id}
                        rows={deliveries && deliveries.deliveriesData && deliveries.deliveriesData.data}
                        columns={columns}
                        autoHeight={true}
                        disableColumnFilter={true}
                        disableColumnMenu={true}
                        sortingMode="server"
                        onSortModelChange={(newSortModel) => onSortChange(newSortModel)}
                        sortModel={meta && meta.sorting && JSON.parse(meta.sorting)}
                        disableSelectionOnClick
                        components={{
                            Pagination: CustomPagination,
                            Toolbar: CustomToolbar,
                            NorowsData
                        }}
                        loading={
                            (deliveries && deliveries.deliveriesData.loading)
                        }
                    />
                </div>
            </Card>
            <TrackingDrawer
                visible={trackingDrawerVisible} onClose={onClose}
            />
        </>
    )
}

export default ManifestDetailsPage;