
import React from "react";
import _ from 'lodash';
import AdbOutlinedIcon from '@mui/icons-material/AdbOutlined';
import DesktopWindowsOutlinedIcon from '@mui/icons-material/DesktopWindowsOutlined';
import LaptopMacOutlinedIcon from '@mui/icons-material/LaptopMacOutlined';
const Os = ({ source = "", size = "30px" }) => {
    let os = "OTHER";
    if (!_.isUndefined(source.isAndroid) && source.isAndroid) {
        os = "android";
    } else if (!_.isUndefined(source.isWindows) && source.isWindows) {
        os = "windows";
    } else if (!_.isUndefined(source.isLinux) && source.isLinux) {
        os = "linux";
    } else if (!_.isUndefined(source.isMac) && source.isMac) {
        os = "apple";
    }

    return (
        <>
            {!os && <div> - </div>}
            {os && os === 'android' &&
                <div><AdbOutlinedIcon /></div>
            }
            {os && os === 'windows' &&
                <div><DesktopWindowsOutlinedIcon /></div>
            }
            {os && os === 'linux' &&
                <div><AdbOutlinedIcon /></div>
            }
            {os && os === 'apple' &&
                <div><LaptopMacOutlinedIcon /></div>
            }
        </>
    );
};

export default Os;