import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import ShipmentUpdateFlag from '../../../../General/ShipmentUpdateFlag';
import { fetchSkulabsData, configureStore, resetConfigureState } from './skulabsSlice';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    height: '100%',
    padding: '20px 0'
}));

const SkuLabsIntegration = ({ storeName, platformName, storeParam, storeId }) => {
    const dispatch = useDispatch();

    const profile = useSelector(state => state.auth);
    const progress = useSelector(state => state.skulabs && state.skulabs.progress);
    const configure = useSelector(state => state.skulabs && state.skulabs.configure);
    const [alert, setAlert] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const [values, setValues] = useState(progress.data);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (progress && progress.data && progress.data.access_token) {
            setValues({
                ...values,
                ...progress.data
            })
        }
    }, [progress && progress.data])

    useEffect(() => {
        if (configure && configure.message) {
            setAlert(true)
            setSnackOpen(true)
        } else if (configure && configure.hasErrors == true) {
            setAlert(true)
            setSnackOpen(true)
        }
    }, [dispatch, configure && configure.message])

    useEffect(() => {
        if (storeName) {
            let apikey = profile.profile.api_token
            const data = { apikey: apikey, store_name: storeName, store_id: storeId }
            dispatch(fetchSkulabsData(data));
        }
    }, [dispatch]);

    const validator = (fieldValues) => {
        let temp = { ...errors }
        if ('access_token' in fieldValues)
            temp.access_token = fieldValues.access_token ? "" : "This field is required."

        setErrors({
            ...temp
        })

        if (fieldValues === values)
            return Object.values(temp).every(x => x == "")
    }

    const onInputChange = e => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
        validator({ [name]: value })
    }

    const updateCallBackValue = (updateValue) => {
        setValues({
            ...values,
            ['is_update_shipment']: updateValue.is_update_shipment,
            ['is_update_shipment_on_create']: updateValue.is_update_shipment_on_create,
            ['is_update_shipment_on_close']: updateValue.is_update_shipment_on_close,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        const isValid = validator(values)
        if (isValid) {
            dispatch(configureStore(values));
        }
    }

    const handleClose = () => {
        dispatch(resetConfigureState())
        setSnackOpen(false);
    };
    console.log(values);
    return (
        <>
            {alert &&
                <Snackbar
                    open={snackOpen}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    autoHideDuration={6000}
                    onClose={handleClose}
                >
                    <Alert
                        variant="filled"
                        severity=
                        {configure && configure.hasErrors === true ? "error" : "success"}
                        style={{ justifyContent: 'center', margin: "10px 0" }}
                    >
                        <span > {configure && configure.message}</span>
                    </Alert>
                </Snackbar>
            }
            {progress && progress.loading === false &&
                <Grid container spacing={1}>
                    <Grid item xs={12} md={12} style={{ textAlign: 'center' }}>
                        <Item style={{ marginBottom: '30px' }}>
                            <div>
                                <img
                                    src="/assets/images/integration/SKULABS.png"
                                    alt="SKULABS"
                                    style={{ margin: 'auto', display: 'flex', maxWidth: '60px' }} />
                            </div>
                            <div
                                className="sc--form--center"
                                style={{ margin: 'auto', textAlign: 'center', width: '30%', marginBottom: '10px' }}>
                                <TextField
                                    label="Access Token"
                                    name="access_token"
                                    value={values.access_token}
                                    onChange={onInputChange}
                                    error={errors.access_token}
                                    helperText={errors.access_token}
                                    style={{ width: "100%", margin: '8px' }}
                                    variant="standard"
                                />
                            </div>
                            <ShipmentUpdateFlag
                                updateFlag={values.is_update_shipment}
                                onCreateFlag={values.is_update_shipment_on_create}
                                onCloseFlag={values.is_update_shipment_on_close}
                                updateCallBack={updateCallBackValue}
                                message={"Do you want to update shipment information back to skulabs account?"}
                            />
                            <div
                                className="sc--form--center"
                                style={{ margin: 'auto', textAlign: 'center', width: '50%' }}>
                                <LoadingButton
                                    variant="contained"
                                    onClick={e => handleSubmit(e)}
                                    loading={configure && configure.loading}
                                >{(storeName) ? "Configure" : "Authorize" }<SendOutlinedIcon style={{ paddingLeft: '10px' }} />
                                </LoadingButton>
                            </div>
                        </Item>
                    </Grid>
                </Grid>
            }
        </>
    )
}

export default SkuLabsIntegration;