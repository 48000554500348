import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Paper from '@mui/material/Paper';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    height: '100%'
}));
const DesktopshipperIntegration = ({ visible, platform, formDataValues, onClose }) => {
    const steps = ['Create web hook', 'Verify', 'Finish'];
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const isStepOptional = (step) => {
        return step === 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    function getStepContent(step) {
        switch (step) {
            case 0:
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={6}>
                            <Item>
                                <h4>Step 1</h4>
                                <ul class="list list-checked">
                                    <li>From your Shopify admin, go to <b>Settings  Notifications</b></li>
                                    <li>Scroll down to the <b>Webhooks section</b></li>
                                    <li>Click the <b>Create webhook</b> button. A new window should appear.</li>
                                    <li>From the event drop-down, select the “<b>Order update</b>”</li>
                                    <li>From the format drop-down, choose the “Json” format</li>
                                    <li>Under URL, enter the below</li>
                                    {/* <span><Input defaultValue={hookUrl} suffix={<CopyOutlined onClick={copyHookUrl} />} readOnly="readonly" /> </span> */}
                                    <li>Tap on Add webhook</li>
                                    <li>Click on the “next” button below</li>
                                </ul>
                            </Item>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <Item>
                                <img className="integration-img__guide" src="/assets/images/integration/shopify/webhook-details.png" alt="Shippingchimp+Shopify" />
                            </Item>
                        </Grid>
                    </Grid>
                )
            case 1:
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={6}>
                            <Item>
                                <img className="integration-img__guide" src="/assets/images/integration/shopify/webhook-details.png" alt="Shippingchimp+Shopify" />
                            </Item>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <Item>
                                <h4>Step 2</h4>
                                <ul class="list list-checked">
                                    <li>To make sure the information we want is sent to the correct URL, you have to test your webhook by tapping on the send test notification link.</li>
                                    <li>Go to webhooks section and click on “Send test notification” link</li>
                                    <li>Click on the verify button below</li>
                                </ul>
                            </Item>
                        </Grid>

                    </Grid>
                )
            case 2:
                return (
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={12}>
                            <Item>
                                <div style={{ textAlign: 'center' }}>
                                    <CheckCircleOutlineOutlinedIcon style={{ color: '#52c41a', fontSize: '45px' }} />
                                    <p>Shopify integrated successfully</p>
                                </div>
                            </Item>
                        </Grid>
                    </Grid>
                )
        }
    }

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <Stepper activeStep={activeStep} style={{ margin: '50px 0' }}>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};

                        if (isStepSkipped(index)) {
                            stepProps.completed = false;
                        }
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                {activeStep === steps.length ? (
                    <React.Fragment>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                            All steps completed - you&apos;re finished
          </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button onClick={handleReset}>Reset</Button>
                        </Box>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                            {getStepContent(activeStep)}
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Button
                                color="inherit"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mr: 1 }}
                            >
                                Back
            </Button>
                            <Box sx={{ flex: '1 1 auto' }} />
                            {isStepOptional(activeStep) && (
                                <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                    Skip
                                </Button>
                            )}

                            <Button onClick={handleNext}>
                                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                            </Button>
                        </Box>
                    </React.Fragment>
                )}
            </Box>
        </>
    )
}


export default DesktopshipperIntegration;