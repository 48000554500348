import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Our components
import TrackingDrawer from '../../../pages/General/TrackingDrawer'
import Carrier from '../../General/Carrier'
import OrderStatus from '../../General/OrderStatus'
import Customer from '../../../pages/General/Customer'
import CarrierTracking from '../../../pages/General/CarrierTracking'
import Filters from './Filters'
import Search from './Search'
import DynamicColumns from '../../../pages/General/DynamicColumns'
import Notification from '../../../pages/General/Notification'
import NorowsData from '../../../pages/General/NorowsData'
import Date from '../../../pages/General/Date'
import OrderDrawer from '../../../pages/General/OrderDrawer'
import ShipmentRate from '../../../pages/General/ShipmentRate'
import Printer from '../../../pages/General/Printer'

// mui components
import {
    Typography,
    FormControl,
    Pagination,
    IconButton,
    Tooltip
} from "@mui/material";
import LinearProgress from '@mui/material/LinearProgress';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ViewColumnSharpIcon from '@mui/icons-material/ViewColumnSharp';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import InfoIcon from '@mui/icons-material/Info';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Badge from '@mui/material/Badge';
import {
    DataGrid,
    GridToolbarContainer
} from '@mui/x-data-grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import {
    doGetMysqlDeliveries,
    fetchDeliveriesColumns,
    saveDeliveriesColumns,
    fetchShipmentCategoryCountData,
    resetDeliveriessNotifications
} from './deliveriesmysqlSlice';
import { fetchTrackingDetail } from './trackingEventsSlice';
import {
    fetchOrderDetail
} from '../../../pages/OrderDetail/orderdetailsSlice';
const DeliveriesMysqlPage = () => {
    const dispatch = useDispatch();
    const deliveries = useSelector(state => state.deliveriesmysql);
    console.log()
    const profile = useSelector(state => state.auth);
    const [meta, setMeta] = useState(deliveries.deliveriesData.meta);
    const [showFilter, setShowFilter] = useState(false);
    const [showSearch, setShowSearch] = useState(true);
    const [showDynamicColumn, setShowDynamicColumn] = useState(false);
    const [columnCheckedObject, setColumnCheckedObject] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const [trackingDrawerVisible, setTrackingDrawerVisible] = useState(false);
    const [orderDrawerVisible, setOrderDrawerVisible] = useState(false);
    const [trackingMessage, setTrackingMessage] = useState(null);
    const [printUrl, setPrintUrl] = useState("")
    const [isPrinted, setIsPrinted] = useState(true)
    const [isMultiPiece, setIsMultiPiece] = useState(false)
    const [value, setValue] = React.useState(0);
    const orderCallback = (item) => {
        dispatch(fetchOrderDetail(item));
        setOrderDrawerVisible(true)
    }
    const orderOnClose = (values) => {
        setOrderDrawerVisible(false);
    };

    let defaultColumns = [{
        field: 'order_id',
        headerName: 'Order',
        headerClassName: 'super-app-theme--header',
        minWidth: 180,
        sortable: true,
        flex: 1,
        renderCell: (params) => {
            return <span style={{ color: 'rgb(24, 144, 255)', cursor: 'pointer' }} onClick={e => { orderCallback(params.row.order_id) }}>{params.row.order_id}</span>

        }
    },
    {
        field: 'tracking_number',
        headerName: 'Tracking Number',
        minWidth: 250,
        sortable: false,
        flex: 1,
        renderCell: (params) => {
            let tracking_number = (params.row.tracking_number
                && typeof params.row.tracking_number !== "undefined" && params.row.tracking_number !== null)
                ? params.row.tracking_number : "";
            let carrier_name = (params.row.label_carrier_name
                && typeof params.row.label_carrier_name !== "undefined" && params.row.label_carrier_name !== null)
                ? params.row.label_carrier_name : "";
            let message = (params.row && params.row.is_manifest_closed === false)
                ? "This shipment manifest is not yet closed" : null;
            let tracking = { "tracking_number": tracking_number, "carrier_name": carrier_name }
            return (
                <CarrierTracking trackingItems={tracking} dispatchCallback={trackingCallback} carrierImageWidth="60px" message={message} />
            )
        }
    },
    {
        field: 'shipment_status_category',
        headerName: 'Shipment Status',
        minWidth: 180,
        sortable: false,
        flex: 1,
        renderCell: (params) => {
            return (
                <OrderStatus orderStatus={params.row.shipment_status_category}
                />
            )
        }
    },
    {
        field: 'customer_first_name',
        headerName: 'Customer',
        minWidth: 170,
        sortable: false,
        flex: 1,
        renderCell: (params) => {
            let name = ""
            if (params.row.customer_first_name !== "undefined" && params.row.customer_first_name !== null) {
                name = params.row.customer_first_name;
            }
            if (params.row.customer_last_name !== "undefined" && params.row.customer_last_name !== null && params.row.customer_last_name !== params.row.customer_first_name) {
                name = name + " " + params.row.customer_last_name
            }
            return (
                <Customer name={name} />
            )
        }
    },
    {
        field: 'shipment_date',
        headerName: 'Shipment Date',
        minWidth: 180,
        flex: 1,
        renderCell: (params) => {
            if (params.row && params.row.shipment_date) {
                return <Date date={params.row.shipment_date} />
            } else {
                return "-"
            }
        }
    },
    {
        field: 'delivered_at',
        headerName: 'Delivery Date',
        minWidth: 180,
        flex: 1,
        renderCell: (params) => {
            if (params.row && params.row.delivered_at) {
                return <Date date={params.row.delivered_at} />
            } else {
                return "-"
            }
        }
    },
    {
        field: 'rate_total_charges',
        headerName: 'Rate',
        sortable: false,
        flex: 1,
        minWidth: 100,
        renderCell: (params) => {
            let voided = ((params.row.is_void_in_progress && params.row.is_void_in_progress !== null) || (params.row.is_voided && params.row.is_voided !== null)) ? true : false
            return <ShipmentRate
                billed_rate={params.row.rate_billed_total}
                api_rate={params.row.rate_total_charges}
                is_multi_piece={params.row.is_multi_piece_label}
                is_voided={voided}
            />
        }
    },
    {
        field: 'action',
        headerName: 'Shipment Action',
        minWidth: 200,
        sortable: false,
        flex: 1,
        renderCell: (params) => {
            const trackingNumber = params.row.tracking_number ? params.row.tracking_number : "";
            let voided = ((params.row.is_void_in_progress && params.row.is_void_in_progress !== null) || (params.row.is_voided && params.row.is_voided !== null)) ? true : false
            if (trackingNumber != "" && voided != true && ((params.row.detachable_label_url) || (params.row.detachable_direct_label_url) || (params.row.label_url && params.row.label_url != "") || (params.row.direct_label_url && params.row.direct_label_url != ""))) {

                let url = "";
                if (params.row.detachable_label_url && params.row.detachable_label_url != "") {
                    url = params.row.detachable_label_url
                } else if (params.row.detachable_direct_label_url && params.row.detachable_direct_label_url != "") {
                    url = params.row.detachable_direct_label_url
                } else if (params.row.label_url && params.row.label_url != "") {
                    url = params.row.label_url
                } else if (params.row.direct_label_url && params.row.direct_label_url != "") {
                    url = params.row.direct_label_url
                }

                return <div>
                    <span style={{ marginRight: '10px' }}><Tooltip title="View" placement="left-start">
                        <IconButton onClick={() => window.open(url, '_blank')}>
                            <RemoveRedEyeIcon style={{ color: "#1890ff", cursor: 'pointer' }}  />
                        </IconButton>
                    </Tooltip>
                    </span>
                    <span style={{ marginRight: '10px' }}><Tooltip title="Print" placement="left-start">
                        <IconButton  onClick={() => { setPrintUrl(url); setIsPrinted(false) }} >
                            <LocalPrintshopIcon style={{ color: "#1890ff", cursor: 'pointer' }}
                               />
                        </IconButton>
                    </Tooltip>
                    </span>
                </div>
            } else {
                return <>-</>
            }
        }
    }]
    const [columns, setColumns] = useState(defaultColumns);

    const tabOnClick = (e,value) => {
        setValue(value);
        let shipment_status=""
        if(value==0){
            shipment_status=""
        }else if(value==1){
            shipment_status="Label Generated"
        }else if(value==2){
            shipment_status="In Transit"
        }else if(value==3){
            shipment_status="Issues"
        }else if(value==4){
            shipment_status="Delivered"
        }
        setMeta({
            ...meta,
            ...{
                shipment_status_category: [shipment_status],
            }
        });
      };
   


    useEffect(() => {
        dispatch(fetchDeliveriesColumns());
        setMeta({
            ...meta,
            ...{
                page: 1,
                per_page: 10,
                start_date: "",
                end_date: "",
                order_id: "",
                order_status: [],
                receiver_province: "",
                receiver_country_code: "",
                tracking_number: "",
                carrier_name: [],
                platform_tags: [],
                customer_name: "",
                shipment_status_category: [],
                shippingchimp_manifest_number: "",
                unclosed_shipment: 'false',
                sorting: JSON.stringify([{
                    field: 'shipment_date',
                    sort: 'desc',
                }])
            }
        });
    }, [dispatch])

    useEffect(() => {
        dispatch(doGetMysqlDeliveries({ ...meta }));
        dispatch(fetchShipmentCategoryCountData({ ...meta }));
    }, [meta])

    const trackingCallback = (carriername, trackingNumber, message = null) => {
        setTrackingMessage(message)
        let apikey = (profile && profile.profile && profile.profile.api_token) ? profile.profile.api_token : "";
        const data = { "carrier_name": carriername, 'tracking_number': trackingNumber }
        dispatch(fetchTrackingDetail(data, apikey))
        setTrackingDrawerVisible(true)
    }
    const onClose = (values) => {
        setTrackingDrawerVisible(false);
    };

    useEffect(() => {
        if (deliveries.deliveriesColumns.columns && typeof deliveries.deliveriesColumns.columns.user_columns !== "undefined") {
            if (deliveries.deliveriesColumns.columns.user_columns.length !== 0) {
                setMeta(prevState => ({
                    ...prevState,
                    [`columns`]: JSON.stringify(deliveries.deliveriesColumns.columns.user_columns)
                }));
                Object.entries(deliveries.deliveriesColumns.columns.user_columns).map(([key, value]) => (
                    defaultColumns.splice(defaultColumns.length - 1, 0, {
                        field: value,
                        minWidth: 150,
                        sortable: false,
                        flex: 1,
                        headerName: key,
                        headerClassName: 'super-app-theme--header',
                        renderCell: (params) => {
                            if(key === "Weight"){
                                return  <span>{parseFloat(params.row.actual_weight).toFixed(2)}</span>
                            }else{
                                return getValueFromObject("row." + value, params)
                            }
                        }
                    })
                ))
            }
            setColumns(defaultColumns)
        }

    }, [deliveries.deliveriesColumns && deliveries.deliveriesColumns.columns])

    const onSortChange = (currentSorting) => {
        setMeta({
            ...meta,
            ...{
                sorting: JSON.stringify(currentSorting)
            }
        });
    }

    const onChangePerPage = (event) => {
        setMeta({
            ...meta,
            ...{
                page: 1,
                per_page: event.target.value
            }
        });
    }

    const onChangePagination = (event, page) => {
        setMeta({
            ...meta,
            ...{
                page: page
            }
        });
    }

    const onMenuClick = (type, show) => {
        setShowSearch((type === "search" && show === true) ? true : false)
        setShowFilter((type === "filter" && show === true) ? true : false)
    }

    const handleColumnsClick = (event) => {
        setAnchorEl(event.currentTarget);
        setShowDynamicColumn(!showDynamicColumn)
    };

    const updateDynamicColumns = (clear = false) => {
        let data = columnCheckedObject
        if (clear == true) {
            data = {}
            setColumnCheckedObject({})
        }
        dispatch(saveDeliveriesColumns(data))
    }

    const handleColumnsClose = () => {
        setAnchorEl(null);
        setShowDynamicColumn(!showDynamicColumn)
    };

    const onExportClick = () => {
        const qs = Object.keys(meta)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(meta[key])}`)
            .join('&');
        console.log(qs)
        let url = process.env.REACT_APP_API_DOMAIN + '/labelgen/shipment/export?' + qs;
        window.open(url)
    }

    function getValueFromObject(path, sourceObject) {
        let paths = path.split(".")
        try {
            paths.map((key) => (
                sourceObject = sourceObject[key]
            ))
            return sourceObject
        }
        catch (err) {
            return "-"
        }

    }

    function CustomTotalRows() {

        return (
            <div className="custom-total-rows">
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    Showing {deliveries && deliveries.deliveriesData && deliveries.deliveriesData.data && deliveries.deliveriesData.data.length} of {(deliveries && deliveries.deliveriesData && deliveries.deliveriesData.meta && deliveries.deliveriesData.meta.total) ? deliveries.deliveriesData.meta.total : 0}
                </FormControl>
            </div>
        )
    }


    function CustomPagesizeoptions() {

        return (
            <div className="custom-page-size-options">
                <span>
                    Show <select
                        value={meta && meta.per_page}
                        onChange={onChangePerPage}
                    >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={1000}>1000</option>
                    </select> entries
                </span>
            </div>
        )
    }

    function CustomToolbar() {

        return (
            <GridToolbarContainer>
                <CustomPagesizeoptions />
                <CustomTotalRows />
            </GridToolbarContainer>
        );
    }

    function CustomPagination() {

        return (
            <Pagination
                color="primary"
                shape="circular"
                page={deliveries && deliveries.deliveriesData && deliveries.deliveriesData.meta && deliveries.deliveriesData.meta.current_page}
                count={deliveries && deliveries.deliveriesData && deliveries.deliveriesData.meta && deliveries.deliveriesData.meta.last_page}
                onChange={onChangePagination}
            />
        );
    }



    return (
        <>
            {(deliveries && deliveries.deliveriesData && deliveries.deliveriesData.notification && deliveries.deliveriesData.notification.show) &&
                <Notification
                    is_open={deliveries.deliveriesData.notification.show}
                    messageType={deliveries.deliveriesData.notification.messageType}
                    messages={deliveries.deliveriesData.notification.messageContent}
                    handleClose={() => dispatch(resetDeliveriessNotifications())}
                />}
            <Printer isPrinted={isPrinted} setIsPrinted={setIsPrinted} print_url={printUrl} />
            <div className="scmaincard">
                <div className="sccardhead">
                    <Typography variant="h5" gutterBottom component="div">
                        Deliveries
                       </Typography>
                    <ul className="features-list" style={{ position: "absolute", float: "right", right: "16px" }}>
                        <li>
                            <Tooltip title="Seacrh" placement="top-start">
                                <IconButton style={(showSearch) ? { backgroundColor: 'rgb(230, 230, 230)' } : {}} onClick={() => onMenuClick("search", !showSearch)}>
                                    <SearchIcon style={{ color: 'rgb(25, 118, 210)' }} />
                                </IconButton>
                            </Tooltip>
                        </li>
                        <li>
                            <Tooltip title="Filter" placement="top-start">
                                <IconButton style={(showFilter) ? { backgroundColor: 'rgb(230, 230, 230)' } : {}} onClick={() => onMenuClick("filter", !showFilter)}>
                                    <FilterAltOutlinedIcon style={{ color: 'rgb(25, 118, 210)' }} />
                                </IconButton>
                            </Tooltip>
                        </li>
                        <li>
                            <Tooltip title="Export Shipments" placement="top-start">
                                <IconButton onClick={() => onExportClick()}>
                                    <FileDownloadIcon style={{ color: 'rgb(25, 118, 210)' }} />
                                </IconButton>
                            </Tooltip>
                        </li>
                        <li>
                            <Tooltip title="Columns" placement="top-start">
                                <IconButton
                                    id="dynamic-column-positioned-button"
                                    aria-controls={showDynamicColumn ? "dynamic-column-positioned-menu" : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={showDynamicColumn ? 'true' : undefined}
                                    style={(showDynamicColumn) ? { backgroundColor: 'rgb(230, 230, 230)' } : {}}
                                    onClick={handleColumnsClick}
                                >
                                    <ViewColumnSharpIcon style={{ color: 'rgb(25, 118, 210)' }} />
                                </IconButton>
                            </Tooltip>
                        </li>
                    </ul>
                </div>

                <div>
                    {anchorEl != null && <DynamicColumns
                        visible={showDynamicColumn}
                        anchorEl={anchorEl}
                        columns={deliveries && deliveries.deliveriesColumns && deliveries.deliveriesColumns.columns}
                        columnCheckedObject={columnCheckedObject}
                        setColumnCheckedObject={setColumnCheckedObject}
                        updateDynamicColumns={updateDynamicColumns}
                        handleColumnsClose={handleColumnsClose}
                        loading={deliveries && deliveries.deliveriesColumns && deliveries.deliveriesColumns.loading}
                    />}
                    <Search visible={showSearch} meta={meta} setMeta={setMeta} />
                    <Filters visible={showFilter} meta={meta} setMeta={setMeta} />
                </div>
                <div>
                <Box className="deliveries_tab"sx={{ border:'1px solid', borderColor: 'divider',borderBottom:'unset',background: '#fff' }}>
                    <Tabs value={value} onChange={tabOnClick} aria-label="basic tabs example">
                        <Tab label="All" icon={<Badge badgeContent={deliveries && deliveries.shipmentStatusCount && deliveries.shipmentStatusCount.data &&  deliveries.shipmentStatusCount.data.total} showZero={true} color="primary" max={1000000000}>
                                <LocalShippingOutlinedIcon color="action"  />
                            </Badge>} iconPosition="end" />
                        <Tab label="Label Generated"  icon={<Badge badgeContent={deliveries && deliveries.shipmentStatusCount && deliveries.shipmentStatusCount.data && deliveries.shipmentStatusCount.data.label_generated} showZero={true} color="primary" max={1000000000}>
                                <LocalShippingOutlinedIcon color="action" />
                            </Badge>} iconPosition="end"/>
                        <Tab label="In Transit" icon={<Badge badgeContent={deliveries && deliveries.shipmentStatusCount && deliveries.shipmentStatusCount.data &&  deliveries.shipmentStatusCount.data.in_transit} showZero={true} color="primary" max={1000000000} >
                                <LocalShippingOutlinedIcon color="action" />
                            </Badge> } iconPosition="end"/>
                        <Tab label="Issues" icon={<Badge badgeContent={deliveries && deliveries.shipmentStatusCount && deliveries.shipmentStatusCount.data && deliveries.shipmentStatusCount.data.issues} color="primary" showZero={true} max={1000000000} >
                                <LocalShippingOutlinedIcon color="action"/>
                            </Badge>} iconPosition="end"/>
                        <Tab label="Delivered" icon={<Badge badgeContent={deliveries && deliveries.shipmentStatusCount && deliveries.shipmentStatusCount.data && deliveries.shipmentStatusCount.data.delivered} color="primary" showZero={true} max={1000000000} >
                                <LocalShippingOutlinedIcon color="action" />
                            </Badge>} iconPosition="end"/>
                    </Tabs>
                </Box>
                </div>
                <div style={{ width: '100%', background: '#fff' }}>
                    <DataGrid
                        style={{ paddingTop: "37px"}}
                        getRowId={(row) => row.id}
                        rows={deliveries && deliveries.deliveriesData && deliveries.deliveriesData.data}
                        columns={columns}
                        autoHeight={true}
                        disableColumnFilter={true}
                        disableColumnMenu={true}
                        sortingMode="server"
                        onSortModelChange={(newSortModel) => onSortChange(newSortModel)}
                        sortModel={meta && meta.sorting && JSON.parse(meta.sorting)}
                        disableRowSelectionOnClick
                        slots={{
                            loadingOverlay: LinearProgress,
                            pagination: CustomPagination,
                            toolbar: CustomToolbar,
                            NorowsData
                        }}
                        loading={
                            (deliveries && deliveries.deliveriesData && deliveries.deliveriesData.loading)
                        }
                    />
                </div>
            </div>
            <TrackingDrawer
                visible={trackingDrawerVisible} message={trackingMessage} isMultiPiece={isMultiPiece} onClose={onClose}
            />
            <OrderDrawer
                visible={orderDrawerVisible} onClose={orderOnClose}
            />
        </>
    )
}

export default DeliveriesMysqlPage;
