import React from "react";

const PlatformImage = ({ platform = null, width = null }) => {

    return (
        <>
            {width == null && platform != null && platform != "" && <div className={`user-card ${platform.toUpperCase()}`}><div><img src={"/assets/images/integration/" + platform.toUpperCase().replace(/ /g, "_") + ".png"} onError={(e)=>{e.target.onerror = null; e.target.src="/assets/images/carriers/OTHERS.jpg"}} alt={platform} /></div></div>}
            {width != null && platform != null && platform != "" && <div className={`user-card`}><div><img src={"/assets/images/integration/" + platform + ".png"} onError={(e)=>{e.target.onerror = null; e.target.src="/assets/images/carriers/OTHERS.jpg"}} alt={platform} style={{ maxWidth: width }} /></div></div>}
        </>
    )

}

export default PlatformImage;
