import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import Modal from '@mui/material/Modal';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import { fetchCarrierDetail, storeCarrier, updateCarrier } from '../carrierCredentialsSlice';

const intelcomstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    // bgcolor: 'background.paper',
    boxShadow: 24,
};
const Intelcommodal = ({ intelcomOpen, setIntelcomOpen, formValues, setFormValues, snackbar }) => {
    const dispatch = useDispatch();

    const initialIntelcomValues = {
        name: '',
        carrier_name: "INTELCOM",
        api_key: '',
        environment: '',
        app_enabled: false,
        api_enabled: false
    }
    const [values, setValues] = useState(formValues && Object.keys(formValues).length === 0 ? initialIntelcomValues : formValues);
    const [errors, setErrors] = useState({});

    const IntelcomModalClose = () => {
        setIntelcomOpen(false)
        setValues(initialIntelcomValues)
        setErrors({})
        setFormValues(initialIntelcomValues)
    }

    useEffect(() => {
        if (formValues && Object.keys(formValues).length !== 0) {
            let tempValues = {
                name: formValues['name'],
                api_key: formValues['api_key'],
                environment: formValues['environment'],
                carrier_name: formValues['carrier_name'],
                app_enabled: formValues['app_enabled'],
                api_enabled: formValues['api_enabled']
            };
            setValues(tempValues)
        }
    }, [formValues])

    const validator = (fieldValues) => {
        let temp = { ...errors }
        if ('name' in fieldValues)
            temp.name = fieldValues.name ? "" : "This field is required."
        if (fieldValues && fieldValues.name && fieldValues.name.length < 3)
            temp.name = "Sender Name required minimum 3 characters"
        if ('environment' in fieldValues)
            temp.environment = fieldValues.environment ? "" : "This field is required."
        if ('api_key' in fieldValues)
            temp.api_key = fieldValues.api_key ? "" : "This field is required."

        setErrors({
            ...temp
        })

        if (fieldValues == values) {
            return Object.values(temp).every(x => x == "")
        }
    }

    const onInputChange = e => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
        validator({ [name]: value })
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: e.target.checked,
        });
        validator({ [name]: value })
    };

    const handleSubmit = e => {
        e.preventDefault()

        const isValid = validator(values)
        if (isValid) {
            if ("_id" in formValues) {
                dispatch(updateCarrier(formValues._id, values))
                IntelcomModalClose()
                snackbar(true)
            } else {
                const dm = { "carrier_name": "INTELCOM" }
                const mergeobjectdata = Object.assign(values, dm);
                dispatch(storeCarrier(mergeobjectdata))
                IntelcomModalClose()
                snackbar(true)
            }
        }

    }
    return (
        <Modal
            open={intelcomOpen}
            onClose={IntelcomModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={intelcomstyle}>
                <Card sx={{ minWidth: 275 }} className="sc--cardhead--details">
                    <CardHeader title='Carrier Details' />
                    <Divider />
                    <CardContent>
                        <div className="sc--form--center" style={{ margin: '20px 0' }}>
                            <TextField
                                id="carrier"
                                label="Carrier"
                                defaultValue="INTELCOM"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    readOnly: true,
                                }}
                                style={{ width: "100%", margin: '8px' }}
                                variant="filled"
                            />
                        </div>
                        <div className="sc--form--center" style={{ margin: '20px 0' }}>
                            <TextField
                                label="Name"
                                name="name"
                                defaultValue={formValues && formValues.name}
                                onChange={onInputChange}
                                error={errors.name}
                                helperText={errors.name}
                                inputProps={{ maxLength: 40 }}
                                style={{ width: "100%", margin: '8px' }}
                                variant="standard"
                            />
                        </div>
                        <div className="sc--form--center" style={{ margin: '20px 0' }}>
                            <TextField
                                label="API Key"
                                name="api_key"
                                defaultValue={formValues && formValues.api_key}
                                onChange={onInputChange}
                                error={errors.api_key}
                                helperText={errors.api_key}
                                inputProps={{ maxLength: 80 }}
                                style={{ width: "100%", margin: '8px' }}
                                variant="standard"
                            />
                        </div>
                        <div className="sc--form--center" style={{ margin: '20px 0' }}>
                            <FormControl variant="standard" style={{ width: "100%", margin: '8px' }} >
                                <InputLabel id="demo-simple-select-standard-label">Environment</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-error"
                                    name="environment"
                                    onChange={onInputChange}
                                    defaultValue={formValues && formValues.environment}
                                    error={errors.environment}
                                    label="Environment"
                                >
                                    <MenuItem value="production">Production</MenuItem>
                                    <MenuItem value="development">Development</MenuItem>
                                </Select>
                                <FormHelperText>{errors.environment}</FormHelperText>
                            </FormControl>                            
                        </div>
                        <div className="sc--form--center" style={{ margin: '20px 0' }}>
                            <FormGroup style={{ width: "50%" }}>
                                <FormControlLabel control={<Switch defaultChecked={formValues && formValues.app_enabled} name="app_enabled" onChange={handleChange} />} label="App usage" />
                            </FormGroup>
                            <FormGroup style={{ width: "50%" }}>
                                <FormControlLabel control={<Switch defaultChecked={formValues && formValues.api_enabled} name="api_enabled" onChange={handleChange} />} label="API usage" />
                            </FormGroup>
                        </div>
                        <div className="sc--form--center" style={{ margin: '20px 0' }}>
                            <Button variant="outlined" onClick={handleSubmit}>Submit</Button>
                        </div>
                    </CardContent>
                </Card>
            </Box>
        </Modal >
    )
}

export default Intelcommodal;
