import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Our components
import Notification from '../../../pages/General/Notification'
import NorowsData from '../../../pages/General/NorowsData'
import Date from '../../../pages/General/Date'
import { Link } from 'react-router-dom'

// mui components
import {
    FormControl,
    Pagination,
    IconButton,
    Button,
    Tooltip,
} from "@mui/material";
import LinearProgress from '@mui/material/LinearProgress';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import DescriptionIcon from '@mui/icons-material/Description';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import {
    DataGrid,
    GridToolbarContainer
} from '@mui/x-data-grid';

import {
    fetchInvoice,
    resetInvoiceNotifications,
    instantChargeInvoice
} from './invoiceSlice';
import { getSubscription } from '../Payment/paymentSlice';
import Filters from './Filters'
import InvoiceDetailDrawer from '../../../pages/General/InvoiceDetailDrawer'

const InvoicePage = () => {
    const dispatch = useDispatch();
    const invoice = useSelector(state => state.invoice.invoiceData);
    const activePaymentData = useSelector(state => state.payment.getActivePayment);
    const instantCharge = useSelector(state => state.invoice.instantCharge);
    const profile = useSelector(state => state.auth);
    const [meta, setMeta] = useState(invoice.meta);
    const [showFilter, setShowFilter] = useState(false);
    const [invoiceId, setInvoiceId] = useState("");
    const [invoiceNumber, setInvoiceNumber] = useState("");
    const [invoiceDetailDrawerVisible, setInvoiceDetailDrawerVisible] = useState(false);

    let columns = [
        {
            field: 'invoice_number',
            headerName: 'Invoice Number #',
            minWidth: 200,
            sortable: false,
            renderCell: (params) => {
                return <span style={{ color: 'rgb(24, 144, 255)', cursor: 'pointer' }} onClick={e => { invoiceCallback(params.row.id,params.row.invoice_number) }}>{params.row.invoice_number}</span>

            }
        },
        {
            field: 'invoiced_date',
            headerName: 'Invoiced Date',
            minWidth: 200,
            sortable: false,
            renderCell: (params) => {
                if (params.row && params.row.invoiced_date) {
                    return params.row.invoiced_date
                } else {
                    return "-"
                }
            }
        },
        {
            field: 'invoice_total_amount',
            headerName: 'Amount',
            minWidth: 200,
            sortable: false,
            renderCell: (params) => {
                let symbol = (params.row && params.row.currency_symbol) ? params.row.currency_symbol : ""
                if (params.row && params.row.invoice_total_amount) {
                    return symbol + params.row.invoice_total_amount
                } else {
                    return "-"
                }
            }
        },

        {
            field: 'action',
            headerName: 'Downloads',
            minWidth: 200,
            sortable: false,
            renderCell: (params) => {
                if (params.row && params.row.invoice_pdf_url && params.row.invoice_pdf_url != "") {
                    return <div>
                        <span style={{ marginRight: '10px' }}><Tooltip title="Download Invoice" placement="left-start">
                            <IconButton>
                                <DescriptionIcon style={{ color: "#1890ff", cursor: 'pointer' }} onClick={() => window.open(params.row.invoice_pdf_url, '_blank')} />
                            </IconButton>
                        </Tooltip>
                        </span>
                        <span style={{ marginRight: '10px' }}><Tooltip title="Download Invoice Shipment Details" placement="left-start">
                            <IconButton>
                                <LocalShippingOutlinedIcon style={{ color: "#1890ff", cursor: 'pointer' }} onClick={() => onExportClick(params.row && params.row.id,params.row && params.row.invoice_number)} />
                            </IconButton>
                        </Tooltip>
                        </span>
                    </div>
                } else {
                    return <>-</>
                }

            }
        },
        {
            field: 'Payment',
            headerName: 'Payment',
            minWidth: 200,
            sortable: false,
            renderCell: (params) => {
                if (params.row.is_paid == true && params.row && params.row.invoice_pdf_url && params.row.invoice_pdf_url != "") {
                    return <div>
                        <span style={{ marginRight: '10px' }}>
                            <Button variant="outlined" style={{ backgroundColor: "#fed813", color: "#0F1111" }} disabled = {(params.row && params.row.is_paid)}>
                            {"PAID"}
                            </Button>
                        </span>
                        { (params.row && params.row.is_paid && params.row.payment_invoice_url != "" && params.row.payment_invoice_url != null) &&
                            <span style={{ marginRight: '10px' }}>
                                <Tooltip title="View" placement="left-start">
                                    <IconButton>
                                        <RemoveRedEyeIcon style={{ color: "#1890ff", cursor: 'pointer' }} onClick={() => window.open(params.row.payment_invoice_url, "_blank")} />
                                    </IconButton>
                                </Tooltip>
                            </span>
                        }
                    </div>
                }else if(params.row.is_paid == false && activePaymentData && activePaymentData.data && activePaymentData.data.length != 0){
                    return <div>
                        <span style={{ marginRight: '10px' }}>
                            <Button variant="outlined" style={{ backgroundColor: "#fea41b", color: "#0F1111" }} disabled = {(instantCharge && instantCharge.loading)} onClick={() => onPayClick(params.row && params.row.id && params.row.id)}>
                            {"PAY"}
                            </Button>
                        </span>
                    </div>
                }else if((profile && profile.profile && profile.profile.payment_method && profile.profile.payment_method==='CC' || profile.profile.payment_method===null) && params.row.is_paid == false && activePaymentData && activePaymentData.data && activePaymentData.data.length == 0){
                    return <div>
                            <span style={{ marginRight: '10px' }}>
                                <Link to="/settings/payment"><Button variant="outlined">Add Payment</Button></Link>
                            </span>
                        </div>
                }else if(profile && profile.profile && profile.profile.payment_method && profile.profile.payment_method !=='CC' && params.row.is_paid == false && activePaymentData && activePaymentData.data && activePaymentData.data.length == 0){
                    return <div>
                    <span style={{ marginRight: '10px' }}>
                        <Button variant="outlined" style={{ backgroundColor: "#fea41b", color: "#0F1111" }} disabled = {(instantCharge && instantCharge.loading)} >
                        {"PENDING"}
                        </Button>
                    </span>
                </div>
                }else {
                    return <>-</>
                }
            }
        }
    ]

    useEffect(() => {
        dispatch(getSubscription(""))
        dispatch(fetchInvoice({ ...meta }));
    }, [meta])

    const onExportClick = (invoice_id,invoice_number) => {
        let url = process.env.REACT_APP_API_DOMAIN + '/labelgen/invoice-shipments/export?invoice_id=' + invoice_id+'&invoice_number='+invoice_number;
        window.open(url, '_blank')
    }

    const onPayClick = (invoice_id) => {
        dispatch(instantChargeInvoice({ "invoice_id": invoice_id }, { ...meta }));
    }

    const invoiceCallback = (invoiceid,invoice_number) => {
        setInvoiceId(invoiceid);
        setInvoiceNumber(invoice_number);
        setInvoiceDetailDrawerVisible(true);
    }

    const invoiceDetailOnClose = () => {
        setInvoiceDetailDrawerVisible(false);
    }

    function CustomTotalRows() {

        return (
            <div className="custom-total-rows">
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    Showing {invoice && invoice.data && invoice.data.length} of {(invoice && invoice.meta && invoice.meta.total) ? invoice.meta.total : 0}
                </FormControl>
            </div>
        )
    }

    const onChangePerPage = (event) => {
        setMeta({
            ...meta,
            ...{
                page: 1,
                per_page: event.target.value
            }
        });
    }

    const onChangePagination = (event, page) => {
        setMeta({
            ...meta,
            ...{
                page: page
            }
        });
    }

    function CustomPagesizeoptions() {

        return (
            <div className="custom-page-size-options">
                <span>
                    Show <select
                        value={meta && meta.per_page}
                        onChange={onChangePerPage}
                    >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </select> entries
                </span>
            </div>
        )
    }

    function CustomToolbar() {

        return (
            <GridToolbarContainer>
                <CustomPagesizeoptions />
                <CustomTotalRows />
            </GridToolbarContainer>
        );
    }

    function CustomPagination() {

        return (
            <Pagination
                color="primary"
                shape="circular"
                page={invoice && invoice.meta && invoice.meta.current_page}
                count={invoice && invoice.meta && invoice.meta.last_page}
                onChange={onChangePagination}
            />
        );
    }

    const onMenuClick = (type, show) => {
        setShowFilter((type === "filter" && show === true) ? true : false)
    }

    return (
        <>
            {(invoice && invoice.notification && invoice.notification.show && invoice.notification.messageContent && invoice.notification.messageContent !== "") &&
                <Notification
                    is_open={invoice.notification.show}
                    messageType={invoice.notification.messageType}
                    messages={invoice.notification.messageContent}
                    handleClose={() => dispatch(resetInvoiceNotifications())}
                />
            }
            {(instantCharge && instantCharge.notification && instantCharge.notification.show && instantCharge.notification.messageContent && instantCharge.notification.messageContent !== "") &&
                <Notification
                    is_open={instantCharge.notification.show}
                    messageType={instantCharge.notification.messageType}
                    messages={instantCharge.notification.messageContent}
                    handleClose={() => dispatch(resetInvoiceNotifications())}
                />
            }
            <Card sx={{ minWidth: 1000, boxShadow: 'unset' }} className="sc--cardhead--details">
                <CardHeader title='Invoices' className="sc--cardhead--details" action={
                    <div>
                        <Tooltip title="Back" placement="top-start">
                            <Link to="/settings/billing">
                                <IconButton style={{ position: "relative", left: '-20px' }}>
                                    <ArrowBackIosNewIcon style={{ color: "#1890ff", cursor: 'pointer' }} />
                                </IconButton>
                            </Link>
                        </Tooltip>
                        <Tooltip title="Filter" placement="top-start">
                            <IconButton style={(showFilter) ? { backgroundColor: 'rgb(230, 230, 230)', position: "relative", left: '-10px' } : { position: "relative", left: '-10px' }} onClick={() => onMenuClick("filter", !showFilter)}>
                                <FilterAltOutlinedIcon style={{ color: "#1890ff" }} />
                            </IconButton>
                        </Tooltip>
                    </div>
                } />

                <Divider />
                <CardContent style={{ padding: '10px 10px' }}>
                    <div className="scmaincard">
                        <div style={{ width: '100%', background: '#fff' }}>
                            <div>
                                <Filters visible={showFilter} meta={meta} setMeta={setMeta} />
                            </div>

                            <DataGrid
                                style={{ paddingTop: "35px"}}
                                getRowId={(row) => row.id}
                                rows={invoice && invoice.data}
                                columns={columns}
                                autoHeight={true}
                                disableColumnFilter={true}
                                disableColumnMenu={true}
                                disableRowSelectionOnClick
                                slots={{
                                    loadingOverlay: LinearProgress,
                                    pagination: CustomPagination,
                                    toolbar: CustomToolbar,
                                    NorowsData
                                }}
                                loading={
                                    (invoice && invoice.loading) || (instantCharge && instantCharge.loading) || (activePaymentData && activePaymentData.loading)
                                }
                            />
                        </div>
                    </div>
                </CardContent>
            </Card >
            {invoiceId &&
                <InvoiceDetailDrawer
                    visible={invoiceDetailDrawerVisible} invoiceId={invoiceId} invoiceNumber={invoiceNumber} invoiceDetailOnClose={invoiceDetailOnClose}
                />
            }
        </>
    )
}

export default InvoicePage;
