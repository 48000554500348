import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// mui components
import {
    Paper,
    Divider,
    styled,
    Grid,
    Box,
    Button,
    Modal,
    Typography,
    TextField,
    Card,
    CardHeader,
    CardContent
} from "@mui/material";
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper'
};

const AddPickup = ({ addPickupItemVisible, onAddPickupModalClose, formDataHandler, onAddPickupItemClick, addPickupItemData, editPickupItemId,handlePackageDimensionInputChange, errors }) => {
    const box = useSelector(state => state.box);

    return (
        <>
            <Modal
                open={addPickupItemVisible}
                onClose={onAddPickupModalClose}
            >
                <Box sx={modalStyle}>
                    <Card sx={{ minWidth: 600 }} className="sc--cardhead--details">
                        <CardHeader title={(editPickupItemId != "") ? "Edit Package" : "Add Package"} />
                        <Divider />
                        <CardContent>
                      <div className="sc--form--center" style={{ margin: '20px 0' }}>
                            <FormControl
                                variant="standard"
                                className="help-text-error"
                                style={{ width: '100%', margin: '8px' }}
                            >
                                <InputLabel
                                    id="demo-simple-select-standard-label"
                                >Choose Measurement
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    name="choosenmeasurement"
                                    value={addPickupItemData.choosemeasurement}
                                    onChange={(event) => {
                                        handlePackageDimensionInputChange(event)
                                    }
                                    }
                                    label="Choose measurement"
                                >

                                    {box && box.data && box.data.map((item) => {
                                        if(item && typeof item.hide=='undefined'){
                                        return (
                                            <MenuItem value={item._id}>
                                                <p style={{ margin: 'unset', color: 'rgba(0, 0, 0, 0.87)' }}>
                                                    {item.box_name}(
                                            {item.box_length}{item.box_length_unit} <span style={{ color: '#0082ff' }}> x </span>
                                                    {item.box_width}{item.box_width_unit} <span style={{ color: '#0082ff' }}> x </span>
                                                    {item.box_height}{item.box_height_unit}<span>, </span>
                                                    {item.box_weight}{item.box_weight_unit})
                                            </p>
                                            </MenuItem>
                                        )
                                        }
                                    })}
                                    <MenuItem value={0}>Custom</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="sc--form--center" style={{ margin: '20px 0' }}>
                            <TextField
                                label="Length"
                                type='number'
                                name="length"
                                inputProps={{ min: 1 }}
                                value={addPickupItemData.length}
                                onChange={formDataHandler}
                                error={errors.length}
                                helperText={errors.length}
                                variant="standard" style={{ width: "100%", margin: '8px' }}
                            />

                            <TextField
                                label="Width"
                                name="width"
                                type='number'
                                inputProps={{ min: 1 }}
                                value={addPickupItemData.width}
                                onChange={formDataHandler}
                                error={errors.width}
                                helperText={errors.width}
                                variant="standard" style={{ width: "100%", margin: '8px' }}
                            />
                             <TextField
                                label="Height"
                                name="height"
                                type='number'
                                inputProps={{ min: 1 }}
                                value={addPickupItemData.height}
                                onChange={formDataHandler}
                                error={errors.height}
                                helperText={errors.height}
                                variant="standard" style={{ width: "100%", margin: '8px' }}
                            />
                             <FormControl
                                variant="standard"
                                className="help-text-error"
                                style={{ width: "100%", margin: '8px' }}
                            >
                                <InputLabel id="demo-simple-select-standard-label">Dimension Unit</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-error"
                                    name="dimension_unit"
                                    value={addPickupItemData.dimension_unit}
                                    onChange={formDataHandler}
                                    error={errors.dimension_unit}
                                    helperText={errors.dimension_unit}
                                    label="Dimension Unit"
                                >
                                    <MenuItem value={"in"}>in</MenuItem>
                                    <MenuItem value={"cm"}>cm</MenuItem>

                                </Select>
                                {/* <FormHelperText>{errors.dimension_unit}</FormHelperText> */}
                            </FormControl>
                        </div>
                        <div className="sc--form--center" style={{ margin: '20px 0' }}>
                            <TextField
                                label="Weight"
                                name="weight"
                                type='number'
                                inputProps={{ min: 1 }}
                                value={addPickupItemData.weight}
                                onChange={formDataHandler}
                                error={errors.weight}
                                helperText={errors.weight}
                                variant="standard" style={{ width: "100%", margin: '8px' }}
                            />
                            <FormControl variant="standard" className="help-text-error" style={{ width: "100%", margin: '8px' }} >
                                <InputLabel id="demo-simple-select-standard-label">Weight Unit</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-error"
                                    name="weight_unit"
                                    value={addPickupItemData.weight_unit}
                                    onChange={formDataHandler}
                                    error={errors.weight_unit}
                                    helperText={errors.weight_unit}
                                    label="Weight Unit"
                                >
                                    <MenuItem value={"kg"}>kg</MenuItem>
                                    <MenuItem value={"lb"}>lb</MenuItem>


                                </Select>
                                {/* <FormHelperText>{errors.weight_unit}</FormHelperText> */}
                            </FormControl>
                            <TextField
                                label="Quantity"
                                name="quantity"
                                type='number'
                                inputProps={{ min: 1 }}
                                value={addPickupItemData.quantity}
                                onChange={formDataHandler}
                                error={errors.quantity}
                                helperText={errors.quantity}
                                variant="standard" style={{ width: "100%", margin: '8px' }}
                            />
                        </div>

                            <div className="sc--form--center" style={{ marginTop: "15px" }}>
                                <Button
                                    variant="outlined"
                                    onClick={onAddPickupItemClick}
                                    style={{ border: '1px solid rgba(0, 0, 0, 0.12)', margin: '0 15px' }}
                                >
                                    {(editPickupItemId != "") ? "Save" : "Save"}
                                </Button>
                            </div>
                        </CardContent>
                    </Card>
                </Box>
            </Modal>
        </>
    )
}

export default AddPickup;