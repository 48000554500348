import { createSlice } from '@reduxjs/toolkit';
import { LABELGENAPI} from '../../API';

export const claimsslice = createSlice({
    name: 'claims',
    initialState: {
        data: [],
        meta: {
            page: 1,
            per_page: 10,
            start_date: "",
            end_date: "",
            claim_type: [],
            tracking_number: "",
            claim_status: [],
            sorting: JSON.stringify([{
                field: 'created_at',
                sort: 'desc',
            }])
        },
        loading: false,
        hasErrors: false,
        notification: { show: false, messageContent: "", messageType: "success" }
    },
    reducers: {
        getClaimsData: (state) => {
            return {
                ...state,
                loading: true,
                hasErrors: false
            }
        },
        getClaimsDataSuccess: (state, action) => {
            return {
                ...state,
                data: action && action.payload && action.payload.data && action.payload.data.data,
                meta: action && action.payload && action.payload.data,
                hasErrors: false,
                loading: false
            }
        },
        getClaimsDataFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                hasErrors: true,
                message: action && action.payload && action.payload.data && action.payload.data.message
            }
        },
        postClaimsData: (state) => {
            return {
                ...state,
                loading: true,
                hasErrors: false
            }
        },
        postClaimsDataSuccess: (state, action) => {
            return {
                ...state,
                hasErrors: false,
                loading: false,
                notification: { show: true, messageContent: [action && action.payload && action.payload.data && action.payload.data.message], messageType: "success" },
            }
        },
        postClaimsDataFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                hasErrors: true,
                notification: { show: true, messageContent: [action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.message], messageType: "error" }
            }
        },
        
        resetclaims: (state) => {
            return {
                ...state,
                loading: false,
                hasError: false,
                notification: { show: false, messageContent: "", messageType: "success" }
            }
        },


    }
})
export const { getClaimsData, getClaimsDataSuccess, getClaimsDataFailure, postClaimsData, postClaimsDataSuccess, postClaimsDataFailure,
    resetclaims } = claimsslice.actions;


export const fetchClaimsData = (meta) => {
    const qs = Object.keys(meta)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(meta[key])}`)
        .join('&');

    return (dispatch) => {
        dispatch(getClaimsData());
        try {
            LABELGENAPI
                .get('/claimsInfo?' + qs)
                .then(function (response) {
                    dispatch(getClaimsDataSuccess(response));
                })
                .catch(function (error) {
                    dispatch(getClaimsDataFailure(error.response));
                });
        } catch (error) {
            dispatch(getClaimsDataFailure());
        }
    };
};

export const storeClaimsData =  (data, meta) => dispatch => {
    dispatch(postClaimsData());
    try {
        LABELGENAPI.post('/claimsInfo', data)
            .then(function (response) {
                dispatch(postClaimsDataSuccess(response));
                dispatch(fetchClaimsData(meta));
            }).catch(function (error) {
                dispatch(postClaimsDataFailure(error.response));
            })
    }
    catch (error) {
        dispatch(postClaimsDataFailure(error.response));
    }
}

export const resetClaimsState = data => dispatch => {
    dispatch(resetclaims());
}
export default claimsslice.reducer;
