import { createSlice } from '@reduxjs/toolkit';
import { OMSAPI } from '../../../API';
import axios from 'axios';

/* canceling previous requests if multiple requests is triggered */
const CancelToken = axios.CancelToken;
let source = CancelToken.source();

export const widgetanalyticsslice = createSlice({
    name: 'feedback',
    initialState: {
        stats: {
            data: {},
            loading: false,
            hasErrors: false,
        },
        report: {
            data: [],
            meta: {},
            loading: false,
            hasErrors: false,
        },
        organization: {
            'companyname': "",
            'shortname': "",
            'website': "",
        },
        meta: [],
    },

    reducers: {
        getWigetAnalyticsReport: (state) => {
            return {
                ...state,
                report: {
                    ...state.report,
                    loading: true
                }
            };
        },
        getWigetAnalyticsReportSuccess: (state, action) => {
            return {
                ...state,
                report: {
                    data: action && action.payload && action.payload.data && action.payload.data.data,
                    meta: action && action.payload && action.payload.data && action.payload.data.meta,
                    hasErrors: false,
                    loading: false
                }

            };
        },
        getWigetAnalyticsReportFailure: (state, action) => {
            return {
                ...state,
                report: {
                    ...state.report,
                    loading: false,
                    hasErrors: true

                }

            };
        },   

        getOrganization: (state) => {
            return {
                ...state,
                organization: {
                    ...state.organization,
                    loading: true
                }
            };
        },
        getOrganizationSuccess: (state, action) => {
            return {
                ...state,
                organization: action && action.payload && action.payload.data && action.payload.data.data,
                meta: action.meta,
                loading: false,
                hasErrors: false,
            };
        },
        getOrganizationFailure: (state, action) => {
            return {
                ...state,
                organization: {
                    ...state.organization,
                    loading: false,
                    hasErrors: true
                }
            };
        },

        getWidgetAnalyticsPreview: (state) => {
            return {
                ...state,
                preview: {
                    ...state.preview,
                    loading: true
                }
            };
        },
        getWidgetAnalyticsPreviewSuccess: (state, action) => {
            return {
                ...state,
                preview: {
                    data: action && action.payload && action.payload.data && action.payload.data.data  && action.payload.data.data.analytics_data,
                    hasErrors: false,
                    loading: false
                }

            };
        },
        getWidgetAnalyticsPreviewFailure: (state, action) => {
            return {
                ...state,
                preview: {
                    ...state.preview,
                    loading: false,
                    hasErrors: true

                }

            };
        },

    }
})

export const { getWigetAnalyticsReport, getWigetAnalyticsReportSuccess, getWigetAnalyticsReportFailure, getWidgetAnalyticsPreview,
    getWidgetAnalyticsPreviewSuccess, getWidgetAnalyticsPreviewFailure, getOrganization, getOrganizationSuccess,
    getOrganizationFailure } = widgetanalyticsslice.actions;

export const fetchWigetAnalyticsReport = (widgetType = "", meta) => {

    const qs = Object.keys(meta)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(meta[key])}`)
        .join('&');

    return (dispatch) => {
        dispatch(getWigetAnalyticsReport());
        try {

            source && source.cancel('Operation canceled due to new request.');
            source = axios.CancelToken.source();

            OMSAPI
                .get('/widget-analytics/report?' + qs + "&type=" + widgetType)
                .then(function (response) {
                    dispatch(getWigetAnalyticsReportSuccess(response));
                })
                .catch(function (error) {
                    dispatch(getWigetAnalyticsReportFailure(error.response));
                });
        } catch (error) {
            dispatch(getWigetAnalyticsReportFailure());
        }
    };
};

export const fetchOrganization = () => {

    return (dispatch) => {

        dispatch(getOrganization());

        try {
            OMSAPI.get('/organization')
                .then(function (response) {
                    dispatch(getOrganizationSuccess(response));
                }).catch(function (error) {
                    dispatch(getOrganizationFailure());
                })
        }
        catch (error) {
            dispatch(getOrganizationFailure());
        }

    }

}

export const fetchWidgetAnalyticspreview = (objectId) => {
    return (dispatch) => {
        dispatch(getWidgetAnalyticsPreview());
        try {
            OMSAPI
                .get('/widget-analytics/preview/' + objectId)
                .then(function (response) {
                    dispatch(getWidgetAnalyticsPreviewSuccess(response));
                })
                .catch(function (error) {
                    dispatch(getWidgetAnalyticsPreviewFailure(error.response));
                });
        } catch (error) {
            dispatch(getWidgetAnalyticsPreviewFailure());
        }
    };
};

export const { resetNotification } = widgetanalyticsslice.actions;

export const resetGenerateManifestNotification = () => {
    return (dispatch) => {
        dispatch(resetNotification());
    }
}

export default widgetanalyticsslice.reducer;
