import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchIntegratedPlatform } from './integratedSlice';
import { DataGrid } from '@mui/x-data-grid';
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';
import Card from '@mui/material/Card';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import PlatformImage from '../../General/PlatformImage'
import PlatformDrawer from '../../General/PlatformDrawer';
import SettingsStatus from '../../../pages/General/SettingsStatus'


const IntegratedPage = ({ loading, data }) => {
    const integrate = useSelector(state => state.integrated && state.integrated);
    const [currentCategory, setCurrentCategory] = useState('all');
    const [orderDrawerVisible, setOrderDrawerVisible] = useState(false);
    const [platformName, setPlatformName] = useState('all');
    const [storeName, setStoreName] = useState('all');
    const [storeId, setStoreId] = useState('all');

    const platformCallback = (platform,name,id) => {
        if(platform==='SHOPIFY_APP'){
            platform='SHOPIFY'
        }       
        setPlatformName(platform)
        setStoreName(name)
        setStoreId(id)    
        setOrderDrawerVisible(true)
    }
    
    const orderOnClose = (values) => {
        setOrderDrawerVisible(false);
    };
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchIntegratedPlatform())
    }, [dispatch])

    const columns = [
        {
            field: "platform",
            headerName: 'Platform',
            width:100,
            resizable: false,           
            renderCell: (params) => {
                return (
                    <PlatformImage platform={params.row.platform} width={"35px"} />
                )
            }
        },
        { field: 'store_name', headerName: 'Store',width:300,resizable: false, flex: 1 },
        { field: 'is_installed', 
        headerName: 'Status',
         minWidth:50,
         resizable: false,
        renderCell: (params) => {
            return <SettingsStatus settingsStatus={params.row.is_installed} />
        } },
        {
            field: 'Actions',
            resizable: false,
            minWidth:100,
            renderCell: (params) => {
                return (
                    <div>
                        <span
                            style={{ marginRight: '20px' }}
                        >
                            <EditIcon
                                style={{ color: '#1890ff', cursor: 'pointer' }}
                                onClick={(e) => {
                                    platformCallback(params.row.platform,params.row.store_name,params.row.store_id)
                                }}
                            />
                        </span>
                    </div>
                )

            }
        }
    ];

    return (
        <>
            {loading && <Card loading={loading} />}
            <div className="integrated--stores--title">
                <h2 style={{ textAlign: 'left' }}>Connected Market Places</h2>
            </div>
            <div className="integrated--stores--list">
                <div style={{ width: '100%' }}>
                    <DataGrid
                        getRowId={(row) => row.store_id}
                        rows={integrate && integrate.data}
                        columns={columns}
                        autoHeight={true}
                        disableRowSelectionOnClick
                        disableColumnFilter={true}
                        disableColumnMenu={true}
                        hideFooter={true}
                        slots={{
                            loadingOverlay: LinearProgress,
                          }}
                        loading={
                            (integrate && integrate.loading)
                        }
                    />
                </div>
            </div>
            <PlatformDrawer
                visible={orderDrawerVisible}
                onClose={orderOnClose}
                platform={platformName}
                storeName={storeName}
                storeId={storeId}
            />

        </>
    )
}

export default IntegratedPage
