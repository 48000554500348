import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

// our components
import TrackingDrawer from './TrackingDrawer'
import NorowsData from './NorowsData'
import Notification from './Notification'
import Carrier from './Carrier'
import OrderStatus from './OrderStatus'
import Customer from './Customer'
import CarrierTracking from './CarrierTracking'
import Printer from './Printer'

// mui components
import {
    Typography,
    FormControl,
    Pagination,
    Card,
    CardContent,
    Stack,
    Tooltip,
    IconButton,
    Button,
    Backdrop,
    CircularProgress,
    Drawer,
    Box,
    Divider,
    CardHeader,
    Paper
} from "@mui/material";
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import {
    DataGrid,
    GridToolbarContainer
} from '@mui/x-data-grid';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import PrintIcon from '@mui/icons-material/Print';
import CloseIcon from '@mui/icons-material/Close';
import Date from './Date'

import { fetchManifestDetail, resetManifestDetailNotifications } from '../../pages/ManifestDetail/manifestdetailSlice';
import { doGetDeliveries } from '../Reports/Deliveries/deliveriesSlice';
import { fetchTrackingDetail } from '../../pages/Reports/Deliveries/trackingEventsSlice';
import {
    fetchOrderDetail
} from '../../pages/OrderDetail/orderdetailsSlice';
import { fetchHubs } from './hubslice';
import OrderDrawer from '../../pages/General/OrderDrawer'

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    boxShadow: 'unset',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    color: theme.palette.text.secondary,
    height: '100%'
}));

const PendingShipmentsDrawer = ({ visible, manifestId, pendingShipmentsOnClose, shipmentType }) => {
    const dispatch = useDispatch();
    const deliveries = useSelector(state => state.deliveries);
    const profile = useSelector(state => state.auth);
    const [meta, setMeta] = useState(deliveries.deliveriesData.meta);
    const [trackingDrawerVisible, setTrackingDrawerVisible] = useState(false);
    const [orderDrawerVisible, setOrderDrawerVisible] = useState(false);
    const [trackingMessage, setTrackingMessage] = useState(null);
    const [printUrl, setPrintUrl] = useState("")
    const [isPrinted, setIsPrinted] = useState(true)

    const orderCallback = (item) => {
        dispatch(fetchOrderDetail(item));
        setOrderDrawerVisible(true)
    }
    const orderOnClose = (values) => {
        setOrderDrawerVisible(false);
    };
    const ondrawerclose = () => {
        pendingShipmentsOnClose(false)
    }

    console.log(shipmentType);

    const columns = [
        {
            field: 'order.id',
            headerName: 'Order',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            resizable: false,
            renderCell: (params) => {
                return <span style={{ color: 'rgb(24, 144, 255)', cursor: 'pointer' }} onClick={e => { orderCallback(params.row.order.id) }}>{params.row.order.id}</span>
    
            }
           
        },
        {
            field: 'shipment.k',
            headerName: 'Tracking Number',
            minWidth: 180,
            flex: 1,
            resizable: false,
            renderCell: (params) => {
                let tracking_number = (params.row.shipment && params.row.shipment.k
                    && typeof params.row.shipment.k !== "undefined" && params.row.shipment.k !== null)
                    ? params.row.shipment.k : "";
                let carrier_name = (params.row.shipment && params.row.shipment.v && params.row.shipment.v.label && params.row.shipment.v.label.carrier_name
                    && typeof params.row.shipment.v.label.carrier_name !== "undefined" && params.row.shipment.v.label.carrier_name !== null)
                    ? params.row.shipment.v.label.carrier_name : "";
                let message = (params.row.shipment && params.row.shipment.v && params.row.shipment.v.manifest &&
                    params.row.shipment.v.manifest.is_closed === false) ? "This shipment manifest is not yet closed" : null;
                let tracking = { "tracking_number": tracking_number, "carrier_name": carrier_name }
                return (
                    <CarrierTracking trackingItems={tracking} dispatchCallback={trackingCallback} carrierImageWidth="60px" message={message} />
                )
            }
        },
        {
            field: 'shipment.v.status',
            headerName: 'Shipment Status',
            flex: 1,
            resizable: false,
            renderCell: (params) => {
                if (params.row && params.row.shipment && params.row.shipment.v && params.row.shipment.v.status) {
                    return (
                        <OrderStatus orderStatus={params.row.shipment.v.status}
                        />
                    )
                }
            }
        },
        {
            field: 'customer.first_name',
            headerName: 'Customer',
            flex: 1,
            resizable: false,
            renderCell: (params) => {
                let name = ""
                if (params.row.customer && typeof params.row.customer.first_name !== "undefined" && params.row.customer.first_name !== null) {
                    name = params.row.customer.first_name;
                }
                if (params.row.customer && typeof params.row.customer.last_name !== "undefined" && params.row.customer.last_name !== null) {
                    name = name + " " + params.row.customer.last_name
                }
                return (
                    <Customer name={name} />
                )
            }
        },
        {
            field: 'shipment.v.product_info.actual_weight',
            headerName: 'Weight',
            width: 80,
            resizable: false,
            renderCell: (params) => {
                if (params.row && params.row.shipment && params.row.shipment.v && params.row.shipment.v.product_info && params.row.shipment.v.product_info.actual_weight) {
                    return params.row.shipment.v.product_info.actual_weight
                } else {
                    return "-"
                }
            }
        },
        {
            field: 'shipment.v.created_at',
            headerName: 'Date',
            flex: 1,
            resizable: false,
            renderCell: (params) => {
                if (params.row && params.row.shipment && params.row.shipment.v && params.row.shipment.v.created_at) {
                    return <Date date={params.row.shipment.v.created_at} />
                } else {
                    return "-"
                }
            }
        }]

    useEffect(() => {
        setMeta({
            ...meta,
            ...{
                page: 1,
                per_page: 10,
                start_date: "",
                end_date: "",
                order_id: "",
                order_status: [],
                receiver_province: "",
                receiver_country_code: "",
                tracking_number: "",
                carrier_name: [],
                platform_tags: [],
                customer_name: "",
                goods_out_note: "",
            }
        });
    }, [dispatch, manifestId])


    useEffect(() => {
        if (manifestId !== '') {
            dispatch(doGetDeliveries({
                ...meta,
                ...{
                    "shippingchimp_manifest_number": manifestId,
                    "shipment_type": shipmentType
                }
            }))
        }
    }, [manifestId, shipmentType, meta])

    const onSortChange = (currentSorting) => {
        setMeta({
            ...meta,
            ...{
                sorting: JSON.stringify(currentSorting)
            }
        });
    }

    const onChangePerPage = (event) => {
        setMeta({
            ...meta,
            ...{
                page: 1,
                per_page: event.target.value
            }
        });
    }

    const onChangePagination = (event, page) => {
        setMeta({
            ...meta,
            ...{
                page: page
            }
        });
    }

    function CustomTotalRows() {

        return (
            <div className="custom-total-rows">
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    Showing {deliveries && deliveries.deliveriesData && deliveries.deliveriesData.data && deliveries.deliveriesData.data.length} of {(deliveries && deliveries.deliveriesData && deliveries.deliveriesData.meta && deliveries.deliveriesData.meta.total) ? deliveries.deliveriesData.meta.total : 0}
                </FormControl>
            </div>
        )
    }


    function CustomPagesizeoptions() {

        return (
            <div className="custom-page-size-options">
                <span>
                    Show <select
                        value={meta && meta.per_page}
                        onChange={onChangePerPage}
                    >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </select> entries
                </span>
            </div>
        )
    }

    function CustomToolbar() {

        return (
            <GridToolbarContainer>
                <CustomPagesizeoptions />
                <CustomTotalRows />
            </GridToolbarContainer>
        );
    }

    function CustomPagination() {

        return (
            <Pagination
                color="primary"
                shape="circular"
                page={deliveries && deliveries.deliveriesData && deliveries.deliveriesData.meta && deliveries.deliveriesData.meta.current_page}
                count={deliveries && deliveries.deliveriesData && deliveries.deliveriesData.meta && deliveries.deliveriesData.meta.last_page}
                onChange={onChangePagination}
            />
        );
    }

    const trackingCallback = (carriername, trackingNumber, message = null) => {
        setTrackingMessage(message)
        let apikey = (profile && profile.profile && profile.profile.api_token) ? profile.profile.api_token : "";
        const data = { "carrier_name": carriername, 'tracking_number': trackingNumber }
        dispatch(fetchTrackingDetail(data, apikey))
        setTrackingDrawerVisible(true)
    }
    const onClose = (values) => {
        setTrackingDrawerVisible(false);
    };

    return (
        <>
            <Drawer
                anchor="right"
                open={visible}
                onClose={ondrawerclose}
                PaperProps={{
                    sx: { width: "70%" },
                }}
            >
                
                <div style={{ padding: "0 30px" }}>
                    <Card sx={{ marginTop: "20px" }} className="sc--cardhead--details">
                        <div style={{ width: '100%', background: '#fff' }}>
                            <CardHeader title="Shipment Details" action={
                                 <div>
                                 <IconButton aria-label="settings" onClick={ondrawerclose}>
                                     <CloseIcon />
                                 </IconButton>
                             </div>
                            }
                            />
                            <DataGrid
                                style={{ paddingTop: "37px" }}
                                getRowId={(row) => row._id + Math.random()}
                                rows={deliveries && deliveries.deliveriesData && deliveries.deliveriesData.data}
                                columns={columns}
                                autoHeight={true}
                                disableColumnFilter={true}
                                disableColumnMenu={true}
                                sortingMode="server"
                                onSortModelChange={(newSortModel) => onSortChange(newSortModel)}
                                sortModel={meta && meta.sorting && JSON.parse(meta.sorting)}
                                disableRowSelectionOnClick
                                slots={{
                                    loadingOverlay: LinearProgress,
                                    pagination: CustomPagination,
                                    toolbar: CustomToolbar,
                                    NorowsData
                                }}
                                loading={
                                    (deliveries && deliveries.deliveriesData.loading)
                                }
                            />
                        </div>
                    </Card>
                    <TrackingDrawer
                        visible={trackingDrawerVisible} message={trackingMessage} onClose={onClose}
                    />
                    <OrderDrawer
                        visible={orderDrawerVisible} onClose={orderOnClose}
                    />
                </div>
            </Drawer >
        </>
    )
}


export default PendingShipmentsDrawer;