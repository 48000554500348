import { createSlice } from '@reduxjs/toolkit';
import { LABELGENAPI } from '../../../API';
import axios from 'axios';

/* canceling previous requests if multiple requests is triggered */
const CancelToken = axios.CancelToken;
let source = CancelToken.source();

export const invoiceslice = createSlice({
    name: 'invoice',
    initialState: {
        invoiceData: {
            data: [],
            loading: false,
            meta: {
                page: 1,
                per_page: 10,
            },
            notification: { show: false, messageContent: "", messageType: "success" }
        },
        invoiceDetail: {
            data: [],
            loading: false,
            meta: {
                page: 1,
                per_page: 10,
            },
            notification: { show: false, messageContent: "", messageType: "success" }
        },
        instantCharge: {
            loading: false,
            notification: { show: false, messageContent: "", messageType: "success" }
        }
    },
    reducers: {
        getInvoice: (state) => {
            return {
                ...state,
                invoiceData: {
                    ...state.invoiceData,
                    data: [],
                    loading: true
                }
            }
        },
        getInvoiceSuccess: (state, action) => {
            return {
                ...state,
                invoiceData: {
                    ...state.invoiceData,
                    data: action && action.payload && action.payload.data && action.payload.data.data,
                    meta: action && action.payload && action.payload.data && action.payload.data.meta,
                    loading: false,
                    notification: { show: true, messageContent: action && action.payload && action.payload.data && action.payload.data.message && [action.payload.data.message], messageType: "success" }
                }
            }
        },
        getInvoiceFailure: (state, action) => {
            return {
                ...state,
                invoiceData: {
                    ...state.invoiceData,
                    loading: false,
                    notification: { show: true, messageContent: action && action.payload && action.payload.data && action.payload.data.message && [action.payload.data.message], messageType: "error" }
                }
            }
        },
        getInvoiceDetail: (state) => {
            return {
                ...state,
                invoiceDetail: {
                    ...state.invoiceDetail,
                    data: [],
                    loading: true
                }
            }
        },
        getInvoiceDetailSuccess: (state, action) => {
            return {
                ...state,
                invoiceDetail: {
                    ...state.invoiceDetail,
                    data: action && action.payload && action.payload.data && action.payload.data.data,
                    meta: action && action.payload && action.payload.data && action.payload.data.meta,
                    loading: false,
                    notification: { show: true, messageContent: action && action.payload && action.payload.data && action.payload.data.message && [action.payload.data.message], messageType: "success" }
                }
            }
        },
        getInvoiceDetailFailure: (state, action) => {
            return {
                ...state,
                invoiceDetail: {
                    ...state.invoiceDetail,
                    loading: false,
                    notification: { show: true, messageContent: action && action.payload && action.payload.data && action.payload.data.message && [action.payload.data.message], messageType: "error" }
                }
            }
        },
        postInstantCharge: (state) => {
            return {
                ...state,
                instantCharge: {
                    ...state.instantCharge,
                    loading: true
                }
            }
        },
        postInstantChargeSuccess: (state, action) => {
            return {
                ...state,
                instantCharge: {
                    ...state.instantCharge,
                    loading: false,
                    notification: { show: true, messageContent: action && action.payload && action.payload.data && action.payload.data.message && [action.payload.data.message], messageType: "success" }
                }
            }
        },
        postInstantChargeFailure: (state, action) => {
            return {
                ...state,
                instantCharge: {
                    ...state.instantCharge,
                    loading: false,
                    notification: { show: true, messageContent: action && action.payload && action.payload.data && action.payload.data.message && [action.payload.data.message], messageType: "error" }
                }
            }
        },
        resetInvoiceNotification: (state, action) => {
            return {
                ...state,
                invoiceData: {
                    ...state.invoiceData,
                    notification: { show: false, messageContent: "", messageType: "success" }
                },
                invoiceDetail: {
                    ...state.invoiceDetail,
                    notification: { show: false, messageContent: "", messageType: "success" }
                },
                instantCharge: {
                    ...state.instantCharge,
                    notification: { show: false, messageContent: "", messageType: "success" }
                }
            }
        }


    }
})

export const { getInvoice, getInvoiceSuccess, getInvoiceFailure, 
    getInvoiceDetail, getInvoiceDetailSuccess, getInvoiceDetailFailure, resetInvoiceNotification, postInstantCharge, postInstantChargeSuccess, postInstantChargeFailure } = invoiceslice.actions;

export const fetchInvoice = (meta) => dispatch => {

    const qs = Object.keys(meta)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(meta[key])}`)
        .join('&');

    dispatch(getInvoice());
    try {
        source && source.cancel('Operation canceled due to new request.');
        source = axios.CancelToken.source();
        LABELGENAPI.get('/invoice/details?' + qs, {
            cancelToken: source.token
        }).then(function (response) {
            dispatch(getInvoiceSuccess(response));
        })
            .catch(function (error) {
                if (!axios.isCancel(error)) {
                    dispatch(getInvoiceFailure(error.response));
                }
            });
    } catch (error) {
        if (!axios.isCancel(error)) {
            dispatch(getInvoiceFailure(error.response));
        }
    }
};

export const fetchInvoiceDetail = (id, meta) => dispatch => {
    const qs = Object.keys(meta)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(meta[key])}`)
        .join('&');
    dispatch(getInvoiceDetail());
    try {
        source && source.cancel('Operation canceled due to new request.');
        source = axios.CancelToken.source();
        LABELGENAPI.get('/invoice/details/' + id + '?' + qs, {
            cancelToken: source.token
        }).then(function (response) {
            dispatch(getInvoiceDetailSuccess(response));
        })
            .catch(function (error) {
                if (!axios.isCancel(error)) {
                    dispatch(getInvoiceDetailFailure(error.response));
                }
            });
    } catch (error) {
        if (!axios.isCancel(error)) {
            dispatch(getInvoiceDetailFailure(error.response));
        }
    }
};

export const instantChargeInvoice = (data, meta) => dispatch => {
    dispatch(postInstantCharge());
    try {
        LABELGENAPI.post('payment/instant-charge', data)
            .then(function (response) {
                dispatch(postInstantChargeSuccess(response));
                dispatch(fetchInvoice(meta));
            }).catch(function (error) {
                dispatch(postInstantChargeFailure(error.response));
            })
    }
    catch (error) {
        dispatch(postInstantChargeFailure(error.response));
    }
}

export const resetInvoiceNotifications = () => {
    return (dispatch) => {
        dispatch(resetInvoiceNotification());
    }
}

export default invoiceslice.reducer;
