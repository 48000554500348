import React from "react";

const Carrier = ({ carrierName = null, width = null }) => {

    return (
        <>
            {width == null && carrierName != null && carrierName != "" &&  carrierName !== "ShippingChimp" && <div className={`user-card ${carrierName.toUpperCase()}`}><div><img src={"/assets/images/carriers/" + carrierName.toUpperCase().replace(/ /g, "_") + ".jpg"} onError={(e)=>{e.target.onerror = null; e.target.src="/assets/images/carriers/OTHERS.jpg"}} alt={carrierName} /></div></div>}
            {width != null && carrierName != null && carrierName != "" &&  carrierName !== "ShippingChimp" && <div className={`user-card`}><div><img src={"/assets/images/carriers/" + carrierName.toUpperCase().replace(/ /g, "_") + ".jpg"} onError={(e)=>{e.target.onerror = null; e.target.src="/assets/images/carriers/OTHERS.jpg"}} alt={carrierName} style={{ maxWidth: width }} /></div></div>}
            {(carrierName == null || carrierName == "") && <div className={`user-card`}><div>-</div></div>}
            {width !== null && carrierName !== null && carrierName !== "" && carrierName === "ShippingChimp" && <div className={`user-card`}><div><img src={"/assets/images/shippingchimp_logo.png"} alt={carrierName} style={{ maxWidth: width }} /></div></div>}
        </>
    )

}

export default Carrier;
