import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import _ from 'lodash';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { Tooltip,MenuItem,FormControl,InputLabel,Select } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import {
    DataGrid,
} from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import FormHelperText from '@mui/material/FormHelperText';
import CarrierTracking from './CarrierTracking'
import LinearProgress from '@mui/material/LinearProgress';
import NorowsData from '../../pages/General/NorowsData'
import CountryDropdown from '../../pages/General/CountryDropdown'
import ProvinceDropdown from '../../pages/General/ProvinceDropdown'
import AddLineItem from '../../pages/General/NewOrder/AddLineItem';
import Notification from '../../pages/General/Notification';
import { orderUpdateData,resetOrderNotifications,updateBulkDimension,resetOrderUpdateNotifications,postBulkOrderValidation } from '../../pages/Orders/ordersSlice';
import {
    fetchOrderDetail
} from '../../pages/OrderDetail/orderdetailsSlice';
const getUniqueId = () => {
    return "SCIT" + Date.now().toString(36) + Math.random().toString(36).substr(2)
}
const OrderDetailsEditDrawer = ({ visible,onClose,values,dispatchInputChangeCallback,errors,lineItemsData,dispatchCountryCallback,dispatchProvinceCallback, invalidDocIds = null  }) => {
    const dispatch = useDispatch();
    const orders = useSelector(state => state.orders);
    const box = useSelector(state => state.box)
    const orderDetails = useSelector(state => state.orderdetails);
    const orderUpdate = useSelector(state => state.orders && state.orders.orderUpdate);
    const [editLineItemId, setEditLineItemId] = useState("")
    const [addLineItemVisible, setAddLineItemVisible] = useState(false)
    const [removeLineItemId, setRemoveLineItemId] = useState("")
    const [customLineItems, setCustomLineItems] = useState(lineItemsData)
    const [updateLineItems, setUpdateLineItems] = useState(lineItemsData)
    const [boxData, setBoxData] = useState([])
    const initialBoxValues = {
        "_id":0,
        "box_name": 'custom',
        "box_length": "",
        "box_width": "",
        "box_height": "",
        "box_dimension_unit": "",
        "box_actual_weight": "",
        "box_weight_unit": "",
        "insurance_amount":"100"
    }
    const [dimensionValue, setDimensionValue] = useState(initialBoxValues);
    const [dimensionsErrors, setDimensionsErrors] = useState({});
    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: (option) => option.box_name,
      });

    const getChooseMeasurement = (currentvalue) => {
    let filteredCurrentValue = {};
    let tempValue = "";
    boxData &&
    boxData.map((key) => {
        if (key._id == currentvalue && tempValue == "") {
            filteredCurrentValue = key;
            tempValue = "success";
        }
        });
    return filteredCurrentValue;
    };

    const onBoxDimensionChange=(event)=>{
        let packageFieldData = {...dimensionValue};
        if(event.target.name==='choosenmeasurement'){
            if (event.target.value != 0) {
                box && box.data && box.data.map((boxData) => {
                    if (boxData._id === event.target.value) {
                        setDimensionValue({
                            ...dimensionValue,
                            "_id": event.target.value,
                            "box_name": boxData.box_name,
                            "box_length": parseFloat(boxData.box_length).toFixed(2),
                            "box_width": parseFloat(boxData.box_width).toFixed(2),
                            "box_height": parseFloat(boxData.box_height).toFixed(2),
                            "box_dimension_unit": boxData.box_length_unit,
                            "box_actual_weight": parseFloat(boxData.box_weight).toFixed(2),
                            "box_weight_unit": boxData.box_weight_unit,
                            "insurance_amount": "100"
                        });
                        setDimensionsErrors({})
                    }
                })
            }else{
                setDimensionValue({
                    ...dimensionValue,
                    "_id":0,
                    "box_name": 'custom',
                    "box_length": "",
                    "box_width": "",
                    "box_height": "",
                    "box_dimension_unit": "",
                    "box_actual_weight": "",
                    "box_weight_unit": "",
                    "insurance_amount": "100"
                });
                setDimensionsErrors({})
            }
        }else{
            packageFieldData[event.target.name] = event.target.value;
            setDimensionValue(packageFieldData);
            validator({ [event.target.name]: event.target.value })
        }
    }
    const validator = (fieldValues) => {
        let temp = { ...dimensionsErrors }
        if ('box_name' in fieldValues)
            temp.box_name = fieldValues.box_name ? "" : "This field is required."
        if (fieldValues && fieldValues.box_name && fieldValues.box_name.length < 3)
            temp.box_name = "Box Name required minimum 3 characters"
        if ('box_length' in fieldValues)
            temp.box_length = fieldValues.box_length ? "" : "This field is required."
        if (fieldValues && fieldValues.box_length && fieldValues.box_length <= 0)
            temp.box_length = "Length is not a negative number or 0"
        if ('box_dimension_unit' in fieldValues)
            temp.box_dimension_unit = fieldValues.box_dimension_unit ? "" : "This field is required."
        if ('box_width' in fieldValues)
            temp.box_width = fieldValues.box_width ? "" : "This field is required."
        if (fieldValues && fieldValues.box_width && fieldValues.box_width <= 0)
            temp.box_width = "Width is not a negative number  or 0"
        if ('box_height' in fieldValues)
            temp.box_height = fieldValues.box_height ? "" : "This field is required."
        if (fieldValues && fieldValues.box_height && fieldValues.box_height <= 0)
            temp.box_height = "Height is not a negative number  or 0"
        if ('box_actual_weight' in fieldValues)
            temp.box_actual_weight = fieldValues.box_actual_weight ? "" : "This field is required."
        if (fieldValues && fieldValues.box_actual_weight && fieldValues.box_actual_weight <= 0)
            temp.box_actual_weight = "Weight is not a negative number  or 0"
        if ('box_weight_unit' in fieldValues)
            temp.box_weight_unit = fieldValues.box_weight_unit ? "" : "This field is required."

        setDimensionsErrors({
            ...temp
        })

        if (fieldValues === dimensionValue)
            return Object.values(temp).every(x => x == "")
    }

    const addBulkDimensionsForm =(ids,values)=>{
        const isValid = validator(values)
        if (isValid) {
            let docid={'docids':ids}
            Object.assign(values, docid);
            dispatch(updateBulkDimension(values))
            setDimensionValue(initialBoxValues)
            setDimensionsErrors({})
        }
    }

    useEffect(() => {
        if (orderDetails && orderDetails.data && orderDetails.data.length!==0 && orderDetails.data.product_info) {
            orderDetails.data.product_info.map((item) => {
                setDimensionValue({
                    ...dimensionValue,
                    "_id": 0,
                    "box_name": "",
                    "box_length": item && item.dimensions && item.dimensions.length && item.dimensions.length >0 ? parseFloat(item.dimensions.length).toFixed(2) : 0,
                    "box_width": item &&  item.dimensions.width && item.dimensions.width>0 ? parseFloat(item.dimensions.width).toFixed(2) : 0,
                    "box_height": item && item.dimensions && item.dimensions.height && item.dimensions.height >0 ? parseFloat(item.dimensions.height).toFixed(2) : 0,
                    "box_dimension_unit": item && item.dimensions && item.dimensions.height_unit!=='' ? item.dimensions.height_unit :"",
                    "box_actual_weight": item && item.actual_weight >0 ?parseFloat(item.actual_weight).toFixed(2) : 0,
                    "box_weight_unit": item && item.weight_unit!=='' ? item.weight_unit :"",
                    "insurance_amount": item && item.insurance_amount && item.insurance_amount ? item.insurance_amount : "100"
                });
            })
        }
    }, [orderDetails])

    const [addLineItemData, setaddLineItemData] = useState({
        "line_item_id": getUniqueId(),
        "product_id": "",
        "product_desc": "",
        "product_url": "",
        "product_price": "",
        "hs_tariff_code": "",
        "hs_tariff_code_country": "",
        "edit_line_item_id": "",
        "quantity": 1,
        "tracking_number":"",
        "origin_country_code":""
    })

    const [lineItemErrors, setLineItemsErrors] = useState({
        "line_item_id": "",
        "product_id": "",
        "product_desc": "",
        "product_url": "",
        "product_price": "",
        "hs_tariff_code": "",
        "hs_tariff_code_country": "",
        "quantity": "",
        "origin_country_code":""
    });

    const handleInputChange = e => {
        const { name, value } = e.target
        dispatchInputChangeCallback(name, value)
    }

    const ondrawerclose = () => {
        onClose(false);
    }

    const isFloatValue = (value) => {
        value = Number(value);
        if (!isNaN(value) && value.toString().indexOf('.') != -1) {
            return true;
        }
        return false;
    }
    const isIntegerValue = (value) => {
        value = Number(value);
        if (Number.isInteger(value)) {
            return true;
        }
        return false;
    }
    const validateLineItemData = () => {
        let isValid = true
        let lineItemErrorsTemp = {
            "line_item_id": "",
            "product_id": "",
            "product_desc": "",
            "product_url": "",
            "product_price": "",
            "hs_tariff_code": "",
            "hs_tariff_code_country": "",
            "origin_country_code":"",
            "quantity": ""
        };

        if (addLineItemData.product_id == "" || addLineItemData.product_id.length >= 25) {
            isValid = false
            lineItemErrorsTemp.product_id = "Product id is required";
        }

        if (addLineItemData.product_desc == "" || addLineItemData.product_desc.length <= 1 || addLineItemData.product_desc.length >= 100) {
            isValid = false
            lineItemErrorsTemp.product_desc = "Product desc is required and should be more than 5 character long";
        }

        if (addLineItemData.product_url == "" || addLineItemData.product_url.length >= 250) {
            isValid = false
            lineItemErrorsTemp.product_url = "Product url is required and should be valid";
        }

        if (addLineItemData.product_price == "" || (!isFloatValue(addLineItemData.product_price) && !isIntegerValue(addLineItemData.product_price))) {
            isValid = false
            lineItemErrorsTemp.product_price = "Product price is required and should be valid";
        }

        if (addLineItemData.quantity == "" || !isIntegerValue(addLineItemData.quantity)) {
            isValid = false
            lineItemErrorsTemp.quantity = "Product quantity is required and should be a valid";
        }

        setLineItemsErrors(lineItemErrorsTemp)
        return isValid
    }

    const formDataHandler = e => {
        const { name, value } = e.target
        setaddLineItemData({
            ...addLineItemData,
            [name]: value
        })
        validateLineItemData();
    }

    const onAddLineItemModalClose = () => {
        setRemoveLineItemId("")
        setEditLineItemId("")
        setaddLineItemData({
            "line_item_id": getUniqueId(),
            "product_id": "",
            "product_desc": "",
            "product_url": "",
            "product_price": "",
            "hs_tariff_code": "",
            "hs_tariff_code_country": "",
            "edit_line_item_id": "",
            "quantity": 1,
            "tracking_number":"",
            "origin_country_code":""
        })
        setLineItemsErrors({
            "line_item_id": "",
            "product_id": "",
            "product_desc": "",
            "product_url": "",
            "product_price": "",
            "hs_tariff_code": "",
            "hs_tariff_code_country": "",
            "origin_country_code":"",
            "quantity": ""
        })
        setAddLineItemVisible(false)
    }

    const dispatchCountryCode = (id) => {
        setaddLineItemData({
            ...addLineItemData,
            ['origin_country_code']: id
        })
        validateLineItemData();
    }

    const onAddLineItemClick = () => {
        if (validateLineItemData()) {
            let customLineItemData = [...customLineItems];
            if (_.isUndefined(_.find(customLineItems, ['product_id', addLineItemData.product_id])) && addLineItemData.edit_line_item_id == "") {
                addLineItemData.tracking_number=' ';
                addLineItemData.edit_line_item_id=' ';
                customLineItemData.push(addLineItemData)
                setCustomLineItems(customLineItemData)
                setaddLineItemData({
                    "line_item_id": getUniqueId(),
                    "product_id": "",
                    "product_desc": "",
                    "product_url": "",
                    "product_price": "",
                    "hs_tariff_code": "",
                    "hs_tariff_code_country": "",
                    "edit_line_item_id": "",
                    "origin_country_code":"",
                    "quantity": 1
                })
            } else if (addLineItemData.edit_line_item_id != "") {
                let editCheckData = _.find(customLineItems, ['line_item_id', addLineItemData.edit_line_item_id])
                if (editCheckData["product_id"] != addLineItemData.product_id && !_.isUndefined(_.find(customLineItems, ['product_id', addLineItemData.product_id]))) {
                    console.log("Product already exists")
                    setLineItemsErrors({
                        ...lineItemErrors,
                        [`product_id`]: "Product id already exists"
                    })
                    return false
                }
                let customLineItemData = [...customLineItems];
                let newLineItemData = [];
                customLineItemData.map((item) => {
                    if (item && item.line_item_id && item.line_item_id == addLineItemData.edit_line_item_id) {
                        newLineItemData.push(addLineItemData)
                    } else {
                        newLineItemData.push(item)
                    }
                })
                setCustomLineItems(newLineItemData)
            } else {
                console.log("Product already exists")
                setLineItemsErrors({
                    ...lineItemErrors,
                    [`product_id`]: "Product id already exists"
                })
                return false;
            }
            onAddLineItemModalClose()

        } else {
            console.log("Errors in line items")
        }
    }

    const updateButtonDisable = (item) => {
        let updateButtonDisable = true;
            if (!item.tracking_number || item.tracking_number === "") {
                updateButtonDisable = false;
            }
        return updateButtonDisable;
    }
    const onLineItemsChange = (items) => {
        if (items) {
            let productLineItem = []
            items && items.map((item) => {
                if (item) {
                    let product_id = 1;
                    if(item && item.product_id && item.product_id != ""){
                        product_id = item.product_id;
                    }else if(item && item.sku && item.sku != ""){
                        product_id = item.sku;
                    }
                    productLineItem.push({
                        "line_item_id": (item && item.line_item_id && item.line_item_id != "") ? item.line_item_id : "1",
                        "product_id": product_id,
                        "product_desc": (item && item.product_desc && item.product_desc != "") ? item.product_desc : "Product 1",
                        "product_url": (item && item.product_url && item.product_url != "") ? item.product_url : "https://www.shippingchimp.com/",
                        "product_price": (item && item.product_price && item.product_price != "") ? item.product_price : "100",
                        "hs_tariff_code": (item && item.hs_tariff_code && item.hs_tariff_code != "") ? item.hs_tariff_code : "",
                        "hs_tariff_code_country": (item && item.hs_tariff_code_country && item.hs_tariff_code_country != "") ? item.hs_tariff_code_country : "",
                        "quantity": (item && item.quantity && item.quantity != "") ? item.quantity : 1,
                        "tracking_number": (item && item.tracking_number && item.tracking_number != "" ) ? item.tracking_number : "",
                        "origin_country_code":(item && item.origin_country_code && item.origin_country_code != "") ? item.origin_country_code : ""
                    })
                }
            })
            setCustomLineItems(productLineItem)
        }
    }

    const handleSubmit=()=>{
        let originalData=updateLineItems
        let originalData2=[]
        customLineItems && customLineItems.map((item) => {
            for (let element = 0; element <= originalData.length; element++) {
                if(originalData && originalData[element] && originalData[element].line_item_id===item.line_item_id){
                    let product_desc=item.product_desc
                    let product_id=item.product_id
                    let product_url=item.product_url
                    let product_price=item.product_price
                    let quantity=item.quantity
                    let origin_country_code=item.origin_country_code
                    let hs_tariff_code=item.hs_tariff_code
                    let hs_tariff_code_country=item.hs_tariff_code_country
                    originalData2.push({...originalData[element],product_desc,product_id,product_url,product_price,quantity,origin_country_code,hs_tariff_code,hs_tariff_code_country})
                    break
                }
            }
            let checkExists = originalData2.some(el => el.line_item_id === item.line_item_id);
            if(checkExists===false){
                originalData2.push(item)
            }
        })
        let orderid=orderDetails && orderDetails.data && orderDetails.data.order_details && orderDetails.data.order_details.orderid
        let data={
            "lineItems":originalData2,
            "customer_info":values,
            "doc_id":orderDetails && orderDetails.data && orderDetails.data.doc_id,
            "orderid":orderid,
        }
        Object.assign(data, dimensionValue);
        dispatch(orderUpdateData(data,orderid))
    }

    useEffect(() => {
        if (lineItemsData.length != 0) {
            onLineItemsChange(lineItemsData)
        }
    }, [lineItemsData])

    useEffect(() => {
        if (orderUpdate && orderUpdate.notification && orderUpdate.notification.messageContent && orderUpdate.notification.messageContent[0] === 'Order updated successfully') {
            dispatch(fetchOrderDetail(orderDetails && orderDetails.data && orderDetails.data.order_details && orderDetails.data.order_details.orderid));
            ondrawerclose()
            let orderDocIdDatas=[];
            if(invalidDocIds!==null && Object.keys(invalidDocIds).length>0){
                if(invalidDocIds.invalid_order_docids && invalidDocIds.invalid_order_docids.length>0){
                    invalidDocIds.invalid_order_docids.map((item) => {
                        orderDocIdDatas.push(item.docIds)
                    })
                }
                if(invalidDocIds.valid_order_docids && invalidDocIds.valid_order_docids.length>0){
                    invalidDocIds.valid_order_docids.map((item) => {
                        orderDocIdDatas.push(item)
                    })
                }
                let final_data={
                    "order_docids":orderDocIdDatas,
                    "request_type":"CREATE_SHIPMENT"
                }
                if(orderDocIdDatas.length>0){
                    dispatch(postBulkOrderValidation(final_data))
                }
            }
        }
    }, [orderUpdate])

    useEffect(() => {
        if (removeLineItemId != "" && removeLineItemId != "undefined") {
            let customLineItemData = [...customLineItems];
            let newLineItemData = [];
            customLineItemData.map((item) => {
                if (item && item.line_item_id && item.line_item_id != removeLineItemId) {
                    newLineItemData.push(item)
                }
            })
            setCustomLineItems(newLineItemData)
        }
    }, [removeLineItemId])

    useEffect(() => {
        if (editLineItemId != "" && editLineItemId != "undefined" && !_.isUndefined(_.find(customLineItems, ['line_item_id', editLineItemId]))) {
            console.log("Found edit")
            let editData = _.find(customLineItems, ['line_item_id', editLineItemId])
            editData[`edit_line_item_id`] = editLineItemId
            setaddLineItemData(editData)
            setAddLineItemVisible(true)
        }
    }, [editLineItemId])

    useEffect(() => {
        let tempBoxData=[]
        let customValue={"_id":0,"box_name":"custom"}
        tempBoxData.push(customValue)
        let boxOriginalData = box && box.data.filter(function( obj ) {
            return  obj.hide !== true;
            });
        if (boxOriginalData.length !== 0) {
            boxOriginalData.map((item) => {
                tempBoxData.push(item)
            })
            setBoxData(tempBoxData)
        }else{
            setBoxData(tempBoxData)
        }
    }, [box && box.data]);

    const columns = [
        {
            field: 'product_id',
            headerName: 'Product Id',
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                if (params.row.product_id && typeof params.row.product_id !== "undefined") {
                    return params.row.product_id
                } else {
                    return "-"
                }
            }
        },
        {
            field: 'product_desc',
            headerName: 'Product Desc',
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                if (params.row.product_desc && typeof params.row.product_desc !== "undefined") {
                    return <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{params.row.product_desc}</span>
                } else {
                    return "-"
                }
            }
        },
        {
            field: 'tracking_number',
            headerName: 'Tracking Number',
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                let tracking_number = (params.row.tracking_number && typeof params.row.tracking_number !== "undefined" && params.row.tracking_number !== null)
                    ? params.row.tracking_number : "";
                let carrier_name = (params.row.fulfilment_company && typeof params.row.fulfilment_company !== "undefined" && params.row.fulfilment_company !== null)
                    ? params.row.fulfilment_company : "";
                let tracking = { "tracking_number": tracking_number, "carrier_name": carrier_name }
                return (
                    <CarrierTracking trackingItems={tracking} carrierImageWidth="60px" />
                )
            }
        },
        {
            field: 'quantity',
            headerName: 'Quantity',
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                if (params.row.quantity && typeof params.row.quantity !== "undefined") {
                    return params.row.quantity
                } else {
                    return "-"
                }
            }
        }, {
            field: 'action',
            headerName: 'Action',
            headerClassName: 'super-app-theme--header',
            width: 100,
            hide: (customLineItems.length == 0),
            renderCell: (params) => {
                if (params.row && params.row.line_item_id && params.row.line_item_id != "") {
                    return <>
                        <span>
                            <Tooltip title="Edit" placement="left-start" disableFocusListener={false}>
                                <IconButton>
                                    <EditIcon style={{  color: 'rgba(0,0,0,.54)', cursor: updateButtonDisable(params.row) ? 'not-allowed' : 'pointer', pointerEvents: updateButtonDisable(params.row) ? 'auto' : 'auto' }} onClick={() => {
                                        setEditLineItemId(params.row.line_item_id)
                                        }
                                        } />
                                </IconButton>
                            </Tooltip>
                        </span>
                        <span>
                            <Tooltip title="Delete" placement="left-start" disableFocusListener={false}>
                                <IconButton>
                                    <DeleteOutlineOutlinedIcon style={{  color: 'rgba(0,0,0,.54)', cursor: updateButtonDisable(params.row) ? 'not-allowed' : 'pointer', pointerEvents: updateButtonDisable(params.row) ? 'none' : 'auto' }}
                                        onClick={() => setRemoveLineItemId(params.row.line_item_id)}
                                    />
                                </IconButton>
                            </Tooltip>
                        </span>
                    </>;
                } else {
                    return <>-</>;
                }
            }
        }
    ]
    
    return (
        <>
            <Drawer
                anchor="right"
                open={visible}
                onClose={ondrawerclose}
                PaperProps={{
                    sx: { width: "70%" },
                }}
            >
               
                {orderDetails && orderDetails.loading === true && <>
                    <Box style={{
                        position: 'absolute', left: '50%', top: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}>
                        <CircularProgress />
                    </Box>
                </>
                }
                <AddLineItem
                    addLineItemVisible={addLineItemVisible}
                    onAddLineItemModalClose={onAddLineItemModalClose}
                    formDataHandler={formDataHandler}
                    dispatchCountryCode={dispatchCountryCode}
                    onAddLineItemClick={onAddLineItemClick}
                    addLineItemData={addLineItemData}
                    editLineItemId={editLineItemId}
                    errors={lineItemErrors}
                />
                {orderDetails && orderDetails.loading === false &&
                <div style={{ padding: "0 30px" }}>
                    <Card sx={{ margin: '20px 0' }} className="sc--cardhead--details">
                            <CardHeader title="Order Details Update" action={
                                <div>
                                    <IconButton aria-label="settings" onClick={ondrawerclose}>
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                            } />
                             {(orderUpdate && orderUpdate.loading === true || orderUpdate.loading === true) &&
                                  <LinearProgress />
                            }
                            <Divider />
                            <CardContent>
                                <>
                                    <Grid
                                        container
                                        direction='row'
                                        spacing={1}
                                        alignnewOrderFormDatas="stretch"
                                >
                                        <Grid item xs={12} md={6} style={{ padding: '10px' }}>
                                            <Card sx={{ maxWidth: 1200 }}>
                                                <CardHeader title='Customer Information' className="sc--cardhead--details" />
                                                <Divider />
                                                <CardContent style={{ paddingBottom: '20px',paddingLeft:'16px',paddingRight:'16px' }}>
                                                <div className="sc--form--center">
                                                        <TextField id="standard-basic"
                                                            label="First name"
                                                            variant="standard"
                                                            name="customer_firstname"
                                                            value={values.customer_firstname}
                                                            onChange={handleInputChange}
                                                            error={errors.customer_firstname}
                                                            helperText={errors.customer_firstname}
                                                            style={{ width: "50%",marginRight: '8px' }} 
                                                        />
                                                        <TextField id="standard-basic"
                                                            label="Last Name"
                                                            variant="standard"
                                                            name="customer_lastname"
                                                            value={values.customer_lastname}
                                                            onChange={handleInputChange}
                                                            error={errors.customer_lastname}
                                                            helperText={errors.customer_lastname}
                                                            style={{ width: "50%" }} 
                                                        />
                                                    </div>
                                                    <TextField id="standard-basic"
                                                        label="Email"
                                                        variant="standard"
                                                        name="customer_email"
                                                        value={values.customer_email}
                                                        onChange={handleInputChange}
                                                        error={errors.customer_email}
                                                        helperText={errors.customer_email}
                                                        style={{ width: "100%" }} 
                                                    />
                                                    <TextField id="standard-basic"
                                                        label="Phone"
                                                        variant="standard"
                                                        name="customer_phone"
                                                        value={values.customer_phone}
                                                        onChange={handleInputChange}
                                                        error={errors.customer_phone}
                                                        helperText={errors.customer_phone}
                                                        style={{ width: "100%" }} 
                                                    />
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} md={6} style={{ padding: '10px' }}>
                                            <Card sx={{ maxWidth: 1200 }}>
                                                <CardHeader title='DELIVERY ADDRESS' className="sc--cardhead--details" />
                                                <Divider />
                                                <CardContent style={{ paddingBottom: '20px',paddingLeft:'16px',paddingRight:'16px' }}>
                                                    <div className="sc--form--center">
                                                        <TextField id="standard-basic"
                                                            label="First name"
                                                            variant="standard"
                                                            name="receiver_first_name"
                                                            value={values.receiver_first_name}
                                                            onChange={handleInputChange}
                                                            error={errors.receiver_first_name}
                                                            helperText={errors.receiver_first_name}
                                                            style={{ width: "50%",marginRight: '8px' }} 
                                                        />
                                                        <TextField id="standard-basic"
                                                            label="Last Name"
                                                            variant="standard"
                                                            name="receiver_last_name"
                                                            value={values.receiver_last_name}
                                                            onChange={handleInputChange}
                                                            error={errors.receiver_last_name}
                                                            helperText={errors.receiver_last_name}
                                                            style={{ width: "50%" }} 
                                                        />
                                                    </div>
                                                    <div className="sc--form--center">
                                                    <TextField id="standard-basic"
                                                        label="Address1"
                                                        variant="standard"
                                                        name="address1"
                                                        value={values.address1}
                                                        onChange={handleInputChange}
                                                        error={errors.address1}
                                                        helperText={errors.address1}
                                                        style={{ width: "50%",marginRight: '8px' }} 
                                                    />
                                                    <TextField id="standard-basic"
                                                        label="Address1"
                                                        variant="standard"
                                                        name="address2"
                                                        value={values.address2}
                                                        onChange={handleInputChange}
                                                        style={{ width: "50%",marginRight: '8px' }} 
                                                    />
                                                    </div>
                                                    <div className="sc--form--center">
                                                    <TextField id="standard-basic"
                                                            label="City"
                                                            variant="standard"
                                                            name="city"
                                                            value={values.city}
                                                            onChange={handleInputChange}
                                                            error={errors.city}
                                                            helperText={errors.city}
                                                            style={{ width: "50%",marginRight: '8px' }} 
                                                        />
                                                        
                                                        <TextField id="standard-basic"
                                                            label="Zip"
                                                            variant="standard"
                                                            name="zip"
                                                            value={values.zip}
                                                            onChange={handleInputChange}
                                                            error={errors.zip}
                                                            helperText={errors.zip}
                                                            style={{ width: "50%",marginRight: '8px' }} 
                                                        />
                                                    </div>
                                                    <div className="sc--form--center">
                                                        <CountryDropdown 
                                                            values={values}
                                                            dispatchCountryCallback={dispatchCountryCallback}
                                                        />
                                                         <ProvinceDropdown 
                                                            values={values}
                                                          dispatchProvinceCallback={dispatchProvinceCallback}
                                                        />
                                                    </div>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} md={12} style={{ padding: '10px' }}>
                                            <Card sx={{ maxWidth: 1200 }}>
                                                <CardHeader title='PRODUCT DIMENSIONS' className="sc--cardhead--details" />
                                                <Divider />
                                                <CardContent style={{ paddingBottom: '20px',paddingLeft:'16px',paddingRight:'16px' }}>
                                                <div className="sc--form--center" style={{ margin: '20px 0' }}>
                                                        <Autocomplete
                                                            className="help-text-error"
                                                            disablePortal
                                                            name="choosenmeasurement"
                                                            id="state-select-demo"
                                                            sx={{ width: "100%",margin:'8px'}}
                                                            options={boxData}
                                                            autoHighlight
                                                            loading={
                                                            (box && box.loading)
                                                            }
                                                            getOptionLabel={(option) => option.box_name=='custom' ? ""+option.box_name+"": ( ""+option.box_name+" ("+option.box_length+option.box_length_unit+"x "+ option.box_width+option.box_width_unit+"x "+ option.box_height+option.box_height_unit+", "+option.box_weight+option.box_weight_unit+")")}
                                                            filterOptions={filterOptions}
                                                            value={getChooseMeasurement(dimensionValue._id)}
                                                            renderOption={(props, option) => (
                                                            <MenuItem {...props} value={option._id} key={option._id}>
                                                                {option.box_name =='custom' ?
                                                                <p style={{ margin: 'unset', color: 'rgba(0, 0, 0, 0.87)' }}>
                                                                    {option.box_name}
                                                                </p>:
                                                                <p style={{ margin: 'unset', color: 'rgba(0, 0, 0, 0.87)' }}>
                                                                {option.box_name}(
                                                                {option.box_length}{option.box_length_unit} <span style={{ color: '#0082ff' }}> x </span>
                                                                                {option.box_width}{option.box_width_unit} <span style={{ color: '#0082ff' }}> x </span>
                                                                                {option.box_height}{option.box_height_unit}<span>, </span>
                                                                                {option.box_weight}{option.box_weight_unit})
                                                                </p>}
                                                            </MenuItem>
                                                            )}
                                                            onChange={(event, value) => {
                                                                if (value !== null) {
                                                                    let event={
                                                                        "target":{
                                                                            "name":"choosenmeasurement",
                                                                            "value":value._id
                                                                        }}
                                                                    onBoxDimensionChange(event);
                                                                } 
                                                            }}
                                                            renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Choose Measurement"
                                                                variant="standard"
                                                            />
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="sc--form--center" style={{ margin: '10px 0' }}>
                                                        <TextField id="standard-basic"
                                                            label="Length"
                                                            variant="standard"
                                                            name="box_length"
                                                            type="number"
                                                            inputProps={{ min: 1 }}
                                                            value={dimensionValue.box_length}
                                                            onChange={event => onBoxDimensionChange(event)}
                                                            error={dimensionsErrors.box_length}
                                                            helperText={dimensionsErrors.box_length}
                                                            style={{ width: '17%' }}
                                                        />
                                                        <TextField id="standard-basic"
                                                            label="Width"
                                                            variant="standard"
                                                            name="box_width"
                                                            type="number"
                                                            inputProps={{ min: 1 }}
                                                            value={dimensionValue.box_width}
                                                            onChange={event => onBoxDimensionChange(event)}
                                                            error={dimensionsErrors.box_width}
                                                            helperText={dimensionsErrors.box_width}
                                                            style={{ width: '17%' }}
                                                        />
                                                        <TextField id="standard-basic"
                                                            label="Height"
                                                            variant="standard"
                                                            name="box_height"
                                                            type="number"
                                                            inputProps={{ min: 1 }}
                                                            value={dimensionValue.box_height}
                                                            onChange={event => onBoxDimensionChange(event)}
                                                            error={dimensionsErrors.box_height}
                                                            helperText={dimensionsErrors.box_height}
                                                            style={{ width: '17%' }}
                                                        />
                                                        <FormControl
                                                            variant="standard"
                                                            className="help-text-error"
                                                            style={{ width: '29%', margin: '8px' }}
                                                        >
                                                            <InputLabel
                                                                style={{ color: dimensionsErrors.box_dimension_unit ? "#D32F2F" : '' }}
                                                            > Dim Unit
                                                        </InputLabel>
                                                            <Select
                                                                name="box_dimension_unit"
                                                                onChange={event => onBoxDimensionChange(event)}
                                                                value={dimensionValue.box_dimension_unit}
                                                                error={dimensionsErrors.box_dimension_unit}
                                                                label="Dimension Unit"
                                                                id="filled-error-helper-text"
                                                            >
                                                                <MenuItem value="in">in</MenuItem>
                                                                <MenuItem value="cm">cm</MenuItem>
                                                            </Select>
                                                            <FormHelperText>{dimensionsErrors.box_dimension_unit}</FormHelperText>
                                                        </FormControl>

                                                    </div>
                                                    <div className="sc--form--center" style={{ margin: '10px 0' }}>
                                                        <TextField id="actual-weight-id"
                                                            label="Actual weight"
                                                            variant="standard"
                                                            name="box_actual_weight"
                                                            type="number"
                                                            inputProps={{ min: 1 }}
                                                            value={dimensionValue.box_actual_weight}
                                                            autoFocus={true}
                                                            onChange={event => onBoxDimensionChange(event)}
                                                            error={dimensionsErrors.box_actual_weight}
                                                            helperText={dimensionsErrors.box_actual_weight}
                                                            style={{ width: '50%' }} />

                                                        <FormControl
                                                            variant="standard"
                                                            className="help-text-error"
                                                            style={{ width: '30%', margin: '8px' }}
                                                        >
                                                            <InputLabel
                                                                id="demo-simple-select-standard-label"
                                                                style={{ color: dimensionsErrors.box_weight_unit ? "#D32F2F" : '' }}
                                                            > Weight Unit
                                                        </InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-standard-label"
                                                                name="box_weight_unit"
                                                                onChange={event => onBoxDimensionChange(event)}
                                                                value={dimensionValue.box_weight_unit}
                                                                label="Weight Unit"
                                                            >
                                                                <MenuItem value="lb">lb</MenuItem>
                                                                <MenuItem value="kg">kg</MenuItem>

                                                            </Select>
                                                            <FormHelperText>{dimensionsErrors.box_weight_unit}</FormHelperText>
                                                        </FormControl>
                                                    </div>
                                                    <div className="sc--form--center" style={{ margin: '10px 0' }}>
                                                        <TextField id="insurance-amount-id"
                                                            label="Insurance Amount (Optional)"
                                                            variant="standard"
                                                            name="insurance_amount"
                                                            type="number"
                                                            inputProps={{ min: 1 }}
                                                            value={dimensionValue.insurance_amount}
                                                            autoFocus={true}
                                                            onChange={event => onBoxDimensionChange(event)}
                                                            style={{ width: '50%' }} />
                                                    </div>
                                                    {/* <div className="sc--form--center" style={{ margin: '30px 0' }}>
                                                        <Button variant="outlined" disabled={orders && orders.addBulkDimension && orders.addBulkDimension.loading === true ? true:false} style={{marginRight:'10px'}} onClick={() =>{addBulkDimensionsForm(dimensionValue,dimensionValue)}}>Submit</Button>
                                                        <Button variant="outlined" onClick={() => { 
                                                            setDimensionValue(initialBoxValues)
                                                            setDimensionsErrors({})
                                                            }}>Cancel</Button>
                                                    </div> */}
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} md={12} style={{ padding: '10px' }}>
                                            <Card sx={{ maxWidth: 1200 }}>
                                                <CardHeader title='Product Details' className="sc--cardhead--details" 
                                                action={
                                                    <>
                                                            <Button variant="outlined" aria-label="settings" onClick={() => setAddLineItemVisible(true)}>
                                                                Add Line Item
                                                            </Button>
                                                    </>
                                                }/>
                                                <Divider />
                                                <CardContent className="sc--product--table">
                                                    <DataGrid
                                                        getRowId={(row) => row.line_item_id + Math.random()}
                                                        rows={customLineItems}
                                                        columns={columns}
                                                        autoHeight={true}
                                                        hideFooter={true}
                                                        slots={{
                                                            NorowsData,
                                                            loadingOverlay: LinearProgress,
                                                        }}
                                                        loading={
                                                            (orderDetails && orderDetails.loading)
                                                        }
                                                    />
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <div className="sc--form--center" style={{ margin: '20px auto 0px auto' }}>
                                            <Button
                                                variant="outlined"
                                                onClick={handleSubmit}
                                                // disabled
                                                style={{ border: '1px solid rgba(0, 0, 0, 0.12)', margin: '0 15px' }}
                                            >
                                               Update
                                            </Button>
                                        </div>
                                    </Grid>
                                </>
                            </CardContent>
                        </Card>
                </div>
                }
            </Drawer >
        </>
    )
}


export default OrderDetailsEditDrawer;