import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import Paper from '@mui/material/Paper';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    boxShadow: 'unset',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    color: theme.palette.text.secondary,
}));

const FeedbackDrawer = ({ visible, reportName, formDataValues, onClose }) => {
    const dispatch = useDispatch();
    const ondrawerclose = () => {       
        onClose(false)       
    }

    return (
        <>

            <Drawer
                anchor="right"
                open={visible}
                onClose={ondrawerclose}
                PaperProps={{
                    sx: { width: "600px" },
                }}
            >
            </Drawer >
        </>
    )
}


export default FeedbackDrawer;