import React, { useState, useEffect } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import ListItemText from '@mui/material/ListItemText';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';


const DynamicColumns = ({ anchorEl, visible, columns, columnCheckedObject, setColumnCheckedObject, updateDynamicColumns, handleColumnsClose, loading }) => {
    const [columnSearchText, setColumnSearchText] = useState("")
    const handleColumnToggle = (key, value) => {
        if (columnCheckedObject && columnCheckedObject[key]) {
            setColumnCheckedObject(prevState => {
                const state = { ...prevState };
                delete state[key]
                return state;
            });
        } else {
            setColumnCheckedObject(prevState => ({
                ...prevState,
                [key]: value
            }));
        }
    };

    useEffect(() => {
        if (columns && columns.user_columns && columns.user_columns.length !== 0) {
            Object.entries(columns.user_columns).map(([key, value]) => (
                setColumnCheckedObject(prevState => ({
                    ...prevState,
                    [key]: value
                }))
                ))
        }
    }, [columns])

    return (<> {visible &&
        <Popover
            id="dynamic-column-positioned-menu"
            className = "dynamic-column-positioned-menu"
            anchorEl={anchorEl}
            open={visible}
            onClose={handleColumnsClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            style={{ maxHeight: "400px", marginTop: "40px" }}
        >
            <List>
                <ListItem style={{ position: "sticky", top: "0px", backgroundColor: "white", zIndex: "99" }}>
                    <TextField size={'small'} onChange={(event) => setColumnSearchText(event.target.value)} placeholder="Search column" />
                </ListItem>
                {columns && columns.default_columns && Object.entries(columns.default_columns).map(([key, value]) => {
                    const labelId = `checkbox-list-secondary-label-${value}`;
                    return (
                        <ListItem key={key} style={{ "display": (columnSearchText && columnSearchText !== "" && key.toLowerCase().indexOf(columnSearchText.toLowerCase()) === -1) ? "none" : "" }}>
                            <Switch
                                size="small"
                                onChange={() => handleColumnToggle(key, value)}
                                checked={(columnCheckedObject && columnCheckedObject[key]) ? true : false}
                                inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                            />
                            <ListItemText id={labelId} primary={key} />
                        </ListItem>);
                })
                }
                <ListItem style={{ position: "sticky", bottom: "0px", backgroundColor: "white", zIndex: "99" }}>
                    <LoadingButton
                    variant="outlined"
                    style={{ width: "45%" }}
                    loading={loading}
                    disabled={(columnCheckedObject && Object.keys(columnCheckedObject).length !== 0) ? false : true}
                    onClick={() => updateDynamicColumns(true)}
                    >Clear</LoadingButton>
                    <LoadingButton variant="contained" style={{ width: "45%", marginLeft: "20px" }} loading={loading} onClick={() => updateDynamicColumns()}>Apply</LoadingButton>
                </ListItem>
            </List>
        </Popover>}
    </>);
}

export default DynamicColumns;